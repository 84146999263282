import { Injectable, Output, EventEmitter } from "@angular/core";
import { UserService } from "./user.service";
import { NotificationService } from "./notification.service";
import { HttpHeaders, HttpResponse, HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { SiteService } from "./site.service";
import { DomainModel } from "../models/domain-type.model";

@Injectable({
  providedIn: "root",
})
export class DomainService {
  public message: string;
  public domainTypeTables: Array<{
    PickListID: number;
    TableName: string;
    FieldName: string;
    DisplayValue: string;
    Active: boolean;
  }> = [];

  @Output() domainDataChanged: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
    private http: HttpClient,
    private siteService: SiteService
  ) { }

  createDomainAPI(domain: DomainModel): Promise<any> {
    const apiURL = environment.getAPI("createUpdateDomain");

    const postBody = {
      Domain: domain.Domain,
      Description: domain.Description,
      DomainCategory: domain.DomainCategory,
      Active: 1,
      UserID: this.userService.userID,
      SiteID: this.siteService.selectedSiteID,
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
            this.notificationService.success(this.message);
            this.domainDataChanged.emit(true);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  updateDomainAPI(domain: DomainModel): Promise<any> {
    const apiURL = environment.getAPI("createUpdateDomain");

    const postBody = {
      DomainID: domain.DomainID,
      Domain: domain.Domain,
      Description: domain.Description,
      DomainCategory: domain.DomainCategory,
      Active: domain.Active,
      UserID: this.userService.userID,
      SiteID: this.siteService.selectedSiteID,
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
            this.notificationService.success(this.message);
            this.domainDataChanged.emit(true);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public deleteDomainAPI(domain: DomainModel) {
    domain.Active = 0; //disabling entry point zone
    return this.updateDomainAPI(domain).then(() =>
      this.domainDataChanged.emit(true)
    );
  }

  // get Pass template  from pick list table
  public getDomainPickListAPI() {
    const apiURL = environment.getAPI("getPickList");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise<
      {
        PickListID: number;
        TableName: string;
        FieldName: string;
        DisplayValue: number;
        Active: boolean;
      }[]
    >((resolve, reject) => {
      this.http
        .post(apiURL, { TableName: "Domain", SiteID: 0 }, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.domainTypeTables = success.body.map((e) => ({
              PickListID: e.PickListID,
              TableName: e.TableName,
              FieldName: e.FieldName,
              DisplayValue: e.DisplayValue,
              Active: e.Active,
            }));
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

}
