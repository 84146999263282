import { Component, OnInit, ViewChild, AbstractType } from "@angular/core";
import { SearchService } from "../services/search.service";
import { RoleMapService } from "../services/role-map.service";
import { SiteService } from "../services/site.service";
import { cloneDeep } from "lodash";

import {
  ISystemSettingsGetAPIResponse,
  ISiteOrgAdminRoles,
} from "../models/interfaces/system-settings-get-api.model";
import { ReadSiteSettingsOperations } from "./read-site-settings-operations";
import { Role } from "../models/role.model";
import { UtilityService } from "../services/utility.service";
import { FormControl, NgForm } from "@angular/forms";
import { WriteSiteSettingsOperation } from "./write-site-settings-operation";
import { DialogService } from "../services/dialog.service";
import { NotificationService } from "../services/notification.service";
import { RequiredFieldsService } from '../services/required-fields.service';
import { TimezoneService } from '../services/timezone.service';
import { PassService } from '../services/pass.service';
import { MessageService } from "../services/message.service";
import { HttpErrorResponse } from "@angular/common/http";
import { map, startWith } from "rxjs/operators";
import { Observable } from "rxjs";
import { LabelService } from "../services/label.service";

@Component({
  selector: "app-site-settings",
  templateUrl: "./site-settings.component.html",
  styleUrls: ["./site-settings.component.css"],
})
export class SiteSettingsComponent implements OnInit {
  @ViewChild("siteSettingsFrm") siteSettingsFrm: NgForm;

  private _settingsAPIResponse: ISystemSettingsGetAPIResponse;
  private _orgAdminRoles: ISiteOrgAdminRoles[] = [];
  private _roles: Role[] = [];
  private _selectedRoles: Role[] = [];
  protected enablePrinter: boolean = false;
  protected rolesLoaded: boolean = false;
  protected siteSettingsLoaded: boolean = false;

  protected selectedRoleNames: string[] = []; // stores the available organization admin roles names
  protected allRoleNames: string[] = []; //stores all the role names
  protected timeZones: Array<{ Name: string }> = [];

  protected poiSyllableCount: number;
  protected poiNameCount: number;
  protected poiArticleCount: number;
  protected poiSuffixCount: number;
  protected poiMinSyllableCount: number = 1;
  protected smartSearchMaxRowCount: number;
  protected smartSearchMinRowCount: number = 15;

  protected passCheckInMinThreshold: number = 0;
  protected passCheckInMaxThreshold: number = 900;
  protected passCheckInThreshold: number;

  protected smartSearchRowCount: number;
  protected selectedTimeZone: string;
  protected isParkingEnabled: boolean = true;
  protected isTemporaryBadgeEnabled: boolean = false;
  protected isCheckinPhotoEnabled: boolean = false;
  protected isCameraEquipmentEnabled: boolean = false;
  protected isCallInPassEnabled: boolean = true;
  protected isPurgeEnabled: boolean = false;
  protected purgeCount: number;
  protected purgeMinCount: number = 1;
  protected purgeMaxCount: number = 180;
  protected purgeError: boolean = false;
  protected timeFormat: string;
  public dateFormat: string;
  public defaultStartTime: any;
  public defaultEndTime: any;
  public reasonOfVisit: any[] = [];
  public siteTypes: any;
  public siteType: any;
  public defaultReasonOfVisit: number;
  protected isCloudPrintEnabled: boolean = false;
  protected isCountryEnabled: boolean = false;
  protected cloudPrintServerName: string;
  protected templateID: number;
  protected carTemplateID: number;
  protected cloudTemplateID: number;
  protected carCloudTemplateID: number;
  protected archivalCount: number;
  protected visitorResponse: any;
  protected parkingResponse: any;
  protected phoneFormat:string;
  protected enableSaveButton: boolean = false;
  protected enableIpError: boolean = false;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  protected LoadingError: boolean = false;
  public userConfigData: Array<any> = [];
  public approvalTypeID;
  protected rowLimitError: boolean = false;
  protected roleNames: any;
  reasonOfVisitFormControl = new FormControl();
  filteredReasonForVisit: Observable<any[]>;
  protected rowLimitCountError: boolean = false;
  protected errorString;

  constructor(
    private _searchService: SearchService,
    private _roleService: RoleMapService,
    private _siteService: SiteService,
    private _utilService: UtilityService,
    private _dialogService: DialogService,
    private _notificationService: NotificationService,
    protected requiredFieldsService: RequiredFieldsService,
    protected timezoneService: TimezoneService,
    protected passService: PassService,
    protected _labelService: LabelService,
    protected messageService: MessageService
  ) { }

  ngOnInit(): void {
    this._searchService.hideSearch();
    this._loadRoleNames();
    this._loadSiteSettings();
    this.checkRowLimitError();

    console.log("initial")
    //labels by message service
    if (localStorage.getItem('SiteSettingsLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('SiteSettingsLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Site Settings",
        "Settings"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('SiteSettingsLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    this.passService.getVisitReasonsAPI().then(() => {
      this.reasonOfVisit = this.passService.reasonOfVisit;
      this.filteredReasonForVisit = this.reasonOfVisitFormControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value?.DisplayValue),
        map(DisplayValue => DisplayValue ? this._filteredReasonForVisit(DisplayValue) : this.reasonOfVisit.slice())
      );
    });

    this.passService.getSiteTypeAPI().then(() => {
      this.siteTypes = this.passService.siteTypes;
    });

    this.passService.getPrintTemplateNameAPI(this._siteService.selectedSiteID, "Visitor").subscribe((res: any) => {
      this.visitorResponse = res.body;
    });

    this._searchService.smartSearchMultiSiteAPI(null, "Approval Type Site Settings", "ApprovalTypeSiteSettings").then(() => {
      const approvalTypeData = this._searchService.searchRecords;
      const id = (approvalTypeData.filter(data => data && data.Tag === "User Request" && data.SiteID === this._siteService.selectedSiteID).map(ele => ele.ApprovalTypeID))
      this.approvalTypeID = (id.length > 0) ? id[0] : 4;
    })
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  private _filteredReasonForVisit(value: string) {
    return this.reasonOfVisit.filter(option => option.DisplayValue.toLowerCase().includes(value.toLowerCase()));
  }

  displayFn1(selectedoption: number) {
    if(selectedoption) {
      const selectedReason = this.reasonOfVisit.find(x => x.PickListID === selectedoption);
    return selectedReason ? selectedReason.DisplayValue : '';
    }
    return '';
  }
  

  ngAfterViewChecked() {
    //setting up the required fields
    // this.requiredFieldsService.setRequiredFields(
    //   'Site Settings',
    //   this.siteSettingsFrm
    // );
  }

  onRowLimitChange(event) {
    if(this.smartSearchRowCount < this.smartSearchMinRowCount) 
      this.rowLimitError = true;
    else 
      this.rowLimitError = false;
    this.checkRowLimitError();
  }
  onParkingChange(event) {
    if (this.isParkingEnabled) {
      this.passService.getPrintTemplateNameAPI(this._siteService.selectedSiteID, "Parking").subscribe((res: any) => {
        this.parkingResponse = res.body;
      });
    }
  }

  checkRowLimitError() {
    if (
      !(
        !isNaN(this.smartSearchRowCount) &&
        this.smartSearchRowCount >= this.smartSearchMinRowCount &&
        this.smartSearchRowCount <= this.smartSearchMaxRowCount &&
        this.smartSearchRowCount % 1 === 0
      )
    ) {
      this.rowLimitCountError = true;
      const replaceID = this.screenLabels["RowLimitErrorMsg"] ? this.screenLabels["RowLimitErrorMsg"] : "Please enter a valid integer between <<id1>> and <<id2>>";
      const errorMsg = replaceID.replace("<<id1>>", this.smartSearchMinRowCount).replace("<<id2>>", this.smartSearchMaxRowCount)
      this.errorString = errorMsg;
    }
    else this.rowLimitCountError = false;
  }
  private async _loadRoleNames() {
    try {
      await this._roleService.getRoleMappingsAPI();

      // filter all roles
      this.allRoleNames = this._roleService
        .getRoleMappingResponse()
        .filter(
          (rm) =>
            rm.RoleActive == 1 && rm.SiteID === this._siteService.selectedSiteID
        )
        .map((r) => r.RoleName);

      // remove duplicates for roles
      this.allRoleNames = [...new Set(this.allRoleNames)];

      this._roles = this._roleService.getRoleMappingResponse().map((r) => ({
        RoleID: r.RoleID,
        SiteID: r.SiteID,
        RoleName: r.RoleName,
        Notes: undefined,
        Active: r.RoleActive,
      }));

      this._roles = this._utilService.removeObjArrayDuplicates(this._roles);
      this.rolesLoaded = true;
    } catch (err) {
      console.log(err);
    }
  }

  private async _loadSiteSettings() {
    try {
      this._settingsAPIResponse = await this._siteService.getSiteSettingsAPI();
      const readSiteSettingsOperator = new ReadSiteSettingsOperations(
        cloneDeep(this._settingsAPIResponse)
      );
      var clone = cloneDeep(this._settingsAPIResponse);
      this.timeZones = readSiteSettingsOperator.getAllTimeZones();
      this.selectedTimeZone = readSiteSettingsOperator.getSelectedTimeZone();
      this.poiSyllableCount = readSiteSettingsOperator.getPOISyllableCount();
      this.poiNameCount = readSiteSettingsOperator.getPOINameCount();
      this.poiArticleCount = readSiteSettingsOperator.getPOIArticleCount();
      this.poiSuffixCount = readSiteSettingsOperator.getPOISuffixCount();
      this.smartSearchMaxRowCount = readSiteSettingsOperator.getSmartSearchMaxRowLimit();
      this.smartSearchRowCount = readSiteSettingsOperator.getSmartSearchRowLimit();
      this.dateFormat = readSiteSettingsOperator.getDateFormat();
      this.timeFormat = readSiteSettingsOperator.getTimeFormat();
      this.phoneFormat = readSiteSettingsOperator.getPhoneFormat();
      const parkingValue = readSiteSettingsOperator.checkParkingEnabled();
      this.isParkingEnabled = parkingValue == null ? true : (parkingValue == 1 ? true : false)
      //this.isParkingEnabled = readSiteSettingsOperator.checkParkingEnabled();
      //loading car templatename on parking enabled
      if (this.isParkingEnabled) {
        this.passService.getPrintTemplateNameAPI(this._siteService.selectedSiteID, "Parking").subscribe((res: any) => {
          this.parkingResponse = res.body;
        });
      }
      const temporaryBadgeValue = readSiteSettingsOperator.checkTemporaryBadgeEnabled();
      this.isTemporaryBadgeEnabled = temporaryBadgeValue ? (temporaryBadgeValue == 1 ? true : false) : false
      this.passCheckInThreshold = readSiteSettingsOperator.getPassCheckInThreshold();
      const checkinPhotoValue = readSiteSettingsOperator.checkinPhotoEnabled();
      this.isCheckinPhotoEnabled = checkinPhotoValue ? (checkinPhotoValue == 1 ? true : false) : false
      const cameraEquipmentValue = readSiteSettingsOperator.cameraEquipmentEnabled();
      this.isCameraEquipmentEnabled = cameraEquipmentValue ? (cameraEquipmentValue == 1 ? true : false) : false
      const callinPassValue = readSiteSettingsOperator.callinPassEnabled();
      this.isCallInPassEnabled = callinPassValue == null ? true : (callinPassValue == 1 ? true : false)

    //  const purgeValue = readSiteSettingsOperator.checkPurgeEnabled();
    //  this.isPurgeEnabled = purgeValue == null ? true : (purgeValue == 1 ? true : false)
    //  this.purgeCount = parseInt(readSiteSettingsOperator.getPurgeCount());

      this._orgAdminRoles = readSiteSettingsOperator.getAvailableOrgAdminRoles();
      var startDate = readSiteSettingsOperator.getDefaultStartTime();
      var endDate = readSiteSettingsOperator.getDefaultEndTime();
      this.defaultStartTime = this.timezoneService.stringToTime(startDate);
      this.defaultEndTime = this.timezoneService.stringToTime(endDate);
      this.defaultReasonOfVisit = parseInt(readSiteSettingsOperator.getDefaultReasonOfVisit());
      this.siteType = parseInt(readSiteSettingsOperator.getDefaultSiteType());
      const cloudPrintEnableValue = readSiteSettingsOperator.checkCloudPrintEnabled();
      this.isCloudPrintEnabled = cloudPrintEnableValue ? (cloudPrintEnableValue == 1 ? true : false) : false
      this.cloudPrintServerName = readSiteSettingsOperator.getCloudPrintServer();

      const countryEnableValue = readSiteSettingsOperator.checkCountryEnabled();
      this.isCountryEnabled = countryEnableValue ? (countryEnableValue == 1 ? true : false) : false

      this.templateID = parseInt(readSiteSettingsOperator.getTemplate());
      this.carTemplateID = parseInt(readSiteSettingsOperator.getCarTemplate());
      this.cloudTemplateID = parseInt(readSiteSettingsOperator.getCloudTemplate());
      this.carCloudTemplateID = parseInt(readSiteSettingsOperator.getCarCloudTemplate());

     // this.archivalCount = parseInt(readSiteSettingsOperator.getArchivalCount());
      

      if (this.isCloudPrintEnabled) {
        this.enablePrinter = true;
        this.onBlurWerServer();
      } else {
        this.enablePrinter = false;
        this.enableSaveButton = false;
      }
      if (this._orgAdminRoles && this._orgAdminRoles.length)
        this.selectedRoleNames = this._orgAdminRoles.map((oar) => oar.RoleName)
      else this.selectedRoleNames = [];

      this.siteSettingsLoaded = true;
    } catch (err) {
      console.error(err);
    }
  }

  OnArrivalTimeChange(event) {
    if (event.newValue == "Invalid Date" || this.defaultStartTime == "Invalid Date") {
      this.defaultStartTime = "";
    }
  }

  OnDefaultEndTime(event) {
    if (event.newValue == "Invalid Date" || this.defaultEndTime == "Invalid Date") {
      this.defaultEndTime = "";
    }
  }

  protected onSaveChanges() {
    if (this._validate()) {
      const confirmDialog = this._dialogService.openConfirmDialog(
     this.screenLabels["SaveSettingsConfirmLabel"] ? this.screenLabels["SaveSettingsConfirmLabel"] : "Save Site Settings" ,
    this.screenLabels["SaveSettingsConfirmMsg"] ? this.screenLabels["SaveSettingsConfirmMsg"] : "Are you sure if you want to save the Site Settings ?"  
      );

      confirmDialog.afterClosed().subscribe((confirm: boolean) => {
        if (confirm) {
          try {
            this._selectedRoles = this._roles.filter((r) =>
              this.selectedRoleNames.includes(r.RoleName)
            );

            // remove any duplicates
            this._selectedRoles = this._utilService.removeObjArrayDuplicates(
              this._selectedRoles
            );
            const writeSiteSettingsOperator = new WriteSiteSettingsOperation(
              this._settingsAPIResponse,
              this.poiSyllableCount,
              this.poiNameCount,
              this.poiArticleCount,
              this.poiSuffixCount,
              this.smartSearchRowCount,
              this.selectedTimeZone,
              this._selectedRoles,
              this.passCheckInThreshold,
              this.isParkingEnabled ? 1 : 0,
              this.isTemporaryBadgeEnabled ? 1 : 0,
            //  this.isPurgeEnabled ? 1 : 0,
             // this.purgeCount,
              this.isCheckinPhotoEnabled ? 1 : 0,
              this.isCameraEquipmentEnabled ? 1 : 0,
              this.isCallInPassEnabled ? 1 : 0,
              this.dateFormat,
              this.timeFormat,
              this.phoneFormat,
              this._siteService,
              this._notificationService,
              this.timezoneService.TimeToString(this.defaultStartTime),
              this.timezoneService.TimeToString(this.defaultEndTime),
              this.isCountryEnabled ? 1 : 0,
              this.defaultReasonOfVisit,
              this.siteType,
              this.isCloudPrintEnabled ? 1 : 0,
              this.cloudPrintServerName,
              this.templateID,
              this.carTemplateID,
              this.cloudTemplateID,
              this.carCloudTemplateID,
             // this.archivalCount
            );

            writeSiteSettingsOperator.save();
            localStorage["isCloudPrinting"] = this.isCloudPrintEnabled;
            if(this.phoneFormat === "USA"){
              localStorage["isPhoneUSA"] = true;
            }
            else if (this.phoneFormat === "International") {
              localStorage["isPhoneUSA"] = false;
            }
          } catch (err) {
            console.error(err);
          }
        }
      });
    }
  }

  private _validate() {
    let isDataValid = true;
    // logic to validate pass check threshold
    if (
      !(
        !isNaN(this.passCheckInThreshold) &&
        this.passCheckInThreshold >= this.passCheckInMinThreshold &&
        this.passCheckInThreshold <= this.passCheckInMaxThreshold
      )
    ) {
      isDataValid = false;
      this._dialogService.openAlertDialogDialog(
        this.screenLabels["InvalidCheckInThreshold"] ? this.screenLabels["InvalidCheckInThreshold"] : "Invalid Pass Check In Threshold" ,
        `Please enter a threshold between ${this.passCheckInMinThreshold} and ${this.passCheckInMaxThreshold} minutes.`
      );
      return isDataValid;
    }

    // logic to check if POI syllable count is valid
    if (
      !(
        !isNaN(this.poiSyllableCount) &&
        this.poiSyllableCount >= this.poiMinSyllableCount &&
        this.poiSyllableCount % 1 === 0
      )
    ) {
      isDataValid = false;
      this._dialogService.openAlertDialogDialog(
        this.screenLabels["InvalidPOICountMsg"] ? this.screenLabels["InvalidPOICountMsg"] : "Invalid Person Of Interest Syllable Count",
        this.screenLabels["EnterValidIntegerMsg"] ? this.screenLabels["EnterValidIntegerMsg"] : "Please enter a valid integer."
      );
      return isDataValid;
    }

    // smart search row limit validation

    
    if (
      !(
        !isNaN(this.smartSearchRowCount) &&
        this.smartSearchRowCount >= this.smartSearchMinRowCount &&
        this.smartSearchRowCount <= this.smartSearchMaxRowCount &&
        this.smartSearchRowCount % 1 === 0
      )
    ) {
      isDataValid = false;
      const replaceID = this.screenLabels["RowLimitErrorMsg"] ? this.screenLabels["RowLimitErrorMsg"] : "Please enter a valid integer between <<id1>> and <<id2>>";
      const errorMsg = replaceID.replace("<<id1>>", this.smartSearchMinRowCount).replace("<<id2>>", this.smartSearchMaxRowCount)
      this._dialogService.openAlertDialogDialog(
        this.screenLabels["InvalidsearchCountMsg"] ? this.screenLabels["InvalidsearchCountMsg"] : "Invalid Smart Search Row Count" ,
        errorMsg
      );
      return isDataValid;
    }

    // org admin role selection validation
    if (!this.selectedRoleNames.length) {
      isDataValid = false;
      this._dialogService.openAlertDialogDialog(
        this.screenLabels["RequiredOrgRoleSelectionLabel"] ? this.screenLabels["RequiredOrgRoleSelectionLabel"] : "Required Organization Role Selection" ,
        this.screenLabels["RequiredOrgRoleSelectionMsg"] ? this.screenLabels["RequiredOrgRoleSelectionMsg"] : "Selecting roles available for Organization administrator is required."
      );
      return isDataValid;
    }

    // parking selection validation
    if (this.isParkingEnabled === undefined) {
      isDataValid = false;
      this._dialogService.openAlertDialogDialog(
        this.screenLabels["RequiredParkingLabel"] ? this.screenLabels["RequiredParkingLabel"] : "Required Parking"  ,
        this.screenLabels["RequiredParkingMsg"] ? this.screenLabels["RequiredParkingMsg"] : "Select if Parking is applicable for within the site."
      );
      return isDataValid;
    }

    return isDataValid;
  }

  toggle() {
    if (this.isCloudPrintEnabled) {
      this.enablePrinter = true;
      this.onBlurWerServer()
    } else {
      this.enablePrinter = false;
      this.enableSaveButton = false;
    }
  }

  onBlurWerServer() {
    this.enableSaveButton = true;
    this.enableIpError = false;
    this.LoadingError = true;
    this.passService.webServerHealthCheck(this.cloudPrintServerName).subscribe((res: any) => {
      if (res.body.message === 'Print server is ready to print') {
        this.enableSaveButton = false;
        this.LoadingError = false;
      }
    }, (err) => {
      this.LoadingError = false;
      this.enableSaveButton = true;
      this.enableIpError = true;
    });
  }

}