import { Injectable, EventEmitter, Output } from "@angular/core";
import { EntryPoint } from "../models/entry-point.model";
import { EntryPointStation } from "../models/entry-point-station.model";
import { HttpHeaders, HttpResponse, HttpClient } from "@angular/common/http";
import { IEntryPointReadAPI } from "../models/interfaces/entrypoint-read-api";
import { UserService } from "./user.service";
import { NotificationService } from "./notification.service";
import { environment } from "src/environments/environment";
import { SiteService } from "./site.service";
import { UtilityService } from "./utility.service";
import { Printers } from "../models/printers.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class EntryPointService {
  public entryPoints: EntryPoint[];
  private activeEntryPoints: EntryPoint[] = [];
  private entryPointStations: EntryPointStation[];
  public entryPointStationApiData: Array<IEntryPointReadAPI>;
  protected entryGroupNames: EntryPoint[] = [];
  public message;
  private postBody;

  @Output() entryPointDataChanged: EventEmitter<boolean>;
  @Output() entryPointStationsDataChanged: EventEmitter<boolean>;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private notificationService: NotificationService,
    private siteService: SiteService,
    private utilService: UtilityService
  ) {
    this.entryPointDataChanged = new EventEmitter<boolean>();
    this.entryPointStations = [];
    this.getEntryPoints();
  }

  public getEntryPoints(): EntryPoint[] {
    return this.entryPoints;
  }

  public parseGetEndpointAPI(entryPointGroupId?: number): Promise<any> {
    const apiURL = environment.getAPI("getEntryPoint");
    if (entryPointGroupId) {
      this.postBody = {
        SiteID: this.siteService.selectedSiteID,
        EntryPointGroupID: entryPointGroupId
      };
    } else {
      this.postBody = {
        SiteID: this.siteService.selectedSiteID,
      };
    }

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, this.postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.entryPointStationApiData = success.body;
            this.entryPoints = new Array<EntryPoint>();
            // store entry point data
            this.entryPointStationApiData.forEach((element) => {
              let ep = new EntryPoint(
                element.EntryPointID,
                element.Description,
                element.Phone,
                element.EmailDirections,
                element.EntryPointActive ? 1 : 0,
                element.EntryPointZoneID,
                element.EntryPointZoneName,
                element.ModifiedBy ? +element.ModifiedBy : null,
                new Date(element.ModifiedDate),
                element.CreatedBy ? +element.CreatedBy : null,
                new Date(element.CreatedDate),
                element.IPAddress,
                element.HostName,
                element.EntryPointStationID
              );
              this.entryPoints.push(ep);
            });

            // store entry point station data

            this.entryPointStations = this.entryPointStationApiData
              .map((epAPI) => ({
                EntryPointStationID: epAPI.EntryPointStationID,
                EntryPointID: epAPI.EntryPointID,
                HostName: epAPI.HostName,
                IPAddress: epAPI.IPAddress,
                DefaultPrinterName: epAPI.DefaultPrinterName,
                ModifiedBy: epAPI.ModifiedBy,
                ModifiedDate: new Date(epAPI.ModifiedDate),
                CreatedBy: epAPI.CreatedBy,
                CreatedDate: new Date(epAPI.CreatedDate),
                Active: epAPI.EntryPointStationActive ? true : false,
              }))
              .filter((epAPI) => epAPI.EntryPointStationID !== null);
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // return EntryPointStation Array
  public getEntryPointStations(entryPointID: number): EntryPointStation[] {
    return [
      ...this.entryPointStations.filter((e) => e.EntryPointID === entryPointID),
    ];
  }

  // using observables
  public getEntryPointForEntryPointGroup(): Observable<EntryPoint[]> {
    const apiURL = environment.getAPI('getEntryPointForEntryPointGroup');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as const
    };

    const postBody = {
      SiteID: this.siteService.selectedSiteID,
    };

    return this.http.post<HttpResponse<any>>(apiURL, postBody, httpOptions).pipe(
      map((response: HttpResponse<any>) => {
        // Map the response body to an array of EntryPoint instances
        return (response.body as any[]).map(item => new EntryPoint(
          item.EntryPointID,
          item.Description,
          item.Phone,
          item.EmailDirections,
          item.Active,
          item.EntryPointZoneID,
          item.EntryPointZoneName,
          item.ModifiedBy,
          new Date(item.ModifiedDate),
          item.CreatedBy,
          new Date(item.CreatedDate),
          item.IPAddress,
          item.HostName,
          item.EntryPointStationID
        ));
      })
    );
  }

  

  public getEntryPointByID(siteID: number, entryPointID: number) {

    const apiURL = environment.getAPI("getEntryPointById");
    //const apiURL = "https://ynx62sq9r9.execute-api.us-west-2.amazonaws.com/dev/getEntryPointById";
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      SiteID: siteID,
      EntryPointID: entryPointID
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    // this.dataChanged.emit(true);
    return res;
  }

  // Invokes a Create an Entry Point API call
  public createEntryPointAPI(
    entryPoint: EntryPoint,
    printers: Array<Printers>,
    entryPointStations: Array<EntryPointStation>,
    cloudPrintEnable: boolean,
  ): Promise<any> {
    const apiURL = environment.getAPI("entryPointCreate");
    //const apiURL = "https://ynx62sq9r9.execute-api.us-west-2.amazonaws.com/dev/entryPointCreate";

    const postBody = {
      Description: entryPoint.Description,
      Phone: entryPoint.Phone,
      EmailDirections: entryPoint.EmailDirections,
      EntryPointZoneID: entryPoint.EntryPointZoneID,
      Active: true,
      UserID: this.userService.userID,
      Printers: printers.map((prnt) => ({
        PrinterID: 0,
        IPAddress: prnt.IPAddress,
        HostName: prnt.HostName,
        Active: true ? 1 : 0,
      })),
      EntryPointStations: entryPointStations.map((eps) => ({
        EntryPointStationID: 0,
        HostName: eps.HostName,
        IPAddress: eps.IPAddress,
        DefaultPrinterName: eps.DefaultPrinterName,
        Active: true ? 1 : 0,
      })),
      SiteID: this.siteService.selectedSiteID,
      CloudPrintEnabled: cloudPrintEnable,
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
            this.entryPointDataChanged.emit(true);
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // add EntryPointStation
  public addEntryPointStation(
    entryPointID: number,
    hostName: string,
    ipAddress: string,
    defaultPrinterName: string
  ) {
    const nextID: number = this.entryPointStations.length + 1;
    const tempEntryPointStation = new EntryPointStation(
      nextID,
      entryPointID,
      hostName,
      ipAddress,
      defaultPrinterName,
      null,
      null,
      this.userService.userID,
      this.utilService.getCurrentSiteDT()
    );
    this.entryPointStations.push(tempEntryPointStation);

    this.entryPointStationsDataChanged.emit(true);
  }

  // update an entry point by id
  public updateEntryPointAPI(
    entryPointID: number,
    description: string,
    phone: string,
    emailDirections: string,
    entryPointZoneID: number,
    active: boolean,
    //entryPointStations: Array<EntryPointStation>

    //entryPoint: EntryPoint,
    printers: Array<Printers>,
    entryPointStations: Array<EntryPointStation>,
    cloudPrintEnable: boolean,
  ): Promise<any> {
    const apiURL = environment.getAPI("entryPointUpdate");
    //const apiURL = "https://ynx62sq9r9.execute-api.us-west-2.amazonaws.com/dev/entryPointUpdate";

    const postBody = {
      EntryPointID: entryPointID,
      Description: description,
      Phone: phone,
      EmailDirections: emailDirections,
      EntryPointZoneID: entryPointZoneID,
      Active: active,
      UserID: this.userService.userID,
      Printers: printers.map((prnt) => ({
        PrinterID: prnt.PrinterID,
        IPAddress: prnt.IPAddress,
        HostName: prnt.HostName,
        Active: prnt.Active ? 1 : 0,
      })),
      EntryPointStations: entryPointStations.map((eps) => ({
        EntryPointStationID: eps.EntryPointStationID,
        HostName: eps.HostName,
        IPAddress: eps.IPAddress,
        DefaultPrinterName: eps.DefaultPrinterName,
        Active: eps.Active ? 1 : 0,
      })),
      SiteID: this.siteService.selectedSiteID,
      CloudPrintEnabled: cloudPrintEnable,
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
            this.entryPointDataChanged.emit(true);
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
    // return null;
  }

  // update an EntryPointStation by ID
  public updateEntryPointStation(
    entryPointStationID: number,
    hostName: string,
    ipAddress: string
  ): void {
    const index: number = this.entryPointStations.findIndex(
      (e) => e.EntryPointStationID === entryPointStationID
    );
    this.entryPointStations[index].HostName = hostName;
    this.entryPointStations[index].IPAddress = ipAddress;
    this.entryPointStations[
      index
    ].ModifiedDate = this.utilService.getCurrentSiteDT();

    this.entryPointStationsDataChanged.emit(true);
  }

  // disable entry point by id
  public disableEntryPoint(
    entryPointID: number,
    description: string,
    phone: string,
    emailDirections: string,
    entryPointZoneID: number,
    printers: Array<Printers>,
    entryPointStations: Array<EntryPointStation>,
    cloudPrintEnable: boolean,
  ) {
    return this.updateEntryPointAPI(
      entryPointID,
      description,
      phone,
      emailDirections,
      entryPointZoneID,
      false,
      printers,
      entryPointStations,
      cloudPrintEnable
    ).then(() => this.entryPointDataChanged.emit(true));
  }

  // disable entry point station by id
  public disableEntryPointStation(entryPointStationID: number): void {
    const index: number = this.entryPointStations.findIndex(
      (e) => e.EntryPointStationID === entryPointStationID
    );
    this.entryPointStations[index].Active = false;

    this.entryPointStationsDataChanged.emit(true);
  }
}
