import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MatExpansionPanel, MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { ApprovalGroup } from 'src/app/models/approval-group-model';
import { CreateParkingGroupModalComponent } from 'src/app/parking-lot-group/modals/create-parking-group/create-parking-group-modal.component';
import { ApprovalGroupService } from 'src/app/services/approval-group.service';
import { EntryPointService } from 'src/app/services/entry-point.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SearchService } from 'src/app/services/search.service';
import { SiteService } from 'src/app/services/site.service';
import { UserService } from 'src/app/services/user.service';
import { RequiredFieldsService } from 'src/app/services/required-fields.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-create-approval-group',
  templateUrl: './create-approval-group.component.html',
  styleUrls: ['./create-approval-group.component.css']
})
export class CreateApprovalGroupComponent implements OnInit {

  protected step = 0;
  protected approvalGroup: ApprovalGroup;
  //nameFormControl = new FormControl("", [Validators.required]);
  usersList: Array<any> = [];
  AvailableUsersList: Array<String> = [];
  selectedUserList: Array<String> = [];
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  @ViewChild("approvalForm", {static:true}) approvalForm: NgForm;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ApprovalGroup,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<CreateApprovalGroupComponent>,
    protected entryPointService: EntryPointService,
    protected approvalGroupService: ApprovalGroupService,
    private userService: UserService,
    protected notificationService: NotificationService,
    protected searchService: SearchService,
    private siteService: SiteService,
    protected requiredFieldsService: RequiredFieldsService,
    protected messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.approvalGroup = new ApprovalGroup(
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );

//labels by message service
if (localStorage.getItem('CreateApprovalGroupLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('CreateApprovalGroupLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Create Approval Group Modal",
      "ApprovalGroup"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('CreateApprovalGroupLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

   // this.getUsers();
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}

  ngAfterViewChecked() {
    // setting up the required fields
    this.requiredFieldsService.setRequiredFields(
      this.screenLabels['NewApprovalGroup'] ? this.screenLabels['NewApprovalGroup'] : "New Approval Group",
      this.approvalForm
    );
  }

  // getUsers(): void {
  //   this.searchService.setPageName("User");
  //   this.searchService
  //     .smartSearchMultiSiteAPI(null)
  //     .then(() => {
  //       this.usersList = this.searchService.searchRecords;
  //       console.log(this.usersList)
  //       this.usersList.forEach(element => {
  //         if (element.Active === 1) {
  //           this.AvailableUsersList.push(element.FirstName +' '+ element.LastName + ' '+ '|'+' '+element.Email);
  //         }
  //       });
  //     })
  //     .catch((err: HttpErrorResponse) => {
  //       this.notificationService.failure(err.message);
  //     });
  // }

  onNoClick(): void {
    this.dialogRef.close(this.searchService.setPageName("Approval Group"));
  }

  onSaveChanges() {
    // const users = [];
    // console.log(this.usersList)
    // this.usersList.forEach(user =>{
    //   console.log( this.selectedUserList)
    //   this.selectedUserList.forEach(suser =>{
    //     const firstName = suser.split(" ")[0];
    //     const lastName = suser.substr(suser.indexOf(" ") + 1);
    //     if(firstName === user.FirstName && lastName === user.LastName){
    //       users.push({
    //         "AGUserID": user.UserID,
    //         "Active": user.Active
    //       });
    //     }
    //   })
    // })
    // console.log(users)
   // this.approvalGroup.Users = users;

   const name = this.approvalGroup.Name.trim() === this.approvalGroup.Name ? this.approvalGroup.Name : this.approvalGroup.Name.trim();
   this.approvalGroup.Name = name ;

    this.approvalGroup.UserID = this.userService.userID;
    this.approvalGroup.SiteID = this.siteService.selectedSiteID;
    const approvalId = this.approvalGroupService.createApprovalGroup(
      this.approvalGroup
    );
    this.onNoClick();
  }

  isNameEmpty(): boolean {
    return !this.approvalGroup.Name || !this.approvalGroup.Name.trim();
  }

  nameFormControl = new FormControl("", [
    Validators.required,  // Required validation
    Validators.pattern(/^[a-zA-Z\s-][a-zA-Z\s0-9-]*$/),  // Pattern validation for only alphabets and spaces , hyphen
    this.specialCharacterValidator(),
  ]);

  // Custom validator function
  specialCharacterValidator() {
    return (control: FormControl) => {
      const value = control.value;
      if (!value) {
        return null; // No validation for empty values
      }
      // Check for spaces or hyphens followed by spaces
      if (/^\s/.test(value)) {
        return { specialCharactersNotAllowed: true };
      }

      const isValid = /^[a-zA-Z\s0-9]*(-[a-zA-Z\s0-9]+)*$/.test(value);

      if (isValid && !/^-/.test(value)) {
        return null;
      };
      return { specialCharactersNotAllowed: true };
    }
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  getNameRequiredErrorMessage() {
    return this.screenLabels['SelectUserErrorMsg'] ? this.screenLabels['SelectUserErrorMsg'] : "You must select a user" ;
  }
  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }
}
