import { Component, OnInit, ViewChild } from "@angular/core";
import {  MatDialog, MatAutocompleteSelectedEvent } from "@angular/material";
import { HttpErrorResponse } from "@angular/common/http";
import { AuthService } from "src/app/services/auth.service";
import { MatExpansionPanel } from "@angular/material";
import { GridApi } from "ag-grid-community";
import { SearchService } from "src/app/services/search.service";
import { UserService } from "src/app/services/user.service";
import { NotificationService } from "src/app/services/notification.service";
import { TemplateCategory } from "src/app/models/template-category,model";
import { DialogService } from "src/app/services/dialog.service";
import { CreateTeamComponent } from "./create-team-modal/create-team-modal.component";
import { UpdateTeamComponent } from "./edit-team-modal/edit-team-modal.component";
import { ViewTeamComponent } from "./view-team-modal/view-team-modal.component";
import { TeamService } from "src/app/services/team-service";
import { Team } from "src/app/models/team.model";
import { OrganizationService } from "src/app/services/organization.service";
import { SiteService } from "src/app/services/site.service";
import { MessageService } from 'src/app/services/message.service';
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "app-team-modal",
  templateUrl: "./team-modal.component.html",
  styleUrls: ["./team-modal.component.css"],
})
export class TeamComponent implements OnInit {
  @ViewChild("freePanel") freezePanel: MatExpansionPanel;

  private gridApi: GridApi;
  protected gridOptions: Object;
  protected isRowSelected: boolean = false;
  protected selectedRequest = null;
  protected selectedTeam: Team;
  protected restrictDeleteForGeneralTeam: boolean = true;
  public orgAdminOrganizations: Array<{
    OrganizationID: number;
    Name: string;
    StartDate: string;
    EndDate: string;
    IsAdmin: number;
  }> = [];
  protected selectedOrganizationID: number;
  
  protected rowData: Array<any> = [];

  protected step = 0;
  protected rolesLoaded: boolean = false;
  protected organizationsLoaded: boolean = false;
  protected authenticationCode: number;

  protected roleItems: Array<{
    RoleID: number;
    RoleName: string;
    Active: number;
  }> = [];
  protected roleBasket: Array<{
    RoleID: number;
    RoleName: string;
    Active: number;
    UserRoleID: number;
  }> = [];

  protected originalRoleBasket: Array<{
    RoleID: number;
    RoleName: string;
    Active: number;
    UserRoleID: number;
  }> = [];

  protected organizationItems: Array<{
    OrganizationID: number;
    Name: string;
    Active: number;
  }> = [];

  protected organizationBasket: Array<{
    UserOrganizationID: number;
    OrganizationID: number;
    Name: string;
    Active: number;
  }> = [];

  protected userName: string = "";

  protected originalOrganizationBasket: Array<{
    UserOrganizationID: number;
    OrganizationID: number;
    Name: string;
    Active: number;
  }> = [];
  protected appLanguage;
  public screenLabels: Array<string> = [];
  protected columnDefs :any;
  protected isActive: boolean = false;
  selectedOrganizationControl = new FormControl();
  filteredOrg: Observable<any[]>;
  public defaultColDef : object;
  constructor(
    protected dialog: MatDialog,
    protected authService: AuthService,
    protected searchService: SearchService,
    protected userService: UserService,
    protected teamService: TeamService,
    protected notificationService: NotificationService,
    protected dialogService: DialogService,
    private organizationService: OrganizationService,
    protected siteService:SiteService,
    protected messageService: MessageService,
  ) {
    this.gridOptions = {
      getRowClass: (params) =>{
        if(params.data.Active === 0){
          return 'row-inactive';
        }
      }
    }
    this.defaultColDef = {
      resizable: true,
  }
  }

  ngOnInit(): void {
    this.searchService.setPageName("Team");

//labels by message service
if (localStorage.getItem('TeamLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('TeamLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Team",
      "Team"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('TeamLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    this.selectedOrganizationID = this.userService.organizationID;
    this.userService.userOrganizations.forEach((element) => {
      if (element.IsAdmin == 1) {
        this.orgAdminOrganizations.push(element);
        this.filteredOrg = this.selectedOrganizationControl.valueChanges.pipe(
          startWith(""),
          map(value => typeof value === 'string' ? value : value?.Name),
          map(Name => Name ? this._filteredOrg(Name) : this.orgAdminOrganizations.slice())
        );
      }
    });

    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        this.rowData = this.searchService.searchRecords;
        this.rowData = this.rowData.filter(
          (f) => f.OrganizationID == this.userService.organizationID
        );
        this.gridApi.paginationGoToPage(0);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    // new search initiated
    this.searchService.searchInitiated.subscribe(() => {
      this.searchService.smartSearchPromise
        .then(() => {
          this.isRowSelected = false;
          this.rowData = this.searchService.searchRecords;
          this.rowData = this.rowData.filter(
            (f) => f.OrganizationID == this.userService.organizationID
          );
          this.gridApi.paginationGoToPage(0);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    });

    // subscribe to site changed
    this.siteService.siteChanged.subscribe((event: boolean) => {
      if(event){
        this.searchService
        .smartSearchMultiSiteAPI(null)
        .then(() => {
          this.rowData = this.searchService.searchRecords;   
          this.rowData = this.rowData.filter(
            (f) => f.OrganizationID == this.userService.organizationID
          );
          this.gridApi.paginationGoToPage(0);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
      }
    });

    //  Template Category  data changed
    this.teamService.teamDataChanged.subscribe((data: boolean) => {
      this.isRowSelected = false;
      this.searchService
        .smartSearchMultiSiteAPI(null)
        .then(() => {
          this.rowData = this.searchService.searchRecords;
          this.rowData = this.rowData.filter(
            (f) => f.OrganizationID == this.userService.organizationID
          );
          this.gridApi.paginationGoToPage(0);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    });
  }

  private _filteredOrg(value: string){
    return this.orgAdminOrganizations.filter(option => option.Name.toLowerCase().includes(value.toLowerCase()));
  }
  displayFn1(selectedoption: number) {
    if(selectedoption) {
    return this.orgAdminOrganizations.find(x => x.OrganizationID === selectedoption).Name;
    }
  }

  setColumnDef(){
    this.columnDefs = [
      {
        headerName: "",
        field: "",
        width: 1,
        cellStyle: { color: "#589bf8" },
        hide: false,
        checkboxSelection: true,
        resizable: false,
        sortable: false,
        lockPosition: true,
      },
  
      {
        headerName: this.screenLabels["TeamName"]? this.screenLabels["TeamName"]: "Team Name",
        field: "Name",
        sortable: true,
        filter: true,
        hide: false,
      },
      {
        headerName:this.screenLabels["Active"]? this.screenLabels["Active"]: "Active", 
        field: "Active",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["ModifiedBy"]? this.screenLabels["ModifiedBy"]: "Modified By",
        field: "ModifiedBy",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["ModifiedDate"]? this.screenLabels["ModifiedDate"]: "ModifiedDate",
        field: "ModifiedDate",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["CreatedBy"]? this.screenLabels["CreatedBy"]: "CreatedBy",
        field: "CreatedBy",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["CreatedDate"]? this.screenLabels["CreatedDate"]: "CreatedDate",
        field: "CreatedDate",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["CreatedByName"]? this.screenLabels["CreatedByName"]: "Created By Name",
        field: "CreatedByName",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["ModifiedByName"]? this.screenLabels["ModifiedByName"]: "Modified By Name",
        field: "ModifiedByName",
        hide: true,
        sortable: true,
      },
    ];
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDef()
}

  // invoked when ag-grid is initialized
  protected onGridReady(params): void {
    this.gridApi = params.api;
  }

  // make ag-rid columns fill their container
  protected onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  onMainHeaderClose() {
    this.freezePanel.open();
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  onCreateTemplatePass() {
    // Create Template pass
    const dialogRef = this.dialog.open(CreateTeamComponent, {
      disableClose: true,
      data: [],
      backdropClass: "backdropBackground",
    });
  }

  // sets the data of selected row
  onSelectionChanged($event) {
    const selectedRows: Team[] = $event.api.getSelectedRows();
    if (selectedRows.length) {
      this.selectedTeam = selectedRows[0];
      this.isRowSelected = true;
      if (selectedRows[0].AutoAddAllOrgUsers == 0) {
        this.restrictDeleteForGeneralTeam = false;
      } else {
        this.restrictDeleteForGeneralTeam = true;
      }
    } else {
      this.isRowSelected = false;
    }

    if (this.selectedTeam.Active === 0) {
      this.isActive = false;
    } else {
      this.isActive = true;
    }
  }

  //on organization dropdown change
  onOrganizationChange(event : MatAutocompleteSelectedEvent) {
    var data = event;

    // log organization change
    this.organizationService.logOrganizationChange(
      this.searchService.getOrganizationID(),
      data.option.value
    );

    this.searchService.setOrganizationID(data.option.value);

    this.userService.organizationID = data.option.value;
    this.userService.userOrganizations.forEach((element) => {
      if (element.OrganizationID == this.userService.organizationID)
        this.userService.IsAdmin = element.IsAdmin
          ? element.IsAdmin == 1
            ? 1
            : 0
          : 0;
    });
    this.orgAdminOrganizations = [];
    this.selectedOrganizationID = this.userService.organizationID;
    this.userService.userOrganizations.forEach((element) => {
      if (element.IsAdmin == 1) {
        this.orgAdminOrganizations.push(element);
        this.filteredOrg = this.selectedOrganizationControl.valueChanges.pipe(
          startWith(""),
          map(value => typeof value === 'string' ? value : value?.Name),
          map(Name => Name ? this._filteredOrg(Name) : this.orgAdminOrganizations.slice())
        );
      }
    });
    this.userService.organizationChanged.emit(true);
    this.searchService.setPageName("Team");

    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        this.rowData = [];
        this.rowData = this.searchService.searchRecords;
        this.rowData = this.rowData.filter(
          (f) => f.OrganizationID == this.userService.organizationID
        );
        this.gridApi.paginationGoToPage(0);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }

  // Invoked when Edit Team
  onEditTeam($event) {
    if (this.selectedTeam) {
      this.dialog.open(UpdateTeamComponent, {
        disableClose: true,
        data: this.selectedTeam,
      });
    }
  }

  // Invoked when Edit Template Category
  openTemplateCategory($event) {
    var selectedTemplateCategory: TemplateCategory = $event.data;
    this.dialog.open(ViewTeamComponent, {
      disableClose: true,
      data: selectedTemplateCategory,
    });
  }

  // Delete Team
  DeleteTeam() {
    var message: string = this.screenLabels["DeleteTeamConfirmMsg"]? this.screenLabels["DeleteTeamConfirmMsg"]: "Are you sure you want to Delete Team ?" ;
    this.dialogService
      .openConfirmDialog(this.screenLabels["DeleteTeamBtn"]? this.screenLabels["DeleteTeamBtn"]:"Delete Team", message)
      .afterClosed()
      .subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.teamService.deleteTeamAPI(this.selectedTeam).then(() => {
            this.notificationService.success(this.screenLabels["DeleteTeamSuccessMsg"]? this.screenLabels["DeleteTeamSuccessMsg"]: "Team was Deleted Successfully.");
          });
        }
      });
  }
}
