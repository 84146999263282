import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatExpansionPanel, MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { ApprovalConfig } from 'src/app/models/approval-config-model';
import { ApprovalGroup } from 'src/app/models/approval-group-model';
import { ApprovalType } from 'src/app/models/approval-type-model';
import { SiteService } from 'src/app/services/site.service';
import { ApprovalRequestConfigurationService } from 'src/app/services/approval-request-configuration.service';
import { RequiredFieldsService } from 'src/app/services/required-fields.service';
import { MessageService } from 'src/app/services/message.service';
import { map, startWith } from "rxjs/operators";
import { Observable } from "rxjs";
import { NotificationService } from 'src/app/services/notification.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { SearchService } from 'src/app/services/search.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-create-approval-request-configuration-modal',
  templateUrl: './create-approval-request-configuration-modal.component.html',
  styleUrls: ['./create-approval-request-configuration-modal.component.css']
})
export class CreateApprovalRequestConfigurationModalComponent implements OnInit {

  protected step = 0;
  protected approvalType: ApprovalType;
  protected approvalGroups: ApprovalGroup[];
  protected tableFunctions: any[];
  protected emailTemplates: any[];
  protected addedApprovalConfigs: any[] = [];
  protected approvalConfig: any;
  // nameFormControl = new FormControl("", [Validators.required]);
  usersList: Array<any> = [];
  AvailableUsersList: Array<String> = [];
  selectedUserList: Array<String> = [];
  configData: any;
  approvalRequest: any;
  requestModificationAllowed = false;
  tableFunctionreturnValues: any[] = [];
  tableFunctionreturnValuesFiltered: any[] = [];
  conditionReturnValueList: any[] = [];
  selectedConditionReturnValueList: any[] = [];
  emailTemplateFiltered: any[] = [];
  positions: Array<number> = [1, 2, 3, 4, 5];
  protected groupEmailDropdown = "No Email Required";
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  @ViewChild("approvalForm") approvalForm: NgForm;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  requestAutoApproval = true;
  protected groupsForLoggedInSite: ApprovalGroup[];
  protected groupsForSiteIDZero: ApprovalGroup[];
  approverEmailTemplates: any[] = [];
  approverTemplates: any[] = [];
  private selectedApprovalRequest: any;
  protected noEmailTemplate = "No Email Required";
  protected ApprovalType ;
  approvalRequiredFormControl = new FormControl();
  filteredApprovalRequired: Observable<any[]>;
  approvalGroupTypeControl = new FormControl();
  approvalGroupTypeOptions: string[] = ['Site', 'System'];
  filteredOptions: Observable<string[]>;
  filteredApprovalGroup: Observable<any[]>;
  approvalGroupFormControl = new FormControl();
  filteredApprovalNeededEmail: Observable<any[]>;
  approvalNeededEmailFormControl = new FormControl();
  filteredApprovalNoLongerNeededEmail: Observable<any[]>;
  approvalNoLongerNeededEmailFormControl = new FormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<CreateApprovalRequestConfigurationModalComponent>,
    protected approvalRequestConfigService: ApprovalRequestConfigurationService,
    protected requiredFieldsService: RequiredFieldsService,
    private siteService: SiteService,
    protected userService: UserService,
    protected messageService: MessageService,
    protected notificationService: NotificationService,
    protected searchService: SearchService,
  ) { 
    this.filteredOptions = this.approvalGroupTypeControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  ngOnInit(): void {
    const data = { ...this.data };
    this.approvalType = this.data.approvalType;
    this.approvalRequest = data.approvalConfig;
    const dropdownValues = data.dropdownValues;
    this.approvalGroups = dropdownValues.approvalGroups;
    this.filteredApprovalGroup = this.approvalGroupFormControl.valueChanges.pipe(
      startWith(""),
      map(value => typeof value === 'string' ? value : value?.Name),
      map(Name => Name ? this._filteredApprovalGroup(Name) : this.approvalGroups.slice())
    );
    this.tableFunctions = dropdownValues.tableFunctions;
    this.filteredApprovalRequired = this.approvalRequiredFormControl.valueChanges.pipe(
      startWith(""),
      map(value => typeof value === 'string' ? value : value?.FunctionName),
      map(FunctionName => FunctionName ? this._filteredApprovalRequired(FunctionName) : this.tableFunctions.slice())
    );
    this.tableFunctionreturnValues = dropdownValues.tableFunctionReturnValues
    this.emailTemplates = dropdownValues.emailTemplates;
    const approverEmailTemplates = this.emailTemplates;
    this.approverEmailTemplates = approverEmailTemplates.filter(site => site.SiteID === this.userService.selectedSiteID)
    this.approverEmailTemplates.push({Description: "No Email Required",EmailTemplateID : 0});
    this.filteredApprovalNeededEmail = this.approvalNeededEmailFormControl.valueChanges.pipe(
      startWith(""),
      map(value => typeof value === 'string' ? value : value?.Description),
      map(Description => Description ? this._filteredApprovalNeededEmail(Description) : this.approverEmailTemplates.slice())
    );
    this.filteredApprovalNoLongerNeededEmail = this.approvalNoLongerNeededEmailFormControl.valueChanges.pipe(
      startWith(""),
      map(value => typeof value === 'string' ? value : value?.Description),
      map(Description => Description ? this._filteredApprovalNoLongerNeededEmail(Description) : this.approverEmailTemplates.slice())
    );
    // this.approvalGroups = dropdownValues.approvalGroups;
    this.approvalConfig = new ApprovalConfig(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    this.approvalConfig.ApprovalTypeID = this.approvalRequest.ApprovalTypeID;

    //labels by message service
    if (localStorage.getItem('CreateApprovalRequestConfigLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('CreateApprovalRequestConfigLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Create Approval Request Configuration Modal",
        "ApprovalRequestConfiguration"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('CreateApprovalRequestConfigLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }
    this.approvalRequestConfigService.getApprovalGroupBySiteID(this.siteService.selectedSiteID, this.approvalConfig.ApprovalTypeID).subscribe((res: HttpResponse<any>) => {
      let response = res.body;
      this.groupsForLoggedInSite = response;
    }, error => {
      console.log(error);
    });
    this.approvalRequestConfigService.getApprovalGroupBySiteID(0, this.approvalConfig.ApprovalTypeID).subscribe((res: HttpResponse<any>) => {
      let response = res.body;
      this.groupsForSiteIDZero = response;
    }, error => {
      console.log(error);
    });
  }
  
  displayFn4(selectedoption: number) {
    if (selectedoption) {
      const selectedApproverEmailTemplates = this.approverEmailTemplates.find(x => x.EmailTemplateID === selectedoption);
      return selectedApproverEmailTemplates ? selectedApproverEmailTemplates.Description : '';
    }
    return '';
  }
  
  
  private _filteredApprovalNeededEmail(value: string) {
    return this.approverEmailTemplates.filter(option => option.Description.toLowerCase().includes(value.toLowerCase()));
  }
  displayFn5(selectedoption: number) {
    if (selectedoption) {
      const selectedApproverEmailTemplate = this.approverEmailTemplates.find(x => x.EmailTemplateID === selectedoption);
      return selectedApproverEmailTemplate ? selectedApproverEmailTemplate.Description : '';
    }
    return '';
  }
  
  private _filteredApprovalNoLongerNeededEmail(value: string) {
    return this.approverEmailTemplates.filter(option => option.Description.toLowerCase().includes(value.toLowerCase()));
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.approvalGroupTypeOptions.filter(option => option.toLowerCase().includes(filterValue));
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }
   
  displayFn3(selectedoption: number) {
    if (selectedoption) {
      const selectedApprovalGroups = this.approvalGroups.find(x => x.ApprovalGroupID === selectedoption);
      return selectedApprovalGroups ? selectedApprovalGroups.Name : '';
    }
    return '';
  }


private _filteredApprovalGroup(value: string) {
  return this.approvalGroups.filter(option => option.Name.toLowerCase().includes(value.toLowerCase()));
}
displayFn1(selectedoption: number) {
  if (selectedoption) {
    const selectedTableFunctions = this.tableFunctions.find(x => x.TableFunctionID === selectedoption);
    return selectedTableFunctions ? selectedTableFunctions.FunctionName : '';
  }
  return '';
}

private _filteredApprovalRequired(value: string) {
  return this.tableFunctions.filter(option => option.FunctionName.toLowerCase().includes(value.toLowerCase()));
}

ngAfterViewInit() {
    // setting up the required fields
  setTimeout(() => {
    this.requiredFieldsService.setRequiredFields(
      'Add Approval Request',
      this.approvalForm
    );
  });
}

 
  

  onNoClick(): void {
    this.dialogRef.close();
  }

  onRemoveConfig(config) {
    const index: number = this.addedApprovalConfigs.indexOf(config);
    if (index !== -1) {
      this.addedApprovalConfigs.splice(index, 1);
    }
  }

  onSaveChanges() {
    let tablefunctionvalues = [];
    if (this.selectedConditionReturnValueList !== null) {
      this.selectedConditionReturnValueList.forEach(fun => {
        this.tableFunctionreturnValuesFiltered.forEach(ele => {
          if (ele.Description === fun) {
            tablefunctionvalues.push({ TableFunctionReturn: ele.TableFunctionReturnValuesID, Value: ele.Value });
          }
        });
      });
    }
    if (this.approvalConfig.ApprovalEmailTemplateID == this.groupEmailDropdown) {
      this.approvalConfig.ApprovalEmailTemplateID = null;
    }
    if (this.approvalConfig.CancelationEmailTemplateID == this.groupEmailDropdown) {
      this.approvalConfig.CancelationEmailTemplateID = null;
    }
    this.approvalRequest.ApprovalRequestConfig.push({
      ApprovalRequestConfigID: 0,
      Description: this.approvalConfig.Description,
      ApprovalGroupID: this.approvalConfig.ApprovalGroupID,
      ApprovalPosition: this.approvalConfig.Position,
      TableFunctionID: this.approvalConfig.TableFunctionID,
      TableFunctionReturn: tablefunctionvalues,
      ApprovalEmailTemplateID: this.approvalConfig.ApprovalEmailTemplateID,
      CancelationEmailTemplateID: this.approvalConfig.CancelationEmailTemplateID,
      RequestModificationAllowed: this.requestModificationAllowed ? 1 : 0,
      RequesterAutoApprovalEnabled: this.requestAutoApproval ? 1 : 0,
      Active: 1
    })
    this.approvalConfig = new ApprovalConfig(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    this.approvalConfig.ApprovalTypeID = this.approvalRequest.ApprovalTypeID;
    this.approvalRequest.SiteID = this.siteService.selectedSiteID;
    this.approvalRequest.ApprovalTypeID = this.approvalType.ApprovalTypeID;
    this.approvalRequestConfigService.createApprovalRequestConfig(this.approvalRequest);
    this.onNoClick();
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  // getNameRequiredErrorMessage() {
  //   return this.nameFormControl.hasError("required")
  //     ? "You must enter a value"
  //     : " ";
  // }
  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }

  approvalRequiredSelectChange(event) {
    this.tableFunctionreturnValuesFiltered = this.tableFunctionreturnValues.filter(val => val.TableFunctionID === event.option.value);
    this.conditionReturnValueList = [];
    this.tableFunctionreturnValuesFiltered.forEach(element => {
      this.conditionReturnValueList.push(element.Description);
    });
    this.selectedConditionReturnValueList = [];
  }

  onApprovalGroupTypeSelect(event) {
    this.approvalConfig.ApprovalGroupID = null;
    if (event.option.value === "Site") 
      this.approvalGroups = this.groupsForLoggedInSite;
    else if (event.option.value === "System") 
      this.approvalGroups = this.groupsForSiteIDZero;
  }
}

