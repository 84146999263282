export class OrganizationModel {
  public CreatedByName: string;
  public ModifiedByName: string;

    constructor(
        public OrganizationID: number,
        public StartDate: string,
        public EndDate: string,
        public Internal: number,
        public SelfRegAllowed: number,
        public CreatedBy: number,
        public CreatedDate: Date,
        public ModifiedBy: number,
        public ModifiedDate: Date,
        public Name: string,
        public Active: number,
    ) {
      this.Active = 1;
    }
  }
  