import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { AuthService } from "../services/auth.service";
import { Router } from "@angular/router";
import { SearchService } from "../services/search.service";
import { ViewSelectorService } from "../services/view-selector.service";

import { MatDialogConfig, MatDialog } from "@angular/material";
import { UserService } from "../services/user.service";
import { SearchFilterModalComponent } from "./modals/search-filter.modal.component";
import { CreateBadgeHolderPassModalComponent } from "../create-badge-holder-pass/create-badge-holder-pass-modal.component";
import { NewpassModalComponentMobile } from "../newpass-modal/mobile/newpass-modal.component.mobile";
import { NewpassModalComponentDesktop } from "../newpass-modal/desktop/newpass-modal.component.desktop";
import { EntryPoint } from "../models/entry-point.model";
import { EntryPointService } from "../services/entry-point.service";
import { ViewpassModalComponentDesktop } from "../viewpass-modal/desktop/viewpass-modal.component.desktop";
import { DatePipe } from "@angular/common";
import { PassService } from "../services/pass.service";
import { BuildingService } from "../services/building.service";
import { DialogService } from "../services/dialog.service";
import { OktaAuthService } from "@okta/okta-angular";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { NotificationService } from "../services/notification.service";
import { HostListener } from "@angular/core";
import { SpinnerService } from "../services/spinner.service";
import { Site, SiteService } from "../services/site.service";
import { environment } from "src/environments/environment";
import moment from "moment-timezone";
// import { default as _rollupMoment } from "moment";
import { UserActionsService } from "../services/user-actions.service";
import { UtilityService } from "../services/utility.service";
import { ClientInfoService } from "../services/client-info.service";
import { MessageService } from "../services/message.service";

@Component({
  selector: "app-nav-bar",
  templateUrl: "./desktop/nav-bar.component.desktop.html",
  styleUrls: ["./desktop/nav-bar.component.desktop.css"],
})
export abstract class NavBarComponent implements OnInit {
  // dateNow: Date = new Date();
  protected isMobile = false;
  panelOpenState: boolean = false;
  public menuOpen: boolean;
  public menuClosed: boolean;
  public searchBtnDisabled: boolean;
  private today: Date = new Date(); // DO NOT replace with utilService.getCurrentSiteDT()

  protected entryPoints: EntryPoint[] = [];
  protected defaultEntryPoint: number = 0;
  protected userCheckIn: boolean = false;
  protected callInUser: boolean = false;
  @ViewChild("searchField") searchField: ElementRef;
  protected config;
  protected selectedSite: Site;
  protected version: string;
  protected logoUrl: string;
  protected dateNow: string = "";


  protected sites: Site[];
  private previouslySelectedSiteID: number;
  newNameEntrypoint: any;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  protected capacityMessage;

  constructor(
    private spinnerService: SpinnerService,
    protected authService: AuthService,
    private router: Router,
    public searchService: SearchService,
    protected viewSelectorService: ViewSelectorService,
    protected dialog: MatDialog,
    public userService: UserService,
    protected entryPointService: EntryPointService,
    private datePipe: DatePipe,
    private passService: PassService,
    private buildingService: BuildingService,
    private dialogService: DialogService,
    private oktaService: OktaAuthService,
    private notificationService: NotificationService,
    private siteService: SiteService,
    private userActionsService: UserActionsService,
    private clientInfoService: ClientInfoService,
    protected messageService: MessageService
  ) {
    this.searchBtnDisabled = false;
    this.version = environment.VERSION + "-" + environment.environment; // read the application version

    this.getSiteDate();

    this.siteService.siteChanged.subscribe((d) => {
      this.getSiteDate();
    });

    this.userService.userDetailsLoaded.subscribe((dataLoaded: boolean) => {
      this.userCheckIn = this.userService.userCheckIn;
      this.getDefaultEntryPoint();
    });
  }

  async getDefaultEntryPoint() {
    await this.entryPointService.parseGetEndpointAPI();
    this.entryPoints = this.entryPointService.getEntryPoints();
    this.defaultEntryPoint = this.userService.userCheckedInEntryPoint;
  }

  ngOnInit() {
    this.isMobile = this.viewSelectorService.getIsMobile();
    //const oktaUserEmail = (await this.oktaService.getUser()).email;
    this.authService.userChangedToCallInUser.subscribe((event: boolean) => {
      if (event) {
        if (this.authService.validCaller) {
          this.callInUser = true;
        } else {
          this.callInUser = false;
        }
      }
    });

    // load available sites & alphabitize
    let siteList = this.siteService.getSites();
    this.sites = siteList.filter(ele =>
      (ele.IsExternal == 1 && ele.UserStatus == "Registered") ||
      (ele.IsExternal == 0 && ele.UserStatus == "Registered") ||
      (ele.IsExternal == 0 && ele.UserStatus == "Expired")
    ).map(e => { return e; });

   
    this.sites.sort(function (a, b) {
      var nameA = a.Name.toLowerCase(), nameB = b.Name.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });

    //labels by message service
    if (localStorage.getItem('NavbarLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('NavbarLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Nav Bar",
        "NavBar"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('NavbarLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    // get the default site
    this.selectedSite = this.siteService.getSelectedSite();
    this.logoUrl = 'this.selectedSite.LogoPath';

    this.siteService.siteChanged.subscribe((changed: boolean) => {
      this.selectedSite = this.siteService.getSelectedSite();
      this.logoUrl = this.selectedSite.LogoPath;
    });

  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }
  // opens new Badge modal
  createBadgeHolderPass() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.backdropClass = "backdropBackground";
    if (this.isMobile === true) {
      this.dialog.open(CreateBadgeHolderPassModalComponent, dialogConfig);
    } else {
      this.dialog.open(CreateBadgeHolderPassModalComponent, dialogConfig);
    }
  }

  onLogOut() {
    this.authService.logout();
  }
  // opens new pass modal
  createPass() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;

    if (this.isMobile === true) {
      this.dialog.open(NewpassModalComponentMobile, dialogConfig);
    } else {
      this.dialog.open(NewpassModalComponentDesktop, dialogConfig);
    }
  }
  //
  togglePanel() {
    this.panelOpenState = false;
  }
  openedPanel() {
    this.panelOpenState = true;
  }

  // open search filter modal
  onFilterBtnClick() {
    const dialogRef = this.dialog.open(SearchFilterModalComponent, {
      disableClose: false,
      data: { pageName: this.searchService.getPageName() },
      backdropClass: "backdropBackground",
    });
  }
  formatDate(date: any): string {
    const formattedDate = this.datePipe.transform(date, "yyyy-MM-dd");
    return formattedDate ? formattedDate : null;
  }

  // invoked when search button is clicked
  onSearchBtnClick() {
    this.spinnerService.setIsLoading(true);
    const key = this.searchField.nativeElement.value.trim()
      ? this.searchField.nativeElement.value.trim()
      : null;
    this.searchService
      .smartSearchMultiSiteAPI(key)
      .then(() => {
        var rowData = this.searchService.searchRecords;
        rowData.forEach((element) => {
          var expTime = moment(element.EstDepartureTime).format("HH:mm");
          element.StartTime = this.formatDate(element.StartTime);
          element.EndTime = this.formatDate(element.EndTime);
          var endDate = element.EndTime;
          var expired;
          if (expTime !== null && endDate !== null) {
            var fullDate = endDate.concat(" ", expTime);
            if (this.today > new Date(fullDate) && element.Active == 1) {
              expired = true;
              element.PassStatus = "Expired";
            } else {
              expired = false;
            }
          }
          if (element.Active == 1 && !expired) {
            element.PassStatus = "Active";
          } else if (element.Active == 0) {
            element.PassStatus = "Inactive";
          }
        });
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    this.searchService.searchInitiated.emit(true);
  }
  clearSearchField() {
    this.searchField.nativeElement.value = "";
    this.onSearchBtnClick();
  }


  private getSiteDate() {
    this.dateNow = moment
      .tz(this.siteService.getSelectedSite().SiteTimeZone)
      .format("dddd, MMMM D, YYYY");
  }

  // the method is invoked when the site is changed
  protected async siteChanged(siteID: number) {
    const changeSiteLabel = this.screenLabels["ChangeSiteMsg"] ? this.screenLabels["ChangeSiteMsg"] : "Change Site";
    const changeSiteMsg = this.screenLabels["ChangeSiteWarningMsg"] ? this.screenLabels["ChangeSiteWarningMsg"] : "Are you sure you want to change the site. Would you like to proceed?";

    const confirmDialog = this.dialogService.openConfirmDialog(
      changeSiteLabel,
      changeSiteMsg
    );

    confirmDialog.afterClosed().subscribe(async (confirm: boolean) => {

      if (confirm) {
        this.siteService.selectedSiteID = siteID;
        this.userService.selectedSiteID = siteID;
        // save the default site preference
        try {
          await this.siteService.setDefaultSiteAPI(siteID);
          this.previouslySelectedSiteID = this.siteService.selectedSiteID;

          this.notificationService.success(this.siteService.message);
        } catch (err) {
          const errorMessage: HttpErrorResponse = err;
          this.notificationService.failure(errorMessage.message);
        }

        //On change of Site calling the site settings API
        this.siteService.getSiteSettingsAPI().then((success: any) => {
        }).catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
        this.userService.userCheckedInEntryPoint = null;
        this.searchService.setEntryPointID(null);

        // emit site change
        this.siteService.siteChanged.emit(true);
        this.userService.userFunctions = [];

        // this.siteService.getUserRoleFunctionBySite()
        this.userService.getRoleFunctionArray(this.userService.userID, this.siteService.selectedSiteID).subscribe((ele: HttpResponse<any>) => {
          ele.body[0].RoleFunctionArray.forEach((element) => {
            this.userService.userFunctions[element.Tag] = true;
          })
        })
        this.userService.userDetailsLoaded.emit(true);
        await this.siteService.getSiteSettingsAPI();
        let cloudValue = (this.siteService.siteSettings.SystemSettings.filter(x => x.Name === "CloudPrintingEnabled").map(x => x.Value))[0];
        if(cloudValue === "1") localStorage["isCloudPrinting"] = true;
        else if(cloudValue === "0") localStorage["isCloudPrinting"] = false;
        
        let phoneValue = (this.siteService.siteSettings.SystemSettings.filter(x => x.Name === "Phone").map(x => x.Value))[0];
        if(phoneValue === "USA") localStorage["isPhoneUSA"] = true;
        else if(phoneValue === "International") localStorage["isPhoneUSA"] = false;

        this.passService.getUserTemplateCategoriesAPI(this.userService.userID, this.userService.organizationID);
        this.passService.checkIsIgnoreLocksAPI(this.userService.userID, this.userService.organizationID, true);


        await this.clientInfoService.initialize();

        if (this.siteService.isWIPSite()) {
          this.router.navigate(['/site-maintenance']);
        }
        else {
          this.router.navigate(["/dashboard"]);
        }

      } else {
        this.siteService.selectedSiteID = this.previouslySelectedSiteID;
        this.userService.selectedSiteID = this.previouslySelectedSiteID;
      }
    });
  }

  public isActive(base: string): boolean {
    return this.router.url.includes(`/${base}`);
 }

}
