import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';
import { AuthService } from '../services/auth.service';
import { LoginRegistrationService } from '../services/login-registration.service';
import { NotificationService } from '../services/notification.service';
import { UserInvitationService } from '../services/user-invitation.service';
import { UserService } from '../services/user.service';
import { MessageService } from '../services/message.service';
import { SiteService } from '../services/site.service';
import { CustomSnackbarComponent } from '../snack-bar/custom-snackbar/custom-snackbar.component';

@Component({
  selector: 'app-user-access-request',
  templateUrl: './user-access-request.component.html',
  styleUrls: ['./user-access-request.component.css']
})
export class UserAccessRequestComponent implements OnInit {

  protected sites;
  protected selectedSite;
  protected orgsLoaded;
  protected organizations;
  protected selectedOrganizationID;
  protected enableLoginBtn: boolean = false;
  protected showExpiredMsg: boolean = false;
  protected showOrganizations: boolean = false;
  protected enableRegisterBtn: boolean = false;
  protected showPendingMsg: boolean = false;
  protected loggedinUserID;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  public ACKResponse: any;
  protected showAcknowledgeForm: boolean = false;
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "bottom";

  constructor(
    protected loginRegistrationService: LoginRegistrationService,
    protected notificationService: NotificationService,
    protected authService: AuthService,
    protected userService: UserService,
    protected userInvitationService: UserInvitationService,
    private _snackBar: MatSnackBar,
    protected messageService: MessageService,
    protected siteService: SiteService
  ) { }

  ngOnInit(): void {

    //labels by message service
    if (localStorage.getItem('CreateAccessRequestLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('CreateAccessRequestLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Create Access Request",
        "CreateAccessRequest"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('CreateAccessRequestLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    this.loggedinUserID = Number(localStorage.getItem('userAuthorizeAPIUserID'));
    this.loginRegistrationService.getSiteListAPI(this.loggedinUserID).subscribe((res: any) => {
      this.sites = res['body'];
    });

  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  onSiteChange(event) {
    this.selectedSite = event.value;

    //if user is Registered to that site,then allow him to login
    if (this.selectedSite.UserStatus === 'Registered') {
      this.enableLoginBtn = true;
      this.showExpiredMsg = false;
      this.showOrganizations = false;
      this.enableRegisterBtn = false;
      this.showPendingMsg = false;
    }
    //if user is Unregistered to that site,then allow him to select org and then register
    else if (this.selectedSite.UserStatus === 'Unregistered') {
      this.showOrganizations = true;
      this.enableLoginBtn = false;
      this.showExpiredMsg = false;
      this.enableRegisterBtn = true;
      this.showPendingMsg = false;
      this.loginRegistrationService.getSelfRegOrganizations(this.selectedSite.SiteID).subscribe((res: any) => {
        let response = res.body;
        this.orgsLoaded = false;

        this.organizations = response;
      })
      this.selectedOrganizationID = null;
    }
    //if user is expired to that site,then show him error msg
    else if (this.selectedSite.UserStatus === 'Expired') {
      this.showExpiredMsg = true;
      this.enableLoginBtn = false;
      this.showOrganizations = false;
      this.enableRegisterBtn = false;
      this.showPendingMsg = false;
    } 
     //if user is pending to that site,then show him error msg
     else if(this.selectedSite.UserStatus === 'Processing') {
      this.showPendingMsg = true;
      this.showExpiredMsg = false;
      this.enableLoginBtn = false;
      this.showOrganizations = false;
      this.enableRegisterBtn = false;
    }
  }

  onOrganizationSelect(event) {
    this.selectedOrganizationID = event.value;
  }

  onCancel() {
    this.authService.logout();
  }

  async onLogin() {
    let listValue = this.selectedSite.SiteID;
    if(this.selectedSite.IsExternal == 1) {
      this.authService.externalUserActiveBadge(this.loggedinUserID, this.selectedSite.SiteID).subscribe((res: HttpResponse<any>) => {
        if (res.body[0].Active >= 1) {
          this.getACKNeededInfo();
        }
        else {
          this.showExpiredMsg = true;
          this.enableLoginBtn = false;
          this.showOrganizations = false;
          this.enableRegisterBtn = false;
          this.showPendingMsg = false;
        }
      });
    } 
    else {
      this.getACKNeededInfo();
    }
  }
  
  onRegister() {
    let emailID = localStorage.getItem("loggedInEmailID");

    const body = {
      UserIDToSubmit: this.loggedinUserID,
      SiteID: this.selectedSite.SiteID,
      UserID: this.loggedinUserID,
      OrganizationID: this.selectedOrganizationID,
      RequesterComments: null,
      Email: emailID,
      FirstName: null,
      LastName: null,
      Phone: null,
      Role : [],
      Team: []
    };

    this.userInvitationService
      .submitUserRoleRequestAPI(body)
      .then((s) => {
        //this.notificationService.success("Your access request is being submitted. You will be notified once the request is processed.");
        let message = this.screenLabels["AccessRequestSubmitMsg"] ? this.screenLabels["AccessRequestSubmitMsg"]: "Your access request is being submitted. You will be notified once the request is processed.";
        this._snackBar.open(message, "", {
          duration: 12000,
          panelClass: ["red-snackbar"],
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
        this.authService.logout();
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

  }

  getACKNeededInfo() {
    //show ACK form
    this.authService.isAcknowledgementNeededAPI(this.loggedinUserID, this.selectedSite.SiteID).subscribe(async (resp: any) => {
      this.ACKResponse = resp.body[0];
      if(this.ACKResponse.isAcknowledgementNeeded === 1) {
        this.showAcknowledgeForm = true;
      }
      else if(this.ACKResponse.isAcknowledgementNeeded === 0) {
        this.getAckUserSettings(false);
        this.siteService.setDefaultSiteAPI(this.selectedSite.SiteID, this.loggedinUserID);
        this.authService.setVerifyUser(true);
      }
    },
    err => {
      this.authService.logout();
      let config = new MatSnackBarConfig();
      config.verticalPosition = this.verticalPosition;
      config.horizontalPosition = this.horizontalPosition;
      config.panelClass = ["red-snackbar"];
      this._snackBar.openFromComponent(CustomSnackbarComponent, config)
    });
  }

  onACKaccept(event) {
    //saving user settings
    this.getAckUserSettings(event);
    //calling normal flow
    this.siteService.setDefaultSiteAPI(this.selectedSite.SiteID, this.loggedinUserID);
    this.authService.setVerifyUser(true);
  }

  async getAckUserSettings(event: boolean) {
    await this.authService.getPolicyAcknowledgementUserSettings(this.loggedinUserID).subscribe(async(res: HttpResponse<any>) => {
       let logoncount = event===true ? 1 : Number(res.body.logonCount[0].Value) + 1;
       let logonSettingsID = res.body.logonCount[0].SettingsID;
       let isACKAccepted = event===true ? 1 : 0;
       this.setAckUserSettings(logonSettingsID, logoncount, isACKAccepted);
     })
   }
 
   async setAckUserSettings(logonSettingsID, logoncount, isACKAccepted) {
     await this.authService.policyAcknowledgementUserSettingsUpdate(this.loggedinUserID, logonSettingsID, logoncount.toString(), isACKAccepted)
   }

}
