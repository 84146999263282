import { ViewSelectorService } from "./../../services/view-selector.service";
import { NewpassModalComponent } from "../newpass-modal.component";
import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PassService } from "src/app/services/pass.service";
import { BuildingService } from "src/app/services/building.service";
import { UserService } from "src/app/services/user.service";
import { DatePipe } from "@angular/common";
import { NotificationService } from "src/app/services/notification.service";
import { EntryPointService } from "src/app/services/entry-point.service";
import { EntryPointGroupService } from "../../services/entry-point-group.service";
import { DialogService } from "src/app/services/dialog.service";
import { SpinnerService } from "../../services/spinner.service";
import { OrganizationService } from "src/app/services/organization.service";
import { SearchService } from "src/app/services/search.service";
import { ParkingLotService } from "src/app/services/parking-lot.service";
import { AuthService } from "src/app/services/auth.service";
import { RequiredFieldsService } from "../../services/required-fields.service";
import { TemplateCategoryService } from "src/app/services/template-category-service";
import { PassTemplateService } from "src/app/services/pass-template-service";
import { TeamService } from "src/app/services/team-service";
import { UtilityService } from "src/app/services/utility.service";
import { SiteService } from "src/app/services/site.service";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material";
import {
  MatDatePickerAdapter,
  CUSTOM_DATE_FORMATS,
} from "src/app/common/mat-date-picker-formatter/mat-date-picker-formatter";
import { ISystemSettingsGetAPIResponse } from 'src/app/models/interfaces/system-settings-get-api.model';
import { TimezoneService } from 'src/app/services/timezone.service';
import { HolidayService } from "src/app/services/holiday-calendar.service";
import { MessageService } from "src/app/services/message.service";
import { FormBuilder } from "@angular/forms";

@Component({
  selector: "app-newpass-modal-mobile",
  templateUrl: "./newpass-modal.component.mobile.html",
  styleUrls: ["./newpass-modal.component.mobile.css"],
  providers: [
    { provide: DateAdapter, useClass: MatDatePickerAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export class NewpassModalComponentMobile extends NewpassModalComponent {
  constructor(
    dialogRef: MatDialogRef<NewpassModalComponent>,
    _snackBar: MatSnackBar,
    service: ViewSelectorService,
    passService: PassService,
    buildingService: BuildingService,
    userService: UserService,
    datePipe: DatePipe,
    notificationService: NotificationService,
    entryPointService: EntryPointService,
    entryPointGroupService: EntryPointGroupService,
    dialogService: DialogService,
    spinnerService: SpinnerService,
    organizationService: OrganizationService,
    searchService: SearchService,
    parkingLotService: ParkingLotService,
    authService: AuthService,
    requiredFieldService: RequiredFieldsService,
    templateCategoryService: TemplateCategoryService,
    passTemplateService: PassTemplateService,
    teamService: TeamService,
    utilService: UtilityService,
    siteService: SiteService,
    timezoneService: TimezoneService,
    holidayService: HolidayService,
    messageService: MessageService,
  ) {
    super(
      dialogRef,
      _snackBar,
      service,
      passService,
      buildingService,
      userService,
      datePipe,
      notificationService,
      entryPointService,
      entryPointGroupService,
      dialogService,
      spinnerService,
      organizationService,
      searchService,
      parkingLotService,
      authService,
      requiredFieldService,
      templateCategoryService,
      passTemplateService,
      teamService,
      utilService,
      siteService,
      timezoneService,
      holidayService,
      messageService,
    );
  }
}
