import { Component, OnInit } from '@angular/core';
import { SiteService } from 'src/app/services/site.service';
import { AuthService } from 'src/app/services/auth.service';
import { OktaAuthService } from '@okta/okta-angular';

@Component({
  selector: 'app-wipsite',
  templateUrl: './wipsite.component.html',
  styleUrls: ['./wipsite.component.css']
})
export class WIPSiteComponent implements OnInit {
  public authResponse: any;
  public aliasResponse: any;
  protected availableSiteList;
  protected onDropdownValue: number;
  protected onDropdownSelected: boolean = false;
  protected enableSubmit: boolean = false;
  protected enableVerifyUser: boolean = false;

  constructor(
    private siteService: SiteService,
    private authService: AuthService,
    private oktaAuthService: OktaAuthService

  ) { }

 async ngOnInit() {
    const oktaEmail = await (await this.oktaAuthService.getUser()).email;
    this.availableSiteList = this.siteService.availableSiteList;
    console.log(this.availableSiteList)

    this.authService.checkUserAliasEmailID(oktaEmail).subscribe(async (res: any) => {
      this.aliasResponse = res.body;
      localStorage["loggedInEmailID"] = this.aliasResponse[0].EmailID;
      if (this.aliasResponse[0].EmailID) {
        this.authService.userSiteListAuthorize(this.aliasResponse[0].EmailID).subscribe(async (res: any) => {
          this.authResponse = res.body;
        })
      }
    })
  }
  async onSelect(event) {
    this.onDropdownValue = event.value;
    this.onDropdownSelected = true;
    this.enableSubmit = true;
  }
  async onSaveChanges() {
    if (this.authResponse[0].IsUserInternal) {
      await this.siteService.setDefaultSiteAPI(this.onDropdownValue, this.authResponse[0].UserID);
      this.enableVerifyUser = true;
      this.authService.setVerifyUser(this.enableVerifyUser);
    }
    else if (!this.authResponse[0].IsUserInternal) {
      await this.siteService.setDefaultSiteAPI(this.onDropdownValue, this.authResponse[0].UserID);
    //  this.externalBadgeAPI(this.onDropdownValue);
    }
  }
  
}
