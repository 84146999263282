import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatExpansionPanel,
} from "@angular/material";
import { FormControl } from "@angular/forms";
import { UpdateEntryPointZoneComponent } from "../update-entry-point-zone/update-entry-point-zone.component";
import { EntryPointGroup } from "../../../models/entry-point-group.model";

import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { NotificationService } from "../../../services/notification.service";
import { HttpErrorResponse } from "@angular/common/http";
import { EntryPointService } from "../../../services/entry-point.service";
import { EntryPoint } from "../../../models/entry-point.model";
import { EntryPointGroupService } from "../../../services/entry-point-group.service";
import { EntryPointZone } from "src/app/models/entry-point-zone.model";
import { EntryPointZoneService } from "src/app/services/entry-point-zone.service";
import { SiteService } from "src/app/services/site.service";
import { MessageService } from "src/app/services/message.service";
@Component({
  selector: "app-view-entry-point-zone",
  templateUrl: "./view-entry-point-zone.component.html",
  styleUrls: ["./view-entry-point-zone.component.css"],
})
export class ViewEntryPointZoneComponent implements OnInit {
  protected step = 0;
  protected entryPointZone: EntryPointZone;
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  protected appLanguage;
  public screenLabels: Array<string> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EntryPointZone,
    protected dialogRef: MatDialogRef<UpdateEntryPointZoneComponent>,
    protected entryPointZoneService: EntryPointZoneService,
    protected notificationService: NotificationService,
    private siteService: SiteService,
    protected messageService: MessageService,
  ) {}

  ngOnInit(): void {

    //labels by message service
if (localStorage.getItem('ViewEntryPointZonelLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('ViewEntryPointZonelLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "View Entry Point Zone Modal",
      "EntryPointZone"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('ViewEntryPointZonelLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    this.entryPointZone = { ...this.data };
    console.log(this.data);
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}

  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }
}
