import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApprovalType } from '../models/approval-type-model';
import { NotificationService } from './notification.service';
import { SiteService } from './site.service';
import { UserService } from './user.service';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class ApprovalTypeService {
  private approvalType: ApprovalType[];
  private approvalTypeApiData: Array<ApprovalType>;

  @Output() approvalTypeDataChanged: EventEmitter<
    boolean
  > = new EventEmitter<boolean>();

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private notificationService: NotificationService,
    private siteService: SiteService,
    private utilService: UtilityService
  ) {
    this.approvalTypeDataChanged = new EventEmitter<boolean>();
    this.approvalType = [];
  }

  // Method to return Parking lot Group array
  public getApprovalTypes(): ApprovalType[] {
    return [...this.approvalType];
  }

  public updateApprovalType(approvalType: any) {
    // const apiURL = environment.getAPI("approvalTypeUpdate");
    const apiURL = environment.getAPI("approvalTypeSiteSettingsUpdate");

    const postBody = approvalType;
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            resolve(success.body);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public getEmailTemplates(useType:string, approvalTypeID: number){

    const apiURL = environment.getAPI("getEmailTemplate");
    const body = {
      UseType : useType,
      SiteID :  this.siteService.selectedSiteID,
      ApprovalTypeID : approvalTypeID
    }
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    return this.http.post(apiURL,body,httpOptions)
  }
  
  
  public getSelfRegAndAccessRole(){

    const apiURL = environment.getAPI("getSelfRegAndAccessRole");
    const body = {
      SiteID :  this.siteService.selectedSiteID,
    }
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    return this.http.post(apiURL,body,httpOptions)
  }
}
