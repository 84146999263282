import { Component, OnInit, Inject } from "@angular/core";
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA
} from "@angular/material/snack-bar";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { from, of } from 'rxjs';
import { concatAll, delay, startWith } from 'rxjs/operators';
import { DialogService } from "../../services/dialog.service";
import { PassService } from "../../services/pass.service";
import { NotificationService } from "../../services/notification.service";
import { UserService } from "../../services/user.service";

@Component({
  selector: "app-basic-snackbar",
  templateUrl: "./basic-snackbar.component.html",
  styleUrls: ["./basic-snackbar.component.css"]
})
export class BasicSnackbarComponent implements OnInit {
  loadingData = "Loading data, do not close.......";
  userTaskId: number = null;
  timeoutHandler: number;
  isLoading: boolean;
  enableClose: boolean = false;
  enableCancel: boolean = true;
  enableSpinner: boolean = true;
  constructor(
    public sbRef: MatSnackBarRef<BasicSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: number,
    protected dialogService: DialogService,
    protected passService: PassService,
    private notificationService: NotificationService,
    public userService : UserService
  ) {
//enableClose --> this has to be true when the process is cancelled and timeout and failed 

this.userService.getToggle().subscribe((data) =>{
  if(data.status === true){
    this.closeSnackBar();
  }
})

this.userService.getCloseButtonToggle().subscribe((data)=>{
  if(data.status === true){
    this.enableClose = true;
    this.enableCancel = false;
    this.enableSpinner = false;
  }
})

this.userService.getSnackBarMessage().subscribe((data)=>{
  this.loadingData = data.message;
  this.enableCancel = false;
})
  }
  ngOnInit() {
    this.userTaskId = this.data;
  }
  public mouseup() {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
      this.isLoading = false;
      this.timeoutHandler = null;
    }
  }

  public mousedown() {
    this.isLoading = true;
    this.timeoutHandler = window.setTimeout(() => {
      this.Cancel();
      this.isLoading = false;
      this.timeoutHandler = null;
    }, 2400);
  }
  progressValue$ = from([
    of(33).pipe(delay(600)),
    of(66).pipe(delay(800)),
    of(100).pipe(delay(1000)),
  ]).pipe(startWith(of(0)), concatAll());
  
  Cancel() {
    this.passService.cancelUser(this.userTaskId).subscribe((res: any) => {
      this.notificationService.success(res.body.message);
    },
      (err) => {
        console.log(err);
        console.log(err.message);
      })
      //this.closeSnackBar();
  }
//to close the snackbar
  closeSnackBar(){
    this.sbRef.dismiss();
  }
//to close the modal and the snack bar
  closeAll(){
    this.sbRef.dismiss();
    this.passService.cancelInitiated.emit(true);
  }
}
