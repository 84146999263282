import { Component, OnInit, Inject, Output, ViewChild, OnDestroy } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  DateAdapter,
  MAT_DATE_FORMATS,
} from "@angular/material";
import { OrganizationService } from "src/app/services/organization.service";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { NotificationService } from "src/app/services/notification.service";
import { GridApi } from "ag-grid-community";
import * as _moment from "moment";
import { default as _rollupMoment } from "moment";
import moment from "moment";
import { LimitRequestService } from "src/app/services/limit-request.service";
import { UserService } from "src/app/services/user.service";
import { SpinnerService } from "src/app/services/spinner.service";
import { NgForm } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { PersonOfInterest } from "src/app/models/person-of-interest.model";
import { PersonOfInterestService } from "src/app/services/person-of-interest.service";
import { MatExpansionPanel } from "@angular/material";
import { SiteService } from "src/app/services/site.service";
import {
  MatDatePickerAdapter,
  CUSTOM_DATE_FORMATS,
} from "src/app/common/mat-date-picker-formatter/mat-date-picker-formatter";
import { IApproveRejectEmitter } from "src/app/common/AgGrid/approve-reject-cell-renderer/approve-reject-cell-renderer.component";
import { ApprovalService, IApprovalRequestStatusResponse } from "src/app/services/approval.service";
import { Subscription } from "rxjs";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-view-pass-approval-modal",
  templateUrl: "./view-pass-approval-modal.component.html",
  styleUrls: ["./view-pass-approval-modal.component.css"],
  providers: [
    { provide: DateAdapter, useClass: MatDatePickerAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export class viewPassApprovalModalComponent implements OnInit, OnDestroy {
  @ViewChild("requestForm") requestForm: NgForm;
  @ViewChild("freePanel") freezePanel: MatExpansionPanel;

  private gridApi: GridApi;

  protected step = 0;
  protected creationDate;
  protected startDate;
  protected endDate;
  protected organizationName: string;
  protected approversComment: string;
  protected createdBy: string;
  protected dateRange: string;
  protected requestersComment: string;
  protected requesterName: string;
  protected parkingLotName: string;
  protected entryPointGroupName: string;

  protected approversColumnDefs = null;  

  protected approvalData: {
    ApprovalRequestID: number;
    RequestedBy: string;
    RequestedOn: string;
    Status: string;
    Organization: string;
    StartTime: string;
    EndTime: string;
    ApproverComments: string;
  };

  protected organization: {
    OrganizationID: number;
    Name: string;
    Active: number;
    Internal: number;
    StartDate: string;
    EndDate: string;
    ModifiedBy: number;
    CreatedBy: number;
    CreatedDate: string;
  } = null;

  protected hostName: string;
  protected guestName: string;
  public approvalStatusLoaded: boolean = false;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  private subscription: Subscription;
  protected arrayData: Array<{
    RequestID: number;
    ApprovalType: number;
  }> = [];
  protected columnDefs = [];
  public defaultColDef : object;
  rowData: any;
  params: [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    protected dialogRef: MatDialogRef<viewPassApprovalModalComponent>,
    private notificationService: NotificationService,
    private limitService: LimitRequestService,
    private userService: UserService,
    protected spinnerService: SpinnerService,
    protected poiService: PersonOfInterestService,
    protected siteService: SiteService,
    protected datePipe: DatePipe,
    private approvalService: ApprovalService,
    protected messageService: MessageService,
  ) {
    
  }

  ngOnInit(): void {
    this.creationDate = this.formatDate(this.data.CreatedDate);

    //labels by message service
    if (localStorage.getItem('ViewPassApprovalsModalLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('ViewPassApprovalsModalLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "View Pass Approvals Modal",
        "Pass"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('ViewPassApprovalsModalLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    this.arrayData.push({
      RequestID: this.data.PassID,
      ApprovalType: this.data.ApprovalType
    });
    // subscribe to the approval and rejection events
    this.subscription = this.approvalService.approvedRejected.subscribe(
      (event: IApproveRejectEmitter) => {
        if (event.isApproved) {
          this.onApprove(event.data);
        } else {
          this.onReject(event.data);
        }
      }
    );
    this.limitService.getPOIMatchesByPassIDAPI(this.data.PassID).subscribe((res:HttpResponse<any>) => {
      this.rowData = res.body;
      this.gridApi.paginationGoToPage(0);
    })

    this.organizationName = this.data.Name;
    this.approversComment = this.data.ApproverComments;
    this.createdBy = this.data.RequestedBy;
    let startTime = this.data.StartTime.slice(0, -1);
    let endTime = this.data.EndTime.slice(0, -1);
    this.dateRange =
      this.formatDate(startTime) +
      " - " +
      this.formatDate(endTime);
    //guestname
    this.guestName = (
      this.data.VisitorNameSuffix +
      " " +
      this.data.VisitorFirstName +
      // " " +
      // this.data.VisitorMiddleName +
      " " +
      this.data.VisitorLastName
    )
      .replace("null", "")
      .replace(null, ""); 

      this.defaultColDef = {
        resizable: true,
    }  
  }    

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDef();
  }
  
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onMainHeaderClose() {
    this.freezePanel.open();
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.approvalService.checkinApprovalOpen = false;
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  onSaveChanges() {
    this.onNoClick();
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  protected onFirstDataRendered() {
    this.gridApi.sizeColumnsToFit();
  }

  private formatDate(date: string) {
    const formattedDate = this.datePipe.transform(
      date,
      this.siteService.dataTimeFormat.angularDateFormat
    );

    return formattedDate ? formattedDate : null;
  }

  setColumnDef() {
    this.columnDefs = [
      {
        headerName: this.screenLabels["PersonOfInterestID"]
        ? this.screenLabels["PersonOfInterestID"]
        : "Person of Interest ID",
        field: "PersonOfInterestID",
        // width: 110,
        cellStyle: { color: "#328CF0" },
        // sort: "desc",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["Name"]
        ? this.screenLabels["Name"]
        :"Name", 
        field: "Name",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["Alias"]
        ? this.screenLabels["Alias"]
        :"Alias", 
        field: "Alias",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["DOB"]
        ? this.screenLabels["DOB"]
        :"Date of Birth", 
        field: "DOB",
        hide: false,
        sortable: true,
        cellRenderer: (data) => {
          const formattedDate = this.datePipe.transform(
            data.value,
            this.siteService.dataTimeFormat.angularDateFormat
          );
          return formattedDate ? formattedDate : null;
        },
      },
    ]
  }
  

  // on approve request
  private async onApprove(reviewData: IApprovalRequestStatusResponse) {
    const approversComment = reviewData.ApprovalComments;
    const approvalGroupRequestID = reviewData.ApprovalGroupRequestID;

    await this.limitService.approvePassRequestAPI(
      this.data.PassID,
      reviewData.ApprovalGroupRequestID,
      this.data.ApprovalType,
      reviewData.ApprovalComments, 
    );
    this.notificationService.success(this.limitService.message);
  }

  // on reject request
  private async onReject(reviewData: IApprovalRequestStatusResponse) {
    const approversComment = reviewData.ApprovalComments;
    const approvalGroupRequestID = reviewData.ApprovalGroupRequestID;

    await this.limitService.rejectPassRequestAPI(
      this.data.PassID,
      approvalGroupRequestID,
      this.data.ApprovalType,
      approversComment
    );

    this.notificationService.success(this.limitService.message);    
  }
}
