import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  Input,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
} from "@angular/core";
import { filter, startWith } from 'rxjs/operators';
import { Params } from '@angular/router';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatSelectChange,
  MatExpansionPanel,
  MatAutocompleteTrigger,
  MatAutocomplete,
  MatChipInputEvent,
  MatAutocompleteSelectedEvent,
  MatSelect,
  MatSelectionListChange,
  MatCheckboxChange,
  MatSelectionList,
  MatListOption,
} from "@angular/material";
import { EntryPoint } from "../../../models/entry-point.model";
import { UserService } from "../../../services/user.service";
import { NotificationService } from "../../../services/notification.service";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { RoleMapService } from "../../../services/role-map.service";
import { HttpErrorResponse } from "@angular/common/http";
import { OrganizationService } from "../../../services/organization.service";
import { DialogService } from "../../../services/dialog.service";
import { GridApi, ValueSetterParams } from "ag-grid-community";
import { FormControl, NgForm } from "@angular/forms";
import { COMMA, ENTER, T } from "@angular/cdk/keycodes";
import { Observable } from "rxjs";
import { map, delay } from "rxjs/operators";
import { SiteService } from "src/app/services/site.service";
import { SearchService } from "src/app/services/search.service";
import { Integration } from "src/app/models/integration.model";
import { UserIntegration } from "src/app/models/userIntegration.model";
import { SelectionModel } from "@angular/cdk/collections";
import { ButtonRendererComponent } from "src/app/common/AgGrid/button-renderer/button-renderer.component";
import { MessageService } from "src/app/services/message.service";
export interface Organization {
  OrganizationID: number;
  Name: string;
}
export interface ArrayList {
  label: string;
  isLinked: boolean;
}


@Component({
  selector: "app-view-user-modal",
  templateUrl: "./create-user-modal.component.html",
  styleUrls: ["./create-user-modal.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CreateUserModalComponent implements OnInit {
  @ViewChild("userFormRef") userFormRef: NgForm;
  protected step = 0;
  protected entryPoints: Array<EntryPoint>;
  protected rolesLoaded: boolean = false;
  protected organizationsLoaded: boolean = false;
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  @ViewChild("trigger") autoCompleteTrigger: MatAutocompleteTrigger;

  @ViewChild("isAdminDropDown") isAdminDropDown: any;

  @ViewChild("organizationDropDown") organizationDropDown: any;

  protected disableNewOrganizationAdd: boolean = true;
  protected isAdmin: boolean = false;
  protected userCreated: boolean = false;
  public chipSelectedRole: Array<{
    RoleID: number;
    RoleName: string;
    Active: number;
    UserRoleID: number;
  }> = ([] = []);
  public filteredRoles: Observable<String[]>;
  public filteredIntegration: Observable<String[]>;
  protected existingUserSelected: boolean = true;
  protected initialState: boolean = true;
  protected enableSubmit: boolean = false;
  //
  // Set this to false to ensure engineers are from allEngineers list only.
  // Set this to true to also allow 'free text' engineers.
  //
  private allowFreeTextAddUser = false;

  public roleControl = new FormControl([]);
  public userControl = new FormControl([]);
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild("roleInput") userInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;

  protected userKey;
  public showGrid : boolean = false;
  protected roleItems: Array<{
    RoleID: number;
    RoleName: string;
    Active: number;
    UserRoleID: number;
  }> = [];
  protected roleBasket: Array<{
    RoleID: number;
    RoleName: string;
    Active: number;
    UserRoleID: number;
  }> = [];

  protected originalRoleBasket: Array<{
    RoleID: number;
    RoleName: string;
    Active: number;
    UserRoleID: number;
  }> = [];

  protected organizationItems: Array<{
    OrganizationID: number;
    Name: string;
    Active: number;
  }> = [];
  protected filteredOrganization = [];

  protected organizationBasket: Array<{
    UserOrganizationID: number;
    OrganizationID: number;
    Name: string;
    Active: number;
    IsAdmin: number;
  }> = [];

  protected userIntegrationData: Array<{
    IntegrationID: any;
    ExternalAppID: any;
    UserIntegrationID: any;
    Active: number;
  }> = [];

  protected originalOrganizationBasket: Array<{
    UserOrganizationID: number;
    OrganizationID: number;
    Name: string;
    Active: number;
  }> = [];
 protected columnDefs = [];
  protected frameworkComponents;
  protected rowData =  [];
  protected gridApi: GridApi;
  protected selectedPhysicalAccess: number = 0;
  protected organizationEmpty: number = 0;
  protected onHide: boolean = true;
  protected disableOnSave: boolean = false;
  protected userData = [];
  protected filteredData = [];
  protected enableDeactivateMessage: boolean = false;
  protected enableExistingMessage: boolean = false;
  protected reactivatee: boolean = false;
  protected enableSuspendedMessage: boolean = false;
  protected arrays: Array<{
    ApplicationType: string
    DisplayValue: string
    ExtApplicationName: string
    ExtApplicationTypeID: number
    IntegrationID: number
    IntegrationName: string
    isLinked?: boolean
    isClicked?: boolean
  }> = [];
  enableUnlink: boolean = false;
  protected selectedValue;
  protected valueSelected: boolean = false;
  protected errorMessage: string = '';
  @ViewChild(MatSelectionList, { static: true })
  private selectionList: MatSelectionList;
  protected integrationLoaded:boolean = false;
  protected userPhysicalMngmnt:string = "UserPhysicalAccessMgmt";
  protected userPepleMangmnt:string = "UserPeopleMgmt";
  protected plpMgmtBoolean : boolean = false;
  protected phyAccessMgmtBoolean : boolean = false;
  protected responseStored;
  protected filteredPlpMgmtChipList = [];
  protected filteredPhyAccessMgmtChipList = [];
  protected intListMgmt = [];
  protected intListAccMgmt = [];
  protected selectionCount : number = 0;
  public disableOrg : boolean = true;
  protected appLanguage;
  protected screenLabels: Array<string> = [];
  public defaultColDef : object;
  protected searchKey: string;
  organizationControl = new FormControl();
  filteredOrganizations: Observable<any[]>;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      Active: number;
      CreatedBy: number;
      CreatedDate: string;
      Email: string;
      EmployeeRef: string;
      FirstName: string;
      LastName: string;
      MiddleName: string;
      ModifiedBy: number;
      ModifiedDate: string;
      OKTAID: string;
      Phone: string;
      PrefFirstName: string;
      PrefMiddleName: string;
      PrefLastName: string;
      PrefEmail: string;
      PrefPhone: string;
      Status: string;
      Suffix: string;
      // UserID: number;
    },
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<CreateUserModalComponent>,
    protected roleMapService: RoleMapService,
    private notificationService: NotificationService,
    protected organizationService: OrganizationService,
    private userService: UserService,
    private dialogService: DialogService,
    protected siteService: SiteService,
    protected searchService: SearchService,
    protected messageService: MessageService
  ) { 
    this.defaultColDef = {
      resizable: true,
  }
  }

  ngOnInit(): void {
    
    //labels by message service
    if (localStorage.getItem('CreateUserLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('CreateUserLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
      "Create User Modal",
      "User"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('CreateUserLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }
    this.userService.getIntegrations().subscribe((response:any) => {
      response.body.Integrations.forEach(element => {
        this.arrays.push(element);
      });
    });

    // Load Roles
    this.loadRoles();
    // Load Organization Data
    this.loadOrganization();
    // Load Unregistered Symmetry Users
    // this.loadSymmetryUsers();
    // initialize page labels

    this.filteredRoles = this.roleControl.valueChanges.pipe(
      map((roleName) => this.filterOnValueChange(roleName))
    );
    this.roleControl.setValue(null);

    this.filteredIntegration = this.userControl.valueChanges.pipe(
      map((Name) => this.filterOnValueChange(Name))
    );
    
    this.searchService.smartSearchMultiSiteAPI(null).then(() => {
      this.userData = this.searchService.searchRecords;
    })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    // this.onHide = false;
    this.onValidatingAddButton();
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDefs();
  }

  isValidEmail(email: string): boolean {
    const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/
    return emailPattern.test(email);
  }

  // initialize column definitions
  
  protected onGridReady(params) {
    this.gridApi = params.api;
    // this.gridApi.setColumnDefs(this.columnDefs);
    // this.gridApi.setRowData(this.rowData);
    this.gridApi.sizeColumnsToFit();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaveChanges() {
    if (this.organizationEmpty) {
      this.onAddOrganization();
    }
    //format selected Roles
    this.userKey = this.data.Email;
    this.chipSelectedRole.forEach(element => {
      element.UserRoleID = 0
    });

    //format Organizational Basket
    this.organizationBasket.forEach((i) => {
      if (i.UserOrganizationID == undefined) {
        i.UserOrganizationID = 0;
      }
    });

    //this.data.UserID = this.userService.userID
    this.data.CreatedBy = this.userService.userID;
    const body: any = { ...this.data };

    body.Organization = this.organizationBasket;
    body.Role = this.chipSelectedRole;

    if (!this.data.PrefFirstName) {
      body.PrefFirstName = this.data.FirstName;
    }
    if (!this.data.PrefMiddleName) {
      body.PrefMiddleName = this.data.MiddleName;
    }
    if (!this.data.PrefLastName) {
      body.PrefLastName = this.data.LastName;
    }
    if (!this.data.PrefEmail) {
      body.PrefEmail = this.data.Email;
    }
    if (!this.data.PrefPhone) {
      body.PrefPhone = this.data.Phone;
    }
    let uniqueIntegrations = [];
      this.userIntegrationData.forEach((element) => {
          if (!uniqueIntegrations.includes(element)) {
            uniqueIntegrations.push(element);
          }
      });
    body.UserIntegrations = uniqueIntegrations;
    // Invoke Create New User api
    this.userService
      .createUserAssociationsAPI(body)
      .then(() => {
        this.notificationService.success(this.userService.message);
        this.userCreated = !this.userCreated;
      })
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );
    this.onNoClick();
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  private loadRoles() {
    // Load Role Data

    // need to call API
    this.roleMapService
      .getRoleMappingsAPI()
      .then(() => {
        this.prepareRoleList();
        this.rolesLoaded = true;
      })
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );
  }
  
  private loadOrganization() {
    // call API
    this.organizationService
      .getOrganizationAPI()
      .then(() => {
        // load the organization basket
        this.prepareOrganizationList();
        this.organizationsLoaded = true;
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }

  private prepareOrganizationList() {

    this.organizationItems = this.organizationService
      .getOrganizations()
      
      .filter((org) => org.Active === 1);
    this.organizationItems.sort((a, b) => a.Name.localeCompare(b.Name));
    this.filteredOrganizations = this.organizationControl.valueChanges.pipe(
      startWith(""),
      map(value => typeof value === 'string' ? value : value?.Name),
      map(Name => Name ? this._filteredOrganizations(Name) : this.organizationItems.slice())
    );
  }

  displayFn1(selectedoption: number) {
    if(selectedoption) {
    return this.organizationItems.find(x => x.OrganizationID === selectedoption).Name;
    }
  }
	
	private _filteredOrganizations(value: string) {
    return this.organizationItems.filter(option => option.Name.toLowerCase().includes(value.toLowerCase()));
  }

  private prepareRoleList() {
    const response = this.roleMapService
      .getRoleMappingResponse()
      .filter((rm) => rm.RoleActive)
      .map((rm) => ({
        RoleID: rm.RoleID,
        RoleName: rm.RoleName,
        Active: rm.RoleActive,
        UserRoleID: 0
      }))
      .filter((rm) => rm.Active == 1);

    // removing duplicates
    this.roleItems = Array.from(
      new Set(response.map((i) => JSON.stringify(i)))
    ).map((i) => JSON.parse(i));
  }

  InputControl(event) {
  }

  editExistingUser() {
    const filterData = this.userService.existingActiveUser;
    filterData.ModifiedBy = this.userService.userID;
    this.reactivatee = true;
    this.userService
      .updateUserAssociationsAPI(filterData, this.reactivatee)
      .then(() => {
        this.dialogRef.close();
        this.userService.userDataChanged.emit(true);
      })
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );
    this.reactivatee = false;
  }

  reactivateUser() {
    const filterData = this.userService.deactivatedUser.deactivatedUser;
    filterData.Active = 1;
    filterData.Status = "Approved";
    filterData.ModifiedBy = this.userService.userID;
    this.reactivatee = true;
    this.userService
      .updateUserAssociationsAPI(filterData, this.reactivatee)
      .then(() => {
        this.notificationService.success(this.userService.message);
        if (this.userService.message) {
          this.dialogRef.close();
        }
        this.userService.userDataChanged.emit(true);
      })
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );
    this.reactivatee = false;
  }

  onKey(event: any) {
    if (event.key !== "Enter") {
      this.enableDeactivateMessage = false;
      this.enableExistingMessage = false;
      this.enableSuspendedMessage = false;
    }
  }

  clearSearchField(){
    this.searchService.key = '';
  }
  searchUserIntegration() {
    //let enableSubmit = false;
    this.integrationLoaded = false;
    this.userIntegrationData = [];
    this.userService
      .searchUserIntegrationAPI(this.data.OKTAID)
      .then(() => {
        this.integrationLoaded = true;
        if (this.userService.deactivatedUser.deactivatedUser) {
          this.userService.setUserIDFromAPI(this.userService.deactivatedUser.deactivatedUser.UserID);
          this.enableDeactivateMessage = true;
        }
        const reqData = this.userService.existingInActiveUser;
        this.responseStored = this.userService.existingInActiveUser;
        this.arrays.forEach(ele => {
          if (ele.DisplayValue === "UserPeopleMgmt") {
            reqData.UserPeopleMgmt.forEach(data => {
              if (ele.IntegrationID === data.IntegrationID) {
                ele.isLinked = true;
                this.enableUnlink = true;
                this.userIntegrationData.push({
                  IntegrationID: data.IntegrationID,
                  ExternalAppID: data.ExternalAppID,
                  UserIntegrationID: data.UserIntegrationID ? data.UserIntegrationID : 0,
                  Active: data.Active
                })
              } else {
                ele.isLinked = false;
                this.enableUnlink = false;
              }
            })
          }
          else {
            //ele.isLinked = false;
            reqData.UserPhysicalAccessMgmt.forEach(data => {
              if (ele.IntegrationID === data.IntegrationID) {
                ele.isLinked = true;
                this.enableUnlink = true;
                this.userIntegrationData.push({
                  IntegrationID: data.IntegrationID,
                  ExternalAppID: data.ExternalAppID,
                  UserIntegrationID: data.UserIntegrationID ? data.UserIntegrationID : 0,
                  Active: data.Active
                })
              } else {
                ele.isLinked = false;
                this.enableUnlink = false;
              }
            });
          }
        });
        if (reqData.UserPeopleMgmt.length > 0) {
          this.data.FirstName = reqData.UserPeopleMgmt[0].FirstName;
          this.data.LastName = reqData.UserPeopleMgmt[0].LastName;
          this.data.MiddleName = reqData.UserPeopleMgmt[0].MiddleName;
          this.data.Suffix = reqData.UserPeopleMgmt[0].Suffix;
          this.data.Phone = reqData.UserPeopleMgmt[0].Phone;
          this.data.Email = this.data.OKTAID;
          this.data.PrefFirstName = reqData.UserPeopleMgmt[0].FirstName;
          this.data.PrefMiddleName = reqData.UserPeopleMgmt[0].MiddleName;
          this.data.PrefLastName = reqData.UserPeopleMgmt[0].LastName;
          this.data.PrefEmail = this.data.OKTAID;
          this.data.PrefPhone = reqData.UserPeopleMgmt[0].Phone;
          this.isAdmin = false;
          this.disableNewOrganizationAdd = true;
          this.isAdminDropDown.value = "";
          this.organizationDropDown.value = null;
          this.organizationBasket = [];
          this.chipSelectedRole = [];
          this.existingUserSelected = true;
          this.initialState = false;
          this.enableSubmit = true;
        } else if (reqData.UserPhysicalAccessMgmt.length > 0) {
          this.data.FirstName = reqData.UserPhysicalAccessMgmt[0].FirstName;
          this.data.LastName = reqData.UserPhysicalAccessMgmt[0].LastName;
          this.data.MiddleName = reqData.UserPhysicalAccessMgmt[0].MiddleName;
          this.data.Suffix = reqData.UserPhysicalAccessMgmt[0].Suffix;
          this.data.Phone = reqData.UserPhysicalAccessMgmt[0].Phone;
          this.data.Email = this.data.OKTAID;
          this.data.PrefFirstName = reqData.UserPhysicalAccessMgmt[0].FirstName;
          this.data.PrefMiddleName = reqData.UserPhysicalAccessMgmt[0].MiddleName;
          this.data.PrefLastName = reqData.UserPhysicalAccessMgmt[0].LastName;
          this.data.PrefEmail = this.data.OKTAID;
          this.data.PrefPhone = reqData.UserPhysicalAccessMgmt[0].Phone;
          this.isAdmin = false;
          this.disableNewOrganizationAdd = true;
          this.isAdminDropDown.value = "";
          this.organizationDropDown.value = null;
          this.organizationBasket = [];
          this.chipSelectedRole = [];
          this.existingUserSelected = true;
          this.initialState = false;
          this.enableSubmit = true;
        } else {
          this.data.FirstName = null;
          this.data.LastName = null;
          this.data.MiddleName = null;
          this.data.Suffix = null;
          this.data.Email = this.data.OKTAID;
          this.data.Phone = null;
          this.data.PrefFirstName = null;
          this.data.PrefMiddleName = null;
          this.data.PrefLastName = null;
          this.data.PrefEmail = null;
          this.data.PrefPhone = null;
          this.isAdmin = false;
          this.disableNewOrganizationAdd = true;
          this.isAdminDropDown.value = ""
          this.organizationDropDown.value = null;
          this.organizationBasket = [];
          this.chipSelectedRole = [];
          this.initialState = false;
          this.existingUserSelected = false;
          this.enableSubmit = true;
        }
      })
      .catch((err: HttpErrorResponse) => {
        this.data.FirstName = null;
        this.data.LastName = null;
        this.data.MiddleName = null;
        this.data.Suffix = null;
        this.data.Email = this.data.OKTAID;
        this.data.Phone = null;
        this.data.PrefFirstName = null;
        this.data.PrefMiddleName = null;
        this.data.PrefLastName = null;
        this.data.PrefEmail = null;
        this.data.PrefPhone = null;
        this.isAdmin = false;
        this.disableNewOrganizationAdd = true;
        this.isAdminDropDown.value = ""
        this.organizationDropDown.value = null;
        this.organizationBasket = [];
        this.chipSelectedRole = [];
        if (err.error.message === "User already exists") {
          if(err.error.Status === "Suspended") {
          this.enableSuspendedMessage = true;
          this.initialState = true;
          this.existingUserSelected = true;
          this.enableSubmit = false;
          } 
          else {
          this.enableExistingMessage = true;
          this.initialState = true;
          this.existingUserSelected = true;
          this.enableSubmit = false;
          this.userService.setUserIDFromAPI(err.error.activeUser.UserID);
          }
        }
        else {
          this.errorMessage = err.error.message;
          this.initialState = false;
          this.existingUserSelected = false;
          this.enableSubmit = true;
        }
      }
      );
  }

  // Code for Drag and Drop
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }

  private extractValues(mappings) {
    return Object.keys(mappings);
  }

  private lookupValue(mappings, key) {
    return mappings[key];
  }

  private lookupKey(mappings, name) {
    var keys = Object.keys(mappings);
    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      if (mappings[key] === name) {
        return key;
      }
    }
  }

  public addRole(event: MatChipInputEvent): void {
    if (!this.allowFreeTextAddUser) {
      // only allowed to select from the filtered autocomplete list
      return;
    }

    //
    // Only add when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    //
    if (this.matAutocomplete.isOpen) {
      return;
    }

    // Add our engineer
    const value = event.value;
    if ((value || "").trim()) {
      this.selectRoleByName(value.trim());
    }

    this.resetInputs();
  }

  public removeRole(role): void {
    const index = this.chipSelectedRole.indexOf(role);
    if (index >= 0) {
      this.chipSelectedRole.splice(index, 1);
      this.resetInputs();
    }
  }

  public roleSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectRoleByName(event.option.value);
    this.resetInputs();
  }

  private resetInputs() {
    // clear input element
    this.userInput.nativeElement.value = "";
    // clear control value and trigger engineerControl.valueChanges event
    this.roleControl.setValue(null);
  }

  //
  // Compute a new autocomplete list each time control value changes
  //
  private filterOnValueChange(roleName: string): String[] {
    let result: String[] = [];
    //
    // Remove the engineers we have already selected from all engineers to
    // get a starting point for the autocomplete list.    
    let allRolesLessSelected = this.roleItems.filter(
      (user) => this.chipSelectedRole.indexOf(user) < 0
    );
    if (roleName) {
      result = this.filterRole(allRolesLessSelected, roleName);
    } else {
      result = allRolesLessSelected.map((role) => role.RoleName);
    }
    return result;
  }

  private filterRole(
    roleList: Array<{
      RoleID: number;
      RoleName: string;
      Active: number;
    }> = [],
    roleName: String
  ): String[] {
    let filteredRolesList: Array<{
      RoleID: number;
      RoleName: string;
      Active: number;
    }> = ([] = []);
    const filterValue = roleName.toLowerCase();
    let rolesMatchingRoleName = roleList.filter(
      (role) => role.RoleName.toLowerCase().indexOf(filterValue) === 0
    );
    if (rolesMatchingRoleName.length || this.allowFreeTextAddUser) {
      //
      // either the engineer name matched some autocomplete options
      // or the name didn't match but we're allowing
      // non-autocomplete engineer names to be entered
      //
      filteredRolesList = rolesMatchingRoleName;
    } else {
      //
      // the engineer name didn't match the autocomplete list
      // and we're only allowing engineers to be selected from the list
      // so we show the whjole list
      //
      filteredRolesList = roleList;
    }
    //
    // Convert filtered list of engineer objects to list of engineer
    // name strings and return it
    //
    return filteredRolesList.map((role) => role.RoleName);
  }

  private selectRoleByName(roleName) {
    let foundUser = this.roleItems.filter((role) => role.RoleName == roleName);
    if (foundUser.length) {
      //
      // We found the engineer name in the allEngineers list
      //
      this.chipSelectedRole.push(foundUser[0]);
    }
  }

  protected selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    trigger.openPanel();
  }

  onAutocompleteFocus() {
    this.autoCompleteTrigger._onChange("");
    this.autoCompleteTrigger.openPanel();
  }

  private selectRoleByID(roleID) {
    let foundUser = this.roleItems.filter((user) => user.RoleID == roleID);
    if (foundUser.length) {
      this.chipSelectedRole.push(foundUser[0]);
    }
  }

  onCellDataChanged(params) {
    var colID = params.colDef.field;
    this.resetOrganization();
  }

  onCellKeyPress(event) {
  }

  resetOrganization() {
    while (this.filteredOrganization.length > 0) {
      this.filteredOrganization.pop();
    }
    this.organizationItems.forEach((element) => {
      this.filteredOrganization.push(element.Name);
    });

    this.filteredOrganization.sort();
  }

  onValidatingAddButton() {
    //for enabling add-if we have 1row
    if (this.onHide && this.organizationBasket.length >= 1 && this.organizationEmpty !== 0) {
      this.disableNewOrganizationAdd = false;
    } else if (!this.onHide && this.organizationBasket.length >= 1) {
      this.disableNewOrganizationAdd = false;
    } else if (!this.onHide && this.organizationBasket.length == 0) {
      this.disableNewOrganizationAdd = false;
    } else if (this.onHide && this.organizationBasket.length == 0 && this.organizationEmpty === 0) {
      this.disableNewOrganizationAdd = true;
    }else if (this.onHide && this.organizationBasket.length >= 1 && this.organizationEmpty === 0) {
      this.disableNewOrganizationAdd = true;
    }
  }

  // On Organization Change
  onOrganizationSelectionChange(event : MatAutocompleteSelectedEvent) {
    this.disableOrg = false ;
    const organizationID = event.option.value;
    this.organizationEmpty = +event.option.value;
    this.disableNewOrganizationAdd = false;
    this.disableOnSave = false;
  }

  // Remove Organization  from UI
  deleteFieldValueForOrganization(
    organizationID: number,
    Name: string,
    index: number
  ) {
    // const index = this.parkingWithLimits.indexOf(parking[0]);
    this.organizationBasket.splice(index, 1);
    this.organizationItems.push({
      OrganizationID: organizationID,
      Name: Name,
      Active: 0,
    });
    if (this.onHide) {
      this.organizationDropDown.value = ""
      //this.organizationEmpty = 0
    }
    if(this.organizationBasket.length === 0 && this.organizationEmpty === 0) {
      this.disableOrg = true ;
    } 
    if(this.organizationEmpty !== 0) {
      this.disableOrg = false ;
    }
    // if (this.organizationBasket.length >= 1 && this.organizationEmpty === 0) {
    //   this.onHide  = false;
    // } 

    //for disabling save on empty field with 0 rows
    if (!this.organizationEmpty && this.organizationBasket.length == 0) {
      this.disableOnSave = true;
    }
    else {
      this.disableOnSave = false;
    }
    //for enabling add-if we have 1row
    this.onValidatingAddButton();
  }

  // Remove Empty Organization  from UI
  deleteEmptyFieldValueForOrganization() {
    if(this.organizationBasket.length > 0) {
      this.disableOrg = false ;
    }else {
      this.disableOrg = true ;
    }
    // this.arr.splice(index, 1);
    this.onHide = false;
    ///for enabling add-if we have 1row
    this.onValidatingAddButton();
    this.organizationDropDown.value = ""
    this.organizationEmpty = 0
  }

  // Add Organization
  onAddOrganization() {
    this.disableOrg = true ;
    if (!this.onHide) {
      this.onHide = true;
    }
    //for enabling add-if we have 1row
    this.onValidatingAddButton();
    
    const organizationID: number = +this.organizationControl.value;
    const Name: string = this.organizationItems.find(item => item.OrganizationID === organizationID)?.Name || '';
    this.isAdmin = this.isAdminDropDown.value;

   
    

    // adding to the organization to Organization basket
    this.organizationBasket.push({
      UserOrganizationID: 0,
      IsAdmin: this.isAdmin ? 1 : 0,
      OrganizationID: organizationID,
      Name: Name,
      Active: 1
    });

    // removing from organizationItems
    let index = -1;

    for (let i = 0; i < this.organizationItems.length; i++) {
      if (this.organizationItems[i].OrganizationID === organizationID) {
        index = i;
      }
    }
    if (index >= 0) {
      this.organizationItems.splice(index, 1);
      this.organizationDropDown.selectedValue = undefined;
      this.organizationControl.setValue('');
      this.organizationEmpty = 0
    }
    // Clearing the Limit Field
    this.isAdmin = false;
    this.disableNewOrganizationAdd = true;
    this.isAdminDropDown.value = ""

    this.disableOnSave = false;
  }

  selection = new SelectionModel(true);
  list = Array.from({ length: 100 }).map((_, i) => `Item #${i}`);

  onSystemIntegratedChange(selection: MatSelectionListChange) {
    this.rowData = [];
    this.showGrid = false;
    this.valueSelected = true;
    this.phyAccessMgmtBoolean = false;
    this.plpMgmtBoolean = false;
    this.searchKey = null;
    
    this.selectedValue = selection.option.value;
    this.clickedIntegrationIs();
    if (this.selectedValue.DisplayValue === this.userPepleMangmnt) {
      this.plpMgmtBoolean = true;
    } else if (this.selectedValue.DisplayValue === this.userPhysicalMngmnt) {
      this.phyAccessMgmtBoolean = true;
    }
    this.arrays.forEach(ele => {
      if (ele.DisplayValue === this.userPepleMangmnt) {
        if(this.responseStored.UserPeopleMgmt.length > 0) {
          this.responseStored.UserPeopleMgmt.forEach(data => {
            if (ele.IntegrationID === data.IntegrationID) {
              if(!this.intListMgmt.includes(data.UserIntegrationID)){
                this.filteredPlpMgmtChipList.push(data);
                this.intListMgmt.push(data.UserIntegrationID);
            }
            }
          })
        }
      }
      else if (ele.DisplayValue === this.userPhysicalMngmnt) {
        if(this.responseStored.UserPhysicalAccessMgmt.length > 0) {
          this.responseStored.UserPhysicalAccessMgmt.forEach(data => {
            if (ele.IntegrationID === data.IntegrationID) {
              if(!this.intListMgmt.includes(data.UserIntegrationID)){
                this.filteredPlpMgmtChipList.push(data);
                this.intListMgmt.push(data.UserIntegrationID);
            }
            }
          })
        }
      }
    })
    //this.display(res.body.data);

    selection.option.selected
      ? this.selection.select(selection.option.value)
      : this.selection.deselect(selection.option.value);
  }

  //to check which integartion is clicked so that to indicate css on that selected integartion
  clickedIntegrationIs() {
    this.arrays.forEach(ele => {
      ele.isClicked = false;
      if(ele.IntegrationID == this.selectedValue.IntegrationID) {
        ele.isClicked = true;
      }
    })
  }

   onSearchBtnUserClick(event) {
    this.searchService
      .smartSearchMultiSiteAPI(this.searchKey, this.selectedValue.DisplayValue,null,false)
      .then(() => {
        const res = this.searchService.searchRecords;
        this.rowData = res.filter(ele => ele.IntegrationID == this.selectedValue.IntegrationID);
        this.showGrid = true;
        this.setColumnDefs();
        this.searchService.key = null;
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }

   clearSearchUserField(){
    this.searchKey = null;
  }

  removeIntegration(index: number, user): void {
    if (index >= 0) {
      if (this.selectedValue.DisplayValue === this.userPepleMangmnt) {
        this.filteredPlpMgmtChipList.splice(index, 1);
      }
      else {
        this.filteredPhyAccessMgmtChipList.splice(index, 1);
      }
    }
    // const userData = user.split(' | ');P
    this.userIntegrationData.push({
      IntegrationID: user.IntegrationID,
      ExternalAppID: user.ExternalAppID,
      UserIntegrationID: user.UserIntegrationID ? user.UserIntegrationID : 0,
      Active: 0
    })
    //this.userIntegrationData = this.userIntegrationData.filter(ele => ele.ExternalAppID !== user.ExternalAppID);
    this.userIntegrationData = this.userIntegrationData.filter(ele => !(ele.Active==0 && ele.UserIntegrationID==0))
  }

  setColumnDefs() {
    this.columnDefs = [
      {
        headerName:this.screenLabels["LinkUseText"]? this.screenLabels["LinkUseText"]: "Link User",
        field: "Link",
        cellStyle: { color: "#589bf8" },
        cellRendererFramework: ButtonRendererComponent,
        width: 100,
        cellRendererParams: {
          onClick: this.addIntegration.bind(this),
          label: this.screenLabels["ViewAssets"]? this.screenLabels["ViewAssets"]:"View Assets"
        },
        lockPosition: true,
      },
      {
        headerName: this.screenLabels["FirstName"]? this.screenLabels["FirstName"]: "First Name",
        field: "FirstName"
      },
      {
        headerName: this.screenLabels["LastName"]? this.screenLabels["LastName"]: "Last Name",
        field: "LastName"
      },
      {
        headerName: this.screenLabels["AppID"]? this.screenLabels["AppID"]:"App ID",
        field: "ExternalAppID"
      }
    ];
  }

  addIntegration(e) {
    const data = e.rowData;
    if (this.selectedValue.DisplayValue === this.userPepleMangmnt) {
      let isPresent = false;
      const plpData = [];
      plpData.push(data);
      plpData.forEach((element) => {
        isPresent = this.filteredPlpMgmtChipList.some(item => 
          item.UserPhysicalAccessMgmtID === plpData[0].UserPhysicalAccessMgmtID && item.ExternalAppID === plpData[0].ExternalAppID
        );
        if (!this.filteredPlpMgmtChipList.includes(element)) {
          this.filteredPlpMgmtChipList.push(element);
          this.userIntegrationData.push({
            IntegrationID: data.IntegrationID,
            ExternalAppID: data.ExternalAppID,
            UserIntegrationID: 0,
            Active: 1
          });
        }
      });
      this.userIntegrationData = this.userIntegrationData.filter(ele => !(ele.Active==0 && ele.UserIntegrationID==0));
    } 
    else if (this.selectedValue.DisplayValue === this.userPhysicalMngmnt) {
     let isPresent = false;
     const phyMgmtData = [];
      phyMgmtData.push(data);
      phyMgmtData.forEach((ele) => {
        isPresent = this.filteredPhyAccessMgmtChipList.some(item => 
          item.UserPhysicalAccessMgmtID === phyMgmtData[0].UserPhysicalAccessMgmtID && item.ExternalAppID === phyMgmtData[0].ExternalAppID
        );
        if (!isPresent) {
          this.filteredPhyAccessMgmtChipList.push(ele);
          this.userIntegrationData.push({
            IntegrationID: data.IntegrationID,
            ExternalAppID: data.ExternalAppID,
            UserIntegrationID: 0,
            Active: 1
          });
        }
      });
      this.userIntegrationData = this.userIntegrationData.filter(ele => !(ele.Active==0 && ele.UserIntegrationID==0));
    }
  }
}