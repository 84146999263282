import { Component, OnInit } from "@angular/core";
import { SearchService } from "../services/search.service";
import { NotificationService } from "../services/notification.service";
import { SpinnerService } from "../services/spinner.service";
import { MatDialog } from "@angular/material";
import { HttpErrorResponse } from "@angular/common/http";
import { ViewPassTransactionModalComponent } from "./modals/view-pass-transaction-modal/view-pass-transaction-modal.component";
import moment from "moment";
import { SiteService } from "../services/site.service";
import { DatePipe } from "@angular/common";
import { GridApi } from "ag-grid-community";
import { MessageService } from '../services/message.service';

@Component({
  selector: "app-pass-transaction",
  templateUrl: "./pass-transaction.component.html",
  styleUrls: ["./pass-transaction.component.css"],
})
export class PassTransactionComponent implements OnInit {
  private gridApi: GridApi;
  
  protected rowData = null; 
  protected passTransactionColumnDefs: any;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  public defaultColDef : object;
  constructor(
    private searchService: SearchService,
    private notificationService: NotificationService,
    private spinnerService: SpinnerService,
    private dialog: MatDialog,
    protected siteService: SiteService,
    private datePipe: DatePipe,
    protected messageService: MessageService,
  ) {
    this.defaultColDef = {
      resizable: true,
  }
  }

  ngOnInit(): void {
    this.searchService.setPageName("Pass Transaction");
    
    //labels by message service
if (localStorage.getItem('PassTransactionLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('PassTransactionLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Pass Transaction",
      "PassTransaction"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('PassTransactionLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    // turn on spinner
    setTimeout(() => {
      this.spinnerService.setIsLoading(true);
    }, 0);

    // invoke smart search to retrieve audit logs
    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        // turn of spinner
        setTimeout(() => {
          this.spinnerService.setIsLoading(false);
        }, 0);
        // populate row data
        this.rowData = this.searchService.searchRecords;
        this.gridApi.paginationGoToPage(0);
        // this.rowData = this.formatAuditLogs(this.searchService.searchRecords);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
        this.spinnerService.setIsLoading(false);
        setTimeout(() => {
          this.spinnerService.setIsLoading(false);
        }, 0);
      });

    // Listen to Search Btn click event and refresh Ag Grid with Search results
    this.searchService.searchInitiated.subscribe((eventData: boolean) => {
      if (eventData) {
        this.searchService.smartSearchPromise
          .then(() => {
            this.rowData = this.searchService.searchRecords;
            this.gridApi.paginationGoToPage(0);
            setTimeout(() => {
              this.spinnerService.setIsLoading(false);
            }, 0);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
            setTimeout(() => {
              this.spinnerService.setIsLoading(false);
            }, 0);
          });
      }
    });

    // subscribe to site changed
    this.siteService.siteChanged.subscribe((event: boolean) => {
      if(event){
        this.searchService
        .smartSearchMultiSiteAPI(null)
        .then(() => {
          this.rowData = this.searchService.searchRecords;   
          this.gridApi.paginationGoToPage(0);
           setTimeout(() => {
              this.spinnerService.setIsLoading(false);
            }, 0);
                
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
           setTimeout(() => {
              this.spinnerService.setIsLoading(false);
            }, 0);
        });
      }
    });
  }
  
  setColumnDef() {
    this.passTransactionColumnDefs = [
      {
        headerName:this.screenLabels["PassTransactionID"]
        ? this.screenLabels["PassTransactionID"]
        : "Pass Transaction ID", 
        field: "PassTransactionID",
        hide: true,
        sortable: true,
        // sort: "desc",
      },
      {
        headerName: this.screenLabels["AgGridPassID"]
          ? this.screenLabels["AgGridPassID"]
          : "Pass ID",
        field: "PassID",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridVisitorName"]
          ? this.screenLabels["AgGridVisitorName"]
          : "Visitor Name",
        field: "VisitorName",
        hide: false,
        sortable: true,
      },
      {
        headerName:this.screenLabels["EntryPointID"]
        ? this.screenLabels["EntryPointID"]
        : "Entry Point ID", 
        field: "EntryPointID",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridEntryPoint"]
          ? this.screenLabels["AgGridEntryPoint"]
          : "Entry Point",
        field: "EntryPoint",
        hide: false,
        sortable: true,
      },
      {
        headerName:this.screenLabels["Operator"]
        ? this.screenLabels["Operator"]
        : "Operator", 
        field: "Operator",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridOperator"]
          ? this.screenLabels["AgGridOperator"]
          : "Operator",
        field: "OperatorName",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridTransactionTime"]
          ? this.screenLabels["AgGridTransactionTime"]
          : "Transaction Time",
        field: "TransactionTime",
        hide: false,
        sortable: true,
        cellRenderer: (data) => {
          // return data.value
          //   ? moment(data.value).format("MM/DD/YYYY HH:mm")
          //   : null;
          const formattedDate = this.datePipe.transform(
            data.value,
            this.siteService.dataTimeFormat.angularDateFormat +
              " " +
              this.siteService.dataTimeFormat.angularTimeFormat
          );
  
          return formattedDate ? formattedDate : null;
        },
      },
      {
        headerName:this.screenLabels["ActionType"]
        ? this.screenLabels["ActionType"]
        : "Action Type", 
        field: "ActionType",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridActionType"]
          ? this.screenLabels["AgGridActionType"]
          : "Action Type",
        field: "ActionTypeName",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridRestrictedMatchStatus"]
          ? this.screenLabels["AgGridRestrictedMatchStatus"]
          : "Restricted Match Status",
        field: "RestrictedMatchStatus",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridRestrictedAutorizer"]
          ? this.screenLabels["AgGridRestrictedAutorizer"]
          : "Restricted Authorizer",
        field: "RestrictedAuthorizer",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["ModifiedBy"]
        ? this.screenLabels["ModifiedBy"]
        : "Modified By",
        field: "ModifiedBy",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["ModifiedByName"]
        ? this.screenLabels["ModifiedByName"]
        : "Modified By",  
        field: "ModifiedByName",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["ModifiedDate"]
        ? this.screenLabels["ModifiedDate"]
        : "Modified Date", 
        field: "ModifiedDate",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["CreatedBy"]
        ? this.screenLabels["CreatedBy"]
        : "Created By", 
        field: "CreatedBy",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["CreatedByName"]
        ? this.screenLabels["CreatedByName"]
        : "Created By",
        field: "CreatedByName",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["CreatedDate"]
        ? this.screenLabels["CreatedDate"]
        : "Created Date", 
        field: "CreatedDate",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["Status"]
          ? this.screenLabels["Status"]
          : "Status",
        field: "Status",
        hide: false,
        sortable: true,
      },
      {
        headerName:this.screenLabels["EntryPointIDActive"]
        ? this.screenLabels["EntryPointIDActive"]
        : "EntryPoint Active", 
        field: "EntryPointIDActive",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["PassIDActive"]
        ? this.screenLabels["PassIDActive"]
        : "Pass Active", 
        field: "PassIDActive",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["ActionTypeActive"]
        ? this.screenLabels["ActionTypeActive"]
        : "Action Type Active", 
        field: "ActionTypeActive",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["OperatorActive"]
        ? this.screenLabels["OperatorActive"]
        : "Operator Active", 
        field: "OperatorActive",
        hide: true,
        sortable: true,
      },
    ];
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDef ()
}

  onGridReady(params) {
    this.gridApi = params.api;
  }

  openPassTransaction(event) {
    const passTransaction = event.data;
    this.dialog.open(ViewPassTransactionModalComponent, {
      disableClose: true,
      data: passTransaction,
    });
  }
}
