import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Inject,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { RoleMapService } from "../../../services/role-map.service";
import { IFunctionData } from "../../../models/interfaces/function-data.model";
import { MatExpansionPanel, MatCheckboxChange } from "@angular/material";
import { FormGroup, FormControl, Validators, NgForm } from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";
import { NotificationService } from "../../../services/notification.service";
import { DialogService } from "../../../services/dialog.service";
import { Site, SiteService } from "src/app/services/site.service";
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: "app-role-map-view-modal",
  templateUrl: "./view-role-map-modal.component.html",
  styleUrls: ["./view-role-map-modal.component.css"],
})
export class RoleMapViewModalComponent implements OnInit {
  @ViewChild("freePanel") freezePanel: MatExpansionPanel;
  @ViewChild("createForm") createForm: NgForm;

  rolenameFormControl = new FormControl("", [Validators.required]);
  descriptionFormControl = new FormControl("", [Validators.required]);
  protected step = 0;
  protected loaded = false;
  protected roleSaved = false;
  protected roleName: string;
  protected description: string;
  protected roleMapping: {
    RoleID: number;
    RoleName: string;
    Notes: string;
    Active: number;
    ModifiedBy: number;
    ModifiedDate: string;
    CreatedBy: number;
    CreatedDate: string;
  };

  protected functionData: Array<{
    FunctionID: number;
    Tag: string;
    Label: string;
    Description: string;
    DisplayOrder: number;
    FunctionCategoryID: number;
    Category: string;
    CatDisplayOrder: number;
  }>;

  public categories; // holds distinct list of categories
  protected functionsChecked: Array<{
    CategoryID: number;
    FunctionID: number;
    Active: number;
  }> = [];
  protected appLanguage;
  public screenLabels: Array<string> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      RoleID: number;
      RoleName: string;
      Notes: string;
      Active: number;
      ModifiedBy: number;
      ModifiedDate: string;
      CreatedBy: number;
      CreatedDate: string;
      CreateByName: string;
      ModifiedByName: string;
    },
    public dialogRef: MatDialogRef<RoleMapViewModalComponent>,
    protected roleMapService: RoleMapService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    protected siteService:SiteService,
    protected messageService: MessageService
  ) {
    this.functionData = [];
    this.roleMapping = { ...this.data };

    console.log(data);
  }

  ngOnInit(): void {
    this.roleMapService
      .getRoleMappingsAPI()
      .then(() => {
        this.roleMapService.getFunctions().then(() => {
          this.categories = [
            ...new Set(
              this.roleMapService.functionApiData
                .sort((a, b): number => {
                  return a.CatDisplayOrder < b.CatDisplayOrder ? -1 : 1;
                })
                .map((e) => e.Category)
            ),
          ];

          this.functionData = [
            ...this.roleMapService.functionApiData.sort((a, b): number => {
              return a.DisplayOrder < b.DisplayOrder ? -1 : 1;
            }),
          ];
          this.functionsChecked = this.roleMapService.getSelectedFunctions(
            this.data.RoleID
          );
          this.loaded = true;
          // console.log(this.functionData);
        });
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    //labels by message service
if (localStorage.getItem('ViewRolesLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('ViewRolesLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "View Role Modal",
      "Roles"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('ViewRolesLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}

  roledataForm: FormGroup = new FormGroup({
    rolename: this.rolenameFormControl,
    description: this.descriptionFormControl,
  });
  getRoleRequiredErrorMessage() {
    return this.rolenameFormControl.hasError("required")
      ? "You must enter a value"
      : " ";
  }
  getDescRequiredErrorMessage() {
    return this.descriptionFormControl.hasError("required")
      ? "You must enter a value"
      : " ";
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  onMainHeaderClose() {
    this.freezePanel.open();
  }

  // Function invoked when Role Function is saved
  onSaveRole() {
    // console.log("Role Name: " + this.roleName);
    // console.log("Description: " + this.description);
    // console.log(this.functionsChecked);
    this.dialogService
      .openConfirmDialog(
        this.screenLabels["UpdateRole"]? this.screenLabels["UpdateRole"]:"Update Role",
        this.screenLabels["SaveConfirmMsg"]? this.screenLabels["SaveConfirmMsg"]: "Are you sure you want to save the changes ?"
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        this.roleMapService
          .updateRoleMapAPI(
            this.data.RoleID,
            this.roleMapping.RoleName,
            this.roleMapping.Notes,
            true,
            this.functionsChecked
          )
          .then(() => {
            this.notificationService.success(this.roleMapService.message);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.success(err.message);
          });
        this.onNoClick();
      });
  }

  public getFunctionData(): void {
    this.roleMapService.getFunctions();
  }

  // invokes when ever  the check box value is changed
  chkBoxValueChanged(
    categoryID: number,
    functionID: number,
    $event: MatCheckboxChange
  ) {
    const foundFilterObj = this.functionsChecked.filter(
      (fc) => fc.FunctionID == functionID && fc.CategoryID == fc.CategoryID
    );

    const tuple = {
      CategoryID: categoryID,
      FunctionID: functionID,
      Active: $event.checked ? 1 : 0,
    };

    if ($event.checked) {
      if (!foundFilterObj.length) this.functionsChecked.push(tuple);
      else foundFilterObj[0].Active = 1;
    } else {
      foundFilterObj[0].Active = 0;
      console.log(this.functionsChecked);
      // this.functionsChecked[foundIndex].Active = 0;
      // console.log(this.functionsChecked);
    }
  }

  isFunctionIDAssociated(functionID: number): boolean {
    if (
      this.functionsChecked.filter(
        (fc) => fc.FunctionID == functionID && fc.Active
      ).length
    )
      return true;
    else return false;
  }
}
