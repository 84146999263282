import { Component, OnInit } from "@angular/core";
import { CallerAuthenticationComponent } from "../caller-authentication.component";
import { MatDialog } from "@angular/material";
import { HttpClient } from "@angular/common/http";
import { ViewSelectorService } from "src/app/services/view-selector.service";
import { AuthService } from "src/app/services/auth.service";
import { SearchService } from "src/app/services/search.service";
import { NotificationService } from "src/app/services/notification.service";
import { SpinnerService } from "src/app/services/spinner.service";
import { UserService } from "src/app/services/user.service";
import { SiteService } from "src/app/services/site.service";
import { MessageService } from 'src/app/services/message.service';
import { PassService } from "src/app/services/pass.service";

@Component({
  selector: "app-caller-authentication-component-desktop",
  templateUrl: "./caller-authentication.component.desktop.html",
  styleUrls: ["./caller-authentication.component.desktop.css"],
})
export class CallerAuthenticationComponentDesktop extends CallerAuthenticationComponent {
  constructor(
    dialog: MatDialog,
    http: HttpClient,
    service: ViewSelectorService,
    authService: AuthService,
    searchService: SearchService,
    notificationService: NotificationService,
    spinnerService: SpinnerService,
    userService: UserService,
    siteService: SiteService,
    messageService: MessageService,
    passService: PassService
  ) {
    super(
      dialog,
      http,
      service,
      authService,
      searchService,
      notificationService,
      spinnerService,
      userService,
      siteService,
      messageService,
      passService
    );
  }
}
