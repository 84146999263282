import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ModuleWithComponentFactories,
  ElementRef,
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, Validators, NgForm, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { EntryPoint } from "src/app/models/entry-point.model";
import { EntryPointGroup } from "src/app/models/entry-point-group.model";
import { Building } from "src/app/models/building.model";
import { ViewSelectorService } from "src/app/services/view-selector.service";
import { PassService } from "src/app/services/pass.service";
import { BuildingService } from "src/app/services/building.service";
import { UserService } from "src/app/services/user.service";
import { NotificationService } from "src/app/services/notification.service";
import { DatePipe } from "@angular/common";
import { EntryPointService } from "src/app/services/entry-point.service";
import { DialogService } from "src/app/services/dialog.service";
import { EntryPointGroupService } from "src/app/services/entry-point-group.service";
import { OrganizationService } from "src/app/services/organization.service";
import { ParkingLotService } from "src/app/services/parking-lot.service";
import { SearchService } from "src/app/services/search.service";
import { RequiredFieldsService } from "src/app/services/required-fields.service";
import { HttpErrorResponse } from "@angular/common/http";
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatChipInputEvent,
  MatRadioChange,
  MatSelectChange,
  MatAutocompleteTrigger,
  MatCheckboxChange,
} from "@angular/material";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { trigger } from "@angular/animations";
import { TemplateCategory } from "src/app/models/template-category,model";
import { TemplateCategoryService } from "src/app/services/template-category-service";
import { RoleMapService } from "src/app/services/role-map.service";
import { TeamService } from "src/app/services/team-service";
import { Team } from "src/app/models/team.model";
import { SiteService } from "src/app/services/site.service";
import { MessageService } from 'src/app/services/message.service';

export interface User {
  userID: number;
  userName: string;
}
// export interface Building {
//   name: string;
// }

@Component({
  selector: "app-create-team-modal",
  templateUrl: "./create-team-modal.component.html",
  styleUrls: ["./create-team-modal.component.css"],
})
export class CreateTeamComponent implements OnInit {
  @ViewChild("teamForm") teamForm: NgForm;
  @ViewChild("expirationDate") expirationDate: ElementRef;
  @ViewChild("trigger") autoCompleteTrigger: MatAutocompleteTrigger;

  public orgUsers: User[] = [];
  public chipSelectedUser: User[] = [];
  public filteredUsers: Observable<String[]>;
  protected ignoreLocks: boolean = false;

  //
  // Set this to false to ensure engineers are from allEngineers list only.
  // Set this to true to also allow 'free text' engineers.
  //
  private allowFreeTextAddUser = false;

  public userControl = new FormControl([]);
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild("userInput") userInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;
  protected team: Team;
  rolenameFormControl = new FormControl("", [Validators.required]);
  descriptionFormControl = new FormControl("", [Validators.required]);
  protected loaded = false;
  protected roleSaved = false;
  protected roleName: string;
  protected description: string;
  protected functionData: Array<{
    OrganizationFunctionID: number;
    Tag: string;
    Label: string;
    Description: string;
    DisplayOrder: number;
    FunctionCategoryID: number;
    Category: string;
    CatDisplayOrder: number;
  }>;

  public categories = []; // holds distinct list of categories
  protected functionsChecked: Array<{
    CategoryID: number;
    OrganizationFunctionID: number;
    Active: boolean;
  }> = [];
  protected TeamName: string = "";
  protected appLanguage;
  public screenLabels: Array<string> = [];
  constructor(
    public dialogRef: MatDialogRef<CreateTeamComponent>,
    protected userService: UserService,
    protected searchService: SearchService,
    protected teamService: TeamService,
    protected roleMapService: RoleMapService,
    private notificationService: NotificationService,
    protected siteService:SiteService,
    protected requiredFieldsService: RequiredFieldsService,
    protected messageService: MessageService,
  ) {
    document.addEventListener(
      "keydown",
      (e) => {
        if ((e.target as any).nodeName === "MAT-EXPANSION-PANEL-HEADER") {
          e.stopImmediatePropagation();
        }
        if ((e.target as any).nodeName === "MAT-DIALOG-CONTAINER") {
          if (this.step != 2) {
            this.nextStep();
          } else {
            return;
          }
        }
      },
      true
    );

    this.functionData = [];
  }
  durationInSeconds = 3;
  step = 0;
  disabled = false;
  private fieldArray: Array<any> = [];
  private newAttribute: any = {};

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  nextSteptwo() {
    this.email.markAsTouched();
    if (!this.email.invalid) {
      this.step++;
    }
  }
  email = new FormControl("", [Validators.required, Validators.email]);
  entryPointGroupFormControl = new FormControl("", Validators.required);

  ngOnInit() {
    this.team = new Team(0,null,[],0,0,0,0,0,null,0,null,[]);

//labels by message service
if (localStorage.getItem('CreateTeamLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('CreateTeamLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Create Team Modal",
      "Team"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('CreateTeamLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    this.teamService.getOrgFunctions().then(() => {
      this.loaded = true;
      this.categories = [
        ...new Set(
          this.teamService.functionApiData
            .sort((a, b): number => {
              return a.CatDisplayOrder < b.CatDisplayOrder ? -1 : 1;
            })
            .map((e) => e.Category)
        ),
      ];
      this.functionData = [
        ...this.teamService.functionApiData.sort((a, b): number => {
          return a.DisplayOrder < b.DisplayOrder ? -1 : 1;
        }),
      ];
      // console.log(this.functionData);
    });
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}

  ngAfterViewChecked() {
    this.requiredFieldsService.setRequiredFields(
      'Create Team',
      this.teamForm
    );
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    console.log(event);
    // Add our fruit
    // if ((value || '').trim()) {

    //   if(DataValidator.isEmail(value) == ValidationErrorCodes.Ok){
    //     this.hostEmails.push({"email" : value.trim(),"valid" : "valid"});
    //   }else{
    //     this.hostEmails.push({"email" : value.trim(),"valid" : "invalid"});
    //   }
    // }
    // console.log(this.hostEmails)
    // Reset the input value

    if (input) {
      input.value = "";
    }
  }

  public addUser(event: MatChipInputEvent): void {
    if (!this.allowFreeTextAddUser) {
      // only allowed to select from the filtered autocomplete list
      console.log("allowFreeTextAddEngineer is false");

      return;
    }

    //
    // Only add when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    //
    if (this.matAutocomplete.isOpen) {
      return;
    }

    // Add our engineer
    const value = event.value;
    if ((value || "").trim()) {
      this.selectUserByName(value.trim());
    }

    this.resetInputs();
  }

  public removeUser(user: User): void {
    const index = this.chipSelectedUser.indexOf(user);
    if (index >= 0) {
      this.chipSelectedUser.splice(index, 1);
      this.resetInputs();
    }
  }

  public userSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectUserByName(event.option.value);
    this.resetInputs();
  }

  private resetInputs() {
    // clear input element
    this.userInput.nativeElement.value = "";
    // clear control value and trigger engineerControl.valueChanges event
    this.userControl.setValue(null);
  }

  //
  // Compute a new autocomplete list each time control value changes
  //
  private filterOnValueChange(userName: string): String[] {
    let result: String[] = [];
    //
    // Remove the engineers we have already selected from all engineers to
    // get a starting point for the autocomplete list.    //
    console.log(userName);
    let allUsersLessSelected = this.orgUsers.filter(
      (user) => this.chipSelectedUser.indexOf(user) < 0
    );
    if (userName) {
      result = this.filterUser(allUsersLessSelected, userName);
    } else {
      result = allUsersLessSelected.map((user) => user.userName);
    }
    console.log(result);
    return result;
  }

  private filterUser(userList: User[], userName: String): String[] {
    let filteredUserList: User[] = [];
    const filterValue = userName.toLowerCase();
    let usersMatchingUserName = userList.filter(
      (user) => user.userName.toLowerCase().indexOf(filterValue) === 0
    );
    if (usersMatchingUserName.length || this.allowFreeTextAddUser) {
      //
      // either the engineer name matched some autocomplete options
      // or the name didn't match but we're allowing
      // non-autocomplete engineer names to be entered
      //
      filteredUserList = usersMatchingUserName;
    } else {
      //
      // the engineer name didn't match the autocomplete list
      // and we're only allowing engineers to be selected from the list
      // so we show the whjole list
      //
      filteredUserList = userList;
    }
    //
    // Convert filtered list of engineer objects to list of engineer
    // name strings and return it
    //
    return filteredUserList.map((user) => user.userName);
  }

  private selectUserByName(userName) {
    let foundUser = this.orgUsers.filter((user) => user.userName == userName);
    if (foundUser.length) {
      //
      // We found the engineer name in the allEngineers list
      //
      this.chipSelectedUser.push(foundUser[0]);
    }
  }

  protected selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    trigger.openPanel();
  }

  onAutocompleteFocus() {
    this.autoCompleteTrigger._onChange("");
    this.autoCompleteTrigger.openPanel();
  }

  onSaveChanges() {

    const name = this.team.Name.trim() === this.team.Name ? this.team.Name : this.team.Name.trim();
    const trimmedname ={
      ...this.team,
      Name: name
    }
    this.team = trimmedname ;

    if(!this.ignoreLocks){
      this.functionsChecked.forEach(element => {
        if(element.CategoryID == 19 ){
          element.Active = false;
        }
      });
    }
    if(!this.team.AutoAddAllCategories){
      this.functionsChecked.forEach(element => {
        if(element.CategoryID == 20 ){
          element.Active = false;
        }
      });
    }
    this.teamService
      .createTeamAPI(this.team, this.functionsChecked)
      .then(() => {
        
        if (this.teamService.teamDataChanged) {
          this.notificationService.success(this.teamService.message);
        }
      })
      .catch((err) => {
        this.notificationService.failure(err.message);
      });

    this.onNoClick();
  }

  isTeamNameEmpty(): boolean {
    return !this.team.Name || !this.team.Name.trim();
  }

  nameFormControl = new FormControl("", [
    Validators.required,  // Required validation
    Validators.pattern(/^[a-zA-Z\s&\/'-][a-zA-Z\s0-9&\/'-]*$/),  // Pattern validation for only alphabets and spaces , hyphen ,forwar slash ,ampersand , single quote
    this.specialCharacterValidator(),
  ]);

  // Custom validator function
  specialCharacterValidator() {
    return (control: FormControl) => {
      const value = control.value;
      if (!value) {
        return null; // No validation for empty values
      }

      // Check for spaces or hyphens forwar slash ,ampersand, single quote followed by spaces
      if (/^\s/.test(value)) {
        return { specialCharactersNotAllowed: true };
      }
  
      const isValid = /^[a-zA-Z\s0-9]*([-\/&'][a-zA-Z\s0-9]+)*$/.test(value);
      if (isValid && !/^[&\/'-]/.test(value)) {
      return null;
    };
  return { specialCharactersNotAllowed: true };
    }
  }

  // invokes when ever  the check box value is changed
  chkBoxValueChanged(
    categoryID: number,
    organizationFunctionID: number,
    $event: MatCheckboxChange
  ) {

    if(organizationFunctionID == 5 ){
      this.ignoreLocks = !this.ignoreLocks;
    }
    const tuple = {
      CategoryID: categoryID,
      OrganizationFunctionID: organizationFunctionID,
      Active: true,
    };
    if ($event.checked) {
      this.functionsChecked.push(tuple);
    } else {
      const foundIndex = this.functionsChecked.indexOf(tuple);
      this.functionsChecked.splice(foundIndex, 1);

      // console.log(this.functionsChecked);
    }
  }
}
