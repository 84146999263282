import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatExpansionPanel,
  MatDialogRef,
  MatCheckboxChange,
} from "@angular/material";
import { NgForm } from "@angular/forms";
import { RoleMapModalComponent } from "../../role-map/modals/role-map-modal/role-map-modal.component";
import { SearchService } from "../../services/search.service";
import { NotificationService } from "../../services/notification.service";
import { HttpErrorResponse } from "@angular/common/http";
import { DialogService } from "../../services/dialog.service";
import { Site, SiteService } from "src/app/services/site.service";
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: "app-search-filter",
  templateUrl: "./search-filter.modal.component.html",
  styleUrls: ["./search-filter.modal.component.css"],
})
export class SearchFilterModalComponent implements OnInit {
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  @ViewChild("filterForm") filterForm: NgForm;

  protected step = 0;
  protected loaded = false;
  protected filterCount = undefined;

  public isFilterLoading = true;
  public isSaving = false;
  public searchFilters = [];
  protected appLanguage;
  public screenLabels: Array<string> = [];

  constructor(
    public dialogRef: MatDialogRef<RoleMapModalComponent>,
    public searchService: SearchService,
    protected notificationService: NotificationService,
    private dialogService: DialogService,
    protected siteService:SiteService,
    protected messageService: MessageService,
  ) {}

  ngOnInit(): void {

//labels by message service
if (localStorage.getItem('SearchFilterLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('SearchFilterLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Search Filter",
      "SearchFilter"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('SearchFilterLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    this.searchService
      .getFiltersAPI()
      .then(() => {
        this.isFilterLoading = false;
        this.searchFilters = [...this.searchService.getFilters()];

        // console.log(this.searchFilters);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}

  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  onMainHeaderClose() {
    this.freezePanel.open();
  }

  onSaveFilter() {
    this.isSaving = true;
    this.searchService
      .updateFilterAPI(this.searchFilters)
      .then(() => {
        this.notificationService.success(
          this.screenLabels["SearchFilterSuccessMsg"]? this.screenLabels["SearchFilterSuccessMsg"]:"Search Filters Have been Saved Successfully."
        );
        this.dialogRef.close(true);
        document.getElementById("searchButton").click();
      })
      .catch((err: HttpErrorResponse) => {
        this.isSaving = false;
        this.notificationService.failure(err.message);
      });
  }

  onSelectionChanged(event: MatCheckboxChange, index: number) {
    // console.log(event.checked);
    this.searchFilters[index].Active = event.checked;
  }

  // protected incrementFilterCount(label) {
  //   if (this.filterCount === undefined) this.filterCount = 0;
  //   else ++this.filterCount;

  //   console.log(label);
  //   console.log(this.filterCount);
  // }
}
