import { Component } from "@angular/core";
import { DashboardWidgetsComponent } from "../dashboard-widgets.component";
import { ViewportRuler } from "@angular/cdk/overlay";
import { WidgetService } from "src/app/services/widget.service";
import { UserService } from "src/app/services/user.service";
import { MatDialog } from "@angular/material";
import { OrganizationService } from "src/app/services/organization.service";
import { SiteService } from "src/app/services/site.service";
import { UtilityService } from "src/app/services/utility.service";
import { AuthService } from "src/app/services/auth.service";
import { MessageService } from "src/app/services/message.service";
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: "app-dashboard-widgets-mobile",
  templateUrl: "./dashboard-widgets.component.mobile.html",
  styleUrls: ["./dashboard-widgets.component.mobile.css"],
})
export class DashboardWidgetsComponentMobile extends DashboardWidgetsComponent {
  constructor(
    viewportruler: ViewportRuler,
    widgetService: WidgetService,
    userService: UserService,
    dialog: MatDialog,
    organizationService: OrganizationService,
    siteService:SiteService,
    utilService: UtilityService,
    authService: AuthService,
    messageService: MessageService,
    dialogService : DialogService
  ) {
    super(
      viewportruler,
      widgetService,
      userService,
      dialog,
      organizationService,
      siteService,
      utilService,
      authService,
      messageService,
      dialogService
    );
  }
  public items = [
    {
      title: "DRIVE-ON VISITORS EXPECTED IN THE NEXT HOUR",
      number: "3,234",
      color: "linear-gradient(to top,#8d969d,#d4d8da)",
      border: "2px solid #8d969d",
    },
    {
      title: "WALK-ON VISITORS EXPECTED IN THE NEXT HOUR",
      number: "3,014",
      color: "linear-gradient(to top,#d4d8da,#f6f6f6)",
      border: "2px solid #c3cbdc",
    },
    {
      title: "TOTAL DRIVE-ON VISITORS EXPECTED TODAY",
      number: "3,012",
      color: "linear-gradient(to top,#8d969d,#d4d8da)",
      border: "2px solid #8d969d",
    },
    {
      title: "TOTAL WALK-ON VISITORS EXPECTED TODAY",
      number: "3,122",
      color: "linear-gradient(to top,#d4d8da,#f6f6f6)",
      border: "2px solid #c3cbdc",
    },
    {
      title: "DRIVE-ON VISITORS CHECKED IN",
      number: "1,088",
      color: "linear-gradient(to top,#8d969d,#d4d8da)",
      border: "2px solid #8d969d",
    },
    {
      title: "WALK-ON VISITORS CHECKED IN",
      number: "8,018",
      color: "linear-gradient(to top,#d4d8da,#f6f6f6)",
      border: "2px solid #c3cbdc",
    },
  ];
}
