import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ModuleWithComponentFactories,
  ElementRef,
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, Validators, NgForm } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { EntryPoint } from 'src/app/models/entry-point.model';
import { EntryPointGroup } from 'src/app/models/entry-point-group.model';
import { Building } from 'src/app/models/building.model';
import { ViewSelectorService } from 'src/app/services/view-selector.service';
import { PassService } from 'src/app/services/pass.service';
import { BuildingService } from 'src/app/services/building.service';
import { UserService } from 'src/app/services/user.service';
import { NotificationService } from 'src/app/services/notification.service';
import { DatePipe } from '@angular/common';
import { EntryPointService } from 'src/app/services/entry-point.service';
import { DialogService } from 'src/app/services/dialog.service';
import { EntryPointGroupService } from 'src/app/services/entry-point-group.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { ParkingLotService } from 'src/app/services/parking-lot.service';
import { SearchService } from 'src/app/services/search.service';
import { RequiredFieldsService } from 'src/app/services/required-fields.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatCheckboxChange, MatChipInputEvent, MatRadioChange, MatSelectChange } from '@angular/material';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { TemplateCategory } from 'src/app/models/template-category,model';
import { TemplateCategoryService } from 'src/app/services/template-category-service';
import { TeamService } from 'src/app/services/team-service';
import { Team } from 'src/app/models/team.model';
import { TeamTemplateCategory } from '../edit-team-modal/edit-team-modal.component';
import { RoleMapService } from 'src/app/services/role-map.service';
import { SiteService } from "src/app/services/site.service";
import { UtilityService } from "src/app/services/utility.service";
import { MessageService } from 'src/app/services/message.service';



export interface User {
  userID: number;
  userName: string;
}
// export interface Building {
//   name: string;
// }

@Component({
  selector: "app-view-team-modal",
  templateUrl: "./view-team-modal.component.html",
  styleUrls: ["./view-team-modal.component.css"],
})
export class ViewTeamComponent implements OnInit {
  @ViewChild("updateTeamForm") updateTeamForm: NgForm;
  @ViewChild("expirationDate") expirationDate: ElementRef;
  @ViewChild("trigger") autoCompleteTrigger: MatAutocompleteTrigger;
  public templateCategories: TeamTemplateCategory[] = [];
  public chipSelectedCategory: TeamTemplateCategory[] = [];
  public originalSelectedCategory: TeamTemplateCategory[] = [];
  public filteredTemplateCategories: Observable<String[]>;
  //
  // Set this to false to ensure engineers are from allEngineers list only.
  // Set this to true to also allow 'free text' engineers.
  //
  private allowFreeTextAddCategory = false;
  protected autoAddAllCategories: boolean = false;

  public templateCategoryControl = new FormControl([]);
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild("templateCategoryInput") templateCategoryInput: ElementRef<
    HTMLInputElement
  >;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;
  protected templateCategory = new TemplateCategory(
    0,
    "",
    this.userService.organizationID,
    "",
    1,
    this.userService.userID,
    this.utilService.getCurrentSiteDT(),
    this.userService.userID,
    this.utilService.getCurrentSiteDT()
  );
  protected team: Team;
  rolenameFormControl = new FormControl("", [Validators.required]);
  descriptionFormControl = new FormControl("", [Validators.required]);
  protected loaded = false;
  protected roleSaved = false;
  protected roleName: string;
  protected description: string;
  protected ignoreLocks: boolean = false;
  protected functionData: Array<{
    OrganizationFunctionID: number;
    Tag: string;
    Label: string;
    Description: string;
    DisplayOrder: number;
    FunctionCategoryID: number;
    Category: string;
    CatDisplayOrder: number;
  }>;

  public categories; // holds distinct list of categories

  protected functionsChecked: Array<{
    CategoryID: number;
    OrganizationFunctionID: number;
    Active: boolean;
    OrganizationFunctionTeamID: number;
  }> = [];
  protected appLanguage;
  public screenLabels: Array<string> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Team,
    public dialogRef: MatDialogRef<ViewTeamComponent>,
    protected userService: UserService,
    protected searchService: SearchService,
    protected teamService: TeamService,
    protected roleMapService: RoleMapService,
    private notificationService: NotificationService,
    protected templateCategoryService: TemplateCategoryService,
    protected dialogService: DialogService,
    protected siteService:SiteService,
    private utilService: UtilityService,
    protected messageService: MessageService,
  ) {
    document.addEventListener(
      "keydown",
      (e) => {
        if ((e.target as any).nodeName === "MAT-EXPANSION-PANEL-HEADER") {
          e.stopImmediatePropagation();
        }
        if ((e.target as any).nodeName === "MAT-DIALOG-CONTAINER") {
          if (this.step != 2) {
            this.nextStep();
          } else {
            return;
          }
        }
      },
      true
    );
  }
  durationInSeconds = 3;
  step = 0;
  disabled = false;
  private fieldArray: Array<any> = [];
  private newAttribute: any = {};

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  nextSteptwo() {
    this.email.markAsTouched();
    if (!this.email.invalid) {
      this.step++;
    }
  }
  email = new FormControl("", [Validators.required, Validators.email]);
  entryPointGroupFormControl = new FormControl("", Validators.required);

  ngOnInit() {
    console.log(this.data);
    this.team = this.data;

//labels by message service
if (localStorage.getItem('ViewTeamLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('ViewTeamLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "View Team Modal",
      "Team"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('ViewTeamLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    this.team.AutoAddAllCategories =  this.team.AutoAddAllCategories ? this.team.AutoAddAllCategories : 0
    this.teamService.getOrgFunctions().then(() => {
      this.categories = [
        ...new Set(
          this.teamService.functionApiData
            .sort((a, b): number => {
              return a.CatDisplayOrder < b.CatDisplayOrder ? -1 : 1;
            })
            .map((e) => e.Category)
        ),
      ];
      this.functionData = [
        ...this.teamService.functionApiData.sort((a, b): number => {
          return a.DisplayOrder < b.DisplayOrder ? -1 : 1;
        }),
      ];

      // console.log(this.functionData);
    });

    this.teamService
      .getTeamAPI(this.data.TeamID)
      .then(() => {
        console.log(this.teamService.getTeam());
        this.team = this.teamService.getTeam()[0];
        console.log(this.team);
        this.functionsChecked = this.team.SelectedTeamOrgFunctionList.map(
          (team) => ({
            CategoryID: team.FunctionCategoryID,
            OrganizationFunctionID: team.OrganizationFunctionID,
            Active: team.Active,
            OrganizationFunctionTeamID: team.OrganizationFunctionTeamID,
          })
        );
        console.log(this.functionsChecked);
        this.ignoreLocks = this.isFunctionIDAssociated(5);
        console.log(this.ignoreLocks);
        this.originalSelectedCategory = this.team.TemplateCategoryTeamList.map(
          (category) => ({
            TemplateCategoryTeamID: category.TemplateCategoryTeamID,
            TemplateCategoryID: category.TemplateCategoryID,
            Category: category.TemplateCategory,
            Active: category.Active ? 1 : 0,
          })
        );
        this.chipSelectedCategory = this.team.TemplateCategoryTeamList.filter(
          (f) => f.Active
        ).map((category) => ({
          TemplateCategoryTeamID: 0,
          TemplateCategoryID: category.TemplateCategoryID,
          Category: category.TemplateCategory,
          Active: 0,
        }));

        this.templateCategoryService.getTemplateCategoryAPI().then(() => {
          var templateCategories: TemplateCategory[] = this.templateCategoryService.getTemplateCategory();
          templateCategories = templateCategories.filter(
            (f) => f.OrganizationID == this.userService.organizationID
          );
          this.templateCategories = templateCategories.map((category) => ({
            TemplateCategoryTeamID: 0,
            TemplateCategoryID: category.TemplateCategoryID,
            Category: category.Category,
            Active: 0,
          }));
          this.chipSelectedCategory.forEach((element) => {
            var index = -1;
            const length = this.templateCategories.filter(
              (f) => f.TemplateCategoryID == element.TemplateCategoryID
            ).length;
            if (length > 0)
              index = this.templateCategories.indexOf(
                this.templateCategories.filter(
                  (f) => f.TemplateCategoryID == element.TemplateCategoryID
                )[0]
              );
            if (index >= 0) {
              this.templateCategories.splice(index, 1);
            }
          });
        });
        console.log(this.templateCategories);
        console.log(this.chipSelectedCategory);
        console.log(this.originalSelectedCategory);
      })
      .catch();
    console.log(this.functionsChecked);
    // load Template category Dropdownonsa
    this.loaded = true;
    // this.resetInputs()
    this.filteredTemplateCategories = this.templateCategoryControl.valueChanges.pipe(
      map((Name) => this.filterOnValueChange(Name))
    );
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}

  ngAfterViewInit() {
    //sthis.resetInputs()
    this.filteredTemplateCategories = this.templateCategoryControl.valueChanges.pipe(
      map((Name) => this.filterOnValueChange(Name))
    );
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    console.log(event);
    // Add our fruit
    // if ((value || '').trim()) {

    //   if(DataValidator.isEmail(value) == ValidationErrorCodes.Ok){
    //     this.hostEmails.push({"email" : value.trim(),"valid" : "valid"});
    //   }else{
    //     this.hostEmails.push({"email" : value.trim(),"valid" : "invalid"});
    //   }
    // }
    // console.log(this.hostEmails)
    // Reset the input value

    if (input) {
      input.value = "";
    }
  }

  public addTemplateCategory(event: MatChipInputEvent): void {
    if (!this.allowFreeTextAddCategory) {
      // only allowed to select from the filtered autocomplete list
      console.log("allowFreeTextAddEngineer is false");
      return;
    }

    //
    // Only add when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    //
    if (this.matAutocomplete.isOpen) {
      return;
    }

    // Add our engineer
    const value = event.value;
    if ((value || "").trim()) {
      this.selectTemplateCategoryByName(value.trim());
    }

    this.resetInputs();
  }

  public removeTemplateCategory(templateCategory: TeamTemplateCategory): void {
    const index = this.chipSelectedCategory.indexOf(templateCategory);
    const templateIndex = this.templateCategories.indexOf(templateCategory);
    if (index >= 0) {
      this.chipSelectedCategory.splice(index, 1);
      if (templateIndex < 0) this.templateCategories.push(templateCategory);
      this.resetInputs();
    }
  }

  public templateCategorySelected(event: MatAutocompleteSelectedEvent): void {
    this.selectTemplateCategoryByName(event.option.value);
    this.resetInputs();
  }

  private resetInputs() {
    // clear input element
    this.templateCategoryInput.nativeElement.value = "";
    // clear control value and trigger engineerControl.valueChanges event
    this.templateCategoryControl.setValue(null);
  }

  //
  // Compute a new autocomplete list each time control value changes
  //
  private filterOnValueChange(category: string): String[] {
    let result: String[] = [];
    //
    // Remove the engineers we have already selected from all engineers to
    // get a starting point for the autocomplete list.    //
    console.log(category);
    let allUsersLessSelected = this.templateCategories.filter(
      (templateCategory) =>
        this.chipSelectedCategory.indexOf(templateCategory) < 0
    );
    if (category) {
      result = this.filterTemplateCategory(allUsersLessSelected, category);
    } else {
      result = allUsersLessSelected.map(
        (templateCategory) => templateCategory.Category
      );
    }
    console.log(result);
    return result;
  }

  private filterTemplateCategory(
    categoryList: TeamTemplateCategory[],
    category: String
  ): String[] {
    let filteredTemplateCategoryList: TeamTemplateCategory[] = [];
    const filterValue = category.toLowerCase();
    let matchingCategory = categoryList.filter(
      (category) => category.Category.toLowerCase().indexOf(filterValue) === 0
    );
    if (matchingCategory.length || this.allowFreeTextAddCategory) {
      //
      // either the engineer name matched some autocomplete options
      // or the name didn't match but we're allowing
      // non-autocomplete engineer names to be entered
      //
      filteredTemplateCategoryList = matchingCategory;
    } else {
      //
      // the engineer name didn't match the autocomplete list
      // and we're only allowing engineers to be selected from the list
      // so we show the whjole list
      //
      filteredTemplateCategoryList = categoryList;
    }
    //
    // Convert filtered list of engineer objects to list of engineer
    // name strings and return it
    //
    return filteredTemplateCategoryList.map((category) => category.Category);
  }

  private selectTemplateCategoryByName(category: string) {
    let foundTemplateCategory = this.templateCategories.filter(
      (tempCategory) => tempCategory.Category == category
    );
    if (foundTemplateCategory.length) {
      //
      // We found the engineer name in the allEngineers list
      //
      this.chipSelectedCategory.push(foundTemplateCategory[0]);
    }
  }

  protected selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    trigger.openPanel();
  }

  onAutocompleteFocus() {
    this.autoCompleteTrigger._onChange("");
    this.autoCompleteTrigger.openPanel();
  }

  onSaveChanges() {
    this.dialogService
      .openConfirmDialog(
        "Confirm Pass Update",
        "Are you sure you want to save the changes ?"
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          // confirmed
          this.chipSelectedCategory.forEach((element) => {
            element.Active = 1;
          });
          this.originalSelectedCategory.forEach((element) => {
            var index = -1;
            const length = this.chipSelectedCategory.filter(
              (f) => f.TemplateCategoryID == element.TemplateCategoryID
            ).length;
            if (length > 0)
              index = this.chipSelectedCategory.indexOf(
                this.chipSelectedCategory.filter(
                  (f) => f.TemplateCategoryID == element.TemplateCategoryID
                )[0]
              );
            if (index < 0) {
              element.Active = 0;
              this.chipSelectedCategory.push(element);
            } else {
              element.Active = 1;
              this.chipSelectedCategory.splice(index, 1);
              this.chipSelectedCategory.push(element);
            }
          });
          console.log(this.originalSelectedCategory);
          console.log(this.chipSelectedCategory);
          if (this.team.AutoAddAllCategories) {
            this.chipSelectedCategory = [];
          }
          if (!this.ignoreLocks) {
            this.functionsChecked.forEach((element) => {
              if (element.CategoryID == 19) {
                element.Active = false;
              }
            });
          }
          if (!this.team.AutoAddAllCategories) {
            this.functionsChecked.forEach((element) => {
              if (element.CategoryID == 20) {
                element.Active = false;
              }
            });
          }
          console.log(this.functionsChecked);

          this.teamService
            .updateTeamAPI(
              this.team,
              this.functionsChecked,
              this.chipSelectedCategory
            )
            .then(() => {
              if (this.teamService.teamDataChanged) {
                this.notificationService.success(this.teamService.message);
              }
            })
            .catch((err) => {
              this.notificationService.failure(err.message);
            });
          this.onNoClick();
        }
      });
  }

  private selectTemplateCategoryByID(categoryID) {
    let foundTemplateCategory = this.templateCategories.filter(
      (category) => category.TemplateCategoryID == categoryID
    );
    if (foundTemplateCategory.length) {
      this.chipSelectedCategory.push(foundTemplateCategory[0]);
    }
  }

  isFunctionIDAssociated(functionID: number): boolean {
    if (
      this.functionsChecked.filter(
        (fc) => fc.OrganizationFunctionID == functionID && fc.Active
      ).length
    )
      return true;
    else return false;
  }

  // invokes when ever  the check box value is changed
  chkBoxValueChanged(
    categoryID: number,
    organizationfunctionID: number,
    $event: MatCheckboxChange
  ) {
    if (organizationfunctionID == 5) {
      this.ignoreLocks = !this.ignoreLocks;
    }
    const foundFilterObj = this.functionsChecked.filter(
      (fc) =>
        fc.OrganizationFunctionID == organizationfunctionID &&
        fc.CategoryID == categoryID
    );

    const tuple = {
      CategoryID: categoryID,
      OrganizationFunctionID: organizationfunctionID,
      OrganizationFunctionTeamID: 0,
      Active: $event.checked ? true : false,
    };

    if ($event.checked) {
      if (!foundFilterObj.length) this.functionsChecked.push(tuple);
      else foundFilterObj[0].Active = true;
    } else {
      foundFilterObj[0].Active = false;
    }
  }
}
