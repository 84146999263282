import {
  Component, OnInit, ViewChild,
  ElementRef
} from '@angular/core';
import { SearchService } from "../services/search.service";
import { SearchFilterModalComponent } from "../nav-bar/modals/search-filter.modal.component";
import { SpinnerService } from "../services/spinner.service";
import { DatePipe } from "@angular/common";
import { MatDialogConfig, MatDialog } from "@angular/material";
import moment from "moment-timezone";
import { NotificationService } from "../services/notification.service";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { UserService } from "../services/user.service";
import { SiteService } from "../services/site.service";
import { MessageService } from '../services/message.service';
@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent implements OnInit {
  public searchBtnDisabled: boolean;
  @ViewChild("searchField") searchField: ElementRef;
  private today: Date = new Date(); // DO NOT replace with utilService.getCurrentSiteDT()
  public screenLabels: Array<string> = [];
  public pageName: string = "";
  protected appLanguage;

  constructor(private spinnerService: SpinnerService,
    public userService: UserService,
    public searchService: SearchService, protected dialog: MatDialog,
    private datePipe: DatePipe, private notificationService: NotificationService,
    protected siteService: SiteService,
    protected messageService: MessageService,
  ) {
    this.searchBtnDisabled = false;

  }

  ngOnInit(): void {
 //labels by message service
 if (localStorage.getItem('SearchlLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('SearchlLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Search",
    "Search"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('SearchlLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  onFilterBtnClick() {
    const dialogRef = this.dialog.open(SearchFilterModalComponent, {
      disableClose: false,
      data: { pageName: this.searchService.getPageName() },
      backdropClass: "backdropBackground",
    });
  }
  formatDate(date: any): string {
    const formattedDate = this.datePipe.transform(date, "yyyy-MM-dd");
    return formattedDate ? formattedDate : null;
  }

  // invoked when search button is clicked
  onSearchBtnClick() {
    this.spinnerService.setIsLoading(true);
    const key = this.searchField.nativeElement.value.trim()
      ? this.searchField.nativeElement.value.trim()
      : null;
    this.searchService
      .smartSearchMultiSiteAPI(key)
      .then(() => {
        var rowData = this.searchService.searchRecords;
        rowData.forEach((element) => {
          var expTime = moment(element.EstDepartureTime).format("HH:mm");
          element.StartTime = this.formatDate(element.StartTime);
          element.EndTime = this.formatDate(element.EndTime);
          var endDate = element.EndTime;
          var expired;
          if (expTime !== null && endDate !== null) {
            var fullDate = endDate.concat(" ", expTime);
            if (this.today > new Date(fullDate) && element.Active == 1) {
              expired = true;
              element.PassStatus = "Expired";
            } else {
              expired = false;
            }
          }
          if (element.Active == 1 && !expired) {
            element.PassStatus = "Active";
          } else if (element.Active == 0) {
            element.PassStatus = "Inactive";
          }
        });
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    this.searchService.searchInitiated.emit(true);
  }
  clearSearchField() {
    this.searchField.nativeElement.value = "";
    this.onSearchBtnClick();
  }
}
