import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatCheckboxChange, MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';
import { Router } from '@angular/router';
import { OktaAuthService } from '@okta/okta-angular';
import { AuthService } from '../services/auth.service';
import { NotificationService } from '../services/notification.service';
import { SiteService } from '../services/site.service';
import { UserService } from '../services/user.service';
import { CustomSnackbarComponent } from '../snack-bar/custom-snackbar/custom-snackbar.component';
import { MessageService } from '../services/message.service';

@Component({
  selector: 'app-user-verification',
  templateUrl: './user-verification.component.html',
  styleUrls: ['./user-verification.component.css']
})
export class UserVerificationComponent implements OnInit {

  public authResponse: any;
  protected availableSiteList: any;
  protected selectedSite: number;
  protected isRegisteredUser: boolean = false;
  protected isExternalUser: boolean = false;
  protected showDefaultSiteList: boolean = false;
  protected enableVerifyUser: boolean = false;
  protected onDropdownValue: number;
  protected onDropdownSelected: boolean = false;
  protected hideLoading: boolean = false;
  public aliasResponse: any;
  protected enableSubmit: boolean = false;
  protected neverShown: boolean = false;
  protected html: any;
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "bottom";
  protected userActive: number;
  protected userStatus: string;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  public acknowledgementResponse: any;
  protected agree: boolean = false;
  protected showACKform: boolean = false;

  constructor(
    private oktaAuthService: OktaAuthService,
    private authService: AuthService,
    private siteService: SiteService,
    private notificationService: NotificationService,
    private snackbar: MatSnackBar,
    private userService: UserService,
    private router: Router,
    protected messageService: MessageService,
  ) { }

  async ngOnInit() {

    //labels by message service
    if (localStorage.getItem('UserVerificationLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('UserVerificationLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "User Verification",
        "UserVerification"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('UserVerificationLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    const oktaEmail = await (await this.oktaAuthService.getUser()).email;
    //add addAliasCheck api
    this.authService.checkUserAliasEmailID(oktaEmail).subscribe(async (res: any) => {
      this.aliasResponse = res.body;
      localStorage["loggedInEmailID"] = this.aliasResponse[0].EmailID;
      if (this.aliasResponse[0].EmailID) {
        this.authService.userSiteListAuthorize(this.aliasResponse[0].EmailID).subscribe(async (res: any) => {
          this.authResponse = res.body;
          localStorage["userAuthorizeAPIUserID"] = this.authResponse[0].UserID;
          this.availableSiteList = this.authResponse[0].AvailableSiteData;
          this.siteService.availableSiteList = this.availableSiteList;
          this.userActive = this.authResponse[0].Active;
          this.userStatus = this.authResponse[0].Status;
          if (localStorage.getItem("PrefLanguage")) {
            this.messageService.setPrefLanguage(localStorage.getItem("PrefLanguage"));
          } else {
            this.messageService.setPrefLanguage(1);
          }
          this.checkUserConfigurations();
        },
          err => {
            this.authService.logout();
            let config = new MatSnackBarConfig();
            config.verticalPosition = this.verticalPosition;
            config.horizontalPosition = this.horizontalPosition;
            config.panelClass = ["red-snackbar"];
            this.snackbar.openFromComponent(CustomSnackbarComponent, config)
          });
      }
      else {
        this.authService.logout();
        let config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        config.panelClass = ["red-snackbar"];
        this.userService.sendToggle(true);
        this.snackbar.openFromComponent(CustomSnackbarComponent, config);
      }
    },
      err => {
        this.authService.logout();
        let config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        config.panelClass = ["red-snackbar"];
        this.snackbar.openFromComponent(CustomSnackbarComponent, config)
      });

  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  externalBadgeAPI(siteID: number) {
    this.authService.externalUserActiveBadge(this.authResponse[0].UserID, siteID).subscribe((res: HttpResponse<any>) => {
      if (res.body[0].Active >= 1) {
         //show ACK form
         this.getAcknowledgementInfo();
      }
      else {
        this.hideLoading = true;
        this.router.navigate(['/user-access-request']);
      }
    });
  }

  async checkUserConfigurations() {
    if (this.availableSiteList.length > 0 && this.userStatus !== "Suspended" && this.userActive && this.userStatus === "Approved") {
      this.authResponse[0].AvailableSiteData.forEach(element => {
        //check for default site id present or not
        if (this.authResponse[0].DefaultSiteID) {
          if ((element.SiteID == this.authResponse[0].DefaultSiteID)) {
            //setting registered user
            if (element.UserStatus === 'Registered') this.isRegisteredUser = true;
            // check to identify internal or external user
            if (element.IsExternal === 1) this.isExternalUser = true;
          }
        } else {
          this.router.navigate(['/user-access-request']);
        }

      });
      // Code for Internal User
      if (!this.isExternalUser) {
        if (this.isRegisteredUser) {
          //show ACK form
          this.getAcknowledgementInfo();
        }
        else if (!this.isRegisteredUser) {
          this.router.navigate(['/user-access-request']);
        }
      }
      // Code for External User
      else if (this.isExternalUser) {

        // External user with default siteID and Default Side Id is present
        if (this.isRegisteredUser) {
          this.externalBadgeAPI(this.authResponse[0].DefaultSiteID);
        }
        else if (!this.isRegisteredUser) {
          this.router.navigate(['/user-access-request']);
        }
      }
    }
    // code for the deactivated user, Active i.e; userActive will be 0 or 1
    else if (this.userStatus === "Approved") {
      this.router.navigate(['/user-access-request'])
    }
    // for suspended user
    else {
      this.authService.logout();
      let message = this.screenLabels["UserSuspendedMsg"] ? this.screenLabels["UserSuspendedMsg"]: "Your access to the application has been currently suspended. If you believe that this has been done in error, please contact your local site Security Administration or Site Administrator.";
      this.notificationService.failure(message);
    }
  }

  getAcknowledgementInfo() {
    this.authService.isAcknowledgementNeededAPI(this.authResponse[0].UserID, this.authResponse[0].DefaultSiteID).subscribe(async (resp: any) => {
      this.acknowledgementResponse = resp.body[0];
      if(this.acknowledgementResponse.isAcknowledgementNeeded === 1) {
        this.showACKform = true;
      }
      else if(this.acknowledgementResponse.isAcknowledgementNeeded === 0) {
        this.getAckUserSettings(false);
        this.enableVerifyUser = true;
        this.authService.setVerifyUser(this.enableVerifyUser);
      }
    },
    err => {
      this.authService.logout();
      let config = new MatSnackBarConfig();
      config.verticalPosition = this.verticalPosition;
      config.horizontalPosition = this.horizontalPosition;
      config.panelClass = ["red-snackbar"];
      this.snackbar.openFromComponent(CustomSnackbarComponent, config)
    });
  }

  onAcceptingACK(event) {
    //saving user ACK settings
    this.getAckUserSettings(event);
    //calling normal flow
    this.enableVerifyUser = true;
    this.authService.setVerifyUser(this.enableVerifyUser);
  }

  async getAckUserSettings(event: boolean) {
    await this.authService.getPolicyAcknowledgementUserSettings(this.authResponse[0].UserID).subscribe(async (res: HttpResponse<any>) => {
      let logoncount = event === true ? 1 : Number(res.body.logonCount[0].Value) + 1;
      let logonSettingsID = res.body.logonCount[0].SettingsID;
      let isACKAccepted = event === true ? 1 : 0;
      this.setAckUserSettings(logonSettingsID, logoncount, isACKAccepted);
    })
  }

  async setAckUserSettings(logonSettingsID, logoncount, isACKAccepted) {
    await this.authService.policyAcknowledgementUserSettingsUpdate(this.authResponse[0].UserID, logonSettingsID, logoncount.toString(), isACKAccepted)
  }
}
