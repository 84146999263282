import { EntryPointGroupMap } from "./entry-point-group-map.model";

export class EntryPointGroup {
  constructor(
    public EntryPointGroupID: number,
    public EntryPointGrpName: string,
    public ModifiedBy: string,
    public ModifiedDate: Date,
    public CreatedBy: string,
    public CreatedDate: Date,
    public Active:number
  ) {}
}
