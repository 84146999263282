import { Component, OnInit } from "@angular/core";
import { GridApi } from "ag-grid-community";
import { SearchService } from "src/app/services/search.service";
import { UserService } from "src/app/services/user.service";
import { MatDialog } from "@angular/material";
import { ViewLimitApprovalModalComponent } from "../modals/view-limit-approval-modal/view-limit-approval-modal.component";
import { NotificationService } from "src/app/services/notification.service";
import { SpinnerService } from "src/app/services/spinner.service";
import { DialogService } from "src/app/services/dialog.service";
import { LimitRequestService } from "src/app/services/limit-request.service";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import moment from "moment";
import { RejectApproveShortModalComponent } from "../modals/reject-approve-short-modal/reject-approve-short-modal.component";
import { SiteService } from "src/app/services/site.service";
import { DatePipe } from "@angular/common";
import { MessageService } from "src/app/services/message.service";
import { ApprovalService } from "src/app/services/approval.service";

@Component({
  selector: "app-limit-request-approvals",
  templateUrl: "./limit-requests-approvals.component.html",
  styleUrls: ["./limit-requests-approvals.component.css"],
})
export class LimitRequestApprovalsComponent implements OnInit {
  private gridApi: GridApi;
  protected isRowSelected = false;
  //private applicationUsers = [];
  protected rowData: Array<any> = [];
  protected selectedRowData;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  protected parkingLotGroupApprovalsColumnDefs = [];
  public defaultColDef: object;
  constructor(
    private searchService: SearchService,
    private userService: UserService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private spinnerService: SpinnerService,
    private dialogService: DialogService,
    private limitRequestService: LimitRequestService,
    protected siteService: SiteService,
    private datePipe: DatePipe,
    protected messageService: MessageService,
    private approvalService: ApprovalService,
  ) {
    this.defaultColDef = {
      resizable: true,
    }
  }

  ngOnInit(): void {
    this.searchService.setPageName("Limit Approvals");

    //labels by message service
    if (localStorage.getItem('LimitRequestApprovalLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('LimitRequestApprovalLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Limit Approvals",
        "OrgCombinedLimitRequest"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('LimitRequestApprovalLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    setTimeout(() => {
      this.spinnerService.setIsLoading(true);
    }, 0);
    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        this.rowData = this.searchService.searchRecords;
        this.rowData = this.rowData.filter(row => row && row.OrgCombinedLimitRequestID !== null);
        this.gridApi.paginationGoToPage(0);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    // new search initiated
    this.searchService.searchInitiated.subscribe(() => {
      this.searchService.smartSearchPromise
        .then(() => {
          this.isRowSelected = false;
          this.rowData = this.searchService.searchRecords;
          this.rowData = this.rowData.filter(row => row && row.OrgCombinedLimitRequestID !== null);
          this.gridApi.paginationGoToPage(0);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    });

    // subscribe to site changed
    this.siteService.siteChanged.subscribe((event: boolean) => {
      if (event) {
        this.searchService
          .smartSearchMultiSiteAPI(null)
          .then(() => {
            this.rowData = this.searchService.searchRecords;
            this.rowData = this.rowData.filter(row => row && row.OrgCombinedLimitRequestID !== null);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    });

    // requests are updated
    this.limitRequestService.requestUpdated.subscribe((event: boolean) => {
      this.searchService
        .smartSearchMultiSiteAPI(null)
        .then(() => {
          this.rowData = this.searchService.searchRecords;
          this.rowData = this.rowData.filter(row => row && row.OrgCombinedLimitRequestID !== null);
          this.gridApi.paginationGoToPage(0);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    });
  }

  setColumnDef() {
    this.parkingLotGroupApprovalsColumnDefs = [
      {
        headerName: "",
        field: "",
        width: 80,
        cellStyle: { color: "#589bf8" },
        hide: false,
        checkboxSelection: true,
        resizable: false,
        sortable: false,
        lockPosition: true,
      },
      {
        headerName: this.screenLabels["ApprovalRequestID"]
          ? this.screenLabels["ApprovalRequestID"]
          : "Approval Requested ID",
        field: "ApprovalRequestID",
        hide: true,
      },
      {
        headerName: this.screenLabels["RequestedID"]
          ? this.screenLabels["RequestedID"]
          : "Requested ID",
        field: "OrgCombinedLimitRequestID",
        sortable: true,
        width: 140,
        filter: true,
        cellStyle: { color: "#589bf8" },
        hide: false,
      },
      {
        headerName: this.screenLabels["RequestedOn"]
          ? this.screenLabels["RequestedOn"]
          : "Requested On",
        field: "CreatedDate",
        sortable: true,
        width: 150,
        filter: true,
        hide: false,
        cellRenderer: (data) => {
          const formattedDate = this.datePipe.transform(
            data.value,
            this.siteService.dataTimeFormat.angularDateFormat
          );

          return formattedDate ? formattedDate : null;

          // return data.value ? moment(data.value).format("MM/DD/YYYY") : null;
        },
      },
      {
        headerName: this.screenLabels["CreatedByName"]
          ? this.screenLabels["CreatedByName"]
          : "Requested By",
        field: "CreatedByName",
        sortable: true,
        width: 200,
        filter: true,
        hide: false,
        // cellRenderer: (data) => {
        //   if (this.applicationUsers.length > 0) {
        //     const user = this.userService
        //       .getApplicationUsers()
        //       .filter((e) => e.UserID === +data.value)[0];
        //     return user
        //       ? user.FirstName +
        //           (user.MiddleName === null ? "" : user.MiddleName) +
        //           " " +
        //           user.LastName
        //       : null;
        //   }
        // },
        cellClass: (params) => {
          return params.data.UserActive === 0 ? "inActiveLinkedField" : "";
        },
      },
      {
        headerName: this.screenLabels["StartDate"]
          ? this.screenLabels["StartDate"]
          : "Start Date",
        field: "StartDate",
        sortable: true,
        width: 110,
        filter: true,
        hide: false,
        cellRenderer: (data) => {
          const formattedDate = this.datePipe.transform(
            data.value,
            this.siteService.dataTimeFormat.angularDateFormat
          );
          return formattedDate ? formattedDate : null;

          // return data.value ? moment(data.value).format("MM/DD/YYYY") : null;
        },
      },
      {
        headerName: this.screenLabels["EndDate"]
          ? this.screenLabels["EndDate"]
          : "End Date",
        field: "EndDate",
        sortable: true,
        width: 110,
        filter: true,
        hide: false,
        cellRenderer: (data) => {
          const formattedDate = this.datePipe.transform(
            data.value,
            this.siteService.dataTimeFormat.angularDateFormat
          );

          return formattedDate ? formattedDate : null;

          // return data.value ? moment(data.value).format("MM/DD/YYYY") : null;
        },
      },
      {
        headerName: this.screenLabels["Limit"]
          ? this.screenLabels["Limit"]
          : "Limit",
        field: "NumVisitors",
        sortable: true,
        width: 110,
        filter: true,
        hide: false,
      },
      {
        headerName: this.screenLabels["Status"]
          ? this.screenLabels["Status"]
          : "Status",
        field: "Status",
        sortable: true,
        width: 110,
        filter: true,
        hide: false,
      },
      {
        headerName: this.screenLabels["UserActive"]
          ? this.screenLabels["UserActive"]
          : "User Active",
        field: "UserActive",
        sortable: true,
        width: 110,
        filter: true,
        hide: true,
      },
      {
        headerName: this.screenLabels["LastName"]
          ? this.screenLabels["LastName"]
          : "Last Name",
        field: "LastName",
        sortable: true,
        width: 110,
        filter: true,
        hide: true,
      },
      {
        headerName: this.screenLabels["FirstName"]
          ? this.screenLabels["FirstName"]
          : "First Name",
        field: "FirstName",
        sortable: true,
        width: 110,
        filter: true,
        hide: true,
      },
      {
        headerName: this.screenLabels["MiddleName"]
          ? this.screenLabels["MiddleName"]
          : "Middle Name",
        field: "MiddleName",
        sortable: true,
        width: 110,
        filter: true,
        hide: true,
      },

      {
        headerName: this.screenLabels["CreatedByName"]
          ? this.screenLabels["CreatedByName"]
          : "CreatedByName",
        field: "CreatedByName",
        sortable: true,
        width: 110,
        filter: true,
        hide: true,
      },
      {
        headerName: this.screenLabels["ModifiedByName"]
          ? this.screenLabels["ModifiedByName"]
          : "ModifiedByName",
        field: "ModifiedByName",
        sortable: true,
        width: 110,
        filter: true,
        hide: true,
      },
    ];
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDef()
  }

  // invoked when ag-grid is initialized
  onGridReady(params): void {
    this.gridApi = params.api;
  }

  // make ag-rid columns fill their container
  protected onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  // invoked when a row is clicked
  protected openApprovalRequest(event) {
    this.limitRequestService.getLimitRequestDetailsAPI(event.data.OrgCombinedLimitRequestID).subscribe((res: HttpResponse<any>) => {
      let reqResponse = res.body;
      reqResponse.OrgCombinedLimitRequestID = event.data.OrgCombinedLimitRequestID;
      if (this.approvalService.orgLimitRequestOpen) return;
      this.dialog.open(ViewLimitApprovalModalComponent, {
        disableClose: true,
        data: reqResponse,
      });
      this.approvalService.orgLimitRequestOpen = true;
    }, error => {
      console.log(error);
    });
  }

  // invoked when a request is being approved
  protected onApprove() {

    if (
      this.selectedRowData.Status !== "Cancelled" ||
      this.selectedRowData.Status !== "Rejected"
    ) {
      const modalData = { ...this.selectedRowData };
      modalData.type = "Approve";
      this.dialog.open(RejectApproveShortModalComponent, {
        disableClose: true,
        data: modalData,
      });
    } else {
      this.dialogService.openAlertDialogDialog(
        this.screenLabels['CannotApproveRequestErrorMsg'] ? this.screenLabels['CannotApproveRequestErrorMsg'] : "Cannot Approve Request",
        this.screenLabels['CannotApproveCancelledRequestErrorMsg'] ? this.screenLabels['CannotApproveCancelledRequestErrorMsg'] : "Cannot Approve a Cancelled Request."
      );
    }
  }

  // invoked when a request is being rejected
  protected onReject() {
    if (
      this.selectedRowData.Status !== "Cancelled" ||
      this.selectedRowData.Status !== "Approved"
    ) {
      const modalData = { ...this.selectedRowData };
      modalData.type = "Reject";

      this.dialog.open(RejectApproveShortModalComponent, {
        disableClose: true,
        data: modalData,
      });
    } else {
      this.dialogService.openAlertDialogDialog(
        this.screenLabels['CannotRejectRequestErrorMsg'] ? this.screenLabels['CannotRejectRequestErrorMsg'] : "Cannot Reject Request",
        this.screenLabels['CannotRejectCancelledRequestErrorMsg'] ? this.screenLabels['CannotRejectCancelledRequestErrorMsg'] : "Cannot Reject a Cancelled Request."
      );
    }
  }

  // invoked when a row selection is changed from the ag-Grid
  protected onSelectionChanged(event) {
    const selectedRows = event.api.getSelectedRows();

    if (selectedRows.length) {
      this.isRowSelected = true;
      this.selectedRowData = selectedRows[0];
    } else {
      this.isRowSelected = false;
    }
  }
}
