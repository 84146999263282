import { SiteService } from "../services/site.service";
import { PersonOfInterestService } from '../services/person-of-interest.service';
import { MessageService } from "../services/message.service";
import { DatePipe } from "@angular/common";

export class PersonOfInterestAgGridConfig {
  private _personOfInterestColumnDefs = [];
  protected restrictionTypa: Array<{
    PickListID: number;
    TableName: string;
    FieldName: string;
    DisplayValue: string;
    Active: boolean;
  }> = [];

  get personOfInterestColumnDefs() {
    return this._personOfInterestColumnDefs;
  }

  constructor( 
    protected personOfInterestService:PersonOfInterestService,
    private screenLabels,) {
    this._personOfInterestColumnDefs = [
      {
        headerName:this.screenLabels["PersonOfInterestID"] ?
        this.screenLabels["PersonOfInterestID"]:
        "Person Of Interest ID" ,
        field: "PersonOfInterestID",
        hide: true,
        // sort: "desc",
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridLastName"]
          ? this.screenLabels["AgGridLastName"]
          : "Last Name",
        field: "LastName",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["FirstName"]
          ? this.screenLabels["FirstName"]
          : "First Name",
        field: "FirstName",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridMiddleName"]
          ? this.screenLabels["AgGridMiddleName"]
          : "Middle Name",
        field: "MiddleName",
        hide: false,
        sortable: true,
      },

      {
        headerName: this.screenLabels["AgGridSuffix"]
          ? this.screenLabels["AgGridSuffix"]
          : "Suffix",
        field: "Suffix",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridCompany"]
          ? this.screenLabels["AgGridCompany"]
          : "Company",
        field: "Company",
        hide: false,
        sortable: true,
      },

      {
        headerName: this.screenLabels["AgGridEmail"]
          ? this.screenLabels["AgGridEmail"]
          : "Email",
        field: "Email",
        hide: false,
        sortable: true,
      },
      // {
      //   headerName: this._labelService.screenLabels["AgGridPhone"]
      //     ? this._labelService.screenLabels["AgGridPhone"]
      //     : "Phone",
      //   field: "Phone",
      //   cellStyle: { color: "#ffff" },
      //   hide: false,
      //   resizable: true,
      //   sortable: true,
      // },

      // {
      //   headerName: this._labelService.screenLabels["AgGridNotes"]
      //     ? this._labelService.screenLabels["AgGridNotes"]
      //     : "Notes",
      //   field: "Notes",
      //   cellStyle: { color: "#ffff" },
      //   hide: false,
      //   resizable: true,
      //   sortable: true,
      // },
      {
        headerName: this.screenLabels["AgGridNotificationEmail"]
          ? this.screenLabels["AgGridNotificationEmail"]
          : "Email notification",
        field: "NotificationEmail",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridRestrictedOn"]
          ? this.screenLabels["AgGridRestrictedOn"]
          : "Restricted On Date",
        field: "RestrictedOn",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridRestrictedEndDate"]
          ? this.screenLabels["AgGridRestrictedEndDate"]
          : "Restricted EndDate",
        field: "RestrictionEndDate",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridRestrictedReason"]
          ? this.screenLabels["AgGridRestrictedReason"]
          : "Restricted Reason",
        field: "ResReasonDisplayValue",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridRestrictedRestrictionType"]
          ? this.screenLabels["AgGridRestrictedRestrictionType"]
          : "Restriction Type",
        field: "ResTypeDisplayValue",
        hide: false,
        sortable: true,
      },
    ];
  }
}
