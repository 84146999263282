import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { SearchService } from "src/app/services/search.service";
import { PassService } from "src/app/services/pass.service";
import { DatePipe } from "@angular/common";
import { BuildingService } from "src/app/services/building.service";
import { EntryPointService } from "src/app/services/entry-point.service";
import { NotificationService } from "src/app/services/notification.service";
import { DialogService } from "src/app/services/dialog.service";
import { SpinnerService } from "src/app/services/spinner.service";
import { UserService } from "src/app/services/user.service";
import { RoleMapService } from "src/app/services/role-map.service";
import { AuthService } from "src/app/services/auth.service";
import moment from "moment";
import { RejectApprovePassModalComponent } from "../modals/reject-approve-pass-modal/reject-approve-pass-modal.component";
import { viewPassApprovalModalComponent } from "../modals/view-pass-approval-modal/view-pass-approval-modal.component";
import { LimitRequestService } from "src/app/services/limit-request.service";
import { Site, SiteService } from "src/app/services/site.service";
import { UtilityService } from "src/app/services/utility.service";
import { GridApi } from "ag-grid-community";
import { ViewPassOnCreateApprovalComponent } from "../modals/view-pass-on-create-approval/view-pass-on-create-approval.component";
import { MessageService } from "src/app/services/message.service";
import { ApprovalService } from "src/app/services/approval.service";
@Component({
  selector: "app-pass-on-create-approvals",
  templateUrl: "./pass-on-create-approvals.component.html",
  styleUrls: ["./pass-on-create-approvals.component.css"],
})
export class PassOnCreateApprovals implements OnInit {
  isVisitReasonLoading = true;
  isBuildingLoading = true;
  isEntryPointLoading = true;
  visitReasons: Array<any> = [];
  protected isRowSelected: boolean;
  private createdDate: string;
  private modifiedDate: string;
  private createdBy: string;
  private modifiedBy: string;
  protected userOrganizations: Array<{
    OrganizationID: number;
    Name: string;
    StartDate: string;
    EndDate: string;
    IsAdmin: number;
  }> = [];
  protected userRoles: Array<{
    RoleID: number;
    Active: string;
    UserCheckin: boolean;
  }> = [];
  public selectedOrg: number = 0;
  public userCheckIn: boolean = false;
  public userCheckedInEntryPoint: number = 0;
  public ipAddress: string;
  protected callInUser: boolean = false;
  AuthoriserOrganizations: Array<{
    UserOrganizationID: number;
    OrganizationID: number;
    Name: string;
    Active: number;
  }> = [];
  dateNow: Date = this.utilService.getCurrentSiteDT();

  protected selectedRowData;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  protected columnDefs = [];
  public defaultColDef : object;
  constructor(
    private dialog: MatDialog,
    private http: HttpClient,
    protected searchService: SearchService,
    protected passService: PassService,
    private datePipe: DatePipe,
    private buildingService: BuildingService,
    private entryPointService: EntryPointService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private spinnerService: SpinnerService,
    private userService: UserService,
    private roleMapService: RoleMapService,
    protected authService: AuthService,
    protected limitService: LimitRequestService,
    protected siteService: SiteService,
    private utilService: UtilityService,
    protected messageService: MessageService,
    private approvalService: ApprovalService,
  ) {
    this.isRowSelected = false;
    this.callInUser = false;

    // load Grid based on UserRole
    this.roleMapService
      .getFunctions()
      .then(() => {})
      .catch((err) => {
        this.notificationService.failure(err.message);
      });

    this.roleMapService
      .getRoleMappingsAPI()
      .then(() => {
        this.userService
          .getUserAssociationAPI(
            this.userService.userID,
            this.siteService.selectedSiteID
          )
          .then(() => {
            this.userOrganizations = [
              ...this.userService
                .getUserDetails()[0]
                .UserOrganization.map((o) => ({
                  OrganizationID: o.OrganizationID,
                  Name: o.Name,
                  StartDate: o.Startdate,
                  EndDate: o.EndDate,
                  IsAdmin: o.IsAdmin ? (o.IsAdmin == 1 ? 1 : 0) : 0,
                })),
            ];
            this.userService.userOrganizations = this.userOrganizations;

            // setting the organization id for the first time
            if (this.userService.organizationID <= 0) {
              this.userService.organizationID = this.userOrganizations[0].OrganizationID;
              this.userService.IsAdmin = this.userOrganizations[0].IsAdmin
                ? this.userOrganizations[0].IsAdmin == 1
                  ? 1
                  : 0
                : 0;
            }
            // to get Ip Adress
            this.userRoles = [
              ...this.userService
                .getUserDetails()[0]
                .UserRole.map((r) => ({
                  RoleID: r.RoleID,
                  Active: r.UserRoleActive,
                  UserCheckin: this.roleMapService
                    .getSelectedFunctions(r.RoleID)
                    .filter(
                      (f) =>
                        f.Active &&
                        f.FunctionID ==
                          this.roleMapService.getPassCheckinFunctionID()
                    ).length
                    ? true
                    : false,
                }))
                .filter((f) => f.Active),
            ];

            // Check if logged in userRole has checkin privileges

            this.userCheckIn = this.userRoles.filter((f) => f.UserCheckin)
              .length
              ? true
              : false;
            // var getInternalIp = require("get-internal-ip");
            // var ipAdress;
            this.userService.userCheckIn = this.userCheckIn;
            this.userService.userDetailsLoaded.emit(true); // emitter to load nav-bar after dashboard is loaded

            if (this.userCheckIn) {
              //if user has checkin then set entrypointdrop and load Passes based on staion Ip
              if (
                this.userOrganizations.length > 0 &&
                this.userService.organizationID <= 0
              ) {
                this.selectedOrg = this.userOrganizations[0].OrganizationID;
                this.userService.organizationID = this.selectedOrg;

                this.userService.IsAdmin = this.userOrganizations[0].IsAdmin
                  ? this.userOrganizations[0].IsAdmin == 1
                    ? 1
                    : 0
                  : 0;
                this.userService.organizationChanged.emit(true);

                this.searchService.setOrganizationID(this.selectedOrg);
              }
            } else {
              //Api call to get entry points
              if (this.userOrganizations.length > 0) {
                this.selectedOrg = this.userOrganizations[0].OrganizationID;
                this.searchService.setOrganizationID(this.selectedOrg);
              }
            }
            this.searchService
              .smartSearchMultiSiteAPI(null)
              .then(() => {
                this.rowData = this.searchService.searchRecords;
                this.rowData = this.rowData.filter(row => row && row.PassID !== null);
                this.rowData.forEach((element) => {
                  element.StartTime = this.formatDate(element.StartTime);
                  element.EndTime = this.formatDate(element.EndTime);
                });
              })
              .catch((err: HttpErrorResponse) => {
                this.notificationService.failure(err.message);
              });
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    this.passService
      .getVisitReasonsAPI()
      .then(() => {
        this.isVisitReasonLoading = false;
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    this.buildingService
      .parseAPI()
      .then(() => {
        this.isBuildingLoading = false;
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
      this.defaultColDef = {
        resizable: true,
    }
  }

  rowData: any;
  params: [];
  private gridApi: GridApi;
  // data for selected row
  passId: any;
  EmployeeRef: any;
  activeOn: any;
  expiresOn: any;
  guestName: any;
  guestFirst: any;
  guestLast: any;
  guestCompany: any;
  guestPhone: any;
  guestEmail: any;
  hostName: any;
  hostFirst: any;
  hostLast: any;
  hostPhone: any;
  hostEmail: any;
  guestReason: any;
  arrivalTime: any;
  departureTime: any;
  guestDestination: any;
  entryType: any;
  entryPoint: any;
  assignedRoom: any;
  assignedParking: any;
  passComment: any;
  internalComment: any;
  fullArrival: any;
  fullDeparture: any;

  
  private isMobile = false;

  onGridReady(params) {
    this.gridApi = params.api;
  }
  // get grid

  ngOnInit() {
    this.searchService.setPageName("Pass Create Approvals");

    //labels by message service
    if (localStorage.getItem('PassOnCreateLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('PassOnCreateLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Pass Create Approvals",
        "Pass"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('PassOnCreateLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    this.userService
      .getUserAssociationAPI(
        this.userService.userID,
        this.siteService.selectedSiteID
      )
      .then(() => {
        this.userOrganizations = [
          ...this.userService
            .getUserDetails()[0]
            .UserOrganization.map((o) => ({
              OrganizationID: o.OrganizationID,
              Name: o.Name,
              StartDate: o.Startdate,
              EndDate: o.EndDate,
              IsAdmin: o.IsAdmin ? (o.IsAdmin == 1 ? 1 : 0) : 0,
            })),
        ];
        this.userService.userOrganizations = this.userOrganizations;

        // setting the organization id for the first time
        if (this.userService.organizationID <= 0) {
          this.userService.organizationID = this.userOrganizations[0].OrganizationID;

          this.userService.IsAdmin = this.userOrganizations[0].IsAdmin
            ? this.userOrganizations[0].IsAdmin == 1
              ? 1
              : 0
            : 0;
        }

        // to get Ip Adress
        this.userRoles = [
          ...this.userService
            .getUserDetails()[0]
            .UserRole.map((r) => ({
              RoleID: r.RoleID,
              Active: r.UserRoleActive,
              UserCheckin: this.roleMapService
                .getSelectedFunctions(r.RoleID)
                .filter(
                  (f) =>
                    f.Active &&
                    f.FunctionID ==
                      this.roleMapService.getPassCheckinFunctionID()
                ).length
                ? true
                : false,
            }))
            .filter((f) => f.Active),
        ];

        // Check if logged in userRole has checkin privileges

        this.userCheckIn = this.userRoles.filter((f) => f.UserCheckin).length
          ? true
          : false;
        // var getInternalIp = require("get-internal-ip");
        // var ipAdress;

        this.userService.userCheckIn = this.userCheckIn;
        this.userService.userDetailsLoaded.emit(true); // emitter to load nav-bar after dashboard is loaded

        if (this.userCheckIn) {
          //if user has checkin then set entrypointdrop and load Passes based on staion Ip
          if (
            this.userOrganizations.length > 0 &&
            this.userService.organizationID <= 0
          ) {
            this.selectedOrg = this.userOrganizations[0].OrganizationID;
            this.userService.organizationID = this.selectedOrg;
            this.userService.IsAdmin = this.userOrganizations[0].IsAdmin
              ? this.userOrganizations[0].IsAdmin == 1
                ? 1
                : 0
              : 0;
            this.userService.organizationChanged.emit(true);

            this.searchService.setOrganizationID(this.selectedOrg);
          }
        } else {
          //Api call to get entry points
          if (this.userOrganizations.length > 0) {
            this.selectedOrg = this.userOrganizations[0].OrganizationID;
            this.searchService.setOrganizationID(this.selectedOrg);
          }
        }
        this.searchService
          .smartSearchMultiSiteAPI(null)
          .then(() => {
            this.rowData = this.searchService.searchRecords;
            this.rowData = this.rowData.filter(row => row && row.PassID !== null);
            this.rowData.forEach((element) => {
              element.StartTime = this.formatDate(element.StartTime);
              element.EndTime = this.formatDate(element.EndTime);
            });

            this.gridApi.paginationGoToPage(0);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        this.rowData = this.searchService.searchRecords;
        this.rowData = this.rowData.filter(row => row && row.PassID !== null);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    setTimeout(() => {
      this.spinnerService.setIsLoading(true);
    }, 0);

    this.limitService.requestUpdated.subscribe((e) => {
      this.searchService
        .smartSearchMultiSiteAPI(null)
        .then(() => {
          this.rowData = this.searchService.searchRecords;
          this.rowData = this.rowData.filter(row => row && row.PassID !== null);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    });

    this.searchService.searchInitiated.subscribe((eventData: boolean) => {
      if (eventData) {
        this.searchService.smartSearchPromise
          .then(() => {
            this.rowData = this.searchService.searchRecords;
            this.rowData = this.rowData.filter(row => row && row.PassID !== null);
            this.rowData.forEach((element) => {
              element.StartTime = this.formatDate(element.StartTime);
              element.EndTime = this.formatDate(element.EndTime);
            });
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    });
  }

  setColumnDef() {
    // ag-grid
    this.columnDefs = [
      {
        headerName: this.screenLabels["AgGridPassID"]
          ? this.screenLabels["AgGridPassID"]
          : "Pass ID",
        field: "PassID",
        // width: 110,
        cellStyle: { color: "#328CF0" },
        // sort: "desc",
        hide: false,
        sortable: true,
      },
      {
        headerName:this.screenLabels["EmployeeRef"]
        ? this.screenLabels["EmployeeRef"]
        : " Employee Ref", 
        field: "EmployeeRef",
        hide: true,
      },
      {
        headerName: this.screenLabels["AgGridActiveOn"]
          ? this.screenLabels["AgGridActiveOn"]
          : "Active On",
        field: "StartTime",
        cellRenderer: (data) => {
          // const formattedDate = this.datePipe.transform(data.value, "MM/dd/yyyy");
          // return formattedDate ? formattedDate : null;
          const formattedDate = this.datePipe.transform(
            data.value,
            this.siteService.dataTimeFormat.angularDateFormat
          );
  
          return formattedDate ? formattedDate : null;
        },
        filter: "agDateColumnFilter",
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridExpiresOn"]
          ? this.screenLabels["AgGridExpiresOn"]
          : "Expires On",
        field: "EndTime",
        //width: 125,
        cellRenderer: (data) => {
          // const formattedDate = this.datePipe.transform(data.value, "MM/dd/yyyy");
          // return formattedDate ? formattedDate : null;
          const formattedDate = this.datePipe.transform(
            data.value,
            this.siteService.dataTimeFormat.angularDateFormat
          );
          return formattedDate ? formattedDate : null;
        },
        filter: "agDateColumnFilter",
        sortable: true,
        // sortable: true,
      },
      {
        headerName:this.screenLabels["VisitorNameSuffix"]
        ? this.screenLabels["VisitorNameSuffix"]
        : "Visitor Suffix", 
        field: "VisitorNameSuffix",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridVisitorFirstName"]
          ? this.screenLabels["AgGridVisitorFirstName"]
          : "Visitor First Name",
        field: "VisitorFirstName",
        sortable: true,
      },
      // {
      //   headerName: this.screenLabels["AgGridVisitorMiddleName"]
      //     ? this.screenLabels["AgGridVisitorMiddleName"]
      //     : "Visitor Middle Name",
      //   field: "VisitorMiddleName",
      //   hide: true,
      //   sortable: true,
      // },
      {
        headerName: this.screenLabels["AgGridVisitorLastName"]
          ? this.screenLabels["AgGridVisitorLastName"]
          : "Visitor Last Name",
        field: "VisitorLastName",
        sortable: true,
      },
      {
        headerName: this.screenLabels["VisitorCompany"]
        ? this.screenLabels["VisitorCompany"]
        : "Visitor Company",
        field: "VisitorCompany",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["VisitorPhone"]
        ? this.screenLabels["VisitorPhone"]
        : "Visitor Phone Number", 
        field: "VisitorPhone",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["VisitorEmail"]
        ? this.screenLabels["VisitorEmail"]
        : "Visitor Email", 
        field: "VisitorEmail",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridHostFirstName"]
          ? this.screenLabels["AgGridHostFirstName"]
          : "Host First Name",
        field: "HostFirstName",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridHostLastName"]
          ? this.screenLabels["AgGridHostLastName"]
          : "Host Last Name",
        field: "HostLastName",
        hide: false,
        sortable: true,
      },
      {
        headerName:this.screenLabels["HostPhone"]
        ? this.screenLabels["HostPhone"]
        : "Host Phone Number", 
        field: "HostPhone",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["HostEmail"]
        ? this.screenLabels["HostEmail"]
        : "Host Email", 
        field: "HostEmail",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridVisitReason"]
          ? this.screenLabels["AgGridVisitReason"]
          : "Reason For Visit",
        field: "VisitReason",
        // width: 175,
        cellRenderer: (data) => {
          if (!this.isVisitReasonLoading) {
            const reason = this.passService.reasonOfVisit
              .filter((r) => r.PickListID === +data.value)
              .map((e) => e.DisplayValue)[0];
  
            return reason ? reason : null;
          }
        },
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridArrivalTime"]
          ? this.screenLabels["AgGridArrivalTime"]
          : "Arrival Time",
        field: "EstArrivalTime",
        cellRenderer: (data) => {
          // return data.value ? moment(new Date(data.value)).format("HH:mm") : null;
          const formattedDate = this.datePipe.transform(
            data.value,
            this.siteService.dataTimeFormat.angularTimeFormat
          );
  
          return formattedDate ? formattedDate : null;
        },
        // width: 150,
        // filter: "agDateColumnFilter",
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridDepartureTime"]
          ? this.screenLabels["AgGridDepartureTime"]
          : "Departure Time",
        field: "EstDepartureTime",
        hide: true,
        cellRenderer: (data) => {
          // return data.value ? moment(data.value).format("HH:mm") : null;
          const formattedDate = this.datePipe.transform(
            data.value,
            this.siteService.dataTimeFormat.angularTimeFormat
          );
  
          return formattedDate ? formattedDate : null;
        },
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridDestination"]
          ? this.screenLabels["AgGridDestination"]
          : "Destination",
        field: "DestinationBldg",
        // width: 110,
        cellRenderer: (data) => {
          if (!this.isBuildingLoading) {
            const destination = this.buildingService
              .getBuildings()
              .filter((b) => b.BuildingID === +data.value)
              .map((e) => e.BuildingName)[0];
  
            return destination ? destination : null;
          }
        },
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridEntryType"]
          ? this.screenLabels["AgGridEntryType"]
          : "Entry Type",
        field: "EntryType",
        // width: 110,
        cellRenderer: (data) => {
          if (+data.value === 1) {
            return "Drive-on";
          } else if (+data.value === 2) {
            return "Walk-on";
          }
          return null;
        },
        sortable: true,
        hide:this.siteService._siteHasParking? false:true
      },
      {
        headerName: this.screenLabels["AgGridExpectedPoint"]
          ? this.screenLabels["AgGridExpectedPoint"]
          : "Expected Point",
        field: "ExpectedEntryPoint",
        // width: 150,
        cellRenderer: (data) => {
          if (!this.isEntryPointLoading && data.value) {
            const entryPoint = this.entryPointService.entryPoints
              .filter((e) => e.EntryPointID === +data.value)
              .map((e) => e.Description)[0];
            return entryPoint ? entryPoint : null;
          }
        },
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridRoom"]
          ? this.screenLabels["AgGridRoom"]
          : "Rooms",
        field: "DestinationRoom",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridParkingAssignment"]
          ? this.screenLabels["AgGridParkingAssignment"]
          : "Parking Assignment",
        field: "ParkingLotID",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridInternalComment"]
          ? this.screenLabels["AgGridInternalComment"]
          : "Internal Commnent",
        field: "NotesInternal",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridPassComment"]
          ? this.screenLabels["AgGridPassComment"]
          : "Pass Comment",
        field: "NotesPass",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["EstArrivalTime"]
        ? this.screenLabels["EstArrivalTime"]
        : "Arrival Time", 
        field: "EstArrivalTime",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["EstDepartureTime"]
        ? this.screenLabels["EstDepartureTime"]
        : "Departure Time", 
        field: "EstDepartureTime",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["DestinationBldgActive"]
        ? this.screenLabels["DestinationBldgActive"]
        : "Destination Building Active",
        field: "DestinationBldgActive",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["DestinationBlgFlrActive"]
        ? this.screenLabels["DestinationBlgFlrActive"]
        : "Destination Buildingg Floor Active", 
        field: "DestinationBlgFlrActive",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["ExpectedEntryPointActive"]
        ? this.screenLabels["ExpectedEntryPointActive"]
        : "Expected Entry Point Active", 
        field: "ExpectedEntryPointActive",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["EntryPointGroupIDActive"]
        ? this.screenLabels["EntryPointGroupIDActive"]
        : "Entry Point Group ID Active", 
        field: "EntryPointGroupIDActive",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["OrganizationIDActive"]
        ? this.screenLabels["OrganizationIDActive"]
        : "Organization ID Active", 
        field: "OrganizationIDActive",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["ParkingLotIDActive"]
        ? this.screenLabels["ParkingLotIDActive"]
        : "ParkingLot ID Active", 
        field: "ParkingLotIDActive",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["EntryTypeActive"]
        ? this.screenLabels["EntryTypeActive"]
        : "Entry Type Active", 
        field: "EntryTypeActive",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["VisitReasonActive"]
        ? this.screenLabels["VisitReasonActive"]
        : "Visit Reason Active",
        field: "VisitReasonActive",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["OperatorActive"]
        ? this.screenLabels["OperatorActive"]
        : "Operator Active", 
        field: "OperatorActive",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["AuthorizerActive"]
        ? this.screenLabels["AuthorizerActive"]
        : "Authorizer Active", 
        field: "AuthorizerActive",
        hide: true,
        sortable: true,
      },
    ];
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDef()
}

  formatDate(date: any): string {
    const formattedDate = this.datePipe.transform(date, "yyyy-MM-dd");
    return formattedDate ? formattedDate : null;
  }

  // make ag-rid columns fill their container
  protected onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  // invoked when a row is clicked
  protected openApprovalRequest(event) {
    if (!this.isVisitReasonLoading) {
      this.guestReason = this.passService.reasonOfVisit
        .filter((e) => e.PickListID === event.data.VisitReason)
        .map((e) => e.DisplayValue);
    }
    this.passService.getPassCreateDetailsAPI(event.data.PassID).subscribe((res: HttpResponse<any>) => {
      let passDetails =  res.body[0];
      passDetails.guestReason = this.guestReason;
      passDetails.PassID = event.data.PassID;
      if (this.approvalService.passCreateApprovalOpen) return;
      this.dialog.open(ViewPassOnCreateApprovalComponent, {
        disableClose: true,
        data: passDetails,
      });
      this.approvalService.passCreateApprovalOpen = true;
    }, error => {
      console.log(error);
    });
  }

  // invoked when a request is being approved
  protected onApprove() {

    if (this.selectedRowData.Status !== "Cancelled") {
      const modalData = { ...this.selectedRowData };
      modalData.type = "Approve";
      this.dialog.open(RejectApprovePassModalComponent, {
        disableClose: true,
        data: modalData,
      });
    } else {
      this.dialogService.openAlertDialogDialog(
        this.screenLabels['CannotApproveRequestMsg'] ? this.screenLabels['CannotApproveRequestMsg'] : 'Cannot Approve Request',
        this.screenLabels['CannotApproveCancelledReqMsg'] ? this.screenLabels['CannotApproveCancelledReqMsg'] : 'Cannot Approve a Cancelled Request.'
      );
    }
  }

  // invoked when a request is being rejected
  protected onReject() {
    if (this.selectedRowData.Status !== "Cancelled") {
      const modalData = { ...this.selectedRowData };
      modalData.type = "Reject";

      this.dialog.open(RejectApprovePassModalComponent, {
        disableClose: true,
        data: modalData,
      });
    } else {
      this.dialogService.openAlertDialogDialog(
        this.screenLabels['CannotRejectRequestMsg'] ? this.screenLabels['CannotRejectRequestMsg'] : 'Cannot Reject Request',
        this.screenLabels['CannotRejectCancelledReqMsg'] ? this.screenLabels['CannotRejectCancelledReqMsg'] : 'Cannot Reject a Cancelled Request.'
      );
    }
  }

  // invoked when a row selection is changed from the ag-Grid
  protected onSelectionChanged(event) {
    const selectedRows = event.api.getSelectedRows();
    if (selectedRows.length) {
      this.isRowSelected = true;
      this.selectedRowData = selectedRows[0];
    } else {
      this.isRowSelected = false;
    }
  }
}
