import { Injectable, Output, EventEmitter } from "@angular/core";
import { UserService } from "./user.service";
import { NotificationService } from "./notification.service";
import { HttpHeaders, HttpResponse, HttpClient } from "@angular/common/http";
import { TemplateCategory } from "../models/template-category,model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TemplateCategoryService {
  private templateCategories: TemplateCategory[];
  private templateCategoryApiData: TemplateCategory[];

  @Output() templateCategoryDataChanged: EventEmitter<
    boolean
  > = new EventEmitter<boolean>();

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private notificationService: NotificationService
  ) {
    this.templateCategoryDataChanged = new EventEmitter<boolean>();
    this.templateCategories = [];
  }

  // Method to return Template Category array
  public getTemplateCategory(): TemplateCategory[] {
    return [...this.templateCategories];
  }

  public getTemplateCategoryAPI(): Promise<any> {
    const apiURL = environment.getAPI("getTemplateCategory");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const postBody = {
      TemplateCategoryID: null,
    };
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.templateCategoryApiData = success.body;
            this.templateCategories = this.templateCategoryApiData;
            console.log(this.templateCategories);
            resolve();
          },
          (err) => {
            reject(err);
          }
        );
    });
    return promise;
  }

  public createTemplateCategoryAPI(templateCategory: TemplateCategory) {
    const apiURL = environment.getAPI("templateCategoryCreateUpdate");

    const postBody = {
      TemplateCategoryID: 0,
      OrganizationID: this.userService.organizationID,
      Category: templateCategory.Category,
      Active: 1,
      UserID: this.userService.userID,
    };
    console.log(postBody);
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            console.log(success.body);
            this.templateCategoryDataChanged.emit(true);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public updateTemplateCategoryAPI(templateCategory: TemplateCategory) {
    const apiURL = environment.getAPI("templateCategoryCreateUpdate");

    const postBody = {
      TemplateCategoryID: templateCategory.TemplateCategoryID,
      OrganizationID: this.userService.organizationID,
      Category: templateCategory.Category,
      Active: 1,
      UserID: this.userService.userID,
    };
    console.log(postBody);
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            console.log(success.body);
            this.templateCategoryDataChanged.emit(true);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public deleteTemplateCategoryAPI(templateCategory: TemplateCategory) {
    const apiURL = environment.getAPI("templateCategoryCreateUpdate");

    const postBody = {
      TemplateCategoryID: templateCategory.TemplateCategoryID,
      OrganizationID: this.userService.organizationID,
      Category: templateCategory.Category,
      Active: 0,
      UserID: this.userService.userID,
    };
    console.log(postBody);
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            console.log(success.body);
            this.templateCategoryDataChanged.emit(true);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }
}