export class EntryPoint {
  public CreatedByName: string;
  public ModifiedByName: string;
  constructor(
    public EntryPointID: number,
    public Description: string,
    public Phone: string,
    public EmailDirections: string,
    public Active: number,
    public EntryPointZoneID: number,
    public EntryPointZoneName: string,
    public ModifiedBy: number,
    public ModifiedDate: Date,
    public CreatedBy: number,
    public CreatedDate: Date,
    public IPAddress: string,
    public HostName:string,
    public EntryPointStationID: number
  ) {
    this.Active = 1;
  }
}
