import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Inject,
  OnDestroy,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  MatRadioChange,
  MatSelectChange,
  MatCheckboxChange,
  MatChipInputEvent,
  MatAutocompleteTrigger,
  MatAutocompleteSelectedEvent,
  DateAdapter,
  MAT_DATE_FORMATS,
} from "@angular/material";
import { map, startWith, min } from "rxjs/operators";

import { cloneDeep } from "lodash";

import { SearchService } from "src/app/services/search.service";
import { DialogService } from "src/app/services/dialog.service";
import { Role } from "src/app/models/role.model";
import { FormControl } from "@angular/forms";
import { ENTER, COMMA } from "@angular/cdk/keycodes";
import { RoleMapService } from "src/app/services/role-map.service";
import { Observable, Subscription } from "rxjs";
import { UtilityService } from "src/app/services/utility.service";
import { UserService } from "src/app/services/user.service";
import { TeamService } from "src/app/services/team-service";
import { MatChipAutoCompleteComponent } from "src/app/common/mat-chip-auto-complete/mat-chip-auto-complete.component";
import { SiteService } from "src/app/services/site.service";
import { NullTemplateVisitor } from "@angular/compiler";
import { IInviteUserApproval } from "src/app/models/interfaces/invite-user-approval";
import { UserInvitationService } from "src/app/services/user-invitation.service";
import { NotificationService } from "src/app/services/notification.service";
import { HttpErrorResponse } from "@angular/common/http";
import { IUserInvitationData } from "src/app/models/interfaces/user-invitation-search-data";

import {
  ApprovalService,
  IApprovalRequestStatusResponse,
} from "src/app/services/approval.service";
import {
  ApproveRejectCellRendererComponent,
  IApproveRejectEmitter,
} from "src/app/common/AgGrid/approve-reject-cell-renderer/approve-reject-cell-renderer.component";
import { DatePipe } from "@angular/common";
import {
  MatDatePickerAdapter,
  CUSTOM_DATE_FORMATS,
} from "src/app/common/mat-date-picker-formatter/mat-date-picker-formatter";
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: "app-view-user-invitation-approval-modal",
  templateUrl: "./view-user-invitation-approval-modal.component.html",
  styleUrls: ["./view-user-invitation-approval-modal.component.css"],
  providers: [
    { provide: DateAdapter, useClass: MatDatePickerAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export class ViewUserInvitationApprovalModal implements OnInit, OnDestroy {
  protected step = 0;

  private _gridApi;
  private _gridColumnApi;

  protected rolesLoaded: boolean = false;
  protected teamsLoaded: boolean = false;
  protected approvalStatusLoaded: boolean = false;

  protected allRoles: Role[] = [];
  protected submittedRoles: Role[] = [];

  protected existingRolesNames: string[] = [];
  protected existingSiteNames: string[] = [];
  protected existingUserOrganizationNames: string[] = [];
  protected existingApprovalGroupNames: string[] = [];

  protected rolesNames: string[] = [];
  protected requestedRoleNames: string[] = [];
  protected requestedOrganizationNames: string[] = [];
  protected approvedRoleNames: string[] = [];

  protected teamNames: string[] = [];
  protected clearExistingRoles: boolean = false;

  protected userInvitationData: any;
  protected userData: any;
  protected phoneIsUSA:boolean = false;
  protected status: string;
  protected requestedByName: string;
  protected requestedForUserStatus: string;
  protected requesterComments: string;

  private subscription: Subscription;

  

  protected rowData: IApprovalRequestStatusResponse[] = [];

  protected frameworkComponents = {
    buttonRenderer: ApproveRejectCellRendererComponent,
  };
  protected arrayData: Array<{
    RequestID: number;
    ApprovalType: number;
  }> = [];
  protected appLanguage;
  public screenLabels: Array<string> = [];
  protected columnDefs: any;
  public integratedData;
  public loadedUserData;
  public enableIntegration: boolean = false;
  public integrationsData: Array<{
    IntegrationsDetails: any;
    EnableIntegration: boolean;
    RequestorUserID: number
  }> = [];
  protected gotValueFromComponent: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<ViewUserInvitationApprovalModal>,
    private _roleService: RoleMapService,
    private _teamService: TeamService,
    private _userService: UserService,
    private _siteService: SiteService,
    private _utilService: UtilityService,
    private userInvitationService: UserInvitationService,
    private _notificationService: NotificationService,
    private _searchService: SearchService,
    private _approvalService: ApprovalService,
    private _datePipe: DatePipe,
    protected messageService: MessageService
  ) {}

  async ngOnInit() {
    
    this.arrayData.push({
      RequestID: this.data.UserAccessRequestID,
      ApprovalType: this.data.ApprovalType
    });
    //labels by message service
    if (localStorage.getItem('ViewUserInvitationApprovalLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('ViewUserInvitationApprovalLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "View User Invitation Approval Request",
        "ViewUserInvitationApprovalRequest"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('ViewUserInvitationApprovalLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    if (localStorage.getItem("isPhoneUSA") === "true") {
      this.phoneIsUSA = true;
    }
    else if (localStorage.getItem("isPhoneUSA") === "false") {
      this.phoneIsUSA = false;
    }

    this.userInvitationData = cloneDeep(this.data);
  
    if(this.userInvitationData.Roles.length !== 0) {
    this.requestedRoleNames = this.userInvitationData.Roles[0].RoleName== null || '' ? null : this.userInvitationData.Roles[0].RoleName.split(",");
    }
    else {
      this.requestedRoleNames = null;
    }

    this._loadRoleNames();
    this._loadUserData();
    this.loadIntegrations();

    //this._loadApprovalStatus();

    // subscribe to the approval and rejection events
    this.subscription = this._approvalService.approvedRejected.subscribe(
      (event: IApproveRejectEmitter) => {
        if (event.isApproved) {
          this.onApprove(event.data);
        } else {
          this.onReject(event.data);
        }
      }
    );
  }

  setColumnDef() {
    this.columnDefs = [
      {
        headerName: this.screenLabels["Status"] ? this.screenLabels["Status"]: "Status" ,
        field: "Status",
        width: 100,
        resizable: true,
        wrapText: true,
        autoHeight: true,
      },
      {
        headerName: this.screenLabels["Level"] ? this.screenLabels["Level"]: "Level",
        field: "ApprovalLevel",
        width: 80,
        resizable: true,
        wrapText: true,
        autoHeight: true,
      },
      {
        headerName: this.screenLabels["ApprovalGroup"] ? this.screenLabels["ApprovalGroup"]: "Approval Group",
        field: "ApprovalGroupName",
        resizable: true,
        wrapText: true,
        autoHeight: true,
        cellStyle: { "white-space": "normal" },
      },
      {
        headerName:this.screenLabels["ReviewedOn"] ? this.screenLabels["ReviewedOn"]: "Reviewed On" ,
        field: "ApprovedDate",
        width: 100,
        resizable: true,
        wrapText: true,
        autoHeight: true,
        cellStyle: { "white-space": "normal" },
        cellRenderer: (data) => {
          // const formattedDate = this._datePipe.transform(
          //   data.value,
          //   // "MM/dd/yyyy"
          //   "short"
          // );
          // return formattedDate ? formattedDate : null;
          return this._formatDate(data.value);
        },
      },
      {
        headerName:this.screenLabels["ReviewedBy"] ? this.screenLabels["ReviewedBy"]: "Reviewed By",
        field: "ApprovedBy",
        width: 150,
        resizable: true,
        wrapText: true,
        autoHeight: true,
        cellStyle: { "white-space": "normal" },
      },
      {
        headerName:this.screenLabels["ReviewersComments"] ? this.screenLabels["ReviewersComments"]: "Reviewer's Comments",
        field: "ApprovalComments",
        editable: (params) => {
          return params.data.CanBeActionedOn === 1 &&
            params.data.Status.toLowerCase() !== "Approved".toLowerCase() &&
            params.data.Status.toLowerCase() !== "Rejected".toLowerCase() &&
            params.data.Status.toLowerCase() !== "Contingent".toLowerCase()
            ? true
            : false;
        },
        // editable: this.checkIsEditable.bind(this)
        resizable: true,
        wrapText: true,
        autoHeight: true,
        cellStyle: { "white-space": "normal" },
      },
      {
        headerName:this.screenLabels["Action"] ? this.screenLabels["Action"]: "Action",
        field: "ApprovalGroupID",
        cellRenderer: "buttonRenderer",

        autoHeight: true,
      },
      {
        headerName:this.screenLabels["CanBeActionedOn"] ? this.screenLabels["CanBeActionedOn"]: "Can Be Actioned On",
        field: "CanBeActionedOn",
        hide: true,
      },
      {
        field: "ApprovalGroupRequestID",
        hide: true,
      },
      {
        field: "ApprovalRequestID",
        hide: true,
      },
      {
        field: "ApprovalGroupID",
        hide: true,
      },
      {
        field: "UserID",
        hide: true,
      },
    ];
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onGridReady(params) {
    this._gridApi = params.api;
    this._gridColumnApi = params.columnApi;
  }

  protected setStep(index: number) {
    this.step = index;
  }

  protected onNoClick(): void {
    this._dialogRef.close();
    this.userInvitationService.approvalOpen = false;
  }

  protected prevStep() {
    this.step--;
  }

  protected nextStep() {
    this.step++;
  }

  private async _loadRoleNames() {
    try {
      await this._roleService.getRoleMappingsAPI();

      this.allRoles = this._roleService
        .getRoleMappingResponse()
        .filter(
          (r) =>
            r.RoleActive === 1 && r.SiteID === this._siteService.selectedSiteID
        )
        .map((r) => ({
          RoleID: r.RoleID,
          SiteID: r.SiteID,
          RoleName: r.RoleName,
          Notes: undefined,
          Active: r.RoleActive,
        }));

      // filter all roles
      this.rolesNames = this._roleService
        .getRoleMappingResponse()
        .map((r) => r.RoleName);

      // remove duplicates for roles
      this.rolesNames = [...new Set(this.rolesNames)];

      this.rolesLoaded = true;
    } catch (err) {
      console.log(err);
    }
  }

  private async _loadUserData() {
    if(this.userInvitationData.UserSites.length !==0)
      this.existingSiteNames = this.userInvitationData.UserSites.map((us) => us.Name);

      if(this.userInvitationData.UserRole.length !==0) {
      this.existingRolesNames = this.userInvitationData.UserRole.filter(
        (ur) =>
          ur.UserRoleActive === 1 &&
          ur.SiteID === this._siteService.selectedSiteID
      ).map((ur) => ur.RoleName);
      }

      this.existingRolesNames.forEach((ers) =>
        this.approvedRoleNames.push(ers)
      );

      this.approvedRoleNames = this._utilService.removeObjArrayDuplicates(
        this.approvedRoleNames
      );

      // this.existingApprovalGroupNames = [];

      if(this.userInvitationData.UserApprovalGroup.length !== 0) {
      this.existingApprovalGroupNames = this.userInvitationData.UserApprovalGroup.filter(
        (uag) => uag.SiteID === 1
      ).map((uag) => uag.ApprovalGroupName);
      }

      if(this.userInvitationData.UserOrganization.length !== 0) {
      this.existingUserOrganizationNames = this.userInvitationData.UserOrganization.filter(
        (uo) => uo.UserOrganizationActive === 1
      ).map((uo) => uo.Name);
      }

      this.requestedOrganizationNames.push(
        this.userInvitationData.RequestedOrganization.length !==0 ? this.userInvitationData.RequestedOrganization[0].Name : null
      );

      this.requestedByName = this.userInvitationData.requestedStatus.length !==0 ? this.userInvitationData.requestedStatus[0].RequestedByName : null;
      this.requestedForUserStatus = this.userInvitationData.requestedStatus.length !==0 ? this.userInvitationData.requestedStatus[0].RequestedForUserStatus : null;
      this.requesterComments = this.userInvitationData.RequestedOrganization.length !==0 ? this.userInvitationData.RequestedOrganization[0].RequesterComments : null;

  }

  protected onApprove(data: any) {
    const approvedRequest = this._updateApprovalRequest(true, data);
    approvedRequest.ApprovalRequestID = this.data.UserAccessRequestID;

    this.userInvitationService
      .approveRejectUserInvitationAPI(approvedRequest)
      .then((res) => {
        this._searchService.key = '';
        this._notificationService.success(res.Message);
        this._searchService.searchInitiated.emit(true);
      })
      .catch((err: HttpErrorResponse) =>
        this._notificationService.failure(err.message)
      );
    // this.onNoClick();
  }

  protected onReject(data: any) {
    const rejectedRequest = this._updateApprovalRequest(false, data);
    rejectedRequest.ApprovalRequestID = this.data.UserAccessRequestID;

    this.userInvitationService
      .approveRejectUserInvitationAPI(rejectedRequest)
      .then((res) => {
        // this.rowData = res.UserRoleRequest;
        //commenting to get display success message
        // this._gridApi.setRowData(res.UserRoleRequest);
        // this._gridApi.redrawRows();
        this._searchService.key = '';
        this._notificationService.success(res.Message);
        this._searchService.searchInitiated.emit(true);
      })
      .catch((err: HttpErrorResponse) =>
        this._notificationService.failure(err.message)
      );

    // this.onNoClick();
  }

  private _getApprovedRoles() {
    let roleAssociation: {
      RoleID: number;
      Active: number;
    }[] = [];

    // remove duplicates
    this.approvedRoleNames = this._utilService.removeObjArrayDuplicates(
      this.approvedRoleNames
    );

    // segregate the role names that have been added newly
    const addedRoleName = this.approvedRoleNames.filter(
      (st) => !this.existingRolesNames.includes(st)
    );

    // segregate the role names that have been removed newly
    const deletedRoleName = this.existingRolesNames.filter(
      (et) => !this.existingRolesNames.includes(et)
    );

    // segregate the role names that haven't been changed
    const unchangedRoleName = this.approvedRoleNames.filter((st) =>
      this.existingRolesNames.includes(st)
    );

    // get the added roles
    const addedRole = this.allRoles.filter((ar) =>
      addedRoleName.includes(ar.RoleName)
    );

    // get the removed roles
    const removedRole = this.allRoles.filter((ar) =>
      deletedRoleName.includes(ar.RoleName)
    );

    // get the unchanged roles
    const unchangedRole = this.allRoles.filter((ar) =>
      unchangedRoleName.includes(ar.RoleName)
    );
    const requestedRole = this.allRoles.filter((ar) =>
    this.userInvitationData.Roles.includes(ar.RoleName)
  );

    // push the removed roles into roleAssociation
    removedRole.forEach((rr) => {
      roleAssociation.push({
        RoleID: rr.RoleID,
        Active: 0,
      });
    });

    // push the added roles into roleAssociation
    addedRole.forEach((ar) => {
      roleAssociation.push({
        RoleID: ar.RoleID,
        Active: 1,
      });
    });

    // push the unchanged roles into roleAssociation'
    unchangedRole.forEach((ar) => {
      roleAssociation.push({
        RoleID: ar.RoleID,
        Active: 1,
      });
    });

    roleAssociation = this._utilService.removeObjArrayDuplicates(
      roleAssociation
    );

    return roleAssociation;
  }

  private _updateApprovalRequest(
    isApproved: boolean,
    data: IApprovalRequestStatusResponse
  ) {
    const approvedRoles = this._getApprovedRoles();

    const updateRequest: IInviteUserApproval = {
      UserID: this.userInvitationData.UserID,
      UpdaterID: this._userService.userID,
      Status: isApproved ? "Approved" : "Rejected",
      Comments: data.ApprovalComments,
      ApprovalRequestID: this.userInvitationData.ApprovalRequestID,
      ApprovalGroupID: data.ApprovalGroupID,
      ApprovalGroupRequestID: data.ApprovalGroupRequestID,
      ClearExistingRecords: this.clearExistingRoles ? 1 : 0,
      Role: this.userInvitationData.Roles,
      SiteID: this._siteService.selectedSiteID
    };

    return updateRequest;
  }

  private _formatDate(date: string) {
    const formattedDate = this._datePipe.transform(
      date,
      this._siteService.dataTimeFormat.angularDateFormat
    );

    return formattedDate ? formattedDate : null;
  }

  integratedDataCall(data) {
    let uniqueIntegrations = [];
    data.forEach((element) => {
      if (!uniqueIntegrations.includes(element)) {
        uniqueIntegrations.push(element);
      }
    });
    this.integratedData = uniqueIntegrations;
  }

  //this is used for enabling the Integrations
  allowIntergration(data) {
    this.gotValueFromComponent = true;
    this.enableIntegration = data;
    this.integrationsData = [];
    this.integrationsData.push({
      IntegrationsDetails: this.loadedUserData,
      EnableIntegration: this.enableIntegration,
      RequestorUserID: this.userInvitationData.UserID
    });
  }

  onSaveChanges() {
    let userID = this.userInvitationData.UserID;
    this.userInvitationService.setIntegrations(this.integratedData, userID).subscribe((res: any) => {
      this._notificationService.success(res.body.Message);
      this.onNoClick();
    },
      (err) => {
        console.log(err);
        console.log(err.message);
        this._notificationService.failure(err.Message);
        this.onNoClick();
      })
  }

  loadIntegrations() {
    this.loadedUserData = this.userInvitationData;
    this.integrationsData.push({
      IntegrationsDetails: this.loadedUserData,
      EnableIntegration: this.enableIntegration,
      RequestorUserID: this.userInvitationData.UserID
    });
  }
  
}
