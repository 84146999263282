import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { RoleMapService } from "../services/role-map.service";
import { MatDialogConfig, MatDialog } from "@angular/material";
import { RoleMapModalComponent } from "../role-map/modals/role-map-modal/role-map-modal.component";
import { ViewSelectorService } from "../services/view-selector.service";
import { SearchService } from "../services/search.service";
import { HttpErrorResponse } from "@angular/common/http";
import { NotificationService } from "../services/notification.service";
import { RoleMapUpdateModalComponent } from "./modals/update-role-mapping-modal/role-map-update-modal.component";
import { DialogService } from "../services/dialog.service";
import { RoleMapViewModalComponent } from "./modals/view-role-mapping-modal/view-role-map-modal.component";
import { SpinnerService } from "../services/spinner.service";
import { UserService } from "../services/user.service";
import { SiteService } from "../services/site.service";
import { GridApi } from "ag-grid-community";
import { MessageService } from '../services/message.service';

@Component({
  selector: "app-role-map",
  templateUrl: "./role-map.component.html",
  styleUrls: ["./role-map.component.css"],
})
export class RoleMapComponent implements OnInit {
  @ViewChild("searchField") searchField: ElementRef;
  protected isMobile = false;
  protected columnDefs: any;
  protected selectedRow: any;
  private gridApi: GridApi;
  private gridColumnApi: any;
  private sortingOrder: any;
  protected isRowSelected = false;
  protected rowClassRules;
  protected rowData;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  public isActive: boolean = false;

  public defaultColDef : object;
  constructor(
    private roleMapService: RoleMapService,
    private viewSelectorService: ViewSelectorService,
    private dialog: MatDialog,
    private searchService: SearchService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private spinnerService: SpinnerService,
    protected userService: UserService,
    protected siteService : SiteService,
    protected messageService: MessageService,
  ) {
    // set row styles
    this.rowClassRules = {
      "row-inactive": (params) => {
        var condition = params.data.Active === 0;
        return condition;
      },
    };
    this.defaultColDef = {
      resizable: true,
  }
  }

  ngOnInit() {
    setTimeout(() => {
      this.spinnerService.setIsLoading(true);
    }, 0);
    this.searchService.setPageName("Roles");
    // this.isMobile = this.viewSelectorService.getIsMobile();

    //labels by message service
if (localStorage.getItem('RolesLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('RolesLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Roles",
      "Roles"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('RolesLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    // Populates the AG Grid
    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        this.rowData = this.searchService.searchRecords;
        this.gridApi.paginationGoToPage(0);
        setTimeout(() => {
          this.spinnerService.setIsLoading(false);
        }, 0);        // this.gridApi.api.setRowData(this.rowData);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
        setTimeout(() => {
          this.spinnerService.setIsLoading(false);
        }, 0);
      });

    // Listen to the event when smart search is invoked
    this.searchService.searchInitiated.subscribe((event: boolean) => {
      if (event) {
        this.searchService.smartSearchPromise
          .then(() => {
            this.rowData = this.searchService.searchRecords;
            this.gridApi.paginationGoToPage(0);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    });

    // subscribe to site changed
    this.siteService.siteChanged.subscribe((event: boolean) => {
      if(event){
        this.searchService
        .smartSearchMultiSiteAPI(null)
        .then(() => {
          this.rowData = this.searchService.searchRecords;   
          this.gridApi.paginationGoToPage(0);
                
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
      }
    });

    // Listen to Role Mapping data change event
    this.roleMapService.roleDataChanged.subscribe((event: boolean) => {
      if (event) {
        this.searchService
          .smartSearchMultiSiteAPI(null)
          .then(() => {
            this.rowData = this.searchService.searchRecords;
            this.gridApi.paginationGoToPage(0);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    });
  }

  setColumnDef(){
    this.columnDefs = [
      {
        headerName: "",
        field: "",
        width: 40,
        cellStyle: { color: "#589bf8" },
        hide: false,
        checkboxSelection: true,
        resizable: false,
        sortable: false,
        lockPosition: true,
      },
      {
        headerName:this.screenLabels["RoleID"]? this.screenLabels["RoleID"]: "Role ID", 
        field: "RoleID",
        checkboxSelection: false,
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridRoleName"]? this.screenLabels["AgGridRoleName"]: "Role Name",
        field: "RoleName",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridNotes"]? this.screenLabels["AgGridNotes"]: "Notes",
        field: "Notes",
        checkboxSelection: false,
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["Active"]? this.screenLabels["Active"]: "Active",
        field: "Active",
        checkboxSelection: false,
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["ModifiedBy"]? this.screenLabels["ModifiedBy"]: "Modified By",
        field: "ModifiedBy",
        checkboxSelection: false,
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["ModifiedDate"]? this.screenLabels["ModifiedDate"]: "Modified Date",
        field: "ModifiedDate",
        checkboxSelection: false,
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["CreatedBy"]? this.screenLabels["CreatedBy"]: "Created By",
        field: "CreatedBy",
        checkboxSelection: false,
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["CreatedDate"]? this.screenLabels["CreatedDate"]: "Created Date",
        field: "CreatedDate",
        checkboxSelection: false,
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["CreatedByName"]? this.screenLabels["CreatedByName"]: "Created By Name",
        field: "CreatedByName",
        checkboxSelection: false,
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["ModifiedByName"]? this.screenLabels["ModifiedByName"]: "Modified By Name",
        field: "ModifiedByName",
        checkboxSelection: false,
        hide: true,
        sortable: true,
      },
    ];
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDef()
}

  createRole() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;

    if (this.isMobile === true) {
      this.dialog.open(RoleMapModalComponent, dialogConfig);
    } else {
      this.dialog.open(RoleMapModalComponent, dialogConfig);
    }
  }
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
    setTimeout(() => {
      this.spinnerService.setIsLoading(false);
    }, 0);
  }
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onRowSelected($event) {
    if (this.gridApi.getSelectedRows().length == 1) {
      this.selectedRow = this.gridApi.getSelectedRows()[0];
      this.isRowSelected = true;
    } else if (this.gridApi.getSelectedRows().length == 0) {
      this.isRowSelected = false;
    }

    if(this.selectedRow.Active === 0){
      this.isActive = false;
    } else {
      this.isActive = true;
    }
  }

  // Invoke when Edit button is Clicked
  onEditRole($event) {
    const dialogRef = this.dialog.open(RoleMapUpdateModalComponent, {
      disableClose: true,
      data: this.selectedRow,
    });
  }

  onDeleteRole($event) {
    let name = this.selectedRow.RoleName;
    let message: string = this.screenLabels["DeleteRoleConfirmMsg"] ? this.screenLabels["DeleteRoleConfirmMsg"] : "Are you sure you want to delete the Role: <<id>> ? ";
    const capacityMessage = message.replace("<<id>>",name)
    this.dialogService
      .openConfirmDialog(
        this.screenLabels["DeleteRole"]? this.screenLabels["DeleteRole"]:"Delete Pass",
        capacityMessage
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.roleMapService
            .getRoleMappingsAPI()
            .then(() => {
              const associatedFunctions = this.roleMapService.getSelectedFunctions(
                this.selectedRow.RoleID
              );

              // unlink associations
              associatedFunctions.forEach((item) => (item.Active = 0));

              this.roleMapService
                .updateRoleMapAPI(
                  this.selectedRow.RoleID,
                  this.selectedRow.RoleName,
                  this.selectedRow.Notes,
                  false,
                  associatedFunctions
                )
                .then(() => {
                  this.notificationService.success(this.roleMapService.message);
                })
                .catch((err: HttpErrorResponse) => {
                  this.notificationService.failure(err.message);
                });
            })
            .catch((err: HttpErrorResponse) => {
              this.notificationService.failure(err.message);
            });
        }
      });
  }

  onRowClicked($event) {
    const data = $event.data;
    this.dialog.open(RoleMapViewModalComponent, { disableClose: true, data });
  }
}
