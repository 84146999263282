import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatExpansionPanel,
  DateAdapter,
  MAT_DATE_FORMATS,
} from "@angular/material";
import { DatePipe } from "@angular/common";
import { OrganizationService } from "../../../services/organization.service";
import { HttpErrorResponse } from "@angular/common/http";
import { NotificationService } from "../../../services/notification.service";
import { SiteService } from "src/app/services/site.service";
import {
  MatDatePickerAdapter,
  CUSTOM_DATE_FORMATS,
} from "src/app/common/mat-date-picker-formatter/mat-date-picker-formatter";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-view-organization-modal",
  templateUrl: "./view-organization-modal.component.html",
  styleUrls: ["./view-organization-modal.component.css"],
  providers: [
    DatePipe,
    { provide: DateAdapter, useClass: MatDatePickerAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export class ViewOrganizationModalComponent implements OnInit {
  protected rowDataAdmin = [];
  columnDefsAdmin = [{ 
    field: "LastName", 
    headerName: 'Last Name', 
    sortable: true },
 { field: "FirstName", 
   headerName: 'First Name', 
   sortable: true }, 
{ field: "OKTAID", 
  headerName: 'Email' }];
  gridOptionsAdmin = {
    pagination: true,       // pagination properties
    paginationPageSize: 10
  };
  protected rowDataZone = [];
  columnDefsZone = [{
   field: "EntryPointZoneName",
   headerName: 'Entry Point Zone',
   sortable: true },
  { valueGetter: this.zoneValue, 
    headerName: 'Limit' }];
    gridOptionsZone = {
    pagination: true,       // pagination properties
    paginationPageSize: 10
  };

  protected rowDataParking = [];
  columnDefsParking = [{
     field: "ParkingLotGroupName", 
     headerName: 'Parking Lot Group',
     sortable: true },
  { valueGetter: this.parkingValue, headerName: 'Limit' }];
  gridOptionsParking = {
    pagination: true,       // pagination properties
    paginationPageSize: 10
  };

  protected orgAdmins: string[] = [];
  protected step = 0;
  protected hasParkingLoaded: boolean = false;
  protected adminsLoaded: boolean = false;
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;

  protected availableParking: Array<{
    ParkingLotGroupID: number;
    ParkingLotGroupName: string;
    Regulated: boolean;
  }>;

  protected parkingWithLimits: Array<{
    OrganizationParkingLimitID: number;
    Limit: number;
    ParkingLotGroupID: number;
    Active: boolean;
    Regulated: boolean;
  }>;

  protected availableEntryPointZones: Array<{
    EntryPointZoneID: number;
    EntryPointZoneName: string;
    Regulated: boolean;
  }>;
  protected entryPointZoneWithLimits: Array<{
    OrgEntryPointZoneLimitID: number;
    Limit: number;
    EntryPointZoneID: number;
    Active: boolean;
    Regulated: boolean;
  }>;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  public defaultColDef : object;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      OrganizationID: number;
      StartDate: string;
      EndDate: string;
      Internal: number;
      SelfRegAllowed: number;
      CreatedBy: number;
      CreatedDate: string;
      ModifiedBy: number;
      ModifiedDate: string;
      Name: string;
      Active: boolean;
      CreatedByName: string;
      ModifiedByName: string;
    },
    protected datePipe: DatePipe,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<ViewOrganizationModalComponent>,
    protected organizationService: OrganizationService,
    private notificationService: NotificationService,
    private siteService: SiteService,
    protected messageService: MessageService,
  ) { 
    this.defaultColDef = {
      resizable: true,
  }
  }

  ngOnInit(): void {
//labels by message service
if (localStorage.getItem('ViewOrganizationLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('ViewOrganizationLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "View Organization Modal",
    "Organization"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('ViewOrganizationLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    //get list of org admins
    this.organizationService.getAdminListByOrg(this.data.OrganizationID).subscribe((res: any) => {
      var adminDetails = res['body'];
      var tempArr = [];
      adminDetails.forEach((ag) => {
        tempArr.push({
          LastName: ag.LastName,
          FirstName: ag.FirstName,
          OKTAID: ag.OKTAID
        });
      });
      this.adminsLoaded = true;
      this.rowDataAdmin = tempArr;
    });

    this.organizationService
      .getOrganizationByIDAPI(this.data.OrganizationID)
      .then(() => {
        const details = this.organizationService.getOrganizationByIDResponse()[0];

        this.availableParking = details.unlinkedParkingLotGroupArray;
        this.parkingWithLimits = details.OrganizationParkingLimitArray;
        this.availableEntryPointZones = details.unlinkedEntryPointZone;
        this.entryPointZoneWithLimits = details.OrganizationEntryPointLimit;
        this.hasParkingLoaded = true;
        const filteredData = this.entryPointZoneWithLimits.filter(item => item.Active);
        this.rowDataZone = filteredData;
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  zoneValue(params) {
    return params.data.Regulated ? 'Self Governed' : params.data.Limit
  }
  parkingValue(params) {
    return params.data.Regulated ? 'Self Governed' : params.data.Limit
  }
  //code to freeze Panel
  onMainHeaderClose() {
    this.freezePanel.open();
  }
}
