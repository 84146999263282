import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class UserActionsService {
  public message: string;

  constructor(private http: HttpClient, private userService: UserService) {}

  recordUserActionAPI(action: any, affectedObject: any): Promise<any> {
    const apiURL = environment.getAPI("createUserActionHistory");
    // const apiURL = environment.getAPI("userActionHistoryCreate");

    const postBody = {
      UserID: this.userService.userID,
      Action: action,
      AffectedObject: affectedObject,
    };

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    console.log(postBody);

    const promise = new Promise((resolve, reject) => {

      // this is to handle successful okta login, but authorizeUser failure 
      // this does not create any entry to the User Action History table
      if(postBody.UserID === null && postBody.Action.toLowerCase() === 'Logout'.toLowerCase()) {
        resolve(null);
      }
      
      else {
        this.http
          .post(apiURL, postBody, httpOptions)
          .toPromise()
          .then(
            (success: HttpResponse<any>) => {
              // success
              this.message = success.body.Message;

              console.log(success.body);
              resolve();
            },
            (err) => {
              // error
              reject(err);
            }
          );
      }


    });


    // console.log(promise);
    return promise;
  }
}
