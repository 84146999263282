import { EventEmitter, Injectable, Output } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ISubmitUserRoleRequestAPI } from "./user-invitation.service";


@Injectable({
  providedIn: "root",
})
export class LoginRegistrationService {
 
  public message: string;
  constructor( public http: HttpClient ) { }

  public getSiteListByRoleAPI(UserID?: number) {
    const apiURL = environment.getAPI("getSiteListByRole");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const apiBody = UserID ?  { UserID: UserID } : {}
    const res = this.http.post<any>(apiURL, apiBody, httpOptions);
    return res;
  }

  public getSiteListAPI(UserID?: number) {
    const apiURL = environment.getAPI("getSiteList");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const apiBody = UserID ?  { UserID: UserID } : {}
    const res = this.http.post<any>(apiURL, apiBody, httpOptions);
    return res;
  }


  public getSelfRegOrganizations(SiteID:number, UserID?: number){ 
    const apiURL = environment.getAPI("getOrganizationSelfReg");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const body = UserID ? {
       SiteID : SiteID,
       UserID : UserID
      } : { SiteID : SiteID }

    const res = this.http.post<any>(apiURL, body, httpOptions);
     return res;
  }

  public registerAPI(
    SiteID:number,
    OKTAID:string,
    RequesterComments:string,
    FirstName:string,
    LastName:string,
    Phone:string,
    OrganizationID:number
  ) {
    const apiURL = environment.getAPI("userCreateRequest");
 
     const postBody = {
        SiteID : SiteID,
        OKTAID: OKTAID,
        RequesterComments: RequesterComments,
        FirstName: FirstName,
        LastName: LastName,
        Phone: Phone,
        OrganizationID: OrganizationID
     };
     const httpOptions: { headers; observe } = {
       headers: new HttpHeaders({
         "Content-Type": "application/json",
       }),
       observe: "response",
     };
 
     const promise = new Promise((resolve, reject) => {
       this.http
         .post(apiURL, postBody, httpOptions)
         .toPromise()
         .then(
           (success: HttpResponse<any>) => {
             // success
             this.message = success.body.Message;
             resolve(success);
           },
           (err) => {
             // error
             reject(err);
           }
         );
     });
     return promise;
   }

   public checkIfEmailAlreadyExists(OKTAID:string){ 
    const apiURL = environment.getAPI("checkUserRequetExist");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const body = { OKTAID : OKTAID }

    const res = this.http.post<any>(apiURL, body, httpOptions);
     return res;
  }

  public submitUserRoleRequestBySelf(body: ISubmitUserRoleRequestAPI){
    const apiURL = environment.getAPI("submitUserRoleRequestBySelf");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const res = this.http.post<any>(apiURL, body, httpOptions);
    return res;
  }
}