export class EntryPointZone {
  public CreatedByName: string;
  public ModifiedByName: string;
  constructor(
    public EntryPointZoneID: number,
    public Name: string,
    public Description: string,
    public Regulated: boolean,
    public TargetCapacity: number,
    public Active: number,
    public ModifiedBy: number,
    public ModifiedDate: Date,
    public CreatedBy: number,
    public CreatedDate: Date
  ) {}
}
