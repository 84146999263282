import { Component, OnInit } from "@angular/core";
import { ViewEntryPointZoneComponent } from "./modals/view-entry-point-zone/view-entry-point-zone.component";
import { EntryPointGroup } from "../models/entry-point-group.model";
import { EntryPointGroupService } from "../services/entry-point-group.service";
import { MatDialog } from "@angular/material";
import { SearchService } from "../services/search.service";
import { CreateEntryPointZoneComponent } from "./modals/create-entry-point-zone/create-entry-point-zone-modal.component";
import { HttpErrorResponse } from "@angular/common/http";
import { NotificationService } from "../services/notification.service";
import { UpdateEntryPointZoneComponent } from "./modals/update-entry-point-zone/update-entry-point-zone.component";
import { DialogService } from "../services/dialog.service";
import { EntryPointZoneService } from "../services/entry-point-zone.service";
import { EntryPointZone } from "../models/entry-point-zone.model";
import { UserService } from "../services/user.service";
import { SiteService } from "../services/site.service";
import { GridApi } from "ag-grid-community";
import { MessageService } from "../services/message.service";

@Component({
  selector: "app-entry-point-zone",
  templateUrl: "./entry-point-zone.component.html",
  styleUrls: ["./entry-point-zone.component.css"],
})
export class EntryPointZoneComponent implements OnInit {
  protected isRowSelected: boolean;
  protected rowData: EntryPointZone[];
  protected rowClassRules;
  private paginationPageSize;
  protected entryPtZoneColumnDefs : any;
  private selectedEntryPointZone: EntryPointZone;
  private gridApi: GridApi;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  public isActive: boolean = false;
  public defaultColDef : object;

  constructor(
    protected dialog: MatDialog,
    protected dialogService: DialogService,
    protected notificationService: NotificationService,
    protected searchService: SearchService,
    protected entryPointZoneService: EntryPointZoneService,
    protected userService: UserService,
    private siteService: SiteService,
    protected messageService: MessageService,
  ) {
    this.paginationPageSize = 50;
    // set row styles
    this.rowClassRules = {
      "row-inactive": (params) => {
        var condition = params.data.Active === 0;
        return condition;
      },
    };
    this.defaultColDef = {
      resizable: true,
  }
  }

  ngOnInit(): void {
    this.searchService.setPageName("Entry Point Zone");

    //labels by message service
if (localStorage.getItem('EntryPointZoneLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('EntryPointZoneLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Entry Point Zone",
      "EntryPointZone"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('EntryPointZoneLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        this.isRowSelected = false;
        this.rowData = this.searchService.searchRecords;
        this.gridApi.paginationGoToPage(0);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    this.searchService.searchInitiated.subscribe((eventData: boolean) => {
      if (eventData) {
        this.searchService.smartSearchPromise
          .then(() => {
            this.rowData = this.searchService.searchRecords;
            this.gridApi.paginationGoToPage(0);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    });

    // subscribe to site changed
    this.siteService.siteChanged.subscribe((event: boolean) => {
      if(event){
        this.searchService
        .smartSearchMultiSiteAPI(null)
        .then(() => {
          this.rowData = this.searchService.searchRecords;   
          this.gridApi.paginationGoToPage(0);      
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
      }
    });

    this.entryPointZoneService.entryPointZoneDataChanged.subscribe((e) => {
      this.searchService
        .smartSearchMultiSiteAPI(null)
        .then(() => {
          this.rowData = this.searchService.searchRecords;
          this.gridApi.paginationGoToPage(0);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    });

    
    
  }

  setColumnDef() {
    this.entryPtZoneColumnDefs = [
      {
        headerName: "",
        field: "",
        width: 80,
        cellStyle: { color: "#589bf8" },
        hide: false,
        checkboxSelection: true,
        resizable: false,
        sortable: false,
        lockPosition: true,
      },
      {
        headerName:this.screenLabels["EntryPointZoneID"]
        ? this.screenLabels["EntryPointZoneID"]: "Entry Point Zone ID",
        field: "EntryPointZoneID",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["NameAgGridField"]
        ? this.screenLabels["NameAgGridField"]: "Name",
        field: "Name",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["DescriptionAgGridField"]
          ? this.screenLabels["DescriptionAgGridField"]
          : "Description",
        field: "Description",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["RegulatedAgGridField"]
          ? this.screenLabels["RegulatedAgGridField"]
          : "Regulated",
        field: "Regulated",
        hide: false,
        sortable: true,
        cellRenderer: (data) => {
          return data.value ? "Yes" : "No";
        },
      },
      {
        headerName: this.screenLabels["TargetCapacityAgGridField"]
          ? this.screenLabels["TargetCapacityAgGridField"]
          : "Target Capacity",
        field: "TargetCapacity",
        hide: false,
        sortable: true,
      },
      {
        headerName:this.screenLabels["Active"]
        ? this.screenLabels["Active"]
        : "Active",
        field: "Active",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["ModifiedDate"]
        ? this.screenLabels["ModifiedDate"]
        : "Modified Date",
        field: "ModifiedDate",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["ModifiedBy"]
        ? this.screenLabels["ModifiedBy"]
        : "Modified By",
        field: "ModifiedBy",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["CreatedDate"]
        ? this.screenLabels["CreatedDate"]
        : "Created Date",
        field: "CreatedDate",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["CreatedBy"]
        ? this.screenLabels["CreatedBy"]
        : "Created By",
        field: "CreatedBy",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["CreatedByName"]
        ? this.screenLabels["CreatedByName"]
        : "Created By Name",
        field: "CreatedByName",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["ModifiedByName"]
        ? this.screenLabels["ModifiedByName"]
        :"Modified By Name",
        field: "ModifiedByName",
        hide: true,
        sortable: true,
      },
    ];
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDef ();
}

  onGridReady(params) {
    this.gridApi = params.api;
  }

  // make ag-rid colums fill their container
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  // sets the data of selected row
  onSelectionChanged($event) {
    const selectedRows: EntryPointZone[] = $event.api.getSelectedRows();
    if (selectedRows.length) {
      this.selectedEntryPointZone = selectedRows[0];
      this.isRowSelected = true;
    } else {
      this.isRowSelected = false;
    }

    if (this.selectedEntryPointZone.Active === 0) {
      this.isActive = false;
    } else {
      this.isActive = true;
    }
  }

  // open selected Entry Point Zone
  EditEntryPointZone() {
    this.dialog.open(UpdateEntryPointZoneComponent, {
      disableClose: true,
      data: this.selectedEntryPointZone,
    });
  }

  // open modal for creating a new Entry Point Zone
  onCreateNewEntryPointZone() {
    this.dialog.open(CreateEntryPointZoneComponent, {
      disableClose: true,
      data: {},
    });
  }

  // open selected Entry Point Zone
  openEntryPointZone($event) {
    var selectedEntryPointZone: EntryPointZone = $event.data;
    this.dialog.open(ViewEntryPointZoneComponent, {
      disableClose: true,
      data: selectedEntryPointZone,
    });
  }

  // Disable Entry Point Zone
  DeleteEntryPointZone() {
    var name = this.selectedEntryPointZone.Name;
    var message: string = this.screenLabels["DeleteApprovalGroupconfirmLabel"] ? this.screenLabels["DeleteApprovalGroupconfirmLabel"] : "Are you sure you want to Delete Entry Point Zone <<id>> ?";
    const capacityMessage = message.replace("<<id>>",name) 
    this.dialogService
      .openConfirmDialog(this.screenLabels["DeleteEntryPointZoneBtn"] ? this.screenLabels["DeleteEntryPointZoneBtn"] :"Delete Entry Point Zone", capacityMessage)
      .afterClosed()
      .subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.entryPointZoneService
            .deleteEntrypointZone(this.selectedEntryPointZone)
            .then(() => {});
        }
      });
  }
}
