import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { EntryPoint } from "../../../models/entry-point.model";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatExpansionPanel,
} from "@angular/material";
import { CreateParkingModalComponent } from "../../../parking-lot/modals/create-parking/create-parking-modal.component";
import { EntryPointService } from "../../../services/entry-point.service";
import { UserService } from "../../../services/user.service";
import { NotificationService } from "../../../services/notification.service";
import { HttpErrorResponse } from "@angular/common/http";
import { SearchService } from "../../../services/search.service";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { EntryPointGroupService } from "../../../services/entry-point-group.service";
import { NgForm, FormControl, Validators } from "@angular/forms";
import { EntryPointZone } from "src/app/models/entry-point-zone.model";
import { EntryPointZoneService } from "src/app/services/entry-point-zone.service";
import { SiteService } from "src/app/services/site.service";
import { RequiredFieldsService } from 'src/app/services/required-fields.service';
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-create-entry-point-zone-modal",
  templateUrl: "./create-entry-point-zone-modal.component.html",
  styleUrls: ["./create-entry-point-zone-modal.component.css"],
})
export class CreateEntryPointZoneComponent implements OnInit {
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  @ViewChild("entryPointForm") entryPointForm: NgForm;
  protected step = 0;
  protected entryPointZone: EntryPointZone;
  //nameFormControl = new FormControl("", [Validators.required]);
  protected appLanguage;
  public screenLabels: Array<string> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EntryPointZone,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<CreateEntryPointZoneComponent>,
    protected entryPointZoneService: EntryPointZoneService,
    private userService: UserService,
    private siteService: SiteService,
    protected requiredFieldsService : RequiredFieldsService,
    protected messageService: MessageService,
  ) {}

  ngOnInit(): void {
    //labels by message service
if (localStorage.getItem('CreateEntryPointZoneLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('CreateEntryPointZoneLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Create Entry Point Zone Modal",
      "EntryPointZone"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('CreateEntryPointZoneLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    this.entryPointZone = new EntryPointZone(
      null,
      null,
      null,
      false,
      null,
      null,
      null,
      null,
      this.userService.userID,
      null
    );
  }
  
  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}
 
  ngAfterViewChecked() {
    // setting up the required fields
    this.requiredFieldsService.setRequiredFields(
      'Add Entry Point Zones',
      this.entryPointForm
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaveChanges() {
    const name = this.entryPointZone.Name.trim() === this.entryPointZone.Name ? this.entryPointZone.Name : this.entryPointZone.Name.trim();
        this.entryPointZone.Name = name ;
    const entryPointZoneId = this.entryPointZoneService.createEntryPointZoneAPI(
      this.entryPointZone
    );
    this.onNoClick();
  }

  isNameEmpty(): boolean {
    return !this.entryPointZone.Name || !this.entryPointZone.Name.trim();
  }

  nameFormControl = new FormControl("", [
    Validators.required,  // Required validation
    Validators.pattern(/^[a-zA-Z\s-][a-zA-Z\s0-9-]*$/),  // Updated pattern validation
    this.specialCharacterValidator(),
  ]);

  // Custom validator function
  specialCharacterValidator() {
    return (control: FormControl) => {
      const value = control.value;
      if (!value) {
        return null; // No validation for empty values
      }

      // Check for spaces or hyphens followed by spaces
      if (/^\s/.test(value)) {
        return { specialCharactersNotAllowed: true };
      }

      const isValid = /^[a-zA-Z\s0-9]*(-[a-zA-Z\s0-9]+)*$/.test(value);

      if (isValid && !/^-/.test(value)) {
        return null;
      };
      return { specialCharactersNotAllowed: true };
    }
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  // getNameRequiredErrorMessage() {
  //   return this.nameFormControl.hasError("required")
  //     ? "You must enter a value"
  //     : " ";
  // }
  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }
}
