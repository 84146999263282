import { Component, NgModule, ViewChild, OnInit } from "@angular/core";
import {
  CdkDrag,
  CdkDragStart,
  CdkDropList,
  CdkDropListGroup,
  CdkDragMove,
  CdkDragEnter,
  moveItemInArray,
} from "@angular/cdk/drag-drop";
import { BrowserModule } from "@angular/platform-browser";
import { ViewportRuler } from "@angular/cdk/overlay";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { WidgetService, widgetType } from "../services/widget.service";
import { HttpErrorResponse } from "@angular/common/http";
import { start } from "repl";
import { UserService } from "../services/user.service";
import { MatDialog } from "@angular/material";
import { OrganizationService } from "../services/organization.service";
import { WidgetConfigModalComponent } from "./modals/widget-config/widget-config.modal.component";
import { SiteService } from "../services/site.service";
import { UtilityService } from "../services/utility.service";
import { AuthService } from "../services/auth.service";
import { MessageService } from "../services/message.service";
import { DialogService } from '../services/dialog.service';

@Component({
  selector: "app-dashboard-widgets",
  templateUrl: "./desktop/dashboard-widgets.component.desktop.html",
  styleUrls: ["./desktop/dashboard-widgets.component.desktop.css"],
})
export abstract class DashboardWidgetsComponent implements OnInit {

  @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList) placeholder: CdkDropList;

  //options for security widgets
  public items = [];

  public parkingAnalysisData = [];

  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;
  public dragIndex: number;
  public activeContainer;

  // options for pie chart walk ins
  walkOn: any[];
  viewWalk: any[] = [700, 300];
  labelWalk: string = "Walk-On Pass Total";
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;

  colorScheme = {
    domain: ["#328CF0", "#A8385D"],
  };
  // options for pie chart drive ins
  driveOn: any[];
  viewDrive: any[] = [700, 300];
  labelDrive: string = "Drive-On Pass Total";
  protected appLanguage;
  public screenLabels: Array<string> = [];

  constructor(
    private viewportRuler: ViewportRuler,
    protected widgetService: WidgetService,
    protected userService: UserService,
    protected dialog: MatDialog,
    protected organizationService: OrganizationService,
    protected siteService: SiteService,
    private utilService: UtilityService,
    protected authService: AuthService,
    protected messageService: MessageService,
    protected dialogService: DialogService
  ) {
    this.target = null;
    this.source = null;
    this.userService.getUserAssociationAPI(this.userService.userID, this.siteService.selectedSiteID).then(() => {

      const userOrganizations = [
        ...this.userService
          .getUserDetails()[0]
          .UserOrganization.map((o) => ({
            OrganizationID: o.OrganizationID,
            Name: o.Name,
            StartDate: o.Startdate,
            EndDate: o.EndDate,
          })),
      ];

      // this.loadWidgetData();
    });
  }

  ngOnInit() {

    //labels by message service
    if (localStorage.getItem('DashboardWidgetLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('DashboardWidgetLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "DashboardWidget Modal",
        "Pass"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('DashboardWidgetLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  ngAfterViewInit() { }

  // Logic for dragging and dropping security widgets
  dragMoved(e: CdkDragMove) {
    let point = this.getPointerPositionOnPage(e.event);

    this.listGroup._items.forEach((dropList) => {
      if (__isInsideDropListClientRect(dropList, point.x, point.y)) {
        this.activeContainer = dropList;
        return;
      }
    });
  }

  dropListDropped() {
    if (!this.target) return;

    let phElement = this.placeholder.element.nativeElement;
    let parent = phElement.parentElement;

    phElement.style.display = "none";

    parent.removeChild(phElement);
    parent.appendChild(phElement);
    parent.insertBefore(
      this.source.element.nativeElement,
      parent.children[this.sourceIndex]
    );

    this.target = null;
    this.source = null;

    if (this.sourceIndex != this.targetIndex)
      moveItemInArray(this.items, this.sourceIndex, this.targetIndex);
  }

  dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList) => {
    if (drop == this.placeholder) return true;

    if (drop != this.activeContainer) return false;

    let phElement = this.placeholder.element.nativeElement;
    let sourceElement = drag.dropContainer.element.nativeElement;
    let dropElement = drop.element.nativeElement;

    let dragIndex = __indexOf(
      dropElement.parentElement.children,
      this.source ? phElement : sourceElement
    );
    let dropIndex = __indexOf(dropElement.parentElement.children, dropElement);

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;

      phElement.style.width = sourceElement.clientWidth + "px";
      phElement.style.height = sourceElement.clientHeight + "px";

      sourceElement.parentElement.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = drop;

    phElement.style.display = "";
    dropElement.parentElement.insertBefore(
      phElement,
      dropIndex > dragIndex ? dropElement.nextSibling : dropElement
    );

    this.placeholder.enter(
      drag,
      drag.element.nativeElement.offsetLeft,
      drag.element.nativeElement.offsetTop
    );
    return false;
  };

  /** Determines the point of the page that was touched by the user. */
  getPointerPositionOnPage(event: MouseEvent | TouchEvent) {
    // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
    const point = __isTouchEvent(event)
      ? event.touches[0] || event.changedTouches[0]
      : event;
    const scrollPosition = this.viewportRuler.getViewportScrollPosition();

    return {
      x: point.pageX - scrollPosition.left,
      y: point.pageY - scrollPosition.top,
    };
  }

  // Format Date field
  dateTickFormatting(val: any): string {
    if (val instanceof Date) {
      const date = new Date(val).toLocaleString("dd-MMMM-yyyy");
      return date;
    }
  }

  // load widget data
  private loadWidgetData() {
    // parking limit analysis for organization
    const orgID = this.userService.organizationID;
    const startDate: Date = this.utilService.getCurrentSiteDT();
    const endDate: Date = new Date(
      +this.utilService.getCurrentSiteDT() + 7 * 24 * 60 * 60 * 1000
    );
    this.widgetService
      .parkingLotGroupAnalyticsAPI(orgID, startDate, endDate)
      .then(() => {
        this.parkingAnalysisData = this.widgetService.getLineChartDate();
      })
      .catch((err: HttpErrorResponse) => { });
  }

  // the method should be invoked to configure a widget
  public widgetConfigure(event, item) {
    const dialogRef = this.dialog.open(WidgetConfigModalComponent, {
      disableClose: true,
      data: item,
      backdropClass: "backdropBackground",
    });
  }
}

function __indexOf(collection, node) {
  return Array.prototype.indexOf.call(collection, node);
}

/** Determines whether an event is a touch event. */
function __isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  return event.type.startsWith("touch");
}

function __isInsideDropListClientRect(
  dropList: CdkDropList,
  x: number,
  y: number
) {
  const {
    top,
    bottom,
    left,
    right,
  } = dropList.element.nativeElement.getBoundingClientRect();
  return y >= top && y <= bottom && x >= left && x <= right;
}
