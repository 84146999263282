import { EditPassModalComponent } from "./../edit-pass-modal.component";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar, DateAdapter, MAT_DATE_FORMATS } from "@angular/material";
import { ViewSelectorService } from "src/app/services/view-selector.service";
import { PassService } from "src/app/services/pass.service";
import { BuildingService } from "src/app/services/building.service";
import { UserService } from "src/app/services/user.service";
import { DatePipe } from "@angular/common";
import { NotificationService } from "src/app/services/notification.service";
import { EntryPointService } from "src/app/services/entry-point.service";
import { DialogService } from "src/app/services/dialog.service";
import { EntryPointGroupService } from "../../services/entry-point-group.service";
import { OrganizationService } from "src/app/services/organization.service";
import { ParkingLotService } from "src/app/services/parking-lot.service";
import { RequiredFieldsService } from "../../services/required-fields.service";
import { SearchService } from "../../services/search.service";
import { SiteService } from "src/app/services/site.service";
import { UtilityService } from "src/app/services/utility.service";
import { MatDatePickerAdapter, CUSTOM_DATE_FORMATS } from 'src/app/common/mat-date-picker-formatter/mat-date-picker-formatter';
import { SpinnerService } from 'src/app/services/spinner.service';
import { HolidayService } from "src/app/services/holiday-calendar.service";
import { MessageService } from "src/app/services/message.service";
import { ApprovalService } from "src/app/services/approval.service";
export interface DialogData {}

@Component({
  selector: "app-edit-pass-modal-desktop",
  templateUrl: "./edit-pass-modal.component.desktop.html",
  styleUrls: ["./edit-pass-modal.component.desktop.css"],
  providers: [
    { provide: DateAdapter, useClass: MatDatePickerAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export class EditPassModalComponentDesktop extends EditPassModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    dialogRef: MatDialogRef<EditPassModalComponent>,
    _snackBar: MatSnackBar,
    service: ViewSelectorService,
    passService: PassService,
    buildingService: BuildingService,
    userService: UserService,
    datePipe: DatePipe,
    notificationService: NotificationService,
    entryPointService: EntryPointService,
    dialogService: DialogService,
    entryPointGroupService: EntryPointGroupService,
    organizationService: OrganizationService,
    parkingLotService: ParkingLotService,
    searchService: SearchService,
    requiredFieldsService: RequiredFieldsService,
    siteService:SiteService,
    spinnerService:SpinnerService,
    utilService: UtilityService,
    holidayService: HolidayService,
    messageService : MessageService,
    approvalService: ApprovalService
  ) {
    super(
      data,
      dialogRef,
      _snackBar,
      service,
      passService,
      buildingService,
      userService,
      datePipe,
      notificationService,
      entryPointService,
      dialogService,
      entryPointGroupService,
      organizationService,
      parkingLotService,
      searchService,
      requiredFieldsService,
      siteService,
      utilService,
      spinnerService,
      holidayService,
      messageService,
      approvalService
    );
  }
}
