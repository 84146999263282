export class DomainModel {
    public CreatedByName: string;
    public ModifiedByName: string;
      constructor(
        public DomainID: number,
        public Domain: string,
        public Description: string,
        public Active: number,
        public DomainCategory: number,
        public ModifiedBy: number,
        public ModifiedDate: Date,
        public CreatedBy: number,
        public CreatedDate: Date
      ) {}
    }