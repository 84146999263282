import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm, FormControl, Validators } from '@angular/forms';
import { MatExpansionPanel, MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { DomainModel } from 'src/app/models/domain-type.model';
import { DialogService } from 'src/app/services/dialog.service';
import { DomainService } from 'src/app/services/domain.service';
import { LabelService } from 'src/app/services/label.service';
import { RequiredFieldsService } from 'src/app/services/required-fields.service';
import { SiteService } from 'src/app/services/site.service';
import { UserService } from 'src/app/services/user.service';
import { MessageService } from 'src/app/services/message.service';
import { map, startWith } from "rxjs/operators";
import { Observable } from "rxjs";

@Component({
  selector: 'app-edit-domain',
  templateUrl: './edit-domain.component.html',
  styleUrls: ['./edit-domain.component.css']
})
export class EditDomainComponent implements OnInit {
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  @ViewChild("domainForm") domainForm: NgForm;
  domainFormControl = new FormControl("", [Validators.required]);
  //domainPattern = '^[A-Za-z]+\\.[a-z]{2,4}$';
  domainPattern = '^[^-].*[A-Za-z0-9-]+\\.[a-z]{2,4}$';
  domainPatternFormControl = new FormControl("", [
    Validators.pattern(this.domainPattern),
  ]);
  protected step = 0;
  protected domain: DomainModel;
  public domainTypes: any[] =[];
  protected appLanguage;
  public screenLabels: Array<string> = [];
  categoriesControl = new FormControl();
  filteredCategory: Observable<any[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DomainModel,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<EditDomainComponent>,
    protected domainService: DomainService,
    protected dialogService: DialogService,
    private userService: UserService,
    private siteService: SiteService,
    protected labelService: LabelService,
    protected requiredFieldsService : RequiredFieldsService,
    protected messageService: MessageService,
  ) {}

  ngOnInit(): void {
     // initialize labels
     this.labelService.initScreenLabels(
      this.siteService.selectedSiteID,
      "Edit Domain Modal",
      "Domain"
    );

    //labels by message service
    if (localStorage.getItem('EditDomainLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('EditDomainLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Edit Domain Modal",
        "Domain"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('EditDomainLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    this.domain = { ...this.data };
    this.domainService.getDomainPickListAPI().then(() => {
      this.domainTypes = this.domainService.domainTypeTables;
       this.filteredCategory = this.categoriesControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value?.DisplayValue ),
        map(DisplayValue  => DisplayValue  ? this._filteredCategory(DisplayValue ) : this.domainTypes.slice())
      );
    });
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  displayFn1(selectedoption: number) {
      this.domainTypes = this.domainService.domainTypeTables;
       
    if(selectedoption) {
    return this.domainService.domainTypeTables.find(x => x.PickListID === selectedoption).DisplayValue;
    }
  }

  private _filteredCategory(value: string) {
    this.domainTypes = this.domainService.domainTypeTables;
    return this.domainService.domainTypeTables.filter(option => option.DisplayValue.toLowerCase().includes(value.toLowerCase()));
  }

  ngAfterViewChecked() {
    // setting up the required fields
    this.requiredFieldsService.setRequiredFields(
      'Edit Domain',
      this.domainForm
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaveChanges() {
    this.dialogService
      .openConfirmDialog(
        this.screenLabels["DomainUpdateMsg"] ? this.screenLabels["DomainUpdateMsg"]:"Confirm Domain Update",
        this.screenLabels["DomainConfirmMsg"] ? this.screenLabels["DomainConfirmMsg"]: "Are you sure you want to save the changes ?"
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.domainService
            .updateDomainAPI(this.domain)
            .then(() => {});
        }
      });

    this.onNoClick();
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  // getNameRequiredErrorMessage() {
  //   return this.nameFormControl.hasError("required")
  //     ? "You must enter a value"
  //     : " ";
  // }
  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }
}
