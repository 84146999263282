import { Component, OnInit } from "@angular/core";
import { SearchService } from "../services/search.service";
import { SiteService } from "../services/site.service";
import { UserService } from "../services/user.service";
import { MessageService } from "../services/message.service";

@Component({
  selector: "app-my-requests",
  templateUrl: "./my-requests.component.html",
  styleUrls: ["./my-requests.component.css"],
})
export class MyRequestsComponent implements OnInit {
  protected appLanguage;
  public screenLabels: Array<string> = [];

  constructor(
    private searchService: SearchService,
    private siteService: SiteService,
    protected userService: UserService,
    protected messageService: MessageService
  ) {
    this.searchService.setPageName("Limit Requests");
  }

  ngOnInit(): void {
    //labels by message service
    if (localStorage.getItem('MyRequestsLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('MyRequestsLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        this.searchService.getPageName(),
        "OrgCombinedLimitRequest"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('MyRequestsLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

}
