import { ViewSelectorService } from "./../services/view-selector.service";
import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ElementRef,
  AfterViewInit,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormControl, Validators, NgForm } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PassService } from "../services/pass.service";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { BuildingService } from "../services/building.service";
import { UserService } from "../services/user.service";
import { DatePipe } from "@angular/common";
import { NotificationService } from "../services/notification.service";
import { EntryPointService } from "../services/entry-point.service";
import {
  MatRadioChange,
  MatCheckboxChange,
  DateAdapter,
  MAT_DATE_FORMATS,
  MatStepper,
} from "@angular/material";
import { EntryPoint } from "../models/entry-point.model";
import { EntryPointGroup } from "../models/entry-point-group.model";
import { EntryPointGroupService } from "../services/entry-point-group.service";
import { DialogService } from "../services/dialog.service";
import { SpinnerService } from "../services/spinner.service";
import { IValidationError } from "./desktop/newpass-modal.component.desktop";
import {
  DataValidator,
  ValidationErrorCodes,
} from "../common/utils/data-validation";
import * as XLSX from "xlsx";
import { OrganizationService } from "../services/organization.service";
import { SearchService } from "../services/search.service";
import { CellKeyDownEvent, CellValueChangedEvent, GridApi } from "ag-grid-community";
import { ParkingLotService } from "../services/parking-lot.service";
import { AuthService } from "../services/auth.service";
import { RequiredFieldsService } from "../services/required-fields.service";
import { SEMICOLON } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { TemplateCategoryService } from "../services/template-category-service";
import { PassTemplateService } from "../services/pass-template-service";
import { TemplateCategory } from "../models/template-category,model";
import { PassTemplate } from "../models/pass-template.model";
import { TeamService } from "../services/team-service";
import { UserTeam } from "../models/userTeam.model";
import { UtilityService } from "../services/utility.service";
import { SiteService } from "../services/site.service";
import {
  MatDatePickerAdapter,
  CUSTOM_DATE_FORMATS,
} from "../common/mat-date-picker-formatter/mat-date-picker-formatter";
import moment from "moment";
import { ISystemSettingsGetAPIResponse } from '../models/interfaces/system-settings-get-api.model';
import { TimezoneService } from '../services/timezone.service';
import { HolidayService } from "../services/holiday-calendar.service";
import { BasicSnackbarComponent } from "../snack-bar/basic-snackbar/basic-snackbar.component";
import { MessageService } from "../services/message.service";
import { IgxTimePickerComponent } from "igniteui-angular";

export interface Reason {
  value: string;
  viewValue: string;
}
export interface Point {
  name: string;
}
export interface PointGrps {
  name: string;
}
export interface Building {
  name: string;
}

export interface Visitor {
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  email: string;
  phone: string;
  country: string;
}

export interface iAPIBody {
  PassID: number;
  VisitorFirstName: string;
  VisitorMiddleName: string;
  VisitorLastName: string;
  VisitorNameSuffix: string;
  VisitorCompany: string;
  VisitorEmail: string;
  VisitorPhone: string;
  VisitorCountry: number;
  StartTime: string;
  EndTime: string;
  EstArrivalTime: string;
  EstDepartureTime: string;
  VisitReason: number;
  IncludeNonWorkingdays: boolean;
  ExpectedEntryPoint: number;
  EntryPointGroupID: number;
  EntryType: number;
  NotesInternal: string;
  NotesPass: string;
  ParkingLotID: number;
  ParkingSpot: string;
  DestinationBldg: number;
  DestinationBlgFlr: number;
  DestinationRoom: string;
  HostFirstName: string;
  HostMiddleName: string;
  HostLastName: string;
  HostNameSuffix: string;
  HostCompany: string;
  HostPhone: string;
  HostEmail: string;
  HostTWID: string;
  OrganizationID: number;
  Authorizer: number;
  Operator: number;
  PhotoPass: string;
  Active: boolean;
  CopyFromPassID: string;
  PartyID: number;
  EmployeeRef: number;
  UserID: number;
}

@Component({
  selector: "app-newpass-modal",
  templateUrl: "./desktop/newpass-modal.component.desktop.html",
  styleUrls: ["./desktop/newpass-modal.component.desktop.css"],
  providers: [
    { provide: DateAdapter, useClass: MatDatePickerAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export abstract class NewpassModalComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("createPassForm") createPassForm: NgForm;
  @ViewChild("expirationDate") expirationDate: ElementRef;
  @ViewChild("startDate") startDate: ElementRef;
  @ViewChild('estArrivalTime', { read: IgxTimePickerComponent, static: true })
  public estArrivalTime: IgxTimePickerComponent;
  @ViewChild('estDepartureTime', { read: IgxTimePickerComponent, static: true })
  public estDepartureTime: IgxTimePickerComponent;
  @ViewChild('stepper') stepper: MatStepper;

  entryPointGroupFormControl = new FormControl("", Validators.required);
  activationDate = new FormControl("", Validators.required);
  expirationDateControl = new FormControl("", Validators.required);
  picker = new FormControl("", Validators.required);
  parkingLotFormControl = new FormControl();
  buildingControl = new FormControl();
  suggestedEntryPointControl = new FormControl();
  templateCategoryControl = new FormControl();
  templateControl = new FormControl();
  visitReasonControl = new FormControl();
  floorControl = new FormControl();
  visitorCountryControl = new FormControl();
  filteredOptions: Observable<string[]>;
  filteredPointGrps: Observable<string[]>;
  filteredParking: Observable<string[]>;
  filteredSuggPoint: Observable<string[]>;
  filteredTemplateCategory: Observable<any[]>;
  filteredTemplates: Observable<any[]>;
  filteredVisitReasons: Observable<any[]>;
  filteredFloors: Observable<any[]>;
  filteredVisitorCountry: Observable<any[]>;
  durationInSeconds = 3;
  step = 0;
  disabled = false;

  private chosenBuildingId;
  public fieldArray: Array<any> = [];
  public buildingNames: string[];
  public groupNames: string[];
  public entryPointNames: string[];
  public parkingNames: string[];
  public templateCategoryNames: string[];
  protected templateNames: PassTemplate[] = [];
  protected tempCategoryNames: string = "";
  protected tempNames: string = "";
  protected parkingName: string = "";
  protected grpName: string = "";
  protected suggEntryPointName: string = "";
  protected buildingName: string = "";
  protected EntryPointGrpName: string = "";
  protected Description: string = "";
  public buildingList;
  public newAttribute: any = {};
  protected entryPointGroupID: number;
  protected suggEntryPointID: number;
  protected addnlVisitor: Visitor[] = [];
  protected templateCatgeoryID: number;
  protected templateID: number = 0;
  protected passTemplate: PassTemplate = new PassTemplate();
  protected templateCategories: TemplateCategory[] = [];
  protected userTeams: UserTeam[] = [];
  protected passTemplates: PassTemplate[] = [];
  // private apiBody: iAPIBody;
  private apiBody: any = {};
  protected entryPoints: Array<EntryPoint> = [];
  protected isReasonOfVisitLoading = true;
  protected isBuildingLoading = true;
  protected isParkingDisabled = true;
  protected isExpirationDateDisabled = true;
  protected isTemplateCategoryLoading = true;
  protected isFetchingData = false;
  protected entryPointGroups: Array<EntryPointGroup>;
  protected minDate = this.utilService.getCurrentSiteDT();
  protected minDuration = this.minDate;
  protected maxDate = new Date(
    this.utilService.getCurrentSiteDT().setDate(this.minDate.getDate() + 14)
  );
  protected maxDuration = new Date(
    this.utilService.getCurrentSiteDT().setDate(this.minDate.getDate() + 30)
  );
  protected parkingCrossedBy: number = 0;
  protected DriveOnGuestPass: number = 0;
  protected entryPointCrossedBy: number = 0;
  protected entryPoinIndicator: boolean = false;
  protected enableNext: boolean = false;
  protected parkingLimit: number;
  protected displayStartDate;
  protected displayEndDate;
  protected entryPointZoneLimit: number;
  protected allZonesSelfGoverened: boolean = false;
  protected orgStartDate: string;
  protected orgEndDate: string;
  protected selectedStartDate;
  protected selectedEndDate;
  protected parkingLotName: string;
  protected ParkingLotID: number = 0;
  protected EntryType: number = 2;
  public remainingParkingCount: number;
  public remainingParking: Array<{
    OrganizationID: number;
    OrganizationName: string;
    OrganizationLimit: number;
    ParkingLotGroupID: number;
    ParkingLotGroupName: string;
    ParkingLotID: number;
    ParkingLotName: string;
    AllocatedParkingCount: number;
    BalanceParkingCount: number;
  }> = [];

  protected allVisitorFieldUnlocked: boolean = false;

  // for the ag grid
  protected columnDefs = [];
  protected validationErrors: Array<IValidationError> = [];
  protected gridApi: GridApi;
  private file: File;
  private arrayBuffer: any;
  protected isMultiplePassSelected: boolean = false;
  protected isFileUploadTypeSelected: boolean = false;
  protected showAuthoriser: boolean = true;

  protected uniquePasses = [];

  private guestPasses: Array<{
    VisitorFirstName: string;
    VisitorLastName: string;
    VisitorNameSuffix: string;
    VisitorCompany: string;
    VisitorEmail: string;
    VisitorPhone: string;
    VisitorCountry: number;
    EntryType: number;
  }> = [];
  protected rowData: Array<any> = [];
  protected assignedParking: Array<{
    ParkingLotName: string;
    ParkingLotID: number;
  }> = [];
  private attemptedValidation: boolean = false;

  protected parkingAvailable: boolean = false;
  protected IgnoreLocks: number;

  public errorMessage: string;
  public isInvalid: Boolean = false;
  // row validation class rules
  protected rowClassRules;
  protected duplicateTempPassID;

  private isMobile = false;
  protected authorizerID: number = 0;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [SEMICOLON];
  hostEmails: Array<{
    email: String;
    valid: String;
  }> = [];
  visitorEmails: Array<{
    email: string;
    valid: string;
  }> = [];
  Name: any;
  public onBackspaceKeydown(event) {
    event.stopImmediatePropagation();
  }
  protected enableSubmit: Boolean = true;
  protected partyPassFlag: Boolean = false;
  protected changedValue: String = "";
  protected minTime: string = "";
  private siteResponse: ISystemSettingsGetAPIResponse;
  public cellChangedValue;
  protected finalPassTmpData: any;
  protected holidays: any[] = [];
  protected checkedDates: boolean = false;
  protected checkDatesNext: boolean = false;
  protected checkDatesError: boolean = false;
  protected startElement: boolean = false;
  protected endElement: boolean = false;
  protected dayRangeArray: any[] = [];
  protected workingDays: any[] = [];
  protected activateSpinner: boolean = false;
  gridOptions: any;
  public killCount: number = 0;
  public UserTaskID: number;
  protected floors: any[] = [];
  protected Getfloors: any[] = [];
  protected buildingArr: any[] = [];
  protected phoneIsUSA: boolean = false;
  protected capacityMessage;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  protected countryList: any;
  private countries = {};
  protected visitReasons: Array<{
    PickListID: number;
    TableName: string;
    FieldName: string;
    DisplayValue: string;
    Active: boolean;
  }> = [];
  protected floorValues: any;
  protected visitorCountry: Array<{
    PickListID: number;
    TableName: string;
    FieldName: string;
    DisplayValue: string;
    Active: boolean;
  }> = [];
  protected isRowSelected: boolean = false;
  protected reqArrivalTime: boolean = false;
  protected reqDepartureTime: boolean = false;
  public defaultColDef: object;
  protected saveCheckInBtnClicked: boolean = false;
  protected emailValidation: boolean = false;
  protected partyID: number;
  isValidEmail(email: string): boolean {
    const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/
    return emailPattern.test(email);
  }

  constructor(
    public dialogRef: MatDialogRef<NewpassModalComponent>,
    private _snackBar: MatSnackBar,
    service: ViewSelectorService,
    protected passService: PassService,
    protected buildingService: BuildingService,
    private userService: UserService,
    private datePipe: DatePipe,
    private notificationService: NotificationService,
    protected entryPointService: EntryPointService,
    protected entryPointServiceGroup: EntryPointGroupService,
    protected dialogService: DialogService,
    private spinnerService: SpinnerService,
    protected organizationService: OrganizationService,
    protected searchService: SearchService,
    protected parkingLotService: ParkingLotService,
    protected authService: AuthService,
    protected requiredFieldsService: RequiredFieldsService,
    protected templateCategoryService: TemplateCategoryService,
    protected passTemplateService: PassTemplateService,
    protected teamService: TeamService,
    private utilService: UtilityService,
    protected siteService: SiteService,
    private timezoneService: TimezoneService,
    protected holidayService: HolidayService,
    protected messageService: MessageService
  ) {
    this.gridOptions = {
      singleClickEdit: true,
      enableCellChangeFlash: true,
      onCellKeyDown: this.onCellKeyDown
    };

    if (service.getIsMobile()) {
      this.isMobile = true;
    }

    this.userService
      .getUserAssociationAPI(
        this.userService.userID,
        this.siteService.selectedSiteID
      )
      .then(() => this.initializeDates());

    //setting default values from siteservice
    this.setSiteServiceDefaultValues();

    //Api call to get parking lot dropdown
    this.organizationService
      .getOrganizationByIDAPI(this.userService.organizationID)
      .then(() => {
        const details = this.organizationService.getOrganizationByIDResponse()[0];
        details.OrganizationParkingLimitArray.map((p) => {
          if (p.ParkingLotArray) {
            p.ParkingLotArray.map((array) => {
              if (array) {
                this.assignedParking.push(array);
                this.parkingAvailable = true;
              }
            });
          }
        });
        //get parking lot name array
        var parkingArr = this.assignedParking;
        this.parkingNames = parkingArr.map(function (item) {
          return item["ParkingLotName"];
        });
        this.filteredParking = this.parkingLotFormControl.valueChanges.pipe(
          startWith(""),
          map((value) => this._filterParking(value))
        );
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    this.passService.getVisitReasonsAPI().then(() => {
      this.isReasonOfVisitLoading = false;
    });

    this.buildingService
      .parseAPI()
      .then(() => {
        this.isBuildingLoading = false;
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    // Load value for Entry Point Groups
    // need to call the API
    this.entryPointServiceGroup
      .getAllEntryPointGroupsAPI(this.userService.organizationID)
      .then(() => {
        // const entryNames: any[] = this.entryPoints;
        // const selectedEntryPoint: any = entryNames.find((element: any) => {})
        // this.Description = selectedEntryPoint.Description;
        this.entryPointGroups = this.entryPointServiceGroup.getEntryPointGroups();
        // var groupArr = this.entryPointGroups;
        var groupArr = this.entryPointGroups.filter(
          (epg: any) => epg.Active === 1
        );
        this.groupNames = groupArr.map((g) => g.EntryPointGrpName);
        this.filteredPointGrps = this.entryPointGroupFormControl.valueChanges.pipe(
          startWith(""),
          map((value) => this._filterGroups(value))
        );
        if (this.groupNames.length === 1) {
          this.grpName = groupArr[0].EntryPointGrpName;
          this.entryPointGroupID = groupArr[0].EntryPointGroupID;

          this.entryPointService.parseGetEndpointAPI(this.entryPointGroupID)
            .then(() => {
              this.entryPoints = [...this.entryPointService.getEntryPoints()];

              var pointsArr = this.entryPoints;
              this.entryPointNames = pointsArr.map(function (item) {
                return item["Description"];
              });
              if (this.entryPointNames.length === 1) {
                this.suggEntryPointName = pointsArr[0].Description;
                this.suggEntryPointID = pointsArr[0].EntryPointID;
              }
            })
            .catch((err: HttpErrorResponse) => {
              this.notificationService.failure(err.message);
            });

          this.filteredSuggPoint = this.suggestedEntryPointControl.valueChanges.pipe(
            startWith(""),
            map((value) => this._filterPoints(value))
          );
        }
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    // set row styles
    this.rowClassRules = {
      "row-ignore": (params) => {
        var condition = params.data.IgnoreDuplicate === true;
        return condition;
      },
      "row-warning": function (params) {
        var condition =
          params.data.IsADuplicate === true &&
          params.data.IgnoreDuplicate != true;
        return condition;
      },
      stopEditingWhenCellsLoseFocus: true,
    };

    //get building name array
    this.buildingService.parseAPI().then(() => {
      this.buildingArr = this.buildingService.getBuildings();
      this.buildingList = this.buildingArr;
      this.buildingNames = this.buildingArr.map(function (item) {
        return item["BuildingName"];
      });
   
      this.filteredOptions = this.buildingControl.valueChanges.pipe(
        startWith(""),
        map((value) => this._filter(value))
      );
     
      if (this.buildingList.length === 1) {
        this.buildingName = this.buildingList[0].BuildingName;
        this.chosenBuildingId = this.buildingList[0].BuildingID;
        if (this.Getfloors.length === 1) {
          this.passTemplate.DestinationBlgFlr = this.Getfloors[0].BuildingFloorID
        }
      }
      if (this.buildingName) {
        this.buildingService.getFloors(this.chosenBuildingId).subscribe((res) => {
          this.Getfloors = res.body;
          this.filteredFloors = this.floorControl.valueChanges.pipe(
            startWith(""),
            map(value => typeof value === 'string' ? value : value?.Floor),
            map(Floor => Floor ? this._filterFloor(Floor) : this.Getfloors.slice())
          );
        });
        if (this.Getfloors.length === 1) {
          this.passTemplate.DestinationBlgFlr = this.Getfloors[0].BuildingFloorID
        }
        if (this.floors.length >= 1) {
          this.enableNext = true;
        }
        else if (this.floors.length == 0) {
          this.enableNext = false;
        }
      }
      this.floorValues = this.buildingService.getBuildingFloors(this.chosenBuildingId ? +this.chosenBuildingId : null);
        this.filteredFloors = this.floorControl.valueChanges.pipe(
          startWith(""),
          map(value => typeof value === 'string' ? value : value?.Floor),
          map(Floor => Floor ? this._filterFloor(Floor) : this.floorValues.slice())
        );
    });
    this.defaultColDef = {
      resizable: true,
    }
  }

  hidden(): Boolean {
    if (this.passTemplates.length > 0 && this.IgnoreLocks < 1) {
      return true;
    } else {
      return false;
    }
  }

  expandedHost() {
    if (this.templateCategories.length > 0 || this.IgnoreLocks > 0) {
      return this.step === 1;
    }
    else {
      return this.step === 0;
    }
  }
  openedd() {
    if (this.passTemplates.length > 0 || this.IgnoreLocks > 0) {
      return this.setStep(1);
    } else {
      return this.setStep(0);
    }
  }
  expanded1() {
    if (this.passTemplates.length > 0 || this.IgnoreLocks > 0) {
      return this.step === 2;
    } else {
      return this.step === 1;
    }
  }
  openedd1() {
    if (this.passTemplates.length > 0 || this.IgnoreLocks > 0) {
      return this.setStep(2);
    } else {
      return this.setStep(1);
    }
  }
  expanded2() {
    if (this.passTemplates.length > 0 || this.IgnoreLocks > 0) {
      return this.step === 3;
    } else {
      return this.step === 2;
    }
  }
  openedd2() {
    if (this.passTemplates.length > 0 || this.IgnoreLocks > 0) {
      return this.setStep(3);
    } else {
      return this.setStep(2);
    }
  }
  getBuildings(event) {
    this.buildingList = event;
  }
  ngAfterViewInit() {
    this.columnDefs = this.initColDef();
  }
  displayFn(selectedoption) {
    return selectedoption ? selectedoption.Category : undefined;
  }
  displayFn2(selectedoption) {
    return selectedoption ? selectedoption.TemplateName : undefined;
  }
  displayFn3(selectedoption: number) {
    if(selectedoption) {
    return this.visitReasons.find(x => x.PickListID === selectedoption).DisplayValue;
    }
  }
  displayFn4(selectedoption: number) {
    if(selectedoption) {
    return this.Getfloors.find(x => x.BuildingFloorID === selectedoption).Floor;
    }
  }
  displayFn5(selectedoption: number) {
    if(selectedoption) {
    return this.visitorCountry.find(x => x.PickListID === selectedoption).DisplayValue;
    }
  }
  setStep(index: number) {
    this.step = index;
  }


  nextStepValid(startDate, endDate, entryPointName) {
    //this.gridApi.setColumnDefs(this.initColDef());
    const capacity = this.screenLabels["CapacityUtilizationMsg"];

    this.capacityMessage = capacity.replace("<<id1>>", entryPointName).replace("<<id2>>", moment(startDate).format("MM/DD/YYYY")).replace("<<id3>>", moment(endDate).format("MM/DD/YYYY"));

    const groupNames: any[] = this.entryPointServiceGroup.getEntryPointGroups();
    const selectedEntryPGroupID: any = groupNames.find((element: any) => element.EntryPointGrpName === entryPointName).EntryPointGroupID;
    this.setEntryLimit(
      startDate,
      endDate,
      this.userService.organizationID,
      selectedEntryPGroupID
    );
    //this.step++;
    this.checkDatesOnNext();

    if (this.passTemplate.IncludeNonWorkingdays) {
      if (this.checkDatesNext) {
        this.checkDatesError = false;
        this.entryPoinIndicator = false;
      }
    }
    else if (!this.passTemplate.IncludeNonWorkingdays) {
      if (!this.checkDatesNext) {
        this.checkDatesError = false;
        // this.entryPoinIndicator = false;
      }
      else if (this.checkDatesNext) {
        this.checkDatesError = true;
        this.enableNext = true;
      }
    }
  }

  prevStep() {
    this.step--;
  }

  onArrivalTimeClear() {
    //this.estArrivalTime.value
    this.passTemplate.EstArrivalTime = '';
  }
  onDepartureTimeClear() {
    //this.estArrivalTime.value
    this.passTemplate.EstDepartureTime = '';
  }
  email = new FormControl("", [Validators.required, Validators.email]);
  //email validation
  getErrorMessage() {
    if (this.email.hasError("required")) {
      return this.screenLabels["ValueEnterMsg"] ? this.screenLabels["ValueEnterMsg"] : "You must enter a value";
    }

    return this.email.hasError("email") ? this.screenLabels["NotValidEmailMsg"] ? this.screenLabels["NotValidEmailMsg"] : "Not a valid email" : "";
  }

  ngOnInit() {
    if (localStorage.getItem("isPhoneUSA") === "true") {
      this.phoneIsUSA = true;
    }
    else if (localStorage.getItem("isPhoneUSA") === "false") {
      this.phoneIsUSA = false;
    }
    this.IgnoreLocks = this.passService.ignoreLocks;
    this.EntryType = 2;
    this.showAuthoriser = true;

    //labels by message service
    if (localStorage.getItem('NewPassLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('NewPassLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "New Pass Modal",
        "Pass"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('NewPassLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    //set user templae categories and ignore locks
    if (!this.authService.validCaller) {
      if (this.passService.userTemplateCategoryAPiData) {
        this.templateCategories = this.passService.userTemplateCategoryAPiData;
      }
      this.filteredTemplateCategory = this.templateCategoryControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value.Category),
        map(Category => Category ? this._filterTemplateCategory(Category) : this.templateCategories.slice())
      );

    } else {
      this.passService
      if (this.passService.userTemplateCategoryAPiData) {
        this.templateCategories = this.passService.userTemplateCategoryAPiData;
      }
      this.filteredTemplateCategory = this.templateCategoryControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value.Category),
        map(Category => Category ? this._filterTemplateCategory(Category) : this.templateCategories.slice())
      );
    }

    if (this.passService.reasonOfVisit) {
      this.visitReasons = this.passService.reasonOfVisit;
      this.filteredVisitReasons = this.visitReasonControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value?.DisplayValue),
        map(DisplayValue => DisplayValue ? this._filterVisitReason(DisplayValue) : this.visitReasons.slice())
      );

    }

    if (this.siteService._siteHasCountry) {
      this.passService.getCountryListAPI().subscribe((res: HttpResponse<any>) => {
        this.countryList = res.body;
        this.countryList.forEach((element) => {
          this.countries[element.PickListID] = element.DisplayValue;
        });
        this.visitorCountry = this.countryList;
        this.filteredVisitorCountry = this.visitorCountryControl.valueChanges.pipe(
          startWith(""),
          map(value => typeof value === 'string' ? value : value?.DisplayValue),
          map(DisplayValue => DisplayValue ? this._filterVisitorCountry(DisplayValue) : this.visitorCountry.slice())
        );
      }, error => {
        console.log(error);
      });
    }

    this.holidayService.getHolidaysYearAPI().subscribe(response => {
      response.HolidayDates.forEach(element => {
        // let formatDate = moment(element).format("YYYY-MM-DD");
        let formatDate = moment(element).utc().format("YYYY-MM-DD");

        this.holidays.push(formatDate);
      })
      response.NonworkingDates.forEach(element => {
        let nonworking = moment(element).utc().format("YYYY-MM-DD");
        //let formatted = new Date(nonworking).getTime();
        this.holidays.push(nonworking);
      })
    }, error => {
      console.log(error);
    });

    // If the cancel is being initiated in the multipass screen
    this.passService.cancelInitiated.subscribe((e) => {
      this.onNoClick();
    });

  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  ngAfterViewChecked() {
    // setting up the required fields
    // this.requiredFieldsService.setRequiredFields(
    //   "Pass",
    //   this.createPassForm
    // );
    this.timeValidation();
  }
  //prevents validation on initial step change before user interacts with form
  stepChanged(event, stepper) {
    stepper.selected.interacted = false;
    if (event.selectedIndex == 3) {
      this.gridApi.setColumnDefs(this.initColDef());
    }
  }
  //start code for building dropdown selection //
  private setTimeoutRef: any = null;
  onInputChange() {
    if (this.buildingName == "") {
      this.passTemplate.DestinationBlgFlr = null;
      this.Getfloors = [];
    }
  }
  onChangeBuilding(event: string, isBlur?: boolean) {
    if (this.buildingName == "") {
      this.passTemplate.DestinationBlgFlr = null;
      this.chosenBuildingId = null;
      this.floors.length = null;
    }
    if (this.buildingName && this.passTemplate.DestinationBlgFlr) {
      this.chosenBuildingId = null;
      this.passTemplate.DestinationBlgFlr = null;
    }
    // Find the building in buildingArr that matches the provided event (building name)
    const matchingBuilding = this.buildingArr.find(
      (building) => building.BuildingName === event
    );
    if (matchingBuilding) {
      if (this.setTimeoutRef) {
        clearTimeout(this.setTimeoutRef);
        this.setTimeoutRef = null;
      }
      // Extract the necessary information from the matching building
      this.chosenBuildingId = matchingBuilding.BuildingID;
    }
    else if (isBlur) {
      this.setTimeoutRef = setTimeout(() => {
        this.buildingName = "";
        this.buildingControl.setValue("");
        this.setTimeoutRef = null;
      }, 500);
    }

    if (this.buildingName) {
      // this is an asynchronous call, subscribe to getFloors
      this.buildingService.getFloors(this.chosenBuildingId).subscribe((res) => {
        this.Getfloors = res.body;
        this.filteredFloors = this.floorControl.valueChanges.pipe(
          startWith(""),
          map(value => typeof value === 'string' ? value : value?.Floor),
          map(Floor => Floor ? this._filterFloor(Floor) : this.Getfloors.slice())

        );
        if(this.Getfloors.length < 1) {
          this.enableNext = false;
        } else if (this.Getfloors.length === 1) {
          this.enableNext = false;
          this.passTemplate.DestinationBlgFlr = this.Getfloors[0].BuildingFloorID;
        } else
          this.enableNext = true;
      });
    }

    // const buildingNames: any[] = this.buildingService.getBuildings();
    // const selectedBuild: any = buildingNames.find((element: any) => element.BuildingName === event);
    // if (selectedBuild) {
    //   if (this.setTimeoutRef) {
    //     clearTimeout(this.setTimeoutRef);
    //     this.setTimeoutRef = null;
    //   }
    //   this.buildingName = selectedBuild.BuildingName;
    //   this.chosenBuildingId = selectedBuild.BuildingID;
    //   this.buildingService.parseAPI().then(() => {
    //     var buildingArr = this.buildingService.getBuildings();
    //     this.buildingList = buildingArr;
    //     this.buildingNames = buildingArr.map(function (item) {
    //       return item["BuildingName"];
    //     });
    //     this.filteredOptions = this.buildingControl.valueChanges.pipe(
    //       startWith(""),
    //       map((value) => this._filter(value))
    //     );
    //   });
    // } else if (isBlur) {
    //   this.setTimeoutRef = setTimeout(() => {
    //     this.buildingName = "";
    //     this.buildingControl.setValue("");
    //     this.setTimeoutRef = null;
    //   }, 500);
    // }
    // this.floors = this.buildingService.getBuildingFloors(this.chosenBuildingId ? +this.chosenBuildingId : null);
    // if (this.floors.length >= 1) {
    //   this.enableNext = true;
    // }
    // else if (this.floors.length == 0) {
    //   this.enableNext = false;
    // }

  }

  onFloor() {
    if (this.Getfloors.length >= 1 && this.passTemplate.DestinationBlgFlr)
      this.enableNext = false;
    else if (this.Getfloors.length >= 1 && !this.passTemplate.DestinationBlgFlr)
      this.enableNext = true;
    this.checkParking();
  }
  checkParking() {
    if (this.EntryType == 1) {
      if (!this.parkingName) this.enableNext = true;
    }
  }

  onChangeEntryPointGroup(event: string, isBlur?: boolean) {
    if (this.entryPoinIndicator) {
      //this.enableNext = false;
      this.entryPoinIndicator = false;
    }
    const groupNames: any[] = this.entryPointServiceGroup.getEntryPointGroups();
    const selectedEntryPGroup: any = groupNames.find((element: any) => element.EntryPointGrpName === event);
    if (selectedEntryPGroup) {
      if (this.setTimeoutRef) {
        clearTimeout(this.setTimeoutRef);
        this.setTimeoutRef = null;
      }
      this.EntryPointGrpName = selectedEntryPGroup.EntryPointGrpName;
      this.entryPointGroupID = selectedEntryPGroup.EntryPointGroupID;
      this.setSuggestedEntryPoint(this.entryPointGroupID);
      this.entryPointServiceGroup.getAllEntryPointGroupsAPI(this.userService.organizationID).then(() => {
        this.entryPointGroups = this.entryPointServiceGroup.getEntryPointGroups();
        this.filteredPointGrps = this.entryPointGroupFormControl.valueChanges.pipe(
          startWith(""),
          map((value) => this._filterGroups(value))
        );
      });
    } else if (isBlur) {
      this.setTimeoutRef = setTimeout(() => {
        this.EntryPointGrpName = "";
        this.entryPointGroupFormControl.setValue("");
        this.setTimeoutRef = null;
      }, 500);
    }
    this.OnIncludeNonWorkingDays();
    if (this.checkDatesError) {
      this.enableNext = false;
      this.checkDatesError = false;
    }
    this.onFloor();
    this.checkParking();
  }

  onChangeSuggstPoints(event: string, isBlur?: boolean) {
    // const entryNames: any[] = this.entryPoints;
    const entryNames: any[] = this.entryPointService.getEntryPoints();
    const selectedEntryPoint: any = entryNames.find((element: any) => {
      if (event == '') {
        // return entryNames[0];
        this.suggEntryPointName = null;
        this.suggEntryPointID = null;
      } else
        if (element.Description === event) {
          return element;
        }
    })
    if (selectedEntryPoint) {
      if (this.setTimeoutRef) {
        clearTimeout(this.setTimeoutRef);
        this.setTimeoutRef = null;
      }
      this.Description = selectedEntryPoint.Description;
      this.suggEntryPointID = selectedEntryPoint.EntryPointID;
      var pointsArr = this.entryPoints;
      this.entryPointNames = pointsArr.map(function (item) {
        return item["Description"];
      });
      this.filteredSuggPoint = this.suggestedEntryPointControl.valueChanges.pipe(
        startWith(""),
        map((value) => this._filterPoints(value))
      );
    } else if (isBlur) {
      this.setTimeoutRef = setTimeout(() => {
        this.Description = "";
        this.suggestedEntryPointControl.setValue("");
        this.setTimeoutRef = null;
      }, 400);
    }
    this.onFloor();
    this.checkParking();
  }

  //building typeahead
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.utilService.searchSortKeyUtil(this.buildingNames, value);
  }
  private _filterGroups(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.utilService.searchSortKeyUtil(this.groupNames, value);
  }
  //suggested entry point typeahead
  private _filterPoints(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.utilService.searchSortKeyUtil(this.entryPointNames, value);
  }
  //parking lot typeahead
  private _filterParking(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.utilService.searchSortKeyUtil(this.parkingNames, value);
  }
  //template category typeahead
  private _filterTemplateCategory(value: string) {
    return this.templateCategories.filter(option => option.Category.toLowerCase().includes(value.toLowerCase()));
  }

  //template typeahead
  private _filterTemplate(value: string) {
    return this.templateNames.filter(option => option.TemplateName.toLowerCase().includes(value.toLowerCase()));
  }

  //visit reason typeahead

  private _filterVisitReason(value: string) {
    return this.visitReasons.filter(option => option.DisplayValue.toLowerCase().includes(value.toLowerCase()));
  }
  //floor typeahead
  private _filterFloor(value: string) {
    return this.Getfloors.filter(option => option.Floor.toLowerCase().includes(value.toLowerCase()));
  }

  //visit reason typeahead
  private _filterVisitorCountry(value: string) {
    return this.visitorCountry.filter(option => option.DisplayValue.toLowerCase().includes(value.toLowerCase()));
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.passService.newPassOpen = false;
  }
  onEntryTypeChange(event: MatRadioChange) {
    if (event.value == 1) {
      this.isParkingDisabled = false;
    } else {
      this.isParkingDisabled = true;
    }
    this.setParkingLimit(
      this.selectedStartDate,
      this.selectedEndDate,
      this.userService.organizationID
    );
  }
  //logic for adding/removing extra guests in visitor infor panel
  addFieldValue() {
    this.addnlVisitor.push(this.newAttribute);
    this.newAttribute = {};
  }

  deleteFieldValue(index) {
    this.addnlVisitor.splice(index, 1);
  }

  // Invoked when a pass is being created
  onCreatePass() {
    // this.validateBulkUploadData();

    let promise: Promise<any>;
    this.apiBody.Authorizer = this.userService.userID;
    this.apiBody.ExpectedEntryPoint = !this.suggEntryPointID
      ? null
      : +this.suggEntryPointID;
    this.apiBody.EntryPointGroupID = !this.entryPointGroupID
      ? null
      : +this.entryPointGroupID;
    this.apiBody.EntryType = this.EntryType;


    this.apiBody.NotesInternal = !this.createPassForm.value.notesInternal
      ? null
      : this.createPassForm.value.notesInternal;
    this.apiBody.NotesPass = !this.createPassForm.value.notesPass
      ? null
      : this.createPassForm.value.notesPass;

    this.apiBody.ParkingLotID =
      this.apiBody.EntryType == 1 ? this.ParkingLotID : null;
    this.apiBody.ParkingSpot = !this.passTemplate.ParkingSpot
      ? null
      : this.passTemplate.ParkingSpot;
    this.apiBody.DestinationBldg = !this.chosenBuildingId
      ? null
      : +this.chosenBuildingId;
    this.apiBody.DestinationBlgFlr = !this.createPassForm.value
      .destinationBlgFlr
      ? null
      : +this.createPassForm.value.destinationBlgFlr;
    this.apiBody.DestinationRoom = !this.createPassForm.value.destinationBlgRoom
      ? null
      : this.createPassForm.value.destinationBlgRoom;
    this.apiBody.HostTWID = null;
    this.apiBody.OrganizationID = this.userService.organizationID;
    this.apiBody.Authorizer = this.userService.userID;
    this.apiBody.Operator =
      this.userService.loggedInUserID > 0
        ? this.userService.loggedInUserID
        : this.userService.userID;
    this.apiBody.PhotoPass = this.createPassForm.value.PhotoPass ? 1 : 0;
    this.apiBody.Active = true;
    this.apiBody.CopyFromPassID = null;
    this.apiBody.PartyID = null;
    this.apiBody.EmployeeRef = null;
    this.apiBody.Status = "Invalid";
    this.apiBody.UserID = this.userService.userID;
    this.apiBody.AllowDup = false;
    this.apiBody.VisitorEmail =
      this.createPassForm.value.visitorEmail === ""
        ? null
        : this.createPassForm.value.visitorEmail;

    this.apiBody.TemplateID = this.templateID;
    this.apiBody.VisitorFirstName = this.passTemplate.VisitorFirstName.trim();
    this.apiBody.VisitorLastName = this.passTemplate.VisitorLastName.trim();
    this.apiBody.VisitorMiddleName = "";
    this.apiBody.VisitorNameSuffix = this.passTemplate.VisitorNameSuffix;
    this.apiBody.VisitorCompany = this.passTemplate.VisitorCompany;
    this.apiBody.VisitorEmail = this.passTemplate.VisitorEmail;
    this.apiBody.VisitorPhone = this.passTemplate.VisitorPhone;
    this.apiBody.VisitorCountry = this.passTemplate.VisitorCountry;
    let ArrivalDate = moment(this.passTemplate.StartDate).format("YYYY-MM-DD");
    let ArrivalTime = moment(this.passTemplate.EstArrivalTime).format("HH:mm:ss");
    let ArrivalTimeDate = moment(ArrivalDate + ' ' + ArrivalTime);
    this.apiBody.EstArrivalTime = this.formatDate(
      ArrivalTimeDate
    );
    let DepatureDate = moment(this.passTemplate.EndDate).format("YYYY-MM-DD");
    let DepatureTime = moment(this.passTemplate.EstDepartureTime).format("HH:mm:ss");
    let DepatureTimeDate = moment(DepatureDate + ' ' + DepatureTime);
    this.apiBody.EstDepartureTime = this.formatDate(
      DepatureTimeDate
    );
    this.apiBody.VisitReason = this.passTemplate.VisitReason;
    this.apiBody.IncludeNonWorkingdays = this.passTemplate.IncludeNonWorkingdays;
    this.apiBody.EntryType = this.EntryType;
    this.apiBody.ParkingSpot = this.passTemplate.ParkingSpot;
    this.apiBody.NotesInternal = this.passTemplate.NotesInternal;
    this.apiBody.NotesPass = this.passTemplate.NotesPass;
    this.apiBody.DestinationBlgFlr = this.passTemplate.DestinationBlgFlr;
    this.apiBody.DestinationBlgRoom = this.passTemplate.DestinationRoom;
    this.apiBody.HostFirstName = this.createPassForm.value
      .isHostAndAuthorizerSame
      ? this.userService.loggedInUserFirstName
      : this.passTemplate.HostFirstName;
    // this.apiBody.HostMiddleName = this.createPassForm.value
    //   .isHostAndAuthorizerSame
    //   ? this.userService.loggedInUserMiddleName
    //   : this.passTemplate.HostMiddleName;
    this.apiBody.HostMiddleName = "";
    this.apiBody.HostLastName = this.createPassForm.value
      .isHostAndAuthorizerSame
      ? this.userService.loggedInUserLastName
      : this.passTemplate.HostLastName;
    this.apiBody.HostNameSuffix = this.createPassForm.value
      .isHostAndAuthorizerSame
      ? this.userService.loggedInSuffix
      : this.passTemplate.HostNameSuffix;

    this.apiBody.HostCompany = this.createPassForm.value.isHostAndAuthorizerSame
      ? this.userService.userOrganizations.filter(
        (f) => f.OrganizationID == this.userService.organizationID
      )[0].Name
      : this.passTemplate.HostCompany;
    this.apiBody.HostPhone = this.passTemplate.HostPhone;
    var hostEmails: String[] = [];
    this.hostEmails.forEach((element) => {
      if (element.valid === "valid") {
        hostEmails.push(element.email);
      }
    });

    this.apiBody.HostEmail =
      hostEmails.length > 0 ? hostEmails.join(";") : null;
    this.apiBody.StartTime = !this.passTemplate.StartDate
      ? null
      : this.datePipe.transform(this.passTemplate.StartDate, "yyyy-MM-dd");
    this.apiBody.EndTime = !this.passTemplate.EndDate
      ? null
      : this.datePipe.transform(this.passTemplate.EndDate, "yyyy-MM-dd");
    this.apiBody.PhotoPass = this.passTemplate.PhotoPass;

    if (this.apiBody.EntryPointGroupID) {
      this.organizationService
        .getEntryPointZoneLimitAnalyticsAPI(
          this.selectedStartDate,
          this.selectedEndDate,
          this.apiBody.OrganizationID,
          null,
          this.apiBody.EntryPointGroupID,
          this.apiBody.IncludeNonWorkingdays,
          this.siteService.selectedSiteID
        )
        .then(() => {
          const entryPointGrpDetails = this.organizationService.entryPointZoneLimitAPIResponse;
          if (
            (entryPointGrpDetails.length > 0 &&
              entryPointGrpDetails[0].FreeSpace <= 0 &&
              !entryPointGrpDetails[0].regulated) ||
            this.entryPointCrossedBy > 0
          ) {
            const entryPointZone = this.screenLabels["EntryPointZone"] ? this.screenLabels["EntryPointZone"] : "Entry Point Zone";
            const entryPointZoneFilledMsg = this.screenLabels["EntryPointZoneFilledMsg"] ? this.screenLabels["EntryPointZoneFilledMsg"] : "Entry Point Zone Filled for selected Entry Point Group"
            this.dialogService.openAlertDialogDialog(
              entryPointZone,
              entryPointZoneFilledMsg
            );
          } else if (this.apiBody.ParkingLotID) {
            this.organizationService
              .getParkingLimitAnalyticsAPI(
                this.datePipe.transform(this.selectedStartDate, "yyyy-MM-dd"),
                this.datePipe.transform(this.selectedEndDate, "yyyy-MM-dd"),
                this.userService.organizationID,
                null
              )
              .then(() => {
                let balanceCount: number = 0;
                this.remainingParking = this.organizationService.parkingLimitAPIResponse.filter(
                  (f) =>
                    f.OrganizationID == this.apiBody.OrganizationID &&
                    f.ParkingLotID == this.apiBody.ParkingLotID
                );
                if (
                  this.remainingParking != undefined &&
                  this.remainingParking.length > 0
                ) {
                  this.remainingParkingCount = this.remainingParking[0].BalanceParkingCount;
                }
                if (
                  this.remainingParkingCount <= 0 ||
                  this.parkingCrossedBy > 0
                ) {
                  if (this.parkingLotService.getParkingLot().length > 0) {
                    this.parkingLotName = this.parkingLotService
                      .getParkingLot()
                      .filter(
                        (p) => p.ParkingLotID === this.ParkingLotID
                      )[0].Name;
                  } else {
                    this.parkingLotService.getParkingLotAPI().then(() => {
                      this.parkingLotName = this.parkingLotService
                        .getParkingLot()
                        .filter(
                          (p) => p.ParkingLotID === this.ParkingLotID
                        )[0].Name;
                    });
                  }
                  const parkingLot = this.screenLabels["ParkingLot"] ? this.screenLabels["ParkingLot"] : "Parking Lot";
                  const parkingFilledMsg = (this.screenLabels["ParkingFilledMsg"]).replace("<<id>>", this.parkingLotName);
                  this.dialogService.openAlertDialogDialog(
                    parkingLot,
                    parkingFilledMsg
                  );
                } else {
                  if (!this.isMultiplePassSelected) {
                    this.createIndividualPass(this.apiBody);
                    //this.onNoClick();
                  }
                }
              })
              .catch((err: HttpErrorResponse) => {
                this.notificationService.failure(err.message);
              });
          } else {
            if (!this.isMultiplePassSelected) {
              this.createIndividualPass(this.apiBody);
              //this.onNoClick();
            }
          }
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    } else if (this.apiBody.ParkingLotID) {
      this.organizationService
        .getParkingLimitAnalyticsAPI(
          this.datePipe.transform(this.selectedStartDate, "yyyy-MM-dd"),
          this.datePipe.transform(this.selectedEndDate, "yyyy-MM-dd"),
          this.apiBody.organizationID,
          null
        )
        .then(() => {
          let balanceCount: number = 0;
          this.remainingParking = this.organizationService.parkingLimitAPIResponse.filter(
            (f) =>
              f.OrganizationID == this.apiBody.OrganizationID &&
              f.ParkingLotID == this.apiBody.ParkingLotID
          );
          if (
            this.remainingParking != undefined &&
            this.remainingParking.length > 0
          ) {
            this.remainingParkingCount = this.remainingParking[0].BalanceParkingCount;
          }
          if (
            (this.remainingParking.length <= 0 ||
              this.remainingParkingCount <= 0) &&
            this.parkingCrossedBy > 0
          ) {
            if (this.parkingLotService.getParkingLot().length > 0) {
              this.parkingLotName = this.parkingLotService
                .getParkingLot()
                .filter((p) => p.ParkingLotID === this.ParkingLotID)[0].Name;
            } else {
              this.parkingLotService.getParkingLotAPI().then(() => {
                this.parkingLotName = this.parkingLotService
                  .getParkingLot()
                  .filter((p) => p.ParkingLotID === this.ParkingLotID)[0].Name;
              });
            }
            const parkingLot = this.screenLabels["ParkingLot"] ? this.screenLabels["ParkingLot"] : "Parking Lot";
            const parkingFilledMsg = (this.screenLabels["ParkingFilledMsg"]).replace("<<id>>", this.parkingLotName);
            this.dialogService.openAlertDialogDialog(
              parkingLot,
              parkingFilledMsg
            );
            return;
          } else {
            if (!this.isMultiplePassSelected) {
              this.createIndividualPass(this.apiBody);
              //this.onNoClick();
            }
          }
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    } else {
      if (!this.isMultiplePassSelected) {
        this.createIndividualPass(this.apiBody);
        //this.onNoClick();
      }
    }
  }
  onCreatePassTemp() {
    this.partyPassFlag = true;
    const reqdata = this.formatBulkData(this.rowData);
    this.passService.createTempPassCheck(reqdata).subscribe((res: any) => {
      this.rowData = res.body
      this.finalPassTmpData = res.body;
    });
    this.validateBulkUploadData();
  }

  showBasicSnackBarComponent() {

    this._snackBar.openFromComponent(BasicSnackbarComponent, {
      data: this.UserTaskID,
      duration: 10000000,
      panelClass: ["custom-style"]
    });
  }

  createPassAPINew() {
    this.activateSpinner = true;
    const data = {
      passLength: this.finalPassTmpData.length,
      tmpPassId: this.finalPassTmpData[0].TmpPassID
    }
    this.passService.createPassAPINew(data).subscribe((res: any) => {
      if (res.body.UserTaskID) {
        this.UserTaskID = res.body.UserTaskID;
        this.showBasicSnackBarComponent();
        this.getUserTaskStatus(res.body.UserTaskID, true);
      }
      else if (res.body.PassCreatedCount < this.finalPassTmpData.length) {
        //to open check-in modal if they clk Save & CheckIn Button
        this.passService.setPassIDFromAPI(res.body.PassID);
        if (this.saveCheckInBtnClicked) this.passService.openViewPass.emit(true);
        this.activateSpinner = false;
        this.onNoClick();
        this.passService.dataChanged.emit(true);
        if (this.finalPassTmpData.length > 1) {
          var message = this.screenLabels["PassSuccessFailCountMsg"] ? this.screenLabels["PassSuccessFailCountMsg"] : "<<count1>> Passes were created successfully, <<count2>> passes were failed. Please check host-email for more information"
          const msg = message.replace("<<count1>>", res.body.PassCreatedCount).replace("<<count2>>", this.finalPassTmpData.length - res.body.PassCreatedCount)
          this.notificationService.success(msg);
        }
        else {
          var message = this.screenLabels["PassSuccessFailCountSingleMsg"] ? this.screenLabels["PassSuccessFailCountSingleMsg"] : "Pass creation was failed. Please check host-email for more information"
          this.notificationService.success(message);
        }
      }
      else if (res.body.message === "Pass is created successfully") {
        //to open check-in modal if they clk Save & CheckIn Button
        this.passService.setPassIDFromAPI(res.body.PassID);
        if (this.saveCheckInBtnClicked) this.passService.openViewPass.emit(true);
        this.activateSpinner = false;
        this.onNoClick();
        this.passService.dataChanged.emit(true);
        this.notificationService.success(res.body.message);
      }
    },
      (err) => {
        this.activateSpinner = false;
        console.log(err);
        console.log(err.message);
      })
  }

  onSaveCheckInPass() {
    this.saveCheckInBtnClicked = true;
    this.onCreatePass();
  }

  onSaveCheckInTempPass() {
    this.saveCheckInBtnClicked = true;
    this.onCreatePassTemp();
  }

  onBlurOfTemplateCategory(event) {
    this.onTemplateCategoryChange(event);
  }
  //on Template Category changed
  onTemplateCategoryChange(event) {
    this.templateCatgeoryID = event.TemplateCategoryID;
    this.isFetchingData = true;
    this.passService.getCategoryTemplates(this.templateCatgeoryID).then(() => {
      this.templateNames = this.passService.sortedTemplates;
      this.passTemplates = this.passService.sortedTemplates;
      this.filteredTemplates = this.templateControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value.TemplateName),
        map(TemplateName => TemplateName ? this._filterTemplate(TemplateName) : this.templateNames.slice())
      );
      this.isTemplateCategoryLoading = false;
      this.isFetchingData = false;
    });
    //setting default values from siteservice
    //this.setSiteServiceDefaultValues();
    //return;
  }


  //Reset Inputs on Templaet Category Change
  resetInputs() {
    this.passTemplate = new PassTemplate();
    this.grpName = "";
    this.parkingName = "";
    this.suggEntryPointName = "";
    this.buildingName = "";
    this.allVisitorFieldUnlocked = false;
    this.hostEmails = [];
    this.buildingControl.enable();
    this.suggestedEntryPointControl.enable();
    this.parkingLotFormControl.enable();
    this.entryPointGroupFormControl.enable();
    this.isParkingDisabled = true;
    this.rowData = [];
    this.templateID = 0;
    this.initializeDates();
  }
  onBlurOfTemplate(event) {
    this.onTemplateChange(event);
  }

  onTemplateChange(event) {
    this.resetInputs();
    this.isFetchingData = true;
    this.passTemplateService.getTemplateAPI(event.PassTemplateID).then(() => {
      this.passTemplate = this.passTemplateService.passTemplates;
      this.templateID = event.PassTemplateID;
      this.setGenericTemplateFields();
      this.checkDateValidation();
      this.OnIncludeNonWorkingDays();
      this.isFetchingData = false;
    })
  }
  //To set default values which saved in siteservice
  setSiteServiceDefaultValues() {
    //Initialise Site Settings  getSiteSettingsAPI
    this.siteResponse = this.siteService.siteSettings;
    if (this.siteResponse) {
      if (!this.passTemplate.EstArrivalTime) {
        const startTime = this.siteResponse.SystemSettings ? (this.siteResponse.SystemSettings.filter(x => x.Name === "DefaultStartTime")).map(x => x.Value) : null;
        this.passTemplate.EstArrivalTime = startTime ? this.timezoneService.stringToTime(startTime.toString()) : null;
      }
      if (!this.passTemplate.EstDepartureTime) {
        const endTime = this.siteResponse.SystemSettings ? (this.siteResponse.SystemSettings.filter(x => x.Name === "DefaultEndTime")).map(x => x.Value) : null;
        this.passTemplate.EstDepartureTime = endTime ? this.timezoneService.stringToTime(endTime.toString()) : null;
      }
      if (!this.passTemplate.VisitReason) {
        const defaultReason = this.siteResponse.SystemSettings ? (this.siteResponse.SystemSettings.find(x => x.Name === "DefaultVisitReason")) : null;
        this.passTemplate.VisitReason = defaultReason ? Number(defaultReason.Value) : null;
      }
    }
    if (this.passTemplate.EstArrivalTime > this.passTemplate.EstDepartureTime) {
      this.errorMessage = this.screenLabels["TimeErrorMsg"] ? this.screenLabels["TimeErrorMsg"] : "Departure time should be greater than Arrival Time";
      this.isInvalid = true;
      this.enableNext = true;
    } else {
      this.isInvalid = false;
      this.enableNext = false;
    }

  }
  setGenericTemplateFields() {
    this.isFetchingData = true;
    this.setLockFields();
    this.EntryType = this.passTemplate.EntryType;
    if (this.EntryType == 1) {
      this.isParkingDisabled = false;
    } else {
      this.isParkingDisabled = true;
    }
    this.allVisitorFieldUnlocked =
      this.passTemplate.VisitorFirstNameLock &&
      //this.passTemplate.VisitorMiddleNameLock &&
      this.passTemplate.VisitorLastNameLock &&
      this.passTemplate.VisitorCompanyLock &&
      this.passTemplate.VisitorPhoneLock &&
      this.passTemplate.VisitorCountryLock &&
      this.passTemplate.VisitorEmailLock;
    this.allVisitorFieldUnlocked =
      this.passTemplate.VisitorFirstNameLock &&
      this.passTemplate.VisitorLastNameLock;
    if (this.passTemplate.HostEmail) {
      var value = this.passTemplate.HostEmail;
      if ((value || "").trim()) {
        if (DataValidator.isEmail(value) == ValidationErrorCodes.Ok) {
          this.hostEmails.push({ email: value.trim(), valid: "valid" });
        } else {
          this.hostEmails.push({ email: value.trim(), valid: "invalid" });
        }
      }
    }

    if (this.IgnoreLocks == 1) {
      this.initializeLocks();
    } else {
      if (this.IgnoreLocks == 0 && this.templateID > 0 && (this.passTemplate.HostFirstNameLock || this.passTemplate.HostLastNameLock || this.passTemplate.HostNameSuffixLock || this.passTemplate.HostCompanyLock || this.passTemplate.HostEmailLock || this.passTemplate.HostPhoneLock)) {
        this.showAuthoriser = false;
      }
    }
    if (this.passTemplate.ParkingLotID > 0) {
      var parkingLots = this.assignedParking;
      this.ParkingLotID = this.passTemplate.ParkingLotID;
      var selectedParkingLot = parkingLots.filter(
        (f) => f.ParkingLotID == this.passTemplate.ParkingLotID
      )
      this.parkingName = selectedParkingLot.length > 0 ? selectedParkingLot[0].ParkingLotName : "";
    }
    if (this.passTemplate.EntryPointGroupID > 0) {
      var entryPointGrps = this.entryPointGroups.filter(
        (epg: any) => epg.Active === 1
      );
      this.entryPointGroupID = this.passTemplate.EntryPointGroupID;
      var selectedEntryPointGroup = entryPointGrps.filter(
        (f) => f.EntryPointGroupID == this.passTemplate.EntryPointGroupID
      );
      this.grpName = selectedEntryPointGroup.length > 0 ? selectedEntryPointGroup[0].EntryPointGrpName : "";

      this.entryPointService.parseGetEndpointAPI(this.entryPointGroupID)
        .then(() => {
          this.entryPoints = [...this.entryPointService.getEntryPoints()];
          this.isFetchingData = false;
          var pointsArr = this.entryPoints;
          this.entryPointNames = pointsArr.map(function (item) {
            return item["Description"];
          });
          this.filteredSuggPoint = this.suggestedEntryPointControl.valueChanges.pipe(
            startWith(""),
            map((value) => this._filterPoints(value))
          );
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });


      if (entryPointGrps.length === 1) {
        this.grpName = entryPointGrps[0].EntryPointGrpName;
        this.entryPointGroupID = entryPointGrps[0].EntryPointGroupID;

        this.entryPointService.parseGetEndpointAPI(this.entryPointGroupID)
          .then(() => {
            this.entryPoints = [...this.entryPointService.getEntryPoints()];
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
        var pointsArr = this.entryPoints;
        this.entryPointNames = pointsArr.map(function (item) {
          return item["Description"];
        });
        this.filteredSuggPoint = this.suggestedEntryPointControl.valueChanges.pipe(
          startWith(""),
          map((value) => this._filterPoints(value))
        );
      }
    } else {
      var entryPointGrps = this.entryPointGroups.filter(
        (epg: any) => epg.Active === 1
      );
      this.grpName = entryPointGrps[0].EntryPointGrpName;
      this.entryPointGroupID = entryPointGrps[0].EntryPointGroupID;
      this.entryPointService.parseGetEndpointAPI(this.entryPointGroupID)
        .then(() => {
          this.entryPoints = [...this.entryPointService.getEntryPoints()];
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
      var pointsArr = this.entryPoints;
      this.entryPointNames = pointsArr.map(function (item) {
        return item["Description"];
      });
      this.filteredSuggPoint = this.suggestedEntryPointControl.valueChanges.pipe(
        startWith(""),
        map((value) => this._filterPoints(value))
      );
    }


    if (this.passTemplate.ExpectedEntryPoint > 0) {
      this.entryPointService
        .parseGetEndpointAPI(this.entryPointGroupID)
        .then(() => {
          this.entryPoints = [...this.entryPointService.getEntryPoints()];
          var pointsArr = this.entryPoints;
          this.entryPointNames = pointsArr.map(function (item) {
            return item["Description"];
          });
          this.filteredSuggPoint = this.suggestedEntryPointControl.valueChanges.pipe(
            startWith(""),
            map((value) => this._filterPoints(value))
          );
          var entryPoints = this.entryPoints;
          this.suggEntryPointID = this.passTemplate.ExpectedEntryPoint;
          var selectedEntryPoint = entryPoints.filter(
            (f) => f.EntryPointID == this.passTemplate.ExpectedEntryPoint
          );
          this.suggEntryPointName = selectedEntryPoint.length > 0 ? selectedEntryPoint[0].Description : "";
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });

    }
    else {
      this.suggEntryPointID = null;
    }
    if (this.passTemplate.DestinationBldg > 0) {
      var buildings = this.buildingService.getBuildings();
      this.chosenBuildingId = this.passTemplate.DestinationBldg;
      var selectedBuilding = buildings.filter(
        (f) => f.BuildingID == this.passTemplate.DestinationBldg
      )
      this.buildingName = selectedBuilding.length > 0 ? selectedBuilding[0].BuildingName : "";
      if (this.buildingName) {
        this.buildingService.getFloors(this.chosenBuildingId).subscribe((res) => {
          this.Getfloors = res.body;
          this.filteredFloors = this.floorControl.valueChanges.pipe(
            startWith(""),
            map(value => typeof value === 'string' ? value : value?.Floor),
            map(Floor => Floor ? this._filterFloor(Floor) : this.Getfloors.slice())
  
          );
        });
      }
    } else {
      var buildings = this.buildingService.getBuildings();
      // this.buildingName = buildings[0].BuildingName;
      // this.chosenBuildingId = buildings[0].BuildingID;
    }

    if (this.passTemplate.EntryPointGroupIDLock)
      this.entryPointGroupFormControl.disable();
    if (this.passTemplate.ParkingLotIDLock)
      this.parkingLotFormControl.disable();
    if (this.passTemplate.DestinationBldgLock) this.buildingControl.disable();
    if (this.passTemplate.ExpectedEntryPointLock)
      this.suggestedEntryPointControl.disable();

    if (this.passTemplate.EstArrivalTime) {
      if (this.siteService.dataTimeFormat.angularTimeFormat == '12 hours') {
        this.minTime = moment(this.passTemplate.EstArrivalTime).format(
          "hh:mm tt"
        );
      } else {
        this.minTime = moment(this.passTemplate.EstArrivalTime).format(
          "HH:mm"
        );
      }
    }
    // this.minDate = */* Issue - When using a template with Activation Date starting in the future,
    // the user is unable to change the Date to any date before the activate date
    // selected by the Template (Ignore Locks allowed)
    //   this.passTemplate.StartDate < this.minDate
    //     ? this.minDate
    //     : new Date(this.passTemplate.StartDate);
    // this.minDuration = this.minDate;

    let minDateCalculated;
    this.passTemplate.EstArrivalTime = this.setArrivalTime();
    this.passTemplate.EstDepartureTime = this.setDepatureTime();
    if (this.passTemplate.StartDate) {
      minDateCalculated = this.passTemplate.StartDate;
    } else {
      this.minDate = this.utilService.getCurrentSiteDT();
      minDateCalculated = this.minDate;
      this.passTemplate.StartDate = null;
    }
    this.minDuration = minDateCalculated;
    //this.expirationDate.nativeElement.value = null;
    this.isExpirationDateDisabled = false;

    this.passService
      .getEndDateForTemplate(
        // this.minDate,
        //minDateCalculated,
        (this.passTemplate.StartDate == null && Number(this.passTemplate.EndTimeDuration) == 0) ? null : minDateCalculated,
        Number(this.passTemplate.EndTimeDuration),
        this.passTemplate.IncludeNonWorkingdays == 1 ? 0 : 1,
        new Date(this.orgEndDate),
        this.siteService.selectedSiteID
      )
      .then(() => {
        if (this.passService.passTemplateEndDate) {
          this.passTemplate.EndDate = this.passService.passTemplateEndDate;

          this.maxDate =
            new Date(this.orgEndDate) <
              new Date(new Date(this.minDate).setDate(this.minDate.getDate() + 14))
              ? new Date(this.orgEndDate)
              : new Date(new Date(this.minDate).setDate(this.minDate.getDate() + 14));
          if (this.IgnoreLocks == 0 && this.templateID > 0 && this.passTemplate.EndTimeDuration > 0 && this.passTemplate.EndTimeLock) {
            this.maxDate = this.passTemplate.EndDate;
          }
          this.maxDuration = new Date(this.passService.passTemplateEndDate);
        }
        else {
          this.minDuration = this.minDate;
          this.maxDate = new Date(
            this.utilService.getCurrentSiteDT().setDate(this.minDate.getDate() + 14)
          );
          this.maxDuration = new Date(
            this.utilService.getCurrentSiteDT().setDate(this.minDate.getDate() + 30)
          );
          //this.passTemplate.EndDate = this.minDuration;
          this.passTemplate.EndDate = null;
        }
        this.siteResponse = this.siteService.siteSettings;
        let dateFormat = (this.siteResponse.SystemSettings.filter(x => x.Name === "DateFormat")).map(x => x.Value);
        // this.expirationDate.nativeElement.value = this.datePipe.transform(
        //   this.passService.passTemplateEndDate,
        //   dateFormat[0]
        // );
        this.expirationDate.nativeElement.value = moment(this.passService.passTemplateEndDate).utc().format(dateFormat[0])
        this.setFromFieldValue();
      });

  }


  startDateChanged(event) {

    this.checkDateValidation();

    this.entryPointCrossedBy = 0;
    if (this.entryPoinIndicator) {
      this.enableNext = false;
      this.entryPoinIndicator = false;
    }
    const tempDate = new Date(event.value).getDate();
    if (this.datePipe.transform(new Date(event.value), "yyyy-MM-dd") < this.datePipe.transform(new Date(this.minDate), "yyyy-MM-dd")) {
      this.passTemplate.StartDate = undefined;
      this.expirationDate.nativeElement.value = null;
      this.isExpirationDateDisabled = false;
    } else
      if (this.datePipe.transform(new Date(event.value), "yyyy-MM-dd") > this.datePipe.transform(new Date(this.maxDate), "yyyy-MM-dd")) {
        this.passTemplate.StartDate = undefined;
        this.expirationDate.nativeElement.value = null;
        this.isExpirationDateDisabled = false;
      } else {
        this.minDuration = new Date(event.value);
        if (this.templateID == 0 || (this.IgnoreLocks == 1 && this.templateID > 0) || (this.IgnoreLocks == 0 && this.templateID > 0 && !this.passTemplate.EndTimeLock)) {
          this.maxDuration =
            new Date(this.orgEndDate) <
              new Date(new Date(event.value).setDate(tempDate + 30))
              ? new Date(this.orgEndDate)
              : new Date(new Date(event.value).setDate(tempDate + 30));

          this.passService
            .getEndDateForTemplate(
              event.value,
              30,
              !this.createPassForm.value.includeNonWorkingDays
                ? 1
                : this.createPassForm.value.includeNonWorkingDays == 1
                  ? 0
                  : 1,
              new Date(this.orgEndDate),
              this.siteService.selectedSiteID
            )
            .then(() => {
              this.maxDuration = this.passService.passTemplateEndDate;
            });

          this.expirationDate.nativeElement.value = null;
          this.isExpirationDateDisabled = false;
          this.selectedStartDate = this.minDuration;
          this.selectedEndDate = this.maxDuration;
        }
        this.entryPointCrossedBy = 0;
        //Set Parking Limit based on date change if parking lot is selected
        if (this.ParkingLotID != undefined && this.ParkingLotID > 0) {
          this.setParkingLimit(
            this.datePipe.transform(this.selectedStartDate, "yyyy-MM-dd"),
            this.datePipe.transform(this.selectedEndDate, "yyyy-MM-dd"),
            this.userService.organizationID
          );
        }
      }

    this.OnIncludeNonWorkingDays();
    if (this.checkDatesError) {
      this.enableNext = false;
      this.checkDatesError = false;
    }
    this.startElement = false;
    this.endElement = false;
    this.dayRangeArray = [];
  }

  endDateChanged(event) {

    this.checkDateValidation();
    //For the limit functionality
    if (this.entryPoinIndicator) {
      this.enableNext = false;
      this.entryPoinIndicator = false;
    }
    this.selectedEndDate = new Date(event.value);
    if (this.datePipe.transform(new Date(event.value), "yyyy-MM-dd") < this.datePipe.transform(new Date(this.minDuration), "yyyy-MM-dd")) {
      this.passTemplate.EndDate = undefined;
    } else
      if (this.datePipe.transform(new Date(event.value), "yyyy-MM-dd") > this.datePipe.transform(new Date(this.maxDuration), "yyyy-MM-dd")) {
        this.passTemplate.EndDate = undefined;
      } else {
        this.entryPointCrossedBy = 0;
        //Set Parking Limit based on date change if parking lot is selected
        if (this.ParkingLotID != undefined && this.ParkingLotID > 0) {
          this.setParkingLimit(
            this.datePipe.transform(this.selectedStartDate, "yyyy-MM-dd"),
            this.datePipe.transform(this.selectedEndDate, "yyyy-MM-dd"),
            this.userService.organizationID
          );
        }
      }
    this.OnIncludeNonWorkingDays();
    if (this.checkDatesError) {
      this.enableNext = false;
      this.checkDatesError = false;
    }
    this.startElement = false;
    this.endElement = false;
    this.dayRangeArray = [];
  }
  // initialize column definitions
  private initColDef() {
    return (this.columnDefs = [
      {
        headerName: this.screenLabels["NameAgGrid"]
          ? this.screenLabels["NameAgGrid"]
          : "Name",
        children: [
          {
            headerName: "",
            field: "",
            width: 80,
            cellStyle: { color: "#589bf8" },
            hide: false,
            checkboxSelection: true,
            resizable: false,
            sortable: false,
            lockPosition: true,
          },
          {
            headerCheckboxSelection: false,
            headerCheckboxSelectionFilteredOnly: true,
            field: "TmpPassID",
            hide: true,
            // columnGroupShow: "closed",
          },
          {
            headerName: this.screenLabels["FirstName"]
              ? this.screenLabels["FirstName"]
              : "First Name*",
            field: "VisitorFirstName",
            editable: !this.passTemplate.VisitorFirstNameLock,
            filter: true,
            //valueGetter: (p) => console.log(p),
            // columnGroupShow: "closed",
            cellStyle: function (params) {
              if (
                DataValidator.isNotEmpty(params.value) !=
                ValidationErrorCodes.Ok
              ) {
                //make background red
                return { color: "black", backgroundColor: "red" };
              } else {
                // reset style
                return { color: "black", backgroundColor: "transparent" };
              }
            },
            tooltip: function (params) {
              const validationCode = DataValidator.isNotEmpty(params.value);
              if (validationCode !== ValidationErrorCodes.Ok) {
                return DataValidator.getErrorMessage(validationCode);
              }
            },
          },
          // {
          //   headerName: this.screenLabels["MiddleName"]
          //     ? this.screenLabels["MiddleName"]
          //     : "Middle Name",
          //   field: "VisitorMiddleName",
          //   hide: true,
          //   editable: !this.passTemplate.VisitorMiddleNameLock,
          //   filter: true,
          //   columnGroupShow: "open",
          // },
          {
            headerName: this.screenLabels["LastName"]
              ? this.screenLabels["LastName"]
              : "Last Name*",
            field: "VisitorLastName",
            editable: !this.passTemplate.VisitorLastNameLock,
            filter: true,
            // valueGetter: (pr) => console.log(pr),
            cellStyle: function (params) {
              if (
                DataValidator.isNotEmpty(params.value) !=
                ValidationErrorCodes.Ok
              ) {
                //make background red
                return { color: "black", backgroundColor: "red" };
              } else {
                // reset style
                return { color: "black", backgroundColor: "transparent" };
              }
            },
            tooltip: function (params) {
              const validationCode = DataValidator.isNotEmpty(params.value);
              if (validationCode !== ValidationErrorCodes.Ok) {
                return DataValidator.getErrorMessage(validationCode);
              }
            },
          },
          {
            headerName: this.screenLabels["Suffix"]
              ? this.screenLabels["Suffix"]
              : "Suffix",
            field: "VisitorNameSuffix",
            hide: false,
            editable: !this.passTemplate.VisitorNameSuffixLock,
            filter: true,
            columnGroupShow: "open",
          },
        ],
      },
      {
        headerName: this.screenLabels["ContactInformationAgGrid"]
          ? this.screenLabels["ContactInformationAgGrid"]
          : "Contact Information",
        children: [
          {
            headerName: this.screenLabels["Company"]
              ? this.screenLabels["Company"]
              : "Company",
            field: "VisitorCompany",
            hide: false,
            editable: !this.passTemplate.VisitorCompanyLock,
            filter: true,
          },
          {
            headerName: this.screenLabels["PhoneNumber"]
              ? this.screenLabels["PhoneNumber"]
              : "Phone Number",
            field: "VisitorPhone",
            hide: false,
            editable: !this.passTemplate.VisitorPhoneLock,
            filter: true,
            columnGroupShow: "open",
          },
          {
            headerName: this.screenLabels["Country"]
              ? this.screenLabels["Country"]
              : "Country",
            field: "VisitorCountry",
            hide: this.siteService._siteHasCountry ? false : true,
            editable: !this.passTemplate.VisitorCountryLock,
            filter: true,
            columnGroupShow: "open",
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
              values: this.extractValues(this.countries),
            },
            valueFormatter: (params) =>
              this.lookupValue(this.countries, params.value),
            valueParser: (params) =>
              this.lookupKey(this.countries, params.newValue),
          },
          {
            headerName: this.screenLabels["EmailAgGrid"]
              ? this.screenLabels["EmailAgGrid"]
              : "Email",
            field: "VisitorEmail",
            hide: false,
            editable: !this.passTemplate.VisitorEmailLock,
            filter: true,
            cellRenderer: function (params) {
              const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,4}){1,2}$/;
              const value = params.value || "";
              if (!emailRegex.test(value) && value !== "") {
                return `<span style="color:red;">Invalid Email</span>`;
              }

              return value;
            }

          },
        ],
      },

      {
        headerName: "",
        children: [
          {
            headerName: this.screenLabels["EntryType"]
              ? this.screenLabels["EntryType"]
              : "Entry Type",
            field: "EntryType",
            editable: !this.passTemplate.EntryTypeLock,
            filter: true,
            hide: +this.passTemplate.EntryType == 2 || !this.siteService._siteHasParking ? true : false,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
              values: this.getEntryTypeValues(),
            },
            valueFormatter: (params) =>
              this.lookupValue(this.entryTypeMappings, params.value),
            valueParser: (params) =>
              this.lookupKey(this.entryTypeMappings, params.newValue),
          },
        ],
      },
      {
        headerName: this.screenLabels["IsDuplicateAgGrid"]
          ? this.screenLabels["IsDuplicateAgGrid"]
          : "Is A Duplicate",
        field: "IsADuplicate",
        editable: false,
        filter: true,
        hide: true,
        cellRenderer: (data) => {
          return data.value ? "Yes" : "No";
        },
      },
      {
        headerName: this.screenLabels["IgnoreDuplicateAgGrid"]
          ? this.screenLabels["IgnoreDuplicateAgGrid"]
          : "Ignore Duplicate",
        field: "IgnoreDuplicate",
        hide: !this.attemptedValidation,
        cellRenderer: function (params) {
          var input = document.createElement("input");
          input.type = "checkbox";
          input.checked = params.value;
          input.addEventListener("click", function (event: any) {
            const checked = event.target.checked;
            params.value = checked;

            // invoke on change event
            params.setValue(checked);

            params.node.data.IgnoreDuplicate = params.value;
          });
          return params.node.data.IsADuplicate ? input : null;
        },
      },
    ]);
  }

  protected onGridReady(params) {
    this.gridApi = params.api;

    // this.gridApi.setColumnDefs(this.columnDefs);
    // this.gridApi.setRowData(this.rowData);
    this.gridApi.setColumnDefs(this.initColDef());
  }

  // set the file
  protected incomingfile(event) {
    this.file = event.target.files[0];
    event.target.value = '';
    const EraseExistingTableDataMsg = this.screenLabels["EraseExistingTableDataMsg"] ? this.screenLabels["EraseExistingTableDataMsg"] : "Erase Existing Table Data";
    const EraseExistingDataErrorMsg = this.screenLabels["EraseExistingDataErrorMsg"] ? this.screenLabels["EraseExistingDataErrorMsg"] : "This action would erase all existing data in the table. Do you want to proceed ?";
    if (this.rowData.length) {
      this.dialogService
        .openConfirmDialog(
          EraseExistingTableDataMsg,
          EraseExistingDataErrorMsg
        )
        .afterClosed()
        .subscribe((confirm: boolean) => {
          if (confirm) this.upload();
        });
    } else {
      this.upload();
    }
  }

  // invoke when the upload button is clicked.
  protected upload() {
    this.enableSubmit = true;
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join("");
      const workbook = XLSX.read(bstr, { type: "binary" });
      const first_sheet_name = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[first_sheet_name];

      const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Retrieve the raw headers from the first row
      const rawHeaders = Object.keys(json[0]);

      // Manually map the raw headers to the expected headers
      const headers = rawHeaders.map((rawHeader) => {
        // Implement any necessary mapping logic here, if the headers don't match directly
        switch (rawHeader) {
          case "0":
            return "Visitor First Name";
          case "1":
            return "Visitor Last Name";
          case "2":
            return "Visitor Suffix";
          case "3":
            return "Visitor Company";
          case "4":
            return "Visitor Email";
          case "5":
            return "Visitor Phone";
          case "6":
            return "Visitor Country";
          default:
            return rawHeader; // If no mapping is necessary, use the raw header
        }
      });

      if (this.headersMatchExpected(headers)) {
        const json = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        this.formatPassUpload(json);
        this.rowData = [];
        // For checking rowlimit
        if (this.guestPasses.length <= 1000) {
          this.rowData = this.guestPasses;
          this.tempPassesCheck(this.apiBody, this.guestPasses);

          if (this.templateID > 0 && this.rowData.length > 0) {
            this.rowData.forEach((element) => {
              if (this.passTemplate.VisitorFirstNameLock)
                element.VisitorFirstName = this.passTemplate.VisitorFirstName;
              // if (this.passTemplate.VisitorMiddleNameLock)
              //   element.VisitorMiddleName = this.passTemplate.VisitorMiddleName;
              if (this.passTemplate.VisitorLastNameLock)
                element.VisitorLastName = this.passTemplate.VisitorLastName;
              if (this.passTemplate.VisitorCompanyLock)
                element.VisitorCompany = this.passTemplate.VisitorCompany;
              if (this.passTemplate.VisitorEmailLock)
                element.VisitorEmail = this.passTemplate.VisitorEmail;
              if (this.passTemplate.VisitorPhoneLock)
                element.VisitorPhone = this.passTemplate.VisitorPhone;
              if (this.passTemplate.VisitorCountryLock)
                element.VisitorPhone = this.passTemplate.VisitorCountry;
              if (this.passTemplate.EntryTypeLock)
                element.EntryType = this.passTemplate.EntryType;
            });
          }

          if (this.entryPointGroupID !== undefined && this.entryPointGroupID > 0) {
            this.setEntryLimitLastStep(
              this.selectedStartDate,
              this.selectedEndDate,
              this.userService.organizationID,
              this.entryPointGroupID
            );
          }

          if (
            this.EntryType === 1 &&
            this.ParkingLotID > 0 &&
            this.guestPasses.length > 0
          ) {
            this.setParkingLimit(
              this.selectedStartDate,
              this.selectedEndDate,
              this.userService.organizationID
            );
          }
          if (this.guestPasses.length > 0) this.gridApi.sizeColumnsToFit();
          this.rowData = this.guestPasses;
          this.tempPassesCheck(this.apiBody, this.guestPasses);
        }
      } else {
        // Show an error message indicating incorrect headers
        const wrongTemplateMsg =
          "The uploaded file does not match the expected template.";
        this.dialogService.openAlertDialogDialog("Error", wrongTemplateMsg, "400px", "150px");
      }
    };
    fileReader.readAsArrayBuffer(this.file);
  }

  private headersMatchExpected(headers: string[]): boolean {
    // Implement the logic to check if the headers match the expected headers.
    // Return true if they match, false if they don't.
    const expectedHeaders = [
      "Visitor First Name",
      "Visitor Last Name",
      "Visitor Suffix",
      "Visitor Company",
      "Visitor Email",
      "Visitor Phone",
    ];

    if (headers.length !== expectedHeaders.length) {
      return false;
    }

    for (let i = 0; i < headers.length; i++) {
      if (headers[i] !== expectedHeaders[i]) {
        return false;
      }
    }

    return true;
  }

  // format uploaded passes
  private formatPassUpload(json: Array<any>) {
    this.guestPasses = [];
    json.forEach((item) => {
      this.guestPasses.push({
        VisitorFirstName: item["Visitor First Name"],
        VisitorLastName: item["Visitor Last Name"],
        VisitorNameSuffix: item["Visitor Suffix"],
        VisitorCompany: item["Visitor Company"],
        VisitorEmail: item["Visitor Email"],
        VisitorPhone: item["Visitor Phone"],
        VisitorCountry: item["Visitor Country"],
        EntryType: this.createPassForm.value.entryType,
      });
    });

    return this.guestPasses;
  }

  onCellKeyPress(event) {
    this.enableSubmit = true;
    this.isValidationDisabled();
  }

  onCellKeyDown(e) {
    if (e.colDef.field == 'VisitorFirstName') e.data.VisitorFirstName = e.event.target.value;
    if (e.colDef.field == 'VisitorLastName') e.data.VisitorLastName = e.event.target.value;
    if (e.colDef.field == 'VisitorNameSuffix') e.data.VisitorNameSuffix = e.event.target.value;
    if (e.colDef.field == 'VisitorCompany') e.data.VisitorCompany = e.event.target.value;
    if (e.colDef.field == 'VisitorCountry') e.data.VisitorCountry = e.event.target.value;
    if (e.colDef.field == 'VisitorEmail') e.data.VisitorEmail = e.event.target.value;
    if (e.colDef.field == 'VisitorPhone') e.data.VisitorPhone = e.event.target.value;
  }


  onSelectionChanged($event) {
    const selectedRows = $event.api.getSelectedRows();
    if (selectedRows.length) {
      this.isRowSelected = true;
    } else {
      this.isRowSelected = false;
    }
  }

  // invoked when a selected row is removed
  onRemoveSelected() {
    const selectedRow = this.gridApi.getSelectedNodes()[0];
    const deleteVisitor = this.screenLabels["DeleteVisitor"] ? this.screenLabels["DeleteVisitor"] : "Delete Visitor";
    const message = this.screenLabels["DeleteWarningMsg"] ? this.screenLabels["DeleteWarningMsg"] : "Are you sure you want to delete the Row: <<id>> ?";
    const translatedMessage = message.replace("<<id>>", (selectedRow.rowIndex + 1));
    const deleteWarningMsg = this.screenLabels["DeleteWarningMsg"] ? translatedMessage : "Are you sure you want to delete the Row: " +
      (selectedRow.rowIndex + 1) +
      " ?";
    if (this.rowData.length !== 0) {
      this.dialogService
        .openConfirmDialog(
          deleteVisitor,
          deleteWarningMsg
        )
        .afterClosed()
        .subscribe((confirm: boolean) => {
          if (confirm) {
            this.guestPasses = this.rowData;

            if (
              selectedRow.data.EntryType == 1 &&
              this.ParkingLotID > 0 &&
              this.parkingCrossedBy > 0
            ) {
              --this.parkingCrossedBy;
            } else {
              //Set Parking Limit based on date change if parking lot is selected
              if (
                selectedRow.data.EntryType == 1 &&
                this.ParkingLotID != undefined &&
                this.ParkingLotID > 0
              ) {
                this.setParkingLimit(
                  this.datePipe.transform(this.selectedStartDate, "yyyy-MM-dd"),
                  this.datePipe.transform(this.selectedEndDate, "yyyy-MM-dd"),
                  this.userService.organizationID
                );
                --this.parkingCrossedBy;
              }
            }

            if (this.entryPointGroupID > 0 && this.entryPointCrossedBy > 0) {
              --this.entryPointCrossedBy;
            }
            this.rowData.splice(selectedRow.rowIndex, 1);
            this.gridApi.setRowData(this.rowData);
            this.isRowSelected = false;
            if (this.rowData.length == 0) {
              this.isRowSelected = false;
            }
          }
        });
    }
    this.passService.updateTempPassCheck(selectedRow.data, true).subscribe((res: any) => {
    })
  }

  // add empty row in ag-Grid
  onEmptyVisitorRowAdd() {
    this.gridApi.setColumnDefs(this.initColDef());
    this.enableSubmit = true;
    var visitor = {
      VisitorFirstName: this.passTemplate.VisitorFirstName,
      // VisitorMiddleName: this.passTemplate.VisitorMiddleName,
      VisitorMiddleName: "",
      VisitorLastName: this.passTemplate.VisitorLastName,
      VisitorCompany: this.passTemplate.VisitorCompany,
      VisitorEmail: this.passTemplate.VisitorEmail,
      VisitorPhone: this.passTemplate.VisitorPhone,
      VisitorCountry: this.passTemplate.VisitorCountry,
      EntryType: this.EntryType,
    };
    //for checking rowlimit
    if (this.rowData.length < 1000) {
      this.rowData.push(visitor);
      this.isValidationDisabled();
    } else {
      this.rowData;
      const rowLimitReached = this.screenLabels["RowLimitReached"] ? this.screenLabels["RowLimitReached"] : "Row Limit Reached";
      const maximumNumberOfRows = this.screenLabels["MaximumNumberOfRows"] ? this.screenLabels["MaximumNumberOfRows"] : "Maximum number of rows is 1000";
      this.dialogService.openAlertDialogDialog(
        rowLimitReached,
        maximumNumberOfRows,
        "400px",
        "150px"
      );
    }
    this.gridApi.setRowData(this.rowData);
    this.guestPasses = this.rowData;
    this.finalPassTmpData = this.rowData;
    if (this.entryPointGroupID > 0 && this.entryPointCrossedBy > 0) {
      ++this.entryPointCrossedBy;
    }

    this.tempPassesCheck(this.apiBody, this.guestPasses)
  }

  // invoked whenever bulk upload data is being validated
  public validateBulkUploadData() {
    this.userService.sendToggle(false);
    this.activateSpinner = true;
    const rowData = this.getGridData();
    this.validationErrors = [];
    setTimeout(() => {
      //this.spinnerService.setIsLoading(true);
      for (let i = 0; i < rowData.length; i++) {
        const entryType = rowData[i].EntryType
          ? rowData[i].EntryType
          : this.EntryType;
        const visitorCompany = rowData[i].VisitorCompany;
        const visitorEmail = rowData[i].VisitorEmail;
        const visitorFirstName = rowData[i].VisitorFirstName;
        const visitorLastName = rowData[i].VisitorLastName;
        // const visitorMiddleName = rowData[i].VisitorMiddleName;
        const visitorMiddleName = "";
        const visitorNameSuffix = rowData[i].VisitorNameSuffix;
        const visitorPhone = rowData[i].VisitorPhone;
        const visitorCountry = rowData[i].VisitorCountry;
        // check email format
        if (
          visitorEmail &&
          DataValidator.isEmail(visitorEmail) != ValidationErrorCodes.Ok
        ) {
          this.validationErrors.push({
            rowNumber: i + 1,
            errorText: DataValidator.getErrorMessage(
              DataValidator.isEmail(visitorEmail)
            ),
            fieldName: "Visitor Email",
          });
        }

        // check if visitor first name not empty
        if (
          DataValidator.isNotEmpty(visitorFirstName) != ValidationErrorCodes.Ok
        ) {
          this.validationErrors.push({
            rowNumber: i + 1,
            errorText: DataValidator.getErrorMessage(
              DataValidator.isNotEmpty(visitorFirstName)
            ),
            fieldName: this.screenLabels["VisitorFirstName"] ?
              this.screenLabels["VisitorFirstName"] : "Visitor First Name",
          });
        }

        // check if visitor last name not empty
        if (
          DataValidator.isNotEmpty(visitorLastName) != ValidationErrorCodes.Ok
        ) {
          this.validationErrors.push({
            rowNumber: i + 1,
            errorText: DataValidator.getErrorMessage(
              DataValidator.isNotEmpty(visitorLastName)
            ),
            fieldName: this.screenLabels["VisitorLastName"] ?
              this.screenLabels["VisitorLastName"] : "Visitor Last Name",
          });
        }

        // check if entry type is correct
        if (entryType < 1 || entryType > 2) {
          this.validationErrors.push({
            rowNumber: i + 1,
            errorText: this.screenLabels["InvalidEntryType"] ? this.screenLabels["InvalidEntryType"] : "Invalid Entry Type",
            fieldName: this.screenLabels["EntryType"] ? this.screenLabels["EntryType"] : "Entry Type",
          });
        }
      }
    })
    this.gridApi.setRowData(this.rowData);
    this.finalPassTmpData = this.rowData;
    this.attemptedValidation = true;
    this.gridApi.setColumnDefs(this.initColDef());
    this.gridApi.sizeColumnsToFit();
    const api: any = { ...this.gridApi };
    this.rowData = [...api.gridOptionsWrapper.gridOptions.rowData];
    const formattedData = this.formatBulkData(this.rowData);
    // ****************************************
    this.passService.updateTempPassCheck(this.rowData).subscribe((res: any) => {
      this.passService.passValidateGenUserTaskID(this.rowData).subscribe((res: any) => {
        const data = res.body;
        if (data.UserTaskID) {
          this.UserTaskID = data.UserTaskID;
          this.showBasicSnackBarComponent();
          this.getUserTaskStatus(data.UserTaskID);
        } else {
          this.getDuplicatePasses();
        }
      },
        (err) => {
          console.log(err);
          console.log(err.message);
        })

    })

    //Set Entry Limit based on date change if entry point group is selected
    if (this.apiBody.EntryPointGroupID != undefined && this.apiBody.EntryPointGroupID > 0) {
      this.setEntryLimitLastStep(
        this.apiBody.StartTime,
        this.apiBody.EndTime,
        this.userService.organizationID,
        this.apiBody.EntryPointGroupID
      );
    }
    //Set Parking Limit based on date change if parking lot is selected
    if (this.apiBody.ParkingLotID != undefined && this.apiBody.ParkingLotID > 0) {
      this.setParkingLimit(
        this.datePipe.transform(this.apiBody.StartTime, "yyyy-MM-dd"),
        this.datePipe.transform(this.apiBody.EndTime, "yyyy-MM-dd"),
        this.userService.organizationID
      );
    }
  }
  getUserTaskStatus(data, onPassCreateClick?) {

    var statusId = setInterval(() => {
      this.passService.getUserTaskStatus(data).subscribe((res: any) => {
        const data = res.body;
        //userTaskStatus, cleanUpStatus
        if (data.userTaskStatus === "Succeeded" || data.userTaskStatus === "Failed" || data.userTaskStatus === "Cancelled" || data.userTaskStatus === "Killed" || data.userTaskStatus === "Timed Out") {
          if (data.userTaskStatus === "Succeeded" && onPassCreateClick) {
            this.onNoClick();
            clearInterval(statusId);
            this.activateSpinner = false;
            const passCreationMsg = this.screenLabels["PassCreationMsg"] ? this.screenLabels["PassCreationMsg"] : "Passes created Successfully";
            this.notificationService.success(passCreationMsg);
          } else if (data.userTaskStatus === "Succeeded") {
            this.getDuplicatePasses();
            clearInterval(statusId);
          } else if (data.userTaskStatus === "Killed") {
            if (this.killCount > 2) {
              this.userService.sendCloseButtonToggle(true);
              const processKilledAdminMsg = this.screenLabels["ProcessKilledAdminMsg"] ? this.screenLabels["ProcessKilledAdminMsg"] : "Process has been killed, Please contact administrator";
              this.userService.setSnackBarMessage(processKilledAdminMsg);
              clearInterval(statusId);
            } else {
              if (data.cleanUpStatus === "Processing" || data.cleanUpStatus === null) {
                const processKilledCleanupMsg = this.screenLabels["ProcessKilledCleanupMsg"] ? this.screenLabels["ProcessKilledCleanupMsg"] : "Process has been killed, Cleanup is processing...";
                this.userService.setSnackBarMessage(processKilledCleanupMsg);
              }
              else if (data.userTaskStatus === "Killed" && data.cleanUpStatus === "Succeeded") {
                this.killCount = this.killCount + 1
                //here modal should be open only but the snackbar closed
                this.userService.sendToggle(true);
                this.activateSpinner = false;
                clearInterval(statusId);
              } else {
                this.userService.sendCloseButtonToggle(true);
                const processKilledCleanUpFailedAdminMsg = this.screenLabels["ProcessKilledCleanUpFailedAdminMsg"] ? this.screenLabels["ProcessKilledCleanUpFailedAdminMsg"] : "Process has been killed and CleanUp also failed. Please contact administrator";
                this.userService.setSnackBarMessage(processKilledCleanUpFailedAdminMsg);
              }
            }
          } else if (data.userTaskStatus === "Timed Out") {
            this.userService.sendCloseButtonToggle(false);
            if (data.cleanUpStatus === "Processing" || data.cleanUpStatus === null) {
              const processTimedoutCleanUpProcessingMsg = this.screenLabels["ProcessTimedoutCleanUpProcessingMsg"] ? this.screenLabels["ProcessTimedoutCleanUpProcessingMsg"] : "Process has been Timedout, Cleanup is processing...";
              this.userService.setSnackBarMessage(processTimedoutCleanUpProcessingMsg);
            }
            else if (data.userTaskStatus === "Timed Out" && data.cleanUpStatus === "Succeeded") {
              this.userService.sendCloseButtonToggle(true);
              const processTimedoutCleanUpCompletedMsg = this.screenLabels["ProcessTimedoutCleanUpCompletedMsg"] ? this.screenLabels["ProcessTimedoutCleanUpCompletedMsg"] : "Process has been Timedout, Cleanup is completed. Close the modal";
              this.userService.setSnackBarMessage(processTimedoutCleanUpCompletedMsg);
              clearInterval(statusId);
            } else {
              this.userService.sendCloseButtonToggle(true);
              const processTimedoutCleanUpFailedAdminMsg = this.screenLabels["ProcessTimedoutCleanUpFailedAdminMsg"] ? this.screenLabels["ProcessTimedoutCleanUpFailedAdminMsg"] : "Process has been Timed Out and CleanUp also failed. Please contact administrator";
              this.userService.setSnackBarMessage(processTimedoutCleanUpFailedAdminMsg);
            }
          } else if (data.userTaskStatus === "Failed") {
            this.userService.sendCloseButtonToggle(false);
            if (data.cleanUpStatus === "Processing" || data.cleanUpStatus === null) {
              const processFailedCleanUpProcessingMsg = this.screenLabels["ProcessFailedCleanUpProcessingMsg"] ? this.screenLabels["ProcessFailedCleanUpProcessingMsg"] : "Process has been Failed, Cleanup is processing...";
              this.userService.setSnackBarMessage(processFailedCleanUpProcessingMsg);
            }
            else if (data.userTaskStatus === "Failed" && data.cleanUpStatus === "Succeeded") {
              this.userService.sendCloseButtonToggle(true);
              const processFailedCleanUpCompletedMsg = this.screenLabels["ProcessFailedCleanUpCompletedMsg"] ? this.screenLabels["ProcessFailedCleanUpCompletedMsg"] : "Process has been Failed, Cleanup is completed. Close the modal";
              this.userService.setSnackBarMessage(processFailedCleanUpCompletedMsg);
              clearInterval(statusId);
            } else {
              this.userService.sendCloseButtonToggle(true);
              const processFailedCleanUpFailedAdminMsg = this.screenLabels["ProcessFailedCleanUpFailedAdminMsg"] ? this.screenLabels["ProcessFailedCleanUpFailedAdminMsg"] : "Process has been Failed and CleanUp also failed. Please contact administrator";
              this.userService.setSnackBarMessage(processFailedCleanUpFailedAdminMsg);
            }
          }
          else if (data.userTaskStatus === "Cancelled") {
            this.showBasicSnackBarComponent();
            this.userService.sendCloseButtonToggle(false);
            if (data.cleanUpStatus === "Processing" || data.cleanUpStatus === null) {
              const processCancelledCleanUpProcessingMsg = this.screenLabels["ProcessCancelledCleanUpProcessingMsg"] ? this.screenLabels["ProcessCancelledCleanUpProcessingMsg"] : "Process has been cancelled, Cleanup is processing...";
              this.userService.setSnackBarMessage(processCancelledCleanUpProcessingMsg);
            }
            else if (data.userTaskStatus === "Cancelled" && data.cleanUpStatus === "Succeeded") {
              const processCancelledCleanUpCompletedMsg = this.screenLabels["ProcessCancelledCleanUpCompletedMsg"] ? this.screenLabels["ProcessCancelledCleanUpCompletedMsg"] : "Process has been Cancelled, Cleanup is completed. Close the modal";
              this.userService.setSnackBarMessage(processCancelledCleanUpCompletedMsg);
              this.userService.sendCloseButtonToggle(true);
              clearInterval(statusId);
            } else {
              this.userService.sendCloseButtonToggle(true);
              const processCancelledCleanUpFailedAdminMsg = this.screenLabels["ProcessCancelledCleanUpFailedAdminMsg"] ? this.screenLabels["ProcessCancelledCleanUpFailedAdminMsg"] : "Process has been Cancelled and CleanUp also failed. Please contact administrator";
              this.userService.setSnackBarMessage(processCancelledCleanUpFailedAdminMsg);
            }
          }
        }
        this.passService.getPartyPassCreatedCountAPI(this.partyID).subscribe((res: HttpResponse<any>) => {
          if (res.body.PartyPassCreatedCount < this.finalPassTmpData.length) {
            var message = this.screenLabels["PassSuccessFailCountMsg"] ? this.screenLabels["PassSuccessFailCountMsg"] : "<<count1>> Passes were created successfully, <<count2>> passes were failed. Please check host-email for more information"
            const msg = message.replace("<<count1>>", res.body.PartyPassCreatedCount).replace("<<count2>>", this.finalPassTmpData.length - res.body.PartyPassCreatedCount)
            this.notificationService.success(msg);
          }
        })
      },
        (err) => {
          console.log(err);
          console.log(err.message);
        })
    }, 5000)
  }

  //get duplicate Entries
  getDuplicatePasses(singlePass?) {
    this.passService.getDuplicatePasses().subscribe((res: any) => {
      this.duplicateTempPassID = res.body.resultData;
      this.activateSpinner = false;
      // here we have to close the snackbar 
      this.userService.sendToggle(true);
      if (this.duplicateTempPassID.length > 0) {
        if (singlePass) {
          // duplicate found on a single pass, show option to allow duplicate
          const duplicatePassDetectMsg = this.screenLabels["DuplicatePassDetectMsg"] ? this.screenLabels["DuplicatePassDetectMsg"] : "Duplicate Pass Detected";
          const duplicatePassDetectWarningMsg = this.screenLabels["DuplicatePassDetectWarningMsg"] ? this.screenLabels["DuplicatePassDetectWarningMsg"] : "It looks like we have a Duplicate Pass in the System! Do you still want to create the Pass as a Duplicate ?";
          this.dialogService
            .openConfirmDialog(
              duplicatePassDetectMsg,
              duplicatePassDetectWarningMsg
            )
            .afterClosed()
            .subscribe((confirmation: boolean) => {
              if (confirmation) {
                this.createPassAPINew()
              }
            });
        }
        else {
          this.enableSubmit = true;

          // Extract TmpPassID values from duplicateTempPassID array
          const duplicatePassIDs = this.duplicateTempPassID.map(data => data.TmpPassID);

          // Iterate through rowData
          this.rowData.forEach(row => {
            // Check if the current row's TmpPassID exists in duplicatePassIDs
            const isDuplicate = duplicatePassIDs.includes(row.TmpPassID);
            // Set IsADuplicate based on the result
            row.IsADuplicate = isDuplicate;
          });
          this.gridApi.setRowData(this.rowData);
          this.finalPassTmpData = this.rowData;

          // this.rowData.forEach(row => {
          //   this.duplicateTempPassID.forEach(data => {
          //     if (row.TmpPassID === data.TmpPassID) {
          //       row.IsADuplicate = true;
          //     }
          //   })
          //   this.gridApi.setRowData(this.rowData);
          //   this.finalPassTmpData = this.rowData;
          // })
        }
      } else if (this.duplicateTempPassID.length === 0) {
        this.enableSubmit = false;
        // this.closeSnackBar.emit(true);
        this.rowData.forEach(row => {
          row.IsADuplicate = false;
          this.attemptedValidation = true;
          this.gridApi.setRowData(this.rowData);
          //this.finalPassTmpData = this.rowData;
        })
        if (this.partyPassFlag || singlePass) {
          if (this.partyPassFlag) {
            this.passService.generatePartyPass().subscribe((res: any) => {
              const response = res.body;
              this.partyID = response.PartyID;
              this.createPassAPINew();
            });
          } else if (singlePass)
            this.createPassAPINew();
        }
      }
    },
      (err) => {
        console.log(err);
        console.log(err.message);
      })
  }
  //ngOnDestroy

  ngOnDestroy(): void { }
  // format the passes in accordance with Pass Validation API
  private formatBulkData(rowData: Array<any>) {
    this.apiBody.Authorizer = this.userService.userID;
    this.apiBody.ExpectedEntryPoint = !this.suggEntryPointID
      ? null
      : +this.suggEntryPointID;
    this.apiBody.EntryPointGroupID = !this.entryPointGroupID
      ? null
      : +this.entryPointGroupID;
    this.apiBody.EntryType = this.EntryType;

    this.apiBody.NotesInternal = !this.createPassForm.value.notesInternal
      ? null
      : this.createPassForm.value.notesInternal;
    this.apiBody.NotesPass = !this.createPassForm.value.notesPass
      ? null
      : this.createPassForm.value.notesPass;

    this.apiBody.ParkingSpot = !this.passTemplate.ParkingSpot
      ? null
      : this.passTemplate.ParkingSpot;
    this.apiBody.DestinationBldg = !this.chosenBuildingId
      ? null
      : +this.chosenBuildingId;
    this.apiBody.DestinationBlgFlr = !this.createPassForm.value
      .destinationBlgFlr
      ? null
      : +this.createPassForm.value.destinationBlgFlr;
    this.apiBody.DestinationRoom = !this.createPassForm.value.destinationBlgRoom
      ? null
      : this.createPassForm.value.destinationBlgRoom;
    this.apiBody.HostTWID = null;
    this.apiBody.OrganizationID = this.userService.organizationID;
    this.apiBody.Authorizer = this.userService.userID;
    this.apiBody.Operator =
      this.userService.loggedInUserID > 0
        ? this.userService.loggedInUserID
        : this.userService.userID;
    this.apiBody.PhotoPass = this.createPassForm.value.PhotoPass ? 1 : 0;
    this.apiBody.Active = true;
    this.apiBody.CopyFromPassID = null;
    this.apiBody.PartyID = null;
    this.apiBody.EmployeeRef = null;
    this.apiBody.Status = "Invalid";
    this.apiBody.UserID = this.userService.userID;
    this.apiBody.AllowDup = false;
    this.apiBody.VisitorEmail =
      this.createPassForm.value.visitorEmail === ""
        ? null
        : this.createPassForm.value.visitorEmail;

    this.apiBody.TemplateID = this.templateID;
    this.apiBody.VisitorFirstName = this.passTemplate.VisitorFirstName.trim();
    this.apiBody.VisitorLastName = this.passTemplate.VisitorLastName.trim();
    // this.apiBody.VisitorMiddleName = this.passTemplate.VisitorMiddleName;
    this.apiBody.VisitorMiddleName = "";
    this.apiBody.VisitorNameSuffix = this.passTemplate.VisitorNameSuffix;
    this.apiBody.VisitorCompany = this.passTemplate.VisitorCompany;
    this.apiBody.VisitorEmail = this.passTemplate.VisitorEmail;
    this.apiBody.VisitorPhone = this.passTemplate.VisitorPhone;
    this.apiBody.VisitorCountry = this.passTemplate.VisitorCountry;
    let ArrivalDate = moment(this.passTemplate.StartDate).format("YYYY-MM-DD");
    let ArrivalTime = moment(this.passTemplate.EstArrivalTime).format("HH:mm:ss");
    let ArrivalTimeDate = moment(ArrivalDate + ' ' + ArrivalTime);
    this.apiBody.EstArrivalTime = this.formatDate(
      ArrivalTimeDate
    );
    let DepatureDate = moment(this.passTemplate.EndDate).format("YYYY-MM-DD");
    let DepatureTime = moment(this.passTemplate.EstDepartureTime).format("HH:mm:ss");
    let DepatureTimeDate = moment(DepatureDate + ' ' + DepatureTime);
    this.apiBody.EstDepartureTime = this.formatDate(
      DepatureTimeDate
    );
    this.apiBody.VisitReason = this.passTemplate.VisitReason;
    this.apiBody.IncludeNonWorkingdays = this.passTemplate.IncludeNonWorkingdays;
    this.apiBody.EntryType = this.EntryType;
    this.apiBody.ParkingSpot = this.passTemplate.ParkingSpot;
    this.apiBody.NotesInternal = this.passTemplate.NotesInternal;
    this.apiBody.NotesPass = this.passTemplate.NotesPass;
    this.apiBody.DestinationBlgFlr = this.passTemplate.DestinationBlgFlr;
    this.apiBody.DestinationBlgRoom = this.passTemplate.DestinationRoom;
    this.apiBody.HostFirstName = this.createPassForm.value
      .isHostAndAuthorizerSame
      ? this.userService.loggedInUserFirstName
      : this.passTemplate.HostFirstName;
    // this.apiBody.HostMiddleName = this.createPassForm.value
    //   .isHostAndAuthorizerSame
    //   ? this.userService.loggedInUserMiddleName
    //   : this.passTemplate.HostMiddleName;
    this.apiBody.HostMiddleName = "";
    this.apiBody.HostLastName = this.createPassForm.value
      .isHostAndAuthorizerSame
      ? this.userService.loggedInUserLastName
      : this.passTemplate.HostLastName;
    this.apiBody.HostNameSuffix = this.createPassForm.value
      .isHostAndAuthorizerSame
      ? this.userService.loggedInSuffix
      : this.passTemplate.HostNameSuffix;

    this.apiBody.HostCompany = this.createPassForm.value.isHostAndAuthorizerSame
      ? this.userService.organization
      : this.passTemplate.HostCompany;
    this.apiBody.HostPhone = this.passTemplate.HostPhone;
    var hostEmails: String[] = [];
    this.hostEmails.forEach((element) => {
      if (element.valid === "valid") {
        hostEmails.push(element.email);
      }
    });
    this.apiBody.HostEmail =
      hostEmails.length > 0 ? hostEmails.join(";") : null;
    this.apiBody.StartTime = !this.passTemplate.StartDate
      ? null
      : this.datePipe.transform(this.passTemplate.StartDate, "yyyy-MM-dd");
    this.apiBody.EndTime = !this.passTemplate.EndDate
      ? null
      : this.datePipe.transform(this.passTemplate.EndDate, "yyyy-MM-dd");
    this.apiBody.PhotoPass = this.passTemplate.PhotoPass;
    this.apiBody.HostTWID = null;
    this.apiBody.ParkingLotID =
      this.apiBody.EntryType == 1 ? this.ParkingLotID : null;
    this.apiBody.OrganizationID = this.userService.organizationID;
    this.apiBody.Authorizer = this.userService.userID;
    this.apiBody.Operator =
      this.userService.loggedInUserID > 0
        ? this.userService.loggedInUserID
        : this.userService.userID;
    this.apiBody.PhotoPass = this.createPassForm.value.PhotoPass ? 1 : 0;
    this.apiBody.Active = true;
    this.apiBody.EmployeeRef = null;
    this.apiBody.UserID = this.userService.userID;
    this.apiBody.AllowDup = false;

    // add visitor details
    const visitorPasses = [];
    rowData.forEach((item) => {
      let tempApiBody = { ...this.apiBody };
      tempApiBody.EntryType = !item.EntryType
        ? this.apiBody.EntryType
        : item.EntryType;
      tempApiBody.ParkingLotID =
        tempApiBody.EntryType == 1 ? this.ParkingLotID : null;
      tempApiBody.IsADuplicate = item.IsADuplicate;
      tempApiBody.VisitorCompany = !item.VisitorCompany
        ? null
        : item.VisitorCompany;
      tempApiBody.VisitorEmail = !item.VisitorEmail ? null : item.VisitorEmail;
      tempApiBody.VisitorFirstName = !item.VisitorFirstName
        ? null
        : item.VisitorFirstName;
      tempApiBody.VisitorLastName = !item.VisitorLastName
        ? null
        : item.VisitorLastName;
      // tempApiBody.VisitorMiddleName = !item.VisitorMiddleName
      //   ? null
      //   : item.VisitorMiddleName;
      tempApiBody.VisitorMiddleName = "";
      tempApiBody.VisitorNameSuffix = !item.VisitorNameSuffix
        ? null
        : item.VisitorNameSuffix;
      tempApiBody.VisitorCountry = !item.VisitorCountry
        ? null
        : item.VisitorCountry;
      tempApiBody.VisitorPhone = !item.VisitorPhone ? null : item.VisitorPhone.toString();
      visitorPasses.push(tempApiBody);
    });

    return visitorPasses;
  }

  // gets the data from Ag Grid
  private getGridData() {
    const rowData = [];
    if (this.gridApi != undefined)
      this.gridApi.forEachNode((node) => rowData.push(node.data));

    return [...rowData];
  }

  // invoked when entry type radio button is changed
  onNewEntryTypeChange(event: MatRadioChange) {
    if (event.value == 1) {
      if (!this.parkingName) {
        this.enableNext = true;
      } else {
        this.enableNext = false;
      }
      this.isParkingDisabled = false;
    } else {
      this.enableNext = false;
      this.isParkingDisabled = true;
      this.parkingName = "";
    }
    //this.gridApi.setColumnDefs(this.initColDef());
    if (this.rowData.length > 0) this.gridApi.sizeColumnsToFit();
  }

  // invoke to toggle file upload.
  onUploadTypeChanged(event: MatCheckboxChange) {
    this.isFileUploadTypeSelected = event.checked;
  }

  // invoke to toggle the view for multi pass upload.
  onMultiplePassSelectionChanged(event: MatCheckboxChange) {
    this.isMultiplePassSelected = event.checked;
    if (this.isMultiplePassSelected) {
      this.passTemplate.VisitorFirstName = "",
        this.passTemplate.VisitorMiddleName = "",
        this.passTemplate.VisitorLastName = "",
        this.passTemplate.VisitorCompany = "",
        this.passTemplate.VisitorEmail = "",
        this.passTemplate.VisitorPhone = "",
        this.passTemplate.VisitorCountry = 0
    }
  }

  // Method to get entry type values based on parkingName
  private getEntryTypeValues() {
    if (this.parkingName) {
      return this.extractValues(this.entryTypeMappings); // Both options available
    } else {
      this.rowData.forEach(item => {
        item.EntryType = 2; // Set EntryType to 2 for each object
      });
      return [2]; // Only "Walk-on" option available
    }
  }

  // ag-grid entry type callbacks
  private entryTypeMappings = {
    1: "Drive-on",
    2: "Walk-on",
  };

  private extractValues(mappings) {
    return Object.keys(mappings);
  }

  private lookupValue(mappings, key) {
    return mappings[key];
  }

  private lookupKey(mappings, name) {
    var keys = Object.keys(mappings);
    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      if (mappings[key] === name) {
        return key;
      }
    }
  }



  // logic to create individual pass
  private createIndividualPass(apiBody) {
    this.finalPassTmpData = [];
    this.activateSpinner = true;
    this.passService
      .createTempPassCheck(apiBody, true).subscribe((res: any) => {
        this.finalPassTmpData = res.body;
        const body = []
        body.push(apiBody)
        this.passService.passValidateGenUserTaskID(body).subscribe((res: any) => {
          const data = res.body;
          if (data.UserTaskID) {
            this.getUserTaskStatus(data.UserTaskID);
          } else {
            this.getDuplicatePasses(true);
          }
        });
      });
  }

  // invoked to display the legend
  protected showInfoForEmail() {
    const html = `
    <span>${this.screenLabels["showInfoForEmailMsg"] ?
        this.screenLabels["showInfoForEmailMsg"] :
        "Maximum Number of Emails allowed are 3"}</span><br><br>
    <table>
      <tr>
      <th class="legend-text">${this.screenLabels["Color"] ?
        this.screenLabels["Color"] :
        "Color"}</th>
      <th class="legend-text">${this.screenLabels["Description"] ?
        this.screenLabels["Description"] :
        "Description"}</th>
      </tr>
      <tr>
        <td class="legend-text"><span class="row-ignore legend-info">&nbsp;</span></td>
        <td class="legend-text">${this.screenLabels["ValidEmailPattern"] ? this.screenLabels["ValidEmailPattern"] : "Valid Email Pattern"}</td>
      </tr> 
      <tr>
        <td class="legend-text"><span class="row-warning legend-info">&nbsp;</span></td>
        <td class="legend-text">${this.screenLabels["InvalidEmailPattern"] ? this.screenLabels["InvalidEmailPattern"] : "Invalid Email Pattern"}</td>
      </tr>     
    </table>
       
    `;
    // <span class="row-ignore legend-info">&nbsp;</span>
    // <span>&nbsp;&nbsp;Invalid Email </span>
    this.dialogService.openAlertDialogDialog(this.screenLabels["Legend"] ? this.screenLabels["Legend"] : "Legend", html, "400px", "260px");
  }

  // invoked to display the legend
  protected showLegend() {
    const html = `
    <table>
      <tr>
        <th class="legend-text">${this.screenLabels["Color"] ?
        this.screenLabels["Color"] :
        "Color"}</th>
        <th class="legend-text">${this.screenLabels["Description"] ?
        this.screenLabels["Description"] :
        "Description"}</th>
      </tr>
      <tr>
        <td class="legend-text"><span class="row-warning legend-info">&nbsp;</span></td>
        <td class="legend-text">${this.screenLabels["DuplicateRecordFoundMsg"] ?
        this.screenLabels["DuplicateRecordFoundMsg"] :
        "Duplicate Record Found"}</td>
      </tr>
      <tr>
        <td class="legend-text"><span class="row-ignore legend-info">&nbsp;</span></td>
        <td class="legend-text">${this.screenLabels["DuplicateRecordIgnoredMsg"] ?
        this.screenLabels["DuplicateRecordIgnoredMsg"] :
        "Duplicate Record Ignored"}</td>
      </tr>
    </table>
    `;
    this.dialogService.openAlertDialogDialog(this.screenLabels["Legend"] ? this.screenLabels["Legend"] : "Legend", html, "400px", "260px");
  }

  // invoked to check if the request could be submitted
  protected isSubmittable() {
    if (this.isMultiplePassSelected) {
      if (this.uniquePasses.length > 0 && this.validationErrors.length === 0)
        return true;
      else return false;
    } else {
      return true;
    }
  }

  //on Parking Lot Dropdown change get Balance ParkingLot count
  onParkingLotChange(event: string, isBlur?: boolean) {
    const parkingNames: any[] = this.parkingLotService.getParkingLot();
    const selectedParkingLot: any = parkingNames.find((element: any) => element.Name === event);
    if (selectedParkingLot) {
      if (this.setTimeoutRef) {
        clearTimeout(this.setTimeoutRef);
        this.setTimeoutRef = null;
      }
      this.Name = selectedParkingLot.Name;
      this.ParkingLotID = selectedParkingLot.ParkingLotID;
      this.setParkingLimit(
        this.datePipe.transform(this.selectedStartDate, "yyyy-MM-dd"),
        this.datePipe.transform(this.selectedEndDate, "yyyy-MM-dd"),
        this.userService.organizationID
      );
      this.parkingLotService.getParkingLotAPI().then(() => {
        this.parkingLotName = this.parkingLotService.getParkingLot()
          .filter(
            (p) => p.ParkingLotID === this.ParkingLotID
          )[0].Name;
      });
      this.filteredParking = this.parkingLotFormControl.valueChanges.pipe(
        startWith(""),
        map((value) => this._filterParking(value))
      );
    } else if (isBlur) {
      this.setTimeoutRef = setTimeout(() => {
        this.Name = "";
        this.parkingLotFormControl.setValue("");
        this.setTimeoutRef = null;
      }, 1000);
    }
    if (this.parkingName) this.enableNext = false;
    else if (!this.parkingName) this.enableNext = true;
    this.onFloor();
  }

  //set Parking limit and parking crossed by
  setParkingLimit(startDate, endDate, organizationID) {
    this.parkingCrossedBy = 0;
    this.DriveOnGuestPass = 0;
    this.guestPasses.forEach((pass) => {
      if (pass.EntryType == 1) {
        ++this.DriveOnGuestPass;
      }
    });
    this.organizationService
      .getParkingLimitAnalyticsAPI(startDate, endDate, organizationID, null)
      .then(() => {
        this.remainingParking = this.organizationService.parkingLimitAPIResponse.filter(
          (f) =>
            f.OrganizationID == this.userService.organizationID &&
            f.ParkingLotID == this.ParkingLotID
        );
        if (
          this.remainingParking != undefined ||
          this.remainingParking.length > 0
        ) {
          this.parkingLimit = this.remainingParking[0].BalanceParkingCount;
          if (this.parkingLimit < this.DriveOnGuestPass) {
            this.parkingCrossedBy = this.DriveOnGuestPass - this.parkingLimit;
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }
  //set Suggested Entry Point
  setSuggestedEntryPoint(entryPointGroupId) {
    this.entryPointService
      .parseGetEndpointAPI(entryPointGroupId)
      .then(() => {
        this.entryPoints = [...this.entryPointService.getEntryPoints()];
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
    var pointsArr = this.entryPoints;
    this.entryPointNames = pointsArr.map(function (item) {
      return item["Description"];
    });
    this.filteredSuggPoint = this.suggestedEntryPointControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterPoints(value))
    );
  }
  //set Entry limit and Entry crossed by
  setEntryLimit(startDate, endDate, organizationID, entryPointGroupId) {
    this.spinnerService.setIsLoading(true);
    this.stepper.selectedIndex = this.stepper.selectedIndex - 1;
    this.displayStartDate = moment(startDate).format("MM/DD/YYYY");
    this.displayEndDate = moment(endDate).format("MM/DD/YYYY");
    this.organizationService
      .getEntryPointZoneLimitAnalyticsAPI(
        startDate,
        endDate,
        organizationID,
        null,
        entryPointGroupId,
        this.passTemplate.IncludeNonWorkingdays,
        this.siteService.selectedSiteID
      )
      .then(() => {
        this.spinnerService.setIsLoading(false);
        const entryPointGrpDetails = this.organizationService.entryPointZoneLimitAPIResponse;
        this.entryPointCrossedBy = 0;
        this.entryPointZoneLimit = 0;
        this.allZonesSelfGoverened = false;
        this.entryPoinIndicator = false;
        if (entryPointGrpDetails[0].regulated) {
          this.allZonesSelfGoverened = true;
          //this.step++;
          this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
        }
        else {
          this.entryPointZoneLimit = entryPointGrpDetails[0].FreeSpace;
          if (
            this.guestPasses.length > 0 &&
            this.entryPointZoneLimit < this.guestPasses.length
          ) {
            this.entryPointCrossedBy = this.guestPasses.length - this.entryPointZoneLimit;
            if (this.entryPointCrossedBy > 0) {
              this.enableSubmit = true;
            }
          } else {
            //For the single pass creation
            if (this.entryPointZoneLimit <= 0) {
              this.entryPoinIndicator = true;
              this.enableNext = true;
              //on holiday msg...hiding entry limit msg
              if (this.checkDatesError) {
                this.entryPoinIndicator = false;
              }
            } else {
              //this.step++;
              this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
            }
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        this.spinnerService.setIsLoading(false);
        this.notificationService.failure(err.message);
        this.enableNext = true;
      })
  }
  setEntryLimitLastStep(startDate, endDate, organizationID, entryPointGroupId) {
    this.spinnerService.setIsLoading(true);
    this.displayStartDate = moment(startDate).format("MM/DD/YYYY");
    this.displayEndDate = moment(endDate).format("MM/DD/YYYY");
    this.organizationService
      .getEntryPointZoneLimitAnalyticsAPI(
        startDate,
        endDate,
        organizationID,
        null,
        entryPointGroupId,
        this.passTemplate.IncludeNonWorkingdays,
        this.siteService.selectedSiteID
      )
      .then(() => {
        this.spinnerService.setIsLoading(false);
        const entryPointGrpDetails = this.organizationService.entryPointZoneLimitAPIResponse;
        this.entryPointCrossedBy = 0;
        this.entryPointZoneLimit = 0;
        this.allZonesSelfGoverened = false;
        this.entryPoinIndicator = false;
        if (entryPointGrpDetails[0].regulated) {
          this.allZonesSelfGoverened = true;
        }
        else {
          this.entryPointZoneLimit = entryPointGrpDetails[0].FreeSpace;
          if (
            this.guestPasses.length > 0 &&
            this.entryPointZoneLimit < this.guestPasses.length
          ) {
            this.entryPointCrossedBy = this.guestPasses.length - this.entryPointZoneLimit;
          } else {
            //For the single pass creation
            if (this.entryPointZoneLimit <= 0) {
              this.entryPoinIndicator = true;
              this.enableNext = true;
              //on holiday msg...hiding entry limit msg
              if (this.checkDatesError) {
                this.entryPoinIndicator = false;
              }
            } else {
            }
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        this.spinnerService.setIsLoading(false);
        this.notificationService.failure(err.message);
        this.enableNext = true;
      })
  }
  //initialize dates
  initializeDates() {
    var d = this.utilService.getCurrentSiteDT();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    const userAssociations = this.userService.getUserDetails();
    const selectedOrg = userAssociations[0].UserOrganization.filter(
      (uo) => uo.OrganizationID === this.userService.organizationID
    );

    this.orgStartDate = selectedOrg[0].Startdate;

    this.orgEndDate = selectedOrg[0].Internal
      ? new Date(year + 10, month, day)
      : selectedOrg[0].EndDate;
    this.minDate =
      new Date(this.orgStartDate) > this.utilService.getCurrentSiteDT()
        ? new Date(this.orgStartDate)
        : this.utilService.getCurrentSiteDT();
    this.minDuration = this.minDate;
    this.maxDate =
      new Date(this.orgEndDate) <
        new Date(new Date(this.minDate).setDate(this.minDate.getDate() + 14))
        ? new Date(this.orgEndDate)
        : new Date(new Date(this.minDate).setDate(this.minDate.getDate() + 14));

    this.maxDuration =
      new Date(this.orgEndDate) <
        new Date(new Date(this.minDate).setDate(this.minDate.getDate() + 30))
        ? new Date(this.orgEndDate)
        : new Date(new Date(this.minDate).setDate(this.minDate.getDate() + 30));
    // this.passService
    //   .getEndDateForTemplate(this.minDate, 30, 1, new Date(this.orgEndDate))
    //   .then(() => {
    //     this.maxDuration = this.passService.passTemplateEndDate;
    //   });

    this.selectedStartDate = this.minDuration;
    this.selectedEndDate = this.maxDuration;
  }

  handleSpacebar(ev) {
    ev.preventDefault();
    this.step = 0;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || "").trim()) {
      if (DataValidator.isEmail(value) == ValidationErrorCodes.Ok) {
        this.hostEmails.push({ email: value.trim(), valid: "valid" });
        this.emailValidation = false;
      } else {
        this.hostEmails.push({ email: value.trim(), valid: "invalid" });
        this.emailValidation = true;
      }
    }
    // Reset the input value

    if (input) {
      input.value = "";
    }
  }

  remove(index: number): void {
    if (index >= 0) {
      this.hostEmails.splice(index, 1);
    }
  }

  onEmailValueChange(index: number): void {
    var email = "hostEmail" + index;
    var changedValue = this.createPassForm.value[email];
    if ((changedValue || "").trim()) {
      if (DataValidator.isEmail(changedValue) == ValidationErrorCodes.Ok) {
        this.hostEmails[index] = { email: changedValue.trim(), valid: "valid" };
      } else {
        this.hostEmails[index] = {
          email: changedValue.trim(),
          valid: "invalid",
        };
      }
    }
  }

  formatDate(data: any) {
    let date = this.utilService.stripLocalTimeZone(data.toString());

    return data ? date : null;
  }

  initializeLocks() {
    this.passTemplate.HostFirstNameLock = false;
    this.passTemplate.HostMiddleNameLock = false;
    this.passTemplate.HostLastNameLock = false;
    this.passTemplate.HostNameSuffixLock = false;
    this.passTemplate.HostEmailLock = false;
    this.passTemplate.HostPhoneLock = false;
    this.passTemplate.HostCompanyLock = false;
    this.passTemplate.IncludeNonWorkingdaysLock = false;
    this.passTemplate.PhotoPassLock = false;
    this.passTemplate.StartTimeLock = false;
    this.passTemplate.EndTimeLock = false;
    this.passTemplate.EstArrivalTimeLock = false;
    this.passTemplate.EstDepartureTimeLock = false;
    this.passTemplate.VisitReasonLock = false;
    this.passTemplate.DestinationBldgLock = false;
    this.passTemplate.DestinationBlgFlrLock = false;
    this.passTemplate.DestinationRoomLock = false;
    this.passTemplate.EntryTypeLock = false;
    this.passTemplate.ExpectedEntryPointLock = false;
    this.passTemplate.EntryPointGroupIDLock = false;
    this.passTemplate.ParkingLotIDLock = false;
    this.passTemplate.ParkingSpotLock = false;
    this.passTemplate.VisitorFirstNameLock = false;
    this.passTemplate.VisitorMiddleNameLock = false;
    this.passTemplate.VisitorLastNameLock = false;
    this.passTemplate.VisitorNameSuffixLock = false;
    this.passTemplate.VisitorEmailLock = false;
    this.passTemplate.VisitorPhoneLock = false;
    this.passTemplate.VisitorCompanyLock = false;
    this.passTemplate.VisitorCountryLock = false;
    this.passTemplate.NotesPassLock = false;
    this.passTemplate.NotesInternalLock = false;
  }
  setLockFields() {
    this.passTemplate.HostFirstNameLock = this.passTemplate.HostFirstNameLock
      ? true
      : false;
    this.passTemplate.HostMiddleNameLock = this.passTemplate.HostMiddleNameLock
      ? true
      : false;
    this.passTemplate.HostLastNameLock = this.passTemplate.HostLastNameLock
      ? true
      : false;
    this.passTemplate.HostNameSuffixLock = this.passTemplate.HostNameSuffixLock
      ? true
      : false;
    this.passTemplate.HostEmailLock = this.passTemplate.HostEmailLock
      ? true
      : false;
    this.passTemplate.HostPhoneLock = this.passTemplate.HostPhoneLock
      ? true
      : false;
    this.passTemplate.HostCompanyLock = this.passTemplate.HostCompanyLock
      ? true
      : false;
    this.passTemplate.IncludeNonWorkingdaysLock = this.passTemplate
      .IncludeNonWorkingdaysLock
      ? true
      : false;
    this.passTemplate.PhotoPassLock = this.passTemplate.PhotoPassLock
      ? true
      : false;
    this.passTemplate.EstArrivalTimeLock = this.passTemplate.EstArrivalTimeLock
      ? true
      : false;
    this.passTemplate.EstDepartureTimeLock = this.passTemplate
      .EstDepartureTimeLock
      ? true
      : false;
    this.passTemplate.VisitReasonLock = this.passTemplate.VisitReasonLock
      ? true
      : false;
    this.passTemplate.DestinationBldgLock = this.passTemplate
      .DestinationBldgLock
      ? true
      : false;
    this.passTemplate.DestinationBlgFlrLock = this.passTemplate
      .DestinationBlgFlrLock
      ? true
      : false;
    this.passTemplate.DestinationRoomLock = this.passTemplate
      .DestinationRoomLock
      ? true
      : false;
    this.passTemplate.EntryTypeLock = this.passTemplate.EntryTypeLock
      ? true
      : false;
    this.passTemplate.ExpectedEntryPointLock = this.passTemplate
      .ExpectedEntryPointLock
      ? true
      : false;
    this.passTemplate.EntryPointGroupIDLock = this.passTemplate
      .EntryPointGroupIDLock
      ? true
      : false;
    this.passTemplate.ParkingLotIDLock = this.passTemplate.ParkingLotIDLock
      ? true
      : false;
    this.passTemplate.VisitorFirstNameLock = this.passTemplate
      .VisitorFirstNameLock
      ? true
      : false;
    this.passTemplate.VisitorMiddleNameLock = this.passTemplate
      .VisitorMiddleNameLock
      ? true
      : false;
    this.passTemplate.VisitorLastNameLock = this.passTemplate
      .VisitorLastNameLock
      ? true
      : false;
    this.passTemplate.VisitorNameSuffixLock = this.passTemplate
      .VisitorNameSuffixLock
      ? true
      : false;
    this.passTemplate.VisitorEmailLock = this.passTemplate.VisitorEmailLock
      ? true
      : false;
    this.passTemplate.VisitorPhoneLock = this.passTemplate.VisitorPhoneLock
      ? true
      : false;
    this.passTemplate.VisitorCompanyLock = this.passTemplate.VisitorCompanyLock
      ? true
      : false;
    this.passTemplate.VisitorCountryLock = this.passTemplate.VisitorCountryLock
      ? true
      : false;
    this.passTemplate.StartTimeLock = this.passTemplate.StartTimeLock
      ? true
      : false;
    this.passTemplate.EndTimeLock = this.passTemplate.EndTimeLock
      ? true
      : false;
    this.passTemplate.NotesPassLock = this.passTemplate.NotesPassLock
      ? true
      : false;
    this.passTemplate.NotesInternalLock = this.passTemplate.NotesInternalLock
      ? true
      : false;
  }

  setFromFieldValue() {
    this.createPassForm.value.visitorFirstName = this.passTemplate.VisitorFirstName.trim();
    this.createPassForm.value.visitorLastName = this.passTemplate.VisitorLastName.trim();
    // this.createPassForm.value.visitorMiddleName = this.passTemplate.VisitorMiddleName;
    this.createPassForm.value.visitorMiddleName = "";
    this.createPassForm.value.visitorNameSuffix = this.passTemplate.VisitorNameSuffix;
    this.createPassForm.value.visitorCompany = this.passTemplate.VisitorCompany;
    this.createPassForm.value.visitorEmail = this.passTemplate.VisitorEmail;
    this.createPassForm.value.visitorPhone = this.passTemplate.VisitorPhone;
    this.createPassForm.value.visitorCountry = this.passTemplate.VisitorCountry;
    this.createPassForm.value.estArrivalTime = this.passTemplate.EstArrivalTime;
    this.createPassForm.value.estDepartureTime = this.passTemplate.EstDepartureTime;
    this.createPassForm.value.visitReason = this.passTemplate.VisitReason;
    this.createPassForm.value.includeNonWorkingDays = this.passTemplate.IncludeNonWorkingdays;
    this.createPassForm.value.entryType = this.passTemplate.EntryType;
    this.createPassForm.value.notesInternal = this.passTemplate.NotesInternal;
    this.createPassForm.value.notesPass = this.passTemplate.NotesPass;
    this.createPassForm.value.destinationBlgFlr = this.passTemplate.DestinationBlgFlr;
    this.createPassForm.value.destinationBlgRoom = this.passTemplate.DestinationRoom;
    this.createPassForm.value.hostFirstName = this.passTemplate.HostFirstName;
    // this.createPassForm.value.hostMiddleName = this.passTemplate.HostMiddleName;
    this.createPassForm.value.hostMiddleName = "";
    this.createPassForm.value.hostLastName = this.passTemplate.HostLastName;
    this.createPassForm.value.hostNameSuffix = this.passTemplate.HostNameSuffix;
    this.createPassForm.value.hostPhone = this.passTemplate.HostPhone;
    this.createPassForm.value.hostCompany = this.passTemplate.HostCompany;
    this.createPassForm.value.PhotoPass = this.passTemplate.PhotoPass;
    this.createPassForm.value.startTime = this.passTemplate.StartDate;
    this.createPassForm.value.endTime = this.passTemplate.EndDate;
    this.createPassForm.value.entryPointGroupId = this.passTemplate.EntryPointGroupID;
    this.createPassForm.value.parkingLot = this.passTemplate.ParkingLotID;
  }

  HostIsSameAsAuthoriser() {
    const userDetails = this.userService.getUserDetails();
    if (!this.disabled) {
      this.passTemplate.HostFirstName = userDetails[0].PrefFirstName ? userDetails[0].PrefFirstName : this.userService.loggedInUserFirstName;
      this.passTemplate.HostLastName = userDetails[0].PrefLastName ? userDetails[0].PrefLastName : this.userService.loggedInUserLastName;
      this.passTemplate.HostNameSuffix = this.userService.loggedInSuffix;
      this.hostEmails = [];
      this.hostEmails.push({ email: userDetails[0].PrefEmail ? userDetails[0].PrefEmail : this.userService.loggedInEmail, valid: "valid" });
      this.passTemplate.HostPhone = userDetails[0].PrefPhone ? userDetails[0].PrefPhone : this.userService.loggedInPhone;
      this.passTemplate.HostCompany = this.userService.userOrganizations.filter(
        (f) => f.OrganizationID == this.userService.organizationID
      )[0].Name;
    } else {
      this.passTemplate.HostFirstName = "";
      this.passTemplate.HostMiddleName = "";
      this.passTemplate.HostLastName = "";
      this.passTemplate.HostNameSuffix = "";
      this.hostEmails = [];
      this.passTemplate.HostPhone = "";
      this.passTemplate.HostCompany = "";
    }
  }

  visitorCheckbox() {
    if (!this.passTemplate.VisitorFirstNameLock ||
      !this.passTemplate.VisitorLastNameLock || !this.passTemplate.VisitorNameSuffixLock || !this.passTemplate.VisitorEmailLock ||
      !this.passTemplate.VisitorPhoneLock || !this.passTemplate.VisitorCompanyLock) {
      return false;

    } else if (this.passTemplate.VisitorFirstNameLock ||
      this.passTemplate.VisitorLastNameLock || this.passTemplate.VisitorNameSuffixLock || this.passTemplate.VisitorEmailLock ||
      this.passTemplate.VisitorPhoneLock || this.passTemplate.VisitorCompanyLock) {
      return true;
    }
  }

  timeValidation() {
    let DepatureDate = moment(this.passTemplate.EndDate).format("YYYY-MM-DD");
    let DepatureTime = moment(this.passTemplate.EstDepartureTime).format("HH:mm:ss");
    let DepatureTimeDate = moment(DepatureDate + ' ' + DepatureTime);
    let ArrivalDate = moment(this.passTemplate.StartDate).format("YYYY-MM-DD");
    let ArrivalTime = moment(this.passTemplate.EstArrivalTime).format("HH:mm:ss");
    let ArrivalTimeDate = moment(ArrivalDate + ' ' + ArrivalTime);
    if (this.formatDate(ArrivalTimeDate) > this.formatDate(DepatureTimeDate)) {
      this.errorMessage = this.screenLabels["TimeErrorMsg"] ? this.screenLabels["TimeErrorMsg"] : "Departure time should be greater than Arrival Time";
      this.isInvalid = true;
      this.enableNext = true;
    }
    else {
      this.isInvalid = false;
      this.enableNext = false;
    }
  }

  OnArrivalTimeChange(event) {
    if (this.estArrivalTime.cleared) {
      event.newValue = "Invalid Date"
    }
    if (event.newValue == "Invalid Date" || this.passTemplate.EstArrivalTime == "Invalid Date") {
      this.passTemplate.EstArrivalTime = "";
      this.isInvalid = true;
      this.enableNext = true;
    } else {
      if (this.siteService.dataTimeFormat.angularTimeFormat == '12 hours') {
        this.minTime = moment(this.passTemplate.EstArrivalTime).format("hh:mm tt");
      } else {
        this.minTime = moment(this.passTemplate.EstArrivalTime).format("HH:mm");
      }
    }

    let DepatureDate = moment(this.passTemplate.EndDate).format("YYYY-MM-DD");
    let DepatureTime = moment(this.passTemplate.EstDepartureTime).format("HH:mm:ss");
    let DepatureTimeDate = moment(DepatureDate + ' ' + DepatureTime);
    let ArrivalDate = moment(this.passTemplate.StartDate).format("YYYY-MM-DD");
    let ArrivalTime = moment(this.passTemplate.EstArrivalTime).format("HH:mm:ss");
    let ArrivalTimeDate = moment(ArrivalDate + ' ' + ArrivalTime);
    if (this.formatDate(ArrivalTimeDate) > this.formatDate(DepatureTimeDate)) {
      this.errorMessage = this.screenLabels["TimeErrorMsg"] ? this.screenLabels["TimeErrorMsg"] : "Departure time should be greater than Arrival Time";
      this.isInvalid = true;
      this.enableNext = true;
    }
    if (this.estDepartureTime.cleared || this.estArrivalTime.cleared) {
      this.isInvalid = true;
      this.enableNext = true;

    }
    else {
      this.isInvalid = false;
      this.enableNext = false;
    }
  }

  OnDepartureTimeChange(event) {
    this.isInvalid = false;
    if (this.estDepartureTime.cleared) {
      event.newValue = "Invalid Date"
    }
    if (event.newValue == "Invalid Date" || this.passTemplate.EstDepartureTime == "Invalid Date") {
      this.passTemplate.EstDepartureTime = "";
      this.errorMessage = this.screenLabels["TimeInvalidMsg"] ? this.screenLabels["TimeInvalidMsg"] : "Departure time is Invalid";
      this.isInvalid = true;
      this.enableNext = true;
    } else {
      if (this.siteService.dataTimeFormat.angularTimeFormat == '12 hours') {
        this.minTime = moment(this.passTemplate.EstDepartureTime).format(
          "hh:mm tt"
        );
      } else {
        this.minTime = moment(this.passTemplate.EstDepartureTime).format(
          "HH:mm"
        );
      }
    }
    let DepatureDate = moment(this.passTemplate.EndDate).format("YYYY-MM-DD");
    let DepatureTime = moment(this.passTemplate.EstDepartureTime).format("HH:mm:ss");
    let DepatureTimeDate = moment(DepatureDate + ' ' + DepatureTime);
    let ArrivalDate = moment(this.passTemplate.StartDate).format("YYYY-MM-DD");
    let ArrivalTime = moment(this.passTemplate.EstArrivalTime).format("HH:mm:ss");
    let ArrivalTimeDate = moment(ArrivalDate + ' ' + ArrivalTime);
    if (this.formatDate(ArrivalTimeDate) > this.formatDate(DepatureTimeDate)) {
      this.errorMessage = this.screenLabels["TimeErrorMsg"] ? this.screenLabels["TimeErrorMsg"] : "Departure time should be greater than Arrival Time";
      this.isInvalid = true;
      this.enableNext = true;
    }
    else {
      this.isInvalid = false;
      this.enableNext = false;
    }
  }

  setArrivalTime() {
    const startTime = this.siteResponse.SystemSettings ? (this.siteResponse.SystemSettings.filter(x => x.Name === "DefaultStartTime")).map(x => x.Value) : null;
    if (this.passTemplate.EstArrivalTime) {
      return new Date(this.formatDate(this.passTemplate.EstArrivalTime))
    } else if (this.passTemplate.EstArrivalTime === null) {
      if (this.passTemplate.EstDepartureTime) {
        if (startTime[0] < (this.timezoneService.TimeToString(this.passTemplate.EstDepartureTime))) {
          return this.timezoneService.stringToTime(startTime.toString());
        }
        else return null;
      }
      else return this.timezoneService.stringToTime(startTime.toString());
    }
  }

  setDepatureTime() {
    const endTime = this.siteResponse.SystemSettings ? (this.siteResponse.SystemSettings.filter(x => x.Name === "DefaultEndTime")).map(x => x.Value) : null;
    if (this.passTemplate.EstDepartureTime) {
      return new Date(this.formatDate(this.passTemplate.EstDepartureTime))
    } else if (this.passTemplate.EstDepartureTime === null) {
      if (this.passTemplate.EstArrivalTime) {
        if (endTime[0] > (this.timezoneService.TimeToString(this.passTemplate.EstArrivalTime))) {
          return this.timezoneService.stringToTime(endTime.toString());
        }
        else return null;
      }
      else
        return this.timezoneService.stringToTime(endTime.toString());
    }
  }

  OnIncludeNonWorkingDays() {
    if (!this.passTemplate.IncludeNonWorkingdays) {
      this.checkDateValidation();
    }
    if (this.checkedDates) {
      if (this.passTemplate.IncludeNonWorkingdays) {
        this.checkedDates = false;
      }
    }
    if (this.checkDatesError) {
      if (this.passTemplate.IncludeNonWorkingdays) {
        this.enableNext = false;
        this.checkDatesError = false;
      }
      else {
        this.enableNext = true;
      }
    }
    if (this.entryPoinIndicator) {
      if (this.passTemplate.IncludeNonWorkingdays) {
        this.enableNext = false;
        this.entryPoinIndicator = false;
      }
      else {
        this.enableNext = true;
      }
    }
  }

  checkDateValidation() {
    this.checkedDates = false;
    let formattedStart = moment(this.passTemplate.StartDate).format("YYYY-MM-DD");
    let formattedEnd = moment(this.passTemplate.EndDate).format("YYYY-MM-DD");
    let start = Date.parse(formattedStart);
    let end = Date.parse(formattedEnd);

    this.holidays.forEach(element => {
      if ((Date.parse(element) <= end) && (Date.parse(element) >= start)) {
        this.checkedDates = true;
        return this.checkedDates;
      }
    })
    return this.checkedDates;
  }

  checkDatesOnNext() {
    this.checkDatesNext = false;
    let formattedStart = moment(this.passTemplate.StartDate).format("YYYY-MM-DD");
    let formattedEnd = moment(this.passTemplate.EndDate).format("YYYY-MM-DD");
    let start = Date.parse(formattedStart);
    let end = Date.parse(formattedEnd);
    //getting dates between start-end dates
    let daylist = this.getDaysArray(formattedStart, formattedEnd);
    //let array = daylist.map((v) => v.toISOString().slice(0, 10)).join(",");

    daylist.forEach(element => {
      let dayRange = moment(element).format("YYYY-MM-DD");
      this.dayRangeArray.push(dayRange);
    });
    //filtering weekdays from dayrange
    this.workingDays = this.dayRangeArray.filter(val => !this.holidays.includes(val));

    this.holidays.forEach(first => {
      if ((Date.parse(first) == start)) {
        this.startElement = true;
      }
    });
    this.holidays.forEach(second => {
      if ((Date.parse(second) == end)) {
        this.endElement = true;
      }
    });
    if (this.startElement && this.endElement && this.workingDays.length == 0) {
      this.checkDatesNext = true;
      return this.checkDatesNext;
    }
    return this.checkDatesNext;
  }

  getDaysArray(startingDate, endingDate) {
    for (var arr = [], dt = new Date(startingDate); dt <= new Date(endingDate); dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  }

  // for the upload of data in the temp API 

  private tempPassesCheck(apiBody, visitorData) {
    if (visitorData.length > 0) {
      const passes = [];
      visitorData.forEach((visitor) => {
        const tempPass = { ...apiBody };
        tempPass.EntryType = !visitor.EntryType
          ? this.apiBody.EntryType
          : visitor.EntryType;
        tempPass.VisitorCompany = !visitor.VisitorCompany
          ? null
          : visitor.VisitorCompany;
        tempPass.VisitorEmail = !visitor.VisitorEmail
          ? null
          : visitor.VisitorEmail;
        tempPass.VisitorFirstName = !visitor.VisitorFirstName
          ? null
          : visitor.VisitorFirstName;
        tempPass.VisitorLastName = !visitor.VisitorLastName
          ? null
          : visitor.VisitorLastName;
        // tempPass.VisitorMiddleName = !visitor.VisitorMiddleName
        //   ? null
        //   : visitor.VisitorMiddleName;
        tempPass.VisitorMiddleName = "";
        tempPass.VisitorNameSuffix = !visitor.VisitorNameSuffix
          ? null
          : visitor.VisitorNameSuffix;
        tempPass.VisitorPhone = !visitor.VisitorPhone
          ? null
          : visitor.VisitorPhone.toString();
        tempPass.VisitorCountry = !visitor.VisitorCountry
          ? null
          : visitor.VisitorCountry;

        if (visitor.IgnoreDuplicate === true) tempPass.AllowDup = true;

        passes.push(tempPass);
      });

      const formattedData = this.formatBulkData(passes);
      this.rowData = [];
      this.passService
        .createTempPassCheck(formattedData).subscribe((res: any) => {
          this.rowData = res.body
        });
    }
  }

  isValidationDisabled(): boolean {
    if (this.rowData.length > 0)
      return this.rowData.some(row => !row.VisitorFirstName?.trim() || !row.VisitorLastName?.trim());
    else
      return true;
  }

  onCellEditingStopped(event) {
    this.passService.updateTempPassCheck(event.data).subscribe((res: any) => {
      this.isValidationDisabled();
    })
  }

}