export class ApprovalConfig {
    public CreatedByName: string;
    public ModifiedByName: string;
  
    constructor(
      public SiteID: number,
      public Description: string,
      public ApprovalTypeID: number,
      public ApprovalGroupID: number,
      public Position: number,
      public TableFunctionID: number,
      public TableFunctionReturn: string,
      public ApprovalEmailTemplateID: number,
      public CancelationEmailTemplateID: number,
      public requestModificationAllowed: number,
      public requestAutoApproval: number,
      public Active: number,
    ) {
        this.Active = 1;
    }
  }