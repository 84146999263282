import { Component, OnInit, Inject, ViewChild, ElementRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatExpansionPanel, MatAutocomplete, MatAutocompleteTrigger, MatChipInputEvent, MatAutocompleteSelectedEvent, MatSelect, MatSelectionList, MatSelectionListChange } from "@angular/material";
import { EntryPoint } from "../../../models/entry-point.model";
import { UserService } from "../../../services/user.service";
import { NotificationService } from "../../../services/notification.service";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { RoleMapService } from "../../../services/role-map.service";
import { HttpErrorResponse } from "@angular/common/http";
import { OrganizationService } from "../../../services/organization.service";
import { DialogService } from "../../../services/dialog.service";
import { GridApi, ValueSetterParams } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map } from 'rxjs/operators';
import { SiteService } from "src/app/services/site.service";
import { Integration } from "src/app/models/integration.model";
import { SearchService } from "src/app/services/search.service";
import { SelectionModel } from "@angular/cdk/collections";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-view-user-modal",
  templateUrl: "./view-user-modal.component.html",
  styleUrls: ["./view-user-modal.component.css"],
})
export class ViewUserModalComponent implements OnInit {
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  @ViewChild("organizationDropDown") organizationDropDown: any;
  @ViewChild("isAdminDropDown") isAdminDropDown: any;

  protected step = 0;
  protected entryPoints: Array<EntryPoint>;
  protected rolesLoaded: boolean = false;
  protected organizationsLoaded: boolean = false;
  protected disableNewOrganizationAdd: boolean = true;
  protected isAdmin: boolean = false;
  protected plpMgmtBoolean : boolean = false;
  protected phyAccessMgmtBoolean : boolean = false;

  @ViewChild("trigger") autoCompleteTrigger: MatAutocompleteTrigger;


  public chipSelectedRole: Array<{
    RoleID: number;
    RoleName: string;
    Active: number;
    UserRoleID: number;
  }> = [];
  public filteredRoles: Observable<String[]>;


  //
  // Set this to false to ensure engineers are from allEngineers list only.
  // Set this to true to also allow 'free text' engineers.
  //
  private allowFreeTextAddUser = false;

  public roleControl = new FormControl([]);
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild("roleInput") userInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete; Active: number;

  protected columnDefs = [];
  protected frameworkComponents;
  protected rowData = [];
  protected gridApi: GridApi;

  protected roleItems: Array<{
    RoleID: number;
    RoleName: string;
    Active: number;
    UserRoleID: number;
  }> = [];
  protected roleBasket: Array<{
    RoleID: number;
    RoleName: string;
    Active: number;
    UserRoleID: number;
  }> = [];
  protected originalRoleBasket: Array<{
    RoleID: number;
    RoleName: string;
    Active: number;
    UserRoleID: number;
  }> = [];

  protected organizationItems: Array<{
    OrganizationID: number;
    Name: string;
    Active: number;
  }> = [];

  protected organizationBasket: Array<{
    UserOrganizationID: number;
    OrganizationID: number;
    Name: string;
    Active: number;
    IsAdmin: number
  }> = [];

  protected originalOrganizationBasket: Array<{
    UserOrganizationID: number;
    OrganizationID: number;
    Name: string;
    Active: number;
    IsAdmin: number;
  }> = [];


  protected filteredOrganization = [];

  protected AvailableSiteList: Array<String> = [];
  protected associatedSitesLoaded: boolean = false;
  protected RequestedSiteList: Array<String> = [];
  protected RequestedSitesLoaded: boolean = false;

  protected integrationList: Array<Integration> = [];
  protected pplMgmtList: Array<Integration> = [];
  protected physicalAccessMgmtList: Array<Integration> = [];

  protected arrays: Array<{
    ApplicationType: string
    DisplayValue: string
    ExtApplicationName: string
    ExtApplicationTypeID: number
    IntegrationID: number
    IntegrationName: string
    isLinked?: boolean
    isClicked?: boolean
  }> = [];
  protected arrayPhysical: any[] = [];
  protected arrayPeople:any[] = [];
  protected selectedValue;
  protected valueSelected: boolean = false;
  protected errorMessage: string = '';
  @ViewChild(MatSelectionList, { static: true })
  private selectionList: MatSelectionList;
  protected integrationLoaded:boolean = false;
  protected userPhysicalMngmnt:string = "UserPhysicalAccessMgmt";
  protected userPepleMangmnt:string = "UserPeopleMgmt";
  protected loadGrid:boolean = false;
  public showGrid= false;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  public defaultColDef : object;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      Active: number;
      CreatedBy: number;
      CreatedDate: string;
      Email: string;
      EmployeeRef: string;
      FirstName: string;
      LastName: string;
      MiddleName: string;
      ModifiedBy: number;
      ModifiedDate: string;
      OKTAID: string;
      Phone: string;
      PrefFirstName: string;
      PrefMiddleName: string;
      PrefLastName: string;
      PrefEmail: string;
      PrefPhone: string;
      Status: string;
      Suffix: string;
      UserID: number;
    },
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<ViewUserModalComponent>,
    protected roleMapService: RoleMapService,
    private notificationService: NotificationService,
    protected organizationService: OrganizationService,
    private userService: UserService,
    private dialogService: DialogService,
    protected siteService: SiteService,
    protected searchService: SearchService,
    protected messageService: MessageService,
  ) {
    this.defaultColDef = {
      resizable: true,
  }
   }

  ngOnInit(): void {
    this.userService.getIntegrations().subscribe((response: any) => {
       //this.integrationLoaded = true;

      response.body.Integrations.forEach(element => {
        this.arrays.push(element);
        this.integrationLoaded = true;
      })
      
    this.arrays.forEach(ele => {
      if (ele.DisplayValue === "UserPeopleMgmt") {
        this.userService.getUserAssociationAPI(this.data.UserID, this.siteService.selectedSiteID).then(() => {
          const reqData = this.userService.userByID[0];
          reqData.UserInegrations.forEach(data => {
            if (ele.IntegrationID === data.IntegrationID) {
              ele.isLinked = true;
            }
          })
        })   
      }
      if (ele.DisplayValue === "UserPhysicalAccessMgmt") {
        this.userService.getUserAssociationAPI(this.data.UserID, this.siteService.selectedSiteID).then(() => {
          const reqData = this.userService.userByID[0];
          //reqData.userIn
          reqData.UserInegrations.forEach(data => {
            if (ele.IntegrationID === data.IntegrationID) {
              ele.isLinked = true;
            }
          })
        })
      }
    })
    });

    // Load Roles
    this.loadRoles();
    // Load Organization Data
    this.loadOrganization();
    // initialize page labels
    // initialize page labels

    //labels by message service
    if (localStorage.getItem('ViewUserModalLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('ViewUserModalLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "View User Modal",
        "User"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('ViewUserModalLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    this.searchService
      .smartSearchMultiSiteAPI(null, "Integration")
      .then(() => {
        this.integrationList = this.searchService.searchRecords;
        //load ppl Mgmt List
        // this.pplMgmtList = this.integrationList.filter((f) => {
        //    f.ExtApplicationTypeID == 2;
        //  });
        this.integrationList.forEach(element => {
          if (element.ExtApplicationTypeID == 1)
            this.physicalAccessMgmtList.push(element)
          else if (element.ExtApplicationTypeID == 2)
            this.pplMgmtList.push(element);
        });
        //  if(this.physicalAccessMgmtList.length == 1){
        //   this.selectedPhysicalAccess = this.physicalAccessMgmtList[0].IntegrationID;
        //  }
        //this.selectedPhysicalAccess = this.physicalAccessMgmtList[0].IntegrationID;
        //load physical access Mgmt List
        // this.physicalAccessMgmtList = this.integrationList.filter((f) => {
        //   f.ExtApplicationTypeID == 1;
        // });     
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    this.filteredRoles = this.roleControl.valueChanges.pipe(
      map((roleName) => this.filterOnValueChange(roleName))
    );
    this.roleControl.setValue(null);
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }



  private loadRoles() {
    // Load Role Data
    // need to call API
    this.roleMapService
      .getRoleMappingsAPI()
      .then(() => this.prepareRoleList())
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );
  }

  private loadOrganization() {
    // call API
    this.organizationService
      .getOrganizationAPI()
      .then(() => {
        // load the organization basket
        this.prepareOrganizationList();
        this.organizationsLoaded = true;
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }

  private prepareOrganizationList() {

    this.userService
      .getUserAssociationAPI(this.data.UserID, this.siteService.selectedSiteID)
      .then(() => {
        // load the organization basket
        const organizationBasket = this.userService
          .getUserDetails()[0]
          .UserOrganization.map((o) => ({
            UserOrganizationID: o.UserOrganizationID,
            Active: o.UserOrganizationActive,
            OrganizationID: o.OrganizationID,
            Name: this.organizationService
              .getOrganizations()
              .filter((f) => f.OrganizationID == o.OrganizationID)[0].Name,
            IsAdmin: o.IsAdmin ? (o.IsAdmin == 1 ? 1 : 0) : 0
          }))
          .filter((f) => f.Active);
        this.organizationBasket = organizationBasket;

        const organizationItems = [];
        for (let j = 0; j < this.organizationItems.length; j++) {
          let matchFound = false;
          for (let i = 0; i < this.organizationBasket.length; i++) {
            if (
              this.organizationItems[j].OrganizationID ==
              this.organizationBasket[i].OrganizationID
            ) {
              matchFound = true;
            }
          }
          if (!matchFound) organizationItems.push(this.organizationItems[j]);
        }
        this.organizationItems = organizationItems;

        //this.originalOrganizationBasket = [...this.organizationBasket];
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    this.organizationItems = this.organizationService
      .getOrganizations()
      .map((o) => ({
        OrganizationID: o.OrganizationID,
        Name: o.Name,
        Active: o.Active
      }))
      .filter((o) => o.Active === 1);
  }


  private prepareRoleList() {
    const response = this.roleMapService
      .getRoleMappingResponse()
      .filter((rm) => rm.RoleActive)
      .map((rm) => ({
        RoleID: rm.RoleID,
        RoleName: rm.RoleName,
        Active: rm.RoleActive,
        UserRoleID: 0
      }))
      .filter((rm) => rm.Active == 1);

    // removing duplicates
    this.roleItems = Array.from(
      new Set(response.map((i) => JSON.stringify(i)))
    ).map((i) => JSON.parse(i));
    this.roleItems.forEach(element => {
      element.Active = 0
    });
    this.userService
      .getUserAssociationAPI(this.data.UserID, this.siteService.selectedSiteID)
      .then(() => {

        const userSites = this.userService.getUserDetails()[0].UserSites;
        if (userSites.length > 0) {
          userSites.forEach((element) => {
            this.AvailableSiteList.push(element.Name);
          });
          this.associatedSitesLoaded = true;
        }

        const requestedSites = this.userService.getUserDetails()[0].RequestedSites;
        if (requestedSites && requestedSites.length > 0) {
          requestedSites.forEach((element) => {
            this.RequestedSiteList.push(element.Name);
          });
          this.RequestedSitesLoaded = true;
        }

        // load the role basket
       
        let roleBasket = this.userService.getUserDetails()[0]
          .UserRole.map((ur) => ({
            UserRoleID: ur.UserRoleID,
            RoleID: ur.RoleID,
            Active: ur.UserRoleActive,
            RoleName: this.roleMapService.getRoleMappingByID(ur.RoleID)[0]
              .RoleName,
          }));
        roleBasket = roleBasket.filter((rb) => rb.Active === 1);
        roleBasket.sort((a, b) =>
          a.RoleID > b.RoleID ? 1 : b.RoleID > a.RoleID ? -1 : 0
        );
        this.roleBasket = roleBasket;


        this.chipSelectedRole = this.roleBasket;
        this.chipSelectedRole.forEach(element => {
          element.Active = 1;
        });


        this.chipSelectedRole.forEach(element => {
          var index = -1
          const length = this.roleItems.filter((f) => f.RoleID == element.RoleID).length
          if (length > 0) index = this.roleItems.indexOf(this.roleItems.filter((f) => f.RoleID == element.RoleID)[0])
          if (index >= 0) {
            this.roleItems.splice(index, 1)
          }
        });
        this.rolesLoaded = true;
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }

  private arrayDifference(original: Array<any>, newArray: Array<any>) {
    const difference = original.filter((x) => !newArray.includes(x));
    return difference;
  }

  // Code for Drag and Drop
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }

  public addRole(event: MatChipInputEvent): void {
    if (!this.allowFreeTextAddUser) {
      // only allowed to select from the filtered autocomplete list
      return;
    }

    //
    // Only add when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    //
    if (this.matAutocomplete.isOpen) {
      return;
    }

    // Add our engineer
    const value = event.value;
    if ((value || "").trim()) {
      this.selectRoleByName(value.trim());
    }

    this.resetInputs();
  }

  public removeRole(role): void {
    const index = this.chipSelectedRole.indexOf(role);
    const templateIndex = this.roleItems.indexOf(role)
    if (index >= 0) {
      this.chipSelectedRole.splice(index, 1);
      if (templateIndex < 0) this.roleItems.push(role);
      this.resetInputs();
    }
  }

  public roleSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectRoleByName(event.option.value);
    this.resetInputs();
  }

  private resetInputs() {
    // clear input element
    this.userInput.nativeElement.value = "";
    // clear control value and trigger engineerControl.valueChanges event
    this.roleControl.setValue(null);
  }

  //
  // Compute a new autocomplete list each time control value changes
  //
  private filterOnValueChange(roleName: string): String[] {
    let result: String[] = [];
    //
    // Remove the engineers we have already selected from all engineers to
    // get a starting point for the autocomplete list.    //
    let allRolesLessSelected = this.roleItems.filter(
      (user) => this.chipSelectedRole.indexOf(user) < 0
    );
    if (roleName) {
      result = this.filterRole(allRolesLessSelected, roleName);
    } else {
      result = allRolesLessSelected.map((role) => role.RoleName);
    }
    return result;
  }

  private filterRole(
    roleList: Array<{
      RoleID: number;
      RoleName: string;
      Active: number;
    }> = [],
    roleName: String
  ): String[] {
    let filteredRolesList: Array<{
      RoleID: number;
      RoleName: string;
      Active: number;
    }> = ([] = []);
    const filterValue = roleName.toLowerCase();
    let rolesMatchingRoleName = roleList.filter(
      (role) => role.RoleName.toLowerCase().indexOf(filterValue) === 0
    );
    if (rolesMatchingRoleName.length || this.allowFreeTextAddUser) {
      //
      // either the engineer name matched some autocomplete options
      // or the name didn't match but we're allowing
      // non-autocomplete engineer names to be entered
      //
      filteredRolesList = rolesMatchingRoleName;
    } else {
      //
      // the engineer name didn't match the autocomplete list
      // and we're only allowing engineers to be selected from the list
      // so we show the whjole list
      //
      filteredRolesList = roleList;
    }
    //
    // Convert filtered list of engineer objects to list of engineer
    // name strings and return it
    //
    return filteredRolesList.map((role) => role.RoleName);
  }

  private selectRoleByName(roleName) {
    let foundUser = this.roleItems.filter((role) => role.RoleName == roleName);
    if (foundUser.length) {
      //
      // We found the engineer name in the allEngineers list
      //
      this.chipSelectedRole.push(foundUser[0]);
    }
  }

  protected selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    trigger.openPanel();
  }

  onAutocompleteFocus() {
    this.autoCompleteTrigger._onChange("");
    this.autoCompleteTrigger.openPanel();
  }

  private selectRoleByID(roleID) {
    let foundUser = this.roleItems.filter((user) => user.RoleID == roleID);
    if (foundUser.length) {
      this.chipSelectedRole.push(foundUser[0]);
    }
  }

  selection = new SelectionModel(true);
  list = Array.from({ length: 100 }).map((_, i) => `Item #${i}`);

  onSystemIntegratedChange(selection: MatSelectionListChange) {
    this.loadGrid = false;
    this.rowData = [];
    this.valueSelected = true;
    this.plpMgmtBoolean = false;
    this.phyAccessMgmtBoolean = false;
    this.selectedValue = selection.option.value;

    this.arrays.forEach(ele => {
      ele.isClicked = false;
      if(ele.IntegrationID == this.selectedValue.IntegrationID) {
        ele.isClicked = true;
      }
    })

    if (this.selectedValue.DisplayValue === this.userPepleMangmnt) {
      this.plpMgmtBoolean = true;
    } else if (this.selectedValue.DisplayValue === this.userPhysicalMngmnt) {
      this.phyAccessMgmtBoolean = true;
    }
    this.userService.getUserIntegrationInfo(this.userService.userByID[0].UserID, this.selectedValue).subscribe((res: any) => {
      this.rowData =  res.body.data.filter(e => e.Active === 1);
      this.setColumnDefs();
      this.loadGrid = true;
    })
    selection.option.selected
      ? this.selection.select(selection.option.value)
      : this.selection.deselect(selection.option.value);
  }


  setColumnDefs() {
    this.columnDefs = [
      {
        headerName:this.screenLabels["FirstName"]? this.screenLabels["FirstName"]: "First Name",
        field: "FirstName"
      },
      {
        headerName: this.screenLabels["LastName"]? this.screenLabels["LastName"]: "Last Name",
        field: "LastName"
      },
      {
        headerName: this.screenLabels["AppID"]? this.screenLabels["AppID"]: "App ID",
        field: "ExternalAppID"
      }
    ];
  }

}