import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatExpansionPanel,
} from "@angular/material";
import { FormGroup, FormControl, Validators, NgForm } from "@angular/forms";
import { EntryPoint } from "../../../models/entry-point.model";
import { UserService } from "../../../services/user.service";
import { EntryPointStation } from "../../../models/entry-point-station.model";
import { EntryPointService } from "../../../services/entry-point.service";
import { ParkingLot } from "../../../models/parking-lot.model";
import { ParkingLotGroup } from "../../../models/parking-lot-group.model";
import { ParkingLotService } from "../../../services/parking-lot.service";
import { ParkingLotGroupService } from "../../../services/parking-lot-group.service";
import { SiteService } from "src/app/services/site.service";
import { RequiredFieldsService } from 'src/app/services/required-fields.service';
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-create-parking-group",
  templateUrl: "./create-parking-group-modal.component.html",
  styleUrls: ["./create-parking-group-modal.component.css"],
})
export class CreateParkingGroupModalComponent implements OnInit {
  protected step = 0;
  protected parkingLotGroups: ParkingLotGroup;
  //nameFormControl = new FormControl("", [Validators.required]);
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  @ViewChild("createForm") createForm: NgForm;
  protected appLanguage;
  public screenLabels: Array<string> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ParkingLotGroup,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<CreateParkingGroupModalComponent>,
    protected entryPointService: EntryPointService,
    protected parkingLotGroupService: ParkingLotGroupService,
    private userService: UserService,
    private siteService: SiteService,
    protected requiredFieldsService: RequiredFieldsService,
    protected messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.parkingLotGroups = new ParkingLotGroup(
      null,
      null,
      null,
      null,
      false,
      null,
      null,
      null,
      null,
      this.userService.userID,
      null
    );

    //labels by message service
    if (localStorage.getItem('CreateParkingLotGroupLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('CreateParkingLotGroupLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Create Parking Lot Group Modal",
        "ParkingLotGroup"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('CreateParkingLotGroupLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  ngAfterViewChecked() {
    // setting up the required fields
    this.requiredFieldsService.setRequiredFields(
     'Add Parking Lot Group',
      this.createForm
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaveChanges() {
    const name = this.parkingLotGroups.Name.trim() === this.parkingLotGroups.Name ? this.parkingLotGroups.Name : this.parkingLotGroups.Name.trim();
    this.parkingLotGroups.Name = name ;
    const parkingLotId = this.parkingLotGroupService.createParkingLotGroup(
      this.parkingLotGroups
    );
    this.onNoClick();
  }

  isNameEmpty(): boolean {
    return !this.parkingLotGroups.Name || !this.parkingLotGroups.Name.trim();
  }

  nameFormControl = new FormControl("", [
    Validators.required,  // Required validation
    Validators.pattern(/^[a-zA-Z\s-][a-zA-Z\s0-9-]*$/),  // Updated pattern validation
    this.specialCharacterValidator(),
  ]);

  // Custom validator function
  specialCharacterValidator() {
    return (control: FormControl) => {
      const value = control.value;
      if (!value) {
        return null; // No validation for empty values
      }

      // Check for spaces or hyphens followed by spaces
      if (/^\s/.test(value)) {
        return { specialCharactersNotAllowed: true };
      }
  
      const isValid = /^[a-zA-Z\s0-9]*(-[a-zA-Z\s0-9]+)*$/.test(value);
  
      if (isValid && !/^-/.test(value)) {
      return null;
    };
  return { specialCharactersNotAllowed: true };
    }
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  // getNameRequiredErrorMessage() {
  //   return this.nameFormControl.hasError("required")
  //     ? "You must enter a value"
  //     : " ";
  // }
  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }
}
