import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ModuleWithComponentFactories,
  ElementRef,
} from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, Validators, NgForm } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { EntryPoint } from "src/app/models/entry-point.model";
import { EntryPointGroup } from "src/app/models/entry-point-group.model";
import { Building } from "src/app/models/building.model";
import { ViewSelectorService } from "src/app/services/view-selector.service";
import { PassService } from "src/app/services/pass.service";
import { BuildingService } from "src/app/services/building.service";
import { UserService } from "src/app/services/user.service";
import { NotificationService } from "src/app/services/notification.service";
import { DatePipe } from "@angular/common";
import { EntryPointService } from "src/app/services/entry-point.service";
import { DialogService } from "src/app/services/dialog.service";
import { EntryPointGroupService } from "src/app/services/entry-point-group.service";
import { OrganizationService } from "src/app/services/organization.service";
import { ParkingLotService } from "src/app/services/parking-lot.service";
import { SearchService } from "src/app/services/search.service";
import { RequiredFieldsService } from "src/app/services/required-fields.service";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import {
  MatRadioChange,
  MatSelectChange,
  DateAdapter,
  MAT_DATE_FORMATS,
  MatAutocompleteSelectedEvent,
} from "@angular/material";
import { TemplateCategory } from "src/app/models/template-category,model";
import { PassTemplate } from "src/app/models/pass-template.model";
import { TemplateCategoryService } from "src/app/services/template-category-service";
import { PassTemplateService } from "src/app/services/pass-template-service";
import { start } from "repl";
import { StartdateConfigModalComponent } from "../startdate-config/startdate-config.modal.component";
import { SiteService } from "src/app/services/site.service";
import { UtilityService } from "src/app/services/utility.service";
import { MessageService } from "src/app/services/message.service";
import {
  MatDatePickerAdapter,
  CUSTOM_DATE_FORMATS,
} from "src/app/common/mat-date-picker-formatter/mat-date-picker-formatter";
import moment from "moment";

export interface DialogData { }

export interface Reason {
  value: string;
  viewValue: string;
}
export interface Point {
  name: string;
}
export interface PointGrps {
  name: string;
}
// export interface Building {
//   name: string;
// }

@Component({
  selector: "app-edit-template-modal",
  templateUrl: "./edit-template-modal.component.html",
  styleUrls: ["./edit-template-modal.component.css"],
  providers: [
    { provide: DateAdapter, useClass: MatDatePickerAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export class UpdateTemplateComponent implements OnInit {
  @ViewChild("updatePassRef") updatePassForm: NgForm;
  @ViewChild("expirationDate") expirationDate: ElementRef;

  protected DateMethod: number = 0;
  protected StartWeek: number = 0;
  protected StartDay: number = 0;
  protected weekList: Array<{
    PickListID: number;
    TableName: string;
    FieldName: string;
    DisplayValue: string;
    Active: boolean;
  }> = [];

  protected dateMethod: Array<{
    PickListID: number;
    TableName: string;
    FieldName: string;
    DisplayValue: string;
    Active: boolean;
  }> = [];
  private apiBody: any = {};
  protected entryPoints: Array<EntryPoint> = [];
  protected entryPointGroups: Array<EntryPointGroup> = [];
  protected templateCategories: Array<TemplateCategory> = [];
  protected reasonOfVisit: Array<{
    PickListID: number;
    TableName: string;
    FieldName: string;
    DisplayValue: string;
    Active: boolean;
  }> = [];
  protected isReasonOfVisitLoading = true;
  protected isBuildingLoading = true;
  protected isEntryPointLoading = true;
  protected isParkingDisabled = true;
  protected isExpirationDateDisabled = true;
  protected passTemplate: PassTemplate = new PassTemplate();
  protected assignedParking: any[] = [];
  // protected assignedParking: Array<{
  //   ParkingLotName: string;
  //   ParkingLotID: number;
  // }> = [];
  public parkingLotName: string;

  protected buildings: Array<Building>;
  protected dayRange: Array<string> = [];
  protected StartDate: string = "Not Specified";
  protected DateMethodName: string = "";
  protected minTime: string = "";
  public validEntryPointGroup: boolean = false;

  protected enableNext: boolean = false;
  protected floors: any[] =[];
  protected enableLock: boolean = false;
  protected phoneIsUSA:boolean = false;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  protected countryList: any;
  visitReasonControl = new FormControl();
  filteredVisitReasons: Observable<any[]>;
  filteredBuilding: Observable<any[]>;
  buildingControl = new FormControl();
  private chosenBuildingId;
  protected Getfloors: any[] = [];
  templateCategoryControl = new FormControl();
  filteredTemplateCategory: Observable<any[]>;
  filteredFloors: Observable<any[]>;
  floorControl = new FormControl();
  parkingLotFormControl = new FormControl();
  filteredParking: Observable<string[]>;
  filteredEntryPointGroup: Observable<any[]>;
  filteredExpectedEntryPointGroup: Observable<any[]>;
  expectedEntryPointFormControl = new FormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PassTemplate,
    public dialogRef: MatDialogRef<UpdateTemplateComponent>,
    private _snackBar: MatSnackBar,
    service: ViewSelectorService,
    protected passService: PassService,
    protected buildingService: BuildingService,
    protected userService: UserService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    protected entryPointService: EntryPointService,
    protected dialogService: DialogService,
    private entryPointGroupService: EntryPointGroupService,
    private organizationService: OrganizationService,
    private parkingLotService: ParkingLotService,
    protected searchService: SearchService,
    protected requiredFieldsService: RequiredFieldsService,
    protected templateCategoryService: TemplateCategoryService,
    protected passTemplateService: PassTemplateService,
    protected siteService: SiteService,
    private _utilService: UtilityService,
    protected messageService: MessageService,
  ) {
    document.addEventListener(
      "keydown",
      (e) => {
        if ((e.target as any).nodeName === "MAT-EXPANSION-PANEL-HEADER") {
          e.stopImmediatePropagation();
        }
        if ((e.target as any).nodeName === "MAT-DIALOG-CONTAINER") {
          if (this.step != 2) {
            this.nextStep();
          } else {
            return;
          }
        }
      },
      true
    );
    this.isParkingDisabled = this.passTemplate.EntryType == 1 ? false : true; // enable ParkingDrodown if EntryType is Drive on

    //Api call to get ParkingLotDropdown

    this.organizationService
      .getOrganizationByIDAPI(this.userService.organizationID)
      .then(() => {
        const details = this.organizationService.getOrganizationByIDResponse()[0];
        details.OrganizationParkingLimitArray.map((p) => {
          if (p.ParkingLotArray) {
            p.ParkingLotArray.map((array) => {
              if (array) {
                this.assignedParking.push(array);
                this.filteredParking = this.parkingLotFormControl.valueChanges.pipe(
                  startWith(""),
                  map(value => typeof value === 'string' ? value : value?.ParkingLotName),
                  map(ParkingLotName => ParkingLotName ? this._filteredParking(ParkingLotName) : this.assignedParking.slice())
                );
              }
            });
          }
        });
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }
  durationInSeconds = 3;
  step = 0;
  disabled = false;
  private fieldArray: Array<any> = [];
  private newAttribute: any = {};

  setStep(index: number) {
    this.step = index;
  }

  //template category typeahead
  private _filteredParking(value: string) {
    return this.assignedParking.filter(option => option.ParkingLotName.toLowerCase().includes(value.toLowerCase()));
  }

  displayFn7(selectedoption: number) {
    if(selectedoption) {
    return this.assignedParking.find(x => x.ParkingLotID === selectedoption).ParkingLotName;
    }
  }

  nextStep() {
    this.step++;
  }

  isNameEmpty(): boolean {
    return !this.passTemplate.TemplateName || !this.passTemplate.TemplateName.trim();
  }

  isValidEmail(email: string): boolean {
    const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/
    return emailPattern.test(email);
  }

  nameFormControl = new FormControl("", [
    Validators.required,  // Required validation
    Validators.pattern(/^[a-zA-Z\s0-9\-!@#$%^&*()_+={}[\]:;<>,.?~'"|/\\`]*$/),
    this.specialCharacterValidator(),
  ]);

  // Custom validator function
  specialCharacterValidator() {
    return (control: FormControl) => {
      const value = control.value;
      if (!value) {
        return null; // No validation for empty values
      }

      // Check for spaces or hyphens followed by spaces
      if (/^\s/.test(value)) {
        return { specialCharactersNotAllowed: true };
      }
  
      const isValid = /^[a-zA-Z\s0-9\-!@#$%^&*()_+={}[\]:;<>,.?~'"|/\\`]*$/.test(value);
  
      if (isValid && !/^-/.test(value)) {
      return null;
    };
  return { specialCharactersNotAllowed: true };
    }
  }

  prevStep() {
    this.step--;
  }

  nextSteptwo() {
    this.email.markAsTouched();
    if (!this.email.invalid) {
      this.step++;
    }
  }
  email = new FormControl("", [Validators.required, Validators.email]);
  entryPointGroupFormControl = new FormControl("", Validators.required);

  //'Reason for visit' dropdown
  reasons: Reason[] = [
    { value: "vendor", viewValue: "Vendor" },
    { value: "business meeting", viewValue: "Business Meeting" },
    { value: "screening", viewValue: "Screening" },
    { value: "consultant", viewValue: "Consultant" },
    { value: "interview", viewValue: "Interview" },
    { value: "personal", viewValue: "Personal" },
  ];

  //Building autocomplete
  myControl = new FormControl();

  filteredOptions: Observable<Building[]>;
  //Entry type autocomplete
  entryControl = new FormControl();
  entryOptions: Point[] = [
    { name: "1658" },
    { name: "153" },
    { name: "8080" },
    { name: "16616" },
  ];
  filteredPoints: Observable<Point[]>;

  entrygrpControl = new FormControl();
  entrygrpOptions: PointGrps[] = [
    { name: "A" },
    { name: "B" },
    { name: "C" },
    { name: "D" },
  ];
  filteredPointGrps: Observable<PointGrps[]>;

  ngOnInit() {
    this.passTemplate.EndTimeDuration = null;
    if (localStorage.getItem("isPhoneUSA") === "true") {
      this.phoneIsUSA = true;
    }
    else if (localStorage.getItem("isPhoneUSA") === "false") {
      this.phoneIsUSA = false;
    }

    this.dayRange = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];


    //labels by message service
    if (localStorage.getItem('EditTemplateLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('EditTemplateLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Update Pass Template Modal",
        "PassTemplate"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('EditTemplateLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    if (this.siteService._siteHasCountry) {
      this.passService.getCountryListAPI().subscribe((res: HttpResponse<any>) => {
        this.countryList = res.body;
      }, error => {
        console.log(error);
      });
    }

    //this.passService.getVisitReasonsAPI().then(() => {
      // console.log(this.passService.reasonOfVisit);
      this.isReasonOfVisitLoading = false;
      this.reasonOfVisit = this.passService.reasonOfVisit;
      this.filteredVisitReasons = this.visitReasonControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value?.DisplayValue),
        map(DisplayValue => DisplayValue ? this._filterVisitReason(DisplayValue) : this.reasonOfVisit.slice())
      );

    //});

    this.buildingService.parseAPI().then(() => {
      this.isBuildingLoading = false;
      this.buildings = [...this.buildingService.getBuildings()];
      this.filteredBuilding = this.buildingControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value?.BuildingName),
        map(BuildingName => BuildingName ? this._filterBuilding(BuildingName) : this.buildings.slice())
      );

      // console.log(this.entryPointService.getEntryPoints());
    });

    // this.entryPointService.parseGetEndpointAPI().then(() => {
    //   this.isEntryPointLoading = false;
    //   this.entryPoints = this.entryPointService.getEntryPoints();
    // });

    this.passTemplate = { ...this.data };
    this.buildingService.getFloors(this.passTemplate.DestinationBldg).subscribe((res) => {
      this.Getfloors = res.body;
      this.filteredFloors = this.floorControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'number' ? value : value?.Floor),
        map(Floor => Floor ? this._filterFloor(Floor) : this.Getfloors.slice())
      );
    });

    this.passTemplate.EstArrivalTime =
      (this.passTemplate.EstArrivalTime === null || this.passTemplate.EstArrivalTime === '0000-00-00T00:00:00')
        ? null : new Date(this.passTemplate.EstArrivalTime);
    this.passTemplate.EstDepartureTime =
      (this.passTemplate.EstDepartureTime === null || this.passTemplate.EstDepartureTime === '0000-00-00T00:00:00')
        ? null : new Date(this.passTemplate.EstDepartureTime);

    this.isEntryPointLoading = false;

    this.initializeLocks();

    this.StartDate = "Not Specified";
    this.passTemplateService.getPassTemplatePickListTablesAPI().then(() => {
      this.dateMethod = this.passTemplateService.passTemplateTables.filter(
        (f) => f.FieldName == "MethodTypeParam"
      );
      this.weekList = this.passTemplateService.passTemplateTables.filter(
        (f) => f.FieldName == "WeekOptionParam"
      );
      if (this.passTemplate.StartTimeParameters) {
        var startTimeParmeterList: string[] = this.passTemplate.StartTimeParameters.split(
          ","
        );

        if (startTimeParmeterList.length > 0) {
          this.DateMethod = Number(startTimeParmeterList[0]);
          this.dateMethod.forEach((element) => {
            if (element.PickListID == this.DateMethod) {
              this.DateMethodName = element.DisplayValue;
            }
          });
          if (
            this.DateMethodName == "WeekWise" &&
            startTimeParmeterList.length >= 2
          ) {
            this.StartWeek = Number(startTimeParmeterList[1]);
            this.StartDay = Number(startTimeParmeterList[2]);
            var week;
            this.weekList.forEach((element) => {
              if (element.PickListID == this.StartWeek) {
                week = element.DisplayValue;
              }
            });

            var day = this.dayRange[this.StartDay - 1];
            this.StartDate = day + " of " + week;
          } else if (
            this.DateMethodName == "CurrentDate" &&
            startTimeParmeterList.length >= 1
          ) {
            this.StartDate = "Today";
          } else if (
            this.DateMethodName == "Tomorrow" &&
            startTimeParmeterList.length >= 1
          ) {
            this.StartDate = "Tomorrow";
          }
        }
      }
    });

    this.isParkingDisabled = this.passTemplate.EntryType == 1 ? false : true; // enable ParkingDrodown if EntryType is Drive on

    // load Template category Dropdown

    this.templateCategoryService.getTemplateCategoryAPI().then(() => {
      this.templateCategories = this.templateCategoryService.getTemplateCategory();
      this.templateCategories = this.templateCategories.filter(
        (f) => f.OrganizationID == this.userService.organizationID
      );
      this.filteredTemplateCategory = this.templateCategoryControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value.Category),
        map(Category => Category ? this._filterTemplateCategory(Category) : this.templateCategories.slice())
      );
      //this.displayFn3 = this.displayFn3.bind(this);
   });

    this.filteredPointGrps = this.entrygrpControl.valueChanges.pipe(
      startWith(""),
      map((value) => (typeof value === "string" ? value : value.name)),
      map((name) =>
        name ? this._filterPointGrp(name) : this.entrygrpOptions.slice()
      )
    );

    // Load Entry Point Groups
    this.entryPointGroupService
      .getAllEntryPointGroupsAPI(this.userService.organizationID)
      .then(() => {
        this.entryPointGroups = this.entryPointGroupService.getEntryPointGroups();
        this.filteredEntryPointGroup = this.entryPointGroupFormControl.valueChanges.pipe(
          startWith(""),
          map(value => typeof value === 'string' ? value : value?.EntryPointGrpName),
          map(EntryPointGrpName => EntryPointGrpName ? this._filteredEntryPointGroup(EntryPointGrpName) : this.entryPointGroups.slice())
        );
        this.entryPoints = this.entryPointGroupService.getGroupMappings(
          this.passTemplate.EntryPointGroupID
        ).EntryPoints;
        this.filteredExpectedEntryPointGroup = this.expectedEntryPointFormControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value?.Description),
        map(Description => Description ? this._filteredExpectedEntryPointGroup(Description) : this.entryPoints.slice())
      );
        this.validEntryPointGroup = this.entryPointGroups.some(item => item.EntryPointGroupID == this.passTemplate.EntryPointGroupID);
      })
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );

    //initialize minTime for timePicker based on arrival time

    if (this.passTemplate.EstArrivalTime) {
      if (this.siteService.dataTimeFormat.angularTimeFormat == '12 hours') {
        this.minTime = moment(this.passTemplate.EstArrivalTime).format(
          "hh:mm tt"
        );
      } else {
        this.minTime = moment(this.passTemplate.EstArrivalTime).format(
          "HH:mm"
        );
      }
    }
  }

  private _filteredExpectedEntryPointGroup(value: string) {
    return this.entryPoints.filter(option => option.Description.toLowerCase().includes(value.toLowerCase()));
  }

  displayFn6(selectedoption: number) {
    if(selectedoption) {
    return this.entryPoints.find(x => x.EntryPointID === selectedoption).Description;
    }
  }

  private _filteredEntryPointGroup(value: string) {
    return this.entryPointGroups.filter(option => option.EntryPointGrpName.toLowerCase().includes(value.toLowerCase()));
  }

     displayFn4(selectedoption: number) {
    if (selectedoption) {
      const selectedEntryPointGroups = this.entryPointGroups.find(x => x.EntryPointGroupID === selectedoption);
      return selectedEntryPointGroups ? selectedEntryPointGroups.EntryPointGrpName : '';
    }
    return '';
  }

  private _filterFloor(value: string) {
    if (typeof value !== 'string') {
      return []; // or handle the error as needed
    }
    return this.Getfloors.filter(option => option.Floor.toLowerCase().includes(value.toLowerCase()));
  }
     displayFn5(selectedoption: number) {
    if (selectedoption) {
      const selectedGetfloors = this.Getfloors.find(x => x.BuildingFloorID === selectedoption);
      return selectedGetfloors ? selectedGetfloors.Floor : '';
    }
    return '';
  }

  //template category typeahead
  private _filterTemplateCategory(value: string) {
    return this.templateCategories.filter(option => option.Category.toLowerCase().includes(value.toLowerCase()));
  }
     displayFn3(selectedoption) {
    if (selectedoption) {
      const selectedTemplateCategories = this.templateCategories.find(x => x.TemplateCategoryID === selectedoption);
      return selectedTemplateCategories ? selectedTemplateCategories.Category : '';
    }
    return '';
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  private _filterBuilding(value: string) {
    return this.buildings.filter(option => option.BuildingName.toLowerCase().includes(value.toLowerCase()));
  }

     displayFn2(selectedoption) {
    if (selectedoption) {
      const selectedBuildings = this.buildings.find(x => x.BuildingID === selectedoption);
      return selectedBuildings ? selectedBuildings.BuildingName : '';
    }
    return '';
  }

//visit reason typeahead
private _filterVisitReason(value: string) {
  return this.reasonOfVisit.filter(option => option.DisplayValue.toLowerCase().includes(value.toLowerCase()));
}

    displayFn(selectedoption: number) {
  if (selectedoption) {
    const selectedReason = this.reasonOfVisit.find(x => x.PickListID === selectedoption);
    return selectedReason ? selectedReason.DisplayValue : '';
  }
  return '';
}

  ngAfterViewChecked() {
    this.requiredFieldsService.setRequiredFields(
      'Edit Pass Template',
      this.updatePassForm
    );
  }

  //entry point type ahead
  displayPoints(point?: Point): string | undefined {
    return point ? point.name : undefined;
  }

  private _filterPoint(name: string): Point[] {
    const filterValue = name.toLowerCase();

    return this.entryOptions.filter(
      (entryOption) => entryOption.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  private _filterPointGrp(name: string): PointGrps[] {
    const filterValue = name.toLowerCase();

    return this.entrygrpOptions.filter(
      (entryOption) => entryOption.name.toLowerCase().indexOf(filterValue) === 0
    );
  }
  //gets chosen destination
  getDestination(building) {
    // this.passTemplate.destination = building.name;
  }
  //gets chose entry point
  getPoint(point) {
    // this.passTemplate.entryPoint = point.name;
  }
  //sends data back to the grids
  onClose(): void {
    this.dialogRef.close(this.passTemplate);
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  //email validation
  getErrorMessage() {
    if (this.email.hasError("required")) {
      return this.screenLabels["EnterAValueValidationMsg"] ? this.screenLabels["EnterAValueValidationMsg"] : "You must enter a value";
    }

    return this.email.hasError("email") ? this.screenLabels["NotAValidEmailErrorMsg"] ? this.screenLabels["NotAValidEmailErrorMsg"] : "Not a valid email" : "";
  }

  onEntryTypeChange(event: MatRadioChange) {
    if (event.value == 1) {
      // Drive On
      if (!this.passTemplate.ParkingLotID) {
        this.enableNext = true;
      } else {
        this.enableNext = false;
      }
      this.isParkingDisabled = false;
    } else {
      this.enableNext = false;
      this.isParkingDisabled = true;
      this.passTemplate.ParkingLotID = 0;
    }
    this.enableLocks();
  }

  enableLocks(){
    if(this.passTemplate.EntryType == 1){
      this.enableLock = this.passTemplate.ParkingLotID > 0 ? true : false;
    }
    if(this.floors.length >= 1){
      this.enableLock = this.passTemplate.DestinationBlgFlr ? true : false;
    }
  }

  onBuilding(event: MatAutocompleteSelectedEvent){

    if (this.passTemplate.DestinationBldg) {
      // this is an asynchronous call, subscribe to getFloors
      this.buildingService.getFloors(event.option.value).subscribe((res) => {
        this.Getfloors = res.body;
        this.filteredFloors = this.floorControl.valueChanges.pipe(
          startWith(""),
          map(value => typeof value === 'number' ? value : value?.Floor),
          map(Floor => Floor ? this._filterFloor(Floor) : this.Getfloors.slice())
        );
      });
    }

    this.floors = this.buildingService.getBuildingFloors(this.passTemplate.DestinationBldg ? +this.passTemplate.DestinationBldg : null);
    if(this.floors.length >= 1){
      this.enableNext = true;
    }
    else if(this.floors.length == 0){
      this.enableNext = false;
      this.passTemplate.DestinationBlgFlr = "";
    }
    this.enableLocks();
  }

  onFloor(event){
    if(this.floors.length >= 1 && this.passTemplate.DestinationBlgFlr){
      this.enableNext = false;
    }
    else if(this.floors.length >= 1 && !this.passTemplate.DestinationBlgFlr){
      this.enableNext = true;
    }
    this.enableLocks();
  }

  onParkingLotChange(){
    this.enableLocks();
  }

  disableLockAllFields(event:string): boolean {
    if(event === "host") {
      // if(this.passTemplate.HostFirstNameLock && this.passTemplate.HostMiddleNameLock && this.passTemplate.HostLastNameLock && this.passTemplate.HostNameSuffixLock && this.passTemplate.HostEmailLock && this.passTemplate.HostPhoneLock && this.passTemplate.HostCompanyLock)
      if(this.passTemplate.HostFirstNameLock && this.passTemplate.HostLastNameLock && this.passTemplate.HostNameSuffixLock && this.passTemplate.HostEmailLock && this.passTemplate.HostPhoneLock && this.passTemplate.HostCompanyLock)
      return true;
      else return false;
    }
    else if(event === "visitorDetails") {
      if(this.passTemplate.EntryType === 1) {
        if(this.passTemplate.IncludeNonWorkingdaysLock && this.passTemplate.PhotoPassLock && this.passTemplate.EstArrivalTimeLock && this.passTemplate.EstDepartureTimeLock && this.passTemplate.VisitReasonLock && this.passTemplate.DestinationBldgLock && this.passTemplate.DestinationBlgFlrLock && this.passTemplate.DestinationRoomLock && this.passTemplate.EntryTypeLock && this.passTemplate.ExpectedEntryPointLock && this.passTemplate.EntryPointGroupIDLock && this.passTemplate.ParkingLotIDLock && this.passTemplate.ParkingSpotLock && this.passTemplate.StartTimeLock && this.passTemplate.EndTimeLock)
        return true;
        else return false;
      }
      else {
        if(this.passTemplate.IncludeNonWorkingdaysLock && this.passTemplate.PhotoPassLock && this.passTemplate.EstArrivalTimeLock && this.passTemplate.EstDepartureTimeLock && this.passTemplate.VisitReasonLock && this.passTemplate.DestinationBldgLock && this.passTemplate.DestinationBlgFlrLock && this.passTemplate.DestinationRoomLock && this.passTemplate.EntryTypeLock && this.passTemplate.ExpectedEntryPointLock && this.passTemplate.EntryPointGroupIDLock && this.passTemplate.StartTimeLock && this.passTemplate.EndTimeLock)
        return true;
        else return false;
      }
    }
    else if(event === "visitorInfo") {
      // if(this.passTemplate.VisitorFirstNameLock && this.passTemplate.VisitorMiddleNameLock && this.passTemplate.VisitorLastNameLock && this.passTemplate.VisitorNameSuffixLock && this.passTemplate.VisitorEmailLock && this.passTemplate.VisitorPhoneLock && this.passTemplate.VisitorCompanyLock && this.passTemplate.VisitorCountryLock && this.passTemplate.NotesInternalLock && this.passTemplate.NotesPassLock )
      if(this.passTemplate.VisitorFirstNameLock && this.passTemplate.VisitorLastNameLock && this.passTemplate.VisitorNameSuffixLock && this.passTemplate.VisitorEmailLock && this.passTemplate.VisitorPhoneLock && this.passTemplate.VisitorCompanyLock && this.passTemplate.VisitorCountryLock && this.passTemplate.NotesInternalLock && this.passTemplate.NotesPassLock )
      return true;
      else return false;
    }
  }

  disableUnLockAllFields(event:string): boolean {
    if(event === "host") {
      // if(this.passTemplate.HostFirstNameLock || this.passTemplate.HostMiddleNameLock || this.passTemplate.HostLastNameLock || this.passTemplate.HostNameSuffixLock || this.passTemplate.HostEmailLock || this.passTemplate.HostPhoneLock || this.passTemplate.HostCompanyLock)
      if(this.passTemplate.HostFirstNameLock || this.passTemplate.HostLastNameLock || this.passTemplate.HostNameSuffixLock || this.passTemplate.HostEmailLock || this.passTemplate.HostPhoneLock || this.passTemplate.HostCompanyLock)
      return false;
      else return true;
    }
    else if(event === "visitorDetails") {
      if(this.passTemplate.IncludeNonWorkingdaysLock || this.passTemplate.PhotoPassLock || this.passTemplate.EstArrivalTimeLock || this.passTemplate.EstDepartureTimeLock || this.passTemplate.VisitReasonLock || this.passTemplate.DestinationBldgLock || this.passTemplate.DestinationBlgFlrLock || this.passTemplate.DestinationRoomLock || this.passTemplate.EntryTypeLock || this.passTemplate.ExpectedEntryPointLock || this.passTemplate.EntryPointGroupIDLock || this.passTemplate.ParkingLotIDLock || this.passTemplate.ParkingSpotLock || this.passTemplate.StartTimeLock || this.passTemplate.EndTimeLock )
      return false;
      else return true;
    }
    else if(event === "visitorInfo") {
      // if(this.passTemplate.VisitorFirstNameLock || this.passTemplate.VisitorMiddleNameLock || this.passTemplate.VisitorLastNameLock || this.passTemplate.VisitorNameSuffixLock || this.passTemplate.VisitorEmailLock || this.passTemplate.VisitorPhoneLock || this.passTemplate.VisitorCompanyLock || this.passTemplate.VisitorCountryLock || this.passTemplate.NotesInternalLock || this.passTemplate.NotesPassLock )
      if(this.passTemplate.VisitorFirstNameLock || this.passTemplate.VisitorLastNameLock || this.passTemplate.VisitorNameSuffixLock || this.passTemplate.VisitorEmailLock || this.passTemplate.VisitorPhoneLock || this.passTemplate.VisitorCompanyLock || this.passTemplate.VisitorCountryLock || this.passTemplate.NotesInternalLock || this.passTemplate.NotesPassLock )
      return false;
      else return true;
    }
  }

  lockHostFields(event) {
    if (event) {
      this.passTemplate.HostFirstNameLock = true;
      this.passTemplate.HostMiddleNameLock = true;
      this.passTemplate.HostLastNameLock = true;
      this.passTemplate.HostNameSuffixLock = true;
      this.passTemplate.HostEmailLock = true;
      this.passTemplate.HostPhoneLock = true;
      this.passTemplate.HostCompanyLock = true;
    } else {
      this.passTemplate.HostFirstNameLock = false;
      this.passTemplate.HostMiddleNameLock = false;
      this.passTemplate.HostLastNameLock = false;
      this.passTemplate.HostNameSuffixLock = false;
      this.passTemplate.HostEmailLock = false;
      this.passTemplate.HostPhoneLock = false;
      this.passTemplate.HostCompanyLock = false;
    }
  }
  lockVisitDetailsFields(event) {
    if (event) {
      this.passTemplate.IncludeNonWorkingdaysLock = true;
      this.passTemplate.PhotoPassLock = true;
      this.passTemplate.EstArrivalTimeLock = true;
      this.passTemplate.EstDepartureTimeLock = true;
      this.passTemplate.VisitReasonLock = true;
      this.passTemplate.DestinationBldgLock = true;
      this.passTemplate.DestinationBlgFlrLock = true;
      this.passTemplate.DestinationRoomLock = true;
      this.passTemplate.EntryTypeLock = true;
      this.passTemplate.ExpectedEntryPointLock = true;
      this.passTemplate.EntryPointGroupIDLock = true;
      this.passTemplate.ParkingLotIDLock = true;
      this.passTemplate.StartTimeLock = true;
      this.passTemplate.EndTimeLock = true;
    } else {
      this.passTemplate.IncludeNonWorkingdaysLock = false;
      this.passTemplate.PhotoPassLock = false;
      this.passTemplate.EstArrivalTimeLock = false;
      this.passTemplate.EstDepartureTimeLock = false;
      this.passTemplate.VisitReasonLock = false;
      this.passTemplate.DestinationBldgLock = false;
      this.passTemplate.DestinationBlgFlrLock = false;
      this.passTemplate.DestinationRoomLock = false;
      this.passTemplate.EntryTypeLock = false;
      this.passTemplate.ExpectedEntryPointLock = false;
      this.passTemplate.EntryPointGroupIDLock = false;
      this.passTemplate.ParkingLotIDLock = false;
      this.passTemplate.StartTimeLock = false;
      this.passTemplate.EndTimeLock = false;
    }
  }
  lockVisitorFields(event) {
    if (event) {
      this.passTemplate.VisitorFirstNameLock = true;
      this.passTemplate.VisitorMiddleNameLock = true;
      this.passTemplate.VisitorLastNameLock = true;
      this.passTemplate.VisitorNameSuffixLock = true;
      this.passTemplate.VisitorEmailLock = true;
      this.passTemplate.VisitorPhoneLock = true;
      this.passTemplate.VisitorCountryLock = true;
      this.passTemplate.VisitorCompanyLock = true;
      this.passTemplate.NotesInternalLock = true;
      this.passTemplate.NotesPassLock = true;
    } else {
      this.passTemplate.VisitorFirstNameLock = false;
      this.passTemplate.VisitorMiddleNameLock = false;
      this.passTemplate.VisitorLastNameLock = false;
      this.passTemplate.VisitorNameSuffixLock = false;
      this.passTemplate.VisitorEmailLock = false;
      this.passTemplate.VisitorPhoneLock = false;
      this.passTemplate.VisitorCompanyLock = false;
      this.passTemplate.VisitorCountryLock = false;
      this.passTemplate.NotesInternalLock = false;
      this.passTemplate.NotesPassLock = false;
    }
  }

  initializeLocks() {
    this.passTemplate.HostFirstNameLock = this.passTemplate.HostFirstNameLock
      ? true
      : false;
    // this.passTemplate.HostMiddleNameLock = this.passTemplate.HostMiddleNameLock
    //   ? true
    //   : false;
    this.passTemplate.HostMiddleNameLock = false;
    this.passTemplate.HostLastNameLock = this.passTemplate.HostLastNameLock
      ? true
      : false;
    this.passTemplate.HostNameSuffixLock = this.passTemplate.HostNameSuffixLock
      ? true
      : false;
    this.passTemplate.HostEmailLock = this.passTemplate.HostEmailLock
      ? true
      : false;
    this.passTemplate.HostPhoneLock = this.passTemplate.HostPhoneLock
      ? true
      : false;
    this.passTemplate.HostCompanyLock = this.passTemplate.HostCompanyLock
      ? true
      : false;
    this.passTemplate.IncludeNonWorkingdaysLock = this.passTemplate
      .IncludeNonWorkingdaysLock
      ? true
      : false;
    this.passTemplate.PhotoPassLock = this.passTemplate.PhotoPassLock
      ? true
      : false;
    this.passTemplate.EstArrivalTimeLock = this.passTemplate.EstArrivalTimeLock
      ? true
      : false;
    this.passTemplate.EstDepartureTimeLock = this.passTemplate
      .EstDepartureTimeLock
      ? true
      : false;
    this.passTemplate.VisitReasonLock = this.passTemplate.VisitReasonLock
      ? true
      : false;
    this.passTemplate.DestinationBldgLock = this.passTemplate
      .DestinationBldgLock
      ? true
      : false;
    this.passTemplate.DestinationBlgFlrLock = this.passTemplate
      .DestinationBlgFlrLock
      ? true
      : false;
    this.passTemplate.DestinationRoomLock = this.passTemplate
      .DestinationRoomLock
      ? true
      : false;
    this.passTemplate.EntryTypeLock = this.passTemplate.EntryTypeLock
      ? true
      : false;
    this.passTemplate.ExpectedEntryPointLock = this.passTemplate
      .ExpectedEntryPointLock
      ? true
      : false;
    this.passTemplate.EntryPointGroupIDLock = this.passTemplate
      .EntryPointGroupIDLock
      ? true
      : false;
    this.passTemplate.ParkingLotIDLock = this.passTemplate.ParkingLotIDLock
      ? true
      : false;
    this.passTemplate.VisitorFirstNameLock = this.passTemplate.VisitorFirstNameLock
      ? true
      : false;
    // this.passTemplate.VisitorMiddleNameLock = this.passTemplate
    //   .VisitorMiddleNameLock
    //   ? true
    //   : false;
    this.passTemplate.VisitorMiddleNameLock = false;
    this.passTemplate.VisitorLastNameLock = this.passTemplate.VisitorLastNameLock
      ? true
      : false;
    this.passTemplate.VisitorNameSuffixLock = this.passTemplate.VisitorNameSuffixLock
      ? true
      : false;
    this.passTemplate.VisitorEmailLock = this.passTemplate.VisitorEmailLock
      ? true
      : false;
    this.passTemplate.VisitorPhoneLock = this.passTemplate.VisitorPhoneLock
      ? true
      : false;
    this.passTemplate.VisitorCompanyLock = this.passTemplate.VisitorCompanyLock
      ? true
      : false;
    this.passTemplate.VisitorCountryLock = this.passTemplate.VisitorCountryLock
      ? true
      : false;
    this.passTemplate.StartTimeLock = this.passTemplate.StartTimeLock
      ? true
      : false;
    this.passTemplate.EndTimeLock = this.passTemplate.EndTimeLock
      ? true
      : false;
    this.passTemplate.NotesPassLock = this.passTemplate.NotesPassLock
      ? true
      : false;
    this.passTemplate.NotesInternalLock = this.passTemplate.NotesInternalLock
      ? true
      : false;
  }

  formatDate(data: any) {
    let date = this._utilService.stripLocalTimeZone(data.toString());

    return data ? date : null;
  }
  onSaveChanges() {
    this.passTemplate.HostMiddleName = "";
    this.passTemplate.VisitorMiddleName = "";
    this.passTemplate.HostMiddleNameLock = false;
    this.passTemplate.VisitorMiddleNameLock = false;
    this.dialogService
      .openConfirmDialog(
        this.screenLabels["UpdatePassTemplateConfirmMsg"] ? this.screenLabels["UpdatePassTemplateConfirmMsg"] : "Confirm Pass Template Update",
        this.screenLabels["UpdateSaveChangesConfirmMsg"] ? this.screenLabels["UpdateSaveChangesConfirmMsg"] : "Are you sure you want to save the changes ?"
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        const name = this.passTemplate.TemplateName.trim() === this.passTemplate.TemplateName ? this.passTemplate.TemplateName : this.passTemplate.TemplateName.trim();
        this.passTemplate.TemplateName = name ;
        if (confirm) {
          // confirmed
          this.passTemplate.UserID = this.userService.userID;
          this.passTemplate.OrganizationID = this.userService.organizationID;
          if(this.DateMethodName == ""){
            this.passTemplate.StartTimeParameters = this.DateMethod + "";
          }
          else if (this.DateMethodName == "WeekWise") {
            this.passTemplate.StartTimeParameters =
              this.DateMethod + "," + this.StartWeek + "," + this.StartDay;
          } else if (this.DateMethodName == "CurrentDate") {
            this.passTemplate.StartTimeParameters = this.DateMethod + "";
          } else if (this.DateMethodName == "Tomorrow") {
            this.passTemplate.StartTimeParameters = this.DateMethod + "";
          }
          if (this.passTemplate.EstArrivalTime) {
            this.passTemplate.EstArrivalTime =
              (this.passTemplate.EstArrivalTime === '0000-00-00T00:00:00')
                ? null : this.formatDate(
                  this.passTemplate.EstArrivalTime
                );
            if (this.passTemplate.EstArrivalTime) {
              this.passTemplate.EstArrivalTime = this.datePipe.transform(
                new Date(this.passTemplate.EstArrivalTime),
                "yyyy-MM-dd HH:mm"
              );
            }
          }
          if (this.passTemplate.EstDepartureTime) {
            this.passTemplate.EstDepartureTime =
              (this.passTemplate.EstDepartureTime === '0000-00-00T00:00:00')
                ? null : this.formatDate(this.passTemplate.EstDepartureTime);
            if (this.passTemplate.EstDepartureTime) {
              this.passTemplate.EstDepartureTime = this.datePipe.transform(
                new Date(this.passTemplate.EstDepartureTime),
                "yyyy-MM-dd HH:mm"
              );
            }
          }
          this.passTemplateService.updatePassTemplateAPI(this.passTemplate);
          this.onNoClick();
        }
      });
  }

  openStartDate() {
    // open widget configure dialog
    const dialogRef = this.dialog
      .open(StartdateConfigModalComponent, {
        disableClose: true,
        data: {
          DateMethod: this.DateMethod,
          StartWeek: this.StartWeek,
          StartDay: this.StartDay,
          DateMethodName: this.DateMethodName,
        },
        backdropClass: "backdropBackground",
      })
      .afterClosed()
      .subscribe((data) => {
        this.StartDate = "Not Specified";
        this.DateMethodName = data.DateMethodName;
        if(data.DateMethodName == ""){
          this.DateMethod = data.DateMethod;
          this.StartDate = "Not Specified";
          this.passTemplate.EndTimeDuration = null;

        }
        else if (data.DateMethodName == "WeekWise") {
          this.DateMethod = data.DateMethod;
          if (data.StartWeek > 0) {
            this.StartWeek = data.StartWeek;
          }
          if (data.StartDay > 0) {
            this.StartDay = data.StartDay;

            var day = this.dayRange[data.StartDay - 1];
            this.StartDate = day + " of " + data.Week;
          } else {
            this.onDateTypeChange();
          }
        } else if (data.DateMethodName == "CurrentDate") {
          this.DateMethod = data.DateMethod;
          this.StartDate = "Today";
        } else if (data.DateMethodName == "Tomorrow") {
          this.DateMethod = data.DateMethod;
          this.StartDate = "Tomorrow";
        }
      });
  }

  onDateTypeChange() {
    this.StartDay = 0;
    this.StartWeek = 0;
  }

  OnArrivalTimeChange(event) {
    this.passTemplate.EstDepartureTime = null;
    if (event.newValue == "Invalid Date" || this.passTemplate.EstArrivalTime == "Invalid Date") {
      this.passTemplate.EstArrivalTime = null;
    } else {
      if (this.siteService.dataTimeFormat.angularTimeFormat == '12 hours') {
        this.minTime = moment(this.passTemplate.EstArrivalTime).format(
          "hh:mm tt"
        );
      } else {
        this.minTime = moment(this.passTemplate.EstArrivalTime).format(
          "HH:mm"
        );
      }
    }
  }
  OnDepartureTimeChange(event) {
    if (event.newValue == "Invalid Date" || this.passTemplate.EstDepartureTime == "Invalid Date") {
      this.passTemplate.EstDepartureTime = null;
    } else if (this.passTemplate.EstArrivalTime > this.passTemplate.EstDepartureTime)
      this.passTemplate.EstDepartureTime = null;
  }

  onEntryPointGroupChange(event: MatAutocompleteSelectedEvent) {
    const entryPointGroupID = event.option.value;
    this.entryPoints = this.entryPointGroupService.getGroupMappings(
      entryPointGroupID
    ).EntryPoints;
    this.filteredExpectedEntryPointGroup = this.expectedEntryPointFormControl.valueChanges.pipe(
      startWith(""),
      map(value => typeof value === 'string' ? value : value?.Description),
      map(Description => Description ? this._filteredExpectedEntryPointGroup(Description) : this.entryPoints.slice())
    );
    this.validEntryPointGroup = this.entryPointGroups.some(item => item.EntryPointGroupID == this.passTemplate.EntryPointGroupID);
  }
}
