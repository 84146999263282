import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { UserService } from "./user.service";
import { EntryPointService } from "./entry-point.service";
import { SearchService } from "./search.service";
import { PassService } from "./pass.service";
import { IEntryPointReadAPI } from "../models/interfaces/entrypoint-read-api";
import { SiteService } from "./site.service";
import { ApplicationRef } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ClientInfoService {
  public IP: string;
  public MachineName: string;
  private machineLocalhost: string = "http://localhost:6677";

  constructor(
    private userService: UserService,
    private searchService: SearchService,
    private entryPointService: EntryPointService,
    private passService: PassService,
    private http: HttpClient,
    private siteService: SiteService,
    private appRef: ApplicationRef
  ) {
    this.userService.userDetailsLoaded.subscribe(() => {
      if (this.userService.userCheckIn) this.initialize();
    });

    // this.siteService.siteChanged.subscribe((data: boolean) => {

    //     this.initialize();

    // });
  }

  public async initialize() {
    await this.entryPointService.parseGetEndpointAPI();
    const entryPointData: IEntryPointReadAPI[] = this.entryPointService.entryPointStationApiData;

    if (entryPointData.length > 1) {
      this.readConfig();

    }
    else if (entryPointData.length === 1) {
      const entryPointID = entryPointData[0].EntryPointID;
      this.setDefaultEntryPoint(entryPointID);


    }
    else {
      console.error('No Entry Point is available for the selected Site');
    }

    this.appRef.tick();
  }

  public async readConfig() {
    // const URL = `C:/VMSInfo/EntryPointInfo.json`;

    // const infoFile = await import("C:/VMSInfo/EntryPointInfo.json");
    // this.IP = infoFile.IP;
    // this.MachineName = infoFile.MachineName;

    this.http.get(this.machineLocalhost).subscribe(
      (res: { MachineName: string; IP: string }) => {
        console.log("Entry Point Station Data Loaded Successfully");
        // console.log(res);

        // get the 1st IP address
        this.IP = res.IP.split(" ")[0].trim();
        this.MachineName = res.MachineName;

        this.setDefaultEntryPoint();
      },
      (err) => {
        if (!this.userService.userCheckedInEntryPoint) {
          this.userService.userCheckedInEntryPoint = undefined;
        }
        console.warn(
          "Service to retrieve the default Entry Point is not running",
          err
        );
      }
    );
  }

  // invoked to set the default Entry Point
  public async setDefaultEntryPoint(entryPointID?: number) {
    let defaultEntryPointID;

    if (entryPointID === undefined) {
      let entryPointStatioData = this.entryPointService.entryPointStationApiData
        .filter(
          (ep) => ep.HostName === this.MachineName || ep.IPAddress === this.IP
        )
        .map((ep) => ep)[0];
      defaultEntryPointID = entryPointStatioData.EntryPointID
      sessionStorage.setItem("EntryPointStationID", entryPointStatioData.EntryPointStationID.toString());
    }
    else {
      defaultEntryPointID = entryPointID;
    }

    setTimeout(async () => {
      this.userService.userCheckedInEntryPoint = defaultEntryPointID;
      this.searchService.setEntryPointID(defaultEntryPointID);
      this.searchService.entryPointDataChanged.emit(true);

      try {
        await this.passService.getValidEntryPointGroupsAPI(defaultEntryPointID);
      }
      catch (err) {
        console.error(err);
      }
    }, 0);



  }
}
