import { Component, OnInit } from "@angular/core";
import { ViewEntryPointGroupComponent } from "./modals/view-entry-point-group/view-entry-point-group.component";
import { EntryPointGroup } from "../models/entry-point-group.model";
import { EntryPointGroupService } from "../services/entry-point-group.service";
import { MatDialog } from "@angular/material";
import { SearchService } from "../services/search.service";
import { CreateEntryPointGroupModalComponent } from "./modals/create-entry-point-group/create-entry-point-group-modal.component";
import { HttpErrorResponse } from "@angular/common/http";
import { NotificationService } from "../services/notification.service";
import { UpdateEntryPointGroupComponent } from "./modals/update-entry-point-group/update-entry-point-group.component";
import { DialogService } from "../services/dialog.service";
import { UserService } from "../services/user.service";
import { SiteService } from "../services/site.service";
import { GridApi } from "ag-grid-community";
import { MessageService } from '../services/message.service';

@Component({
  selector: "app-entry-point-group",
  templateUrl: "./entry-point-group.component.html",
  styleUrls: ["./entry-point-group.component.css"],
})
export class EntryPointGroupComponent implements OnInit {
  protected selectedEntryPtGrp: EntryPointGroup;
  protected rowData;
  protected rowClassRules;
  private paginationPageSize;
  private gridApi: GridApi;
  private selectedRow;
  public isRowSelected: boolean = false;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  protected entryptgrpColumnDefs : any ;
  public isActive : boolean = false;
  public defaultColDef : object;

  constructor(
    protected entryPointGroupService: EntryPointGroupService,
    protected dialog: MatDialog,
    private searchService: SearchService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    protected userService: UserService,
    private siteService: SiteService,
    protected messageService: MessageService,
  ) {
    this.paginationPageSize = 50;
    // set row styles
    this.rowClassRules = {
      "row-inactive": (params) => {
        // console.log(params);
        var condition = params.data.Active === 0;
        return condition;
      },
    };
    this.defaultColDef = {
      resizable: true,
  }
  }

  ngOnInit(): void {
    // this.searchService.pageName = "Entry Point Group";
    this.searchService.setPageName("Entry Point Group");


    //labels by message service
if (localStorage.getItem('EntryPointGroupsLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('EntryPointGroupsLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Entry Point Group",
      "EntryPointGroup"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('EntryPointGroupsLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        this.rowData = this.searchService.searchRecords;
        this.gridApi.paginationGoToPage(0);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    // new search initiated
    this.searchService.searchInitiated.subscribe(() => {
      this.searchService.smartSearchPromise
        .then(() => {
          this.isRowSelected = false;
          this.rowData = this.searchService.searchRecords;
          this.gridApi.paginationGoToPage(0);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    });

    //  entry point group data changed
    this.entryPointGroupService.entryPointGroupDataChanged.subscribe(
      (data: boolean) => {
        this.isRowSelected = false;
        this.searchService
          .smartSearchMultiSiteAPI(null)
          .then(() => {
            this.rowData = this.searchService.searchRecords;
            this.gridApi.paginationGoToPage(0);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    );
  }

  setColumnDef(){
  this.entryptgrpColumnDefs = [
    {
      headerName: "",
      field: "",
      width: 20,
      cellStyle: { color: "#589bf8" },
      hide: false,
      checkboxSelection: true,
      resizable: false,
      sortable: false,
      lockPosition: true,
    },
    {
      headerName:this.screenLabels["EntryPointGroupID"]
      ? this.screenLabels["EntryPointGroupID"]
      : "Entry Point Group ID",
      field: "EntryPointGroupID",
      hide: true,
      sortable: true,
    },
    {
      headerName:this.screenLabels["EntryPointGroupName"]
      ? this.screenLabels["EntryPointGroupName"]
      : "Entry Point Group Name",
      field: "EntryPointGrpName",
      sortable: true,
    },
    {
      headerName:this.screenLabels["Active"]
      ? this.screenLabels["Active"]
      : "Active",
      field: "Active",
      hide: true,
      sortable: true,
    },
    {
      headerName:this.screenLabels["ModifiedBy"]
      ? this.screenLabels["ModifiedBy"]
      : "Modified By",
      field: "ModifiedBy",
      hide: true,
      sortable: true,
    },
    {
      headerName:this.screenLabels["ModifiedDate"]
      ? this.screenLabels["ModifiedDate"]
      : "Modified Date",
      field: "ModifiedDate",
      hide: true,
      sortable: true,
    },
    {
      headerName:this.screenLabels["CreatedBy"]
      ? this.screenLabels["CreatedBy"]
      : "Created By",
      field: "CreatedBy",
      hide: true,
      sortable: true,
    },
    {
      headerName:this.screenLabels["CreatedDate"]
      ? this.screenLabels["CreatedDate"]
      : "Created Date",
      field: "CreatedDate",
      hide: true,
      sortable: true,
    },
    {
      headerName:this.screenLabels["CreatedByName"]
      ? this.screenLabels["CreatedByName"]
      : "Created By Name",
      field: "CreatedByName",
      hide: true,
      sortable: true,
    },
    {
      headerName:this.screenLabels["ModifiedByName"]
      ? this.screenLabels["ModifiedByName"]
      : "Modified By Name",
      field: "ModifiedByName",
      hide: true,
      sortable: true,
    },
  ];
}

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDef()
}

  onGridReady(params) {
    this.gridApi = params.api;
  }

  // make ag-rid column fill their container
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  openEntryPointGroup($event) {
    this.selectedEntryPtGrp = $event.data;
    this.dialog.open(ViewEntryPointGroupComponent, {
      disableClose: true,
      data: this.selectedEntryPtGrp,
    });
  }

  // Invoked when Create Entry Point Button is Clicked
  onCreateEntryPointButtonClick() {
    this.dialog.open(CreateEntryPointGroupModalComponent, {
      disableClose: true,
      data: {},
    });
  }

  // Invoked when Edit Entry Point Button is Clicked
  onEditEntryPointButtonClick($event) {
    if (this.selectedRow) {
      this.dialog.open(UpdateEntryPointGroupComponent, {
        disableClose: true,
        data: this.selectedRow,
      });
    }
  }

  // Invoked when Entry Point Group 'Delete' button is clicked
  onDisableBtnClicked($event) {
    let name = this.selectedRow.EntryPointGrpName;
    let message: string = this.screenLabels["DeleteEntryPointGroupConfirmMsg"] ? this.screenLabels["DeleteEntryPointGroupConfirmMsg"] : "Are you sure you want to delete the Entry Point Group <<id>> ? ";
    const capacityMessage = message.replace("<<id>>",name)

    this.dialogService
      .openConfirmDialog(
        this.screenLabels["DeleteEntryPointGroupBtn"]? this.screenLabels["DeleteEntryPointGroupBtn"]: "Delete Entry Point Group",
        capacityMessage
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          if (this.entryPointGroupService.IsAllEntryPointsGroupLoaded()) {
            // all entry points group mappings are loaded
            const associatedEntryPoints = this.entryPointGroupService.getGroupMappings(
              this.selectedRow.EntryPointGroupID
            );

            // Unlink the association
            if (
              associatedEntryPoints.hasOwnProperty("EntryPoints") &&
              associatedEntryPoints.EntryPoints.length
            ) {
              associatedEntryPoints.EntryPoints.forEach((item) => {
                item.Active = 0;
              });
            }

            // call the Entry Point Group Update API and make it Inactive
            this.entryPointGroupService
              .updateEntryPointGroupAPI(
                this.selectedRow.EntryPointGroupID,
                this.selectedRow.EntryPointGrpName,
                false,
                associatedEntryPoints.EntryPoints
              )
              .then(() => {
                this.notificationService.success(
                  this.entryPointGroupService.message
                );
              })
              .catch((err: HttpErrorResponse) => {
                this.notificationService.failure(err.message);
              });
          } else {
            // All entry point group mappings are not loaded
            this.entryPointGroupService.getAllEntryPointGroupsAPI().then(() => {
              const associatedEntryPoints = this.entryPointGroupService.getGroupMappings(
                this.selectedRow.EntryPointGroupID
              );

              // Unlink the association
              if (
                associatedEntryPoints.hasOwnProperty("EntryPoints") &&
                associatedEntryPoints.EntryPoints.length
              ) {
                associatedEntryPoints.EntryPoints.forEach((item) => {
                  item.Active = 0;
                });
              }

              // call the Entry Point Group Update API and make it Inactive
              this.entryPointGroupService
                .updateEntryPointGroupAPI(
                  this.selectedRow.EntryPointGroupID,
                  this.selectedRow.EntryPointGrpName,
                  false,
                  associatedEntryPoints.EntryPoints
                )
                .then(() => {
                  this.notificationService.success(
                    this.entryPointGroupService.message
                  );
                })
                .catch((err: HttpErrorResponse) => {
                  this.notificationService.failure(err.message);
                });
            });
          }
        }
      });
  }

  onSelectionChanged($event) {
    //if (this.gridApi.getSelectedRows() != "")
    if ($event.api.getSelectedRows().length) {
      // sets the data of selected row
      this.isRowSelected = true;
      this.selectedRow = $event.api.getSelectedRows()[0];
    } else {
      this.isRowSelected = false;
    }
    
    if(this.selectedRow.Active === 0){
      this.isActive = false;
    } else {
      this.isActive = true;
    }
  }
}
