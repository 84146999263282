import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ApprovalGroup } from '../models/approval-group-model';
import { DialogService } from '../services/dialog.service';
import { NotificationService } from '../services/notification.service';
import { SearchService } from '../services/search.service';
import { SiteService } from '../services/site.service';
import { UserService } from '../services/user.service';
import { CreateApprovalRequestConfigurationModalComponent } from './modals/create-approval-request-configuration-modal/create-approval-request-configuration-modal.component';
import { UpdateApprovalRequestConfigurationModalComponent } from './modals/update-approval-request-configuration-modal/update-approval-request-configuration-modal.component';
import { ViewApprovalRequestConfigurationModalComponent } from './modals/view-approval-request-configuration-modal/view-approval-request-configuration-modal.component';
import { SpinnerService } from '../services/spinner.service';
import { ApprovalRequestConfigurationService } from '../services/approval-request-configuration.service';
import { GridApi } from 'ag-grid-community';
import { MessageService } from '../services/message.service';
import { map, startWith } from "rxjs/operators";
import { Observable } from "rxjs";
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-approval-request-configuration',
  templateUrl: './approval-request-configuration.component.html',
  styleUrls: ['./approval-request-configuration.component.css']
})
export class ApprovalRequestConfigurationComponent implements OnInit {
  protected isRowSelected: boolean;
  protected rowData: any;
  protected approvalTypeData: any;
  protected approvalGroups: ApprovalGroup[] = [];
  protected approvalGroup: ApprovalGroup;
  protected tableFunctions: any[];
  protected emailTemplates: any[] = [];
  protected tableFunctionReturnValues: any[];
  protected rowClassRules;
  protected approvalConfig: any;
  protected selectedApprovalTypeId: number;
  private paginationPageSize;
  protected configData: any[];
  protected approvalTypeColumnDefs :any;
  private selectedApprovalRequest: any;
  private gridApi: GridApi;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  approvalTypeControl = new FormControl();
  filteredApprovalType: Observable<any[]>;
  public isActive: boolean = false;
  public defaultColDef : object;
  protected tableFunctionIDNumber: number;
  protected filteredTableReturns: any;
  approverEmailTemplates: any[];

  constructor(
    protected dialog: MatDialog,
    protected dialogService: DialogService,
    protected notificationService: NotificationService,
    protected searchService: SearchService,
    protected spinnerService: SpinnerService,
    protected approvalRequestConfigService: ApprovalRequestConfigurationService,
    protected userService: UserService,
    private siteService: SiteService,
    protected messageService: MessageService,
  ) {
    this.paginationPageSize = 50;
    // set row styles
    this.rowClassRules = {
      "row-inactive": (params) => {
        let condition = params.data.Active === 0;
        return condition;
      },
    };
    this.defaultColDef = {
      resizable: true,
  };
  }

  ngOnInit(): void {
    this.getDropdowns();
    this.searchService.setPageName("Approval Request Configuration");
    // this.isMobile = this.viewSelectorService.getIsMobile();

    //labels by message service
    if (localStorage.getItem('ApprovalRequestConfigLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('ApprovalRequestConfigLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Approval Request Configuration",
        "ApprovalRequestConfiguration"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('ApprovalRequestConfigLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    // Populates the AG Grid
    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        this.rowData = this.searchService.searchRecords.filter(row => row && row.ApprovalTypeID === this.selectedApprovalTypeId && row.SiteID === this.siteService.selectedSiteID)
        this.approvalTypeData = this.rowData[0];
        console.log("site ID"+this.siteService.selectedSiteID)
        setTimeout(() => {
          this.spinnerService.setIsLoading(false);
        }, 0);        // this.gridApi.api.setRowData(this.rowData);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
        setTimeout(() => {
          this.spinnerService.setIsLoading(false);
        }, 0);
      });
    // Listen to the event when smart search is invoked
    this.searchService.searchInitiated.subscribe((event: boolean) => {
      if (event) {
        this.searchService.smartSearchPromise
          .then(() => {
            this.rowData = this.searchService.searchRecords;
            this.rowData = this.rowData.filter(row => row && row.ApprovalTypeID === this.selectedApprovalTypeId);
            this.approvalTypeData = this.rowData[0];
            this.setApprovalConfig();
            this.isRowSelected = false;
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    });

    // subscribe to site changed
    this.siteService.siteChanged.subscribe((event: boolean) => {
        if(event){
           this.searchService
          .smartSearchMultiSiteAPI(null)
          .then(() => {
            this.rowData = this.searchService.searchRecords;   
            this.rowData = this.rowData.filter(row => row && row.ApprovalTypeID === this.selectedApprovalTypeId);;
            this.approvalTypeData = this.rowData[0];
            this.setApprovalConfig();
            this.isRowSelected = false;      
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
        }
    });

    this.approvalRequestConfigService.approvalRequestConfigDataChanged.subscribe((e) => {
      this.getApprovalConfig();
    });
    
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    //this.setColumnDef();
}

  onGridReady(params) {
    this.gridApi = params.api;
  }

  setColumnDef() {
    this.approvalTypeColumnDefs = [
      {
        headerName: "",
        field: "",
        width: 30,
        cellStyle: { color: "#589bf8" },
        hide: false,
        checkboxSelection: true,
        resizable: false,
        sortable: false,
        lockPosition: true
      },
      {
        headerName: this.screenLabels["AgGridDescription"]? this.screenLabels["AgGridDescription"]: "Description",
        field: "ApprovalRequestConfigDescription",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridApprovalGroup"]? this.screenLabels["AgGridApprovalGroup"]: "Approval Group",
        field: "ApprovalGroupID",
        hide: false,
        sortable: true,
        valueGetter: params => {
          let grpName = "";
          if (params.data.ApprovalGroupID && this.approvalGroups) {
            const grpObj = this.approvalGroups.filter(val => val.ApprovalGroupID === params.data.ApprovalGroupID)[0];
            grpName = grpObj ? grpObj.Name : '';
          }
          return grpName;
        }
      },
      {
        headerName: this.screenLabels["AgGridApprovalPosition"]? this.screenLabels["AgGridApprovalPosition"]: "Approval Position",
        field: "Position",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridApprovalRequired"]? this.screenLabels["AgGridApprovalRequired"]: "Approval Required",
        field: "TableFunctionID",
        hide: false,
        sortable: true,
        valueGetter: params => {
          let functionName = "";
          this.tableFunctionIDNumber = params.data.TableFunctionID;
          if (params.data.TableFunctionID && this.tableFunctions) {
            const functionObj = this.tableFunctions.filter(val => val.TableFunctionID === params.data.TableFunctionID)[0];
            functionName = functionObj ? functionObj.FunctionName : '';
          }
          return functionName;
        }
      },
      {
        headerName: this.screenLabels["AgGridValue"]? this.screenLabels["AgGridValue"]: "Value",
        field: "TableFunctionReturn",
        hide: false,
        valueGetter: params => {
          let functionValue = "";
          if (params.data.TableFunctionReturn) {
            if(this.tableFunctionIDNumber) {
            let list = [];
            if(this.tableFunctionReturnValues) {
            this.filteredTableReturns = this.tableFunctionReturnValues.filter(e => e.TableFunctionID == this.tableFunctionIDNumber);
            const values = params.data.TableFunctionReturn.split(",");
            values.forEach(value => {
              if (this.filteredTableReturns) {
                const functionObj = this.filteredTableReturns.filter(val => val.Value === Number(value))[0];
                functionValue = functionObj ? functionObj.Description : '';
                list.push(functionValue);
              }
            });
            return list;
          }
          }

          } else {
            return functionValue;
          }
        },
        sortable: true,


      },
      {

        headerName: this.screenLabels["AgGridActive"]? this.screenLabels["AgGridActive"]: "Active",
        field: "Active",
        hide: true,
        sortable: true,

      }
    ];
  }

  getDropdowns(): void {
    this.searchService
      .smartSearchMultiSiteAPI(null,"Approval Type Site Settings",null,false)
      .then(() => {
        this.configData = this.searchService.searchRecords.sort(this.compare);
       // this.configData = this.configData.filter(data => data.InUse === 1);
        this.selectedApprovalTypeId = this.configData[0] ? this.configData[0].ApprovalTypeID : null;
        this.filteredApprovalType = this.approvalTypeControl.valueChanges.pipe(
          startWith(""),
          map(value => typeof value === 'string' ? value : value?.Tag),
          map(Tag => Tag ? this._filteredApprovalType(Tag) : this.configData.slice())
        );
        this.getDropdownLists();
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }

  displayFn1(selectedoption: number) {
    if(selectedoption) {
    return this.configData.find(x => x.ApprovalTypeID === selectedoption).Tag;
    }
  }
  
  private _filteredApprovalType(value: string) {
    return this.configData.filter(option => option.Tag.toLowerCase().includes(value.toLowerCase()));
  }

  getDropdownLists() {
    this.searchService
      .smartSearchMultiSiteAPI(null, "Approval Group")
      .then(() => {
        const data = this.searchService.searchRecords;
        let result = data;

          this.approvalRequestConfigService.getApprovalGroupBySiteID(0, this.selectedApprovalTypeId).subscribe((res: HttpResponse<any>) => {
              let response = res.body;
              this.approvalGroups = response.concat(result);
              this.setColumnDef();
            }, error => {
              console.log(error);
            });

            this.searchService
              .smartSearchMultiSiteAPI(null, "Table Function")
              .then(() => {
                this.tableFunctions = this.searchService.searchRecords;

                this.searchService
                  .smartSearchMultiSiteAPI(null, "Table Function Return Values")
                  .then(() => {
                    this.tableFunctionReturnValues = this.searchService.searchRecords;

                    this.searchService
                      .smartSearchMultiSiteAPI(null, "Email Template")
                      .then(() => {
                        this.emailTemplates = this.searchService.searchRecords;
                        this.getApprovalConfig();
                      })
                      .catch((err: HttpErrorResponse) => {
                        this.notificationService.failure(err.message);
                      });
                  })
                  .catch((err: HttpErrorResponse) => {
                    this.notificationService.failure(err.message);
                  });
              })
              .catch((err: HttpErrorResponse) => {
                this.notificationService.failure(err.message);
              });
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      })

  }

  compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const descA = a.Tag.toUpperCase();
    const descB = b.Tag.toUpperCase();

    let comparison = 0;
    if (descA > descB) {
      comparison = 1;
    } else if (descA < descB) {
      comparison = -1;
    }
    return comparison;
  }

  // make ag-rid colums fill their container
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  // sets the data of selected row
  onSelectionChanged($event) {
    const selectedRows: any[] = $event.api.getSelectedRows();
    if (selectedRows.length) {
      this.selectedApprovalRequest = selectedRows[0];
      this.isRowSelected = true;
    } else {
      this.isRowSelected = false;
    }

    if (this.selectedApprovalRequest.Active === 0) {
      this.isActive = false;
    } else {
      this.isActive = true;
    }
  }

  onApprovalTypeSelectionChange(event) {
    this.approvalRequestConfigService.getApprovalGroupBySiteID(0, this.selectedApprovalTypeId).subscribe((res: HttpResponse<any>) => {
      let response = res.body;
      this.approvalGroups = response.concat(this.approvalGroups);
      this.setColumnDef();
    }, error => {
      console.log(error);
    });

    this.getApprovalConfig();
  }

  getApprovalConfig() {
    this.rowData = [];
    this.searchService.setPageName("Approval Request Configuration");

    this.searchService
      .smartSearchMultiSiteAPI(null, null, null, null, this.selectedApprovalTypeId)
      .then(() => {
        const configData = this.searchService.searchRecords;
        this.rowData = configData.filter(row => row && row.ApprovalTypeID === this.selectedApprovalTypeId && row.SiteID === this.siteService.selectedSiteID);
        this.approvalTypeData = this.rowData[0];
        this.setApprovalConfig();
        this.isRowSelected = false;
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }

  setApprovalConfig() {
    let approvalRequestConfigs = [];
    const approvalConfig = {
      SiteID: this.siteService.selectedSiteID,
      ApprovalTypeID: this.selectedApprovalTypeId,
      UserID: this.userService.userID,
      ApprovalRequestConfig: approvalRequestConfigs

    }
    this.approvalConfig = approvalConfig;
  }

  setDropdownValue() {
    const approvalGroups = this.approvalGroups.filter(row => row.SiteID);
    const aType = this.configData.filter(data => data.ApprovalTypeID === this.selectedApprovalTypeId)[0];
    const emailTemplates = [];
    this.emailTemplates.forEach(email => {
      if (email.EmailTag === aType.EmailTemplateLookupKey) {
        let isPresent = false;
        emailTemplates.forEach(pemail => {
          if (pemail.EmailTemplateID === email.EmailTemplateID) {
            isPresent = true;
          }
        });
        if (!isPresent) {
          emailTemplates.push(email);
        }
      }
    });
    const tableFunctions = [];
    this.tableFunctions.forEach(fun => {
      if (fun.LookUpKey === aType.EmailTemplateLookupKey) {
        let isTPresent = false;
        tableFunctions.forEach(pfun => {
          if (pfun.TableFunctionID === fun.TableFunctionID) {
            isTPresent = true;
          }
        });
        if (!isTPresent) {
          tableFunctions.push(fun);
        }
      }
    });
    const dropdownValues = {
      approvalGroups: approvalGroups,
      tableFunctions: tableFunctions,
      tableFunctionReturnValues: this.tableFunctionReturnValues,
      emailTemplates: emailTemplates
    }
    console.log("dropdown v"+dropdownValues)
    return dropdownValues;
  }

  newApprovalRequest() {
    const approverEmailTemplates = this.emailTemplates.filter(row => row.EmailTag === this.rowData.EmailTemplateLookupKey);
    this.dialog.open(CreateApprovalRequestConfigurationModalComponent, {
      disableClose: true,
      data: {
        approvalConfig: this.approvalConfig,
        approvalType: this.configData.filter(data => data.ApprovalTypeID === this.selectedApprovalTypeId)[0],
        dropdownValues: this.setDropdownValue(),
        approverEmailTemplates: approverEmailTemplates
      }
    });
  }

  editApprovalRequest() {
    const approverEmailTemplates = this.emailTemplates.filter(row => row.EmailTag === this.rowData.EmailTemplateLookupKey);
    this.dialog.open(UpdateApprovalRequestConfigurationModalComponent, {
      disableClose: true,
      data: { approvalConfig: this.approvalConfig, approvalType: this.configData.filter(data => data.ApprovalTypeID === this.selectedApprovalTypeId)[0], selectedApprovalRequest: this.selectedApprovalRequest, dropdownValues: this.setDropdownValue(), approverEmailTemplates: approverEmailTemplates }
    });
  }

  openApprovalConfig(event) {
    const approverEmailTemplates = this.emailTemplates.filter(row => row.EmailTag === this.rowData.EmailTemplateLookupKey);
    this.dialog.open(ViewApprovalRequestConfigurationModalComponent, {
      disableClose: true,
      data: { approvalConfig: this.approvalConfig, approvalType: this.configData.filter(data => data.ApprovalTypeID === this.selectedApprovalTypeId)[0], selectedApprovalRequest: event.data, dropdownValues: this.setDropdownValue(), approverEmailTemplates: approverEmailTemplates }
    });
  }

  deleteApprovalConfig() {
    let description = this.selectedApprovalRequest.ApprovalRequestConfigDescription;
    let message: string = this.screenLabels["DeleteApprovalRequestConfirmMsg"] ? this.screenLabels["DeleteApprovalRequestConfirmMsg"] : "Are you sure you want to Delete Approval Request Configuration <<id>> ? ";
    const capacityMessage = message.replace("<<id>>", description)
    this.dialogService
      .openConfirmDialog(this.screenLabels["DeleteApprovalRequestConfirmLabel"] ? this.screenLabels["DeleteApprovalRequestConfirmLabel"] : "Delete Approval Request Configuration", capacityMessage)
      .afterClosed()
      .subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.approvalConfig.ApprovalRequestConfig.push({
            ApprovalRequestConfigID: this.selectedApprovalRequest.ApprovalRequestConfigID,
            ApprovalGroupID: this.selectedApprovalRequest.ApprovalGroupID,
            ApprovalPosition: this.selectedApprovalRequest.ApprovalPosition,
            TableFunctionID: this.selectedApprovalRequest.TableFunctionID,
            TableFunctionReturn: this.selectedApprovalRequest.TableFunctionReturn,
            GrpApprovalEmailTemplateID: this.selectedApprovalRequest.GrpApprovalEmailTemplateID,
            RequestModificationAllowed: this.selectedApprovalRequest.RequestModificationAllowed,
            RequesterAutoApprovalEnabled: this.selectedApprovalRequest.RequesterAutoApprovalEnabled,
            Active: 0
          });
          this.approvalRequestConfigService.deleteApprovalRequestConfig(this.approvalConfig);
        }
      });
  }

}
