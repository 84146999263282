import { Component, OnInit } from '@angular/core';
import { MatSnackBarConfig, MatSnackBarRef } from '@angular/material';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.css']
})
export class CustomSnackbarComponent implements OnInit {
  nonRegistered : Boolean = false;
  constructor(
    public snackBarRef: MatSnackBarRef<CustomSnackbarComponent>,
    public userService: UserService
  ) { 
    this.userService.getToggle().subscribe((data) =>{
      if(data.status === true){
        this.nonRegistered = true;
      }
    })
  }

  ngOnInit(): void {
  }

  onNoClick(){
    this.snackBarRef.dismiss();
  }
}
