import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { NotificationService } from "../services/notification.service";
import { SearchService } from "../services/search.service";
import { HttpErrorResponse } from "@angular/common/http";
import { MatDialog } from "@angular/material";
import { DatePipe } from "@angular/common";
import { AssignUpdateRoleModalComponent } from "./modals/assign-update-role/assign-update-role-modal.component";
import { ViewUserModalComponent } from "./modals/view-user/view-user-modal.component";
import { SpinnerService } from "../services/spinner.service";
import { CreateUserModalComponent } from "./modals/create-user/create-user-modal.component";
import { UserService } from '../services/user.service';
import { DialogService } from '../services/dialog.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { SiteService } from "../services/site.service";
import { GridApi } from "ag-grid-community";
import { elementAt } from "rxjs-compat/operator/elementAt";
import { AuthService } from '../services/auth.service';
import { MessageService } from "../services/message.service";
import { UtilityService } from "../services/utility.service";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"],
})
export class UsersComponent implements OnInit {
  @ViewChild("searchField") searchField: ElementRef;
  visible = true;
  selectable = true;
  removable = true;
  chosenRoleList: string[] = [];
  wholeRoleList: string[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  roleCtrl = new FormControl();
  filteredRoles: Observable<string[]>;
  gridOptions: Object;
  protected userSites = [];
  protected loggedInSiteName: string;
  protected hasAccessToCurrentSite: boolean = false;
  protected apiDataLoaded: boolean = false;
  private subscription: Subscription;
  protected reactivate:boolean = false;
  protected useridFromapi: number = 0;
  private apiBody: any = {};
  public defaultColDef : object;
  protected sitesUserHaveAccess = [];

  constructor(
    private notificationService: NotificationService,
    private searchService: SearchService,
    private dialog: MatDialog,
    private spinnerService: SpinnerService,
    private userService: UserService,
    private dialogService: DialogService,
    protected siteService: SiteService,
    private authService: AuthService,
    private utilService: UtilityService,
    protected messageService: MessageService
  ) {
    this.gridOptions = {
      getRowClass: (params) => {
        if (params.data.Active === 0 || params.data.Status === "Suspended") {
          return 'row-inactive';
        }
      }
    }
    this.defaultColDef = {
      resizable: true,
  }
  }
  protected rowData;
  private gridApi: GridApi;
  protected isRowSelected = false;
  protected isActive: boolean = true;
  protected selectedRow: any;
  protected isSuspended: boolean = false;
  userColumnDefs: object = [{}];
  protected appLanguage;
  protected screenLabels: Array<string> = [];
  protected removeSiteAccessLabel: string = "";
  protected bodyData = [];


  ngOnInit(): void {
    this.loggedInSiteName = localStorage.getItem("loggedInSiteName");
    setTimeout(() => {
      this.spinnerService.setIsLoading(true);
    }, 0);
    this.searchService.setPageName("User");


    //labels by message service
    if (localStorage.getItem('UserLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('UserLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "User",
        "User"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('UserLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        this.rowData = this.searchService.searchRecords;
        this.bodyData = this.searchService.searchRecords;
        this.rowData.forEach(element => {
          //element.UserIDRef = element.UserID;
        });
        this.gridApi.paginationGoToPage(0);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });


    this.searchService.searchInitiated.subscribe((event: boolean) => {
      if (event) {
        this.searchService.smartSearchPromise
          .then(() => {
            this.rowData = this.searchService.searchRecords;
            this.rowData.forEach(element => {
              //element.UserIDRef = element.UserID;
            });
            this.gridApi.paginationGoToPage(0);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    });

    // subscribe to site changed
    this.siteService.siteChanged.subscribe((event: boolean) => {
      if (event) {
        this.searchService
          .smartSearchMultiSiteAPI(null)
          .then(() => {
            this.rowData = this.searchService.searchRecords;
            this.rowData.forEach(element => {
              //element.UserIDRef = element.UserID;
            });
            this.gridApi.paginationGoToPage(0);

          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    });


    this.userService.userDataChanged.subscribe(
      (dataChanged: boolean) => {
        if (dataChanged) {
          this.searchService
            .smartSearchMultiSiteAPI(null)
            .then(() => {
              this.rowData = this.searchService.searchRecords;
              this.rowData.forEach(element => {
                //element.UserIDRef = element.UserID;
              });
              this.gridApi.paginationGoToPage(0);
              this.isRowSelected = false;
              this.isActive = true;
              this.isSuspended = false;
              this.hasAccessToCurrentSite = false;
            })
            .catch((err: HttpErrorResponse) => {
              this.notificationService.failure(err.message);
            });
        }
      }
    );

    this.userService.getUserIDFromAPI().subscribe((data) => {
      this.useridFromapi = data.userid;
    });

    //for triggering editmodal from service on createuser screen
    this.userService.editUser.subscribe((event: boolean) => {
      if (event) {
        this.onAssignUpdateRoleButtonClicked(true);
      }
    });

    var message: string =
      this.screenLabels["RemoveSiteAccess"] ? this.screenLabels["RemoveSiteAccess"] : "Remove <<site-name>> access"
    this.removeSiteAccessLabel = message.replace("<<site-name>>", this.loggedInSiteName)
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDefs();
  }

  setColumnDefs() {
    this.userColumnDefs = [
      {
        headerName: this.screenLabels["UserID"] ? this.screenLabels["UserID"] : "UserID",
        field: "UserID",
        sortable: true,
        hide: true,
      },
      {
        headerName: this.screenLabels["OKTAID"] ? this.screenLabels["OKTAID"] : "OKTAID",
        field: "OKTAID",
        sortable: true,
        hide: true,
      },

      {
        headerName: "",
        field: "",
        width: 100,
        cellStyle: { color: "#589bf8" },
        hide: false,
        checkboxSelection: true,
        resizable: false,
        sortable: false,
        lockPosition: true,
      },
      {
        headerName: this.screenLabels["LastName"] ? this.screenLabels["LastName"] : "Last Name",
        field: "LastName",
        sortable: true,
      },
      {
        headerName: this.screenLabels["FirstName"] ? this.screenLabels["FirstName"] : "First Name",
        field: "FirstName",
        sortable: true,
      },
      {
        headerName: this.screenLabels["MiddleName"] ? this.screenLabels["MiddleName"] : "Middle Name",
        field: "MiddleName",
        sortable: true,
      },
      {
        headerName: this.screenLabels["Suffix"] ? this.screenLabels["Suffix"] : "Suffix",
        field: "Suffix",
        sortable: true,
      },
      {
        headerName: this.screenLabels["Email"] ? this.screenLabels["Email"] : "E Mail",
        field: "Email",
        sortable: true,
      },
      {
        headerName: this.screenLabels["Phone"] ? this.screenLabels["Phone"] : "Phone",
        field: "Phone",
        sortable: true,
      },
      {
        headerName: this.screenLabels["Status"] ? this.screenLabels["Status"] : "Status",
        field: "Status",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["BadgeID"] ? this.screenLabels["BadgeID"] : "Badge ID",
        field: "BadgeID",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["Active"] ? this.screenLabels["Active"] : "Active",
        field: "Active",
        hide: false,
        sortable: true,
        cellRenderer: (data) => {
          return data.value ? "Yes" : "No";
        },
      },
      {
        headerName: this.screenLabels["ModifiedBy"] ? this.screenLabels["ModifiedBy"] : "Modified By",
        field: "ModifiedBy",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["ModifiedDate"] ? this.screenLabels["ModifiedDate"] : "Modified Date",
        field: "ModifiedDate",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["CreatedBy"] ? this.screenLabels["CreatedBy"] : "Created By",
        field: "CreatedBy",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["CreatedDate"] ? this.screenLabels["CreatedDate"] : "Created Date",
        field: "CreatedDate",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["CreatedbyName"] ? this.screenLabels["CreatedbyName"] : "Created By Name",
        field: "CreatedByName",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["ModifiedByName"] ? this.screenLabels["ModifiedByName"] : "Modified By Name",
        field: "ModifiedByName",
        hide: true,
        sortable: true,
      },
    ];
  }

  // make ag-rid columns fill their container
  onFirstDataRendered(params) {
    setTimeout(() => {
      this.spinnerService.setIsLoading(false);
    }, 0); params.api.sizeColumnsToFit();
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  // show view user modal
  openUser($event) {
    this.dialog.open(ViewUserModalComponent, {
      disableClose: true,
      data: $event.data
    });
  }

  // ngOnDestroy() {
  //   this.subscription.unsubscribe();
  // }

  // sets the data of selected row
  onSelectionChanged($event) {
    //setting back to its default value
    this.hasAccessToCurrentSite = false;
    const selectedRows = $event.api.getSelectedRows();
    this.selectedRow = $event.api.getSelectedRows();
    if (selectedRows.length) {
      this.isRowSelected = true;
    } else {
      this.isRowSelected = false;
    }
    if (selectedRows[0].Active) {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
    if (selectedRows[0].Status === "Suspended") {
      this.isSuspended = true;
    } else {
      this.isSuspended = false;
    }
    const body = this.gridApi.getSelectedRows()[0];
    this.authService.userSiteListAuthorize(body.Email).subscribe((res: any) => {
      let authResponse = res.body;
      this.userSites = authResponse[0].AvailableSiteData;
      this.userSites.forEach(ele => {
        if (
          (ele.SiteID === this.siteService.selectedSiteID && ele.IsExternal == 1 && ele.UserStatus == "Registered") ||
          (ele.SiteID === this.siteService.selectedSiteID && ele.IsExternal == 0 && (ele.UserStatus == "Registered" || ele.UserStatus == "Expired" ))
        ) {
          this.hasAccessToCurrentSite = true;
        }
      })
      this.sitesUserHaveAccess = this.userSites.filter(ele =>
        (ele.IsExternal == 1 && ele.UserStatus == "Registered") ||
        (ele.IsExternal == 0 && (ele.UserStatus == "Registered" || ele.UserStatus == "Expired"))
      ).map(e => { return e; })

      this.apiDataLoaded = true;
    },
      err => {
        console.log("error", err);
      }
    );
  }

  // invokes function when 'Assign / Edit Role' Button is clicked
  onAssignUpdateRoleButtonClicked($event) {
    if ($event === true) {
      //const filterData = this.bodyData.filter(element => element.UserID == this.userService.takeUserID)[0];
      let filterData;
      this.userService
        .getUserAssociationAPI(this.useridFromapi, this.siteService.selectedSiteID)
        .then(() => {
          filterData = this.userService.getUserDetails()[0];

          this.apiBody.Active = filterData.UserActive;
          this.apiBody.BadgeID = filterData.BadgeID ? filterData.BadgeID : "";
          this.apiBody.CreatedBy = filterData.CreatedBy;
          this.apiBody.CreatedByName = filterData.CreatedByName ? filterData.CreatedByName : null;
          this.apiBody.CreatedDate = filterData.CreatedDate;
          this.apiBody.Email = filterData.Email;
          this.apiBody.EmployeeRef = filterData.EmployeeRef ? filterData.EmployeeRef : null;
          this.apiBody.FirstName = filterData.FirstName;
          this.apiBody.IsSystemUser = filterData.IsSystemUser ? filterData.IsSystemUser : null;
          this.apiBody.LastName = filterData.LastName;
          this.apiBody.MiddleName = filterData.MiddleName;
          this.apiBody.ModifiedBy = this.userService.userID;
          this.apiBody.ModifiedByName = filterData.ModifiedByName ? filterData.ModifiedByName : null;
          this.apiBody.ModifiedDate = filterData.ModifiedDate;
          this.apiBody.OKTAID = filterData.OKTAID;
          this.apiBody.OKTAID_Orig = filterData.OKTAID_Orig ? filterData.OKTAID_Orig : null;
          this.apiBody.PIN = filterData.PIN;
          this.apiBody.Phone = filterData.Phone;
          this.apiBody.PrefEmail = filterData.PrefEmail;
          this.apiBody.PrefFirstName = filterData.PrefFirstName;
          this.apiBody.PrefLastName = filterData.PrefLastName;
          this.apiBody.PrefMiddleName = filterData.PrefMiddleName;
          this.apiBody.PrefPhone = filterData.PrefPhone;
          this.apiBody.Status = filterData.Status;
          this.apiBody.Suffix = filterData.Suffix;
          this.apiBody.UserID = filterData.UserID;

          // filterData.Active = 1;
          // filterData.ModifiedBy = this.userService.userID;
        });
      const dialogRef = this.dialog.open(AssignUpdateRoleModalComponent, {
        disableClose: true,
        data: this.apiBody,
      });
      this.userService.userDataChanged.emit(true);

    }
    else if ($event.type === "click") {
      const dialogRef = this.dialog.open(AssignUpdateRoleModalComponent, {
        disableClose: true,
        data: this.gridApi.getSelectedRows()[0],
      });
    }
  }

  // invokes function when 'Delete User' Button is clicked
  onDisableUserForAllSites() {
    if (this.gridApi.getSelectedRows().length) {
      const body = this.gridApi.getSelectedRows()[0];
      const deleteUserId = body.UserID;
      let siteNames;
      siteNames = this.userSites.filter(ele=>
        (ele.IsExternal==1 && ele.UserStatus=="Registered")||
        (ele.IsExternal==0 && (ele.UserStatus=="Registered" || ele.UserStatus=="Expired"))
      ).map(e => { return e.Name; });
      var message: string =
        this.screenLabels["DeactivateUserForAllSites"] ? this.screenLabels["DeactivateUserForAllSites"] : "De-activate user for all sites"
      const confirmMessage = message.replace("<<site-name>>", this.loggedInSiteName)
      this.dialogService
        .openDeactivationDialog(
          siteNames,
          confirmMessage,
          this.screenLabels["DeactivateUserForAllSitesConfirmMsg"] ? this.screenLabels["DeactivateUserForAllSitesConfirmMsg"] : "Deactivating this user will remove their access to all of the following Site list :",
          this.screenLabels["AreYouSureToProceedMsg"] ? this.screenLabels["AreYouSureToProceedMsg"] : "Are you sure you want to proceed ?"
        )
        .afterClosed()
        .subscribe((data: boolean) => {
          if (data) {
            this.userService.deactivateUserForAllSites(deleteUserId)
              .then(() => {
                this.notificationService.success(this.userService.deleteUserMessage);
                this.userService.userDataChanged.emit(true);
              })
              .catch((err: HttpErrorResponse) =>
                this.notificationService.failure(err.message)
              );
          }
        });
    }
  }

  onDeactivateUserFor1Site() {
    if (this.gridApi.getSelectedRows().length) {
      const body = this.gridApi.getSelectedRows()[0];
      const deleteUserId = body.UserID;
      let siteNames = this.userSites.map(ele => { return ele.Name; });
      var message1 = this.screenLabels["RemoveAccessConfirmLabel"] ? this.screenLabels["RemoveAccessConfirmLabel"] : "Remove <<site-name>> Access";
      var message: string =
        this.screenLabels["RemoveSiteAccessConfirmMsg"] ? this.screenLabels["RemoveSiteAccessConfirmMsg"] : "Are you sure you want to remove the access for <<site-name>> ?"
        const confirmMessage1 = message1.replace("<<site-name>>",this.loggedInSiteName)
        const confirmMessage2 = message.replace("<<site-name>>", this.loggedInSiteName)
      this.dialogService
        .openConfirmDialog(
          confirmMessage1,
          confirmMessage2
        )
        .afterClosed()
        .subscribe((data: boolean) => {
          if (data) {
            this.userService.deactivateSiteUser(deleteUserId)
              .then(() => {
                this.notificationService.success(this.userService.deleteUserMessage2);
                this.userService.userDataChanged.emit(true);
              })
              .catch((err: HttpErrorResponse) =>
                this.notificationService.failure(err.message)
              );
          }
        });
    }
  }

  onSuspendUser() {
    if (this.gridApi.getSelectedRows().length) {
      const body = this.gridApi.getSelectedRows()[0];
      const deleteUserId = body.UserID;
      let siteNames;
      siteNames = this.userSites.filter(ele=>
        (ele.IsExternal==1 && ele.UserStatus=="Registered")||
        (ele.IsExternal==0 && (ele.UserStatus=="Registered" || ele.UserStatus=="Expired"))
      ).map(e => { return e.Name; });
      var message: string =
        this.screenLabels["SuspendAccessConfirmLabel"] ? this.screenLabels["SuspendAccessConfirmLabel"] : "Suspend <<site-name>>  Access Confirmation"
      const confirmMessage3 = message.replace("<<site-name>>", this.loggedInSiteName)
      if(siteNames.length > 0) {
        this.dialogService
        .openDeactivationDialog(
          siteNames,
          confirmMessage3,
          this.screenLabels["SuspendAccessConfirmMsg"] ? this.screenLabels["SuspendAccessConfirmMsg"] : "Suspending this user will remove their access to all of the following Site list :",
          this.screenLabels["AreYouSureToProceedMsg"] ? this.screenLabels["AreYouSureToProceedMsg"] : "Are you sure you want to proceed ?"
        )
        .afterClosed()
        .subscribe((data: boolean) => {
          if (data) {
            this.userService.suspendUser(deleteUserId)
              .then(() => {
                this.notificationService.success(this.userService.deleteUserMessage3);
                this.userService.userDataChanged.emit(true);
              })
              .catch((err: HttpErrorResponse) =>
                this.notificationService.failure(err.message)
              );
          }
        });
      }
      else {
        this.dialogService
        .openConfirmDialog(
          confirmMessage3,
          this.screenLabels["AreYouSureToProceedMsg"] ? this.screenLabels["AreYouSureToProceedMsg"] : "Are you sure you want to proceed ?"
        )
        .afterClosed()
        .subscribe((data: boolean) => {
          if (data) {
            this.userService.suspendUser(deleteUserId)
              .then(() => {
                this.notificationService.success(this.userService.deleteUserMessage3);
                this.userService.userDataChanged.emit(true);
              })
              .catch((err: HttpErrorResponse) =>
                this.notificationService.failure(err.message)
              );
          }
        });
      }
      
    }
  }

  // invokes function when 'Enable User' Button is clicked
  onEnableUser() {
    if (this.gridApi.getSelectedRows().length) {
      const body = this.gridApi.getSelectedRows()[0];
      body.Active = 1;
      body.Status = "Approved";
      body.ModifiedBy = this.userService.userID;
      // body.UserID = body.UserIDRef;
      // body.UserID = this.userService.userID;
      body.BadgeID = body.BadgeID ? body.BadgeID : "";
      this.userService
      .getUserAssociationAPI(body.UserID, this.siteService.selectedSiteID)
      .then(() => {
       let filterData = this.userService.getUserAssociationByID(body.UserID)[0];
       body.UserIntegrations = filterData.UserInegrations;
      })
      this.dialogService
        .openConfirmDialog(
          this.screenLabels["ConfirmUserEnableLabel"] ? this.screenLabels["ConfirmUserEnableLabel"] : "Confirm User Enable",
          this.screenLabels["EnableUserSwitchToEditModeConfirmMsg"] ? this.screenLabels["EnableUserSwitchToEditModeConfirmMsg"] : "Are you sure you want to enable the user & switch to edit mode ?"
        )
        .afterClosed()
        .subscribe((data: boolean) => {
          if (data) {
            this.userService
              .updateUserAssociationsAPI(body)
              .then(() => {
                this.notificationService.success(this.userService.message);
                const dialogRef = this.dialog.open(AssignUpdateRoleModalComponent, {
                  disableClose: true,
                  data: this.gridApi.getSelectedRows()[0],
                });
                this.userService.userDataChanged.emit(true);
              })
              .catch((err: HttpErrorResponse) =>
                this.notificationService.failure(err.message)
              );
          }
        });
    }
  }

  onRemoveSuspension() {
    if (this.gridApi.getSelectedRows().length) {
      const body = this.gridApi.getSelectedRows()[0];
      body.Active = 1;
      body.Status = "Approved";
      body.ModifiedBy = this.userService.userID;
      // body.UserID = body.UserIDRef;
      // body.UserID = this.userService.userID;
      body.BadgeID = body.BadgeID ? body.BadgeID : "";
      this.userService
      .getUserAssociationAPI(body.UserID, this.siteService.selectedSiteID)
      .then(() => {
       let filterData = this.userService.getUserAssociationByID(body.UserID)[0];
       body.UserIntegrations = filterData.UserInegrations;
      })
      this.dialogService
        .openConfirmDialog(
          this.screenLabels["ConfirmUserSuspensionLabel"] ? this.screenLabels["ConfirmUserSuspensionLabel"] : "Confirm Remove Suspension",
          this.screenLabels["ConfirmUserSuspensionMsg"] ? this.screenLabels["ConfirmUserSuspensionMsg"] : "Are you sure you want to remove the suspension of the user ?"
        )
        .afterClosed()
        .subscribe((data: boolean) => {
          if (data) {
            this.userService
              .updateUserAssociationsAPI(body)
              .then(() => {
                this.notificationService.success(this.userService.message);
                this.userService.userDataChanged.emit(true);
              })
              .catch((err: HttpErrorResponse) =>
                this.notificationService.failure(err.message)
              );
          }
        });
    }
  }

  protected onCreateUser() {
    const dialogRef = this.dialog.open(CreateUserModalComponent, {
      disableClose: true,
      data: {},
    });
  }
 
}
