import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { MatExpansionPanel } from "@angular/material";

@Component({
  selector: "app-common-confirm-dialog",
  templateUrl: "./common-confirm-dialog.component.html",
  styleUrls: ["./common-confirm-dialog.component.css"],
})
export class CommonConfirmDialogComponent implements OnInit {
  @ViewChild("freePanel") freezePanel: MatExpansionPanel;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<CommonConfirmDialogComponent>
  ) {}

  ngOnInit() {}
  onMainHeaderClose() {
    this.freezePanel.open();
  }
  closeDialog(value: boolean) {
    this.dialogRef.close(value);
  }

  onConfirmChange(value: boolean) {
    this.closeDialog(value);
  }
}
