import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ViewSelectorService {
  private isMobile: boolean;

  constructor() {
    this.getWidth();
  }

  private getWidth() {
    const width = window.innerWidth;

    if (width <= 768) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  public getIsMobile(): boolean {
    return this.isMobile;
  }
}
