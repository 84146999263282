import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Component, NgZone } from "@angular/core";

declare global {
  interface Window {
    RTCPeerConnection: RTCPeerConnection;
    mozRTCPeerConnection: RTCPeerConnection;
    webkitRTCPeerConnection: RTCPeerConnection;
  }
}

@Injectable({
  providedIn: "root",
})
export class IpService {
  private ipRegex = new RegExp(
    /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/
  );

  constructor(private http: HttpClient, private zone: NgZone) {}
  public getIPAddress() {
    //  return this.http.get("http://api.ipify.org/?format=json");
    return "";
  }

  //   private determineLocalIp() {
  //     window.RTCPeerConnection = this.getRTCPeerConnection();

  //     const pc = new RTCPeerConnection({ iceServers: [] });
  //     pc.createDataChannel('');
  //     pc.createOffer().then(pc.setLocalDescription.bind(pc));

  //     pc.onicecandidate = (ice) => {
  //       this.zone.run(() => {
  //         if (!ice || !ice.candidate || !ice.candidate.candidate) {
  //           return;
  //         }

  //         return this.ix.exec(ice.candidate.candidate)[1];

  //         pc.onicecandidate = () => {};
  //         pc.close();
  //       });
  //     };
  //   }

  //   private getRTCPeerConnection() {
  //     return window.RTCPeerConnection ||
  //       window.mozRTCPeerConnection ||
  //       window.webkitRTCPeerConnection;
  //   }
}
