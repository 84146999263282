import { Component, OnInit } from "@angular/core";
import { SearchService } from "../services/search.service";
import { SiteService } from "../services/site.service";
import { SpinnerService } from "../services/spinner.service";
import { UserService } from "../services/user.service";
import { MessageService } from '../services/message.service';

@Component({
  selector: "app-permissions",
  templateUrl: "./permissions.component.html",
  styleUrls: ["./permissions.component.css"],
})

export class PermissionsComponent implements OnInit {
  protected appLanguage;
  public screenLabels: Array<string> = [];
  constructor(
    protected searchService: SearchService,
    private spinnerService: SpinnerService,
    protected userService: UserService,
    protected siteService:SiteService,
    protected messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.searchService.setPageName("Permissions");
    
//labels by message service
if (localStorage.getItem('SiteAdminPermissionsLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('SiteAdminPermissionsLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Permissions",
      "Permissions"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('SiteAdminPermissionsLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}

}
