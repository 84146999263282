import moment from "moment";
import { UserService } from "src/app/services/user.service";
import { HttpErrorResponse } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { SiteService } from "src/app/services/site.service";
import { ViewSelectorService } from "src/app/services/view-selector.service";
import { MessageService } from "src/app/services/message.service";

export class LimitRequestsAgGridConfig {
  private _limitRequestColumnDefs = [];
  private _applicationUsers = [];
  private isMobile = false;
  get limitRequestColumnDefs() {
    return this._limitRequestColumnDefs;
  }

  constructor(
    private _userService: UserService,
    private _datePipe: DatePipe,
    private _siteService: SiteService,
    private screenLabels,
  ) {
    //this._readAllApplicationUserList();

    this._limitRequestColumnDefs = [
      {
        headerName: "",
        field: "",
        width: 80,
        cellStyle: { color: "#589bf8" },
        hide: false,
        checkboxSelection: true,
        resizable: false,
        sortable: false,
        lockPosition: true,
      },
      {
        headerName: this.screenLabels[
          "AgGridOrgCombinedLimitRequestID"]
          ? this.screenLabels["AgGridOrgCombinedLimitRequestID"]
          : "Request ID",
        field: "OrgCombinedLimitRequestID",
        sortable: true,
        // width: 110,
        filter: true,
        cellStyle: { color: "#589bf8" },
        hide: false,
      },
      {
        headerName: this.screenLabels["AgGridCreatedDate"]
          ? this.screenLabels["AgGridCreatedDate"]
          : "Requested On",
        field: "CreatedDate",
        sortable: true,
        // width: 110,
        filter: true,
        hide: false,
        cellRenderer: (data) => {
          const formattedDate = this._datePipe.transform(
            data.value,
            this._siteService.dataTimeFormat.angularDateFormat
          );
          return formattedDate ? formattedDate : null;

          // return data.value ? moment(data.value).format("MM/DD/YYYY") : null;
        },
      },
      {
        headerName: this.screenLabels["AgGridCreatedBy"]
          ? this.screenLabels["AgGridCreatedBy"]
          : "Requested By",
        field: "CreatedByName",
        sortable: true,
        // width: 110,
        filter: true,
        hide: false,
        // cellRenderer: (data) => {
        //   if (this._applicationUsers.length > 0) {
        //     const user = this._userService
        //       .getApplicationUsers()
        //       .filter((e) => e.UserID === +data.value)[0];
        //     // console.log(entryPoint);
        //     return user
        //       ? user.FirstName +
        //           (user.MiddleName === null ? "" : user.MiddleName) +
        //           " " +
        //           user.LastName
        //       : null;
        //   }
        // },
        cellClass: (params) => {
          return params.data.UserActive === 0 ? "inActiveLinkedField" : "";
        },
      },
      {
        headerName: this.screenLabels["AgGridStartDate"]
          ? this.screenLabels["AgGridStartDate"]
          : "Start Date",
        field: "StartDate",
        sortable: true,
        // width: 110,
        filter: true,
        hide: false,
        cellRenderer: (data) => {
          const formattedDate = this._datePipe.transform(
            data.value,
            this._siteService.dataTimeFormat.angularDateFormat
          );

          // console.log(formattedDate)
          return formattedDate ? formattedDate : null;

          // return data.value ? moment(data.value).format("MM/DD/YYYY") : null;
        },
      },
      {
        headerName: this.screenLabels["AgGridEndDate"]
          ? this.screenLabels["AgGridEndDate"]
          : "End Date",
        field: "EndDate",
        sortable: true,
        // width: 110,
        filter: true,
        hide: false,
        cellRenderer: (data) => {
          const formattedDate = this._datePipe.transform(
            data.value,
            this._siteService.dataTimeFormat.angularDateFormat
          );

          // console.log(formattedDate)
          return formattedDate ? formattedDate : null;

          // return data.value ? moment(data.value).format("MM/DD/YYYY") : null;
        },
      },
      {
        headerName: this.screenLabels["AgGridNumVisitors"]
          ? this.screenLabels["AgGridNumVisitors"]
          : "Limit",
        field: "NumVisitors",
        sortable: true,
        // width: 110,
        filter: true,
        hide: false,
      },
      {
        headerName: this.screenLabels["AgGridNumStatus"]
          ? this.screenLabels["AgGridNumStatus"]
          : "Status",
        field: "Status",
        sortable: true,
        // width: 110,
        filter: true,
        hide: false,
      },
      {
        headerName: this.screenLabels["AgGridParkingLot"]
          ? this.screenLabels["AgGridParkingLot"]
          : "Parking Lot ID",
        field: "ParkingLotID",
        sortable: true,
        // width: 110,
        filter: true,
        hide: true,
      },
      {
        headerName: this.screenLabels["AgGridEntryPointGroupName"]
        ? this.screenLabels["AgGridEntryPointGroupName"]
        : "Entry Point Group ID",
        field: "EntryPointGroupID",
        sortable: true,
        // width: 110,
        filter: true,
        hide: true,
      },
      {
        headerName: this.screenLabels["AgGridUserActive"]
        ? this.screenLabels["AgGridUserActive"]
        : "User Active",
        field: "UserActive",
        sortable: true,
        // width: 110,
        filter: true,
        hide: true,
      },
      {
        headerName: this.screenLabels["AgGridLastName"]
        ? this.screenLabels["AgGridLastName"]
        : "Last Name",
        field: "LastName",
        sortable: true,
        // width: 110,
        filter: true,
        hide: true,
      },
      {
        headerName: this.screenLabels["AgGridFirstName"]
        ? this.screenLabels["AgGridFirstName"]
        : "First Name",
        field: "FirstName",
        sortable: true,
        // width: 110,
        filter: true,
        hide: true,
      },
      {
        headerName: this.screenLabels["AgGridMiddleName"]
        ? this.screenLabels["AgGridMiddleName"]
        : "Middle Name",
        field: "MiddleName",
        sortable: true,
        // width: 110,
        filter: true,
        hide: true,
      },

      {
        headerName:this.screenLabels["CreatedByName"]
        ? this.screenLabels["CreatedByName"]
        : "Created By Name", 
        field: "CreatedByName",
        sortable: true,
        // width: 110,
        filter: true,
        hide: true,
      },
      {
        headerName:this.screenLabels["ModifiedByName"]
        ? this.screenLabels["ModifiedByName"]
        : "Modified By Name", 
        field: "ModifiedByName",
        sortable: true,
        // width: 110,
        filter: true,
        hide: true,
      },
    ];
  }

  // retrieve the list of application users
  // private async _readAllApplicationUserList() {
  //   // call the API to retrieve the list
  //     const promise = this._userService.getAllUsersAPI();

  //     await promise;

  //     promise
  //       .then(() => {
  //         this._applicationUsers = this._userService.getApplicationUsers();
  //       })
  //       .catch((err: HttpErrorResponse) => console.error(err.message));
  // }
}
