import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ApprovalService } from "src/app/services/approval.service";

export interface IApproveRejectEmitter {
  isApproved: boolean;
  data: any;
}

@Component({
  selector: "app-approve-reject-cell-renderer",
  templateUrl: "./approve-reject-cell-renderer.component.html",
  styleUrls: ["./approve-reject-cell-renderer.component.css"],
})
export class ApproveRejectCellRendererComponent
  implements OnInit, ICellRendererAngularComp, OnDestroy {
  constructor(private _approvalService: ApprovalService, private _dialog: MatDialog ) {}

  ngOnInit(): void {}

  public params;
  public canBeActioned: boolean;
  public disabled: boolean = false;

  agInit(params: any): void {
    this.params = params;
    this.canBeActioned = params.data.CanBeActionedOn === 1 ? true : false;
  }

  btnClickedHandler() {
    this.params.clicked(this.params.value);
  }

  ngOnDestroy() {}

  refresh(params?: any): boolean {
    return true;
  }

  onApprove($event) {
    const eventData: IApproveRejectEmitter = {
      isApproved: true,
      data: this.params.data,
    };
    // alert("Approved " + this.params.value);
    this.disabled = true;

    this._approvalService.approvedRejected.emit(eventData);
    this.params.clicked(this.params.value);
  }

  onReject($event) {
    const eventData: IApproveRejectEmitter = {
      isApproved: false,
      data: this.params.data,
    };
    this.disabled = true;
    // alert("Rejected" + this.params.value);
  

    this._approvalService.approvedRejected.emit(eventData);
    // this.params.clicked(this.params.value);
  }

  // afterGuiAttached?(params?: import("ag-grid-community").IAfterGuiAttachedParams): void {
  //   throw new Error("Method not implemented.");
  // }
}
