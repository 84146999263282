import { Injectable } from "@angular/core";
import { SiteService } from "./site.service";

import * as moment from "moment-timezone";
import {
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
  HttpClient,
} from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TimezoneService {
  public currentTime ;
  constructor(private _siteService: SiteService, private _http: HttpClient) {
    this._siteService.siteChanged.subscribe((changed) => {
      // get the system time and get the site offset on site changed
      this.setSiteUTCOffset();
    });
  }

  public async setSiteUTCOffset() {
    try {
      const siteDateTime = await this._getCurrentSiteTimeAPI();
      this.currentTime = siteDateTime;
      this._siteService.siteUTCOffset = this.getUTCOffSet(
        siteDateTime
      ).utcOffset;
    } catch (err) {
      console.error(err);
    }
  }

  private _getCurrentSiteTimeAPI() {
    const apiURL = environment.getAPI("getCurrentSystemTime");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise<string>((resolve, reject) => {
      this._http
        .post(
          apiURL,
          { TimeZone: this._siteService.getSelectedSite().SiteTimeZone },
          httpOptions
        )
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            resolve(success.body.CurrentTime);
          },
          (err: HttpErrorResponse) => {
            reject();
          }
        );
    });

    return promise;
  }

  /**
   * The method returns the UTC offset from the supplied time.It strips off any timezone information which is available with the supplied date
   * @param dateTimeString
   */
  public getUTCOffSet(dateTimeString: string | Date) {
    const sysDateTime = this.stripTimeZoneOffset(moment.tz("GMT").format()); // get the UTC system time
    const inpDateTime = new Date(dateTimeString); // get the input time

    const diffMillisecond = inpDateTime.getTime() - sysDateTime.getTime(); // calculate date time difference in ms
    const isInpTimeLeading = diffMillisecond >= 0; // check if the input time is leading

    // console.log(diffMillisecond);

    let diffHours = diffMillisecond / (1000 * 60 * 60);
    let diffMinutes = (diffHours % 1) * 60;

    diffMinutes = Math.abs((Math.round(diffMinutes / 15) * 15) % 60); // rounding to nearest 15 minutes

    diffHours = Math.abs(Math.trunc(diffHours));

    let utcOffsetStr = isInpTimeLeading ? "+" : "-";
    utcOffsetStr +=
      String(diffHours).padStart(2, "0") + String(diffMinutes).padStart(2, "0");

    const offsetObj = {
      isInpTimeLeading: isInpTimeLeading,
      offsetHours: Math.abs(diffHours),
      offsetMinutes: Math.abs(diffMinutes),
      utcOffset: utcOffsetStr,
    };

    console.log("Offset", offsetObj);
    return offsetObj;
  }

  /**
   * The method strips the timezone info from a date time string
   * @param dateString
   */
  public stripTimeZoneOffset(dateString: string): Date {
    const removedTz = dateString.split("T");
    const datePart = removedTz[0];
    const timePart = removedTz[1]
      .replace("+", "*")
      .replace("-", "*")
      .split("*")[0];

    const dateTime = new Date(datePart + "T" + timePart.replace("Z", ""));
    // console.log(dateTime);

    return dateTime;
  }
  
  // To convert time from string to time with current date
  stringToTime(timeString) {
    var timeTokens = timeString.split(':');
    const d = new Date();
    return new Date(d.getFullYear(), d.getMonth(), d.getDate(), timeTokens[0], timeTokens[1], timeTokens[2]);
  }

  // To convert date to a string
  TimeToString(time) {
    let newTime = time.toString().split(" ")[4];
    return newTime;
  }
}
