export class CallerAuthenticationAgGridConfig {
  private _userColumnDefs = [];

  get userColumnDefs() {
    return this._userColumnDefs;
  }

  constructor(private screenLabels) {

    this._userColumnDefs = [
      {
        headerName: screenLabels["AgGridUserID"] ? screenLabels["AgGridUserID"] : "UserID", 
        field: "UserID",
        sortable: true,
        hide: true,
      },
      {
        headerName: screenLabels["AgGridOKTAID"] ? screenLabels["AgGridOKTAID"] : "OKTAID",
        field: "OKTAID",
        sortable: true,
        hide: true,
      },
      {
        headerName:screenLabels["AgGridEmployeeRef"] ? screenLabels["AgGridEmployeeRef"] : "EmployeeRef",  
        field: "EmployeeRef",
        sortable: true,
        hide: true,
      },
      {
        headerName: screenLabels["AgGridLastName"] ? screenLabels["AgGridLastName"] : "Last Name",
        field: "LastName",
        sortable: true,
      },
      {
        headerName: screenLabels["FirstName"] ? screenLabels["FirstName"] : "First Name",
        field: "FirstName",
        sortable: true,
      },
      {
        headerName: screenLabels["AgGridMiddleName"] ? screenLabels["AgGridMiddleName"] : "Middle Name",
        field: "MiddleName",
        sortable: true,
      },
      {
        headerName: screenLabels["AgGridSuffix"] ? screenLabels["AgGridSuffix"] : "Suffix",
        field: "Suffix",
        sortable: true,
      },
      {
        headerName: screenLabels["AgGridEmail"] ? screenLabels["AgGridEmail"] : "E Mail",
        field: "Email",
        sortable: true,
      },
      {
        headerName: screenLabels["AgGridPhone"] ? screenLabels["AgGridPhone"] : "Phone",
        field: "Phone",
        sortable: true,
      },
      {
        headerName: screenLabels["AgGridStatus"] ? screenLabels["AgGridStatus"] : "Status",
        field: "Status",
        hide: false,
        sortable: true,
      },
      {
        headerName: screenLabels["AgGridActive"] ? screenLabels["AgGridActive"] : "Active",
        field: "Active",
        hide: false,
        sortable: true,
        cellRenderer: (data) => {
          return data.value ? "Yes" : "No";
        },
      },
      {
        headerName: screenLabels["AgGridModifiedBy"] ? screenLabels["AgGridModifiedBy"] : "Modified By",
        field: "ModifiedBy",
        hide: true,
        sortable: true,
      },
      {
        headerName: screenLabels["AgGridModifiedDate"] ? screenLabels["AgGridModifiedDate"] : "Modified Date",
        field: "ModifiedDate",
        hide: true,
        sortable: true,
      },
      {
        headerName: screenLabels["AgGridCreatedBy"] ? screenLabels["AgGridCreatedBy"] : "Created By",
        field: "CreatedBy",
        hide: true,
        sortable: true,
      },
      {
        headerName: screenLabels["AgGridCreatedDate"] ? screenLabels["AgGridCreatedDate"] : "Created Date",
        field: "CreatedDate",
        hide: true,
        sortable: true,
      },
    ];
  }
}
