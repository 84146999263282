import { Component, OnInit, Inject, DoCheck, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatExpansionPanel } from "@angular/material";
import { EntryPointStation } from "../../../models/entry-point-station.model";
import { EntryPoint } from "../../../models/entry-point.model";
import { EntryPointService } from "../../../services/entry-point.service";
import { NotificationService } from "../../../services/notification.service";
import { HttpErrorResponse } from "@angular/common/http";
import { EntryPointZone } from 'src/app/models/entry-point-zone.model';
import { EntryPointZoneService } from 'src/app/services/entry-point-zone.service';
import { SiteService } from "src/app/services/site.service";
import { Printers } from "src/app/models/printers.model";
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: "app-update-entry-point",
  templateUrl: "./view-entry-point-modal.component.html",
  styleUrls: ["./view-entry-point-modal.component.css"],
})

export class ViewEntryPointModalComponent implements OnInit {
  @ViewChild("freezePanel") freezePanel : MatExpansionPanel
  protected step = 0;
  protected tempEntryPointStation: EntryPointStation;
  protected tempEntryPointStations: EntryPointStation[] = [];
  protected entryPoint: EntryPoint;
  protected entryPointStations: EntryPointStation[] = [];
  protected entryPointStationsIndexMarkedForDeletion: number[] = [];
  protected addedEntryPointStations: EntryPointStation[] = [];
  protected entryPointZones : EntryPointZone[] = [];

  protected entryPointDescription: string;
  protected entryPointPhone: string;
  protected entryPointDirection: string;
  protected entryPointZoneID:string;
  protected isEntryPointStationsLoading: boolean = true;

  protected disableOnAddPrinter: boolean = true;
  protected isEnabled: boolean = false;
  protected cloudPrintEnableEP: boolean = false;
  protected showPrinters: boolean = false;
  protected enableHostError: boolean = false;
  protected enableIPError: boolean = false;
  protected printer: Printers;
  protected addedPrinters: Printers[] = [];
  protected disableOnSave: boolean = false;
  protected enableHostErrorEP: boolean = false;
  protected enableIPErrorEP: boolean = false;
  protected isPrinterLoading: boolean = true;
  protected phoneIsUSA:boolean = false;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EntryPoint,
    protected dialogRef: MatDialogRef<ViewEntryPointModalComponent>,
    protected entryPointService: EntryPointService,
    protected notificationService: NotificationService,
    protected entryPointZoneService: EntryPointZoneService,
    protected siteService:SiteService,
    protected messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.entryPoint = { ...this.data };
    console.log(this.data);
    if (localStorage.getItem("isPhoneUSA") === "true") {
      this.phoneIsUSA = true;
    }
    else if (localStorage.getItem("isPhoneUSA") === "false") {
      this.phoneIsUSA = false;
    }  

    //labels by message service
if (localStorage.getItem('ViewEntryPointLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('ViewEntryPointLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "View Entry Point Modal",
      "EntryPoint"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('ViewEntryPointLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    if(this.entryPointZones.length == 0){
      this.entryPointZoneService
          .getAllEntryPointZonesAPI()
          .then(() => {
            this.entryPointZones = [...this.entryPointZoneService.getEntryPointZone()];
            console.log(this.entryPointZones)
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
    }

    // this.entryPointDescription = this.data.Description;
    // this.entryPointPhone = this.data.Phone;
    // this.entryPointDirection = this.data.EmailDirections;

    this.tempEntryPointStation = new EntryPointStation(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    this.printer = new Printers(
      0,
      null,
      null
    );

    // if (this.entryPointService.getEntryPoints().length) {
    //   // Entry Point Data is not loaded
    //   this.isEntryPointStationsLoading = true;
    //   this.entryPointService
    //     .parseGetEndpointAPI()
    //     .then(() => {
    //       this.entryPointStations = this.entryPointService.getEntryPointStations(
    //         this.data.EntryPointID
    //       );
    //       console.log("Entry Point Stations");
    //       console.log(this.entryPointStations);

    //       this.isEntryPointStationsLoading = false;
    //     })
    //     .catch((err: HttpErrorResponse) => {
    //       this.notificationService.failure(err.message);
    //     });
    // } else {
    //   this.isEntryPointStationsLoading = false;
    //   this.entryPointStations = this.entryPointService.getEntryPointStations(
    //     this.data.EntryPointID
    //   );
    // }

    if (this.entryPointService.getEntryPoints().length) {
    this.entryPointService.getEntryPointByID(this.siteService.selectedSiteID,this.data.EntryPointID).subscribe((res: any) => {

      console.log("response",res.body);
      let response = res.body;
      this.entryPointDescription = response.Description;
    this.entryPointPhone = response.Phone;
    this.entryPointDirection = response.EmailDirections;
    this.entryPointZoneID = response.EntryPointZoneID;
    this.cloudPrintEnableEP = response.CloudPrintEnabled;

    this.isPrinterLoading = true;
    this.isEntryPointStationsLoading = true;
    // if(response.Entrypointstations.length > 0){
    //   this.isEntryPointStationsLoading = false;
    // }
    this.entryPointStations = response.EntryPointStations;
    this.isEntryPointStationsLoading = false;
    this.addedPrinters = response.Printers;
    this.isPrinterLoading = false;
          console.log("Printers",this.addedPrinters);
          console.log("Entry Point Stations",this.tempEntryPointStations);
         
    });
  }
  else{
    this.isEntryPointStationsLoading = false;
  }

    if (localStorage.getItem("isCloudPrinting") === "true") {
      this.isEnabled = true;
    }
    else if(localStorage.getItem("isCloudPrinting") === "false"){
      this.isEnabled = false;
    }

    if(this.isEnabled){
      this.showPrinters = true;
    }
    else{
      this.showPrinters = false;
    }
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}

  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  // Add Entry Point Station to UI
  addFieldValue() {
    this.tempEntryPointStation.EntryPointID = this.data.EntryPointID;

    this.addedEntryPointStations.push(this.tempEntryPointStation);
    this.entryPointStations.push(this.tempEntryPointStation);
    this.tempEntryPointStations.push(this.tempEntryPointStation);

    this.tempEntryPointStation = new EntryPointStation(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
  }

  // Remove EntryPointStation  from UI
  deleteFieldValue(index: number) {
    this.entryPointStationsIndexMarkedForDeletion.push(
      this.entryPointStations[index].EntryPointStationID
    );
    console.log(
      "EntryPointStationID to Be Removed: " +
        this.entryPointStations[index].EntryPointStationID
    );
    this.entryPointStations = this.entryPointStations.filter(
      (e) => e !== this.entryPointStations[index]
    );
  }

  //code to freeze Panel
  onMainHeaderClose() {
    this.freezePanel.open();
  }
}
