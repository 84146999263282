import { Component, OnInit, Inject, ViewChild, ElementRef } from "@angular/core";
import { EntryPoint } from "../../../models/entry-point.model";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatSelectChange,
  MatAutocompleteTrigger,
  MatAutocomplete,
  MatChipInputEvent,
  MatAutocompleteSelectedEvent,
  MatSelect,
} from "@angular/material";
import { CreateParkingModalComponent } from "../../../parking-lot/modals/create-parking/create-parking-modal.component";
import { EntryPointService } from "../../../services/entry-point.service";
import { UserService } from "../../../services/user.service";
import { NotificationService } from "../../../services/notification.service";
import { HttpErrorResponse } from "@angular/common/http";
import { SearchService } from "../../../services/search.service";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { EntryPointGroupService } from "../../../services/entry-point-group.service";
import { NgForm } from "@angular/forms";
import { MatExpansionPanel } from "@angular/material";
import { SiteService } from "src/app/services/site.service";
import { FormControl } from "@angular/forms";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { EntryPointGroup } from "src/app/models/entry-point-group.model";
import { UtilityService } from "../../../services/utility.service";
import { RequiredFieldsService } from 'src/app/services/required-fields.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: "app-create-entry-point-group-modal",
  templateUrl: "./create-entry-point-group-modal.component.html",
  styleUrls: ["./create-entry-point-group-modal.component.css"],
})
export class CreateEntryPointGroupModalComponent implements OnInit {
  @ViewChild("freePanel") freezePanel: MatExpansionPanel;
  protected step = 0;
  protected entryPoints: Array<EntryPoint>;
  protected items: Array<EntryPoint> = [];
  protected basket: Array<EntryPoint> = [];
  public groupControl = new FormControl();
  public filteredGroups: Observable<String[]>;
  public selectedGroups: Array<EntryPoint> = ([] = []);
  public selectedGroupNames: Array<String> = ([] = []);
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild("createForm") createForm: NgForm;
  @ViewChild("groupInput") userInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;
  @ViewChild("trigger") autoCompleteTrigger: MatAutocompleteTrigger;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  public isSaveButtonDisabled: boolean = false;
  protected entryGroupNamesValue: Array<EntryPoint> = [];
  protected entryGroupNames: Array<EntryPoint> = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {},
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<CreateEntryPointGroupModalComponent>,
    protected entryPointService: EntryPointService,
    private userService: UserService,
    private notificationService: NotificationService,
    private searchService: SearchService,
    private entryPointGroupService: EntryPointGroupService,
    private siteService: SiteService,
    private utilService: UtilityService,
    protected requiredFieldsService: RequiredFieldsService,
    protected messageService: MessageService,
  ) { }

  ngOnInit(): void {
    //labels by message service
if (localStorage.getItem('CreateEntryPointGroupLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('CreateEntryPointGroupLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Create Entry Point Group Modal",
      "EntryPointGroup"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('CreateEntryPointGroupLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    if (this.entryPointService.getEntryPoints().length != 0) {
      this.items = [...this.entryPointService.getEntryPoints()];
    } else {
      this.entryPointService
        .parseGetEndpointAPI()
        .then(() => {
          this.items = [...this.entryPointService.getEntryPoints()];
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    }
    this.entryPointService.getEntryPointForEntryPointGroup().subscribe(
      (entryGroupNames: Array<EntryPoint>) => {
        this.entryGroupNamesValue = entryGroupNames;
        this.filteredGroups = this.groupControl.valueChanges.pipe(
          map((entryName) => this.filterOnValueChange(entryName))
        );
      },
      (error) => {
        console.error('Failed to load entry points', error);
      }
    );
  }

  private filterOnValueChange(entryName: string): String[] {
    let result: String[] = [];
    //
    // Remove the engineers we have already selected from all engineers to
    // get a starting point for the autocomplete list.
    let allEntryLessSelected = this.entryGroupNamesValue.filter(
      (user) => this.selectedGroups.indexOf(user) < 0
    );
    if (entryName) {
      result = this.filterEntry(allEntryLessSelected, entryName);
    } else {
      result = allEntryLessSelected.map((entry) => entry.Description);
    }
    return result;
  }

  private filterEntry(
    entryList: Array<EntryPoint> = [],
    entryName: String
  ): String[] {
    let filteredEntrysList: Array<EntryPoint> = [];
    const filterValue = entryName.toLowerCase();
    let entryMatchingEntryName = entryList.filter(
      (entry) => entry.Description.toLowerCase().indexOf(filterValue) === 0
    );
    if (entryMatchingEntryName.length) {
      filteredEntrysList = entryMatchingEntryName;
    } else {
      filteredEntrysList = entryList;
    }
    return filteredEntrysList.map((entry) => entry.Description);
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}

   ngAfterViewInit() {
  // setting up the required fields
setTimeout(() => {
  this.requiredFieldsService.setRequiredFields(
    'Add Entry Point Group',
    this.createForm
  );
});
}

  public removeGroup(role): void {
    const index = this.selectedGroups.indexOf(role);
    const templateIndex = this.entryGroupNamesValue.indexOf(role);
    if (index >= 0) {
      this.selectedGroups.splice(index, 1);
      if (templateIndex < 0) {
        this.entryGroupNamesValue.push(role);
      }
      this.resetInputs();
    }
  }

  protected selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    trigger.openPanel();
  }
 
  private resetInputs() {
    // clear input element
    this.userInput.nativeElement.value = "";
    // clear control value
    this.groupControl.setValue(null);
  }
  public addEntry(event: MatChipInputEvent): void {
    // Only add when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (this.matAutocomplete.isOpen) {
      return;
    }
    // Add our engineer
    const value = event.value;
    if ((value || "").trim()) {
      this.selectEntry(value.trim());
    }
    this.resetInputs();
  }
  private selectEntry(entryName) {
    let foundEntry = this.entryGroupNamesValue.filter((item) => item.Description == entryName);
    if (foundEntry.length) {
      this.selectedGroups.push(foundEntry[0]);
      this.selectedGroupNames.push(foundEntry[0].Description);
    }
  }
  public entrySelected(event: MatAutocompleteSelectedEvent): void {
    this.selectEntry(event.option.value);
    this.resetInputs();
  }
  onAutocompleteFocus() {
    this.autoCompleteTrigger._onChange("");
    this.autoCompleteTrigger.openPanel();
  }
  onMainHeaderClose() {
    this.freezePanel.open();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaveChanges() {
    const entryPointGrpName: string = this.createForm.form.value.entryPointGrpName.trim();
    const promise = this.entryPointGroupService.createEntryPointGroupAPI(
      entryPointGrpName,
      true,
      this.selectedGroups
    );

    promise
      .then(() => {
        this.notificationService.success(this.entryPointGroupService.message);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    this.onNoClick();
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  // Code for Drag and Drop

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  checkInput() {
    const trimmedGrpName: string = this.createForm.form.value.entryPointGrpName.trim();
    this.isSaveButtonDisabled = trimmedGrpName === '';
  }
}
