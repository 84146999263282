import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material";
import { Building } from "src/app/models/building.model";
import { DatePipe } from "@angular/common";
import { BuildingService } from "src/app/services/building.service";
import { SiteService } from "src/app/services/site.service";
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: "app-view-building-modal",
  templateUrl: "./view-building-modal.component.html",
  styleUrls: ["./view-building-modal.component.css"],
  providers: [DatePipe],
})
export class ViewBuildingModalComponent implements OnInit {
  protected step = 0;
  
  protected buildingFloorColumnDefs : any ;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      BuildingID: number;
      BuildingNumber: string;
      BuildingName: string;
      OpenDate: Date;
      CloseDate: Date;
      Active: boolean;
    },
    protected datePipe: DatePipe,
    protected buildingService: BuildingService,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<ViewBuildingModalComponent>,
    private siteService: SiteService,
    protected messageService: MessageService,
  ) {}

  ngOnInit(): void {
 
//labels by message service
if (localStorage.getItem('ViewBuildingLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('ViewBuildingLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "View Building Modal",
      "Building"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('ViewBuildingLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDef()
}

setColumnDef(){
  this.buildingFloorColumnDefs = [
    {
      headerName: "Floor",
      field: "floor",
      sortable: true,
      width: 110,
      filter: true,
    },
  ];
}


  // make ag-rid colums fill their container
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number): void {
    this.step = index;
  }

}
