import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatExpansionPanel, MatSelect } from '@angular/material';
import { AgGridAngular } from 'ag-grid-angular';
import { OrganizationModel } from 'src/app/models/organization-model';
import { DialogService } from 'src/app/services/dialog.service';
import { MessageService } from 'src/app/services/message.service';
import { NotificationService } from 'src/app/services/notification.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { ParkingLotService } from 'src/app/services/parking-lot.service';
import { SiteService } from 'src/app/services/site.service';
import { UserService } from 'src/app/services/user.service';
import { UtilityService } from 'src/app/services/utility.service';
import { UpdateOrganizationModalComponent } from '../update-organization-modal/update-organization-modal.component';

@Component({
  selector: 'app-create-organization-modal',
  templateUrl: './create-organization-modal.component.html',
  styleUrls: ['./create-organization-modal.component.css']
})
export class CreateOrganizationModalComponent implements OnInit {
  
  @ViewChild("parkingLimit") parkingLimit: ElementRef;
  @ViewChild("parkingLotGrpDropDown") parkingLotGrpDropDown: any;
  @ViewChild("entryPointZoneLimit") entryPointZoneLimit: ElementRef;
  @ViewChild("entryPointZoneDropDown") entryPointZoneDropDown: any;
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;

  protected step = 0;
  protected hasParkingLoaded: boolean = false;
  protected hasEntryPointZoneLoaded: boolean = false;
  protected organization = { ...this.data };
  protected isSelectedParkingLotGrpRegulated: boolean = true;
  protected isSelectedEntryPointZoneRegulated: boolean = true;
  protected disableNewParkingLotAdd: boolean = true;
  protected disableNewEntryZoneAdd: boolean = true;
  private originalParkingWithLimits: Array<{
    OrganizationParkingLimitID: number;
    Limit: number;
    ParkingLotGroupID: number;
    ParkingLotGroupName: string;
    Active: boolean;
    Regulated: boolean;
  }> = [];

  protected availableParking: Array<{
    ParkingLotGroupID: number;
    ParkingLotGroupName: string;
    Regulated: boolean;
  }> = [];
  protected parkingWithLimits: Array<{
    OrganizationParkingLimitID: number;
    Limit: number;
    ParkingLotGroupID: number;
    ParkingLotGroupName: string;
    Active: boolean;
    Regulated: boolean;
  }> = [];
  protected originalEntryPointZoneWithLimits: Array<{
    OrgEntryPointZoneLimitID: number;
    Limit: number;
    EntryPointZoneID: number;
    EntryPointZoneName: string;
    Active: boolean;
    Regulated: boolean;
  }>;
  protected availableEntryPointZones: Array<{
    EntryPointZoneID: number;
    EntryPointZoneName: string;
    Regulated: boolean;
  }> = [];
  protected entryPointZoneWithLimits: Array<{
    OrgEntryPointZoneLimitID: number;
    Limit: number;
    EntryPointZoneID: number;
    EntryPointZoneName: string;
    Active: boolean;
    Regulated: boolean;
  }> = [];
  //protected minDate = this.utilService.getCurrentSiteDT();
  protected minDate;
  protected organizationModel: OrganizationModel;

  protected parkingEmpty: number = 0;
  protected onHideParking: boolean = true;
  protected zoneEmpty: number = 0;
  protected onHideZone: boolean = true;
  protected disableOnSave: boolean = false;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      OrganizationID: number;
      StartDate: string;
      EndDate: string;
      Internal: number;
      SelfRegAllowed: number;
      CreatedBy: number;
      CreatedDate: string;
      ModifiedBy: number;
      ModifiedDate: string;
      Name: string;
      Active: boolean;
    },
    protected datePipe: DatePipe,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<UpdateOrganizationModalComponent>,
    protected organizationService: OrganizationService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private siteService: SiteService,
    private utilService: UtilityService,
    private userService: UserService,
    protected messageService: MessageService,
  ) {
    
  }

  ngOnInit(): void {
    this.organizationModel = new OrganizationModel(
      0,
      null,
      null,
      1,
      null,
      null,
      null,
      null,
      null,
      null,
      this.userService.userID     
    );

    //labels by message service
    if (localStorage.getItem('CreateOrganizationLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('CreateOrganizationLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "New Organization Modal",
        "Organization"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('CreateOrganizationLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    //for enabling add-if we have 1row
    this.onValidatingAddParkingButton();
    //for enabling add-if we have 1row
    this.onValidatingAddZoneButton();
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  startDateChanged(event) {
    if(event.value !== null) {
        this.minDate =
        new Date(this.organizationModel.StartDate) >
        this.utilService.getCurrentSiteDT()
          ? new Date(this.organizationModel.StartDate) 
          : this.utilService.getCurrentSiteDT();
     } else {
       this.minDate = new Date(event.value);
     }

  }
  endDateChanged(event) {
    // this.minDate = new Date(event.value);
    if(this.organizationModel.StartDate == null ) {
      this.minDate = new Date(event.value);
    } else {
      this.minDate =
        new Date(this.organizationModel.StartDate) >
        this.utilService.getCurrentSiteDT()
          ? new Date(this.organizationModel.StartDate) 
          : this.utilService.getCurrentSiteDT();
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  onValidatingAddParkingButton(){
    //for enabling add-if we have 1row
    if (this.onHideParking && this.parkingWithLimits.length >= 1) {
      this.disableNewParkingLotAdd = true;
    } else if (!this.onHideParking && this.parkingWithLimits.length >= 1){
      this.disableNewParkingLotAdd = false;
    } else if (!this.onHideParking && this.parkingWithLimits.length == 0){
      this.disableNewParkingLotAdd = false;
    } else if (this.onHideParking && this.parkingWithLimits.length == 0){
      this.disableNewParkingLotAdd = true;
    }
  }

  // Remove Organization  from UI
  deleteFieldValueForParking(
    parkingLotGroupID: number,
    parkingGroupName: string,
    index: number,
    regulated: boolean
  ) {
    // const index = this.parkingWithLimits.indexOf(parking[0]);
    this.parkingWithLimits.splice(index, 1);

    this.availableParking.push({
      ParkingLotGroupID: parkingLotGroupID,
      ParkingLotGroupName: parkingGroupName,
      Regulated: regulated,
    });
    if(this.onHideParking){
    this.parkingEmpty = 0
    }

    //for disabling save on empty field with 0 rows
    if(!this.parkingEmpty && this.parkingWithLimits.length==0){
      this.disableOnSave = true;
    }
    else{
      this.disableOnSave = false;
    }
    //for enabling add-if we have 1row
    this.onValidatingAddParkingButton();
  }

  // Remove Empty Organization  from UI
  deleteEmptyFieldValueForParking(){

    // this.arr.splice(index, 1);
     this.onHideParking = false;
     //for enabling add-if we have 1row
    this.onValidatingAddParkingButton();
 
     //this.organizationDropDown.value = ""
     this.parkingEmpty = 0
   }

   onValidatingAddZoneButton(){
    //for enabling add-if we have 1row
    if (this.onHideZone && this.entryPointZoneWithLimits.length >= 1) {
      this.disableNewEntryZoneAdd = true;
    } else if (!this.onHideZone && this.entryPointZoneWithLimits.length >= 1){
      this.disableNewEntryZoneAdd = false;
    } else if (!this.onHideZone && this.entryPointZoneWithLimits.length == 0){
      this.disableNewEntryZoneAdd = false;
    } else if (this.onHideZone && this.entryPointZoneWithLimits.length == 0){
      this.disableNewEntryZoneAdd = true;
    }
  }

  // Remove Organization  from UI
  deleteFieldValueForZone(
    entryPointZoneID: number,
    entryPointZoneName: string,
    index: number,
    regulated: boolean
  ) {
    this.entryPointZoneWithLimits.splice(index, 1);

    this.availableEntryPointZones.push({
      EntryPointZoneID: entryPointZoneID,
      EntryPointZoneName: entryPointZoneName,
      Regulated: regulated,
    });
    if(this.onHideParking){
    this.zoneEmpty = 0
    }

    //for disabling save on empty field with 0 rows
    if(!this.zoneEmpty && this.entryPointZoneWithLimits.length==0){
      this.disableOnSave = true;
    }
    else{
      this.disableOnSave = false;
    }
    //for enabling add-if we have 1row
    this.onValidatingAddZoneButton();
  }

  // Remove Empty Organization  from UI
  deleteEmptyFieldValueForZone(){

    // this.arr.splice(index, 1);
     this.onHideZone = false;
     //for enabling add-if we have 1row
    this.onValidatingAddZoneButton();
 
     //this.organizationDropDown.value = ""
     this.zoneEmpty = 0
   }

  // Add Parking Lot Limit
  onAddParkingLimit() {
    if(!this.onHideParking){
      this.onHideParking = true;
    }
    //for enabling add-if we have 1row
    this.onValidatingAddParkingButton();
    const parkingLotGrpName: string = this.parkingLotGrpDropDown.selected
      ._mostRecentViewValue;
    const parkingLoGrpID: number = +this.parkingLotGrpDropDown.value;

    const isRegulated = this.availableParking.filter(
      (ap) => ap.ParkingLotGroupID === parkingLoGrpID
    )[0].Regulated;
    const limit = isRegulated ? null : this.parkingLimit.nativeElement.value;

    // adding to the parking lot group with limit to parkingWithLimits array
    this.parkingWithLimits.push({
      OrganizationParkingLimitID: 0,
      Limit: limit,
      ParkingLotGroupID: parkingLoGrpID,
      ParkingLotGroupName: parkingLotGrpName,
      Active: true,
      Regulated: isRegulated,
    });

    // removing from available parking
    let index = -1;

    for (let i = 0; i < this.availableParking.length; i++) {
      if (this.availableParking[i].ParkingLotGroupID === parkingLoGrpID) {
        index = i;
      }
    }

    if (index >= 0) {
      this.availableParking.splice(index, 1);
      this.parkingLotGrpDropDown.selectedValue = undefined;
      this.parkingEmpty = 0
    }
    // Clearing the Limit Field
    this.parkingLimit.nativeElement.value = null;
    this.disableNewParkingLotAdd = true;
    this.isSelectedParkingLotGrpRegulated = true;

    this.disableOnSave = false;
  }

  // Add Entry Point Zone limit
  onAddZoneLimit() {
    if(!this.onHideZone){
      this.onHideZone = true;
    }
    //for enabling add-if we have 1row
    this.onValidatingAddZoneButton();
    const entryPointZoneName: string = this.entryPointZoneDropDown.selected
      ._mostRecentViewValue;
    const entryPointZoneID: number = +this.entryPointZoneDropDown.value;

    const isRegulated = this.availableEntryPointZones.filter(
      (ap) => ap.EntryPointZoneID === entryPointZoneID
    )[0].Regulated;
    const limit = isRegulated
      ? null
      : this.entryPointZoneLimit.nativeElement.value;

    // adding to the entry Point Zone with limit to EntryPointZoneLimit array
    this.entryPointZoneWithLimits.push({
      OrgEntryPointZoneLimitID: 0,
      Limit: limit,
      EntryPointZoneID: entryPointZoneID,
      EntryPointZoneName: entryPointZoneName,
      Active: true,
      Regulated: isRegulated,
    });

    // removing from available entry Point Zone
    let index = -1;

    for (let i = 0; i < this.availableEntryPointZones.length; i++) {
      if (
        this.availableEntryPointZones[i].EntryPointZoneID === entryPointZoneID
      ) {
        index = i;
      }
    }

    if (index >= 0) {
      this.availableEntryPointZones.splice(index, 1);
      this.entryPointZoneDropDown.selectedValue = undefined;
      this.zoneEmpty = 0
    }
    // Clearing the Limit Field
    this.entryPointZoneLimit.nativeElement.value = null;
    this.disableNewEntryZoneAdd = true;

    this.disableOnSave = false;
  }
  // Save the Organizational Limits
  onSave($event) {
    
    if(this.parkingEmpty){
    this.onAddParkingLimit();
    }
    if(this.zoneEmpty){
    this.onAddZoneLimit();
    }
    this.dialogService
      .openConfirmDialog(
        this.screenLabels['ConfirmOrgLimitCreate'] ? this.screenLabels['ConfirmOrgLimitCreate'] : "Confirm Organization Limit Create",
        this.screenLabels['ConfirmSaveMsg'] ? this.screenLabels['ConfirmSaveMsg'] : "Are you sure you want to save the changes ?" 
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        const name = this.organizationModel.Name.trim() === this.organizationModel.Name ? this.organizationModel.Name : this.organizationModel.Name.trim();
        this.organizationModel.Name = name ;
        if (confirm) {
          this.parkingWithLimits.forEach((element) => {
            const parkingLimitIndex = this.originalParkingWithLimits.findIndex(
              (f) => f.ParkingLotGroupID == element.ParkingLotGroupID
            );
            if (parkingLimitIndex >= 0) {
              element.OrganizationParkingLimitID = this.originalParkingWithLimits[
                parkingLimitIndex
              ].OrganizationParkingLimitID;
              this.originalParkingWithLimits.splice(parkingLimitIndex, 1);
            }
          });

          const differenceParking = this.originalParkingWithLimits.filter(
            (x) => !this.parkingWithLimits.includes(x)
          );

          // unlinking
          differenceParking.forEach((item) => {
            item.Active = false;
            this.parkingWithLimits.push(item);
          });

          this.entryPointZoneWithLimits.forEach((element) => {
            const zoneLimitIndex = this.originalEntryPointZoneWithLimits.findIndex(
              (f) => f.EntryPointZoneID == element.EntryPointZoneID
            );
            if (zoneLimitIndex >= 0) {
              element.OrgEntryPointZoneLimitID = this.originalEntryPointZoneWithLimits[
                zoneLimitIndex
              ].OrgEntryPointZoneLimitID;
              this.originalEntryPointZoneWithLimits.splice(zoneLimitIndex, 1);
            }
          });

          const differenceZone = this.originalEntryPointZoneWithLimits.filter(
            (x) => !this.entryPointZoneWithLimits.includes(x)
          );

          // unlinking
          differenceZone.forEach((item) => {
            item.Active = false;
            this.entryPointZoneWithLimits.push(item);
          });
          
         this.data.OrganizationID = this.organizationService.createOrganizationLimit(
         this.organizationModel,
         this.parkingWithLimits,
         this.entryPointZoneWithLimits,
         )
         if(this.data.OrganizationID !=0){
           this.onNoClick();
         }
        }
           
            
      });
  }
 
  isNameEmpty(): boolean {
    return !this.organizationModel.Name || !this.organizationModel.Name.trim();
  }

  nameFormControl = new FormControl("", [
    Validators.required,  // Required validation
    Validators.pattern(/^[a-zA-Z\s-][a-zA-Z\s0-9-]*$/),  // Updated pattern validation
    this.specialCharacterValidator(),
  ]);

  // Custom validator function
  specialCharacterValidator() {
    return (control: FormControl) => {
      const value = control.value;
      if (!value) {
        return null; // No validation for empty values
      }

      // Check for spaces or hyphens followed by spaces
      if (/^\s/.test(value)) {
        return { specialCharactersNotAllowed: true };
      }
  
      const isValid = /^[a-zA-Z\s0-9]*(-[a-zA-Z\s0-9]+)*$/.test(value);
  
      if (isValid && !/^-/.test(value)) {
      return null;
    };
  return { specialCharactersNotAllowed: true };
    }
  }

  //method to create organization
  onCreateOrganization() {

    const name = this.organizationModel.Name.trim() === this.organizationModel.Name ? this.organizationModel.Name : this.organizationModel.Name.trim();
    this.organizationModel.Name = name ;

    this.organizationModel.Internal ? 1: 0;
    this.organizationModel.SelfRegAllowed ? 1 : 0;
    this.organizationService.createOrganizationLimitAPI(
      this.organizationModel,
      [],
      []
        ).then(() => {      
        this.organizationModel.OrganizationID = this.organizationService.createdOrganizationID;
        if(this.organizationModel.OrganizationID > 0){
          this.organizationService.getOrganizationByIDAPI(this.organizationModel.OrganizationID)
          .then(() => {
            const details = this.organizationService.getOrganizationByIDResponse()[0];
  
            this.availableParking = details.unlinkedParkingLotGroupArray;
            this.parkingWithLimits = details.OrganizationParkingLimitArray;
            this.availableEntryPointZones = details.unlinkedEntryPointZone;
            this.entryPointZoneWithLimits = details.OrganizationEntryPointLimit;
            this.originalParkingWithLimits = [
              ...details.OrganizationParkingLimitArray,
            ];
            this.originalEntryPointZoneWithLimits = [
              ...details.OrganizationEntryPointLimit,
            ];
    
            this.hasParkingLoaded = true;
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
        }
      });
  
  }

  // On Parking Lot Group Change
  onParkingLotGrpSelectionChange(event: MatSelect) {
    const parkingLotGrpID = +event.value;
    this.parkingEmpty = +event.value;
    this.disableNewParkingLotAdd = false;
    this.disableOnSave = false;
    const isRegulated = Number(
      this.availableParking.filter(
        (ap) => ap.ParkingLotGroupID === parkingLotGrpID
      )[0].Regulated
    );
    this.isSelectedParkingLotGrpRegulated = isRegulated == 0 ? false : true;
  }

  //On Entry Point Zone Change
  onEntryPointZoneSelectionChange(event: MatSelect) {
    const entryPointZoneID = +event.value;
    this.zoneEmpty = +event.value;
    this.disableNewEntryZoneAdd = false;
    this.disableOnSave = false;
    const isRegulated = Number(
      this.availableEntryPointZones.filter(
        (ap) => ap.EntryPointZoneID === entryPointZoneID
      )[0].Regulated
    );
    this.isSelectedEntryPointZoneRegulated = isRegulated == 0 ? false : true;
  }

  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }

}
