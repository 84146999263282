import { MasterDataComponent } from "./../master-data.component";
import { Component, Inject } from "@angular/core";
import { UserService } from "src/app/services/user.service";
import { SiteService } from "src/app/services/site.service";
import { MessageService } from 'src/app/services/message.service';
export interface DialogData {}

@Component({
  selector: "app-master-data-mobile",
  templateUrl: "./master-data.component.mobile.html",
  styleUrls: ["./master-data.component.mobile.css"],
})
export class MasterDataComponentMobile extends MasterDataComponent {
  constructor(
    userService: UserService,
    siteService: SiteService,
    messageService : MessageService
  ) {
    super(userService, 
      siteService, 
      messageService);
  }
}
