import { Component, OnInit, Inject, DoCheck, ViewChild } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatExpansionPanel,
} from "@angular/material";
import { FormGroup, FormControl, Validators, NgForm } from "@angular/forms";
import { EntryPointStation } from "../../../models/entry-point-station.model";
import { EntryPoint } from "../../../models/entry-point.model";
import { EntryPointService } from "../../../services/entry-point.service";
import { NotificationService } from "../../../services/notification.service";
import { ParkingLot } from "../../../models/parking-lot.model";
import { ParkingLotGroupService } from "../../../services/parking-lot-group.service";
import { ParkingLotGroup } from "../../../models/parking-lot-group.model";
import { DialogService } from "../../../services/dialog.service";
import { SiteService } from "src/app/services/site.service";
import { RequiredFieldsService } from 'src/app/services/required-fields.service';
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-update-parking-group",
  templateUrl: "./update-parking-group-modal.component.html",
  styleUrls: ["./update-parking-group-modal.component.css"],
})
export class UpdateParkingGroupModalComponent implements OnInit {
  protected step = 0;
  protected parkingLotGroups: ParkingLotGroup;
  //nameFormControl = new FormControl("", [Validators.required]);
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  @ViewChild("editForm") editForm: NgForm;
  protected appLanguage;
  public screenLabels: Array<string> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ParkingLotGroup,
    protected dialogRef: MatDialogRef<UpdateParkingGroupModalComponent>,
    protected entryPointService: EntryPointService,
    protected notificationService: NotificationService,
    protected parkingLotGroupService: ParkingLotGroupService,
    protected dialogService: DialogService,
    private siteService: SiteService,
    protected requiredFieldsService : RequiredFieldsService,
    protected messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.parkingLotGroups = { ...this.data };

    //labels by message service
    if (localStorage.getItem('UpadateParkingLotGroupLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('UpadateParkingLotGroupLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Update Parking Lot Group Modal",
        "ParkingLotGroup"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('UpadateParkingLotGroupLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  ngAfterViewChecked() {
    // setting up the required fields
    this.requiredFieldsService.setRequiredFields(
     'Edit Parking Lot Group',
      this.editForm
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  // Method to Update Parking Lot Group

  onSaveChanges() {
    this.dialogService
      .openConfirmDialog(
        this.screenLabels['CanceUpdateParkingLotGroupConfirmLabellBtn'] ? this.screenLabels['UpdateParkingLotGroupConfirmLabel'] : "Confirm Parking Lot Group Update", 
        this.screenLabels['UpdateParkingLotGroupConfirmMsg'] ? this.screenLabels['UpdateParkingLotGroupConfirmMsg'] : "Are you sure you want to save the changes ?" 
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        const name = this.parkingLotGroups.Name.trim() === this.parkingLotGroups.Name ? this.parkingLotGroups.Name : this.parkingLotGroups.Name.trim();
        this.parkingLotGroups.Name = name ;
        if (confirm) {
          this.parkingLotGroupService
            .updateParkingLotGroup(this.parkingLotGroups)
            .then(() => {
              this.notificationService.success(
                this.screenLabels['ParkingLotGroupEditSuccessMsg'] ? this.screenLabels['ParkingLotGroupEditSuccessMsg'] : "Parking Lot Group Updated Successfully." 
              );
            });
        }
      });

    this.onNoClick();
  }

  isNameEmpty(): boolean {
    return !this.parkingLotGroups.Name || !this.parkingLotGroups.Name.trim();
  }

  nameFormControl = new FormControl("", [
    Validators.required,  // Required validation
    Validators.pattern(/^[a-zA-Z\s-][a-zA-Z\s0-9-]*$/),  // Updated pattern validation
    this.specialCharacterValidator(),
  ]);

  // Custom validator function
  specialCharacterValidator() {
    return (control: FormControl) => {
      const value = control.value;
      if (!value) {
        return null; // No validation for empty values
      }

      // Check for spaces or hyphens followed by spaces
      if (/^\s/.test(value)) {
        return { specialCharactersNotAllowed: true };
      }
  
      const isValid = /^[a-zA-Z\s0-9]*(-[a-zA-Z\s0-9]+)*$/.test(value);
  
      if (isValid && !/^-/.test(value)) {
      return null;
    };
  return { specialCharactersNotAllowed: true };
    }
  }

  // getNameRequiredErrorMessage() {
  //   return this.nameFormControl.hasError("required")
  //     ? "You must enter a value"
  //     : " ";
  // }

  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }
}
