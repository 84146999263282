import { Component, OnInit, Inject, DoCheck } from "@angular/core";

import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { EntryPointStation } from "../../../models/entry-point-station.model";
import { EntryPoint } from "../../../models/entry-point.model";
import { EntryPointService } from "../../../services/entry-point.service";
import { NotificationService } from "../../../services/notification.service";
import { HttpErrorResponse } from "@angular/common/http";
import { EntryPointZone } from "src/app/models/entry-point-zone.model";
import { EntryPointZoneService } from "src/app/services/entry-point-zone.service";
import { SearchService } from "src/app/services/search.service";

import * as $ from "jquery";
import moment from "moment";
import console from "console";
import { PassService } from "src/app/services/pass.service";
import { SiteService } from "src/app/services/site.service";
import { UtilityService } from "src/app/services/utility.service";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-view-pass-audit-modal",
  templateUrl: "./view-pass-transaction-modal.component.html",
  styleUrls: ["./view-pass-transaction-modal.component.css"],
})
export class ViewPassTransactionModalComponent implements OnInit {
  protected step = 0;
  protected passID: number;
  protected hasPassTransDataLoaded: boolean = false;
  protected visitorName: string;
  protected passTransactions: Array<any>;
  protected appLanguage;
  protected lastTimeStamp: string;
  public screenLabels: Array<string> = [];


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ViewPassTransactionModalComponent>,
    private searchService: SearchService,
    private passService: PassService,
    protected siteService:SiteService,
    protected utilService: UtilityService,
    protected messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.passID = this.data.PassID;
    this.readPassTransactionHistory();
    
//labels by message service
if (localStorage.getItem('ViewPassTransactionLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('ViewPassTransactionLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "View Pass Transaction Modal",
    "PassTransaction"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('ViewPassTransactionLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
   // console.log(JSON.stringify(this.screenLabels))
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
   
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  protected collapsePassTransactionTable($event, id) {
    // console.log($event, id);

    $(".log-row-" + id).toggle();
    // $(".audit-caret-" + id).toggleClass("flip-caret");

    $(".transaction-caret-" + id).text(function (_, value) {
      return value == "-" ? "+" : "-";
    });
  }

  private async readPassTransactionHistory() {
    this.passTransactions = await this.passService.getPassTransactionAPI(
      this.passID
    );

    this.visitorName =
      this.passTransactions[0].VisitorFirstName +
      " " +
      this.passTransactions[0].VisitorLastName;

    this.passTransactions[0].PassTransactionHistory = this.passTransactions[0].PassTransactionHistory.sort(
      (a, b) => {
        return b.PassTransactionID - a.PassTransactionID;
      }
    );

    this.hasPassTransDataLoaded = true;
    // console.log(await this.passService.getPassTransactionAPI(this.passID));
  }
}
