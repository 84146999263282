import { Component, OnInit } from '@angular/core';
import { GridApi } from 'ag-grid-community';
import { DomainService } from '../services/domain.service';
import { MatDialog } from '@angular/material';
import { DialogService } from '../services/dialog.service';
import { LabelService } from '../services/label.service';
import { NotificationService } from '../services/notification.service';
import { SearchService } from '../services/search.service';
import { SiteService } from '../services/site.service';
import { UserService } from '../services/user.service';
import { EditDomainComponent } from './modals/edit-domain/edit-domain.component';
import { CreateDomainComponent } from './modals/create-domain/create-domain.component';
import { ViewDomainComponent } from './modals/view-domain/view-domain.component';
import { DomainModel } from '../models/domain-type.model';
import { HttpErrorResponse } from '@angular/common/http';
import { SpinnerService } from '../services/spinner.service';
import { MessageService } from '../services/message.service';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-domain',
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.css']
})
export class DomainComponent implements OnInit {

  protected isRowSelected: boolean;
  protected rowData: DomainModel[];
  protected rowClassRules;
  private paginationPageSize;
  protected domainColumnDefs = [];
  private selectedDomain: DomainModel;
  private gridApi: GridApi;
  protected isActive: boolean = false;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  capacityMessage: string;
  public defaultColDef : object;

  constructor(
    protected dialog: MatDialog,
    protected dialogService: DialogService,
    protected notificationService: NotificationService,
    protected searchService: SearchService,
    protected domainService: DomainService,
    protected userService: UserService,
    private siteService: SiteService,
    protected labelService: LabelService,
    private spinnerService: SpinnerService,
    protected messageService: MessageService,
  ) { 
    this.paginationPageSize = 50;
    // set row styles
    this.rowClassRules = {
      "row-inactive": (params) => {
        // console.log(params);
        var condition = params.data.Active === 0;
        return condition;
      },
    };
    this.defaultColDef = {
      resizable: true,
  }
  }

  ngOnInit(): void {

    //labels by message service
    if (localStorage.getItem('DomainsLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('DomainsLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Domain",
        "Domain"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('DomainsLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    setTimeout(() => {
      this.spinnerService.setIsLoading(true);
    }, 0);
    this.searchService.setPageName("Domain");

    // initialize labels
    this.labelService.initScreenLabels(
      this.siteService.selectedSiteID,
      this.searchService.getPageName(),
      "Domain"
    );

    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        this.isRowSelected = false;
        this.rowData = this.searchService.searchRecords;
        this.gridApi.paginationGoToPage(0);
        setTimeout(() => {
          this.spinnerService.setIsLoading(false);
        }, 0); 
        console.log(this.rowData);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
        setTimeout(() => {
          this.spinnerService.setIsLoading(false);
        }, 0); 
      });

    this.searchService.searchInitiated.subscribe((eventData: boolean) => {
      if (eventData) {
        this.searchService.smartSearchPromise
          .then(() => {
            this.rowData = this.searchService.searchRecords;
            this.gridApi.paginationGoToPage(0);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    });

    // subscribe to site changed
    this.siteService.siteChanged.subscribe((event: boolean) => {
      if(event){
        this.searchService
        .smartSearchMultiSiteAPI(null)
        .then(() => {
          this.rowData = this.searchService.searchRecords;   
          this.gridApi.paginationGoToPage(0);      
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
      }
    });
    
    this.domainService.domainDataChanged.subscribe((e) => {
      this.searchService
        .smartSearchMultiSiteAPI(null)
        .then(() => {
          this.rowData = this.searchService.searchRecords;
          this.gridApi.paginationGoToPage(0);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    });

    
  }

  setColumnDef() {
  this.domainColumnDefs = [
    {
      headerName: "",
      field: "",
      width: 80,
      cellStyle: { color: "#589bf8" },
      hide: false,
      checkboxSelection: true,
      resizable: false,
      sortable: false,
      lockPosition: true,
    },
    {
      headerName: this.screenLabels["DomainID"] ? this.screenLabels["DomainID"]: "Domain ID" ,
      field: "DomainID",
      hide: true,
      sortable: true,
    },
    {
      headerName:this.screenLabels["Domain"] ? this.screenLabels["Domain"]: "Domain Name" ,
      field: "Domain",
      hide: false,
      sortable: true,
    },
    {
      headerName:this.screenLabels["Description"] ? this.screenLabels["Description"]: "Description" ,
      field: "Description",
      hide: false,
      sortable: true,
    },
    {
      headerName:this.screenLabels["Category"] ? this.screenLabels["Category"]: "Category" ,
      field: "DisplayDomainCategory",
      hide: false,
      sortable: true,
    },
    {
      headerName:this.screenLabels["Active"] ? this.screenLabels["Active"]: "Active" ,
      field: "Active",
      hide: true,
      sortable: true,
    },
    {
      headerName:this.screenLabels["ModifiedDate"] ? this.screenLabels["ModifiedDate"]: "Modified Date" ,
      field: "ModifiedDate",
      hide: true,
      sortable: true,
    },
    {
      headerName:this.screenLabels["ModifiedBy"] ? this.screenLabels["ModifiedBy"]: "Modified By" ,
      field: "ModifiedBy",
      hide: true,
      sortable: true,
    },
    {
      headerName:this.screenLabels["CreatedDate"] ? this.screenLabels["CreatedDate"]: "Created Date" ,
      field: "CreatedDate",
      hide: true,
      sortable: true,
    },
    {
      headerName:this.screenLabels["CreatedBy"] ? this.screenLabels["CreatedBy"]: "Created By" ,
      field: "CreatedBy",
      hide: true,
      sortable: true,
    },
    {
      headerName:this.screenLabels["CreatedByName"] ? this.screenLabels["CreatedByName"]: "Created By Name" ,
      field: "CreatedByName",
      hide: true,
      sortable: true,
    },
    {
      headerName:this.screenLabels["ModifiedByName"] ? this.screenLabels["ModifiedByName"]: "Modified By Name" ,
      field: "ModifiedByName",
      hide: true,
      sortable: true,
    },
  ];
}

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDef()
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  // make ag-rid colums fill their container
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  // sets the data of selected row
  onSelectionChanged($event) {
    const selectedRows: DomainModel[] = $event.api.getSelectedRows();
    if (selectedRows.length) {
      this.selectedDomain = selectedRows[0];
      this.isRowSelected = true;
    } else {
      this.isRowSelected = false;
    }
    if (this.selectedDomain.Active === 0) {
      this.isActive = false;
    } else {
      this.isActive = true;
    }
  }

  // open modal for editing a domain
  onEditDomain() {
    this.dialog.open(EditDomainComponent, {
      disableClose: true,
      data: this.selectedDomain,
    });
  }

  // open modal for creating a new domain
  onCreateNewDomain() {
    this.dialog.open(CreateDomainComponent, {
      disableClose: true,
      data: {},
    });
  }

  // open selected domain
  openDomain($event) {
    var selectedDomain: DomainModel = $event.data;
    this.dialog.open(ViewDomainComponent, {
      disableClose: true,
      data: selectedDomain,
    });
  }

  //Disable domain
  onDeleteDomain() {
    var name = this.selectedDomain.Domain;
    var message: string = this.screenLabels["DomainDeleteMsg"] ? this.screenLabels["DomainDeleteMsg"]: "Are you sure you want to Delete Domain <<id>> ? "
    this.capacityMessage = message.replace("<<id>>",name)
    this.dialogService
      .openConfirmDialog(this.screenLabels["DeleteDomain"] ? this.screenLabels["DeleteDomain"]: "Delete Domain", this.capacityMessage)
      .afterClosed()
      .subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.domainService
            .deleteDomainAPI(this.selectedDomain)
            .then(() => {});
        }
      });
  }
}
