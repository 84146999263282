import { Component, OnInit, Inject, ViewChild, ElementRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatExpansionPanel, MatAutocompleteTrigger, MatAutocomplete, MatChipInputEvent, MatAutocompleteSelectedEvent } from "@angular/material";
import { EntryPoint } from "../../../models/entry-point.model";
import { UserService } from "../../../services/user.service";
import { NotificationService } from "../../../services/notification.service";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { RoleMapService } from "../../../services/role-map.service";
import { HttpErrorResponse } from "@angular/common/http";
import { OrganizationService } from "../../../services/organization.service";
import { DialogService } from "../../../services/dialog.service";
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map } from 'rxjs/operators';
import { Team } from 'src/app/models/team.model';
import { TeamService } from 'src/app/services/team-service';
import { TemplateCategory } from 'src/app/models/template-category,model';
import { UserTeam } from 'src/app/models/userTeam.model';
import { SiteService } from "src/app/services/site.service";
import { MessageService } from 'src/app/services/message.service';
// export interface UserTeam{
//   TeamID:number;
//   Name:string;
//   UserTeamID:number;
//   Active: number
//   OrganizationID:number
//   TemplateCategoryTeamList: TemplateCategory[],
//   OverrideLocks: number
// }
@Component({
  selector: "app-assign-update-team-modal",
  templateUrl: "./assign-update-team-modal.component.html",
  styleUrls: ["./assign-update-team-modal.component.css"],
})

export class AssignUpdateTeamModalComponent implements OnInit {
  @ViewChild("freezePanel") freezePanel :MatExpansionPanel
  @ViewChild("trigger") autoCompleteTrigger: MatAutocompleteTrigger;

  public orgTeams: UserTeam[] = [];
  public chipSelectedTeam: UserTeam[] = [];
  public filteredTeams: Observable<String[]>;
  private allowFreeTextAddUser = false;
  public orriginalUserTeam : UserTeam[] = [];

  public selectedGroupNames: Array<String> = ([] = []);;

  public teamControl = new FormControl([]);
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('teamInput') teamInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  protected step = 0;
  protected entryPoints: Array<EntryPoint>;
  protected rolesLoaded: boolean = false;
  protected organizationsLoaded: boolean = false;

  protected roleItems: Array<{
    RoleID: number;
    RoleName: string;
    Active: number;
  }> = [];
  protected roleBasket: Array<{
    RoleID: number;
    RoleName: string;
    Active: number;
    UserRoleID: number;
  }> = [];

  protected originalRoleBasket: Array<{
    RoleID: number;
    RoleName: string;
    Active: number;
    UserRoleID: number;
  }> = [];

  protected organizationItems: Array<{
    OrganizationID: number;
    Name: string;
    Active: number;
  }> = [];

  protected organizationBasket: Array<{
    UserOrganizationID: number;
    OrganizationID: number;
    Name: string;
    Active: number;
  }> = [];

  protected originalOrganizationBasket: Array<{
    UserOrganizationID: number;
    OrganizationID: number;
    Name: string;
    Active: number;
  }> = [];
  protected phoneIsUSA:boolean = false;
  protected appLanguage;
  public screenLabels: Array<string> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      Active: number;
      CreatedBy: number;
      CreatedDate: string;
      Email: string;
      EmployeeRef: string;
      FirstName: string;
      LastName: string;
      MiddleName: string;
      ModifiedBy: number;
      ModifiedDate: string;
      OKTAID: string;
      Phone: string;
      Status: string;
      Suffix: string;
      UserID: number;
    },
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<AssignUpdateTeamModalComponent>,
    protected roleMapService: RoleMapService,
    private notificationService: NotificationService,
    protected organizationService: OrganizationService,
    private userService: UserService,
    private dialogService: DialogService,
    private teamService: TeamService,
    protected siteService: SiteService,
    protected messageService: MessageService,

  ) {}

  ngOnInit(): void {
    console.log(this.data);
    if (localStorage.getItem("isPhoneUSA") === "true") {
      this.phoneIsUSA = true;
    }
    else if (localStorage.getItem("isPhoneUSA") === "false") {
      this.phoneIsUSA = false;
    }
    
    this.loadTeams()  ;
    console.log(this.orgTeams);

    //labels by message service
if (localStorage.getItem('AssignUpdateTeamLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('AssignUpdateTeamLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Assign User Team Modal",
      "User"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('AssignUpdateTeamLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}
    
    this.teamControl.setValue(null);
    this.teamService
    .getUserTeamAPI(this.data.UserID)
    .then(() => {
      // load the organization basket

      this.orriginalUserTeam =  this.teamService.userTeams.filter((f) =>f.OrganizationID == this.userService.organizationID  && f.AutoAddAllOrgUsers == 0);
      var chipSelectedTeams : UserTeam[] = this.teamService.userTeams.filter((f) =>f.OrganizationID == this.userService.organizationID &&   f.Active && f.AutoAddAllOrgUsers == 0);

      // Remove duplicates from chipSelectedTeams
      const uniqueChipSelectedTeams = Array.from(new Set(chipSelectedTeams.map(team => team.Name)))
      .map(name => chipSelectedTeams.find(team => team.Name === name));

      this.chipSelectedTeam = uniqueChipSelectedTeams.map((team) => ({
        UserTeamID : 0,
        TeamID : team.TeamID,
        Active: 0,
        Name: team.Name,
        OrganizationID: team.OrganizationID,
        TemplateCategoryTeamList:[],
        OrganizationFunctionTeamList:[],
        AutoAddAllCategories:team.AutoAddAllCategories,
        AutoAddAllOrgUsers: team.AutoAddAllOrgUsers,        
        ModifiedBy: team.ModifiedBy,
        ModifiedDate: team.ModifiedDate,
        ModifiedByName: team.ModifiedByName,
        CreatedBy: team.CreatedBy,
        CreatedDate: team.CreatedDate, 
        CreatedByName:team.CreatedByName,
      }));

      this.chipSelectedTeam.forEach(element => {
        var index = -1
        const length = this.orgTeams.filter((f) => f.TeamID == element.TeamID).length
        if(length > 0) index = this.orgTeams.indexOf( this.orgTeams.filter((f) => f.TeamID == element.TeamID)[0])
        if( index >= 0){
          this.orgTeams.splice(index, 1)
        }
    });
        this.filteredTeams = this.teamControl.valueChanges.pipe(
          map((Name) => this.filterOnValueChange(Name))
        );
    })
    .catch((err: HttpErrorResponse) => {
      this.notificationService.failure(err.message);
    });

    this.filteredTeams = this.teamControl.valueChanges.pipe(
      map((Name) => this.filterOnValueChange(Name))
    );
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaveChanges() {
    this.dialogService
      .openConfirmDialog(
        this.screenLabels["UpdateUserConfirmLabel"]? this.screenLabels["UpdateUserConfirmLabel"]: "Confirm User Update",
        this.screenLabels["UpdateUserConfirmMsg"]? this.screenLabels["UpdateUserConfirmMsg"]: "Are you sure you want to save the changes ?"
      )
      .afterClosed()
      .subscribe((data: boolean) => {
        if (data) {
          const teamsToAdd: UserTeam[] = [];
          const teamsToRemove: UserTeam[] = [];
  
          this.chipSelectedTeam.forEach((element) => {
            const existingTeam = this.orriginalUserTeam.find(
              (team) => team.TeamID === element.TeamID
            );
  
            if (existingTeam) {
              existingTeam.Active = 1;
              teamsToAdd.push(existingTeam); 
            } else {
              teamsToAdd.push(element);
            }
          });
  
          this.orriginalUserTeam.forEach((element) => {
            if (
              !this.chipSelectedTeam.some((team) => team.TeamID === element.TeamID)
            ) {
              element.Active = 0;
              teamsToRemove.push(element); 
            }
          });
   const finalTeams: UserTeam[] = [...teamsToAdd, ...teamsToRemove];
  
          this.teamService
            .updateUserTeamAssociationsAPI(this.data.UserID, finalTeams)
            .then(() => {
              this.notificationService.success(this.teamService.message);
            }).catch((err: HttpErrorResponse) =>
              this.notificationService.failure(err.message)
            );
          this.onNoClick();
        }
      });
  }
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  private loadRoles() {
    // Load Role Data
   // need to call API
      this.roleMapService
        .getRoleMappingsAPI()
        .then(() => this.prepareRoleList())
        .catch((err: HttpErrorResponse) =>
          this.notificationService.failure(err.message)
        );
  }

  private loadTeams() {
     // call API
      this.teamService
        .getTeamAPI(null)
        .then(() => {
          // load the organization basket
          var orgTeams : Team[] = this.teamService.getTeam().filter((f) => f.OrganizationID == this.userService.organizationID && f.Active && f.AutoAddAllOrgUsers == 0);
          this.orgTeams = orgTeams.map((team) => ({
            UserTeamID : 0,
            TeamID : team.TeamID ,
            Active: team.Active,
            Name: team.Name,
            OrganizationID : team.OrganizationID,
            TemplateCategoryTeamList:[],
            OrganizationFunctionTeamList:[],
            AutoAddAllCategories:team.AutoAddAllCategories,
            AutoAddAllOrgUsers: team.AutoAddAllOrgUsers,        
            ModifiedBy: team.ModifiedBy,
            ModifiedDate: team.ModifiedDate,
            ModifiedByName: team.ModifiedByName,
            CreatedBy: team.CreatedBy,
            CreatedDate: team.CreatedDate, 
            CreatedByName:team.CreatedByName,

          }))
          
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
  }

  private prepareOrganizationList() {
    // console.log("Organization Item: ");
    // console.log(this.organizationItems);

    this.userService
      .getUserAssociationAPI(this.data.UserID,this.siteService.selectedSiteID)
      .then(() => {
        // load the organization basket
        const organizationBasket = this.userService
          .getUserDetails()[0]
          .UserOrganization.map((o) => ({
            UserOrganizationID: o.UserOrganizationID,
            Active: o.UserOrganizationActive,
            OrganizationID: o.OrganizationID,
            Name: this.organizationService
              .getOrganizations()
              .filter((f) => f.OrganizationID == o.OrganizationID)[0].Name,
          }))
          .filter((f) => f.Active);
        this.organizationBasket = organizationBasket;

        const organizationItems = [];
        for (let j = 0; j < this.organizationItems.length; j++) {
          let matchFound = false;
          for (let i = 0; i < this.organizationBasket.length; i++) {
            if (
              this.organizationItems[j].OrganizationID ==
              this.organizationBasket[i].OrganizationID
            ) {
              matchFound = true;
            }
          }
          if (!matchFound) organizationItems.push(this.organizationItems[j]);
        }
        this.organizationItems = organizationItems;

        this.originalOrganizationBasket = [...this.organizationBasket];

        console.log("Organization Basket:");
        console.log(this.organizationBasket);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    this.organizationItems = this.organizationService
      .getOrganizations()
      .map((o) => ({
        OrganizationID: o.OrganizationID,
        Name: o.Name,
        Active: o.Active,
      }))
      .filter((o) => o.Active === 1);
  }

  private prepareRoleList() {
    const response = this.roleMapService
      .getRoleMappingResponse()
      .filter((rm) => rm.RoleActive)
      .map((rm) => ({
        RoleID: rm.RoleID,
        RoleName: rm.RoleName,
        Active: rm.RoleActive,
      }))
      .filter((rm) => rm.Active == 1);

    // removing duplicates
    this.roleItems = Array.from(
      new Set(response.map((i) => JSON.stringify(i)))
    ).map((i) => JSON.parse(i));

    this.userService
      .getUserAssociationAPI(this.data.UserID,this.siteService.selectedSiteID)
      .then(() => {
        // load the role basket

        let roleBasket = this.userService
          .getUserDetails()[0]
          .UserRole.map((ur) => ({
            UserRoleID: ur.UserRoleID,
            RoleID: ur.RoleID,
            Active: ur.UserRoleActive,
            RoleName: this.roleMapService.getRoleMappingByID(ur.RoleID)[0]
              .RoleName,
          }));

        roleBasket = roleBasket.filter((rb) => rb.Active === 1);
        roleBasket.sort((a, b) =>
          a.RoleID > b.RoleID ? 1 : b.RoleID > a.RoleID ? -1 : 0
        );
        this.roleBasket = roleBasket;
        this.roleBasket = roleBasket.filter(
          ((set) => (f) => !set.has(f.RoleID) && set.add(f.RoleID))(new Set())
        );
        // this.roleBasket = [];

        // for (let i = 0; i < roleBasket.length; i++) {
        //   for (let j = i + 1; j < roleBasket.length; j++) {
        //     if (roleBasket[i].RoleID != roleBasket[j].RoleID) {
        //       this.roleBasket.push(roleBasket[i]);
        //       i = j - 1;
        //       break;
        //     }
        //   }
        // }
        let roleItems = [];
        for (let j = 0; j < this.roleItems.length; j++) {
          let matchFound = false;
          for (let i = 0; i < this.roleBasket.length; i++) {
            if (this.roleItems[j].RoleID == this.roleBasket[i].RoleID) {
              matchFound = true;
            }
          }
          if (!matchFound) roleItems.push(this.roleItems[j]);
        }
        // this.roleBasket = roleBasket;
        this.roleItems = roleItems;

        console.log("Role Basket: ");
        console.log(this.roleBasket);

        this.originalRoleBasket = [...this.roleBasket];
        this.rolesLoaded = true;
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }

  private arrayDifference(original: Array<any>, newArray: Array<any>) {
    const difference = original.filter((x) => !newArray.includes(x));
    return difference;
  }

  // Code for Drag and Drop
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }

  public addTeam(event: MatChipInputEvent): void {
    if (!this.allowFreeTextAddUser) {
      // only allowed to select from the filtered autocomplete list
      console.log("allowFreeTextAddEngineer is false");

      return;
    }

    //
    // Only add when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    //
    if (this.matAutocomplete.isOpen) {
      return;
    }
  
    const value = (event.value || "").trim();
  
    if (value && !this.isTeamAlreadySelected(value)) {
      if (this.isTeamInDropdown(value)) {
      } else {
        this.selectTeamByName(value);
      }
    }
    this.resetInputs();
  }

  private isTeamAlreadySelected(teamName: string): boolean {
    const isAlreadySelected = this.chipSelectedTeam.some(
      (team) => team.Name.trim().toLowerCase() === teamName.trim().toLowerCase()
    );
    return isAlreadySelected;
  }
  
  private isTeamInDropdown(teamName: string): boolean {
    return this.orgTeams.some((team) => team.Name.trim().toLowerCase() === teamName.toLowerCase());
  }
  
  public removeTeam(team: UserTeam): void {
    const index = this.chipSelectedTeam.indexOf(team);
      if (index >= 0) {
      const otherInstances = this.chipSelectedTeam.some((t) => t.Name === team.Name);
      if (!otherInstances) {
        this.orgTeams.push(team);
      }
      this.chipSelectedTeam.splice(index, 1);
      this.resetInputs();
    }
  }
 
  public teamSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectTeamByName(event.option.value);
    this.resetInputs();
  }

  private resetInputs() {
    // clear input element
    this.teamInput.nativeElement.value = "";
    // clear control value and trigger engineerControl.valueChanges event
    this.teamControl.setValue(null);
  }

  //
  // Compute a new autocomplete list each time control value changes
  //
  private filterOnValueChange(teamName: string): String[] {
    let result: String[] = [];
    //
    // Remove the teams we have already selected from all teams to
    // get a starting point for the autocomplete list.
    //
    let allTeamsLessSelected = this.orgTeams.filter(
      (team) => !this.chipSelectedTeam.some((selectedTeam) => selectedTeam.Name.toLowerCase() === team.Name.toLowerCase())
    );
    if (teamName) {
      result = this.filterUser(allTeamsLessSelected, teamName);
    } else {
      result = allTeamsLessSelected.map((team) => team.Name);
    }
    return result;
  }
  

  private filterUser(teamList: UserTeam[], userName: String): String[] {
    let filteredUserList: UserTeam[] = [];
    const filterValue = userName.toLowerCase();
    let teamsMatchingTeamName = teamList.filter(
      (team) => team.Name.toLowerCase().indexOf(filterValue) === 0
    );
    if (teamsMatchingTeamName.length || this.allowFreeTextAddUser) {
      //
      // either the engineer name matched some autocomplete options
      // or the name didn't match but we're allowing
      // non-autocomplete engineer names to be entered
      //
      filteredUserList = teamsMatchingTeamName;
    } else {
      //
      // the engineer name didn't match the autocomplete listss
      // and we're only allowing engineers to be selected from the list
      // so we show the whjole list
      //
      filteredUserList = teamList;
    }
    //
    // Convert filtered list of engineer objects to list of engineer
    // name strings and return it
    //
    return filteredUserList.map((team) => team.Name);
  }

  private selectTeamByName(teamName) {
    const lowerCaseTeamName = teamName.toLowerCase();
    const foundUser = this.orgTeams.find((team) => team.Name.toLowerCase() === lowerCaseTeamName);
  
    if (foundUser && !this.isTeamAlreadySelected(foundUser.Name)) {
      this.chipSelectedTeam.push(foundUser);
    }
  }
  
  protected selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    trigger.openPanel();
  }

  onAutocompleteFocus() {
    this.autoCompleteTrigger._onChange("");
    this.autoCompleteTrigger.openPanel();
  }
}