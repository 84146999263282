import { EventEmitter, Injectable, Output } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Site, SiteService } from "../services/site.service";
import { clone, cloneDeep } from "lodash";
import moment from "moment";
import { AnyCnameRecord } from "dns";
import { Observable } from "rxjs";
import { ISystemSettingsGetAPIResponse } from "../models/interfaces/system-settings-get-api.model";
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: "root",
})
export class HolidayService {
  public holidayData: any;

  @Output() dataChanged: EventEmitter<boolean>;
  private _siteSettings: ISystemSettingsGetAPIResponse;

  get siteSettings() {
    return cloneDeep(this._siteSettings);
  }


  constructor(
    public http: HttpClient,
    protected siteService: SiteService
  ) {
    this.dataChanged = new EventEmitter<boolean>();
  }

   holidayApiURL = environment.getAPI("holidays");
  //holidayApiURL = "https://vms-api-dev.warnerbros.com/general/holidays"

  dt = new Date();
  year = this.dt.getFullYear();

  getYear() {
    var dt = new Date();
    var year = dt.getFullYear();
    if (new Date().getFullYear) {
      return year;
    } else
      return year + 1;
  }

  public getHolidaysYearAPI() {
    return this.http.get<any>(`${this.holidayApiURL}/${this.year}/${this.siteService.selectedSiteID}`);
  }

  public getHolidaysNextAPI() {
    return this.http.get<any>(`${this.holidayApiURL}/${this.year+1}/${this.siteService.selectedSiteID}`);
  }

  public setNonWorkingDaysAPI(Days: string, SiteID: number) {
   // const apiURL = "https://vms-api-dev.warnerbros.com/general/setNonWorkingDays"
    const apiURL = environment.getAPI("setNonWorkingDays");

    const postBody = {
      Days: Days,
      SiteID: this.siteService.selectedSiteID,
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            console.log("SUCCESS BODY", success.body);
            //this.notificationService.success(success.body.Message);
            this.dataChanged.emit(true);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public setHolidaysAPI(Year: number, SiteID: number, HolidayDates: Array<String>) {
    const apiURL = environment.getAPI("setHolidayAPIForYear");
    //const apiURL = "https://5qz1rzxiqf.execute-api.us-west-2.amazonaws.com/dev/setHolidaysForYear"

    const postBody = {
      Year: Year,
      SiteID: SiteID,
      HolidayDates: HolidayDates
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            console.log("SUCCESS BODY", success.body);
            //this.notificationService.success(success.body.Message);
            // this.dataChanged.emit(true);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }


  public getSiteSettingsAPI() {
    const apiURL = environment.getAPI("getSystemSettings");

    const body = {
      SiteID: this.siteService.selectedSiteID,
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise<ISystemSettingsGetAPIResponse>(
      (resolve, reject) => {
        this.http
          .post(apiURL, body, httpOptions)
          .toPromise()
          .then(
            async (success: HttpResponse<any>) => {
              // success
              // console.log(success.body);

              this._siteSettings = success.body;
              console.log(success.body);
              resolve(success.body);
            },
            (err) => {
              // error
              reject(err);
            }
          );
      }
    );
    return promise;
  }

}