import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { OktaAuthService } from "@okta/okta-angular";
import { UserService } from "../services/user.service";
import { AuthService } from "../services/auth.service";
import { NotificationService } from "../services/notification.service";

@Injectable()
export class UserRegisteredGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private notificationService: NotificationService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // user is registered
    if (localStorage.getItem("isUserRegistered") === "true") {
      console.log(localStorage["isUserRegistered"]);
      return true;
    }
    // user is not registered
    else {
      console.log(localStorage["isUserRegistered"]);
      this.authService.logout();
      // this.notificationService.failure(
      //   "You are not registered with the Application."
      // );

      return false;
    }
  }
}
