import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatExpansionPanel,
} from "@angular/material";
import { FormControl } from "@angular/forms";
import { UpdateEntryPointGroupComponent } from "../update-entry-point-group/update-entry-point-group.component";
import { EntryPointGroup } from "../../../models/entry-point-group.model";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { NotificationService } from "../../../services/notification.service";
import { HttpErrorResponse } from "@angular/common/http";
import { EntryPointService } from "../../../services/entry-point.service";
import { EntryPoint } from "../../../models/entry-point.model";
import { EntryPointGroupService } from "../../../services/entry-point-group.service";
import { SiteService } from "src/app/services/site.service";
import { MessageService } from 'src/app/services/message.service';
@Component({
  selector: "app-view-entry-point-group",
  templateUrl: "./view-entry-point-group.component.html",
  styleUrls: ["./view-entry-point-group.component.css"],
})
export class ViewEntryPointGroupComponent implements OnInit {
  @ViewChild("freePanel") freezePanel: MatExpansionPanel;
  protected items: Array<EntryPoint> = [];
  protected basket: Array<EntryPoint> = [];
  protected appLanguage;
  public screenLabels: Array<string> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<ViewEntryPointGroupComponent>,
    private notificationService: NotificationService,
    private entryPointService: EntryPointService,
    private entryPointGrpService: EntryPointGroupService,
    private siteService: SiteService,
    protected messageService: MessageService
  ) {}

  ngOnInit(): void {

    //labels by message service
if (localStorage.getItem('ViewEntryPointGroupLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('ViewEntryPointGroupLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "View Entry Point Group Modal",
      "EntryPointGroup"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('ViewEntryPointGroupLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    if (this.entryPointService.getEntryPoints().length != 0) {
      this.entryPointGrpService
        .getAllEntryPointGroupsAPI()
        .then(() => {
          const mappedEntryPoint = this.entryPointGrpService.getGroupMappings(
            this.data.EntryPointGroupID
          ).EntryPoints;
          if(mappedEntryPoint)this.basket = mappedEntryPoint;
          this.items = this.entryPointService
            .getEntryPoints()
            .filter((x) => !this.basket.includes(x));
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    } else {
      this.entryPointService
        .parseGetEndpointAPI()
        .then(() => {
          this.entryPointGrpService
            .getAllEntryPointGroupsAPI()
            .then(() => {
              const mappedEntryPoint = this.entryPointGrpService.getGroupMappings(
                this.data.EntryPointGroupID
              ).EntryPoints;
              if(mappedEntryPoint)this.basket = mappedEntryPoint;
              this.items = this.entryPointService
                .getEntryPoints()
                .filter((x) => !this.basket.includes(x));
            })
            .catch((err: HttpErrorResponse) => {
              this.notificationService.failure(err.message);
            });
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    }
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}

  onMainHeaderClose() {
    this.freezePanel.open();
  }
  onEditEntryPointgroup() {
    this.dialog.open(UpdateEntryPointGroupComponent, { 
      disableClose: true,
      data: this.data });
  }
  onNoClick() {
    this.dialogRef.close();
  }

  // Code for Drag and Drop

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
}
