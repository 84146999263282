import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { SearchService } from "../services/search.service";
import { UserService } from "../services/user.service";
import { MatDialog } from "@angular/material";
import { RaiseCapacityModalComponent } from "./modals/raise-capacity-request-modal/raise-capacity-request-modal.component";
import { NgForm } from "@angular/forms";
import { OrganizationService } from "../services/organization.service";
import { NotificationService } from "../services/notification.service";
import { DialogService } from "../services/dialog.service";
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import { ParkingLotService } from "../services/parking-lot.service";
import { ParkingLot } from "../models/parking-lot.model";
import { SpinnerService } from "../services/spinner.service";
import { LimitRequestService } from "../services/limit-request.service";
import { EntryPointZoneService } from "../services/entry-point-zone.service";
import { EntryPointGroupService } from "../services/entry-point-group.service";
import { EntryPointGroup } from "../models/entry-point-group.model";
import { EntryPointService } from "../services/entry-point.service";
import { UtilityService } from "../services/utility.service";
import { SiteService } from "../services/site.service";

@Component({
  selector: "app-capacity-management",
  templateUrl: "./capacity-management.component.html",
  styleUrls: ["./capacity-management.component.css"],
})
export class CapacityManagementComponent implements OnInit, AfterViewInit {
  @ViewChild("filterForm") filterForm: NgForm;

  protected isOrganizationDataLoaded = false;
  protected isParkingLotDataLoaded = false;
  protected isEntryPointGroupLoaded = false;
  protected isSubmitting: boolean = false;

  protected minDate = this.utilService.getCurrentSiteDT();
  protected maxDate = new Date(
    this.utilService.getCurrentSiteDT().setDate(this.minDate.getDate() + 6 * 7)
  );

  protected organizations: Array<{
    OrganizationID: number;
    Name: string;
    Active: number;
    Internal: number;
    StartDate: string;
    EndDate: string;
    ModifiedBy: number;
    CreatedBy: number;
    CreatedDate: string;
  }> = [];

  protected parkingLots: Array<any>;
  protected entryPointGroups: Array<any>;

  constructor(
    private searchService: SearchService,
    private userService: UserService,
    private dialog: MatDialog,
    private organizationService: OrganizationService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private parkingLotService: ParkingLotService,
    private spinnerService: SpinnerService,
    private limitService: LimitRequestService,
    private entryPointZoneService: EntryPointZoneService,
    private entryPointGroupService: EntryPointGroupService,
    private entryPointService: EntryPointService,
    private utilService: UtilityService,
    private siteService:SiteService
  ) {}

  ngOnInit(): void {
    this.searchService.setPageName("Daily Limit Request");
    this.searchService.hideSearch();

    // get assigned organizations
    this.loadOrganizationData();
    setTimeout(() => {
      this.spinnerService.setIsLoading(true);
    }, 0);
  }

  ngAfterViewInit() {
    // get parking lots
    this.loadParkingLotData();

    // get entry point zones
  }

  // invoked when a request is raised
  protected onRequestRaised() {
    const organizationID = this.filterForm.value.organization;
    const startDate = this.filterForm.value.startDate;
    const endDate = this.filterForm.value.endDate;
    const parkingLotID =
      this.filterForm.value.parkingLot === undefined
        ? null
        : this.filterForm.value.parkingLot;
    const entryPointGroupID = this.filterForm.value.entryPointGroup;
    const extraLimit = this.filterForm.value.extraLimit;
    const reason = this.filterForm.value.reason;
    this.isSubmitting = true;
    this.limitService
      .createLimitRequestAPI(
        organizationID,
        startDate,
        endDate,
        extraLimit,
        entryPointGroupID,
        parkingLotID,
        reason
      )
      .then(() => {
        // reset the form
        this.filterForm.reset();
        this.filterForm.resetForm();
        this.isSubmitting = false;
        // show success message
        this.notificationService.success(this.limitService.message);
      })
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );
  }

  // invoked when the organization data is loaded
  private loadOrganizationData() {
    this.userService.getUserAssociationAPI(this.userService.userID,this.siteService.selectedSiteID).then(() => {
      this.organizations = [
        ...this.userService
          .getUserDetails()[0]
          .UserOrganization.map((o) => ({
            OrganizationID: o.OrganizationID,
            Name: o.Name,
            StartDate: o.Startdate,
            EndDate: o.EndDate,
          })),
      ];

      this.isOrganizationDataLoaded = true;
      setTimeout(() => {
        this.spinnerService.setIsLoading(false);
      }, 0);
    });
  }

  // invoked to load parking lot data
  protected loadParkingLotData() {
    const organizationID =
      this.filterForm !== undefined
        ? +this.filterForm.value.organization
        : null;
    this.isParkingLotDataLoaded = false;
    this.organizationService.getOrganizationByIDAPI(organizationID).then(() => {
      const organization = this.organizationService.getOrganizationByIDResponse()[0];
      const tempParking = organization.OrganizationParkingLimitArray.filter(
        (op) => !op.Regulated && op.Active
      );
      const parkings = [];
      tempParking.forEach((item) => {
        item.ParkingLotArray.forEach((p) => parkings.push(p));
      });
      this.parkingLots = parkings;
      if (this.parkingLots.length > 0) this.isParkingLotDataLoaded = true;
    });
  }

  // invoked to load Entry Point Zone data
}
