import { Injectable, Output, EventEmitter } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { FunctionModel } from "../models/function.model";
import { IFunctionData } from "../models/interfaces/function-data.model";
import { resolve } from "url";
import "rxjs/add/operator/toPromise";
import { UserService } from "./user.service";
import { NotificationService } from "./notification.service";
import { ConditionalExpr } from "@angular/compiler";
import { environment } from "src/environments/environment";
import { SiteService } from "./site.service";

@Injectable({
  providedIn: "root",
})
export class RoleMapService {
  public message: string;
  private roleMappingAPIResponse: Array<{
    RoleID: number;
    RoleName: string;
    Notes: string;
    RoleActive: number;
    ModifiedBy: number;
    ModifiedDate: string;
    CreatedBy: number;
    CreatedDate: string;
    FunctionCategoryID: number;
    FunctionID: number;
    RoleFunctionActive: number;
    SiteID: number;
  }> = [];

  @Output() roleDataChanged: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  public functionApiData: Array<{
    FunctionID: number;
    Tag: string;
    Label: string;
    Description: string;
    DisplayOrder: number;
    FunctionCategoryID: number;
    Category: string;
    CatDisplayOrder: number;
  }>;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    notificationService: NotificationService,
    protected siteService: SiteService
  ) { }

  // Invokes an API call to get the list of Functions
  getFunctions(): Promise<any> {
    const apiURL = environment.getAPI("getRoleScreenDesign");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise<{
      FunctionID: number;
      Tag: string;
      Label: string;
      Description: string;
      DisplayOrder: number;
      FunctionCategoryID: number;
      Category: string;
      CatDisplayOrder: number;
    }>((resolve, reject) => {
      this.http
        .post(apiURL, "", httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.functionApiData = success.body.filter(
              (fd) => fd.FunctionID !== null
            );
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // Invoke Create API call when a Role is getting created
  createRoleMapAPI(
    roleName: string,
    notes: string,
    isRoleActive: boolean,
    functions: Array<{
      CategoryID: number;
      FunctionID: number;
      Active: boolean;
    }>
  ): Promise<any> {
    const apiURL = environment.getAPI("roleCreate");

    const postBody = {
      RoleName: roleName,
      Notes: notes,
      Active: isRoleActive,
      Function: functions.filter((f) => f.FunctionID !== null),
      UserID: this.userService.userID,
      SiteID: this.siteService.selectedSiteID,
    };
    // console.log(postBody);
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
            // console.log(success.body);
            this.roleDataChanged.emit(true);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // Invoke Update API call when a Role is updated
  updateRoleMapAPI(
    roleID: number,
    roleName: string,
    notes: string,
    isRoleMappingActive: boolean,
    functions: Array<{
      CategoryID: number;
      FunctionID: number;
      Active: number;
    }>
  ): Promise<any> {
    const apiURL = environment.getAPI("roleUpdate");

    const postBody = {
      RoleID: roleID,
      RoleName: roleName,
      Notes: notes,
      Active: isRoleMappingActive,
      Function: functions.filter((f) => f.FunctionID !== null),
      UserID: this.userService.userID,
      SiteID: this.siteService.selectedSiteID,
    };
    // console.log(postBody);
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
            this.roleDataChanged.emit(true);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // Invoke Get All Roles when
  getRoleMappingsAPI(roleID?: number): Promise<any> {
    const apiURL = environment.getAPI("getRoleFunction");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const postBody = {
      SiteID: this.siteService.selectedSiteID,
      RoleID: roleID
    };

    const promise = new Promise<any>((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.roleMappingAPIResponse = success.body;
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // returns a copy of array
  getRoleMappingResponse() {
    return [...this.roleMappingAPIResponse];
  }

  // get role mapping by ID
  getRoleMappingByID(roleID: number) {
    if (this.roleMappingAPIResponse.length) {
      const roleMapping = this.roleMappingAPIResponse.filter(
        (rm) => rm.RoleID == roleID
      );
      return roleMapping ? roleMapping : null;
    } else {
      return null;
    }
  }

  // get function mappings for a Role
  getSelectedFunctions(roleID: number) {
    const mappings = this.getRoleMappingByID(roleID);
    const functions = mappings.map((f) => ({
      CategoryID: f.FunctionCategoryID,
      FunctionID: f.FunctionID,
      Active: f.RoleFunctionActive,
    }));
    return functions;
  }

  getPassCheckinFunctionID() {
    // console.log(this.functionApiData);
    const checkinFunction = this.functionApiData
      .filter((f) => f.Tag == "PassCheckIn")
      .map((f) => ({
        FunctionID: f.FunctionID,
      }));
    // console.log(checkinFunction);
    return checkinFunction[0].FunctionID;
  }
}
