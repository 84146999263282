import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ApprovalType } from '../models/approval-type-model';
import { DialogService } from '../services/dialog.service';
import { NotificationService } from '../services/notification.service';
import { SearchService } from '../services/search.service';
import { SiteService } from '../services/site.service';
import { UserService } from '../services/user.service';
import { ApprovalTypeService } from '../services/approval-type.service';
import { UpdateApprovalTypeModalComponent } from './modals/update-approval-type-modal/update-approval-type-modal.component';
import { ViewApprovalTypeModalComponent } from './modals/view-approval-type-modal/view-approval-type-modal.component';
import { GridApi } from 'ag-grid-community';
import { ApprovalTypeSiteSettingsSmartSearch } from '../models/interfaces/approval-type-site-settings-smart-search.model';
import { MessageService } from '../services/message.service';

@Component({
  selector: 'app-approval-type',
  templateUrl: './approval-type.component.html',
  styleUrls: ['./approval-type.component.css']
})
export class ApprovalTypeComponent implements OnInit {

  protected isRowSelected: boolean;
  protected rowData: ApprovalTypeSiteSettingsSmartSearch[];
  protected rowClassRules;
  private paginationPageSize;
  protected approvalTypeColumnDefs = [];
  private selectedApprovalType: ApprovalType;
  emailTemplates: any[];
  private gridApi: GridApi;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  public defaultColDef : object;
  requestApprovedDropDown: any;

  constructor(
    protected dialog: MatDialog,
    protected dialogService: DialogService,
    protected notificationService: NotificationService,
    protected searchService: SearchService,
    protected approvalTypeService: ApprovalTypeService,
    protected userService: UserService,
    private siteService: SiteService,
    protected messageService: MessageService,
  ) {
    this.paginationPageSize = 50;
    // set row styles
    this.rowClassRules = {
      "row-inactive": (params) => {
        var condition = params.data.Active === 0;
        return condition;
      },
      "row-nouse": (params) => {
        var condition = params.data.InUse === 0;
        return condition;
      }
    };
    this.defaultColDef = {
      resizable: true,
  };
  }

  ngOnInit(): void {
    this.getDropdwonValues();
    this.searchService.setPageName("Approval Type Site Settings");

    //labels by message service
if (localStorage.getItem('ApprovalTypeLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('ApprovalTypeLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Approval Type Site Settings",
      "ApprovalTypeSiteSettings"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('ApprovalTypeLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    this.searchService.searchInitiated.subscribe((eventData: boolean) => {
      if (eventData) {
        this.searchService.smartSearchPromise
          .then(() => {
            this.rowData = this.searchService.searchRecords; 
            this.gridApi.paginationGoToPage(0);           
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    });

     // subscribe to site changed
    this.siteService.siteChanged.subscribe((event: boolean) => {
      if(event){
        this.searchService
        .smartSearchMultiSiteAPI(null,null,null,false)
        .then(() => {
          this.rowData = this.searchService.searchRecords;  
          this.gridApi.paginationGoToPage(0);       
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
      }
    });

    this.approvalTypeService.approvalTypeDataChanged.subscribe((e) => {
      this.searchService
        .smartSearchMultiSiteAPI(null,null,null,false)
        .then(() => {
          this.rowData = this.searchService.searchRecords;
          this.isRowSelected = false;

          this.gridApi.paginationGoToPage(0);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    });
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDefs();
}

  onGridReady(params) {
    this.gridApi = params.api;
  }

  getApprovalTypeValues() {
    this.searchService.setPageName("Approval Type Site Settings");

    this.searchService
      .smartSearchMultiSiteAPI(null,null,null,false)
      .then(() => {
        this.isRowSelected = false;
        this.rowData = this.searchService.searchRecords;
        
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }

  setColumnDefs() {
    this.approvalTypeColumnDefs = [
      {
        headerName: "",
        field: "",
        width: 80,
        cellStyle: { color: "#589bf8" },
        hide: false,
        checkboxSelection: true,
        resizable: false,
        sortable: false,
        lockPosition: true,
      },
      {
        headerName: this.screenLabels["AgGridApprovalType"] ? this.screenLabels["AgGridApprovalType"] : "Approval Type",
        field: "Tag",
        hide: false,
        sortable: true,
      },
      {
        headerName:  this.screenLabels["AgGridDefaultEmailTemplate"] ? this.screenLabels["AgGridDefaultEmailTemplate"]: "Default Email Template",
        field: "DefApprovalEmailTemplateDescription",
        hide: true,
        sortable: true,
        
      },
      {
        headerName:  "In Use",
        field: "InUse",
        hide: false,
        sortable: true,
        cellRenderer: (data) => {
          if (data.value === 1) {
            return "Yes";
          } else{
            return "No";
          }  
        },
      }
    ];
  }

  getDropdwonValues() {
    this.searchService
      .smartSearchMultiSiteAPI(null,"Email Template",null,false)
      .then(() => {
        this.emailTemplates = this.searchService.searchRecords;
        this.getApprovalTypeValues();
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }

  // make ag-rid colums fill their container
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  // sets the data of selected row
  onSelectionChanged($event) {
    const selectedRows: ApprovalType[] = $event.api.getSelectedRows();
    if (selectedRows.length) {
      this.selectedApprovalType = selectedRows[0];
      this.isRowSelected = true;
    } else {
      this.isRowSelected = false;
    }
  }

  editApprovalType() {
    const emailTemplates = this.emailTemplates.filter(row => row.EmailTag === this.selectedApprovalType.EmailTemplateLookupKey);
    this.dialog.open(UpdateApprovalTypeModalComponent, {
      disableClose: true,
      data: { approvalType: this.selectedApprovalType, emailTemplates: emailTemplates },
    });
  }

  // open selected approval type
  openApprovalType(event) {
    this.dialog.open(ViewApprovalTypeModalComponent, {
      disableClose: true,
      data: { approvalType: event.data, emailTemplates: this.emailTemplates },
    });
  }
}

