import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatExpansionPanel, MatCheckboxChange, MatAutocompleteSelectedEvent } from '@angular/material';
import { EntryPointService } from 'src/app/services/entry-point.service';
import { ApprovalTypeService } from 'src/app/services/approval-type.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SearchService } from 'src/app/services/search.service';
import { DialogService } from 'src/app/services/dialog.service';
import { SiteService } from 'src/app/services/site.service';
import { ApprovalType } from 'src/app/models/approval-type-model';
import { UserService } from 'src/app/services/user.service';
import { NgForm , FormControl} from '@angular/forms';
import { RoleMapService } from 'src/app/services/role-map.service';
import { MessageService } from 'src/app/services/message.service';
import { RequiredFieldsService } from 'src/app/services/required-fields.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilityService } from 'src/app/services/utility.service';
import { map, startWith } from "rxjs/operators";
import { Observable } from "rxjs";

@Component({
  selector: 'app-update-approval-type-modal',
  templateUrl: './update-approval-type-modal.component.html',
  styleUrls: ['./update-approval-type-modal.component.css']
})
export class UpdateApprovalTypeModalComponent implements OnInit {
  protected step = 0;
  protected approvalType: ApprovalType;
  inUse = false;
  emailTemplates: any[] = [];
  protected allRoleNames: Array<any> = [];
  protected accessReqRoleNames: Array<any> = [];
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  @ViewChild("updateForm") updateForm: NgForm
  protected noEmailTemplate = "No Email Required";
  protected requestApprovedDropDown;
  protected requestRejectedDropDown;
  protected requestCreatedDropDown;
  protected requestCancelledDropDown;
  protected requestExpiredDropDown;
  public configMessage: boolean = false;
  public userConfigData: Array<any> = [];
  protected appLanguage;
  public screenLabels: Array<string> = [];
  public selfReg : boolean = false;
  public inUseValidation : boolean = false;
  public selfReqRole : boolean = false;
  selfRegisRolesFormControl = new FormControl();
  filteredSelfReg: Observable<any[]>;
  accessReqRolesFormControl = new FormControl();
  filteredAccessReq: Observable<any[]>;
  requestApprovedControl = new FormControl();
  fiteredRequestApprovedDropDown: Observable<any[]>;
  requestRejectedControl = new FormControl();
  filteredRequestRejectedDropDown: Observable<any[]>;
  requestCreatedControl = new FormControl();
  filteredRequestCreatedDropDown: Observable<any[]>;
  requestCancelledControl = new FormControl();
  filteredRequestCancelledDropDown: Observable<any[]>;
  requestExpiredControl = new FormControl();
  filteredRequestExpiredDropDown: Observable<any[]>;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected dialogRef: MatDialogRef<UpdateApprovalTypeModalComponent>,
    protected entryPointService: EntryPointService,
    protected approvalTypeService: ApprovalTypeService,
    private _notificationService: NotificationService,
    protected userService: UserService,
    private _searchService: SearchService,
    private _siteService: SiteService,
    protected dialogService: DialogService,
    private siteService: SiteService,
    private _roleService: RoleMapService,
    protected requiredFieldsService: RequiredFieldsService,
    protected messageService: MessageService,
    private _utilService: UtilityService,
  ) { }

  ngOnInit(): void {
    const data = { ...this.data }
    this.approvalType = data.approvalType;
    const emailTemplates = data.emailTemplates;
    this.emailTemplates = emailTemplates.filter(site => site.SiteID === this.userService.selectedSiteID)
    this.inUse = this.approvalType.InUse === 1 ? true : false;
    this._loadRoleNames();
    this.loadEmailTemplates();

    //labels by message service
    if (localStorage.getItem('UpdateApprovalTypeLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('UpdateApprovalTypeLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Update Approval Type Modal",
        "ApprovalTypeSiteSettings"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('UpdateApprovalTypeLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    // calling this API to set the configuration error message
    this._searchService
      .smartSearchMultiSiteAPI(null, "Approval Request Configuration", "ApprovalRequestConfig", null, this.approvalType.ApprovalTypeID)
      .then(() => {
        const configData = this._searchService.searchRecords;
        this.userConfigData = configData.filter(data => data && data.SiteID === this._siteService.selectedSiteID);
        if (this.userConfigData.length > 0) {
          this.configMessage = false;
        } else {
          this.configMessage = true;
        }
      })
      .catch((err: HttpErrorResponse) => {
        this._notificationService.failure(err.message);
      })

      this.accessReqRolesFormControl.valueChanges.subscribe(value => {
        if (!value) {
          this.selfReqRole = true;
        }
      });
      this.selfRegisRolesFormControl.valueChanges.subscribe(value => {
        if (!value) {
          this.selfReg = true;
        }
      });
  }

  private _filteredSelfReg(value: string) {
    return this.allRoleNames.filter(option => option.RoleName.toLowerCase().includes(value.toLowerCase()));
  }
  
  displayFn(selectedoption: number) {
    if(selectedoption) {
    return this.allRoleNames.find(x => x.RoleID === selectedoption).RoleName;
    }
  }
  private _filteredAccessReq(value: string) {
    return this.allRoleNames.filter(option => option.RoleName.toLowerCase().includes(value.toLowerCase()));
  }
  
  displayFn2(selectedoption: number) {
    if(selectedoption) {
    return this.allRoleNames.find(x => x.RoleID === selectedoption).RoleName;
    }
  }

  private async _loadRoleNames() {
    try {
      await this._roleService.getRoleMappingsAPI();

      // filter all roles
      this.allRoleNames = this._roleService
        .getRoleMappingResponse()
        .filter(
          (rm) =>
            rm.RoleActive == 1 && rm.SiteID === this.siteService.selectedSiteID
        )
        .map((r) => {
          return {
            RoleName: r.RoleName,
            RoleActive: r.RoleActive,
            RoleID: r.RoleID,
            SiteID: r.SiteID
          }
        });
      // remove duplicates for roles
      this.allRoleNames = this._utilService.removeObjArrayDuplicates(this.allRoleNames);
      this.filteredSelfReg = this.selfRegisRolesFormControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value?.RoleName),
        map(RoleName => RoleName ? this._filteredSelfReg(RoleName) : this.allRoleNames.slice())
      );
      this.filteredAccessReq = this.accessReqRolesFormControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value?.RoleName),
        map(RoleName => RoleName ? this._filteredAccessReq(RoleName) : this.allRoleNames.slice())
      );
      // another method - remove duplicates for roles
     // this.accessReqRoleNames = [...new Set(this.accessReqRoleNames)];

    } catch (err) {
      console.log(err);
    }
  }

  loadEmailTemplates() {
    this.approvalTypeService.getEmailTemplates("Request Approved", this.approvalType.ApprovalTypeID).subscribe((response: any) => {
      this.requestApprovedDropDown = response.body;
      this.requestApprovedDropDown.unshift({ EmailTemplateID: 1, Description: "No Email Required" });
      this.fiteredRequestApprovedDropDown = this.requestApprovedControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value?.Description),
        map(Description => Description ? this._fiteredRequestApprovedDropDown(Description) : this.requestApprovedDropDown.slice())
      );
    });
    this.approvalTypeService.getEmailTemplates("Request Rejected", this.approvalType.ApprovalTypeID).subscribe((response: any) => {
      this.requestRejectedDropDown = response.body;
      this.requestRejectedDropDown.unshift({ EmailTemplateID: 1, Description: "No Email Required" });
      this.filteredRequestRejectedDropDown = this.requestRejectedControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value?.Description),
        map(Description => Description ? this._filteredRequestRejectedDropDown(Description) : this.requestRejectedDropDown.slice())
      );
    });
    this.approvalTypeService.getEmailTemplates("Request Created", this.approvalType.ApprovalTypeID).subscribe((response: any) => {
      this.requestCreatedDropDown = response.body;
      this.requestCreatedDropDown.unshift({ EmailTemplateID: 1, Description: "No Email Required" });
      this.filteredRequestCreatedDropDown = this.requestCreatedControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value?.Description),
        map(Description => Description ? this._filteredRequestCreatedDropDown(Description) : this.requestCreatedDropDown.slice())
      );
    });
    this.approvalTypeService.getEmailTemplates("Request Cancelled", this.approvalType.ApprovalTypeID).subscribe((response: any) => {
      this.requestCancelledDropDown = response.body;
      this.requestCancelledDropDown.unshift({ EmailTemplateID: 1, Description: "No Email Required" });
      this.filteredRequestCancelledDropDown = this.requestCancelledControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value?.Description),
        map(Description => Description ? this._filteredRequestCancelledDropDown(Description) : this.requestCancelledDropDown.slice())
      );
    });
    this.approvalTypeService.getEmailTemplates("Request Expired", this.approvalType.ApprovalTypeID).subscribe((response: any) => {
      this.requestExpiredDropDown = response.body;
      this.requestExpiredDropDown.unshift({ EmailTemplateID: 1, Description: "No Email Required" });
      this.filteredRequestExpiredDropDown = this.requestExpiredControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value?.Description),
        map(Description => Description ? this._filteredRequestExpiredDropDown(Description) : this.requestExpiredDropDown.slice())
      );
    });

    this.approvalTypeService.getSelfRegAndAccessRole().subscribe((response: any) => {
      this.approvalType.UserAccessRequestEnabled = Number(response.body.filter(x => x.Name === "UserAccessRequestEnabled").map(x => x.Value)) ? true : false;
      this.approvalType.UserAccessRole = Number(response.body.filter(x => x.Name === "UserAccessRequestRole").map(x => x.Value));
      this.approvalType.SelfRegistrationEnabled = Number(response.body.filter(x => x.Name === "SelfRegistrationEnabled").map(x => x.Value)) ? true : false;
      this.approvalType.SelfRegRole = Number(response.body.filter(x => x.Name === "SelfRegistrationRole").map(x => x.Value));
    });

  }

  private _fiteredRequestApprovedDropDown(value: string){
    return this.requestApprovedDropDown.filter(option => option.Description.toLowerCase().includes(value.toLowerCase()));
  }
  displayFn3(selectedoption: number) {
    if(selectedoption) {
    return this.requestApprovedDropDown.find(x => x.EmailTemplateID === selectedoption).Description;
    }
  }

  private _filteredRequestRejectedDropDown(value: string){
    return this.requestRejectedDropDown.filter(option => option.Description.toLowerCase().includes(value.toLowerCase()));
  }
  displayFn4(selectedoption: number) {
    if(selectedoption) {
    return this.requestRejectedDropDown.find(x => x.EmailTemplateID === selectedoption).Description;
    }
  }

  private _filteredRequestCreatedDropDown(value: string){
    return this.requestCreatedDropDown.filter(option => option.Description.toLowerCase().includes(value.toLowerCase()));
  }
  displayFn5(selectedoption: number) {
    if(selectedoption) {
    return this.requestCreatedDropDown.find(x => x.EmailTemplateID === selectedoption).Description;
    }
  }

  private _filteredRequestCancelledDropDown(value: string){
    return this.requestCancelledDropDown.filter(option => option.Description.toLowerCase().includes(value.toLowerCase()));
  }
  displayFn6(selectedoption: number) {
    if(selectedoption) {
    return this.requestCancelledDropDown.find(x => x.EmailTemplateID === selectedoption).Description;
    }
  }

  private _filteredRequestExpiredDropDown(value: string){
    return this.requestExpiredDropDown.filter(option => option.Description.toLowerCase().includes(value.toLowerCase()));
  }
  displayFn7(selectedoption: number) {
    if(selectedoption) {
    return this.requestExpiredDropDown.find(x => x.EmailTemplateID === selectedoption).Description;
    }
  }

  onSelectionChanged(event: MatCheckboxChange) {
    if (event.checked == true) {
      this.inUseValidation = true ;
    } else {
      this.inUseValidation = false ;
      this.selfReg = false ;
      this.selfReqRole = false ;
    }
  }
  onSelectSelfReg(event : MatAutocompleteSelectedEvent) {
    if (event.option.value) this.selfReg = false ;
    else this.selfReg = true ;
  }
  onSelectAccessReqRole (event : MatAutocompleteSelectedEvent) {
    if (event.option.value) this.selfReqRole = false;
    else this.selfReqRole = true;
  }
  onSelfRegRoleClick(event) {
    if(event.value) this.selfReg = false;
    else this.selfReg = true;
  }
  onAccessReqRoleClick(event) {
    if (event.value) this.selfReqRole = false;
    else this.selfReqRole = true;
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  ngAfterViewChecked() {
    // setting up the required fields
    this.requiredFieldsService.setRequiredFields(
      'Update Approval Type',
      this.updateForm
    );
  }

  onSelfRegistrationToggle(event) {
    if (event.checked === true) {
      this.selfRegisRolesFormControl.setValue('');
      //this.approvalType.SelfRegRole = null;
      this.selfReg = true ;
    } else {
      this.selfRegisRolesFormControl.setValue('');
     // this.approvalType.SelfRegRole = null;
      this.selfReg = false ;
    }
  }

  onAccessRequestToggle(event) {
    if (event.checked === true) {
      this.accessReqRolesFormControl.setValue('');
      //this.approvalType.UserAccessRole = null;
      this.selfReqRole = true;
    } else {
      this.accessReqRolesFormControl.setValue('');
      //this.approvalType.UserAccessRole = null;
      this.selfReqRole = false;
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }

  onSaveChanges() {
    if (this.approvalType.Tag === 'User Request') {
      if (this.approvalType.SelfRegistrationEnabled === false) this.approvalType.SelfRegRole = null;
      if (this.approvalType.UserAccessRequestEnabled === false) this.approvalType.UserAccessRole = null;
    }
    else {
      this.approvalType.SelfRegistrationEnabled === false;
      this.approvalType.UserAccessRequestEnabled === false;
      this.approvalType.SelfRegRole = null;
      this.approvalType.UserAccessRole = null;
    }

    const approvalTypeSettings = {
      ApprovalTypeID: this.approvalType.ApprovalTypeID,
      InUse: this.inUse ? 1 : 0,
      UserID: this.userService.userID,
      SiteID: this.siteService.selectedSiteID,
      SelfRegistrationEnabled: this.approvalType.SelfRegistrationEnabled ? this.approvalType.SelfRegistrationEnabled : 0,
      UserAccessRequestEnabled: this.approvalType.UserAccessRequestEnabled ? this.approvalType.UserAccessRequestEnabled : 0,
      SelfRegRole: this.approvalType.SelfRegRole ? this.approvalType.SelfRegRole : null,
      UserAccessRole: this.approvalType.UserAccessRole ? this.approvalType.UserAccessRole : null,
      ApprovedEmailTemplateID: this.approvalType.ApprovedEmailTemplateID,
      RejectedEmailTemplateID: this.approvalType.RejectedEmailTemplateID,
      CreatedEmailTemplateID: this.approvalType.CreatedEmailTemplateID,
      CancelledEmailTemplateID: this.approvalType.CancelledEmailTemplateID,
      ExpiredEmailTemplateID: this.approvalType.ExpiredEmailTemplateID
    }
    this.approvalTypeService.updateApprovalType(approvalTypeSettings).then((success: any) => {
      this._notificationService.success(
        this.screenLabels['EditApprovalTypeSuccessMsg'] ? this.screenLabels['EditApprovalTypeSuccessMsg'] : "Approval Type Updated Successfully."
      );
      this.approvalTypeService.approvalTypeDataChanged.emit(true);
    });
    this.onNoClick();
  }

}