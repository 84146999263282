import { Component, OnInit, Inject, Output, ViewChild, OnDestroy } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  DateAdapter,
  MAT_DATE_FORMATS,
} from "@angular/material";
import { OrganizationService } from "src/app/services/organization.service";
import { HttpErrorResponse } from "@angular/common/http";
import { NotificationService } from "src/app/services/notification.service";
import { GridApi } from "ag-grid-community";
import moment from "moment";
import { LimitRequestService } from "src/app/services/limit-request.service";
import { UserService } from "src/app/services/user.service";
import { SpinnerService } from "src/app/services/spinner.service";
import { NgForm } from "@angular/forms";
import { MatExpansionPanel } from "@angular/material";
import { SiteService } from "src/app/services/site.service";
import {
  MatDatePickerAdapter,
  CUSTOM_DATE_FORMATS,
} from "src/app/common/mat-date-picker-formatter/mat-date-picker-formatter";
import { DatePipe } from "@angular/common";
import { ApproveRejectCellRendererComponent, IApproveRejectEmitter } from "src/app/common/AgGrid/approve-reject-cell-renderer/approve-reject-cell-renderer.component";
import { ApprovalService, IApprovalRequestStatusResponse } from "src/app/services/approval.service";
import { Subscription } from "rxjs";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-view-limit-approval-modal",
  templateUrl: "./view-limit-approval-modal.component.html",
  styleUrls: ["./view-limit-approval-modal.component.css"],
  providers: [
    { provide: DateAdapter, useClass: MatDatePickerAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export class ViewLimitApprovalModalComponent implements OnInit, OnDestroy {
  @ViewChild("requestForm") requestForm: NgForm;
  @ViewChild("freePanel") freezePanel: MatExpansionPanel;

  private gridApi: GridApi;

  public step = 0;
  public creationDate;
  public startDate;
  public endDate;
  public organizationName: string;
  public approversComment: string;
  public createdBy: string;
  public requestersComment: string;
  public requesterName: string;
  public parkingLotName: string;
  public entryPointGroupName: string;
  public numVisitors: number;
  public status: string;
  public modifiedDate;
  public modifiedByName;
  public createdByName;
  
  
  public approvalStatusLoaded: boolean = false;

  public approversColumnDefs = null;
  public rowData: Array<any> = null;
  public rowClassRules;

  public approvalData: {
    ApprovalRequestID: number;
    RequestedBy: string;
    RequestedOn: string;
    Status: string;
    Organization: string;
    StartDate: string;
    EndDate: string;
    ParkingLot: string;
    EntryPointGroup: string;
    IncreaseByLimit: number;
    ApproverComments: string;
    RequesterComments: string;
    CreatedByName: string;
    ModifiedByName: string;
    ModifiedDate: string;
    AffectedEntityDetails: Array<{
      AffectedEntity: string;
      TargetCapacity: number;
      ApprovedSummary: number;
      RequestedSummary: number;
      series: Array<{
        date: string;
        ApprovedCapacity: number;
        RequestedCapacity: number;
      }>;
    }>;
  };
 
  

  public frameworkComponents = {
    buttonRenderer: ApproveRejectCellRendererComponent,
  };

  public reviewerRowData: IApprovalRequestStatusResponse[] = [];

  public organization: {
    OrganizationID: number;
    Name: string;
    Active: number;
    Internal: number;
    StartDate: string;
    EndDate: string;
    ModifiedBy: number;
    CreatedBy: number;
    CreatedDate: string;
  } = null;

  private subscription: Subscription;
  protected arrayData: Array<{
    RequestID: number;
    ApprovalType: number;
  }> = [];
  public screenLabels: Array<string> = [];
  protected tableColDef = [];
  protected reviewerColumnDefs = [];
  protected appLanguage;
  public defaultColDef : object;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<ViewLimitApprovalModalComponent>,
    private notificationService: NotificationService,
    private limitService: LimitRequestService,
    private userService: UserService,
    private approvalService: ApprovalService,
    public spinnerService: SpinnerService,
    public siteService: SiteService,
    private datePipe: DatePipe,
    protected messageService: MessageService,
  ) {
    // set row styles
    this.rowClassRules = {
      "row-warning": (params) => {
        var condition =
          params.data.approvedSummary < 0 ||
          params.data.totalRequestedSummary < 0;
        return condition;
      },
    };
    this.defaultColDef = {
      resizable: true,
  }
  }

  async ngOnInit() {
    this.creationDate = this.formatDate(this.data.CreatedDate);

    //labels by message service
    if (localStorage.getItem('ViewLimitRequestApprovalLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('ViewLimitRequestApprovalLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "View Limit Approval Modal",
        "OrgCombinedLimitRequest"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('ViewLimitRequestApprovalLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    this.arrayData.push({
      RequestID: this.data.OrgCombinedLimitRequestID,
      ApprovalType: this.data[0].ApprovalType
    });
    
    // subscribe to the approval and rejection events
    this.subscription = this.approvalService.approvedRejected.subscribe(
      (event: IApproveRejectEmitter) => {
        if (event.isApproved) {
          this.onApprove(event.data);
        } else {
          this.onReject(event.data);
        }
      }
    );

        this.approvalData = this.data[0];
        this.organizationName = this.approvalData.Organization;
        this.parkingLotName = this.approvalData.ParkingLot;
        this.entryPointGroupName = this.approvalData.EntryPointGroup;
        this.requestersComment = this.approvalData.RequesterComments;
        this.approversComment = this.approvalData.ApproverComments;
        this.createdBy = this.approvalData.RequestedBy;
        this.numVisitors = this.approvalData.IncreaseByLimit;
        this.status = this.approvalData.Status;
        this.startDate = this.approvalData.StartDate;
        this.endDate = this.approvalData.EndDate;
        this.creationDate = this.approvalData.RequestedOn;
        this.modifiedDate = this.approvalData.ModifiedDate;
        this.modifiedByName = this.approvalData.ModifiedByName;
        this.createdByName = this.approvalData.CreatedByName;

        this.prepareAffectedEntityStructure(
          this.approvalData.AffectedEntityDetails
        );
    
    // this.loadApprovalStatus();
  }


setColumnDef() {
  this.tableColDef = [
    {
      headerName: this.screenLabels["AgGridAffectedEntity"]
        ? this.screenLabels["AgGridAffectedEntity"]
        : "Affected Entity",
      field: "affectedEntity",
      sortable: true,
      // width: 150,
      filter: true,
      cellStyle: { color: "#000" },
      hide: false,
    },
    {
      headerName: this.screenLabels["AgGridTargetCapacity"]
        ? this.screenLabels["AgGridTargetCapacity"]
        : "Target Capacity",
      field: "targetCapacity",
      sortable: true,
      // width: 150,
      filter: true,
      cellStyle: { color: "#000" },
      hide: false,
    },
  ];
  
}

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDef()
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.approvalService.orgLimitRequestOpen = false;
  }

  setStep(index: number): void {
    this.step = index;
  }
  onMainHeaderClose() {
    this.freezePanel.open();
  }
  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  onSaveChanges() {
    this.onNoClick();
  }

  public onGridReady(params) {
    this.gridApi = params.api;
  }

  public onFirstDataRendered() {
    this.gridApi.sizeColumnsToFit();
  }

  //dynamically prepare the Column Def and Row data for ag grid to display the Entity Summary
  private prepareAffectedEntityStructure(
    affectedEntity: {
      AffectedEntity: string;
      TargetCapacity: number;
      ApprovedSummary: number;
      RequestedSummary: number;
      series: {
        date: string;
        ApprovedCapacity: number;
        RequestedCapacity: number;
      }[];
    }[]
  ) {
    const data: Array<any> = [];

    let approvedCapacitySection: any = {};
    let requestedCapacitySection: any = {};

    let isApprovedCapacityGenerated = false;
    let isApprovedRequestedGenerated = false;

    let reviewerColumnDefs: any = [
        {
          headerName: this.screenLabels["Status"]
          ? this.screenLabels["Status"]
          : "Status",
          field: "Status",
          width: 100,
          wrapText: true,
          autoHeight: true,
        },
        {
          headerName: this.screenLabels["Level"]
          ? this.screenLabels["Level"]
          : "Level",
          field: "ApprovalLevel",
          width: 80,
          wrapText: true,
          autoHeight: true,
        },
        {
          headerName: this.screenLabels["ApprovalGroup"]
          ? this.screenLabels["ApprovalGroup"]
          : "Approval Group",
          field: "ApprovalGroupName",
          wrapText: true,
          autoHeight: true,
          cellStyle: { "white-space": "normal" },
        },
        {
          headerName:this.screenLabels["ReviewedOn"]
          ? this.screenLabels["ReviewedOn"]
          : "Reviewed On",
          field: "ReviewedOn",
          width: 100,
          wrapText: true,
          autoHeight: true,
          cellStyle: { "white-space": "normal" },
          cellRenderer: (data) => {
            // const formattedDate = this._datePipe.transform(
            //   data.value,
            //   // "MM/dd/yyyy"
            //   "short"
            // );
            // return formattedDate ? formattedDate : null;
            return this.formatDate(data.value);
          },
        },
        {
          headerName:this.screenLabels["ReviewedBy"]
          ? this.screenLabels["ReviewedBy"]
          : "Reviewed By",
          field: "ApprovedBy",
          width: 150,
          wrapText: true,
          autoHeight: true,
          cellStyle: { "white-space": "normal" },
        },
        {
          headerName:this.screenLabels["ReviewersComments"]
          ? this.screenLabels["ReviewersComments"]
          : "Reviewer's Comments",
          field: "ApprovalComments",
          editable: (params) => {
            return params.data.CanBeActionedOn === 1 &&
              params.data.Status.toLowerCase() !== "Approved".toLowerCase() &&
              params.data.Status.toLowerCase() !== "Rejected".toLowerCase() &&
              params.data.Status.toLowerCase() !== "Contingent".toLowerCase()
              ? true
              : false;
          },
          // editable: this.checkIsEditable.bind(this)
          wrapText: true,
          autoHeight: true,
          cellStyle: { "white-space": "normal" },
        },
        {
          headerName:this.screenLabels["Action"]
          ? this.screenLabels["Action"]
          : "Action",
          field: "ApprovalGroupID",
          cellRenderer: "buttonRenderer",
          autoHeight: true,
        },
        {
          headerName:this.screenLabels["CanBeActionedOn"]
          ? this.screenLabels["CanBeActionedOn"]
          : "Can Be Actioned On",
          field: "CanBeActionedOn",
          hide: true,
        },
        {
          field: "ApprovalGroupRequestID",
          hide: true,
        },
        {
          field: "ApprovalRequestID",
          hide: true,
        },
        {
          field: "ApprovalGroupID",
          hide: true,
        },
        {
          field: "UserID",
          hide: true,
        },
      ];

    let tableColDef: any = [
      {
        headerName: this.screenLabels["AgGridAffectedEntity"]
          ? this.screenLabels["AgGridAffectedEntity"]
          : "Affected Entity",
        field: "affectedEntity",
        sortable: true,
        // width: 150,
        filter: true,
        cellStyle: { color: "#000" },
        hide: false,
      },
      {
        headerName: this.screenLabels["AgGridTargetCapacity"]
          ? this.screenLabels["AgGridTargetCapacity"]
          : "Target Capacity",
        field: "targetCapacity",
        sortable: true,
        // width: 150,
        filter: true,
        cellStyle: { color: "#000" },
        hide: false,
      },
    ];

    approvedCapacitySection.headerName = this.screenLabels[
      "AgGridApprovedCapacity"
    ]
      ? this.screenLabels["AgGridApprovedCapacity"]
      : "Approved Capacity";
    requestedCapacitySection.headerName = this.screenLabels[
      "AgGridTotalRequestedCapacity"
    ]
      ? this.screenLabels["AgGridTotalRequestedCapacity"]
      : "Total Requested Capacity";

    approvedCapacitySection.children = [];
    requestedCapacitySection.children = [];

    affectedEntity.forEach((item) => {
      const row = {
        affectedEntity: item.AffectedEntity,
        targetCapacity: item.TargetCapacity,
        approvedSummary: item.ApprovedSummary,
        totalRequestedSummary: item.RequestedSummary,
      };

      item.series.forEach((s) => {
        let approvedCapacityRowDef: any = {};
        let requestedCapacityRowDef: any = {};

        const dateString = s.date.replace("-", "").replace("-", "");
        row["ApprovedCapacity" + dateString] = s.ApprovedCapacity;
        row["TotalRequested" + dateString] = s.RequestedCapacity;

        approvedCapacityRowDef.headerName = s.date;
        approvedCapacityRowDef.field = "ApprovedCapacity" + dateString;
        approvedCapacityRowDef.sortable = true;
        approvedCapacityRowDef.filter = true;
        approvedCapacityRowDef.cellStyle = { color: "#000" };
        approvedCapacityRowDef.columnGroupShow = "open";
        approvedCapacityRowDef.hide = false;

        requestedCapacityRowDef.headerName = this.formatDate(s.date);
        requestedCapacityRowDef.field = "TotalRequested" + dateString;
        requestedCapacityRowDef.sortable = true;
        requestedCapacityRowDef.filter = true;
        requestedCapacityRowDef.cellStyle = { color: "#000" };
        requestedCapacityRowDef.columnGroupShow = "open";
        requestedCapacityRowDef.hide = false;

        approvedCapacitySection.children.push(approvedCapacityRowDef);
        requestedCapacitySection.children.push(requestedCapacityRowDef);
      });

      data.push(row);
    });

    approvedCapacitySection.children.push({
      headerName: this.screenLabels["AgGridSummary"]
        ? this.screenLabels["AgGridSummary"]
        : "Summary",
      field: "approvedSummary",
      sortable: true,
      filter: true,
      cellStyle: { color: "#000" },
      hide: false,
    });

    requestedCapacitySection.children.push({
      headerName: this.screenLabels["AgGridSummary"]
        ? this.screenLabels["AgGridSummary"]
        : "Summary",
      field: "totalRequestedSummary",
      sortable: true,
      // width: 150,
      filter: true,
      cellStyle: { color: "#000" },
      hide: false,
    });

    tableColDef.push(approvedCapacitySection);
    tableColDef.push(requestedCapacitySection);
    this.approversColumnDefs = tableColDef;
    this.rowData = data;
  }

  // // invoked when a limit request is approved
  // protected async onApproveLimit() {
  //   const approversComment = this.requestForm.value.approversComment;
  //   this.onNoClick();

  //   await this.limitService.approveLimitRequestAPI(
  //     this.data.OrgCombinedLimitRequestID,
  //     approversComment
  //   );
  //   this.notificationService.success(this.limitService.message);
  // }

  // // invoked when a limit request is rejected
  // protected async onRejectLimit() {
  //   const approversComment = this.requestForm.value.approversComment;
  //   this.onNoClick();

  //   await this.limitService.rejectLimitRequestAPI(
  //     this.data.OrgCombinedLimitRequestID,
  //     approversComment
  //   );
  //   this.notificationService.success(this.limitService.message);
  // }

  private formatDate(date: string) {
    const formattedDate = this.datePipe.transform(
      date,
      this.siteService.dataTimeFormat.angularDateFormat
    );
    return formattedDate ? formattedDate : null;
  }    

  private async onApprove(reviewData: IApprovalRequestStatusResponse) {
    const approversComment = reviewData.ApprovalComments;
    const approvalGroupRequestID = reviewData.ApprovalGroupRequestID;
    const approvalRecordID = reviewData.RecordID;

    await this.limitService.approveLimitRequestAPI(
      approvalRecordID,
      approvalGroupRequestID,
      approversComment
    );
    this.notificationService.success(this.limitService.message);
  }

  private async onReject(reviewData: IApprovalRequestStatusResponse) {
    const approversComment = reviewData.ApprovalComments;
    const approvalGroupRequestID = reviewData.ApprovalGroupRequestID;
    const approvalRecordID = reviewData.RecordID;

    await this.limitService.rejectLimitRequestAPI(
      approvalRecordID,
      approvalGroupRequestID,
      approversComment
    );
    this.notificationService.success(this.limitService.message);
  }
  
}
