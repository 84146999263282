import { ViewSelectorService } from "./../../services/view-selector.service";
import { MatDialog } from "@angular/material";
import { Component,Renderer2, OnInit, ChangeDetectorRef } from "@angular/core";
import { DashboardComponent } from "../dashboard.component";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { SearchService } from "../../services/search.service";
import { PassService } from "../../services/pass.service";
import { DatePipe } from "@angular/common";
import { BuildingService } from "../../services/building.service";
import { EntryPointService } from "../../services/entry-point.service";
import { NotificationService } from "../../services/notification.service";
import { DialogService } from "../../services/dialog.service";
import { UserService } from "src/app/services/user.service";
import { RoleMapService } from "src/app/services/role-map.service";
import { IpService } from "src/app/services/ipService.service";
import { SpinnerService } from "../../services/spinner.service";
import { AuthService } from "src/app/services/auth.service";
import { WidgetService } from "src/app/services/widget.service";
import { TeamService } from "src/app/services/team-service";
import { OrganizationService } from "src/app/services/organization.service";
import { SiteService } from "src/app/services/site.service";
import { UtilityService } from "src/app/services/utility.service";
import { MessageService } from "src/app/services/message.service";
import { UserActionsService } from "src/app/services/user-actions.service";
import { ParkingLotService } from "src/app/services/parking-lot.service";
import { ApprovalService } from "src/app/services/approval.service";

@Component({
  selector: "app-dashboard-mobile",
  templateUrl: "./dashboard.component.mobile.html",
  styleUrls: ["./dashboard.component.mobile.css"],
})
export class DashBoardMobileComponent extends DashboardComponent {
  constructor(
    dialog: MatDialog,
    http: HttpClient,
    service: ViewSelectorService,
    searchService: SearchService,
    passService: PassService,
    datePipe: DatePipe,
    buildingService: BuildingService,
    entryPointService: EntryPointService,
    notificationService: NotificationService,
    dialogService: DialogService,
    spinnerService: SpinnerService,
    userService: UserService,
    roleMapService: RoleMapService,
    ipService: IpService,
    authService: AuthService,
    widgetService: WidgetService,
    teamService: TeamService,
    organizationService: OrganizationService,
    siteService:SiteService,
    utilService: UtilityService,
    renderer: Renderer2,
    userActionsService: UserActionsService,
    messageService: MessageService,
    parkingLotService: ParkingLotService,
    approvalService: ApprovalService
  ) {
    super(
      dialog,
      http,
      service,
      searchService,
      passService,
      datePipe,
      buildingService,
      entryPointService,
      notificationService,
      dialogService,
      spinnerService,
      userService,
      roleMapService,
      ipService,
      authService,
      widgetService,
      teamService,
      organizationService,
      siteService,
      utilService,
      renderer,
      userActionsService,
      messageService,
      parkingLotService,
      approvalService
    );
  }
  rowData: any;
}
