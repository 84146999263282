import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";
import {
  MatExpansionPanel,
  MatDialogRef,
  MAT_DIALOG_DATA,
  DateAdapter,
  MAT_DATE_FORMATS,
} from "@angular/material";
import { NgForm, FormControl } from "@angular/forms";
import { WidgetService, widgetType } from "src/app/services/widget.service";
import { SearchService } from "src/app/services/search.service";
import { Observable } from "rxjs";
import { cloneDeep } from "lodash";
import { PassTemplateService } from "src/app/services/pass-template-service";
import { SiteService } from "src/app/services/site.service";
import { MessageService } from "src/app/services/message.service";
import {
  MatDatePickerAdapter,
  CUSTOM_DATE_FORMATS,
} from "src/app/common/mat-date-picker-formatter/mat-date-picker-formatter";

@Component({
  selector: "app-startdate-config-modal",
  templateUrl: "./startdate-config.modal.component.html",
  styleUrls: ["./startdate-config.modal.component.css"],
  providers: [
    { provide: DateAdapter, useClass: MatDatePickerAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export class StartdateConfigModalComponent implements OnInit {
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  @ViewChild("filterForm") filterForm: NgForm;

  protected step = 0;
  protected loaded = true;

  protected minSeriesLimit = 1;
  protected maxSeriesLimit = 6;

  protected selectedWidgetID = null;
  // protected selectedSeries: Array<string> = [];
  protected availableSeries: Array<string> = [];

  protected autoCompleteOptionItems: Array<{
    name: string;
    show: boolean;
  }>;

  filteredOptions: Observable<
    Array<{
      name: string;
      show: boolean;
    }>
  >;

  protected selectedDateRangeOption: number = undefined;

  protected seriesControl = new FormControl([]);
  protected filterControl = new FormControl();

  protected filterStartDate: Date;
  protected filterEndDate: Date;
  protected previousData:any;
  protected weekwiseEnable:boolean = false;
  protected newCourse:any;
  public screenLabels: Array<string> = [];
  protected userWidgetConfig: {
    UserWidgetFilterID: number;
    UserWidgetID: number;
    DateOption: number;
    Series: string[];
    Active: number;
  };

  protected dateOptions: Array<{
    PickListID: number;
    TableName: string;
    FieldName: string;
    DisplayValue: string;
    Active: string;
  }> = [];
  protected dayRange = [];

  protected weekList: Array<{
    PickListID: number;
    TableName: string;
    FieldName: string;
    DisplayValue: string;
    Active: boolean;
  }> = [];

  protected dateMethod: Array<{
    PickListID: number;
    TableName: string;
    FieldName: string;
    DisplayValue: string;
    Active: boolean;
  }> = [];
  protected appLanguage;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<StartdateConfigModalComponent>,
    public searchService: SearchService,
    protected widgetService: WidgetService,
    protected passTemplateService: PassTemplateService,
    protected siteService: SiteService,
    protected messageService: MessageService,
  ) {
    console.log(data);
  }

  async ngOnInit() {
    //copying to store previous data 
    this.previousData= Object.assign({}, this.data);

    if(this.previousData.DateMethodName === "WeekWise"){
      this.weekwiseEnable = true;
    }else{
      this.weekwiseEnable = false;
    }
   
    //labels by message service
if (localStorage.getItem('StartDateConfigLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('StartDateConfigLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Start Date Config Modal",
      "PassTemplate"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('StartDateConfigLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    // week Day range
    this.dayRange = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    this.passTemplateService.getPassTemplatePickListTablesAPI().then(() => {
      this.dateMethod = this.passTemplateService.passTemplateTables.filter(
        (f) => f.FieldName == "MethodTypeParam"
      );
      this.weekList = this.passTemplateService.passTemplateTables.filter(
        (f) => f.FieldName == "WeekOptionParam"
      );
      console.log(this.passTemplateService.passTemplateTables);
    });
  }

  onDateTypeChange(event) {
    // this.data.DateMethodName = this.dateMethod.find((f) => {
    //         f.PickListID == this.data.DateMethod;
    //       }).DisplayValue;

    

    this.weekwiseEnable = false;
      this.dateMethod.forEach((element) => {
          if (this.data.DateMethod == element.PickListID && element.DisplayValue === "WeekWise") {
            console.log(element);
            this.weekwiseEnable = true;
          }
    });
    
    console.log(this.data.DateMethodName);
    this.data.StartDay = 0;
    this.data.StartWeek = 0;
  }

  onNoClick() {
    this.dateMethod.forEach((element) => {
      if (element.PickListID == this.previousData.DateMethod) {
        console.log(element);
        this.previousData.DateMethodName = element.DisplayValue;
      }
    });

    if(this.previousData.DateMethod === null){
      this.previousData.DateMethodName = "";
    }
    this.weekList.forEach((element) => {
      if (element.PickListID === this.previousData.StartWeek) {
        this.previousData.Week = element.DisplayValue;
      }
    });

    this.dialogRef.close(this.previousData);
  }


  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  onMainHeaderClose() {
    this.freezePanel.open();
  }

  protected async onSaveDate() {
    console.log(this.weekList);
    console.log(this.data.StartWeek);

    this.dateMethod.forEach((element) => {
      if (element.PickListID == this.data.DateMethod) {
        console.log(element);
        this.data.DateMethodName = element.DisplayValue;
      }
    });

    if(this.data.DateMethod === null){
      this.data.DateMethodName = "";
    }
    this.weekList.forEach((element) => {
      if (element.PickListID === this.data.StartWeek) {
        this.data.Week = element.DisplayValue;
      }
    });

    this.dialogRef.close(this.data);
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }
}
