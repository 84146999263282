import { Component, OnInit, Input } from "@angular/core";
import { UtilityService } from "src/app/services/utility.service";
import { SiteService } from "src/app/services/site.service";

@Component({
  selector: "app-master-data-tool-tip",
  templateUrl: "./master-data-tool-tip.component.html",
  styleUrls: ["./master-data-tool-tip.component.css"],
})
export class MasterDataToolTipComponent implements OnInit {
  @Input() createdDate;
  @Input() modifiedDate;

  @Input() createdByFirstName: string;
  @Input() createdByMiddleName: string;
  @Input() createdByLastName: string;

  @Input() modifiedByFirstName: string;
  @Input() modifiedByMiddleName: string;
  @Input() modifiedByLastName: string;

  @Input() createdBy: string;
  @Input() modifiedBy: string;

  constructor(
    protected utilService: UtilityService,
    protected siteService: SiteService
  ) {}

  ngOnInit(): void {
    // this.createdBy = this.createdByFirstName + " " + this.createdByLastName;
    // this.modifiedBy = this.modifiedByFirstName + " " + this.modifiedByLastName;

    this.createdDate = this.createdDate
      ? this.utilService.getSiteTime(this.createdDate)
      : this.createdDate;

    this.modifiedDate = this.modifiedDate
      ? this.utilService.getSiteTime(this.modifiedDate)
      : this.modifiedDate;
  }
}
