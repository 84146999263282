import { NewpassModalComponent } from "./../newpass-modal.component";
import { Component, OnInit, AfterViewInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ViewSelectorService } from "../../services/view-selector.service";
import { PassService } from "../../services/pass.service";
import { BuildingService } from "../../services/building.service";
import { UserService } from "../../services/user.service";
import { DatePipe } from "@angular/common";
import { NotificationService } from "../../services/notification.service";
import { EntryPointService } from "../../services/entry-point.service";
import { OrganizationService } from "src/app/services/organization.service";
import { SearchService } from "src/app/services/search.service";
import { EntryPointGroupService } from "../../services/entry-point-group.service";
import * as _moment from "moment";
import { SpinnerService } from "../../services/spinner.service";
import { default as _rollupMoment } from "moment";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
} from "@angular/material";
import { DialogService } from "src/app/services/dialog.service";
import { ParkingLotService } from "src/app/services/parking-lot.service";
import { AuthService } from "src/app/services/auth.service";
import { RequiredFieldsService } from "../../services/required-fields.service";
import { TemplateCategoryService } from "src/app/services/template-category-service";
import { PassTemplateService } from "src/app/services/pass-template-service";
import { TeamService } from "src/app/services/team-service";
import { UtilityService } from "src/app/services/utility.service";
import { SiteService } from "src/app/services/site.service";
import {
  MatDatePickerAdapter,
  CUSTOM_DATE_FORMATS,
} from "src/app/common/mat-date-picker-formatter/mat-date-picker-formatter";
import { TimezoneService } from 'src/app/services/timezone.service';
import { HolidayService } from "src/app/services/holiday-calendar.service";
import { MessageService } from "src/app/services/message.service";
const moment = _rollupMoment || _moment;

export interface IValidationError {
  rowNumber: number;
  fieldName: string;
  errorText: string;
}

@Component({
  selector: "app-newpass-modal-desktop",
  templateUrl: "./newpass-modal.component.desktop.html",
  styleUrls: ["./newpass-modal.component.desktop.css"],
  providers: [
    { provide: DateAdapter, useClass: MatDatePickerAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export class NewpassModalComponentDesktop extends NewpassModalComponent {
  constructor(
    dialogRef: MatDialogRef<NewpassModalComponent>,
    _snackBar: MatSnackBar,
    service: ViewSelectorService,
    passService: PassService,
    buildingService: BuildingService,
    userService: UserService,
    datePipe: DatePipe,
    notificationService: NotificationService,
    entryPointService: EntryPointService,
    entryPointGroupService: EntryPointGroupService,
    dialogService: DialogService,
    spinnerService: SpinnerService,
    organizationService: OrganizationService,
    searchService: SearchService,
    parkingLotService: ParkingLotService,
    authService: AuthService,
    requiredFieldService: RequiredFieldsService,
    templateCategoryService: TemplateCategoryService,
    passTemplateService: PassTemplateService,
    teamService: TeamService,
    utilService: UtilityService,
    siteService: SiteService,
    timezoneService: TimezoneService,
    holidayService: HolidayService,
    messageService: MessageService
  ) {
    super(
      dialogRef,
      _snackBar,
      service,
      passService,
      buildingService,
      userService,
      datePipe,
      notificationService,
      entryPointService,
      entryPointGroupService,
      dialogService,
      spinnerService,
      organizationService,
      searchService,
      parkingLotService,
      authService,
      requiredFieldService,
      templateCategoryService,
      passTemplateService,
      teamService,
      utilService,
      siteService,
      timezoneService,
      holidayService,
      messageService
    );
  }
}
