import { Component, OnInit } from "@angular/core";
import { ParkingLot } from "../models/parking-lot.model";
import { MatDialog } from "@angular/material";
import { DialogService } from "../services/dialog.service";
import { NotificationService } from "../services/notification.service";
import { SearchService } from "../services/search.service";
import { ViewParkingModalComponent } from "./modals/view-parking/view-parking-modal.component";
import { CreateParkingModalComponent } from "./modals/create-parking/create-parking-modal.component";
import { ParkingLotService } from "../services/parking-lot.service";
import { UpdateParkingModalComponent } from "./modals/update-parking/update-parking-modal.component";
import { HttpErrorResponse } from "@angular/common/http";
import { ParkingLotGroupService } from "../services/parking-lot-group.service";
import { EntryPointService } from "../services/entry-point.service";
import { UserService } from "../services/user.service";
import { SiteService } from "../services/site.service";
import { GridApi } from "ag-grid-community";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-parking-lot",
  templateUrl: "./parking-lot.component.html",
  styleUrls: ["./parking-lot.component.css"],
})
export class ParkingLotComponent implements OnInit {
  private selectedParking: ParkingLot;
  private paginationPageSize;
  private gridApi: GridApi;
  protected isRowSelected: boolean;
  protected rowData: ParkingLot[];
  protected rowClassRules;
  protected parkingColumnDefs = [];
  protected appLanguage;
  public screenLabels: Array<string> = [];
  public isActive: boolean = false;
  public defaultColDef : object;

  constructor(
    protected dialog: MatDialog,
    protected dialogService: DialogService,
    protected notificationService: NotificationService,
    protected searchService: SearchService,
    protected parkingLotService: ParkingLotService,
    protected parkingLotGroupService: ParkingLotGroupService,
    protected entryPointService: EntryPointService,
    protected userService: UserService,
    private siteService: SiteService,
    protected messageService: MessageService,
  ) {
    this.paginationPageSize = 50;
    // set row styles
    this.rowClassRules = {
      "row-inactive": (params) => {
        var condition = params.data.Active === 0;
        return condition;
      },
    };
    this.defaultColDef = {
      resizable: true,
  }
  }

  ngOnInit(): void {
    this.searchService.setPageName("Parking Lot");

    //labels by message service
    if (localStorage.getItem('ParkingLotlLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('ParkingLotlLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Parking Lot",
        "ParkingLot"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('ParkingLotlLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        this.isRowSelected = false;
        // call the API
          this.parkingLotGroupService
            .getParkingLotGroupAPI()
            .then(() => {
              this.rowData = this.searchService.searchRecords;
              this.gridApi.paginationGoToPage(0);
            })
            .catch((err: HttpErrorResponse) =>
              this.notificationService.failure(err.message)
            );
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    this.searchService.searchInitiated.subscribe((eventData: boolean) => {
      if (eventData) {
        this.searchService.smartSearchPromise
          .then(() => {
            this.rowData = this.searchService.searchRecords;
            this.gridApi.paginationGoToPage(0);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    });

    // subscribe to site changed
    this.siteService.siteChanged.subscribe((event: boolean) => {
      if(event){
        this.searchService
        .smartSearchMultiSiteAPI(null)
        .then(() => {
          this.rowData = this.searchService.searchRecords;   
          this.gridApi.paginationGoToPage(0);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
      }
    });

    this.parkingLotService.parkingLotDataChanged.subscribe(
      (dataChanged: boolean) => {
        if (dataChanged) {
          this.searchService
            .smartSearchMultiSiteAPI(null)
            .then(() => {
              this.rowData = this.searchService.searchRecords;
              this.gridApi.paginationGoToPage(0);
            })
            .catch((err: HttpErrorResponse) => {
              this.notificationService.failure(err.message);
            });
        }
      }
    );

    
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  setColumnDef(){
    this.parkingColumnDefs = [
      {
        headerName: "",
        field: "",
        width: 80,
        cellStyle: { color: "#589bf8" },
        hide: false,
        checkboxSelection: true,
        resizable: false,
        sortable: false,
        lockPosition: true,
      },
      {
        headerName: this.screenLabels["ParkingLotID"]
        ? this.screenLabels["ParkingLotID"]
        : "Parking Lot ID",
        field: "ParkingLotID",
        hide: true,
        // sort: "desc",
        sortable: true, 
      },
      {
        headerName: this.screenLabels["NameAgGrid"]
          ? this.screenLabels["NameAgGrid"]
          : "Name",
        field: "Name",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["DescriptionAgGrid"]
          ? this.screenLabels["DescriptionAgGrid"]
          : "Description",
        field: "Description",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["DriveOnRequiredAgGrid"]
          ? this.screenLabels["DriveOnRequiredAgGrid"]
          : "Drive-On Required",
        field: "DriveOnRequired",
        hide: false,
        sortable: true,
        cellRenderer: (data) => {
          return data.value ? "Yes" : "No";
        },
      },

      {
        headerName: this.screenLabels["ParkingLotGroupAgGrid"]
          ? this.screenLabels["ParkingLotGroupAgGrid"]
          : "Parking Lot Group",
        field: "ParkingLotGroupID",
        hide: false,
        sortable: true,
        cellRenderer: (data) => {
          return this.parkingLotGroupService
            .getParkingLotGroups()
            .filter((pg) => pg.ParkingLotGroupID == +data.value)
            .map((e) => e.Name)[0];
        },
      },
      {
        headerName: this.screenLabels["DefaultEntryPointGrid"]
          ? this.screenLabels["DefaultEntryPointGrid"]
          : "Default Entry Point",
        field: "DefaultEntryPoint",
        hide: false,
        sortable: true,
        cellRenderer: (data) => {
          return this.entryPointService
            .getEntryPoints()
            .filter((e) => e.EntryPointID == +data.value)
            .map((e) => e.Description)[0];
        },
      },

      {
        headerName: this.screenLabels["Active"]
        ? this.screenLabels["Active"]
        : "Active",
        field: "Active",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["ModifiedDate"]
        ? this.screenLabels["ModifiedDate"]
        : "Modified Date",
        field: "ModifiedDate",
        hide: true,
        sortable: true,
      },
      {
        headerName:  this.screenLabels["ModifiedBy"]
        ? this.screenLabels["ModifiedBy"]
        : "Modified By",
        field: "ModifiedBy",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["CreatedDate"]
        ? this.screenLabels["CreatedDate"]
        : "Created Date",
        field: "CreatedDate",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["CreatedBY"]
        ? this.screenLabels["CreatedBY"]
        : "Created By",
        field: "CreatedBY",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["CreatedByName"]
        ? this.screenLabels["CreatedByName"]
        : "Created By Name",
        field: "CreatedByName",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["ModifiedByName"]
        ? this.screenLabels["ModifiedByName"]
        : "Modified By Name",
        field: "ModifiedByName",
        hide: true,
        sortable: true,
      },
    ];
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDef()
  }
  
  // make ag-rid column fill their container
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  // sets the data of selected row
  onSelectionChanged($event) {
    const selectedRows: ParkingLot[] = $event.api.getSelectedRows();
    if (selectedRows.length) {
      this.selectedParking = selectedRows[0];
      this.isRowSelected = true;
    } else {
      this.isRowSelected = false;
    }
    if(this.selectedParking.Active === 0){
      this.isActive = false;
    } else {
      this.isActive = true;
    }
  }

  // Edit selected parking
  EditParkingLot() {
    this.dialog.open(UpdateParkingModalComponent, {
      disableClose: true,
      data: this.selectedParking,
    });
  }

  // open selected parking
  openParkingLot($event) {
    var selectedParkingLot: ParkingLot = $event.data;
    this.dialog.open(ViewParkingModalComponent, {
      disableClose: true,
      data: selectedParkingLot,
    });
  }

  // open modal for creating a new parking lot
  onCreateNewParkingLot() {
    this.dialog.open(CreateParkingModalComponent, {
      disableClose: true,
      data: {},
    });
  }

  // Delete Parking Lot
  DeleteParkingLot() {
    var name = this.selectedParking.Name;
    var message: string =
    this.screenLabels["DeleteParkingLotConfirmMsg"]
    ? this.screenLabels["DeleteParkingLotConfirmMsg"]
    : "Are you sure you want to Delete Parking Lot Group <<id>> ?";
    const capacityMessage = message.replace("<<id>>",name)
    this.dialogService
      .openConfirmDialog(this.screenLabels["DeleteParkingLotBtn"]
      ? this.screenLabels["DeleteParkingLotBtn"]
      : "Delete Parking Lot", capacityMessage)
      .afterClosed()
      .subscribe((isConfirmed: boolean) => {if (isConfirmed) {
          this.parkingLotService
            .deleteParkingLot(this.selectedParking)
            .then(() => {
              this.notificationService.success(
                this.screenLabels["DeleteParkingLotSuccessMsg"]
        ? this.screenLabels["DeleteParkingLotSuccessMsg"]
        : "Parking Lot was Deleted Successfully.",
              );
            });
        }
      });
  }
}
