import { Component, OnInit } from "@angular/core";
import { UserService } from "src/app/services/user.service";
import { SearchService } from "src/app/services/search.service";
import { GridApi } from "ag-grid-community";
import { MatDialog } from "@angular/material";
import { ViewParkingLimitRequestModalComponent } from "../modals/view-parking-limit-request-modal/view-parking-limit-request-modal.component";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { NotificationService } from "src/app/services/notification.service";
import { SpinnerService } from "src/app/services/spinner.service";
import moment from "moment";
import { DialogService } from "src/app/services/dialog.service";
import { LimitRequestService } from "src/app/services/limit-request.service";
import { CreateLimitRequestModalComponent } from "../modals/create-limit-request-modal/create-limit-request-modal.component";
import { SiteService } from "src/app/services/site.service";
import { LimitRequestsAgGridConfig } from "./limit-requests-ag-grid-config";
import { DatePipe } from "@angular/common";
import { MessageService } from "src/app/services/message.service";
import { ViewSelectorService } from "src/app/services/view-selector.service";
@Component({
  selector: "app-limit-requests",
  templateUrl: "./limit-requests.component.html",
  styleUrls: ["./limit-requests.component.css"],
})
export class LimitRequestsComponent implements OnInit {
  private gridApi: GridApi;
  private applicationUsers = [];
  public reqTranslatedResponse: string;
  protected isRowSelected: boolean = false;
  protected selectedRequest = null;

  protected limitRequestColumnDefs = [];
  protected rowData: Array<any> = [];
  protected screenLabels: Array<string> = [];
  protected appLanguage;
  public defaultColDef: object;

  constructor(
    private searchService: SearchService,
    protected userService: UserService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private spinnerService: SpinnerService,
    private dialogService: DialogService,
    private limitRequestService: LimitRequestService,
    private siteService: SiteService,
    private datePipe: DatePipe,
    protected messageService: MessageService,
    protected viewSelectorService: ViewSelectorService
  ) {
    // retrieve the list of application users
    // call the API to retrieve the list
    // this.userService
    //   .getAllUsersAPI()
    //   .then(() => {
    //     this.applicationUsers = this.userService.getApplicationUsers();
    //   })
    //   .catch((err: HttpErrorResponse) =>
    //     this.notificationService.failure(err.message)
    //   );
    this.defaultColDef = {
      resizable: true,
    }
  }

  ngOnInit(): void {
    this.searchService.setPageName("Limit Requests");
    setTimeout(() => {
      this.spinnerService.setIsLoading(true);
    }, 0);

    //labels by message service
    if (localStorage.getItem('LimitRequestLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('LimitRequestLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Limit Requests",
        "OrgCombinedLimitRequest"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('LimitRequestLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        this.rowData = this.searchService.searchRecords;
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    // new search initiated
    this.searchService.searchInitiated.subscribe(() => {
      this.searchService.smartSearchPromise
        .then(() => {
          this.isRowSelected = false;
          this.rowData = this.searchService.searchRecords;
          this.gridApi.paginationGoToPage(0);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    });

    // subscribe to site changed
    this.siteService.siteChanged.subscribe((event: boolean) => {
      if (event) {
        this.searchService
          .smartSearchMultiSiteAPI(null)
          .then(() => {
            this.rowData = this.searchService.searchRecords;
            this.gridApi.paginationGoToPage(0);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    });

    // requests are updated
    this.limitRequestService.requestUpdated.subscribe((event: boolean) => {
      this.searchService
        .smartSearchMultiSiteAPI(null)
        .then(() => {
          this.rowData = this.searchService.searchRecords;
          this.gridApi.paginationGoToPage(0);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    });
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.limitRequestColumnDefs = new LimitRequestsAgGridConfig(
      this.userService,
      this.datePipe,
      this.siteService,
      this.screenLabels,
    ).limitRequestColumnDefs;
  }

  // invoked when ag-grid is initialized
  protected onGridReady(params): void {
    this.gridApi = params.api;
  }

  // make ag-rid columns fill their container
  protected onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
    setTimeout(() => {
      this.spinnerService.setIsLoading(false);
    }, 0);
  }

  // invoked when a row is clicked
  protected openRequest(event) {
    this.limitRequestService.getLimitRequestDetailsAPI(event.data.OrgCombinedLimitRequestID).subscribe((res: HttpResponse<any>) => {
      let reqResponse = res.body[0];
      reqResponse.OrgCombinedLimitRequestID = event.data.OrgCombinedLimitRequestID;
      if (this.limitRequestService.parkingRequestOpen) return;
      this.dialog.open(ViewParkingLimitRequestModalComponent, {
        disableClose: true,
        data: reqResponse,
      });
      this.limitRequestService.parkingRequestOpen = true;
    }, error => {
      console.log(error);
    });
  }

  // invoked when a row is reselected on the ag-Grid
  protected onSelectionChanged(event) {
    const selectedRows = event.api.getSelectedRows();
    if (selectedRows.length > 0) {
      this.selectedRequest = selectedRows[0];
      this.isRowSelected = true;
    } else {
      this.selectedRequest = null;
      this.isRowSelected = false;
    }
  }

  // invoked when a request is being cancelled
  protected onCancelRequest() {

    if (this.selectedRequest.Status === "Requested" || this.selectedRequest.Status === "Processing") {
      const cancelMsg = this.screenLabels["CancelLimitRequestWarningMsg"] ? this.screenLabels["CancelLimitRequestWarningMsg"] : "Are you sure you want to cancel Request # <<id>> ?";
      const cancelLabel = this.screenLabels["CancelLimitRequestMsg"] ? this.screenLabels["CancelLimitRequestMsg"] : "Cancel Limit Request";
      const replaceID = cancelMsg.replace(" <<id>>", this.selectedRequest.OrgCombinedLimitRequestID)
      this.dialogService
        .openConfirmDialog(
          cancelLabel,
          replaceID
        )
        .afterClosed()
        .subscribe((confirm: boolean) => {
          if (confirm) {
            this.limitRequestService.cancelOrgLimitRequestAPI(this.selectedRequest.OrgCombinedLimitRequestID)
          }
        });
    } else {
      const cannotCancelMsg = this.screenLabels["CannotCancelRequestWarningMsg"] ? this.screenLabels["CannotCancelRequestWarningMsg"] : "Cannot Cancel a Request which is already <<status>>.";
      const cannotCancelLabel = this.screenLabels["CannotCancelRequestMsg"] ? this.screenLabels["CannotCancelRequestMsg"] : "Cannot Cancel the Request";
      const cannotReplaceID = cannotCancelMsg.replace("<<status>>", this.selectedRequest.Status)
      this.dialogService.openAlertDialogDialog(
        cannotCancelLabel,
        cannotReplaceID
      );
    }
  }

  // invoked when a request is being raised
  protected onNewRequest() {
    this.dialog.open(CreateLimitRequestModalComponent, {
      disableClose: true,
      data: true,
    });
  }
}
