import { Component, OnInit } from "@angular/core";
import { MatDialogConfig, MatDialog } from "@angular/material";

import { MasterLayoutComponent } from "../master-layout.component";
import { ViewSelectorService } from "../../services/view-selector.service";
import { NewpassModalComponentMobile } from "src/app/newpass-modal/mobile/newpass-modal.component.mobile";
import { NewpassModalComponentDesktop } from "src/app/newpass-modal/desktop/newpass-modal.component.desktop";
import { SpinnerService } from "../../services/spinner.service";
import { AuthService } from "src/app/services/auth.service";
import { UserService } from "src/app/services/user.service";
import { SiteService } from "src/app/services/site.service";
import { PassService } from "src/app/services/pass.service";
import { NotificationService } from "src/app/services/notification.service";
import { HttpErrorResponse } from "@angular/common/http";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-master-layout-mobile",
  templateUrl: "./master-layout.mobile.component.html",
  styleUrls: ["./master-layout.mobile.component.css"],
})
export class MasterLayoutMobileComponent extends MasterLayoutComponent {
  constructor(
    viewSelectorService: ViewSelectorService,
    dialog: MatDialog,
    spinnerService: SpinnerService,
    authService: AuthService,
    userService: UserService,
    siteService: SiteService,
    passService: PassService,
    notificationService: NotificationService,
    messageService: MessageService

  ) {
    super(
      viewSelectorService,
      dialog,
      spinnerService,
      authService,
      userService,
      siteService,
      passService,
      notificationService,
      messageService
    );
  }

  // opens new pass modal
  createPass() {
    setTimeout(() => {
      this.spinnerService.setIsLoading(true);
    }, 0);
    this.passService.getUserTemplateCategoriesAPI(this.userService.userID, this.userService.organizationID)
      .then(() => {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.backdropClass = "backdropBackground";
        dialogConfig.disableClose = true;
        if (this.isMobile === true) {
          this.dialog.open(NewpassModalComponentMobile, dialogConfig);

        } else {
          this.dialog.open(NewpassModalComponentDesktop, dialogConfig);
        }
      }).catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
    setTimeout(() => {
      this.spinnerService.setIsLoading(false);
    }, 0);
    this.passService.checkIsIgnoreLocksAPI(this.userService.userID, this.userService.organizationID, true);
  }
}
