import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatExpansionPanel,
  MatAutocomplete,
  MatAutocompleteTrigger,
  MatChipInputEvent,
  MatAutocompleteSelectedEvent,
  MatSelect,
  MatSelectionList,
  MatSelectionListChange,
} from "@angular/material";
import { EntryPoint } from "../../../models/entry-point.model";
import { UserService } from "../../../services/user.service";
import { NotificationService } from "../../../services/notification.service";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { RoleMapService } from "../../../services/role-map.service";
import { HttpErrorResponse } from "@angular/common/http";
import { OrganizationService } from "../../../services/organization.service";
import { DialogService } from "../../../services/dialog.service";
import { GridApi, ValueSetterParams } from "ag-grid-community";
import { Observable } from "rxjs";
import { FormControl, NgForm, Validators } from "@angular/forms";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { map, startWith } from "rxjs/operators";
import { Site, SiteService } from "src/app/services/site.service";
import { Integration } from "src/app/models/integration.model";
import { SearchService } from "src/app/services/search.service";
import { UsersComponent } from "../../users.component";
import { SelectionModel } from "@angular/cdk/collections";
import { ButtonRendererComponent } from "src/app/common/AgGrid/button-renderer/button-renderer.component";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-assign-update-role-modal",
  templateUrl: "./assign-update-role-modal.component.html",
  styleUrls: ["./assign-update-role-modal.component.css"],
})
export class AssignUpdateRoleModalComponent implements OnInit {
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  @ViewChild("organizationDropDown") organizationDropDown: any;
  @ViewChild("isAdminDropDown") isAdminDropDown: any;

  protected step = 0;
  protected entryPoints: Array<EntryPoint>;
  protected rolesLoaded: boolean = false;
  protected organizationsLoaded: boolean = false;
  protected disableNewOrganizationAdd: boolean = true;
  protected organization: boolean = false;
  protected isAdmin: boolean = false;

  @ViewChild("trigger") autoCompleteTrigger: MatAutocompleteTrigger;

  public chipSelectedRole: Array<{
    RoleID: number;
    RoleName: string;
    Active: number;
    UserRoleID: number;
  }> = [];
  public filteredRoles: Observable<String[]>;
  //
  // Set this to false to ensure engineers are from allEngineers list only.
  // Set this to true to also allow 'free text' engineers.
  //
  private allowFreeTextAddUser = false;

  public roleControl = new FormControl([]);
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild("roleInput") userInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;
  @ViewChild('dummyClick') dummyClickRef: ElementRef;

  Active: number;

  protected roleItems: Array<{
    RoleID: number;
    RoleName: string;
    Active: number;
    UserRoleID: number;
  }> = [];
  protected roleBasket: Array<{
    RoleID: number;
    RoleName: string;
    Active: number;
    UserRoleID: number;
  }> = [];
  protected originalRoleBasket: Array<{
    RoleID: number;
    RoleName: string;
    Active: number;
    UserRoleID: number;
  }> = [];

  protected organizationItems: Array<{
    OrganizationID: number;
    Name: string;
    Active: number;
  }> = [];

  protected organizationBasket: Array<{
    UserOrganizationID: number;
    OrganizationID: number;
    Name: string;
    Active: number;
    IsAdmin: number;
  }> = [];

  protected originalOrganizationBasket: Array<{
    UserOrganizationID: number;
    OrganizationID: number;
    Name: string;
    Active: number;
    IsAdmin: number;
  }> = [];

  protected filteredOrganization = [];
  protected AvailableSiteList: Array<String> = [];
  protected associatedSitesLoaded: boolean = false;
  protected selectedPhysicalAccess: number = 0; 
  protected ExternalAppID: string = "";

  organizationFormControl = new FormControl("", Validators.required);

  protected organizationEmpty: number = 0;
  protected onHide: boolean = true;
  protected disableOnSave: boolean = false;
  protected PendingSiteList: Array<String> = [];
  protected PendingSitesLoaded: boolean = false;

  protected arrays: Array<{
    ApplicationType: string
    DisplayValue: string
    ExtApplicationName: string
    ExtApplicationTypeID: number
    IntegrationID: number
    IntegrationName: string
    isLinked?: boolean
    isClicked?: boolean
  }> = [];
  protected physicalAccessArray: Array<{
    ApplicationType: string
    DisplayValue: string
    ExtApplicationName: string
    ExtApplicationTypeID: number
    IntegrationID: number
    IntegrationName: string
    isLinked?: boolean
    isClicked?: boolean
  }> = [];
  protected peopleAccessArray: Array<{
    ApplicationType: string
    DisplayValue: string
    ExtApplicationName: string
    ExtApplicationTypeID: number
    IntegrationID: number
    IntegrationName: string
    isLinked?: boolean
    isClicked?: boolean
  }> = [];
  protected userIntegrationData: Array<{
    IntegrationID: any;
    ExternalAppID: any;
    UserIntegrationID: number;
    Active: number;
  }> = [];
  protected arrayPhysical: any[] = [];
  protected arrayPeople:any[] = [];

  protected selectedValue;
  protected valueSelected: boolean = false;
  protected errorMessage: string = '';
  @ViewChild(MatSelectionList, { static: true })
  private selectionList: MatSelectionList;
  protected integrationLoaded:boolean = false;
  protected userPhysicalMngmnt:string = "UserPhysicalAccessMgmt";
  protected userPepleMangmnt:string = "UserPeopleMgmt";
  protected columnDefs = [];
  protected frameworkComponents;
  protected rowData =  [];
  public showGrid= false;
  protected plpMgmtBoolean : boolean = false;
  protected phyAccessMgmtBoolean : boolean = false;
  protected filteredPlpMgmtChipList = [];
  protected filteredPhyAccessMgmtChipList = [];
  protected intListMgmt = [];
  protected intListAccMgmt = [];
  protected countPlpMgmt : number = 0;
  protected countPhyAccMgmt : number = 0;
  public disableOrg : boolean = true;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  integrateMessage: string;
  public defaultColDef : object;
  isLinkedUsrPplMgmt: boolean = false;
  isLinkedPhyAMgmt: boolean = false;
  protected searchKey: string;
  organizationControl = new FormControl();
  filteredOrganizations: Observable<any[]>;
  protected userIntegratData: any = [];
  protected intergrationResponse: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      Active: number;
      CreatedBy: number;
      CreatedDate: string;
      Email: string;
      EmployeeRef: string;
      FirstName: string;
      LastName: string;
      MiddleName: string;
      ModifiedBy: number;
      ModifiedDate: string;
      OKTAID: string;
      Phone: string;
      PrefFirstName: string;
      PrefMiddleName: string;
      PrefLastName: string;
      PrefEmail: string;
      PrefPhone: string;
      Status: string;
      Suffix: string;
      UserID: number;
      BadgeID: string;
    },
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<AssignUpdateRoleModalComponent>,
    protected roleMapService: RoleMapService,
    private notificationService: NotificationService,
    protected organizationService: OrganizationService,
    private userService: UserService,
    private dialogService: DialogService,
    protected siteService: SiteService,
    protected searchService:SearchService,
    protected messageService: MessageService,
  ) {
    this.defaultColDef = {
      resizable: true,
  }
   }

  ngOnInit(): void {
    // initialize page labels

    this.userService.getIntegrations().subscribe((response: any) => {
      response.body.Integrations.forEach(element => {
        this.arrays.push(element);
        this.physicalAccessArray = this.arrays.filter(x => x.DisplayValue == 'UserPhysicalAccessMgmt');
        this.peopleAccessArray = this.arrays.filter(x => x.DisplayValue == 'UserPeopleMgmt');
        this.integrationLoaded = true;
      })
      if (this.physicalAccessArray.length === 0 || this.peopleAccessArray.length === 0) this.disableOrg = false;
      this.physicalAccessArray.forEach(ele => {
        this.userService.getUserAssociationAPI(this.data.UserID, this.siteService.selectedSiteID).then(() => {
          const reqData = this.userService.userByID[0];
          this.userIntegratData = this.userService.userByID[0];
          if (ele.DisplayValue === "UserPhysicalAccessMgmt") {
            const reqData = this.userService.userByID[0];
            if (reqData.UserOrganization.length > 0) {
              this.disableOrg = false;
            } else {
              this.disableOrg = true;
            }
            reqData.UserInegrations.forEach(data => {
              if (ele.IntegrationID === data.IntegrationID) {
                ele.isLinked = true;
                this.isLinkedPhyAMgmt = true;

                if(!this.intListAccMgmt.includes(data.UserIntegrationID)){
                  this.intListAccMgmt.push(data.UserIntegrationID);
                  this.filteredPhyAccessMgmtChipList.push(data);
                  this.userIntegrationData.push({
                    IntegrationID: data.IntegrationID,
                    ExternalAppID: data.ExternalAppID,
                    UserIntegrationID: data.UserIntegrationID,
                    Active: data.Active
                  })
              }
              }
            })
          }
        })
      });
      this.peopleAccessArray.forEach(ele => {
        this.userService.getUserAssociationAPI(this.data.UserID, this.siteService.selectedSiteID).then(() => {
          const reqData = this.userService.userByID[0];
          this.userIntegratData = this.userService.userByID[0];
          if (ele.DisplayValue === "UserPeopleMgmt") {
            if (reqData.UserOrganization.length > 0) {
              this.disableOrg = false;
            } else {
              this.disableOrg = true;
            }
            reqData.UserInegrations.forEach(data => {
              if (ele.IntegrationID === data.IntegrationID) {
                ele.isLinked = true;
                this.isLinkedUsrPplMgmt = true;

                if(!this.intListMgmt.includes(data.UserIntegrationID)){
                  this.intListMgmt.push(data.UserIntegrationID);
                  this.filteredPlpMgmtChipList.push(data);
                  this.userIntegrationData.push({
                    IntegrationID: data.IntegrationID,
                    ExternalAppID: data.ExternalAppID,
                    UserIntegrationID: data.UserIntegrationID,
                    Active: data.Active
                  })
              }
              }
              this.integrationLoaded = true;
              //  else {
              //   ele.isLinked = false;
              // }
            })
          }
        })
      });
    });
    

//labels by message service
if (localStorage.getItem('EditUsersLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('EditUsersLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Update User Modal",
      "User"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('EditUsersLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    // Load Roles
    this.loadRoles();

    // Load Organization Data
    this.loadOrganization();


    this.filteredRoles = this.roleControl.valueChanges.pipe(
      map((roleName) => this.filterOnValueChange(roleName))
    );
    this.roleControl.setValue(null);
    
    this.onHide = false;
    this.onValidatingAddButton();
    
  }

  orgValidation() {
if (this.organizationBasket.length === 0 && this.organizationEmpty === 0) {
  this.organization = true ;
} else {
  this.organization = false ;
}
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDefs()
}

isValidEmail(email: string): boolean {
  const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/
  return emailPattern.test(email);
}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaveChanges() {
    if(this.organizationEmpty){
    this.onAddOrganization();
    }
    this.dialogService
      .openConfirmDialog(
        this.screenLabels["UserUpdateConfirmLabel"]? this.screenLabels["UserUpdateConfirmLabel"]: "Confirm User Update",
        this.screenLabels["UserUpdateConfirmMsg"]? this.screenLabels["UserUpdateConfirmMsg"]: "Are you sure you want to save the changes ?"  
      )
      .afterClosed()
      .subscribe((data: boolean) => {
        if (data) {
          this.onNoClick();
          const orginalOrgs = this.userService
            .getUserDetails()[0]
            .UserOrganization.map((o) => ({
              UserOrganizationID: o.UserOrganizationID,
              Active: o.UserOrganizationActive,
              OrganizationID: o.OrganizationID,
              Name: this.organizationService
                .getOrganizations()
                .filter((f) => f.OrganizationID == o.OrganizationID)[0].Name,
              IsAdmin: o.IsAdmin ? (o.IsAdmin == 1 ? 1 : 0) : 0,
            }));

          this.originalOrganizationBasket = orginalOrgs;

          this.chipSelectedRole.forEach((element) => {
            element.UserRoleID = 0;
          });
          let roleBasket = this.userService
            .getUserDetails()[0]
            .UserRole.map((ur) => ({
              UserRoleID: ur.UserRoleID,
              RoleID: ur.RoleID,
              Active: ur.UserRoleActive,
              RoleName: this.roleMapService.getRoleMappingByID(ur.RoleID)[0]
                .RoleName,
            }));
          this.originalRoleBasket = roleBasket;
          this.chipSelectedRole.forEach((element) => {
            element.Active = 1;
          });
          this.originalRoleBasket.forEach((element) => {
            var index = -1;
            const length = this.chipSelectedRole.filter(
              (f) => f.RoleID == element.RoleID
            ).length;
            if (length > 0)
              index = this.chipSelectedRole.indexOf(
                this.chipSelectedRole.filter(
                  (f) => f.RoleID == element.RoleID
                )[0]
              );
            if (index < 0) {
              element.Active = 0;
              this.chipSelectedRole.push(element);
            } else {
              element.Active = 1;
              this.chipSelectedRole.splice(index, 1);
              this.chipSelectedRole.push(element);
            }
          });

          this.originalOrganizationBasket.forEach((element) => {
            var index = -1;
            const length = this.organizationBasket.filter(
              (f) => f.OrganizationID == element.OrganizationID
            ).length;
            if (length > 0)
              index = this.organizationBasket.indexOf(
                this.organizationBasket.filter(
                  (f) => f.OrganizationID == element.OrganizationID
                )[0]
              );
            if (index < 0) {
              element.Active = 0;
              this.organizationBasket.push(element);
            } else {
              element.Active = 1;
              element.IsAdmin = this.organizationBasket[index].IsAdmin;
              this.organizationBasket.splice(index, 1);
              this.organizationBasket.push(element);
            }
          });

          //Updating UserID to the loggedInUSer

          // this.data.UserID = this.userService.userID;
          this.data.ModifiedBy = this.userService.userID;

          const body: any = { ...this.data };
          body.Organization = this.organizationBasket;
          body.Role = this.chipSelectedRole;
          if(!this.data.PrefFirstName){
            body.PrefFirstName = this.data.FirstName;
          }
          if(!this.data.PrefMiddleName){
            body.PrefMiddleName = this.data.MiddleName;
          }
          if(!this.data.PrefLastName){
            body.PrefLastName = this.data.LastName;
          }

          if(!this.data.PrefEmail){
            body.PrefEmail = this.data.Email;
          }
          if (!this.data.PrefPhone) {
            body.PrefPhone = this.data.Phone;
          }
          let uniqueIntegrations = [];
          this.userIntegrationData.forEach((element) => {
            if (!uniqueIntegrations.includes(element)) {
              uniqueIntegrations.push(element);
            }
          });
          body.UserIntegrations = uniqueIntegrations;
          if (body.Status === "Pending") {
            body.PreviousStatus = body.Status;
            body.Status = "Approved";
            body.AdminSiteID = this.userService.selectedSiteID;
          }
          this.userService
            .updateUserAssociationsAPI(body)
            .then(() => {
              this.notificationService.success(this.userService.message);
              this.userService.userDataChanged.emit(true);
            })
            .catch((err: HttpErrorResponse) =>
              this.notificationService.failure(err.message)
            );
        }
      });
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  private loadRoles() {
    // Load Role Data
    // need to call API
    this.roleMapService
      .getRoleMappingsAPI()
      .then(() => this.prepareRoleList())
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );
  }

  private loadOrganization() {
    // call API
    this.organizationService
      .getOrganizationAPI()
      .then(() => {
        // load the organization basket
        this.prepareOrganizationList();
        this.organizationsLoaded = true;
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }

  private prepareOrganizationList() {

    this.userService
      .getUserAssociationAPI(this.data.UserID, this.siteService.selectedSiteID)
      .then(() => {
        const organizationBasket = this.userService
          .getUserDetails()[0]
          .UserOrganization.map((o) => ({
            UserOrganizationID: o.UserOrganizationID,
            Active: o.UserOrganizationActive,
            OrganizationID: o.OrganizationID,
            Name: this.organizationService
              .getOrganizations()
              .filter((f) => f.OrganizationID == o.OrganizationID)[0].Name,
            IsAdmin: o.IsAdmin ? (o.IsAdmin == 1 ? 1 : 0) : 0,
          }))
          .filter((f) => f.Active);
        this.organizationBasket = organizationBasket;
        this.orgValidation()
        const organizationItems = [];
        for (let j = 0; j < this.organizationItems.length; j++) {
          let matchFound = false;
          for (let i = 0; i < this.organizationBasket.length; i++) {
            if (
              this.organizationItems[j].OrganizationID ==
              this.organizationBasket[i].OrganizationID
            ) {
              matchFound = true;
            }
          }
          if (!matchFound)
           organizationItems.push(this.organizationItems[j]);
        }
        this.organizationItems = organizationItems;
        this.organizationItems.sort((a, b) => a.Name.localeCompare(b.Name));
        this.filteredOrganizations = this.organizationControl.valueChanges.pipe(
          startWith(""),
          map(value => typeof value === 'string' ? value : value?.Name),
          map(Name => Name ? this._filteredOrganizations(Name) : this.organizationItems.slice())
        );
        //this.originalOrganizationBasket = [...this.organizationBasket];
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    this.organizationItems = this.organizationService
      .getOrganizations()
      .map((o) => ({
        OrganizationID: o.OrganizationID,
        Name: o.Name,
        Active: o.Active,
      }))
      .filter((o) => o.Active === 1);
  }

  displayFn1(selectedoption: number) {
    if(selectedoption) {
    return this.organizationItems.find(x => x.OrganizationID === selectedoption).Name;
    }
  }
	
	private _filteredOrganizations(value: string) {
    return this.organizationItems.filter(option => option.Name.toLowerCase().includes(value.toLowerCase()));
  }

  private prepareRoleList() {
    const response = this.roleMapService
      .getRoleMappingResponse()
      .filter((rm) => rm.RoleActive)
      .map((rm) => ({
        RoleID: rm.RoleID,
        RoleName: rm.RoleName,
        Active: rm.RoleActive,
        UserRoleID: 0,
      }))
      .filter((rm) => rm.Active == 1);

    // removing duplicates
    this.roleItems = Array.from(
      new Set(response.map((i) => JSON.stringify(i)))
    ).map((i) => JSON.parse(i));
    this.roleItems.forEach((element) => {
      element.Active = 0;
    });
    this.userService
      .getUserAssociationAPI(this.data.UserID, this.siteService.selectedSiteID)
      .then(() => {
        const pendingSites = this.userService.getUserDetails()[0].UserPendingSiteAccess;
        if (pendingSites && pendingSites.length > 0) {
          pendingSites.forEach((element) => {
            this.PendingSiteList.push(element.Name);
          });
          this.PendingSitesLoaded = true;
        }

        const userSites = this.userService.getUserDetails()[0].UserSites;
        if (userSites.length > 0) {
          userSites.forEach((element) => {
            this.AvailableSiteList.push(element.Name);           
          });
          this.associatedSitesLoaded = true;
        }

        let roleBasket = this.userService.getUserDetails()[0]
          .UserRole.map((ur) => ({
            UserRoleID: ur.UserRoleID,
            RoleID: ur.RoleID,
            Active: ur.UserRoleActive,
            RoleName: this.roleMapService.getRoleMappingByID(ur.RoleID)[0]
              .RoleName,
          }));
        roleBasket = roleBasket.filter((rb) => rb.Active === 1);
        roleBasket.sort((a, b) =>
          a.RoleID > b.RoleID ? 1 : b.RoleID > a.RoleID ? -1 : 0
        );
        this.roleBasket = roleBasket;
        this.chipSelectedRole = this.roleBasket;
        this.chipSelectedRole.forEach((element) => {
          element.Active = 1;
        });

        this.chipSelectedRole.forEach((element) => {
          var index = -1;
          const length = this.roleItems.filter(
            (f) => f.RoleID == element.RoleID
          ).length;
          if (length > 0)
            index = this.roleItems.indexOf(
              this.roleItems.filter((f) => f.RoleID == element.RoleID)[0]
            );
          if (index >= 0) {
            this.roleItems.splice(index, 1);
          }
        });
        this.rolesLoaded = true;
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }

  private arrayDifference(original: Array<any>, newArray: Array<any>) {
    const difference = original.filter((x) => !newArray.includes(x));
    return difference;
  }

  // Code for Drag and Drop
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }

  public addRole(event: MatChipInputEvent): void {
    if (!this.allowFreeTextAddUser) {
      // only allowed to select from the filtered autocomplete list
      return;
    }

    //
    // Only add when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    //
    if (this.matAutocomplete.isOpen) {
      return;
    }

    // Add our engineer
    const value = event.value;
    if ((value || "").trim()) {
      this.selectRoleByName(value.trim());
    }

    this.resetInputs();
  }

  public removeRole(role): void {
    const index = this.chipSelectedRole.indexOf(role);
    const templateIndex = this.roleItems.indexOf(role);
    if (index >= 0) {
      this.chipSelectedRole.splice(index, 1);
      if (templateIndex < 0) this.roleItems.push(role);
      this.resetInputs();
    }
  }

  public roleSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectRoleByName(event.option.value);
    this.resetInputs();
  }

  private resetInputs() {
    // clear input element
    this.userInput.nativeElement.value = "";
    // clear control value and trigger engineerControl.valueChanges event
    this.roleControl.setValue(null);
  }

  //
  // Compute a new autocomplete list each time control value changes
  //
  private filterOnValueChange(roleName: string): String[] {
    let result: String[] = [];
    //
    // Remove the engineers we have already selected from all engineers to
    // get a starting point for the autocomplete list.
    let allRolesLessSelected = this.roleItems.filter(
      (user) => this.chipSelectedRole.indexOf(user) < 0
    );
    if (roleName) {
      result = this.filterRole(allRolesLessSelected, roleName);
    } else {
      result = allRolesLessSelected.map((role) => role.RoleName);
    }
    return result;
  }

  private filterRole(
    roleList: Array<{
      RoleID: number;
      RoleName: string;
      Active: number;
    }> = [],
    roleName: String
  ): String[] {
    let filteredRolesList: Array<{
      RoleID: number;
      RoleName: string;
      Active: number;
    }> = ([] = []);
    const filterValue = roleName.toLowerCase();
    let rolesMatchingRoleName = roleList.filter(
      (role) => role.RoleName.toLowerCase().indexOf(filterValue) === 0
    );
    if (rolesMatchingRoleName.length || this.allowFreeTextAddUser) {
      //
      // either the engineer name matched some autocomplete options
      // or the name didn't match but we're allowing
      // non-autocomplete engineer names to be entered
      //
      filteredRolesList = rolesMatchingRoleName;
    } else {
      //
      // the engineer name didn't match the autocomplete list
      // and we're only allowing engineers to be selected from the list
      // so we show the whjole list
      //
      filteredRolesList = roleList;
    }
    //
    // Convert filtered list of engineer objects to list of engineer
    // name strings and return it
    //
    return filteredRolesList.map((role) => role.RoleName);
  }

  private selectRoleByName(roleName) {
    let foundUser = this.roleItems.filter((role) => role.RoleName == roleName);
    if (foundUser.length) {
      //
      // We found the engineer name in the allEngineers list
      //
      this.chipSelectedRole.push(foundUser[0]);
    }
  }

  protected selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    trigger.openPanel();
  }

  onAutocompleteFocus() {
    this.autoCompleteTrigger._onChange("");
    this.autoCompleteTrigger.openPanel();
  }

  private selectRoleByID(roleID) {
    let foundUser = this.roleItems.filter((user) => user.RoleID == roleID);
    if (foundUser.length) {
      this.chipSelectedRole.push(foundUser[0]);
    }
  }

  resetOrganization() {
    while (this.filteredOrganization.length > 0) {
      this.filteredOrganization.pop();
    }
    this.organizationItems.forEach((element) => {
      this.filteredOrganization.push(element.Name);
    });

    this.filteredOrganization.sort();
  }

  onValidatingAddButton(){
    //for enabling add-if we have 1row
    if (this.onHide && this.organizationBasket.length >= 1 && this.organizationEmpty !== 0) {
      this.disableNewOrganizationAdd = false;
    } else if (!this.onHide && this.organizationBasket.length >= 1) {
      this.disableNewOrganizationAdd = false;
    } else if (!this.onHide && this.organizationBasket.length == 0) {
      this.disableNewOrganizationAdd = false;
    } else if (this.onHide && this.organizationBasket.length == 0 && this.organizationEmpty === 0) {
      this.disableNewOrganizationAdd = true;
    }else if (this.onHide && this.organizationBasket.length >= 1 && this.organizationEmpty === 0) {
      this.disableNewOrganizationAdd = true;
    }
  }
  // On Organization Change
  onOrganizationSelectionChange(event : MatAutocompleteSelectedEvent) {
    this.disableOrg = false ;
    const organizationID = event.option.value;
    this.organizationEmpty = +event.option.value;
    this.disableNewOrganizationAdd = false;
    this.disableOnSave = false;
    this.orgValidation()
  }

  // Remove Organization  from UI
  deleteFieldValueForOrganization(
    organizationID: number,
    Name: string,
    index: number
  ) {
    // const index = this.parkingWithLimits.indexOf(parking[0]);
    this.organizationBasket.splice(index, 1);

    this.organizationItems.push({
      OrganizationID: organizationID,
      Name: Name,
      Active: 0,
    });
    if(this.onHide){
    this.organizationDropDown.value = ""
    //this.organizationEmpty = 0
    }
    if(this.organizationBasket.length === 0 && this.organizationEmpty === 0) {
      this.disableOrg = true ;
    } 
    if(this.organizationEmpty !== 0) {
      this.disableOrg = false ;
    }

    //for disabling save on empty field with 0 rows
    if(!this.organizationEmpty && this.organizationBasket.length==0){
      this.disableOnSave = true;
    }
    else{
      this.disableOnSave = false;
    }

    //for enabling add-if we have 1row
    this.onValidatingAddButton();
 }

  // Remove Empty Organization  from UI
  deleteEmptyFieldValueForOrganization(){
    if(this.organizationBasket.length > 0) {
      this.disableOrg = false ;
    }else {
      this.disableOrg = true ;
    }
   // this.arr.splice(index, 1);
    this.onHide = false;
    //for enabling add-if we have 1row
    this.onValidatingAddButton();
    this.organizationDropDown.value = ""
    this.organizationEmpty = 0
  }

  // Add Organization
  onAddOrganization() {
    this.orgValidation()
    this.disableOrg = true ;
    if(!this.onHide){
      this.onHide = true;
    }
    //for enabling add-if we have 1row
    this.onValidatingAddButton();
    const organizationID: number = +this.organizationControl.value;
    const Name: string = this.organizationItems.find(item => item.OrganizationID === organizationID)?.Name || '';
    this.isAdmin = this.isAdminDropDown.value;
    // const Name: string = this.organizationDropDown.selected
    //   ._mostRecentViewValue;
    // const organizationID: number = +this.organizationDropDown.value;
    this.isAdmin = this.isAdminDropDown.value;
    // adding to the organization to Organization basket

    this.organizationBasket.push({
      UserOrganizationID: 0,
      IsAdmin: this.isAdmin ? 1 : 0,
      OrganizationID: organizationID,
      Name: Name,
      Active: 1,
    });

    // removing from organizationItems
    let index = -1;

    for (let i = 0; i < this.organizationItems.length; i++) {
      if (this.organizationItems[i].OrganizationID === organizationID) {
        index = i;
      }
    }

    if (index >= 0) {
      this.organizationItems.splice(index, 1);
      this.organizationDropDown.selectedValue = undefined;
      this.organizationControl.setValue('');
      this.organizationEmpty = 0
    }
    // Clearing the Limit Field
    this.isAdmin = null;
    this.disableNewOrganizationAdd = true;
    this.isAdminDropDown.value = "";

    this.disableOnSave = false;
    //this.onRequiredOrganization();
  }

  selection = new SelectionModel(true);
  list = Array.from({ length: 100 }).map((_, i) => `Item #${i}`);
  selectionPhysical = new SelectionModel(true);

  onSystemIntegratedChange(selection: MatSelectionListChange) {
    this.valueSelected = true;
    this.searchKey = null;
    this.selectedValue = selection.option.value;
    this.showGrid = false;
    this.rowData = [];

    this.clickedIntegrationIs();

    var message: string = this.screenLabels["SearchSystemForUserText"] ? this.screenLabels["SearchSystemForUserText"] :"Search system for <<name>> user";
     this.integrateMessage = message.replace("<<name>>",this.selectedValue.IntegrationName) 

      this.userService.getUserIntegrationInfo(this.userService.userByID[0].UserID, this.selectedValue).subscribe((res: any) => {
        const data = res.body.data.filter(e => e.Active === 1);
        this.intergrationResponse.push(res.body.data);
          if(data.length > 0) {
            data.forEach(ele => {
              if(!this.intListMgmt.includes(ele.UserIntegrationID)){
                  this.filteredPlpMgmtChipList.push(ele);
                  this.intListMgmt.push(ele.UserIntegrationID);
                  this.userIntegrationData.push({
                    IntegrationID: ele.IntegrationID,
                    ExternalAppID: ele.ExternalAppID,
                    UserIntegrationID: ele.UserIntegrationID,
                    Active: ele.Active
                  })
              }
            })
            } 
      })
    selection.option.selected
      ? this.selection.select(selection.option.value)
      : this.selection.deselect(selection.option.value);
  }

  onSystemIntegratedChangePhysical(selectionPhysical: MatSelectionListChange) {
    this.valueSelected = true;
    this.searchKey = null;
    this.selectedValue = selectionPhysical.option.value;
    this.showGrid = false;
    this.rowData = [];

    this.clickedIntegrationIs();
    var message: string = this.screenLabels["SearchSystemForUserText"] ? this.screenLabels["SearchSystemForUserText"] :"Search system for <<name>> user";
     this.integrateMessage = message.replace("<<name>>",this.selectedValue.IntegrationName) 

      this.userService.getUserIntegrationInfo(this.userService.userByID[0].UserID, this.selectedValue).subscribe((res: any) => {
        const data = res.body.data.filter(e => e.Active === 1);
        this.intergrationResponse.push(res.body.data);
        if(data.length > 0) {
          data.forEach(ele => {
            if(!this.intListAccMgmt.includes(ele.UserIntegrationID)){
                this.filteredPhyAccessMgmtChipList.push(ele);
                this.intListAccMgmt.push(ele.UserIntegrationID);
                this.userIntegrationData.push({
                  IntegrationID: ele.IntegrationID,
                  ExternalAppID: ele.ExternalAppID,
                  UserIntegrationID: ele.UserIntegrationID,
                  Active: ele.Active
                })
            }
          })
          } 
      })
    selectionPhysical.option.selected
      ? this.selectionPhysical.select(selectionPhysical.option.value)
      : this.selectionPhysical.deselect(selectionPhysical.option.value);
  }

//to check which integartion is clicked so that to indicate css on that selected integartion
  clickedIntegrationIs() {
    this.physicalAccessArray.forEach(ele => {
      ele.isClicked = false;
      if(ele.IntegrationID == this.selectedValue.IntegrationID) {
        ele.isClicked = true;
      }
    })
    this.peopleAccessArray.forEach(ele => {
      ele.isClicked = false;
      if(ele.IntegrationID == this.selectedValue.IntegrationID) {
        ele.isClicked = true;
      }
    })
  }

  onSearchBtnClick(event) {
    this.searchService
      .smartSearchMultiSiteAPI(this.searchKey, this.selectedValue.DisplayValue, null,false)
      .then(() => {
        const res = this.searchService.searchRecords;
        this.rowData = res.filter(ele => ele.IntegrationID == this.selectedValue.IntegrationID);
        this.showGrid = true;
        this.setColumnDefs();
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }

  clearSearchField(){
    this.searchKey = null;
  }

  removeIntegration(index: number, user): void {
    if (index >= 0) {
      if (this.selectedValue.DisplayValue === this.userPepleMangmnt) {
        this.filteredPlpMgmtChipList.splice(index, 1);
      }
      else {
        this.filteredPhyAccessMgmtChipList.splice(index, 1);
      }
    }
    this.userIntegrationData.push({
      IntegrationID: user.IntegrationID,
      ExternalAppID: user.ExternalAppID,
      UserIntegrationID: user.UserIntegrationID ? user.UserIntegrationID : 0,
      Active: 0
    }),
    this.userIntegrationData = this.filterUserIntegrations(this.userIntegrationData, true);
    this.userIntegrationData = this.userIntegrationData.filter(ele => !(ele.Active==0 && ele.UserIntegrationID==0));
  }

  //for filtering the integrations
  filterUserIntegrations(userIntegrations: any[],removeIntegration: boolean): any[] {
    const filteredMap = new Map<string, any>();
    userIntegrations.forEach((integration) => {
      const existingIntegration = filteredMap.get(integration.ExternalAppID);
      if(removeIntegration) {
      if (!existingIntegration || (existingIntegration.Active === 1 && integration.Active === 0)) {
        filteredMap.set(integration.ExternalAppID, integration);
      }
    }
    else {
      if (!existingIntegration || (existingIntegration.Active === 0 && integration.Active === 1)) {
        filteredMap.set(integration.ExternalAppID, integration);
      }
    }
    });
    return Array.from(filteredMap.values());
  }

  setColumnDefs() {
    this.columnDefs = [
      {
        headerName: this.screenLabels["Link"]? this.screenLabels["Link"]:"Link User",
        field: "Link",
        cellStyle: { color: "#589bf8" },
        cellRendererFramework: ButtonRendererComponent,
        width: 100,
        cellRendererParams: {
          onClick: this.addIntegration.bind(this),
          label: this.screenLabels["ViewAssets"]? this.screenLabels["ViewAssets"]:"View Assets"
        },
        lockPosition: true,
      },
      {
        headerName: this.screenLabels["FirstName"]? this.screenLabels["FirstName"]: "First Name",
        field: "FirstName"
      },
      {
        headerName: this.screenLabels["LastName"]? this.screenLabels["LastName"]: "Last Name",
        field: "LastName"
      },
      {
        headerName: this.screenLabels["AppID"]? this.screenLabels["AppID"]:"App ID",
        field: "ExternalAppID"
      }
    ];
  }

  addIntegration(e) {
    const data = e.rowData;
    if (this.selectedValue.DisplayValue === this.userPepleMangmnt) {
      let isPresent = false;
      const plpData = [];
      plpData.push(data);
      plpData.forEach((element) => {
        isPresent = this.filteredPlpMgmtChipList.some(item => 
          item.IntegrationID === plpData[0].IntegrationID && item.ExternalAppID === plpData[0].ExternalAppID
        );
        if (!isPresent) {
          const userIntegrationid = this.intergrationResponse[0]?.filter(e => e.ExternalAppID === element.ExternalAppID).map(m => m.UserIntegrationID)[0];
          this.filteredPlpMgmtChipList.push(element);
          this.userIntegrationData.push({
            IntegrationID: data.IntegrationID,
            ExternalAppID: data.ExternalAppID,
            UserIntegrationID: userIntegrationid ? userIntegrationid : 0,
            Active: 1
          });
        }
      });
      this.userIntegrationData = this.filterUserIntegrations(this.userIntegrationData, false);
      this.userIntegrationData = this.userIntegrationData.filter(ele => !(ele.Active==0 && ele.UserIntegrationID==0));
    } 
    else if (this.selectedValue.DisplayValue === this.userPhysicalMngmnt) {
      let isPresent = false;
      const phyMgmtData = [];
      phyMgmtData.push(data);
      phyMgmtData.forEach((ele) => {
        isPresent = this.filteredPhyAccessMgmtChipList.some(item => 
          item.IntegrationID === phyMgmtData[0].IntegrationID && item.ExternalAppID === phyMgmtData[0].ExternalAppID
        );
        if (!isPresent) {
          const userIntegrationid = this.intergrationResponse[0]?.filter(e => e.ExternalAppID === ele.ExternalAppID).map(m => m.UserIntegrationID)[0];
          this.filteredPhyAccessMgmtChipList.push(ele);
          this.userIntegrationData.push({
            IntegrationID: ele.IntegrationID,
            ExternalAppID: ele.ExternalAppID,
            UserIntegrationID: userIntegrationid ? userIntegrationid : 0,
            Active: 1
          });
        }
      });
      this.userIntegrationData = this.filterUserIntegrations(this.userIntegrationData, false);
      this.userIntegrationData = this.userIntegrationData.filter(ele => !(ele.Active==0 && ele.UserIntegrationID==0));
    }
  }
}