export class ParkingLotGroup {
  public CreatedByName: string;
  public ModifiedByName: string;

  constructor(
    public ParkingLotGroupID: number,
    public Name: string,
    public Description: string,
    public EmailDirections: string,
    public Regulated: boolean,
    public TargetCapacity: number,
    public Active: number,
    public ModifiedBy: number,
    public ModifiedDate: Date,
    public CreatedBy: number,
    public CreatedDate: Date
  ) {
    this.Active = 1;
  }
}
