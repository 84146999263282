import { Component, OnInit } from "@angular/core";
import { SiteService } from "../services/site.service";
import { UserService } from "../services/user.service";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-my-approvals",
  templateUrl: "./my-approvals.component.html",
  styleUrls: ["./my-approvals.component.css"],
})
export class MyApprovalsComponent implements OnInit {
  protected appLanguage;
  public screenLabels: Array<string> = [];
  constructor(public userService: UserService,
    public siteService: SiteService,
    protected messageService: MessageService,) { }


  ngOnInit(): void {

    //labels by message service
    if (localStorage.getItem('MyApprovalLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('MyApprovalLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Approval Page",
        "MyApproval"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('MyApprovalLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }
}
