import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  forwardRef,
} from "@angular/core";
import { Observable } from "rxjs";
import {
  FormControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgForm,
} from "@angular/forms";
import {
  MatChipInputEvent,
  MatAutocompleteTrigger,
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
  MatChipList,
} from "@angular/material";
import { ENTER, COMMA } from "@angular/cdk/keycodes";
import { startWith, map } from "rxjs/operators";
import { UtilityService } from "src/app/services/utility.service";

@Component({
  selector: "app-user-mat-chip-auto-complete",
  templateUrl: "./user-mat-chip-auto-complete.component.html",
  styleUrls: ["./user-mat-chip-auto-complete.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserMatChipAutoCompleteComponent),
      multi: true,
    },
  ],
})
export class UserMatChipAutoCompleteComponent
  implements OnInit, ControlValueAccessor {
  @Input() parentList: string[]; // required: it is the basket list (universal).
  @Input() selectedList?: string[]; // optional: it list elements used to display pre-selected item.
  @Input() placeholder: string; // required: the attribute used to display the placeholder text.
  @Input() separatorKeysCodes?: number[]; // optional: it is the array of pre-defined chars used to separate mat-chips default is COMMA, ENTER
  @Input() allowFreeText?: boolean; // optional: determines whether free text entries should be allowed or not, by default it is false
  @Input() required?: boolean; // optional: determines if the control is a required, default is false
  @Input() disabled?: boolean; // optional: determines if the control is disabled, default is false

  @ViewChild("frm") matForm: NgForm;
  @ViewChild("matAutoCompleteInput") matAutoCompleteInput: ElementRef<
    HTMLInputElement
  >;

  @ViewChild("chipList") chipList: MatChipList;

  private _allowFreeText: boolean = false;

  protected matChipControl: FormControl = new FormControl([]);

  public chipSelected: string[] = [];
  public filteredItems: Observable<String[]>;
  public readonly validationMessages = {
    Required: "The field cannot be left empty",
  };

  constructor(private _utilService: UtilityService) { }

  ngOnInit(): void {
    // initialize the change listener
    this.filteredItems = this.matChipControl.valueChanges.pipe(
      startWith(""),
      map((Name) => this._filterOnValueChange(Name))
    );

    if (this.parentList === undefined || this.parentList === null) {
      console.error("Invalid Parent List");
    }

    if (this.allowFreeText) {
      this._allowFreeText = this.allowFreeText;
    } else {
      this._allowFreeText = false;
    }

    if (
      this.separatorKeysCodes === undefined ||
      this.separatorKeysCodes === null
    ) {
      this.separatorKeysCodes = [ENTER, COMMA];
    }

    if (this.required === null || this.required === undefined) {
      this.required = false;
    }

    if (this.selectedList !== undefined && this.selectedList.length > 0) {
      this.chipSelected = this.selectedList;
    }
  }

  private _filterOnValueChange(name: string): String[] {
    let result: String[] = [];
    //
    // Remove the names we have already selected  to in the mat chips
    // get a starting point for the autocomplete list.    //
    console.log(name);
    let basket = this.parentList.filter(
      (pl) => this.chipSelected.indexOf(pl) < 0
    );

    if (name) {
      // result = this._filterBasket(basket, name);
      result = this._utilService.searchSortKeyUtil(basket, name);
    } else {
      result = basket;
    }
    console.log(result);
    return result;
  }

  // the method clears input element and chip control
  private _resetInputs() {
    this.matAutoCompleteInput.nativeElement.value = "";
    this.matChipControl.setValue(null);

    this._propagateChange(this.chipSelected);
  }

  private _selectByName(name: string) {
    let foundItem = this.parentList.filter((n) => n == name);
    if (foundItem.length) {
      foundItem[0] = foundItem[0].replace(/([^.@\s]+)(\.[^.@\s]+)*@([^.@\s]+\.)+([^.@\s]+)/,"");
      foundItem[0] = foundItem[0].replace(/\|/g,"");
      foundItem[0] = foundItem[0].trim();
      this.chipSelected.push(foundItem[0]);
    }
  }

  // the method removes the item from mat chip and appends it to the parent element
  protected removeItem(item: string) {
    const index = this.chipSelected.indexOf(item);
    const parentIndex = this.parentList.indexOf(item);
    if (index >= 0) {
      this.chipSelected.splice(index, 1);
      if (parentIndex < 0) this.parentList.push(item);
      this._resetInputs();

      this.chipList.ngControl.control.setValue(this.chipSelected);
    }

    this._propagateChange(this.chipSelected);
  }

  protected addItem(event: MatChipInputEvent): void {

    this.chipList.ngControl.control.setValue(this.chipSelected);
    this._propagateChange(this.chipSelected);

    if (!this._allowFreeText) {
      return;
    }
  }

  protected onAutocompleteFocus(autoCompleteTrigger: MatAutocompleteTrigger) {
    autoCompleteTrigger._onChange("");
    autoCompleteTrigger.openPanel();
  }

  protected selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    trigger.openPanel();
  }

  protected onSelected(event: MatAutocompleteSelectedEvent): void {
    this._selectByName(event.option.value);
    this._resetInputs();

  }

  public getSelectedChipNames() {
    return [...this.chipSelected];
  }

  /**
   * ControlValueAccessor method implementation
   */

  // the method writes from form model into the view/dom property
  writeValue(value: string[]): void {
    if (value) {
      this.chipSelected = value;
    } else if (!value && this.selectedList && this.selectedList.length) {
      this.chipSelected = this.selectedList;
    } else {
      this.chipSelected = [];
      this.selectedList = [];

      if (value === null && this.matForm) this.matForm.reset();
    }
  }

  // invokes a callback listener whenever a control is changed
  registerOnChange(fn: any): void {
    this._propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    // do nothing
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private _propagateChange = (_: any) => { };
}
