import { Injectable } from "@angular/core";

import { Building } from "../models/building.model";
import { BuildingFloor } from "../models/building-floor.model";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { SiteService } from "./site.service";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BuildingService {
  private apiURL = environment.getAPI("getBuilding");
  private apiPATH = environment.getAPI("getBuidlingFloorbyBuildingID")

  private buildings: Building[];
  private buildingFloors: BuildingFloor[];
  private buildingApiData: Array<Building>;
  protected buildingApiPromise: Promise<unknown>;

  constructor(private http: HttpClient, private siteService: SiteService) {
    this.buildings = [];

    this.buildingFloors = [];

    this.buildingApiPromise = this.parseAPI();
  }

  public getBuildings(): Building[] {
    // return this.buildings;
    return this.buildingApiData;
  }

  getFloors(buildingID: number):Observable<any>{
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
  return this.http.post(
      this.apiPATH,
      { BuildingID: buildingID },
      httpOptions
    )
  }

  getBuildingFloors(buildingID: number){
    
    if (buildingID) {
      const selectedBuilding = this.buildingApiData.filter(
        (e) => e.BuildingID === buildingID
      );
      if (selectedBuilding && selectedBuilding.length > 0) {
        const floors = selectedBuilding[0].Floors;
        return floors ? floors : [];
      } else return [];
    } else return [];
  }

  parseAPI() {
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(
          this.apiURL,
          { SiteID: this.siteService.selectedSiteID },
          httpOptions
        )
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.buildingApiData = success.body;
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }
}
