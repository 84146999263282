export enum ValidationErrorCodes {
  Ok = 0,
  Email,
  Required,
  USPhone,
}

export class DataValidator {
  private static errorCodes: Array<{
    code: number;
    message: string;
  }> = [
      {
        code: ValidationErrorCodes.Email,
        message: "Invalid Email.",
      },
      {
        code: ValidationErrorCodes.Required,
        message: "The field cannot be empty.",
      },
      {
        code: ValidationErrorCodes.USPhone,
        message: "Invalid Phone Number.",
      },
    ];

  public static isEmail(data: string) {
    if (data !== undefined) {
      const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (regex.test(String(data).toLowerCase())) {
        return ValidationErrorCodes.Ok;
      }
      // no validation error
      else return ValidationErrorCodes.Email; // returns error code
    } else {
      return ValidationErrorCodes.Required;
    }
  }
  

  public static isNotEmpty(data: string) {
    // console.log(data);
    if (data == "" || data == null || data == undefined) {
      return ValidationErrorCodes.Required;
    } else {
      return ValidationErrorCodes.Ok;
    }
  }

  public static getErrorMessage(code: number): string {
    return this.errorCodes.filter((ec) => ec.code === code)[0].message;
  }

  public static isUSPhone(data: string) {
    const regex = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
    if (regex.test(String(data))) return ValidationErrorCodes.Ok;
    // no validation error
    else return ValidationErrorCodes.USPhone; // returns error code
  }
}
