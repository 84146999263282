import { Component, OnInit, ViewChild } from "@angular/core";
import { GridApi } from "ag-grid-community";
import { SearchService } from "../services/search.service";
import { EntryPointService } from "../services/entry-point.service";
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from "@angular/material";
import { DialogService } from "../services/dialog.service";
import { NotificationService } from "../services/notification.service";
import { HttpErrorResponse } from "@angular/common/http";
import { default as _rollupMoment } from "moment";
import { ViewOrganizationModalComponent } from "./modals/view-organization-modal/view-organization-modal.component";
import { UpdateOrganizationModalComponent } from "./modals/update-organization-modal/update-organization-modal.component";
import { OrganizationService } from "../services/organization.service";
import { UserService } from "../services/user.service";
import { SiteService } from "../services/site.service";
import { UtilityService } from "../services/utility.service";
import { DatePipe } from "@angular/common";
import { CreateOrganizationModalComponent } from "./modals/create-organization-modal/create-organization-modal.component";
import { Organization } from "../users/modals/create-user/create-user-modal.component";

import { AgGridAngular } from "ag-grid-angular";
import { OrganizationModel } from "../models/organization-model";
import { MessageService } from "../services/message.service";

// const moment = _rollupMoment || _moment;

@Component({
  selector: "app-organization",
  templateUrl: "./organization.component.html",
  styleUrls: ["./organization.component.css"],
})
export class OrganizationComponent implements OnInit {
  private gridApi: GridApi;
  protected isRowSelected = false;
  protected selectedRow;
  protected rowData: Organization[];
  protected rowClassRules;
  private paginationPageSize;
  //protected organization = { ...this.data };
  private organizationModel: OrganizationModel;
  protected parkingWithLimits: Array<{
    OrganizationParkingLimitID: number;
    Limit: number;
    ParkingLotGroupID: number;
    ParkingLotGroupName: string;
    Active: boolean;
    Regulated: boolean;
  }>;
  protected entryPointZoneWithLimits: Array<{
    OrgEntryPointZoneLimitID: number;
    Limit: number;
    EntryPointZoneID: number;
    EntryPointZoneName: string;
    Active: boolean;
    Regulated: boolean;
  }>;
  protected organizationColumnDefs = [];
  @ViewChild('agGrid') agGrid: AgGridAngular;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  public isActive: boolean = false;
  public defaultColDef : object;
  
  constructor(
    /*@Inject(MAT_DIALOG_DATA)
    public data: {
      OrganizationID: number;
      StartDate: string;
      EndDate: string;
      Internal: number;
     SelfRegAllow: number;
      CreatedBy: number;
      CreatedDate: string;
      ModifiedBy: number;
      ModifiedDate: string;
      Name: string;
      Active: boolean;
    },*/
    protected searchService: SearchService,
    protected entryPointService: EntryPointService,
    protected dialog: MatDialog,
    protected dialogService: DialogService,
    protected notificationService: NotificationService,
    protected organizationService: OrganizationService,
    protected userService: UserService,
    private siteService: SiteService,
    private utilService: UtilityService,
    private datePipe: DatePipe,
    protected messageService: MessageService,
  ) {
    this.paginationPageSize = 50;
    // set row styles
    this.rowClassRules = {
      "row-inactive": (params) => {
        var condition = params.data.Active === 0;
        return condition;
      },
    };
    this.defaultColDef = {
      resizable: true,
  }
  }

  ngOnInit(): void {
    this.searchService.setPageName("Organizations");

    //labels by message service
    if (localStorage.getItem('OrganizationLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('OrganizationLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Organizations",
        "Organization"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('OrganizationLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    

    // load rows into ag grid on OnInit event
    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        this.isRowSelected = false;
        this.rowData = this.searchService.searchRecords;
        this.gridApi.paginationGoToPage(0);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    //Refresh AgGrid after Update Organization Limit

    this.organizationService.organizationLimitUpdated.subscribe(
      (dataChanged: boolean) => {
        if (dataChanged) {
          this.searchService
            .smartSearchMultiSiteAPI(null)
            .then(() => {
              this.rowData = this.searchService.searchRecords;
              this.gridApi.paginationGoToPage(0);
            })
            .catch((err: HttpErrorResponse) => {
              this.notificationService.failure(err.message);
            });
        }
      }
    );

    // subscribe to site changed
    this.siteService.siteChanged.subscribe((event: boolean) => {
      if(event){
        this.searchService
        .smartSearchMultiSiteAPI(null)
        .then(() => {
          this.rowData = this.searchService.searchRecords;   
          this.gridApi.paginationGoToPage(0);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
      }
    });

    // Listen to Search Btn click event and refresh Ag Grid with Search results
    this.searchService.searchInitiated.subscribe((eventData: boolean) => {
      if (eventData) {
        this.searchService.smartSearchPromise
          .then(() => {
            this.isRowSelected = false;
            this.rowData = this.searchService.searchRecords;
            this.gridApi.paginationGoToPage(0);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    });
  }

  setColumnDef() {
    this.organizationColumnDefs = [
      {
        headerName: "",
        field: "",
        width: 80,
        cellStyle: { color: "#589bf8" },
        hide: false,
        checkboxSelection: true,
        resizable: false,
        sortable: false,
        lockPosition: true,
      },
      {
        headerName: this.screenLabels["OrganizationID"]
        ? this.screenLabels["OrganizationID"]
        : "Organization ID",
        field: "OrganizationID",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridOrganizationName"]
          ? this.screenLabels["AgGridOrganizationName"]
          : "Name",
        field: "Name",
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridOrganizationInternal"]
          ? this.screenLabels["AgGridOrganizationInternal"]
          : "Internal",
        field: "Internal",
        sortable: true,
        editable: true,
        cellRenderer: (data) => {
          return data.value ? "Yes" : "No";
        },
      },
      {
        headerName: this.screenLabels["AgGridOrganizationSelfRegistration"]
          ? this.screenLabels["AgGridOrganizationSelfRegistration"]
          : "Self Registration",
        field: "SelfRegAllowed",
        sortable: true,
        editable: true,
        cellRenderer: (data) => {
          return data.value ? "Yes" : "No";
        },
      },
      {
        headerName: this.screenLabels[ "AgGridOrganizationStartDate"]
          ? this.screenLabels["AgGridOrganizationStartDate"]
          : "Start  Date",
        field: "StartDate",
        sortable: true,
        editable: true,
        filter: true,
        cellRenderer: (data) => {
          const formattedDate = this.datePipe.transform(
            data.value,
            this.siteService.dataTimeFormat.angularDateFormat
          );
          return formattedDate ? formattedDate : null;
        },
      },
      {
        headerName: this.screenLabels["AgGridOrganizationEndDate"]
          ? this.screenLabels["AgGridOrganizationEndDate"]
          : "End  Date",
        field: "EndDate",
        sortable: true,
        editable: true,
        filter: true,
        cellRenderer: (data) => {
          // const formattedDate = moment(data.value).format("MM/DD/YYYY");
          // return formattedDate !== "Invalid date" ? formattedDate : null;
          const formattedDate = this.datePipe.transform(
            data.value,
            this.siteService.dataTimeFormat.angularDateFormat
          );
          return formattedDate ? formattedDate : null;
        },
        valueGetter (params) {
          return (params.data.Internal === 1 ) ? null : params.data.EndDate;
        }
      },
      {
        headerName: this.screenLabels["ModifiedBy"]
        ? this.screenLabels["ModifiedBy"]
        : "Modified By", 
        field: "ModifiedBy",
        sortable: true,
        filter: true,
        hide: true,
      },
      {
        headerName: this.screenLabels["ModifiedDate"]
        ? this.screenLabels["ModifiedDate"]
        : "Modified Date", 
        field: "ModifiedDate",
        sortable: true,
        filter: true,
        hide: true,
      },
      {
        headerName: this.screenLabels["CreatedBy"]
        ? this.screenLabels["CreatedBy"]
        : "Created By", 
        field: "CreatedBy",
        sortable: true,
        filter: true,
        hide: true,
      },
      {
        headerName: this.screenLabels["CreatedDate"]
        ? this.screenLabels["CreatedDate"]
        : "CreatedDate",
        field: "CreatedDate",
        sortable: true,
        filter: true,
        hide: true,
      },
      {
        headerName:this.screenLabels["CreatedByName"]
        ? this.screenLabels["CreatedByName"]
        : "Created By Name",
        field: "CreatedByName",
        sortable: true,
        filter: true,
        hide: true,
      },
      {
        headerName:this.screenLabels["ModifiedByName"]
        ? this.screenLabels["ModifiedByName"]
        :  "Modified By Name",
        field: "ModifiedByName",
        sortable: true,
        filter: true,
        hide: true,
      },
    ];
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDef()
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  // make ag-rid column fill their container
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }
  // show entry-point modal
  openOrganization($event) {
    const data = $event.data;
    this.dialog.open(ViewOrganizationModalComponent, {
      disableClose: true,
      data: data,
    });
  }

  // sets the data of selected row
  onSelectionChanged($event) {
    const selectedRows = $event.api.getSelectedRows();
    if (selectedRows.length) {
      this.selectedRow = selectedRows[0];
      this.isRowSelected = true;
    } else {
      this.isRowSelected = false;
    }

    if(this.selectedRow.Active === 0){
      this.isActive = false;
    } else {
      this.isActive = true;
    }
  }

  onEditOrganizationalLimits($event) {
    this.dialog.open(UpdateOrganizationModalComponent, {
      disableClose: true,
      data: this.selectedRow,
    });
  }
  // open modal for creating a new organization
  onCreateOrganizationalLimits() {
    this.dialog.open(CreateOrganizationModalComponent, {
      disableClose: true,
      data: {},
    });
  }

  
  // Delete Organization 
  DeleteOrganization() {
    var name = this.selectedRow.Name;
    var message: string = this.screenLabels["DeleteOrgConfirmMsg"]
    ? this.screenLabels["DeleteOrgConfirmMsg"]
    : "Are you sure you want to Delete Organization <<id>> ?"
    const capacityMessage = message.replace("<<id>>",name)

    this.dialogService
      .openConfirmDialog(this.screenLabels["DeleteOrganizationBtn"] ? this.screenLabels["DeleteOrganizationBtn"] : "Delete Organization" , capacityMessage)
      .afterClosed()
      .subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.organizationService
            .updateOrganizationLimitAPI(
              this.selectedRow.OrganizationID,
              this.selectedRow.EndDate,
              this.userService.userID,
              this.siteService.selectedSiteID,
              this.selectedRow.StartDate,
              this.selectedRow.Internal ? 1 : 0,
              this.selectedRow.SelfRegAllowed ? 1 : 0,
              0,
              this.selectedRow.Name,
              [],
              []
              )
            .then(() => {
              this.notificationService.success(
                this.screenLabels["DeleteOrgSuccessMsg"] ? this.screenLabels["DeleteOrgSuccessMsg"] : "Organization was Deleted Successfully." 
              );
            });
        }
      });
  }
   
}
