import { Component, OnInit } from "@angular/core";
import { NavBarComponent } from "../nav-bar.component";
import { AuthService } from "src/app/services/auth.service";
import { Router } from "@angular/router";
import { SearchService } from "src/app/services/search.service";
import { ViewSelectorService } from "../../services/view-selector.service";
import { MatDialogConfig, MatDialog } from "@angular/material";
import { UserService } from "../../services/user.service";
import { EntryPointService } from "src/app/services/entry-point.service";
import { PassService } from "src/app/services/pass.service";
import { BuildingService } from "src/app/services/building.service";
import { DatePipe } from "@angular/common";
import { DialogService } from "src/app/services/dialog.service";
import { count } from "console";
import { OktaAuthService } from "@okta/okta-angular";
import { NotificationService } from "src/app/services/notification.service";
import { SpinnerService } from "../../services/spinner.service";
import { SiteService } from "src/app/services/site.service";
import { UserActionsService } from "src/app/services/user-actions.service";
import { ClientInfoService } from "src/app/services/client-info.service";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-nav-bar-mobile",
  templateUrl: "./nav-bar.component.mobile.html",
  styleUrls: ["./nav-bar.component.mobile.css"],
})
export class NavBarMobileComponent extends NavBarComponent {
  constructor(
    spinnerService: SpinnerService,
    authService: AuthService,
    router: Router,
    searchService: SearchService,
    viewSelectorService: ViewSelectorService,
    dialog: MatDialog,
    userService: UserService,
    entryPointService: EntryPointService,
    datePipe: DatePipe,
    passService: PassService,
    buildingService: BuildingService,
    dialogService: DialogService,
    oktaService: OktaAuthService,
    notificationService: NotificationService,
    siteService: SiteService,
    userActionsService: UserActionsService,
    clientInfoService: ClientInfoService,
    messageService: MessageService
  ) {
    super(
      spinnerService,
      authService,
      router,
      searchService,
      viewSelectorService,
      dialog,
      userService,
      entryPointService,
      datePipe,
      passService,
      buildingService,
      dialogService,
      oktaService,
      notificationService,
      siteService,
      userActionsService,
      clientInfoService,
      messageService
    );
  }
}
