import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatExpansionPanel,
  DateAdapter,
  MAT_DATE_FORMATS,
} from "@angular/material";
import { OrganizationService } from "src/app/services/organization.service";
import { HttpErrorResponse } from "@angular/common/http";
import { NotificationService } from "src/app/services/notification.service";
import { GridApi } from "ag-grid-community";
import moment from "moment";
import { LimitRequestService } from "src/app/services/limit-request.service";
import { UserService } from "src/app/services/user.service";
import { SpinnerService } from "src/app/services/spinner.service";
import { SiteService } from "src/app/services/site.service";
import {
  MatDatePickerAdapter,
  CUSTOM_DATE_FORMATS,
} from "src/app/common/mat-date-picker-formatter/mat-date-picker-formatter";
import { DatePipe } from "@angular/common";
import { ApprovalService, IApprovalRequestStatusResponse } from "src/app/services/approval.service";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-view-parking-limit-request-modal",
  templateUrl: "./view-parking-limit-request-modal.component.html",
  styleUrls: ["./view-parking-limit-request-modal.component.css"],
  providers: [
    { provide: DateAdapter, useClass: MatDatePickerAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export class ViewParkingLimitRequestModalComponent implements OnInit {
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  private gridApi: GridApi;

  protected step = 0;
  protected creationDate;
  protected startDate;
  protected endDate;
  protected organizationName: string;
  protected approversComment: string;
  protected requestersComment: string;
  protected requesterName: string;
  protected parkingLotName: string;
  protected entryPointGroupName: string;
  protected modifiedDate;
  protected modifiedByName;
  protected createdByName;
  protected status: string;
  protected numVisitors: number;

  public approvalStatusLoaded: boolean = false;

  public reviewerRowData: IApprovalRequestStatusResponse[] = [];

  protected rowData: any;
  protected appLanguage;
  protected approversColumnDefs = [];
  protected reviewerColumnDefs = [];
  

  protected organization: {
    OrganizationID: number;
    Name: string;
    Active: number;
    Internal: number;
    StartDate: string;
    EndDate: string;
    ModifiedBy: number;
    CreatedBy: number;
    CreatedDate: string;
  } = null;
  public screenLabels: Array<string> = [];
  public defaultColDef : object;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    protected dialogRef: MatDialogRef<ViewParkingLimitRequestModalComponent>,
    private notificationService: NotificationService,
    private limitService: LimitRequestService,
    protected userService: UserService,
    private siteService: SiteService,
    private datePipe: DatePipe,
    private approvalService: ApprovalService,
    protected messageService: MessageService
  ) {
    this.defaultColDef = {
      resizable: true,
  }
  }

  

  ngOnInit(): void {
    this.creationDate = this.formatDate(this.data.RequestedOn);
    
     //labels by message service
     if (localStorage.getItem('ViewLimitRequestLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('ViewLimitRequestLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "View Limit Request Modal",
        "OrgCombinedLimitRequest"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('ViewLimitRequestLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    
        const request = this.data;
        this.organizationName = request.Organization;
        
        this.requestersComment = request.ApproverArray && request.ApproverArray.length > 0 ?
          request.ApproverArray[
            request.ApproverArray.length - 1
          ].RequesterComments : null;

          // this.requestersComment = this.approvalData.RequesterComments;
        //this.approversComment = this.approvalData.ApproverComments;
        this.parkingLotName = request.ParkingLot;
        this.entryPointGroupName = request.EntryPointGroup;
        this.requesterName = request.RequestedBy;
        this.status = request.Status;
        this.numVisitors = request.IncreaseByLimit;
        this.startDate = request.StartDate;
        this.endDate = request.EndDate;
        this.creationDate = request.RequestedOn;
        this.modifiedDate = request.ModifiedDate;
        this.modifiedByName = request.ModifiedByName;
        this.createdByName = request.CreatedByName;

        this.rowData = request.ApproverArray.map((a) => ({
          Approved:
            a.Approved === 0
              ? "Requested"
              : a.Approved === 1
              ? "Approved"
              : "Rejected",
          ApprovalGroup: a.ApproverGroupName,
          ApprovedDate:
            a.ApprovedDate !== null ? this.formatDate(a.ApprovedDate) : null,
          ApproverComment: a.ApprovalComments,
          ApprovedBy: a.ApprovedBy,
          ApprovedByActive: a.ApprovedByActive,
        }));

        if(this.gridApi) {
          this.gridApi.sizeColumnsToFit();
        }
        this.loadApprovalStatus(); 

  }

  private async loadApprovalStatus() {
    try {
      this.approvalStatusLoaded = false;
      this.rowData = await this.approvalService.getApprovalDetails(
        this.data.OrgCombinedLimitRequestID,
        this.data.ApprovalType
      );

      this.approvalStatusLoaded = true;
    } catch (err) {
      console.error(err);
    }
  }

  public refreshApprovalDetails() {
    this.loadApprovalStatus();
  }

  setColumnDef() {
    this.approversColumnDefs = [
    {
      headerName: this.screenLabels["Status"]
      ? this.screenLabels["Status"]
      : "Status",
      field: "Approved",
      sortable: true,
      // width: 150,
      filter: true,
      cellStyle: { color: "#000" },
      hide: false,
    },
    {
      headerName: this.screenLabels["AgGridApprovalGroup"]
      ? this.screenLabels["AgGridApprovalGroup"]
      : "Approval Group",
      field: "ApprovalGroup",
      sortable: true,
      // width: 150,
      filter: true,
      cellStyle: { color: "#000" },
      hide: false,
    },
    {
      headerName:this.screenLabels["ApprovedDate"]
      ? this.screenLabels["ApprovedDate"]
      : "Approved On", 
      field: "ApprovedDate",
      sortable: true,
      // width: 150,
      cellRenderer: (data) => {

        const formattedDate = this.datePipe.transform(
          data.value,
          this.siteService.dataTimeFormat.angularDateFormat
        );

        return formattedDate ? formattedDate : null;
      },
      filter: true,
      cellStyle: { color: "#000" },
      hide: false,
    },
    {
      headerName: "Approved By",
      field: "ApprovedBy",
      sortable: true,
      // width: 150,
      filter: true,
      cellStyle: { color: "#000" },
      cellClass: (params) => {
        return params.data.ApprovedByActive === 0 ? "inActiveLinkedField" : "";
      },
      hide: false,
    },
    {
      headerName: this.messageService.screenLabels["ApproversComment"]
      ? this.messageService.screenLabels["ApproversComment"]
      : "Approver's Comments",
      field: "ApproverComment",
      sortable: true,
      // width: 150,
      filter: true,
      cellStyle: { color: "#000", "white-space": "normal" },
      hide: false,
      autoHeight: true,
    },
    {
      headerName: this.messageService.screenLabels["AgGridapprovedByActive"]
      ? this.messageService.screenLabels["AgGridapprovedByActive"]
      : "Approved By Active",
      field: "ApprovedByActive",
      sortable: true,
      // width: 150,
      filter: true,
      cellStyle: { color: "#000", "white-space": "normal" },
      hide: true,
      autoHeight: true,
    },
  ];
  
  
  this.reviewerColumnDefs = [
    {
      headerName: this.screenLabels["Status"]
      ? this.screenLabels["Status"]
      : "Status",
      field: "Status",
      width: 100,
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: this.screenLabels["AgGridLevel"]
      ? this.screenLabels["AgGridLevel"]
      : "Level",
      field: "ApprovalLevel",
      width: 80,
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: this.screenLabels["AgGridApprovalGroup"]
      ? this.screenLabels["AgGridApprovalGroup"]
      : "Approval Group",
      field: "ApprovalGroupName",
      wrapText: true,
      autoHeight: true,
      cellStyle: { "white-space": "normal" },
    },
    {
      headerName: this.screenLabels["AgGridReviewedOn"]
      ? this.screenLabels["AgGridReviewedOn"]
      : "Reviewed On",
      field: "ApprovedDate",
      width: 100,
      wrapText: true,
      autoHeight: true,
      cellStyle: { "white-space": "normal" },
      cellRenderer: (data) => {
        return this.formatDate(data.value);
      },
    },
    {
      headerName: this.screenLabels["AgGridReviewedBy"]
      ? this.screenLabels["AgGridReviewedBy"]
      : "Reviewed By",
      field: "ApprovedBy",
      width: 150,
      wrapText: true,
      autoHeight: true,
      cellStyle: { "white-space": "normal" },
    },
    {
      headerName: this.screenLabels["AgGridReviewersComments"]
      ? this.screenLabels["AgGridReviewersComments"]
      : "Reviewer's Comments",
      field: "ApprovalComments",
      editable: (params) => {
        return params.data.CanBeActionedOn === 1 &&
          params.data.Status.toLowerCase() !== "Approved".toLowerCase() &&
          params.data.Status.toLowerCase() !== "Rejected".toLowerCase() &&
          params.data.Status.toLowerCase() !== "Contingent".toLowerCase()
          ? true
          : false;
      },
      // editable: this.checkIsEditable.bind(this)
      wrapText: true,
      autoHeight: true,
      cellStyle: { "white-space": "normal" },
    },
    {
      // headerName: this.messageService.screenLabels["AgGridCanBeActionedOn"]
      // ? this.messageService.screenLabels["AgGridCanBeActionedOn"]
      // : "CanBeActionedOn",
      headerName:this.screenLabels["CanBeActionedOn"]
      ? this.screenLabels["CanBeActionedOn"]
      : "Can Be Actioned On",
      field: "CanBeActionedOn",
      hide: true,
    },
    {
      field: "ApprovalGroupRequestID",
      hide: true,
    },
    {
      field: "ApprovalRequestID",
      hide: true,
    },
    {
      field: "ApprovalGroupID",
      hide: true,
    },
    {
      field: "UserID",
      hide: true,
    },
  ];}
  

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDef()
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.limitService.parkingRequestOpen = false;
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  onSaveChanges() {
    this.onNoClick();
  }

  protected onGridReady(params) {
    this.gridApi = params.api;
  }
  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }

  private formatDate(date: string) {
    const formattedDate = this.datePipe.transform(
      date,
      this.siteService.dataTimeFormat.angularDateFormat
    );

    return formattedDate ? formattedDate : null;
  }

  
}