import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { GridApi } from 'ag-grid-community';
import { DialogService } from 'src/app/services/dialog.service';
import { LimitRequestService } from 'src/app/services/limit-request.service';
import { MessageService } from 'src/app/services/message.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SearchService } from 'src/app/services/search.service';
import { SiteService } from 'src/app/services/site.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UserInvitationService } from 'src/app/services/user-invitation.service';
import { UserService } from 'src/app/services/user.service';
import { CreateAccessRequestComponent } from '../../modals/create-access-request/create-access-request.component';
import { ViewAccessRequestModalComponent } from '../../modals/view-access-request-modal/view-access-request-modal.component';
import { AccessRequestsAgGridConfig } from './access-request-ag-grid-config';
import { LoginRegistrationService } from 'src/app/services/login-registration.service';

@Component({
  selector: 'app-access-request',
  templateUrl: './access-request.component.html',
  styleUrls: ['./access-request.component.css']
})
export class AccessRequestComponent implements OnInit {

  private gridApi: GridApi;
  protected isRowSelected: boolean = false;
  protected rowData: Array<any> = [];
  protected accessRequestColumnDefs = [];
  protected selectedRequest = null;
  protected rowClassRules;
  protected screenLabels : Array<string> = [];
  protected appLanguage; 
  public defaultColDef : object;
  protected sites: any;
  //accessRequestColumnDefs: object = [{}];
  constructor(
    private dialog: MatDialog,
    private searchService: SearchService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private siteService: SiteService,
    protected userService: UserService,
    private spinnerService: SpinnerService,
    private datePipe: DatePipe,
    private userInvitationService: UserInvitationService,
    private limitRequestService: LimitRequestService,
    protected messageService: MessageService,
    private loginRegistrationService: LoginRegistrationService,
  ) { 
    this.rowClassRules = {
      "row-inactive": (params) => {
        var condition = params.data.Active === 0;
        return condition;
      },
    };
    this.defaultColDef = {
      resizable: true,
  }
  }

  ngOnInit(): void {
    this.searchService.setPageName("User Access Request");
    setTimeout(() => {
      this.spinnerService.setIsLoading(true);
    }, 0);

    //labels by message service
    if (localStorage.getItem('AccessRequestLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('AccessRequestLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "User Access Request",
        "UserAccessRequest"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('AccessRequestLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        this.rowData = this.searchService.searchRecords;
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    // new search initiated
    this.searchService.searchInitiated.subscribe(() => {
      this.searchService.smartSearchPromise
        .then(() => {
          this.isRowSelected = false;
          this.rowData = this.searchService.searchRecords;
          this.gridApi.paginationGoToPage(0);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    });

    // subscribe to site changed
    this.siteService.siteChanged.subscribe((event: boolean) => {
      if(event){
        this.searchService
        .smartSearchMultiSiteAPI(null)
        .then(() => {
          this.rowData = this.searchService.searchRecords;   
          this.gridApi.paginationGoToPage(0);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
      }
    });

     // requests are updated
     this.userInvitationService.accessRequestUpdated.subscribe((event: boolean) => {
      this.searchService
        .smartSearchMultiSiteAPI(null)
        .then(() => {
          this.rowData = this.searchService.searchRecords;
          this.gridApi.paginationGoToPage(0);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    });
    this.loginRegistrationService.getSiteListByRoleAPI(this.userService.userID).subscribe((res: any) => {
      this.sites = res['body'];
    });
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    //this.accessRequestColumnDefs()
    this.accessRequestColumnDefs = new AccessRequestsAgGridConfig(
      this.screenLabels,
      this.userService,
      this.datePipe,
      this.siteService
    ).accessRequestColumnDefs;
  }

  // invoked when ag-grid is initialized
  protected onGridReady(params): void {
    this.gridApi = params.api;
  }

  // make ag-rid columns fill their container
  protected onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
    setTimeout(() => {
      this.spinnerService.setIsLoading(false);
    }, 0);
  }

  //invoked on row click
  protected openAccessRequestModal($event) {
    this.selectedRequest = $event.data.UserAccessApprovalRequestID;
    this.userInvitationService.getUserAccessDetailsAPI( this.selectedRequest).subscribe((res: HttpResponse<any>) => {
      let accessDetails = res.body[0];
      accessDetails.UserAccessApprovalRequestID = $event.data.UserAccessApprovalRequestID;
      if(this.limitRequestService.acessRequestOpen) return;
      this.dialog.open(ViewAccessRequestModalComponent, {
        disableClose: true,
        data: accessDetails,
      });
      this.limitRequestService.acessRequestOpen = true;
    }, error => {
      console.log(error);
    });
  }

  // invoked when a row is reselected on the ag-Grid
  protected onSelectionChanged(event) {
    const selectedRows = event.api.getSelectedRows();

    if (selectedRows.length > 0) {
      this.selectedRequest = selectedRows[0];
      this.isRowSelected = true;
    } else {
      this.selectedRequest = null;
      this.isRowSelected = false;
    }
  }

  onNewRequest(){
    this.dialog.open(CreateAccessRequestComponent, {
      disableClose: true,
      data: true,
    });
  }

  protected onCancelRequest() {
      if (
        this.selectedRequest.Status.toLowerCase() === "Approved".toLowerCase() ||
        this.selectedRequest.Status.toLowerCase() === "Rejected".toLowerCase() ||
        this.selectedRequest.Status.toLowerCase() === "Cancelled".toLowerCase() ||
        this.selectedRequest.Status.toLowerCase() === "Automatic Approval".toLowerCase()
      ) {
        var message: string = this.screenLabels["CannotCancelRequestErrorMsg"] ? this.screenLabels["CannotCancelRequestErrorMsg"] : "The request has been <<status>>. Hence the request cannot be cancelled now."
      const capacityMessage = message.replace("<<status>>",this.selectedRequest.Status)
        this.dialogService.openAlertDialogDialog(
          this.screenLabels["CancelRequestBtn"] ? this.screenLabels["CancelRequestBtn"] : "Cancel Request",
          capacityMessage
        );
        return;
      }
      var message: string = this.screenLabels["CancelAccessRequestConfirmMsg"] ? this.screenLabels["CancelAccessRequestConfirmMsg"] : "Are you sure you want to cancel Request # <<id>> ?"
      const capacityMessage = message.replace("<<id>>",this.selectedRequest.UserAccessApprovalRequestID);
      const dialogRef = this.dialogService.openConfirmDialog(
        this.screenLabels["CancelAccessRequestConfirmLabel"] ? this.screenLabels["CancelAccessRequestConfirmLabel"] : "Cancel Access Request",
          capacityMessage
      );
  
      dialogRef.afterClosed().subscribe((confirm: boolean) => {
  
        if (confirm) {
          this.userInvitationService
            .cancelUserInvitationRequestAPI(
              this.selectedRequest.ApprovalRequestID,
              this.selectedRequest.UserAccessApprovalRequestID
            )
            .then((res) => {
              this.notificationService.success(res.Message);
            })
            .catch((err: HttpErrorResponse) => {
              this.notificationService.failure(err.message);
            });
        }
      });
  }

}
