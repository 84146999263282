import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatExpansionPanel,
} from "@angular/material";
import { FormControl, Validators, NgForm } from "@angular/forms";

import { EntryPointGroup } from "../../../models/entry-point-group.model";

import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { NotificationService } from "../../../services/notification.service";
import { HttpErrorResponse } from "@angular/common/http";
import { EntryPointService } from "../../../services/entry-point.service";
import { EntryPoint } from "../../../models/entry-point.model";
import { EntryPointGroupService } from "../../../services/entry-point-group.service";
import { DialogService } from "../../../services/dialog.service";
import { EntryPointZone } from "src/app/models/entry-point-zone.model";
import { EntryPointZoneService } from "src/app/services/entry-point-zone.service";
import { SiteService } from "src/app/services/site.service";
import { RequiredFieldsService } from 'src/app/services/required-fields.service';
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-update-entry-point-zone",
  templateUrl: "./update-entry-point-zone.component.html",
  styleUrls: ["./update-entry-point-zone.component.css"],
})
export class UpdateEntryPointZoneComponent implements OnInit {
  protected step = 0;
  protected entryPointZone: EntryPointZone;
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  @ViewChild("entryPointForm") entryPointForm : NgForm;
  protected appLanguage;
  public screenLabels: Array<string> = [];
 // nameFormControl = new FormControl("", [Validators.required]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EntryPointZone,
    protected dialogRef: MatDialogRef<UpdateEntryPointZoneComponent>,
    protected entryPointService: EntryPointService,
    protected notificationService: NotificationService,
    protected entryPointZoneService: EntryPointZoneService,
    protected dialogService: DialogService,
    private siteService: SiteService,
    protected requiredFieldsService: RequiredFieldsService,
    protected messageService: MessageService,
  ) {}

  ngOnInit(): void {
    //labels by message service
if (localStorage.getItem('UpdateEntryPointZoneLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('UpdateEntryPointZoneLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Update Entry Point Zone Modal",
      "EntryPointZone"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('UpdateEntryPointZoneLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    this.entryPointZone = { ...this.data };
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}

  ngAfterViewChecked() {
    // setting up the required fields
    this.requiredFieldsService.setRequiredFields(
      'Edit Entry Point Zones',
      this.entryPointForm
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  // Method to Update Entry Point zone

  onSaveChanges() {
    this.dialogService
      .openConfirmDialog(
        this.screenLabels["EditEntryPointZoneUpdateLabel"]? this.screenLabels["EditEntryPointZoneUpdateLabel"]:"Confirm Entry Point Zone Update",
        this.screenLabels["EditEntryPointZoneUpdateMsg"]? this.screenLabels["EditEntryPointZoneUpdateMsg"]:"Are you sure you want to save the changes ?"
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        const name = this.entryPointZone.Name.trim() === this.entryPointZone.Name ? this.entryPointZone.Name : this.entryPointZone.Name.trim();
        this.entryPointZone.Name = name ;
        if (confirm) {
          this.entryPointZoneService
            .updateEntryPointZoneAPI(this.entryPointZone)
            .then(() => {});
        }
      });

    this.onNoClick();
  }

  isNameEmpty(): boolean {
    return !this.entryPointZone.Name || !this.entryPointZone.Name.trim();
  }

  nameFormControl = new FormControl("", [
    Validators.required,  // Required validation
    Validators.pattern(/^[a-zA-Z\s-][a-zA-Z\s0-9-]*$/),  // Updated pattern validation
    this.specialCharacterValidator(),
  ]);

  // Custom validator function
  specialCharacterValidator() {
    return (control: FormControl) => {
      const value = control.value;
      if (!value) {
        return null; // No validation for empty values
      }

      // Check for spaces or hyphens followed by spaces
      if (/^\s/.test(value)) {
        return { specialCharactersNotAllowed: true };
      }
  
      const isValid = /^[a-zA-Z\s0-9]*(-[a-zA-Z\s0-9]+)*$/.test(value);
  
      if (isValid && !/^-/.test(value)) {
      return null;
    };
  return { specialCharactersNotAllowed: true };
    }
  }

  // getNameRequiredErrorMessage() {
  //   return this.nameFormControl.hasError("required")
  //     ? "You must enter a value"
  //     : " ";
  // }

  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }
}
