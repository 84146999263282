export class PassTemplate {
  public PassTemplateID: number;
  public TemplateCategoryID: number;
  public OrganizationID: number;
  public IsPersonal: number;
  public TemplateName: string;
  public VisitorFirstName: string;
  public VisitorFirstNameLock: boolean;
  public VisitorMiddleName: string;
  public VisitorMiddleNameLock: boolean;
  public VisitorLastName: string;
  public VisitorLastNameLock: boolean;
  public VisitorNameSuffix: string;
  public VisitorNameSuffixLock: boolean;
  public VisitorCompany: string;
  public VisitorCompanyLock: boolean;
  public VisitorEmail: string;
  public VisitorEmailLock: boolean;
  public VisitorPhone: string;
  public VisitorPhoneLock: boolean;
  public VisitorCountry: number;
  public VisitorCountryLock: boolean;
  public StartTimeParameters: string;
  public StartTimeLock: boolean;
  public EndTimeDuration: number;
  public EndTimeLock: boolean;
  public EstArrivalTime: any;
  public EstArrivalTimeLock: boolean;
  public EstDepartureTime: any;
  public EstDepartureTimeLock: boolean;
  public VisitReason: number;
  public VisitReasonLock: boolean;
  public IncludeNonWorkingdays: number;
  public IncludeNonWorkingdaysLock: boolean;
  public ExpectedEntryPoint: number;
  public ExpectedEntryPointLock: boolean;
  public EntryPointGroupID: number;
  public EntryPointGroupIDLock: boolean;
  public EntryType: number;
  public EntryTypeLock: boolean;
  public NotesInternal: string;
  public NotesInternalLock: boolean;
  public NotesPass: string;
  public NotesPassLock: boolean;
  public ParkingLotID: number;
  public ParkingLotIDLock: boolean;
  public ParkingSpot: string;
  public ParkingSpotLock:boolean;
  public DestinationBldg: number;
  public DestinationBldgLock: boolean;
  public DestinationBlgFlr: string;
  public DestinationBlgFlrLock: boolean;
  public DestinationRoom: string;
  public DestinationRoomLock: boolean;
  public HostFirstName: string;
  public HostFirstNameLock: boolean;
  public HostMiddleName: string;
  public HostMiddleNameLock: boolean;
  public HostLastName: string;
  public HostLastNameLock: boolean;
  public HostNameSuffix: string;
  public HostNameSuffixLock: boolean;
  public HostCompany: string;
  public HostCompanyLock: boolean;
  public HostPhone: string;
  public HostPhoneLock: boolean;
  public HostEmail: string;
  public HostEmailLock: boolean;
  public HostTWID: string;
  public HostTWIDLock: boolean;
  public PhotoPass: number;
  public PhotoPassLock: boolean;
  public Active: number;
  public UserID: number;
  public CategoryName: string;
  public StartDate: Date;
  public EndDate: Date;
  public CreatedByID: number;
  public ModifiedByID: number;

  constructor() {
    this.PassTemplateID = 0;
    this.TemplateCategoryID = 0;
    this.OrganizationID = 0;
    this.IsPersonal = 1;
    this.TemplateName = "";
    this.VisitorFirstName = null;
    this.VisitorFirstNameLock = false;
    this.VisitorMiddleName = null;
    this.VisitorMiddleNameLock = false;
    this.VisitorLastName = null;
    this.VisitorLastNameLock = false;
    this.VisitorNameSuffix = null;
    this.VisitorNameSuffixLock = false;
    this.VisitorCompany = null;
    this.VisitorCompanyLock = false;
    this.VisitorEmail = null;
    this.VisitorEmailLock = false;
    this.VisitorPhone = null;
    this.VisitorPhoneLock = false;
    this.StartTimeParameters = null;
    this.StartTimeLock = false;
    this.EndTimeDuration = 0;
    this.EndTimeLock = false;
    this.EstArrivalTime = null;
    this.EstArrivalTimeLock = false;
    this.EstDepartureTime = null;
    this.EstDepartureTimeLock = false;
    this.VisitReason = null;
    this.VisitReasonLock = false;
    this.IncludeNonWorkingdays = 0;
    this.IncludeNonWorkingdaysLock = false;
    this.ExpectedEntryPoint = 0;
    this.ExpectedEntryPointLock = false;
    this.EntryPointGroupID = 0;
    this.EntryPointGroupIDLock = false;
    this.EntryType = 1;
    this.EntryTypeLock = false;
    this.NotesInternal = null;
    this.NotesInternalLock = false;
    this.NotesPass = null;
    this.NotesPassLock = false;
    this.ParkingLotID = 0;
    this.ParkingLotIDLock = false;
    this.DestinationBldg = 0;
    this.DestinationBldgLock = false;
    this.DestinationBlgFlr = null;
    this.DestinationBlgFlrLock = false;
    this.DestinationRoom = null;
    this.DestinationRoomLock = false;
    this.ParkingSpot = null;
    this.ParkingSpotLock = false;
    this.HostFirstName = null;
    this.HostFirstNameLock = false;
    this.HostMiddleName = null;
    this.HostMiddleNameLock = false;
    this.HostLastName = null;
    this.HostLastNameLock = false;
    this.HostNameSuffix = null;
    this.HostNameSuffixLock = false;
    this.HostCompany = null;
    this.HostCompanyLock = false;
    this.HostPhone = null;
    this.HostPhoneLock = false;
    this.HostEmail = null;
    this.HostEmailLock = false;
    this.HostTWID = null;
    this.HostTWIDLock = false;
    this.PhotoPass = 0;
    this.PhotoPassLock = false;
    this.Active = 0;
    this.UserID = 0;
    this.CategoryName = null;
    this.CreatedByID = 0;
    this.ModifiedByID = 0;
  }
}
