import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import * as _ from 'lodash';
import { environment } from "src/environments/environment";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor() { }

    /**
    * The method returns the Okta token string
    * @returns Okta token string
    */
    private _getOktaToken() {
        let oktaToken = "";

        try {

            if (localStorage.getItem("okta-token-storage") !== '{}') {
                const oktaTokenResponse: string = localStorage.getItem("okta-token-storage");
                const responseObj: Object = JSON.parse(oktaTokenResponse);
                const isEmptyResponse = _.isEmpty(responseObj);

                // @ts-ignore
                oktaToken = !isEmptyResponse ? (responseObj.idToken).idToken : '';
            }
        } catch (ex) {
            console.error(ex);
        }
        finally {

            return oktaToken;
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (req.url.search(environment.baseUrl) !== -1) {

            // attach the Authorization token for internal(vms) related api
            const request = req.clone({
                setHeaders: {
                    "Authorization": this._getOktaToken()
                }
            });
            return next.handle(request);
        }
        else
            return next.handle(req);
    }



}