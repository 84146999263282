import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { SiteService } from "../services/site.service";
import { cloneDeep } from "lodash";
import { ISystemSettingsGetAPIResponse } from "../models/interfaces/system-settings-get-api.model";
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: "root",
})
export class NotificationSettingsService {

  private _siteSettings: ISystemSettingsGetAPIResponse;

  get siteSettings() {
    return cloneDeep(this._siteSettings);
  }

  constructor(
    public http: HttpClient,
    protected siteService: SiteService
  ) {  }

  public getNotificationSettingsAPI() {
    const apiURL = environment.getAPI("getSystemSettings");

    const body = {
      SiteID: this.siteService.selectedSiteID,
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise<ISystemSettingsGetAPIResponse>(
      (resolve, reject) => {
        this.http
          .post(apiURL, body, httpOptions)
          .toPromise()
          .then(
            async (success: HttpResponse<any>) => {
              // success
              // console.log(success.body);

              this._siteSettings = success.body;
              console.log(success.body);
              resolve(success.body);
            },
            (err) => {
              // error
              reject(err);
            }
          );
      }
    );
    return promise;
  }

}