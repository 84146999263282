import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private appLanguage = [];
  public screenLabels: Array<string> = [];
  public prefLanguageID: number;

  languageSubject = new Subject();

  constructor(private http: HttpClient) { }

  setLanguageID(status: boolean){
    this.languageSubject.next({status:status});
  }
  getLanguageID(): Observable<any>{
    return this.languageSubject.asObservable();
  }

  public setPrefLanguage(prefLanguage){
    this.prefLanguageID = prefLanguage;
  }

  public getPrefLanguage() {
    return this.prefLanguageID;
  }


  public getLabelLanguageObs(screenName, tableName) {
    const apiURL = environment.getAPI("getSystemSettingsByLanguage");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    
    const body = { 
      ScreenName: screenName,
      TableName: tableName,
      LanguageID: this.prefLanguageID
     }

    const res = this.http.post<any>(apiURL, body, httpOptions); 
    //this.appLanguage = res.body        
    return res;
  }
 
  public loadScreenLabelsByLanguage(appLanguage) {
    const labels = appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));

    console.log(labels)
    console.log(this.screenLabels)

  }

  // getTranslatedMessage(message:string, tagName?:string){
  //   console.log(message);
  //   const apiURL =  environment.getAPI("getTransalatedMessage");

  //   const httpOptions: { headers; observe } = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json",
  //     }),
  //     observe: "response",
  //   };
  //   const body = {
  //     Message : message,
  //     LanguageID : this.prefLanguageID,
  //     TagName: tagName? tagName :  null
  //   }
  //   const res = this.http.post<any>(apiURL, body, httpOptions);
  //   return res;

  // }

  // getTagMessageList(tagName: string){
  //   const apiURL = environment.getAPI("getTagMessageList");

  //   const httpOptions: { headers; observe } = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json",
  //     }),
  //     observe: "response",
  //   };
  //   const body = {
  //     LanguageID : this.prefLanguageID,
  //     TagName: tagName
  //   }
  //   const res = this.http.post<any>(apiURL, body, httpOptions);
  //   return res;

  // }
}

