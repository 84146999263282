import { Component, OnInit } from "@angular/core";
import { BuildingService } from "../services/building.service";
import { Building } from "../models/building.model";
import { MatDialog } from "@angular/material";
import { ViewBuildingModalComponent } from "./modals/view-building-modal/view-building-modal.component";
import { SearchService } from "../services/search.service";
import { HttpErrorResponse } from "@angular/common/http";
import { NotificationService } from "../services/notification.service";
import { SiteService } from "../services/site.service";
import { GridApi } from "ag-grid-community";
import { MessageService } from '../services/message.service';

@Component({
  selector: "app-building",
  templateUrl: "./desktop/building.desktop.component.html",
  styleUrls: ["./desktop/building.desktop.component.css"],
})
export abstract class BuildingComponent implements OnInit {
  private gridApi: GridApi;
  protected selectedBuilding: Building;
  private paginationPageSize;
  protected buildingColumnDefs = [];
  protected rowData: Array<any>;
  protected gridOptions: Object;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  public defaultColDef : object;
  constructor(
    protected buildingService: BuildingService,
    protected dialog: MatDialog,
    private searchService: SearchService,
    private notificationService: NotificationService,
    private siteService: SiteService,
    protected messageService: MessageService,
  ) {
    this.paginationPageSize = 50;
    this.gridOptions = {
      getRowClass: (params) =>{
        if(params.data.Active === 0){
          return 'row-inactive';
        }
      }
    }
    this.defaultColDef = {
      resizable: true,
  }
  }

  ngOnInit(): void {
    // this.searchService.pageName = "Building";
    this.searchService.setPageName("Building");


    //labels by message service
if (localStorage.getItem('BuildinglLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('BuildinglLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Building",
      "Building"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('BuildinglLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        this.rowData = this.searchService.searchRecords;
        this.gridApi.paginationGoToPage(0)
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    // retrieves the result set from Smart Search API cal and loads the data it into ag-grid
    this.searchService.searchInitiated.subscribe((eventData: boolean) => {
      if (eventData) {
        this.searchService.smartSearchPromise
          .then(() => {
            this.rowData = this.searchService.searchRecords;
            this.gridApi.paginationGoToPage(0);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    });

    // subscribe to site changed
    this.siteService.siteChanged.subscribe((event: boolean) => {
      if(event){
        this.searchService
        .smartSearchMultiSiteAPI(null)
        .then(() => {
          this.rowData = this.searchService.searchRecords;
          this.gridApi.paginationGoToPage(0);         
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
      }
    });

  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDef()
}

setColumnDef(){
  this.buildingColumnDefs = [
    {
      headerName: this.screenLabels["BuildingNumberAgGrid"]
        ? this.screenLabels["BuildingNumberAgGrid"]
        : "Building Number",
      field: "BuildingNumber",
      sortable: true,
      width: 110,
      filter: true,
    },
    {
      headerName: this.screenLabels["BuildingNameAgGrid"]
        ? this.screenLabels["BuildingNameAgGrid"]
        : "Building Name",
      field: "BuildingName",
      sortable: true,
      width: 110,
      filter: true,
    },
  ];
}

  // make ag-rid colums fill their container
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  // show building modal
  openBuilding($event) {
    this.selectedBuilding = $event.data;
    this.dialog.open(ViewBuildingModalComponent, {
      disableClose: true,
      data: this.selectedBuilding,
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    const defaultSortModel = this.searchService.getSortingRules();
    params.api.setSortModel(defaultSortModel);
  }
  onSortChanged() {}
}
