import { Injectable, EventEmitter, Output } from "@angular/core";
import {
  HttpHeaders,
  HttpResponse,
  HttpClient,
  HttpErrorResponse,
} from "@angular/common/http";
import { UserService } from "./user.service";
import { NotificationService } from "./notification.service";
import { ClientInfoService } from "./client-info.service";
import { OktaAuthService } from "@okta/okta-angular";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { SiteService } from "./site.service";
// import { UserActionsService } from "./user-actions.service";
import * as moment from "moment-timezone";
import { TimezoneService } from "./timezone.service";
import { Observable, Subject } from "rxjs";
import { MessageService } from "./message.service";
import { error } from "console";

@Injectable()
export class AuthService {
  // signedInFlag: boolean;
  // isUserRegistered: boolean;
  validCaller: boolean;
  callerMessage: string;
  public userFunctions: Array<boolean> = [];

  @Output() userChangedToCallInUser: EventEmitter<boolean>;

  responseSubject = new Subject();
  siteSubject = new Subject();

  protected response: any;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private notificationService: NotificationService,
    private clientInfoService: ClientInfoService,
    public oktaAuthService: OktaAuthService,
    private router: Router,
    private siteService: SiteService,
    private messageService: MessageService,
    private timezoneService: TimezoneService
  ) {
    // this.signedInFlag = false;
    // this.isUserRegistered = false;
    this.validCaller = true;
    this.userChangedToCallInUser = new EventEmitter<boolean>();
    localStorage["isUserRegistered"] = false; // comment to stop auto redirection to OKTA for re-validation after every refresh
  }

  //for getting the userid
  setResponseFromAPI(response: any) {
    this.responseSubject.next({response:response})
  }
  getResponseFromAPI(): Observable<any>{
    return this.responseSubject.asObservable();
  }
  //for enabling sitedropdown
  setVerifyUser(status: boolean){
    this.siteSubject.next({status:status});
  }
  getVerifyUser(): Observable<any>{
    return this.siteSubject.asObservable();
  }
  public authenticateUserAPI(email: string) {
    const apiURL = environment.getAPI("authorizeUser");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, { EmailID: email }, httpOptions)
        .toPromise()
        .then(
          async (success: HttpResponse<any>) => {
            // success
            this.userService.email = success.body.EmailID;
            this.userService.userID = +success.body.UserID;
            this.userService.loggedInEmail = success.body.EmailID;
            this.userService.loggedInUserID = +success.body.UserID;
            this.userService.firstName = success.body.FirstName;
            this.userService.lastName = success.body.LastName;
            this.userService.middleName = success.body.MiddleName;
            this.userService.suffix = success.body.Suffix;
            this.userService.pin = success.body.PIN;
            this.userService.phone = success.body.Phone;
            this.userService.loggedInUserFirstName = success.body.FirstName;
            this.userService.loggedInUserLastName = success.body.LastName;
            this.userService.loggedInUserMiddleName = success.body.MiddleName;
            this.validCaller = false;
            this.callerMessage = "";
            this.userService.roleFunctionArray = success.body.RoleFunctionArray;
            success.body.RoleFunctionArray.filter((f) => f.SiteID == success.body.DefaultSiteID).map((rfa) => rfa.Tag).forEach(
              (element) => {
                this.userService.userFunctions[element] = true;
              }
            )

            // this.clientInfoService.readConfig();

            // this.signedInFlag = true;
            localStorage["isUserRegistered"] = true;
            this.siteService.initSites(success.body.AvailableSiteList);

            if (success.body.DefaultSiteID) {
              this.siteService.defaultSiteID = +success.body.DefaultSiteID;
              this.siteService.selectedSiteID = +success.body.DefaultSiteID;
              this.userService.selectedSiteID = +success.body.DefaultSiteID;
            } else {
              this.siteService.defaultSiteID = this.siteService.getSites()[0].SiteID;
              this.siteService.selectedSiteID = this.siteService.getSites()[0].SiteID;
              this.userService.selectedSiteID = this.siteService.getSites()[0].SiteID;
            }

            await this.timezoneService.setSiteUTCOffset();


            await this.siteService.getSiteSettingsAPI();

            if (localStorage.getItem("PrefLanguage")) {
              this.messageService.setPrefLanguage(localStorage.getItem("PrefLanguage"));
            }else {
              this.messageService.setPrefLanguage(success.body.PrefLanguageID ? success.body.PrefLanguageID : 1);
            }
            

            let cloudValue = (this.siteService.siteSettings.SystemSettings.filter(x => x.Name === "CloudPrintingEnabled").map(x => x.Value))[0];
            if(cloudValue === "1") localStorage["isCloudPrinting"] = true;
            else if(cloudValue === "0") localStorage["isCloudPrinting"] = false;
            
            let phoneValue = (this.siteService.siteSettings.SystemSettings.filter(x => x.Name === "Phone").map(x => x.Value))[0];
            if(phoneValue === "USA") localStorage["isPhoneUSA"] = true;
            else if(phoneValue === "International") localStorage["isPhoneUSA"] = false;
        
            // set the default site id
            // this.siteService.selectedSiteID = 1; // TODO: read this from API

            resolve(success);
          },
          (err: HttpErrorResponse) => {
            // error
            this.notificationService.failure(err.message);
            localStorage["isUserRegistered"] = false;

            reject(err);
          }
        );
    });
    return promise;
  }

  public authenticateCallerAPI(
    userID: number,
    authenticationCode: number,
    emailID: string
  ) {
    const apiURL = environment.getAPI("verifyAuthoriserPin");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const postBody = {
      UserID: userID,
      PIN: authenticationCode,
      ToEmailID: emailID,
      CalledByUserID: this.userService.userID,
      CalledByUserEmailID: this.userService.email,
      SiteID: this.siteService.selectedSiteID
    };
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            const valid = success.body.Valid;
            if (valid == true) {
              this.validCaller = true;
              success.body.RoleFunctionArray.filter((f) => f.SiteID == success.body.DefaultSiteID).map((rfa) => rfa.Tag).forEach(
                (element) => {
                  this.userFunctions[element] = true;
                }
              )
            } else {
              this.validCaller = false;
            }
            resolve(success);
          },
          (err: HttpErrorResponse) => {
            // error
            this.notificationService.failure(err.message);
            reject(err);
          }
        );
    });
    return promise;
  }

  public async logout() {
    // this.signedInFlag = false;
    localStorage["isUserRegistered"] = false;
    this.oktaAuthService.logout();
    localStorage.clear();
    sessionStorage.clear();
  }

  public userSiteListAuthorize(email: string){

    const apiURL = environment.getAPI("userSiteListToAuthorize");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      EmailID: email
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    return res;
  }
  
  public externalUserActiveBadge(userID: number, siteID: number){

    const apiURL = environment.getAPI("checkExternalUserActiveBadge");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      UserID: userID,
      SiteID: siteID
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    // this.dataChanged.emit(true);
    return res;
  }

  public checkUserAliasEmailID(email: string){

    const apiURL = environment.getAPI("checkUserAliasEmail");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      EmailID: email
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);          
    return res;
  }

  public isAcknowledgementNeededAPI(userID: number, siteID: number) {

    const apiURL = environment.getAPI("isAcknowledgementNeeded");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      UserID: userID,
      SiteID: siteID
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    return res;
  }

  public getPolicyAcknowledgementUserSettings(userID: number) {

    const apiURL = environment.getAPI("getPolicyAcknowledgementUserSettings");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      UserID: userID
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    return res;
  }

  public policyAcknowledgementUserSettingsUpdate(userID: number, loginCountSettingsID: number, loginCount: string, isAckAccepted: boolean) {

    const apiURL = environment.getAPI("policyAcknowledgementUserSettingsUpdate");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      UserID: userID,
      LoginCountSettingsID: loginCountSettingsID,
      LoginCount: loginCount,
      IsAckAccepted: isAckAccepted
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    res.subscribe((data: HttpResponse<any>) => {
      console.log("output", data.body);
    })
    return res;
  }

}
