import { Component, OnInit } from "@angular/core";
import { OktaAuthService } from "@okta/okta-angular";
import { AuthService } from "../services/auth.service";
import { NotificationService } from "../services/notification.service";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { SpinnerService } from "../services/spinner.service";
import { RequiredFieldsService } from "../services/required-fields.service";
import { SiteService } from "../services/site.service";
import { UserService } from "../services/user.service";
import { MessageService } from '../services/message.service';

@Component({
  selector: "app-verify-user",
  templateUrl: "./verify-user.component.html",
  styleUrls: ["./verify-user.component.css"],
})
export class VerifyUserComponent implements OnInit {
  protected appLanguage;
  public screenLabels: Array<string> = [];
  constructor(
    private oktaAuthService: OktaAuthService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private router: Router,
    private spinnerService: SpinnerService,
    private requiredFieldsService: RequiredFieldsService,
    private siteService: SiteService,
    private userService: UserService,
    protected messageService: MessageService,
  ) { }

  async ngOnInit() {

//labels by message service
if (localStorage.getItem('VerifyUserLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('VerifyUserLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Verify User",
    "VerifyUser"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('VerifyUserLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    setTimeout(() => {
      this.spinnerService.setIsLoading(true);
    }, 0);
    const oktaEmail = await (await this.oktaAuthService.getUser()).email;
    await this.authService
      .authenticateUserAPI(oktaEmail)
      .then(() => {
        // if (!this.siteService.defaultSiteID) {
        //   throw new Error('Default Site is not set for the user.');
        // }

        if (!this.siteService.getSites() || this.siteService.getSites().length === 0) {
          throw new Error(this.screenLabels["UserNotAssignedToActiveSiteOrgErrorMsg"]? this.screenLabels["UserNotAssignedToActiveSiteOrgErrorMsg"]: "The user is not assigned to Site / Organization. Kindly request the Administrator to add the user to any suitable Organization");
        }

        else if (!Object.keys(this.userService.userFunctions).length) {
          throw new Error(this.screenLabels["UserNotAssignedToActiveRoleErrorMsg"]? this.screenLabels["UserNotAssignedToActiveRoleErrorMsg"]: "The user is not assigned to an active Role / Function. Kindly request the Administrator to assign an appropriate Role");
        }
      })
      .catch((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.notificationService.failure(
            this.screenLabels["YouNotRegisteredErrorMsg"]? this.screenLabels["YouNotRegisteredErrorMsg"]: "You are not registered to the Application"
          );
        } 
        else if (err.status === 403) {
          this.notificationService.failure(
            this.screenLabels["DefaultSiteAssignedMsg"]? this.screenLabels["DefaultSiteAssignedMsg"]: "A default site is not assigned to you. Kindly request the administrator to associate a site to your profile"
          );
        }
        else if (err.status === 500) {
          this.notificationService.failure(
            this.screenLabels["DuplicateUserFoundMsg"]? this.screenLabels["DuplicateUserFoundMsg"]: "Duplicate user found in system. Please contact your administrator"
          );
        }  
        else {
          this.notificationService.failure(err.message);
        }
        this.authService.logout();
        // this.router.navigate(["/"]);
      });



    if (localStorage.getItem("isUserRegistered") === "true") {

      await this.requiredFieldsService.getRequiredFieldsAPI();
      // this is to check if the site is in maintenance mode
      if (this.siteService.isWIPSite()) {        
        this.router.navigate(['/site-maintenance']);
      }
      else {
        this.router.navigate(["/dashboard"]);
      }
    } else {
      this.authService.logout();
    }
    setTimeout(() => {
      this.spinnerService.setIsLoading(false);
    }, 0);
  }
  
  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }
}
