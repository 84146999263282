import { Component, OnInit, Renderer2 } from '@angular/core';
import { UserService } from '../services/user.service';
import { SiteService } from '../services/site.service';
import { SearchService } from '../services/search.service';
import {MatAccordion, MatExpansionModule} from '@angular/material/expansion';

@Component({
  selector: 'app-report-problem',
  templateUrl: './report-problem.component.html',
  styleUrls: ['./report-problem.component.css']
})
export class ReportProblemComponent implements OnInit {
  protected userOrganizations: Array<{
    OrganizationID: number;
    Name: string;
    StartDate: string;
    EndDate: string;
    IsAdmin: number;
  }> = [];
  public userOrg;

  constructor(
    public userService: UserService,
    public siteService: SiteService,
    public searchService: SearchService,
    private renderer: Renderer2) {

  }

  ngOnInit() {
    this.loadCognitoFormsScript();
    var orgID = this.searchService.getOrganizationID();
    this.userService.userOrganizations.forEach((element) => {
      if (element.OrganizationID == orgID)
        this.userOrg = element.Name
    });

    this.userService
      .getUserAssociationAPI(this.userService.userID, this.siteService.selectedSiteID)
      .then(() => {
        this.userOrganizations = [
          ...this.userService
            .getUserDetails()[0]
            .UserOrganization.map((o) => ({
              OrganizationID: o.OrganizationID,
              Name: o.Name,
              StartDate: o.Startdate,
              EndDate: o.EndDate,
              IsAdmin: o.IsAdmin ? (o.IsAdmin == 1 ? 1 : 0) : 0,
              Active: o.UserOrganizationActive,
            }))
            .filter((f) => f.Active),
        ];
        this.userService.userOrganizations = this.userOrganizations;
        console.log(this.userService.organizationID)
      })

  }
  private loadCognitoFormsScript() {
    const script = this.renderer.createElement('script');
    script.src = 'https://www.cognitoforms.com/f/iframe.js';
    script.type = 'text/javascript';
    script.async = true;
    this.renderer.appendChild(document.body, script);
    script.onload = () => {
      this.prefillForm();
    };
  }
  private prefillForm() {
    if (window.hasOwnProperty('Cognito')) {
      const userEmail = this.userService.loggedInEmail;
      const userFirstName = this.userService.loggedInUserFirstName;
      const userLastName = this.userService.loggedInUserLastName;
      const selectedSite = this.siteService.selectedSite;

      (window as any).Cognito("#form1").prefill({
        "OKTAID": userEmail,
        "Name": {
          "First": userFirstName,
          "Last": userLastName
        },
        "Site": selectedSite,
        "Organization": this.userOrg
      });
      (window as any).Cognito("#form1").setCss(
        "#cognito .cog-form__container{padding:40px;}");
    }
  }
}
