import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ModuleWithComponentFactories,
  ElementRef,
} from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, Validators, NgForm } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { EntryPoint } from "src/app/models/entry-point.model";
import { EntryPointGroup } from "src/app/models/entry-point-group.model";
import { Building } from "src/app/models/building.model";
import { ViewSelectorService } from "src/app/services/view-selector.service";
import { PassService } from "src/app/services/pass.service";
import { BuildingService } from "src/app/services/building.service";
import { UserService } from "src/app/services/user.service";
import { NotificationService } from "src/app/services/notification.service";
import { DatePipe } from "@angular/common";
import { EntryPointService } from "src/app/services/entry-point.service";
import { DialogService } from "src/app/services/dialog.service";
import { EntryPointGroupService } from "src/app/services/entry-point-group.service";
import { OrganizationService } from "src/app/services/organization.service";
import { ParkingLotService } from "src/app/services/parking-lot.service";
import { SearchService } from "src/app/services/search.service";
import { RequiredFieldsService } from "src/app/services/required-fields.service";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { MessageService } from "src/app/services/message.service";
import {
  MatRadioChange,
  MatSelectChange,
  DateAdapter,
  MAT_DATE_FORMATS,
} from "@angular/material";
import { TemplateCategory } from "src/app/models/template-category,model";
import { PassTemplate } from "src/app/models/pass-template.model";
import { TemplateCategoryService } from "src/app/services/template-category-service";
import { PassTemplateService } from "src/app/services/pass-template-service";
import { start } from "repl";
import { StartdateConfigModalComponent } from "../startdate-config/startdate-config.modal.component";
import { SiteService } from "src/app/services/site.service";
import { UtilityService } from "src/app/services/utility.service";
import {
  MatDatePickerAdapter,
  CUSTOM_DATE_FORMATS,
} from "src/app/common/mat-date-picker-formatter/mat-date-picker-formatter";
import moment from "moment";

export interface DialogData {}

export interface Reason {
  value: string;
  viewValue: string;
}
export interface Point {
  name: string;
}
export interface PointGrps {
  name: string;
}
// export interface Building {
//   name: string;
// }

@Component({
  selector: "app-view-template-modal",
  templateUrl: "./view-template-modal.component.html",
  styleUrls: ["./view-template-modal.component.css"],
  providers: [
    { provide: DateAdapter, useClass: MatDatePickerAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export class ViewTemplateComponent implements OnInit {
  @ViewChild("updatePassRef") updatePassForm: NgForm;
  @ViewChild("expirationDate") expirationDate: ElementRef;

  protected DateMethod: number = 0;
  protected StartWeek: number = 0;
  protected StartDay: number = 0;
  protected weekList: Array<{
    PickListID: number;
    TableName: string;
    FieldName: string;
    DisplayValue: string;
    Active: boolean;
  }> = [];

  protected dateMethod: Array<{
    PickListID: number;
    TableName: string;
    FieldName: string;
    DisplayValue: string;
    Active: boolean;
  }> = [];
  private apiBody: any = {};
  protected entryPoints: Array<EntryPoint> = [];
  protected entryPointGroups: Array<EntryPointGroup> = [];
  protected templateCategories: Array<TemplateCategory> = [];
  protected reasonOfVisit: Array<{
    PickListID: number;
    TableName: string;
    FieldName: string;
    DisplayValue: string;
    Active: boolean;
  }> = [];
  protected isReasonOfVisitLoading = true;
  protected isBuildingLoading = true;
  protected isEntryPointLoading = true;
  protected isParkingDisabled = true;
  protected isExpirationDateDisabled = true;
  protected passTemplate: PassTemplate = new PassTemplate();
  protected assignedParking: Array<{
    ParkingLotName: string;
    ParkingLotID: number;
  }> = [];
  public parkingLotName: string;

  protected buildings: Array<Building>;
  protected dayRange: Array<string> = [];
  protected StartDate: string = "Not Specified";
  protected DateMethodName: string = "";
  protected minTime:string = "";
  protected phoneIsUSA:boolean = false;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  protected countryList: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PassTemplate,
    public dialogRef: MatDialogRef<ViewTemplateComponent>,
    private _snackBar: MatSnackBar,
    service: ViewSelectorService,
    protected passService: PassService,
    protected buildingService: BuildingService,
    protected userService: UserService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    protected entryPointService: EntryPointService,
    protected dialogService: DialogService,
    private entryPointGroupService: EntryPointGroupService,
    private organizationService: OrganizationService,
    private parkingLotService: ParkingLotService,
    protected searchService: SearchService,
    protected requiredFieldsService: RequiredFieldsService,
    protected templateCategoryService: TemplateCategoryService,
    protected passTemplateService: PassTemplateService,
    protected siteService: SiteService,
    private _utilService: UtilityService,
    protected messageService: MessageService,
  ) {
    document.addEventListener(
      "keydown",
      (e) => {
        if ((e.target as any).nodeName === "MAT-EXPANSION-PANEL-HEADER") {
          e.stopImmediatePropagation();
        }
        if ((e.target as any).nodeName === "MAT-DIALOG-CONTAINER") {
          if (this.step != 2) {
            this.nextStep();
          } else {
            return;
          }
        }
      },
      true
    );
    this.isParkingDisabled = this.passTemplate.EntryType == 1 ? false : true; // enable ParkingDrodown if EntryType is Drive on

    //Api call to get ParkingLotDropdown

    this.organizationService
      .getOrganizationByIDAPI(this.userService.organizationID)
      .then(() => {
        const details = this.organizationService.getOrganizationByIDResponse()[0];
        // console.log(details);
        details.OrganizationParkingLimitArray.map((p) => {
          if (p.ParkingLotArray) {
            console.log(p.ParkingLotArray);
            p.ParkingLotArray.map((array) => {
              if (array) {
                this.assignedParking.push(array);
              }
            });
          }
        });

        // console.log(this.assignedParking);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }
  durationInSeconds = 3;
  step = 0;
  disabled = false;
  private fieldArray: Array<any> = [];
  private newAttribute: any = {};

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  nextSteptwo() {
    this.email.markAsTouched();
    if (!this.email.invalid) {
      this.step++;
    }
  }
  email = new FormControl("", [Validators.required, Validators.email]);
  entryPointGroupFormControl = new FormControl("", Validators.required);

  //'Reason for visit' dropdown
  reasons: Reason[] = [
    { value: "vendor", viewValue: "Vendor" },
    { value: "business meeting", viewValue: "Business Meeting" },
    { value: "screening", viewValue: "Screening" },
    { value: "consultant", viewValue: "Consultant" },
    { value: "interview", viewValue: "Interview" },
    { value: "personal", viewValue: "Personal" },
  ];

  //Building autocomplete
  myControl = new FormControl();

  filteredOptions: Observable<Building[]>;
  //Entry type autocomplete
  entryControl = new FormControl();
  entryOptions: Point[] = [
    { name: "1658" },
    { name: "153" },
    { name: "8080" },
    { name: "16616" },
  ];
  filteredPoints: Observable<Point[]>;

  entrygrpControl = new FormControl();
  entrygrpOptions: PointGrps[] = [
    { name: "A" },
    { name: "B" },
    { name: "C" },
    { name: "D" },
  ];
  filteredPointGrps: Observable<PointGrps[]>;

  ngOnInit() {
    console.log(this.data);
    if (localStorage.getItem("isPhoneUSA") === "true") {
      this.phoneIsUSA = true;
    }
    else if (localStorage.getItem("isPhoneUSA") === "false") {
      this.phoneIsUSA = false;
    }
    //labels by message service
    if (localStorage.getItem('ViewTemplateLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('ViewTemplateLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "View Pass Template Modal",
        "PassTemplate"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('ViewTemplateLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    if (this.siteService._siteHasCountry) {
      this.passService.getCountryListAPI().subscribe((res: HttpResponse<any>) => {
        console.log("response", res);
        this.countryList = res.body;
      }, error => {
        console.log(error);
      });
    }


    this.dayRange = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    this.passService.getVisitReasonsAPI().then(() => {
      // console.log(this.passService.reasonOfVisit);
      this.isReasonOfVisitLoading = false;
      this.reasonOfVisit = this.passService.reasonOfVisit;

      console.log(this.reasonOfVisit);
    });

    this.buildingService.parseAPI().then(() => {
      this.isBuildingLoading = false;
      this.buildings = [...this.buildingService.getBuildings()];

      // console.log(this.entryPointService.getEntryPoints());
    });

    this.entryPointService.parseGetEndpointAPI().then(() => {
      this.isEntryPointLoading = false;
      this.entryPoints = this.entryPointService.getEntryPoints();
    });

    this.passTemplate = { ...this.data };

    this.passTemplate.EstArrivalTime =  this.passTemplate.EstArrivalTime ? new Date(
      this.passTemplate.EstArrivalTime
    ) : null;
    this.passTemplate.EstDepartureTime =  this.passTemplate.EstDepartureTime ? new Date(
      this.passTemplate.EstDepartureTime
    ) : null;

    this.isEntryPointLoading = false;

    this.initializeLocks();
    console.log(this.passTemplate);

    this.StartDate = "Not Specified";
    this.passTemplateService.getPassTemplatePickListTablesAPI().then(() => {
      this.dateMethod = this.passTemplateService.passTemplateTables.filter(
        (f) => f.FieldName == "MethodTypeParam"
      );
      this.weekList = this.passTemplateService.passTemplateTables.filter(
        (f) => f.FieldName == "WeekOptionParam"
      );
      if (this.passTemplate.StartTimeParameters) {
        var startTimeParmeterList: string[] = this.passTemplate.StartTimeParameters.split(
          ","
        );
        console.log(startTimeParmeterList);

        if (startTimeParmeterList.length > 0) {
          this.DateMethod = Number(startTimeParmeterList[0]);
          this.dateMethod.forEach((element) => {
            if (element.PickListID == this.DateMethod) {
              console.log(element);
              this.DateMethodName = element.DisplayValue;
            }
          });
          if (
            this.DateMethodName == "WeekWise" &&
            startTimeParmeterList.length >= 2
          ) {
            this.StartWeek = Number(startTimeParmeterList[1]);
            this.StartDay = Number(startTimeParmeterList[2]);
            var week;
            this.weekList.forEach((element) => {
              console.log(element);
              if (element.PickListID == this.StartWeek) {
                console.log(element);
                week = element.DisplayValue;
              }
            });

            var day = this.dayRange[this.StartDay - 1];
            this.StartDate = day + " of " + week;
          } else if (
            this.DateMethodName == "CurrentDate" &&
            startTimeParmeterList.length >= 1
          ) {
            this.StartDate = "Today";
          } else if (
            this.DateMethodName == "Tomorrow" &&
            startTimeParmeterList.length >= 1
          ) {
            this.StartDate = "Tomorrow";
          }
        }
      }
    });

    console.log(this.passTemplate);
    this.isParkingDisabled = this.passTemplate.EntryType == 1 ? false : true; // enable ParkingDrodown if EntryType is Drive on

    // load Template category Dropdown

    this.templateCategoryService.getTemplateCategoryAPI().then(() => {
      this.templateCategories = this.templateCategoryService.getTemplateCategory();
      this.templateCategories = this.templateCategories.filter(
        (f) => f.OrganizationID == this.userService.organizationID
      );
    });

    this.filteredPointGrps = this.entrygrpControl.valueChanges.pipe(
      startWith(""),
      map((value) => (typeof value === "string" ? value : value.name)),
      map((name) =>
        name ? this._filterPointGrp(name) : this.entrygrpOptions.slice()
      )
    );

    // Load Entry Point Groups
    this.entryPointGroupService
      .getAllEntryPointGroupsAPI(this.userService.organizationID)
      .then(() => {
        this.entryPointGroups = this.entryPointGroupService.getEntryPointGroups();
      })
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );

    console.log(this.passTemplate);
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  ngAfterViewChecked() {}

  //entry point type ahead
  displayPoints(point?: Point): string | undefined {
    return point ? point.name : undefined;
  }

  private _filterPoint(name: string): Point[] {
    const filterValue = name.toLowerCase();

    return this.entryOptions.filter(
      (entryOption) => entryOption.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  private _filterPointGrp(name: string): PointGrps[] {
    const filterValue = name.toLowerCase();

    return this.entrygrpOptions.filter(
      (entryOption) => entryOption.name.toLowerCase().indexOf(filterValue) === 0
    );
  }
  //gets chosen destination
  getDestination(building) {
    // this.passTemplate.destination = building.name;
  }
  //gets chose entry point
  getPoint(point) {
    // this.passTemplate.entryPoint = point.name;
    // console.log(this.passTemplate.entryPoint);
  }
  //sends data back to the grids
  onClose(): void {
    this.dialogRef.close(this.passTemplate);
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  //email validation
  getErrorMessage() {
    if (this.email.hasError("required")) {
      return "You must enter a value";
    }

    return this.email.hasError("email") ? "Not a valid email" : "";
  }

  onEntryTypeChange(event: MatRadioChange) {
    if (event.value == 1) {
      // Drive On
      this.isParkingDisabled = false;
    } else {
      // console.log(event.value);
      this.isParkingDisabled = true;
    }
  }
  lockHostFields(event) {
    if (event) {
      this.passTemplate.HostFirstNameLock = true;
      this.passTemplate.HostMiddleNameLock = true;
      this.passTemplate.HostLastNameLock = true;
      this.passTemplate.HostNameSuffixLock = true;
      this.passTemplate.HostEmailLock = true;
      this.passTemplate.HostPhoneLock = true;
      this.passTemplate.HostCompanyLock = true;
    } else {
      this.passTemplate.HostFirstNameLock = false;
      this.passTemplate.HostMiddleNameLock = false;
      this.passTemplate.HostLastNameLock = false;
      this.passTemplate.HostNameSuffixLock = false;
      this.passTemplate.HostEmailLock = false;
      this.passTemplate.HostPhoneLock = false;
      this.passTemplate.HostCompanyLock = false;
    }
  }
  lockVisitDetailsFields(event) {
    if (event) {
      this.passTemplate.IncludeNonWorkingdaysLock = true;
      this.passTemplate.PhotoPassLock = true;
      this.passTemplate.EstArrivalTimeLock = true;
      this.passTemplate.EstDepartureTimeLock = true;
      this.passTemplate.VisitReasonLock = true;
      this.passTemplate.DestinationBldgLock = true;
      this.passTemplate.DestinationBlgFlrLock = true;
      this.passTemplate.DestinationRoomLock = true;
      this.passTemplate.EntryTypeLock = true;
      this.passTemplate.ExpectedEntryPointLock = true;
      this.passTemplate.EntryPointGroupIDLock = true;
      this.passTemplate.ParkingLotIDLock = true;
      this.passTemplate.StartTimeLock = true;
      this.passTemplate.EndTimeLock = true;
    } else {
      this.passTemplate.IncludeNonWorkingdaysLock = false;
      this.passTemplate.PhotoPassLock = false;
      this.passTemplate.EstArrivalTimeLock = false;
      this.passTemplate.EstDepartureTimeLock = false;
      this.passTemplate.VisitReasonLock = false;
      this.passTemplate.DestinationBldgLock = false;
      this.passTemplate.DestinationBlgFlrLock = false;
      this.passTemplate.DestinationRoomLock = false;
      this.passTemplate.EntryTypeLock = false;
      this.passTemplate.ExpectedEntryPointLock = false;
      this.passTemplate.EntryPointGroupIDLock = false;
      this.passTemplate.ParkingLotIDLock = false;
      this.passTemplate.StartTimeLock = false;
      this.passTemplate.EndTimeLock = false;
    }
  }
  lockVisitorFields(event) {
    if (event) {
      this.passTemplate.VisitorFirstNameLock = true;
      this.passTemplate.VisitorMiddleNameLock = true;
      this.passTemplate.VisitorLastNameLock = true;
      this.passTemplate.VisitorNameSuffixLock = true;
      this.passTemplate.VisitorEmailLock = true;
      this.passTemplate.VisitorPhoneLock = true;
      this.passTemplate.VisitorCompanyLock = true;
      this.passTemplate.NotesInternalLock = true;
      this.passTemplate.NotesPassLock = true;
    } else {
      this.passTemplate.VisitorFirstNameLock = false;
      this.passTemplate.VisitorMiddleNameLock = false;
      this.passTemplate.VisitorLastNameLock = false;
      this.passTemplate.VisitorNameSuffixLock = false;
      this.passTemplate.VisitorEmailLock = false;
      this.passTemplate.VisitorPhoneLock = false;
      this.passTemplate.VisitorCompanyLock = false;
      this.passTemplate.NotesInternalLock = false;
      this.passTemplate.NotesPassLock = false;
    }
  }

  initializeLocks() {
    this.passTemplate.HostFirstNameLock = this.passTemplate.HostFirstNameLock
      ? true
      : false;
    // this.passTemplate.HostMiddleNameLock = this.passTemplate.HostMiddleNameLock
    //   ? true
    //   : false;
    this.passTemplate.HostMiddleNameLock = false;
    this.passTemplate.HostLastNameLock = this.passTemplate.HostLastNameLock
      ? true
      : false;
    this.passTemplate.HostNameSuffixLock = this.passTemplate.HostNameSuffixLock
      ? true
      : false;
    this.passTemplate.HostEmailLock = this.passTemplate.HostEmailLock
      ? true
      : false;
    this.passTemplate.HostPhoneLock = this.passTemplate.HostPhoneLock
      ? true
      : false;
    this.passTemplate.IncludeNonWorkingdaysLock = this.passTemplate
      .IncludeNonWorkingdaysLock
      ? true
      : false;
    this.passTemplate.PhotoPassLock = this.passTemplate.PhotoPassLock
      ? true
      : false;
    this.passTemplate.EstArrivalTimeLock = this.passTemplate.EstArrivalTimeLock
      ? true
      : false;
    this.passTemplate.EstDepartureTimeLock = this.passTemplate
      .EstDepartureTimeLock
      ? true
      : false;
    this.passTemplate.VisitReasonLock = this.passTemplate.VisitReasonLock
      ? true
      : false;
    this.passTemplate.DestinationBldgLock = this.passTemplate
      .DestinationBldgLock
      ? true
      : false;
    this.passTemplate.DestinationBlgFlrLock = this.passTemplate
      .DestinationBlgFlrLock
      ? true
      : false;
    this.passTemplate.DestinationRoomLock = this.passTemplate
      .DestinationRoomLock
      ? true
      : false;
    this.passTemplate.EntryTypeLock = this.passTemplate.EntryTypeLock
      ? true
      : false;
    this.passTemplate.ExpectedEntryPointLock = this.passTemplate
      .ExpectedEntryPointLock
      ? true
      : false;
    this.passTemplate.EntryPointGroupIDLock = this.passTemplate
      .EntryPointGroupIDLock
      ? true
      : false;
    this.passTemplate.ParkingLotIDLock = this.passTemplate.ParkingLotIDLock
      ? true
      : false;
    this.passTemplate.VisitorFirstNameLock = this.passTemplate
      .VisitorFirstNameLock
      ? true
      : false;
    // this.passTemplate.VisitorMiddleNameLock = this.passTemplate
    //   .VisitorMiddleNameLock
    //   ? true
    //   : false;
    this.passTemplate.VisitorMiddleNameLock = false;
    this.passTemplate.VisitorLastNameLock = this.passTemplate
      .VisitorLastNameLock
      ? true
      : false;
    this.passTemplate.VisitorNameSuffixLock = this.passTemplate
      .VisitorNameSuffixLock
      ? true
      : false;
    this.passTemplate.VisitorEmailLock = this.passTemplate.VisitorEmailLock
      ? true
      : false;
    this.passTemplate.VisitorPhoneLock = this.passTemplate.VisitorPhoneLock
      ? true
      : false;
    this.passTemplate.VisitorCompanyLock = this.passTemplate.VisitorCompanyLock
      ? true
      : false;
    this.passTemplate.StartTimeLock = this.passTemplate.StartTimeLock
      ? true
      : false;
    this.passTemplate.EndTimeLock = this.passTemplate.EndTimeLock
      ? true
      : false;
    this.passTemplate.NotesPassLock = this.passTemplate.NotesPassLock
      ? true
      : false;
    this.passTemplate.NotesInternalLock = this.passTemplate.NotesInternalLock
      ? true
      : false;
  }

  formatDate(data: any) {
    let date = this._utilService.stripLocalTimeZone(data.toString());

    return data ? date : null;
  }
  onSaveChanges() {
    this.dialogService
      .openConfirmDialog(
        "Confirm Pass Template Update",
        "Are you sure you want to save the changes ?"
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          // confirmed
          this.passTemplate.UserID = this.userService.userID;
          this.passTemplate.OrganizationID = this.userService.organizationID;
          if (this.DateMethodName == "WeekWise") {
            this.passTemplate.StartTimeParameters =
              this.DateMethod + "," + this.StartWeek + "," + this.StartDay;
          } else if (this.DateMethodName == "CurrentDate") {
            this.passTemplate.StartTimeParameters = this.DateMethod + "";
          } else if (this.DateMethodName == "Tomorrow") {
            this.passTemplate.StartTimeParameters = this.DateMethod + "";
          }
          if (this.passTemplate.EstArrivalTime) {
            this.passTemplate.EstArrivalTime = this.formatDate(
              this.passTemplate.EstArrivalTime
            );

            this.passTemplate.EstArrivalTime = this.datePipe.transform(
              new Date(this.passTemplate.EstArrivalTime),
              "yyyy-MM-dd HH:mm"
            );
          }

          if (this.passTemplate.EstDepartureTime) {
            this.passTemplate.EstDepartureTime = this.formatDate(
              this.passTemplate.EstDepartureTime
            );

            this.passTemplate.EstDepartureTime = this.datePipe.transform(
              new Date(this.passTemplate.EstDepartureTime),
              "yyyy-MM-dd HH:mm"
            );
          }

          this.passTemplateService.updatePassTemplateAPI(this.passTemplate);
          this.onNoClick();
        }
      });
  }

  openStartDate() {
    // open widget configure dialog
    const dialogRef = this.dialog
      .open(StartdateConfigModalComponent, {
        disableClose: true,
        data: {
          DateMethod: this.DateMethod,
          StartWeek: this.StartWeek,
          StartDay: this.StartDay,
          DateMethodName: this.DateMethodName,
        },
        backdropClass: "backdropBackground",
      })
      .afterClosed()
      .subscribe((data) => {
        console.log(data);
        this.StartDate = "Not Specified";
        this.DateMethodName = data.DateMethodName;
        if (data.DateMethodName == "WeekWise") {
          this.DateMethod = data.DateMethod;
          if (data.StartWeek > 0) {
            this.StartWeek = data.StartWeek;
          }
          if (data.StartDay > 0) {
            this.StartDay = data.StartDay;

            var day = this.dayRange[data.StartDay - 1];
            this.StartDate = day + " of " + data.Week;
          } else {
            this.onDateTypeChange();
          }
        } else if (data.DateMethodName == "CurrentDate") {
          this.DateMethod = data.DateMethod;
          this.StartDate = "Today";
        } else if (data.DateMethodName == "Tomorrow") {
          this.DateMethod = data.DateMethod;
          this.StartDate = "Tomorrow";
        }
      });
  }

  onDateTypeChange() {
    this.StartDay = 0;
    this.StartWeek = 0;
  }


   OnArrivalTimeChange(event){
    
    this.passTemplate.EstDepartureTime ='';    
    if(event.newValue == "Invalid Date" ||  this.passTemplate.EstArrivalTime == "Invalid Date"){     
      this.passTemplate.EstArrivalTime = "";
    }else {
        if(this.siteService.dataTimeFormat.angularTimeFormat == '12 hours'){
        this.minTime = moment(this.passTemplate.EstArrivalTime).format(
          "hh:mm tt"
        );
        }else{
          this.minTime = moment(this.passTemplate.EstArrivalTime).format(
            "HH:mm"
          );
        } 
    }   
       

  }
  OnDepartureTimeChange(event){
    console.log(this.passTemplate.EstArrivalTime)
    console.log(this.passTemplate.EstDepartureTime) 
    
    if(event.newValue == "Invalid Date" ||  this.passTemplate.EstDepartureTime == "Invalid Date") {
         this.passTemplate.EstDepartureTime = ""; 
    } else if(this.passTemplate.EstArrivalTime > this.passTemplate.EstDepartureTime)
    this.passTemplate.EstDepartureTime =  ""; 
       
  
  }
}
