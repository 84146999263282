import { Component, OnInit } from "@angular/core";
import { RoleAssignmentApprovalAgGridConfig } from "./role-assignment-approval-ag-grid-config";
import { ViewUserInvitationApprovalModal } from "../modals/view-user-invitation-approval-modal/view-user-invitation-approval-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { SearchService } from "src/app/services/search.service";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { NotificationService } from "src/app/services/notification.service";
import { SpinnerService } from "src/app/services/spinner.service";
import { DatePipe } from "@angular/common";
import { SiteService } from "src/app/services/site.service";
import { GridApi } from "ag-grid-community";
import { UserInvitationService } from "src/app/services/user-invitation.service";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-role-assignment-approval",
  templateUrl: "./role-assignment-approval.component.html",
  styleUrls: ["./role-assignment-approval.component.css"],
})
export class RoleAssignmentApprovalComponent implements OnInit {
  protected selectedRequest: any;
  protected isRowSelected: boolean = false;
  private gridApi: GridApi;

  
  protected rowData = [];
  protected screenLabels : Array<string> = [] ;
  protected appLanguage;
  protected roleAssignmentApprovalColumnDefs = [];
  roleAssignmentApprovalAgGridConfig: any;

  // public roleAssignmentApprovalAgGridConfig: RoleAssignmentApprovalAgGridConfig = new RoleAssignmentApprovalAgGridConfig(
  //   this._datePipe,
  //   this._siteService,
  //   this.screenLabels
  // );
  //screenLabels: any;
  
  public rowClassRules;
  public defaultColDef : object;
  constructor(
    private _dialog: MatDialog,
    private _searchService: SearchService,
    private _spinnerService: SpinnerService,
    private _notificationService: NotificationService,
    private datePipe: DatePipe,
    private siteService: SiteService,
    protected messageService: MessageService,
    private userInvitationService: UserInvitationService
  ) {// set styles cell rows
    this.rowClassRules = {
      "row-inactive": (params) => {
        var condition = params.data.Active === 0;
        return condition;
      },
    };
    this.defaultColDef = {
      resizable: true,
  }
  this._spinnerService.setIsLoading(true);
  } 

  ngOnInit(): void {
    this._searchService.setPageName("User Access Approvals");

    //labels by message service
    if (localStorage.getItem('RoleAssignmentapprovalLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('RoleAssignmentapprovalLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "User Organization Request Approval",
        "RoleAssignmentApproval"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('RoleAssignmentapprovalLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    this._searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        this.rowData = this._searchService.searchRecords;
        this.rowData = this.rowData.filter(row => row && row.UserAccessRequestID !== null);
        this._spinnerService.setIsLoading(false);

        this.gridApi.paginationGoToPage(0);
      })
      .catch((err: HttpErrorResponse) => {
        this._notificationService.failure(err.message);
        this._spinnerService.setIsLoading(false);
      });

    // Listen to Search Btn click event and updated Ag Grid with Search results
    this._searchService.searchInitiated.subscribe((eventData: boolean) => {
      if (eventData) {
        this._searchService.smartSearchPromise
          .then(() => {
            this.rowData = this._searchService.searchRecords;
            this.rowData = this.rowData.filter(row => row && row.UserAccessRequestID !== null);
            this.gridApi.paginationGoToPage(0);
          })
          .catch((err: HttpErrorResponse) => {
            this._notificationService.failure(err.message);
          });
      }
    });

    // Listen to accessRequestUpdated event and updated Ag Grid with updated results
    this.userInvitationService.accessRequestUpdated.subscribe((event: boolean) => {
      this._searchService
        .smartSearchMultiSiteAPI(null)
        .then(() => {
          this.rowData = this._searchService.searchRecords;
          this.gridApi.paginationGoToPage(0);
        })
        .catch((err: HttpErrorResponse) => {
          this._notificationService.failure(err.message);
        });
    });
  }
  

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.roleAssignmentApprovalColumnDefs = new RoleAssignmentApprovalAgGridConfig(
      this.datePipe,
      this.siteService,
       this.screenLabels,
    ).roleAssignmentApprovalColumnDefs
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  // make ag-rid columns fill their container
  protected onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }
  
  protected openApprovalStatusModal($event) {
    this.selectedRequest = $event.data.UserAccessRequestID;
    this.userInvitationService.getUserAccessDetailsAPI( this.selectedRequest).subscribe((res: HttpResponse<any>) => {
      let accessDetails = res.body[0];
      accessDetails.UserAccessRequestID = $event.data.UserAccessRequestID;
      if (this.userInvitationService.approvalOpen) return;
      this._dialog.open(ViewUserInvitationApprovalModal, {
        disableClose: true,
        data: accessDetails,
      });
      this.userInvitationService.approvalOpen = true;
    }, error => {
      console.log(error);
    });
  } 

  // sets the data of selected row
  protected onSelectionChanged($event) {
    const selectedRows = $event.api.getSelectedRows();
    if (selectedRows.length) {
      this.selectedRequest = selectedRows[0];
      this.isRowSelected = true;
    } else {
      this.isRowSelected = false;
    }
  }
}
