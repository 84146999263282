import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ApprovalService } from 'src/app/services/approval.service';
import { SiteService } from 'src/app/services/site.service';
import { UserService } from 'src/app/services/user.service';
import { cloneDeep } from "lodash";
import { MessageService } from 'src/app/services/message.service';
import { LimitRequestService } from 'src/app/services/limit-request.service';

@Component({
  selector: 'app-view-access-request-modal',
  templateUrl: './view-access-request-modal.component.html',
  styleUrls: ['./view-access-request-modal.component.css']
})
export class ViewAccessRequestModalComponent implements OnInit {

  protected rowData: any;
  protected phoneIsUSA:boolean = false;
  protected step = 0;
  protected approvalStatusLoaded: boolean = false;
  protected requestedRoleNames: string[] = [];
  protected userInvitationData: any;
  protected modifiedDate;
  protected status: string;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  protected columnDefs = [];
  public defaultColDef : object;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<ViewAccessRequestModalComponent>,
    private _userService: UserService,
    private _approvalService: ApprovalService,
    private _datePipe: DatePipe,
    private _siteService: SiteService,
    protected messageService: MessageService,
    protected limitRequestService: LimitRequestService
  ) {
    this.defaultColDef = {
      resizable: true,
  }
   }

  ngOnInit(): void {
    if (localStorage.getItem("isPhoneUSA") === "true") {
      this.phoneIsUSA = true;
    }
    else if (localStorage.getItem("isPhoneUSA") === "false") {
      this.phoneIsUSA = false;
    }

    this.userInvitationData = cloneDeep(this.data);

    this.status = this.userInvitationData.Status;
    this.modifiedDate = this.userInvitationData.ModifiedDate.length !==0 ? this.userInvitationData.ModifiedDate[0].ModifiedDate : null;
    this._loadApprovalRequestStatus();

//labels by message service
if (localStorage.getItem('ViewAccessRequestLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('ViewAccessRequestLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "View Access Request Status Modal",
    "UserAccessApprovalRequest"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('ViewAccessRequestLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

  }

  setColumnDef() {
    this.columnDefs = [
      {
        headerName: this.screenLabels["Status"] ? this.screenLabels["Status"] : "Status",
        field: "Status",
        width: 100,
        wrapText: true,
        autoHeight: true,
      },
      {
        headerName: this.screenLabels["ApprovalLevel"] ? this.screenLabels["ApprovalLevel"] : "Level",
        field: "ApprovalLevel",
        width: 80,
        wrapText: true,
        autoHeight: true,
      },
      {
        headerName: this.screenLabels["ApprovalGroupName"] ? this.screenLabels["ApprovalGroupName"] : "Approval Group",
        field: "ApprovalGroupName",
        wrapText: true,
        autoHeight: true,
        cellStyle: { "white-space": "normal" },
      },
      {
        headerName: this.screenLabels["ApprovedDate"] ? this.screenLabels["ApprovedDate"] : "Reviewed On",
        field: "ApprovedDate",
        width: 100,
        wrapText: true,
        autoHeight: true,
        cellStyle: { "white-space": "normal" },
        cellRenderer: (data) => {
          const formattedDate = this._datePipe.transform(
            data.value,
            // "MM/dd/yyyy"
            this._siteService.dataTimeFormat.angularDateFormat
          );
          // console.log(formattedDate)
          return formattedDate ? formattedDate : null;
        },
      },
      {
        headerName: this.screenLabels["ApprovedBy"] ? this.screenLabels["ApprovedBy"] : "Reviewed By",
        field: "ApprovedBy",
        width: 150,
        wrapText: true,
        autoHeight: true,
      },
      {
        headerName: this.screenLabels["ApprovalComments"] ? this.screenLabels["ApprovalComments"] : "Reviewer's Comments",
        field: "ApprovalComments",
        // editable: this.checkIsEditable.bind(this)
        wrapText: true,
        autoHeight: true,
        cellStyle: { "white-space": "normal" },
      },
      {
        field: "ApprovalGroupRequestID",
        hide: true,
      },
      {
        field: "ApprovalRequestID",
        hide: true,
      },
      {
        field: "ApprovalGroupID",
        hide: true,
      },
      {
        field: "UserID",
        hide: true,
      },
    ];
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDef() 
  }

  protected setStep(index: number) {
    this.step = index;
  }

  protected onNoClick(): void {
    this._dialogRef.close();
    this.limitRequestService.acessRequestOpen = false;
  }

  protected prevStep() {
    this.step--;
  }

  protected nextStep() {
    this.step++;
  }

  private async _loadApprovalRequestStatus() {
    try {
      this.approvalStatusLoaded = false;

      this.rowData = await this._approvalService.getApprovalDetails(
        this.data.UserAccessApprovalRequestID,
        this.data.ApprovalType
      );

      this.approvalStatusLoaded = true;
    } catch (err) {
      console.error(err);
    }
  }

  public refreshApprovalDetails() {
    this._loadApprovalRequestStatus();
  }

}
