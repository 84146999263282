import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FormControl, Validators, NgForm } from "@angular/forms";
import { Observable } from "rxjs";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { EntryPoint } from "src/app/models/entry-point.model";
import { EntryPointGroup } from "src/app/models/entry-point-group.model";
import { Building } from "src/app/models/building.model";
import { ViewSelectorService } from "src/app/services/view-selector.service";
import { PassService } from "src/app/services/pass.service";
import { BuildingService } from "src/app/services/building.service";
import { UserService } from "src/app/services/user.service";
import { NotificationService } from "src/app/services/notification.service";
import { DatePipe } from "@angular/common";
import { EntryPointService } from "src/app/services/entry-point.service";
import { DialogService } from "src/app/services/dialog.service";
import { EntryPointGroupService } from "src/app/services/entry-point-group.service";
import { OrganizationService } from "src/app/services/organization.service";
import { ParkingLotService } from "src/app/services/parking-lot.service";
import { SearchService } from "src/app/services/search.service";
import { RequiredFieldsService } from "src/app/services/required-fields.service";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import {
  MatRadioChange,
  MatSelectChange,
  DateAdapter,
  MAT_DATE_FORMATS,
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from "@angular/material";
import { TemplateCategory } from "src/app/models/template-category,model";
import { PassTemplate } from "src/app/models/pass-template.model";
import { TemplateCategoryService } from "src/app/services/template-category-service";
import { PassTemplateService } from "src/app/services/pass-template-service";
import { StartdateConfigModalComponent } from "../startdate-config/startdate-config.modal.component";
import { SiteService } from "src/app/services/site.service";
import { UtilityService } from "src/app/services/utility.service";
import {
  MatDatePickerAdapter,
  CUSTOM_DATE_FORMATS,
} from "src/app/common/mat-date-picker-formatter/mat-date-picker-formatter";
import moment from "moment";
import { MessageService } from "src/app/services/message.service";
import { map, startWith } from "rxjs/operators";

export interface DialogData {}

export interface Reason {
  value: string;
  viewValue: string;
}
export interface Point {
  name: string;
}
export interface PointGrps {
  name: string;
}
// export interface Building {
//   name: string;
// }

@Component({
  selector: "app-create-template-modal",
  templateUrl: "./create-template-modal.component.html",
  styleUrls: ["./create-template-modal.component.css"],
  providers: [
    { provide: DateAdapter, useClass: MatDatePickerAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export class CreateTemplateComponent implements OnInit {
  @ViewChild("templateForm") templateForm: NgForm;
  @ViewChild("expirationDate") expirationDate: ElementRef;

  protected DateMethod: number = 0;
  protected DateMethodName: string = "";
  protected StartWeek: number = 0;
  protected StartDay: number = 0;
  protected StartDuration: number = 0;
  protected dayRange: Array<string> = [];
  private apiBody: any = {};
  protected entryPoints: Array<EntryPoint> = [];
  public entryPointGroups: Array<EntryPointGroup> = [];
  protected templateCategories: Array<TemplateCategory> = [];
  protected reasonOfVisit: Array<{
    PickListID: number;
    TableName: string;
    FieldName: string;
    DisplayValue: string;
    Active: boolean;
  }> = [];
  protected weekList: Array<{
    PickListID: number;
    TableName: string;
    FieldName: string;
    DisplayValue: string;
    Active: boolean;
  }> = [];

  protected dateMethod: Array<{
    PickListID: number;
    TableName: string;
    FieldName: string;
    DisplayValue: string;
    Active: boolean;
  }> = [];
  protected isReasonOfVisitLoading = true;
  protected isBuildingLoading = true;
  protected isEntryPointLoading = true;
  protected isParkingDisabled = true;
  protected isExpirationDateDisabled = true;
  protected passTemplate: PassTemplate = new PassTemplate();
  protected assignedParking: any[] = [];
  // protected assignedParking: Array<{
  //   ParkingLotName: string;
  //   ParkingLotID: number;
  // }> = [];
  public parkingLotName: string;
  public ParkingSpot: string;
  protected buildings: Array<Building>;
  protected StartDate: string = "Not Specified";
  protected minTime: string = '';
  protected entryPointGroupID: number;
  protected EntryPointGrpName: string = "";
  public entryPointNames: string[];
  filteredSuggPoint: Observable<string[]>;
  suggestedEntryPointControl = new FormControl();
  protected enableNext: boolean = false;

  protected floors: any[] =[];
  protected enableFloorLock: boolean = true;
  protected enableParkingLock: boolean = true;
  protected phoneIsUSA:boolean = false;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  protected countryList: any;
  templateCategoryControl = new FormControl();
  filteredTemplateCategory: Observable<any[]>;
  visitReasonControl = new FormControl();
  filteredVisitReasons: Observable<any[]>;
  filteredEntryPointGroup: Observable<any[]>;
  filteredExpectedEntryPointGroup: Observable<any[]>;
  expectedEntryPointFormControl = new FormControl();
  filteredBuilding: Observable<any[]>;
  buildingControl = new FormControl();
  private chosenBuildingId;
  protected Getfloors: any[] = [];
  filteredFloors: Observable<any[]>;
  floorControl = new FormControl();
  parkingLotFormControl = new FormControl();
  filteredParking: Observable<string[]>;

  constructor(
    public dialogRef: MatDialogRef<CreateTemplateComponent>,
    private _snackBar: MatSnackBar,
    service: ViewSelectorService,
    protected passService: PassService,
    protected buildingService: BuildingService,
    protected userService: UserService,
    private notificationService: NotificationService,
    protected entryPointService: EntryPointService,
    protected dialogService: DialogService,
    private entryPointGroupService: EntryPointGroupService,
    private organizationService: OrganizationService,
    private parkingLotService: ParkingLotService,
    protected searchService: SearchService,
    protected requiredFieldsService: RequiredFieldsService,
    protected templateCategoryService: TemplateCategoryService,
    protected passTemplateService: PassTemplateService,
    private dialog: MatDialog,
    protected datePipe: DatePipe,
    protected siteService: SiteService,
    private _utilService: UtilityService,
    protected messageService: MessageService
  ) {
    document.addEventListener(
      "keydown",
      (e) => {
        if ((e.target as any).nodeName === "MAT-EXPANSION-PANEL-HEADER") {
          e.stopImmediatePropagation();
        }
        if ((e.target as any).nodeName === "MAT-DIALOG-CONTAINER") {
          if (this.step != 2) {
            this.nextStep();
          } else {
            return;
          }
        }
      },
      true
    );
    this.initializeLocks();
    this.isParkingDisabled = this.passTemplate.EntryType == 1 ? false : true; // enable ParkingDrodown if EntryType is Drive on

    //Api call to get ParkingLotDropdown

    this.organizationService
      .getOrganizationByIDAPI(this.userService.organizationID)
      .then(() => {
        const details = this.organizationService.getOrganizationByIDResponse()[0];
        details.OrganizationParkingLimitArray.map((p) => {
          if (p.ParkingLotArray) {
            p.ParkingLotArray.map((array) => {
              if (array) {
                this.assignedParking.push(array);
                this.filteredParking = this.parkingLotFormControl.valueChanges.pipe(
                  startWith(""),
                  map(value => typeof value === 'string' ? value : value?.ParkingLotName),
                  map(ParkingLotName => ParkingLotName ? this._filteredParking(ParkingLotName) : this.assignedParking.slice())
                );
              }
            });
          }
        });
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }
  durationInSeconds = 3;
  step = 0;
  disabled = false;
  private fieldArray: Array<any> = [];
  private newAttribute: any = {};
  
  //template category typeahead
  private _filteredParking(value: string) {
    return this.assignedParking.filter(option => option.ParkingLotName.toLowerCase().includes(value.toLowerCase()));
  }

  displayFn7(selectedoption: number) {
    if(selectedoption) {
    return this.assignedParking.find(x => x.ParkingLotID === selectedoption).ParkingLotName;
    }
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  prevStepTwo() {
    if(!this.passTemplate.TemplateCategoryID || !this.passTemplate.TemplateName){
      //do nothing
    } else {
      this.step--;
    }
  }

  nextSteptwo() {
    this.email.markAsTouched();
    if (!this.email.invalid) {
      this.step++;
    }
  }

  isNameEmpty(): boolean {
    return !this.passTemplate.TemplateName || !this.passTemplate.TemplateName.trim();
  }

  isValidEmail(email: string): boolean {
    const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/
    return emailPattern.test(email);
  }

  nameFormControl = new FormControl("", [
    Validators.required,  // Required validation
    Validators.pattern(/^[a-zA-Z\s0-9\-!@#$%^&*()_+={}[\]:;<>,.?~'"|/\\`]*$/),
    this.specialCharacterValidator(),
  ]);

  // Custom validator function
  specialCharacterValidator() {
    return (control: FormControl) => {
      const value = control.value;
      if (!value) {
        return null; // No validation for empty values
      }

      // Check for spaces or hyphens followed by spaces
      if (/^\s/.test(value)) {
        return { specialCharactersNotAllowed: true };
      }
  
      const isValid = /^[a-zA-Z\s0-9\-!@#$%^&*()_+={}[\]:;<>,.?~'"|/\\`]*$/.test(value);
  
      if (isValid && !/^-/.test(value)) {
      return null;
    };
  return { specialCharactersNotAllowed: true };
    }
  }

  email = new FormControl("", [Validators.required, Validators.email]);
  entryPointGroupFormControl = new FormControl("", Validators.required);

  //Building autocomplete
  myControl = new FormControl();

  filteredOptions: Observable<Building[]>;
  //Entry type autocomplete
  entryControl = new FormControl();

  filteredPoints: Observable<Point[]>;

  entrygrpControl = new FormControl();
  filteredPointGrps: Observable<PointGrps[]>;

  ngOnInit() {
    this.passTemplate = new PassTemplate();
    this.passTemplate.EndTimeDuration = null;
    if (localStorage.getItem("isPhoneUSA") === "true") {
      this.phoneIsUSA = true;
    }
    else if (localStorage.getItem("isPhoneUSA") === "false") {
      this.phoneIsUSA = false;
    }
    //Initialize locks
    this.initializeLocks();
     //labels by message service
     if (localStorage.getItem('CreateTemplateLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('CreateTemplateLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Create Pass Template Modal",
        "PassTemplate"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('CreateTemplateLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    this.passService.getVisitReasonsAPI().then(() => {
      this.isReasonOfVisitLoading = false;
      this.reasonOfVisit = this.passService.reasonOfVisit;
      this.filteredVisitReasons = this.visitReasonControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value?.DisplayValue),
        map(DisplayValue => DisplayValue ? this._filterVisitReason(DisplayValue) : this.reasonOfVisit.slice())
      );
    });

    if (this.siteService._siteHasCountry) {
      this.passService.getCountryListAPI().subscribe((res: HttpResponse<any>) => {
        this.countryList = res.body;
      }, error => {
        console.log(error);
      });
    }
    
    this.dayRange = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    this.passTemplateService.getPassTemplatePickListTablesAPI().then(() => {
      this.dateMethod = this.passTemplateService.passTemplateTables.filter(
        (f) => f.FieldName == "MethodTypeParam"
      );
      this.weekList = this.passTemplateService.passTemplateTables.filter(
        (f) => f.FieldName == "WeekOptionParam"
      );
    });
    this.buildingService.parseAPI().then(() => {
      this.isBuildingLoading = false;
      this.buildings = [...this.buildingService.getBuildings()];
      this.filteredBuilding = this.buildingControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value?.BuildingName),
        map(BuildingName => BuildingName ? this._filterBuilding(BuildingName) : this.buildings.slice())
      );

      // console.log(this.entryPointService.getEntryPoints());
    });

    // this.entryPointService.parseGetEndpointAPI().then(() => {
    //   this.isEntryPointLoading = false;
    //   this.entryPoints = this.entryPointService.getEntryPoints();
    // });

    // load Template category Dropdown

    this.templateCategoryService.getTemplateCategoryAPI().then(() => {
      this.templateCategories = this.templateCategoryService.getTemplateCategory();
      this.templateCategories = this.templateCategories.filter(
        (f) => f.OrganizationID == this.userService.organizationID
      );
      this.filteredTemplateCategory = this.templateCategoryControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value?.Category),
        map(Category => Category ? this._filterTemplateCategory(Category) : this.templateCategories.slice())
      );
    });
    // Load Entry Point Groups
    this.entryPointGroupService
      .getAllEntryPointGroupsAPI(this.userService.organizationID)
      .then(() => {
        this.entryPointGroups = this.entryPointGroupService.getEntryPointGroups();
        this.filteredEntryPointGroup = this.entryPointGroupFormControl.valueChanges.pipe(
          startWith(""),
          map(value => typeof value === 'string' ? value : value?.EntryPointGrpName),
          map(EntryPointGrpName => EntryPointGrpName ? this._filteredEntryPointGroup(EntryPointGrpName) : this.entryPointGroups.slice())
        );
      })
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  ngAfterViewChecked() {
    this.requiredFieldsService.setRequiredFields(
      "Create Pass Template",
      this.templateForm
    );
  }

  //floor typeahead
  private _filterFloor(value: string) {
    if (typeof value !== 'string') {
      return []; // or handle the error as needed
    }
    return this.Getfloors.filter(option => option.Floor.toLowerCase().includes(value.toLowerCase()));
  }
  displayFn5(selectedoption: number) {
    if(selectedoption) {
    return this.Getfloors.find(x => x.BuildingFloorID === selectedoption).Floor;
    }
  }

   //template category typeahead
   private _filterTemplateCategory(value: string) {
    return this.templateCategories.filter(option => option.Category.toLowerCase().includes(value.toLowerCase()));
  }

  private _filterBuilding(value: string) {
    return this.buildings.filter(option => option.BuildingName.toLowerCase().includes(value.toLowerCase()));
  }
  //gets chose entry point
  getPoint(point) {
    // this.passTemplate.entryPoint = point.name;
  }

   //visit reason typeahead
   private _filterVisitReason(value: string) {
    return this.reasonOfVisit.filter(option => option.DisplayValue.toLowerCase().includes(value.toLowerCase()));
  }
  
  private _filteredEntryPointGroup(value: string) {
    return this.entryPointGroups.filter(option => option.EntryPointGrpName.toLowerCase().includes(value.toLowerCase()));
  }
  private _filteredExpectedEntryPointGroup(value: string) {
    return this.entryPoints.filter(option => option.Description.toLowerCase().includes(value.toLowerCase()));
  }

  displayFn(selectedoption) {
    if(selectedoption) {
    return this.templateCategories.find(x => x.TemplateCategoryID === selectedoption).Category
    }
    //return selectedoption ? selectedoption.Category : undefined;
  }

  displayFn2(selectedoption) {
    if(selectedoption) {
    return this.buildings.find(x => x.BuildingID === selectedoption).BuildingName;
    }
  }

  displayFn3(selectedoption: number) {
    if(selectedoption) {
    return this.reasonOfVisit.find(x => x.PickListID === selectedoption).DisplayValue;
    }
  }

  displayFn4(selectedoption: number) {
    if(selectedoption) {
    return this.entryPointGroups.find(x => x.EntryPointGroupID === selectedoption).EntryPointGrpName;
    }
  }
  displayFn6(selectedoption: number) {
    if(selectedoption) {
    return this.entryPoints.find(x => x.EntryPointID === selectedoption).Description;
    }
  }

  //sends data back to the grids
  onClose(): void {
    this.dialogRef.close(this.passTemplate);
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  //email validation
  getErrorMessage() {
    if (this.email.hasError("required")) { 
      const enterAValue = this.screenLabels["EnterAValueValidationMsg"] ? this.screenLabels["EnterAValueValidationMsg"]: "You must enter a value";
      return enterAValue;
    }
    const notValidEmail = this.screenLabels["NotAValidEmailErrorMsg"] ? this.screenLabels["NotAValidEmailErrorMsg"]: "Not a valid email";
    return this.email.hasError("email") ? notValidEmail : "";
  }

  onEntryTypeChange(event: MatRadioChange) {
    if (event.value == 1) {
      // Drive On
      if (!this.passTemplate.ParkingLotID) {
        this.enableNext = true;
      } else {
        this.enableNext = false;
      }
      this.isParkingDisabled = false;
    } else {
      this.enableNext = false;
      this.isParkingDisabled = true;
      this.passTemplate.ParkingLotID = 0;
    }
    this.enableLocks();
  }

  enableLocks(){
    this.checkFloors();
    this.checkParking();
  }
  checkFloors() {
    if(this.floors.length >= 1){
      this.enableFloorLock = this.passTemplate.DestinationBlgFlr ? true : false;
    }
  }
  checkParking() {
    if(this.passTemplate.EntryType == 1){
      this.enableParkingLock = this.passTemplate.ParkingLotID > 0 ? true : false;
    }
  }

  disableLockAllFields(event:string): boolean {
    if(event === "host") {
      //if(this.passTemplate.HostFirstNameLock && this.passTemplate.HostMiddleNameLock && this.passTemplate.HostLastNameLock && this.passTemplate.HostNameSuffixLock && this.passTemplate.HostEmailLock && this.passTemplate.HostPhoneLock && this.passTemplate.HostCompanyLock)
      if(this.passTemplate.HostFirstNameLock && this.passTemplate.HostLastNameLock && this.passTemplate.HostNameSuffixLock && this.passTemplate.HostEmailLock && this.passTemplate.HostPhoneLock && this.passTemplate.HostCompanyLock)
      return true;
      else return false;
    }
    else if(event === "visitorDetails") {
      if(this.passTemplate.EntryType === 1) {
        if(this.passTemplate.IncludeNonWorkingdaysLock && this.passTemplate.PhotoPassLock && this.passTemplate.EstArrivalTimeLock && this.passTemplate.EstDepartureTimeLock && this.passTemplate.VisitReasonLock && this.passTemplate.DestinationBldgLock && this.passTemplate.DestinationBlgFlrLock && this.passTemplate.DestinationRoomLock && this.passTemplate.EntryTypeLock && this.passTemplate.ExpectedEntryPointLock && this.passTemplate.EntryPointGroupIDLock && this.passTemplate.ParkingLotIDLock && this.passTemplate.ParkingSpotLock && this.passTemplate.StartTimeLock && this.passTemplate.EndTimeLock)
        return true;
        else return false;
      }
      else {
        if(this.passTemplate.IncludeNonWorkingdaysLock && this.passTemplate.PhotoPassLock && this.passTemplate.EstArrivalTimeLock && this.passTemplate.EstDepartureTimeLock && this.passTemplate.VisitReasonLock && this.passTemplate.DestinationBldgLock && this.passTemplate.DestinationBlgFlrLock && this.passTemplate.DestinationRoomLock && this.passTemplate.EntryTypeLock && this.passTemplate.ExpectedEntryPointLock && this.passTemplate.EntryPointGroupIDLock && this.passTemplate.StartTimeLock && this.passTemplate.EndTimeLock)
        return true;
        else return false;
      }
    }
    else if(event === "visitorInfo") {
     // if(this.passTemplate.VisitorFirstNameLock && this.passTemplate.VisitorMiddleNameLock && this.passTemplate.VisitorLastNameLock && this.passTemplate.VisitorNameSuffixLock && this.passTemplate.VisitorEmailLock && this.passTemplate.VisitorPhoneLock && this.passTemplate.VisitorCompanyLock && this.passTemplate.VisitorCountryLock && this.passTemplate.NotesInternalLock && this.passTemplate.NotesPassLock )
      if(this.passTemplate.VisitorFirstNameLock && this.passTemplate.VisitorLastNameLock && this.passTemplate.VisitorNameSuffixLock && this.passTemplate.VisitorEmailLock && this.passTemplate.VisitorPhoneLock && this.passTemplate.VisitorCompanyLock && this.passTemplate.VisitorCountryLock && this.passTemplate.NotesInternalLock && this.passTemplate.NotesPassLock )
      return true;
      else return false;
    }
  }

  disableUnLockAllFields(event:string): boolean {
    if(event === "host") {
      // if(this.passTemplate.HostFirstNameLock || this.passTemplate.HostMiddleNameLock || this.passTemplate.HostLastNameLock || this.passTemplate.HostNameSuffixLock || this.passTemplate.HostEmailLock || this.passTemplate.HostPhoneLock || this.passTemplate.HostCompanyLock)
      if(this.passTemplate.HostFirstNameLock || this.passTemplate.HostLastNameLock || this.passTemplate.HostNameSuffixLock || this.passTemplate.HostEmailLock || this.passTemplate.HostPhoneLock || this.passTemplate.HostCompanyLock)
      return false;
      else return true;
    }
    else if(event === "visitorDetails") {
      if(this.passTemplate.IncludeNonWorkingdaysLock || this.passTemplate.PhotoPassLock || this.passTemplate.EstArrivalTimeLock || this.passTemplate.EstDepartureTimeLock || this.passTemplate.VisitReasonLock || this.passTemplate.DestinationBldgLock || this.passTemplate.DestinationBlgFlrLock || this.passTemplate.DestinationRoomLock || this.passTemplate.EntryTypeLock || this.passTemplate.ExpectedEntryPointLock || this.passTemplate.EntryPointGroupIDLock || this.passTemplate.ParkingLotIDLock || this.passTemplate.ParkingSpotLock || this.passTemplate.StartTimeLock || this.passTemplate.EndTimeLock )
      return false;
      else return true;
    }
    else if(event === "visitorInfo") {
      // if(this.passTemplate.VisitorFirstNameLock || this.passTemplate.VisitorMiddleNameLock || this.passTemplate.VisitorLastNameLock || this.passTemplate.VisitorNameSuffixLock || this.passTemplate.VisitorEmailLock || this.passTemplate.VisitorPhoneLock || this.passTemplate.VisitorCompanyLock || this.passTemplate.VisitorCountryLock || this.passTemplate.NotesInternalLock || this.passTemplate.NotesPassLock )
      if(this.passTemplate.VisitorFirstNameLock || this.passTemplate.VisitorLastNameLock || this.passTemplate.VisitorNameSuffixLock || this.passTemplate.VisitorEmailLock || this.passTemplate.VisitorPhoneLock || this.passTemplate.VisitorCompanyLock || this.passTemplate.VisitorCountryLock || this.passTemplate.NotesInternalLock || this.passTemplate.NotesPassLock )
      return false;
      else return true;
    }
  }

  lockHostFields(event) {
    if (event) {
      this.passTemplate.HostFirstNameLock = true;
      this.passTemplate.HostMiddleNameLock = true;
      this.passTemplate.HostLastNameLock = true;
      this.passTemplate.HostNameSuffixLock = true;
      this.passTemplate.HostEmailLock = true;
      this.passTemplate.HostPhoneLock = true;
      this.passTemplate.HostCompanyLock = true;
    } else {
      this.passTemplate.HostFirstNameLock = false;
      this.passTemplate.HostMiddleNameLock = false;
      this.passTemplate.HostLastNameLock = false;
      this.passTemplate.HostNameSuffixLock = false;
      this.passTemplate.HostEmailLock = false;
      this.passTemplate.HostPhoneLock = false;
      this.passTemplate.HostCompanyLock = false;
    }
  }
  lockVisitDetailsFields(event) {
    if (event) {
      this.passTemplate.IncludeNonWorkingdaysLock = true;
      this.passTemplate.PhotoPassLock = true;
      this.passTemplate.EstArrivalTimeLock = true;
      this.passTemplate.EstDepartureTimeLock = true;
      this.passTemplate.VisitReasonLock = true;
      this.passTemplate.DestinationBldgLock = true;
      this.passTemplate.DestinationBlgFlrLock = true;
      this.passTemplate.DestinationRoomLock = true;
      this.passTemplate.EntryTypeLock = true;
      this.passTemplate.ExpectedEntryPointLock = true;
      this.passTemplate.EntryPointGroupIDLock = true;
      this.passTemplate.ParkingLotIDLock = true;
      this.passTemplate.ParkingSpotLock = true;
      this.passTemplate.StartTimeLock = true;
      this.passTemplate.EndTimeLock = true;
    } else {
      this.passTemplate.IncludeNonWorkingdaysLock = false;
      this.passTemplate.PhotoPassLock = false;
      this.passTemplate.EstArrivalTimeLock = false;
      this.passTemplate.EstDepartureTimeLock = false;
      this.passTemplate.VisitReasonLock = false;
      this.passTemplate.DestinationBldgLock = false;
      this.passTemplate.DestinationBlgFlrLock = false;
      this.passTemplate.DestinationRoomLock = false;
      this.passTemplate.EntryTypeLock = false;
      this.passTemplate.ExpectedEntryPointLock = false;
      this.passTemplate.EntryPointGroupIDLock = false;
      this.passTemplate.ParkingLotIDLock = false;
      this.passTemplate.ParkingSpotLock = false;
      this.passTemplate.StartTimeLock = false;
      this.passTemplate.EndTimeLock = false;
    }
  }
  lockVisitorFields(event) {
    if (event) {
      this.passTemplate.VisitorFirstNameLock = true;
      this.passTemplate.VisitorMiddleNameLock = true;
      this.passTemplate.VisitorLastNameLock = true;
      this.passTemplate.VisitorNameSuffixLock = true;
      this.passTemplate.VisitorEmailLock = true;
      this.passTemplate.VisitorPhoneLock = true;
      this.passTemplate.VisitorCompanyLock = true;
      this.passTemplate.VisitorCountryLock = true;
      this.passTemplate.NotesInternalLock = true;
      this.passTemplate.NotesPassLock = true;
    } else {
      this.passTemplate.VisitorFirstNameLock = false;
      this.passTemplate.VisitorMiddleNameLock = false;
      this.passTemplate.VisitorLastNameLock = false;
      this.passTemplate.VisitorNameSuffixLock = false;
      this.passTemplate.VisitorEmailLock = false;
      this.passTemplate.VisitorPhoneLock = false;
      this.passTemplate.VisitorCompanyLock = false;
      this.passTemplate.VisitorCountryLock = false;
      this.passTemplate.NotesInternalLock = false;
      this.passTemplate.NotesPassLock = false;
    }
  }

  initializeLocks() {
    this.passTemplate.HostFirstNameLock = false;
    this.passTemplate.HostMiddleNameLock = false;
    this.passTemplate.HostLastNameLock = false;
    this.passTemplate.HostNameSuffixLock = false;
    this.passTemplate.HostEmailLock = false;
    this.passTemplate.HostPhoneLock = false;
    this.passTemplate.HostCompanyLock = false;
    this.passTemplate.IncludeNonWorkingdaysLock = false;
    this.passTemplate.PhotoPassLock = false;
    this.passTemplate.StartTimeLock = false;
    this.passTemplate.EndTimeLock = false;
    this.passTemplate.EstArrivalTimeLock = false;
    this.passTemplate.EstDepartureTimeLock = false;
    this.passTemplate.VisitReasonLock = false;
    this.passTemplate.DestinationBldgLock = false;
    this.passTemplate.DestinationBlgFlrLock = false;
    this.passTemplate.DestinationRoomLock = false;
    this.passTemplate.EntryTypeLock = false;
    this.passTemplate.ExpectedEntryPointLock = false;
    this.passTemplate.EntryPointGroupIDLock = false;
    this.passTemplate.ParkingLotIDLock = false;
    this.passTemplate.ParkingSpotLock = false;
    this.passTemplate.VisitorFirstNameLock = false;
    this.passTemplate.VisitorMiddleNameLock = false;
    this.passTemplate.VisitorLastNameLock = false;
    this.passTemplate.VisitorNameSuffixLock = false;
    this.passTemplate.VisitorEmailLock = false;
    this.passTemplate.VisitorPhoneLock = false;
    this.passTemplate.VisitorCompanyLock = false;
    this.passTemplate.VisitorCountryLock = false;
    this.passTemplate.NotesPassLock = false;
    this.passTemplate.NotesInternalLock = false;
    this.passTemplate.EntryType = 2;
    this.passTemplate.ParkingLotID = null;
    this.passTemplate.EntryPointGroupID = null;
    this.passTemplate.Active = 1;
    this.passTemplate.UserID = this.userService.userID;
    this.passTemplate.OrganizationID = this.userService.organizationID;
  }

  formatDate(data: any) {
    if(data){
      let date = this._utilService.stripLocalTimeZone(data.toString());
      return data ? date : null;
    }else{
      return null;
    }
  }

  onBuilding(event: MatAutocompleteSelectedEvent){
    if (this.passTemplate.DestinationBldg) {
      // this is an asynchronous call, subscribe to getFloors
      this.buildingService.getFloors(event.option.value).subscribe((res) => {
        this.Getfloors = res.body;
        this.filteredFloors = this.floorControl.valueChanges.pipe(
          startWith(""),
          map(value => typeof value === 'number' ? value : value.Floor),
          map(Floor => Floor ? this._filterFloor(Floor) : this.Getfloors.slice())
        );
      });
    }

    this.floors = this.buildingService.getBuildingFloors(this.passTemplate.DestinationBldg ? +this.passTemplate.DestinationBldg : null);
    if(this.floors.length >= 1){
      this.enableNext = true;
    }
    else if(this.floors.length == 0){
      this.enableNext = false;
      this.passTemplate.DestinationBlgFlr = "";
    }
    this.enableLocks();
  }

  onFloor(event){
    if(this.floors.length >= 1 && this.passTemplate.DestinationBlgFlr){
      this.enableNext = false;
    }
    else if(this.floors.length >= 1 && !this.passTemplate.DestinationBlgFlr){
      this.enableNext = true;
    }
    this.enableLocks();
  }

  onParkingLotChange(){
    this.enableLocks();
  }

  onSaveChanges() {
    this.passTemplate.HostMiddleName = "";
    this.passTemplate.VisitorMiddleName = "";
    this.passTemplate.HostMiddleNameLock = false;
    this.passTemplate.VisitorMiddleNameLock = false;
    const name = this.passTemplate.TemplateName.trim() === this.passTemplate.TemplateName ? this.passTemplate.TemplateName : this.passTemplate.TemplateName.trim();
        this.passTemplate.TemplateName = name ;
    if(this.DateMethodName == ""){
      this.passTemplate.StartTimeParameters = this.DateMethod + "";
    }
    else if (this.DateMethodName == "WeekWise") {
      this.passTemplate.StartTimeParameters =
        this.DateMethod + "," + this.StartWeek + "," + this.StartDay;
    } else if (this.DateMethodName == "CurrentDate") {
      this.passTemplate.StartTimeParameters = this.DateMethod + "";
    } else if (this.DateMethodName == "Tomorrow") {
      this.passTemplate.StartTimeParameters = this.DateMethod + "";
    }
    if(this.passTemplate.EstArrivalTime !== null){
    this.passTemplate.EstArrivalTime = this.formatDate(
      this.passTemplate.EstArrivalTime
    );
    }
    if(this.passTemplate.EstDepartureTime !== null){
    this.passTemplate.EstDepartureTime = this.formatDate(
      this.passTemplate.EstDepartureTime
    );
    }
    this.datePipe.transform(
      new Date(this.passTemplate.EstArrivalTime),
      "yyyy-MM-dd HH:mm"
    );

    this.datePipe.transform(
      new Date(this.passTemplate.EstDepartureTime),
      "yyyy-MM-dd HH:mm"
    );
    this.passTemplateService.createPassTemplateAPI(this.passTemplate);
    this.onNoClick();
  }

  onDateTypeChange() {
    this.StartDay = 0;
    this.StartDuration = 0;
    this.StartWeek = 0;
  }

  openStartDate() {
    // open widget configure dialog
    const dialogRef = this.dialog
      .open(StartdateConfigModalComponent, {
        disableClose: true,
        data: {
          DateMethod: this.DateMethod,
          StartWeek: this.StartWeek,
          StartDay: this.StartDay,
          DateMethodName: this.DateMethodName,
        },
        backdropClass: "backdropBackground",
      })
      .afterClosed()
      .subscribe((data) => {
        this.StartDate = "Not Specified";
        this.DateMethodName = data.DateMethodName;
        if(data.DateMethodName == ""){
          this.DateMethod = data.DateMethod;
          this.StartDate = "Not Specified";
          this.passTemplate.EndTimeDuration = null;
        }
        else if (data.DateMethodName == "WeekWise") {
          this.DateMethod = data.DateMethod;
          if (data.StartWeek > 0) {
            this.StartWeek = data.StartWeek;
          }
          if (data.StartDay > 0) {
            this.StartDay = data.StartDay;

            var day = this.dayRange[data.StartDay - 1];
            this.StartDate = day + " of " + data.Week;
          } else {
            this.onDateTypeChange();
          }
        } else if (data.DateMethodName == "CurrentDate") {
          this.DateMethod = data.DateMethod;
          this.StartDate = "Today";
        } else if (data.DateMethodName == "Tomorrow") {
          this.DateMethod = data.DateMethod;
          this.StartDate = "Tomorrow";
        }
      });
  }

  

  OnArrivalTimeChange(event){
    
    this.passTemplate.EstDepartureTime ='';    
    if(event.newValue == "Invalid Date" ||  this.passTemplate.EstArrivalTime == "Invalid Date"){     
      this.passTemplate.EstArrivalTime = "";
    }else {
        if(this.siteService.dataTimeFormat.angularTimeFormat == '12 hours'){
        this.minTime = moment(this.passTemplate.EstArrivalTime).format(
          "hh:mm tt"
        );
        }else{
          this.minTime = moment(this.passTemplate.EstArrivalTime).format(
            "HH:mm"
          );
        } 
    }   
       

  }
  OnDepartureTimeChange(event){
    
    if(event.newValue == "Invalid Date" ||  this.passTemplate.EstDepartureTime == "Invalid Date") {
         this.passTemplate.EstDepartureTime = ""; 
    } else if(this.passTemplate.EstArrivalTime > this.passTemplate.EstDepartureTime)
    this.passTemplate.EstDepartureTime =  ""; 
       
  
  }

  onEntryPointGroupChange(event: MatAutocompleteSelectedEvent): void {
    const groupNames: any[] = this.entryPointGroupService.getEntryPointGroups();
    const selectedEntryPGroup: any = groupNames.find((element: any) => element.EntryPointGroupID === event.option.value);
    if (selectedEntryPGroup) {
      this.EntryPointGrpName = selectedEntryPGroup.EntryPointGrpName;
      this.entryPointGroupID = selectedEntryPGroup.EntryPointGroupID;
      this.setSuggestedEntryPoint(this.entryPointGroupID);
      this.entryPointGroupService.getAllEntryPointGroupsAPI(this.userService.organizationID).then(() => {
        this.entryPointGroups = this.entryPointGroupService.getEntryPointGroups();
        this.filteredEntryPointGroup = this.entryPointGroupFormControl.valueChanges.pipe(
          startWith(""),
          map(value => typeof value === 'string' ? value : value?.EntryPointGrpName),
          map(EntryPointGrpName => EntryPointGrpName ? this._filteredEntryPointGroup(EntryPointGrpName) : this.entryPointGroups.slice())
        );
      });
    }
  }

  //set Suggested Entry Point
  setSuggestedEntryPoint(entryPointGroupId){
    this.entryPointService
    .parseGetEndpointAPI(entryPointGroupId)
    .then(() => {
      this.entryPoints = [...this.entryPointService.getEntryPoints()];
      console.log("dhds",this.entryPoints)
      this.filteredExpectedEntryPointGroup = this.expectedEntryPointFormControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value?.Description),
        map(Description => Description ? this._filteredExpectedEntryPointGroup(Description) : this.entryPoints.slice())
      );
    })
    .catch((err: HttpErrorResponse) => {
      this.notificationService.failure(err.message);
    });
  var pointsArr = this.entryPoints;
  this.entryPointNames = pointsArr.map(function (item) {
    return item["Description"];
  });
  }

}
