import { ViewpassModalComponent } from "../viewpass-modal.component";
import { MAT_DIALOG_DATA, MatDialogRef,MatDialog } from "@angular/material/dialog";
import { Component, Inject, Renderer2 } from "@angular/core";
import { PassService } from "src/app/services/pass.service";
import { NotificationService } from "src/app/services/notification.service";
import { ParkingLotService } from "src/app/services/parking-lot.service";
import { DialogService } from "src/app/services/dialog.service";
import { UserService } from "src/app/services/user.service";
import { AuthService } from "../../services/auth.service";
import { EntryPointGroupService } from "../../services/entry-point-group.service";
import { SiteService } from "src/app/services/site.service";
import { UtilityService } from "src/app/services/utility.service";
import { TimezoneService } from "src/app/services/timezone.service";
import { MessageService } from "src/app/services/message.service";
import { EntryPointService } from "src/app/services/entry-point.service";
import { SearchService } from "src/app/services/search.service";
export interface DialogData {}

@Component({
  selector: "app-viewpass-modal-mobile",
  templateUrl: "./viewpass-modal.component.mobile.html",
  styleUrls: ["./viewpass-modal.component.mobile.css"],
})
export class ViewpassModalComponentMobile extends ViewpassModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    dialog: MatDialog,
    public dialogRef: MatDialogRef<ViewpassModalComponentMobile>,
    passService: PassService,
    notificationService: NotificationService,
    parkingLotService: ParkingLotService,
    dialogService: DialogService,
    userService: UserService,
    authService: AuthService,
    entryPointGroupService: EntryPointGroupService,
    entryPointService: EntryPointService,
    timezoneService: TimezoneService,
    siteService: SiteService,
    utilityService: UtilityService,
    renderer: Renderer2,
    messageService: MessageService,
    searchService: SearchService
  ) {
    super(
      data,
      dialog,
      dialogRef,
      passService,
      notificationService,
      parkingLotService,
      dialogService,
      userService,
      authService,
      entryPointGroupService,
      entryPointService,
      timezoneService,
      siteService,
      utilityService,
      renderer,
      messageService,
      searchService
    );
  }
}
