import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatExpansionPanel } from '@angular/material';
import { EntryPointService } from 'src/app/services/entry-point.service';
import { ApprovalTypeService } from 'src/app/services/approval-type.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SearchService } from 'src/app/services/search.service';
import { DialogService } from 'src/app/services/dialog.service';
import { SiteService } from 'src/app/services/site.service';
import { ApprovalType } from 'src/app/models/approval-type-model';
import { MessageService } from 'src/app/services/message.service';
import { RoleMapService } from 'src/app/services/role-map.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-view-approval-type-modal',
  templateUrl: './view-approval-type-modal.component.html',
  styleUrls: ['./view-approval-type-modal.component.css']
})
export class ViewApprovalTypeModalComponent implements OnInit {
  protected step = 0;
  protected approvalType: ApprovalType;
  emailTemplates: any[] = [];
  templateSelected: string;
  inUse = false;
  protected requestApprovedDropDown;
  protected requestRejectedDropDown;
  protected requestCreatedDropDown;
  protected requestCancelledDropDown;
  protected requestExpiredDropDown;
  protected noEmailTemplate = "No Email Required";
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  protected allRoleNames: Array<any> = [];
  protected accessReqRoleNames: Array<any> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected dialogRef: MatDialogRef<ViewApprovalTypeModalComponent>,
    protected entryPointService: EntryPointService,
    protected approvalTypeService: ApprovalTypeService,
    protected notificationService: NotificationService,
    protected searchService: SearchService,
    protected dialogService: DialogService,
    private siteService: SiteService,
    private _roleService: RoleMapService,
    protected messageService: MessageService,
    private _utilService: UtilityService,
  ) { }

  ngOnInit(): void {
    const data = { ...this.data }
    this.approvalType = data.approvalType;
    this.emailTemplates = data.emailTemplates;
    this.inUse = this.approvalType.InUse === 1 ? true : false;
    this._loadRoleNames();
    this.loadEmailTemplates();
    //labels by message service
    if (localStorage.getItem('ViewApprovalTypeLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('ViewApprovalTypeLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "View Approval Type Modal",
        "ApprovalTypeSiteSettings").subscribe((res: any) => {
          this.appLanguage = res.body;
          localStorage.setItem('ViewApprovalTypeLabels', JSON.stringify(this.appLanguage));
          this.loadTranslatedLabels();
        })
    }
  }

  loadEmailTemplates() {
    this.approvalTypeService.getEmailTemplates("Request Approved", this.approvalType.ApprovalTypeID).subscribe((response: any) => {
      this.requestApprovedDropDown = response.body;
    });
    this.approvalTypeService.getEmailTemplates("Request Rejected", this.approvalType.ApprovalTypeID).subscribe((response: any) => {
      this.requestRejectedDropDown = response.body;
    });
    this.approvalTypeService.getEmailTemplates("Request Created", this.approvalType.ApprovalTypeID).subscribe((response: any) => {
      this.requestCreatedDropDown = response.body;
    });
    this.approvalTypeService.getEmailTemplates("Request Cancelled", this.approvalType.ApprovalTypeID).subscribe((response: any) => {
      this.requestCancelledDropDown = response.body;
    });
    this.approvalTypeService.getEmailTemplates("Request Expired", this.approvalType.ApprovalTypeID).subscribe((response: any) => {
      this.requestExpiredDropDown = response.body;
    });

    this.approvalTypeService.getSelfRegAndAccessRole().subscribe((response: any) => {
      this.approvalType.UserAccessRequestEnabled = Number(response.body.filter(x => x.Name === "UserAccessRequestEnabled").map(x => x.Value)) ? true : false;
      this.approvalType.UserAccessRole = Number(response.body.filter(x => x.Name === "UserAccessRequestRole").map(x => x.Value));
      this.approvalType.SelfRegistrationEnabled = Number(response.body.filter(x => x.Name === "SelfRegistrationEnabled").map(x => x.Value)) ? true : false;
      this.approvalType.SelfRegRole = Number(response.body.filter(x => x.Name === "SelfRegistrationRole").map(x => x.Value));
    });
  }

  private async _loadRoleNames() {
    try {
      await this._roleService.getRoleMappingsAPI();

      // filter all roles
      this.allRoleNames = this._roleService
        .getRoleMappingResponse()
        .filter(
          (rm) =>
            rm.RoleActive == 1 && rm.SiteID === this.siteService.selectedSiteID
        )
        .map((r) => {
          return {
            RoleName: r.RoleName,
            RoleActive: r.RoleActive,
            RoleID: r.RoleID,
            SiteID: r.SiteID
          }
        });
      // remove duplicates for roles
      this.allRoleNames = this._utilService.removeObjArrayDuplicates(this.allRoleNames);
    } catch (err) {
      console.log(err);
    }
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  //code to freeze Panel
  onMainHeaderClose() {
    this.freezePanel.open();
  }

}

