import { NativeDateAdapter } from "@angular/material";
import { MatDateFormats } from "@angular/material/core";
import { Injectable } from "@angular/core";
import { SiteService } from "src/app/services/site.service";
import { Platform } from "@angular/cdk/platform";

@Injectable()
export class MatDatePickerAdapter extends NativeDateAdapter {
  constructor(private _siteService: SiteService) {
    super("en", new Platform());
  }

  format(date: Date, displayFormat: Object): string {
    if (displayFormat === "input") {
      let day: string = date.getDate().toString();
      day = +day < 10 ? "0" + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? "0" + month : month;
      let year = date.getFullYear();

      switch (this._siteService.dataTimeFormat.dateFormat.toLowerCase()) {
        case "dd/mm/yyyy":
          return `${day}/${month}/${year}`;

        case "mm/dd/yyyy":
          return `${month}/${day}/${year}`;

        case "yyyy/mm/dd":
          return `${year}/${month}/${day}`;
      }
    }
    return date.toDateString();
  }
}

export const CUSTOM_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: "short", year: "numeric", day: "numeric" },
  },
  display: {
    dateInput: "input",
    monthYearLabel: { year: "numeric", month: "numeric" },
    dateA11yLabel: { year: "numeric", month: "long", day: "numeric" },
    monthYearA11yLabel: { year: "numeric", month: "long" },
  },
};
