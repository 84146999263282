import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatExpansionPanel, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-deactivation-dialog',
  templateUrl: './deactivation-dialog.component.html',
  styleUrls: ['./deactivation-dialog.component.css']
})
export class DeactivationDialogComponent implements OnInit {

  @ViewChild("freePanel") freezePanel: MatExpansionPanel;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DeactivationDialogComponent>
  ) {}

  ngOnInit() {}
  onMainHeaderClose() {
    this.freezePanel.open();
  }
  closeDialog(value: boolean) {
    this.dialogRef.close(value);
  }

  onConfirmChange(value: boolean) {
    this.closeDialog(value);
  }
}
