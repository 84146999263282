import { Component, OnInit, ViewChild } from "@angular/core";
import { BuildingComponent } from "../building.component";
import { BuildingService } from "src/app/services/building.service";
import { MatDialog, MatExpansionPanel } from "@angular/material";
import { SearchService } from "src/app/services/search.service";
import { NotificationService } from "../../services/notification.service";
import { SiteService } from "src/app/services/site.service";
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: "app-desktop-building",
  templateUrl: "./building.desktop.component.html",
  styleUrls: ["./building.desktop.component.css"],
})
export class BuildingDesktopComponent extends BuildingComponent {
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  constructor(
    buildingService: BuildingService,
    dialog: MatDialog,
    searchService: SearchService,
    notificationService: NotificationService,
    siteService: SiteService,
    messageService: MessageService
  ) {
    super(
      buildingService,
      dialog,
      searchService,
      notificationService,
      siteService,
      messageService
    );
  }
  onMainHeaderClose() {
    this.freezePanel.open();
  }
}
