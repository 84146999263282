import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ISiteConfig } from '../models/interfaces/system-settings-get-api.model';
import { DialogService } from '../services/dialog.service';
import { NotificationSettingsService } from '../services/notification-settings.service';
import { NotificationService } from '../services/notification.service';
import { RequiredFieldsService } from '../services/required-fields.service';
import { SearchService } from '../services/search.service';
import { SiteService } from '../services/site.service';
import { MessageService } from '../services/message.service';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.css']
})
export class NotificationSettingsComponent implements OnInit {

  @ViewChild("notificationSettingsFrm") notificationSettingsFrm: NgForm;

  private _selectedSiteID: number;
  private _selectedSiteSettings: ISiteConfig[];
  protected notificationEmail: string;
  protected notificationsLoaded:boolean = false;
  protected appLanguage;

  constructor(
    protected _searchService: SearchService,
    protected requiredFieldsService: RequiredFieldsService,
    protected siteService: SiteService,
    private notificationService: NotificationService,
    private _dialogService: DialogService,
    private notificationSettingService: NotificationSettingsService,
    protected messageService: MessageService,
  ) {
    this._selectedSiteID = this.siteService.selectedSiteID;
    this._selectedSiteSettings = this.siteService.siteSettings.SystemSettings;
  } public screenLabels: Array<string> = [];

  ngOnInit(): void {

//labels by message service
if (localStorage.getItem('NotificationsSettingsLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('NotificationsSettingsLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Notifications",
      "Notifications"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('NotificationsSettingsLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    this._searchService.hideSearch();
    this.notificationSettingService.getNotificationSettingsAPI().then((response) => {
      response.SystemSettings

      this.notificationEmail = (response.SystemSettings.filter(x => x.Name === "Email").map(x => x.Value))[0];
      console.log("notificationemail", this.notificationEmail);

      this.notificationsLoaded = true;

    })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      }
      );
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}

  // ngAfterViewChecked() {
  //   // setting up the required fields
  //   this.requiredFieldsService.setRequiredFields(
  //     'Notification Settings',
  //     this.notificationSettingsFrm
  //   );
  // }

  /*  for setting setsystemsettings */
  private _upsertSiteSettingsValue(
    name: string,
    category: string,
    value: string,
    active: number,
    isSingleValued: boolean,
    siteID?: number
  ) {
    let settingRecord = this._checkExistingSiteSetting(
      name,
      category,
      isSingleValued,
      siteID,
      value
    );

    const systemSettingsID =
      settingRecord === undefined ? 0 : settingRecord.SystemSettingsID;

    const siteSettings: ISiteConfig = {
      SystemSettingsID: systemSettingsID,
      SiteID: siteID,
      Name: name,
      Category: category,
      Value: value,
      Active: active,
    };

    if (systemSettingsID !== 0) {
      const index = this._selectedSiteSettings.findIndex(
        (s) => s.SystemSettingsID === systemSettingsID
      );

      this._selectedSiteSettings[index] = siteSettings;

      console.log(this._selectedSiteSettings);
    } else {
      this._selectedSiteSettings.push(siteSettings);
      console.log(this._selectedSiteSettings);
    }
  }

  private _checkExistingSiteSetting(
    name: string,
    category: string,
    isSingleValued: boolean,
    siteID?: number,
    value?: string
  ): ISiteConfig {
    if (siteID === undefined)
      return this._selectedSiteSettings.find(
        (ss) => ss.Name === name && ss.Category === category
      );
    else if (
      (siteID !== undefined && value === undefined) ||
      (siteID !== undefined && value !== undefined && isSingleValued)
    )
      return this._selectedSiteSettings.find(
        (ss) =>
          ss.Name === name && ss.Category === category && ss.SiteID === siteID
      );
    else if (siteID !== undefined && value !== undefined && !isSingleValued)
      return this._selectedSiteSettings.find(
        (ss) =>
          ss.Name === name &&
          ss.Category === category &&
          ss.SiteID === siteID &&
          ss.Value === value
      );
  }

  private _formatNotificationEmail() {
    const settingName = "Email";
    const settingCategory = "Notification";

    const value = this.notificationEmail;

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }

  onNoClick(): void { }

  protected onSaveChanges() {

    this._dialogService.openConfirmDialog(
      this.screenLabels['CannotApproveRequestMsg'] ? this.screenLabels['CannotApproveRequestMsg'] : 'Save Notification Settings',
        this.screenLabels['CannotApproveCancelledReqMsg'] ? this.screenLabels['CannotApproveCancelledReqMsg'] : 'Are you sure if you want to save the Notification Settings ?'
    )
      .afterClosed().subscribe(async (confirm: boolean) => {
        if (confirm) {

          this._formatNotificationEmail();
          const days = this.siteService.createUpdateSiteSettingsAPI(
            this._selectedSiteSettings
          ).then(() => {
            this.notificationService.success(this.screenLabels["NotificationSettingsSuccessMsg"]? this.screenLabels["NotificationSettingsSuccessMsg"]:"Notification settings updated successfully");
          });

          this.onNoClick();
        }

      });
  }

}
