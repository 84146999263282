import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl, NgForm } from "@angular/forms";
import { MatAutocompleteSelectedEvent, MatDialog, MatDialogRef, MatExpansionPanel, MatSelectChange, MAT_DIALOG_DATA } from "@angular/material";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { NotificationService } from "src/app/services/notification.service";
import { SearchService } from "src/app/services/search.service";
import { UserService } from "src/app/services/user.service";
import { ApprovalGroup } from 'src/app/models/approval-group-model';
import { ApprovalGroupMember } from 'src/app/models/approval-group-member-model';
import { ApprovalGroupService } from 'src/app/services/approval-group.service';
import { SiteService } from 'src/app/services/site.service';
import { MessageService } from 'src/app/services/message.service';


@Component({
  selector: 'app-create-approval-member',
  templateUrl: './create-approval-member.component.html',
  styleUrls: ['./create-approval-member.component.css']
})
export class CreateApprovalMemberComponent implements OnInit {

  protected step = 0;
  protected approvalGroup: ApprovalGroup;
  protected approvalGroupMember: ApprovalGroupMember;
  protected selectedValue: string = "";
  protected selectedMember: string;
  protected selectedName: string;
  protected myControl = new FormControl();
  protected filteredOptions: Observable<string[]>;
  protected onSearch: boolean = false;
  protected filteringg: string[] = [];
  protected userExists: boolean = false;
  protected enableSubmit: boolean = true;
  protected userList: Array<any> = [];
  protected filteredUsersList: Array<String> = [];
  protected selectedUserList: Array<String> = [];
  protected currentUsers: any;
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  SelectApprovalGroupMemberControl = new FormControl();
  filteredDropdown: Observable<any[]>;

  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<CreateApprovalMemberComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected searchService: SearchService,
    protected userService: UserService,
    protected notificationService: NotificationService,
    protected approvalGroupService: ApprovalGroupService,
    private siteService: SiteService,
    protected messageService: MessageService,
  ) { }

  ngOnInit(): void {
    const data = { ...this.data };
    this.selectedName = data.name;
    this.currentUsers = data.currentUsers;
    this.approvalGroupMember = new ApprovalGroupMember(
      data.approvalGroupId,
      null,
    );

//labels by message service
if (localStorage.getItem('CreateApprovalMemberLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('CreateApprovalMemberLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Create Approval Group Member",
      "ApprovalGroupMember"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('CreateApprovalMemberLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}

  onKey(event: any) {
    this.onSearch = false;
    this.filteredUsersList = [];
  }

  onSelectionChange(event: MatAutocompleteSelectedEvent) {
    this.userExists = false;
    this.enableSubmit = false;
    this.selectedMember = event.option.value;
    const email = this.selectedMember.split(" ").splice(-1)[0];
    this.userList.forEach(user => {
      if (email === user.Email) {
        this.currentUsers.forEach(cUser => {
          if (user.UserID === cUser.UserID) {
            this.userExists = true;
            this.enableSubmit = true;
          }
        })
      }
    });
  }

  searchAprrovalMembers() {
    this.filteredUsersList = [];

    if (this.selectedValue === null) {
      this.onSearch = false;
      this.filteredUsersList = [];
    } else {
      this.onSearch = true;
      this.searchService.setPageName("User");
      this.searchService
        .smartSearchMultiSiteAPI(this.selectedValue,null,null,false)
        .then(() => {
          this.userList = this.searchService.searchRecords;
          this.userList.forEach(element => {
            if (element.Active === 1) {
              this.filteredUsersList.push(element.FirstName + ' ' + element.LastName + ' ' + '|' + ' ' + element.Email);
              this.filteredDropdown = this.SelectApprovalGroupMemberControl.valueChanges.pipe(
                startWith(""),
                map(value => typeof value === 'string' ? value : value?.option),
                map(option => option ? this._filteredDropdown(option) : this.filteredUsersList.slice())
              );
            }
          });
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    }
  }

  private _filteredDropdown(value: string){
    return this.filteredUsersList.filter(option => option.toLowerCase().includes(value.toLowerCase()));
  }
  displayFn4(selectedoption: string) {
    if(selectedoption) {
    return this.filteredUsersList.find(x => x === selectedoption)
    }
  }

  setStep(index: number) {
    this.step = index;
  }

  onMainHeaderClose() {
    this.freezePanel.open();
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onSaveChanges() {
    const users = [];
    const email = this.selectedMember.split(" ").splice(-1)[0];
    this.userList.forEach(user => {
      if (email === user.Email) {
        users.push({
          "AGUserID": user.UserID,
          "Active": user.Active
        });
      }
    });
    this.approvalGroupMember.Users = users;
    this.approvalGroupService.addApprovalGroupMember(this.approvalGroupMember);
    this.onNoClick();
  }


}