import { Injectable, EventEmitter, Output } from "@angular/core";
import { HttpHeaders, HttpResponse, HttpClient, HttpErrorResponse } from "@angular/common/http";
import { SpinnerService } from "./spinner.service";
import { environment } from "../../environments/environment";
import { UserTeam } from "../models/userTeam.model";
import { SiteService } from "./site.service";
import { UserIntegration } from "../models/userIntegration.model";
import { NotificationService } from "./notification.service";
import { MatDialog } from "@angular/material";
import { AssignUpdateRoleModalComponent } from "../users/modals/assign-update-role/assign-update-role-modal.component";
import { Observable, Subject } from "rxjs";
import { UserRegisteredGuard } from '../guards/userRegisteredGuard';

@Injectable({
  providedIn: "root",
})
export class UserService {
  @Output() userDetailsLoaded: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();
  @Output() organizationChanged: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();
  @Output() userDataChanged: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();
  @Output() editUser: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();
  private userAssociations: Array<any> = [];
  private applicationUsers: Array<any> = [];
  public hasApprovalGroup: boolean;
  public loggedInUserID: number = 0;
  public loggedInUserFirstName: string = "";
  public loggedInUserMiddleName: string = "";
  public loggedInUserLastName: string = "";
  public loggedInEmail: string = "";
  public loggedInPhone: string = "";
  public loggedInUserOrganizationID: number = 0;
  public loggedInSuffix: string = "";
  public callInUserID: number;
  public userFunctions: Array<boolean> = [];
  public userOrgFunctions: Array<boolean> = [];
  public loggedInUserFunctions: Array<boolean> = [];
  public userTeams: UserTeam[] = [];
  public loggedInUserOrganizations: Array<{
    OrganizationID: number;
    Name: string;
    StartDate: string;
    EndDate: string;
    IsAdmin: number;
  }> = [];
  public callInUserOrganizations: any;

  public selectedSiteID: number;

  public hasUserAssociationsLoaded: boolean = false;
  public roleFunctionArray;

  // holds the list of Entry Points groups (applicable only for users having checkin privileges)
  public entryPointGroupIDs: Array<number> = [];

  public userID: number;
  public IsAdmin: number = 0;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public suffix: string;
  public organizationID: number = 0;
  public organization: string;
  public userCheckedInEntryPoint;
  public userCheckIn: boolean;
  public email: string;
  public pin: string;
  public phone: string;
  public userOrganizations: Array<{
    OrganizationID: number;
    Name: string;
    StartDate: string;
    EndDate: string;
    IsAdmin: number;
  }> = [];
  public message;
  private symmetryUsers: Array<{
    SymmetryCardHolderRawID: number;
    EmployeeRef: string;
    FirstName: string;
    LastName: string;
  }>;
  private symmetryActiveUsers: Array<{
    SymmetryCardHolderRawID: number;
    EmployeeRef: string;
    FirstName: string;
    LastName: string;
  }>;
  public createdUserID: number;
  public existingActiveUser: any;
  public existingInActiveUser: any;

  public deactivatedUser: any;
  public deleteUser: any;
  public takeUserID: any;
  public deleteUserMessage: any;
  toggleStatusSubject = new Subject();
  closeToggleSubject = new Subject();
  messageSubject = new Subject();
  useridSubject = new Subject();
  callInUserSubject = new Subject();
   public functionID:number;
   public deleteUserMessage2: any;
   public deleteUserMessage3: any;
   public userByID;
   httpOptions: { headers; observe } = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
    observe: "response",
  };
  constructor(
    private http: HttpClient,
    private spinnerService: SpinnerService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {
    this.userID = null;
    this.firstName = null;
    this.middleName = null;
    this.lastName = null;
    // this.organizationID = null;
    this.organization = null;
    this.suffix = null;
    this.email = null;
    this.pin = null;
  }

  //for closing the snackbar
  sendToggle(status: boolean) {
    this.toggleStatusSubject.next({ status: status });
  }
  getToggle(): Observable<any> {
    return this.toggleStatusSubject.asObservable();
  }

  //for the message on the snackbar
  setSnackBarMessage(message: string) {
    this.messageSubject.next({ message: message })
  }
  getSnackBarMessage(): Observable<any> {
    return this.messageSubject.asObservable();
  }

  //for getting the userid
  setUserIDFromAPI(userid: number) {
    this.useridSubject.next({ userid: userid })
  }
  getUserIDFromAPI(): Observable<any> {
    return this.useridSubject.asObservable();
  }

  //for enabling the close button
  sendCloseButtonToggle(status: boolean) {
    this.closeToggleSubject.next({ status: status });
  }
  getCloseButtonToggle(): Observable<any> {
    return this.closeToggleSubject.asObservable();
  }

  //for setting if callinuser authenticated or not
  setCallInUserAuthenticated(status: boolean) {
    this.callInUserSubject.next({ status: status })
  }
  getCallInUserAuthenticated(): Observable<any> {
    return this.callInUserSubject.asObservable();
  }
  public getInitials(): string {
    return (
      this.loggedInUserFirstName.charAt(0) + this.loggedInUserLastName.charAt(0)
    ).toUpperCase();
  }

  getUsers() {
    // usr id, user
  }

  // Calls API to get the list of application users
  getAllUsersAPI(): Promise<any> {
    const apiURL = environment.getAPI("getUser");
    const postBody = { UserID: "", SiteID: "" };
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, this.httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.applicationUsers = success.body;
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // invoked to return the list of all application users
  public getApplicationUsers() {
    return [...this.applicationUsers];
  }


  // Calls update user API
  updateUserAssociationsAPI(user: {
    CreatedBy: number;
    //UserIDRef: number;
    OKTAID: string;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    Suffix: string;
    Email: string;
    Phone: string;
    Status: string;
    PIN: string;
    Active: boolean;
    PrefFirstName: string;
    PrefMiddleName: string;
    PrefLastName: string;
    PrefEmail: string;
    PrefPhone: string;
    Organization: Array<{
      UserOrganizationID: number;
      OrganizationID: number;
      Active: boolean;
      IsAdmin: number;
    }>;
    Role: Array<{
      UserRoleID: number;
      RoleID: number;
      Active: boolean;
    }>;
    BadgeID: string;
    UserIntegrations;
    SiteID: number;
  }, isUserReActive?: boolean): Promise<any> {
    const apiURL = environment.getAPI("userUpdate");
    user.UserIntegrations = user.UserIntegrations ? user.UserIntegrations : [];
    user.SiteID = this.selectedSiteID;
    const postBody: any = user;
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, this.httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.takeUserID = success.body.UserID;
            if (isUserReActive) {
              this.editUser.emit(true);
            }
            this.message = success.body.Message;
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // Calls create user API
  createUserAssociationsAPI(user: {
    CreatedBy: number;
    // UserID: number;
    OKTAID: string;
    EmployeeRef: string;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    Suffix: string;
    Email: string;
    Phone: string;
    Status: string;
    PIN: string;
    Active: boolean;
    PrefFirstName: string;
    PrefMiddleName: string;
    PrefLastName: string;
    PrefEmail: string;
    PrefPhone: string;
    Organization: Array<{
      UserOrganizationID: number;
      OrganizationID: number;
      IsAdmin: number;
      Active: boolean;
    }>;
    Role: Array<{
      UserRoleID: number;
      RoleID: number;
      Active: boolean;
    }>;
    SiteID: number;
  }): Promise<any> {
    const apiURL = environment.getAPI("userCreate");
    const postBody = user;
    postBody.Status = "Approved";
    postBody.Active = true;
    postBody.OKTAID = user.OKTAID;
    postBody.PIN = null;
    postBody.Organization = postBody.Organization.map((o) => ({
      UserOrganizationID: o.UserOrganizationID,
      OrganizationID: o.OrganizationID,
      IsAdmin: o.IsAdmin,
      Active: o.Active,
    }));
    postBody.Role = postBody.Role.map((r) => ({
      UserRoleID: r.UserRoleID,
      RoleID: r.RoleID,
      Active: r.Active,
    }));
    postBody.SiteID = this.selectedSiteID;
    postBody.CreatedBy = this.userID;
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, this.httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
            this.createdUserID = success.body.UserID;
            this.userDataChanged.emit(true);
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
    // return null;
  }

  // Calls API to get particular User
  getUserAssociationAPI(userID: number, siteID: number): Promise<any> {
    const apiURL = environment.getAPI("getUser");
    const postBody = { UserID: userID, SiteID: siteID };
    setTimeout(() => {
      this.spinnerService.setIsLoading(true);
    }, 0);
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, this.httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.userAssociations = success.body;
            this.userByID = success.body;
            if (this.userAssociations[0].UserApprovalGroup.length > 0) {
              this.hasApprovalGroup = true;
            }
            else {
              this.hasApprovalGroup = false;
            }
            // this.entryPointDataChanged.emit(true);
            this.hasUserAssociationsLoaded = true;
            setTimeout(() => {
              this.spinnerService.setIsLoading(false);
            }, 0);
            resolve(success);
          },
          (err) => {
            // error
            setTimeout(() => {
              this.spinnerService.setIsLoading(false);
            }, 0);
            reject(err);
          }
        );
    });
    return promise;
  }

  getUserDetails(){
    return this.userAssociations;
  }

  getUserAssociationByID(userID: number) {
    if (!this.userAssociations.length) return [];
    return this.userAssociations.filter((ua) => ua.UserID === userID);
  }

  // Get Symmetry Users API from smart search
  getSymmetryUsersAPI(): Promise<any> {
    const apiURL = environment.getAPI("getUpdatedSmartSearch");

    const body: {
      UserID: number;
      EntryPointID: number;
      OrganizationID: number;
      TableName: string;
      CsvColumns: string;
      Key: string;
      ScreenName: string;
    } = {
      UserID: this.userID,
      EntryPointID: null,
      OrganizationID: null,
      TableName: "SymmetryCardHolderRaw",
      CsvColumns: "*",
      Key: null,
      ScreenName: "Create User",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, this.httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.symmetryUsers = success.body;
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });

    return promise;
  }

  //Get Active Symmetry Users API
  getSymmetryCardInfoApi(key: string): Promise<any> {
    const apiURL = environment.getAPI("getSymmetryCardInfo");

    const body: {
      Key: string;
    } = { Key: key };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, this.httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.symmetryActiveUsers = success.body;
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });

    return promise;
  }

  // Get Symmetry Users API
  searchSymmetryUsersAPI(key: string): Promise<any> {
    const apiURL = environment.getAPI("getMultiSiteSmartSearch");
    if (!key) key = null;
    const body: {
      UserID: number;
      EntryPointID: number;
      OrganizationID: number;
      TableName: string;
      Key: string;
      ScreenName: string;
      SiteID: number;
      ObjectID: number;
      ParentObjectField: string
    } = {
      UserID: this.userID,
      EntryPointID: null,
      OrganizationID: this.organizationID,
      TableName: "SymmetryCardHolderRaw",
      // CsvColumns: this.getColumns(),
      Key: key,
      ScreenName: "Create User",
      SiteID: this.selectedSiteID,
      ObjectID: null,
      ParentObjectField: null
    };
   
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, this.httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.symmetryUsers = success.body;
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });

    return promise;
  }


  // search User Integration
  searchUserIntegrationAPI(OKTAID: string): Promise<any> {
    const apiURL = environment.getAPI("searchUserIntegration");
    const body = {
      SiteID: this.selectedSiteID,
      OKTAID: OKTAID
    };
    this.existingActiveUser = null;
    this.deactivatedUser = null;
    this.existingInActiveUser = null;

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, this.httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.deactivatedUser = success.body;
            this.existingInActiveUser = success.body.data;
            resolve(success);
          },
          (err: HttpErrorResponse) => {
            // error
            console.log(err.error);
            console.log(err);
            if (err.status === 422) {
              this.existingActiveUser = err.error.activeUser;
            }
            reject(err);
          }
        );
    });

    return promise;
  }
  public getUserIntegrationInfo(userId: number, selectedData: any) {
    const apiURL = environment.getAPI('getUserIntegrationInfo');
    const body = {
      IntegrationID: selectedData.IntegrationID,
      UserID: userId,
      DisplayValue: selectedData.DisplayValue
    }
    const res = this.http.post<any>(apiURL, body, this.httpOptions);
    return res;
  }
  getSymmetryUsers() {
    return [...this.symmetryUsers];
  }

  getSymmetryCardInfo() {
    return [...this.symmetryActiveUsers];
  }

  // set the entry point group ids for users having check in privilege
  public setUserEntryPointGroupIDs(entryPointGroups: Array<number>) {
    this.entryPointGroupIDs = entryPointGroups;
  }

  // Calls update user Organization API
  updateUserOrganizationAPI(user: any): Promise<any> {
    const apiURL = environment.getAPI("updateUserOrganization");
    const postBody: any = user;
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, this.httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
           // this.existingUser =
              resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // Calls create user integration API
  createUserIntegrationAPI(userIntegration: UserIntegration
  ): Promise<any> {
    const apiURL = environment.getAPI("userIntegrationAction");
    const postBody = userIntegration;
    postBody.UserID = this.createdUserID;
  
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, this.httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
    // return null;
  }

  deactivateUserForAllSites(UserID: number): Promise<any> {
    const apiURL = environment.getAPI("deactivateUser");
    const loggedInUser =this.loggedInUserID;
    const body = {
      UserID: UserID,
      ModifiedBy:loggedInUser
    };
  
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, this.httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            this.deleteUser = success.body;
            this.deleteUserMessage = success.body.message;
            resolve(success);
          },
          (err: HttpErrorResponse) => {
            // error
            console.log(err.error);
            console.log(err);
            this.notificationService.failure(err.error.message);
            reject(err);
          }
        );
    });
    return promise;
  }

  deactivateSiteUser(UserID: number): Promise<any> {
    const apiURL = environment.getAPI("deactivateSiteUser");
    //const apiURL = "https://p4htl069t4.execute-api.us-west-2.amazonaws.com/dev/deactivateSiteUser";
    const loggedInUser =this.loggedInUserID;
    const body = {
      UserID: UserID,
      SiteID: this.selectedSiteID,
      ModifiedBy:loggedInUser
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, this.httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            this.deleteUserMessage2 = success.body.message;
            resolve(success);
          },
          (err: HttpErrorResponse) => {
            // error
            console.log(err.error);
            console.log(err);
            this.notificationService.failure(err.error.message);
            reject(err);
          }
        );
    });
    return promise;
  }

  suspendUser(UserID: number): Promise<any> {
    const apiURL = environment.getAPI("userStatusUpdate");
    const loggedInUser =this.loggedInUserID;
    const body = {
      UserID: UserID,
      Status: 'Suspended',
      ModifiedBy: loggedInUser,
    };
   
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, this.httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            this.deleteUserMessage3 = success.body.message;
            resolve(success);
          },
          (err: HttpErrorResponse) => {
            // error
            console.log(err.error);
            console.log(err);
            this.notificationService.failure(err.error.message);
            reject(err);
          }
        );
    });
    return promise;
  }

  public getRoleFunctionArray(UserID: number,SiteID: number){
    const apiURL = environment.getAPI("getUserRoleFunctionBySite");
    const body = {
      UserID : UserID,
      SiteID : SiteID
    }

    const res = this.http.post<any>(apiURL, body, this.httpOptions);
    return res;

  }

  public getIntegrations() {
    const apiURL = environment.getAPI("getIntegrations");
    const body = {
      SiteID: this.selectedSiteID,
    }
    const res = this.http.post<any>(apiURL, body, this.httpOptions);
    return res;
  }

  public checkUserCanApprove(approvalRequestID: number, approvalGroupRequestID : number) {
    const apiURL = environment.getAPI("checkUserCanApprove");
    const body = {
      UserID: this.userID,
      ApprovalRequestID: approvalRequestID,
      ApprovalGroupRequestID: approvalGroupRequestID
    }
    const res = this.http.post<any>(apiURL, body, this.httpOptions);
    return res;
  }

  getPreferredLanguage():Observable<any>{
    const apiURL = environment.getAPI("getLanguageList");
    return this.http.post(apiURL,this.httpOptions)
  }

  public unlinkUserAPI(userID: any) {
    const apiURL = environment.getAPI("removeOrgAccess");
    const body = {
      SiteID: this.selectedSiteID,
      OrganizationID: this.organizationID, 
      UserID: userID
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            this.message = success.body.Message;
           // this.teamDataChanged.emit(true);
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }


}