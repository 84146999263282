export class ApprovalGroupMember {
    public CreatedByName: string;
    public ModifiedByName: string;
  
    constructor(
      public ApprovalGroupID: number,
      public Users: any[]
    ) {
     // this.Active = 1;
    }
  }
  