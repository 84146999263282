import { Injectable } from "@angular/core";
import { SiteService } from "./site.service";
import { TimezoneService } from "./timezone.service";
import { DatePipe } from "@angular/common";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UtilityService {
  public UserBadgeVerficationResponse: any;
  constructor(
    private _siteService: SiteService,
    private _timezoneService: TimezoneService,
    private _datePipe: DatePipe,
    private http:HttpClient
  
  ) {}

  // the method should be called to search and sort a key from an array of strings
  public searchSortKeyUtil(strArray: Array<string>, input: string) {
    const weightedInp: Array<{ str: string; weight: number }> = [];

    // convert input and compared string to lowercase
    strArray = strArray
      .filter((s) => s.toLowerCase().includes(input.toLowerCase()))
      .sort();

    // split the each array element(sentence) into words
    strArray.forEach((s) => {
      const rawWords = s.split(" ");
      let w = 0;
      for (let i = 0; i < rawWords.length; i++) {
        // split the word into group of strings and numbers
        const words = rawWords[i].split(/(\d+)/);

        for (let j = 0; j < words.length; j++) {
          let word: any = words[j];

          if (word === "") continue;

          word = isNaN(+words[j]) ? words[j] : +words[j];
          word = word.toString();

          // console.log(s, words[j], word);

          // const isMatchFound = words[j].includes(input);
          const isMatchFound = word.toLowerCase().includes(input.toLowerCase());
          if (isMatchFound) {
            w += 10 / (word.length + j + i * 10);
          }
        }
      }

      weightedInp.push({
        str: s,
        weight: w,
      });
    });

    weightedInp.sort((a, b) => b.weight - a.weight);
    console.log(weightedInp);

    return weightedInp.map((wi) => wi.str);
  }

  // remove duplicates based on all properties of the array
  public removeObjArrayDuplicates(arr: Array<any>) {
    return arr.filter(
      (v, i, a) =>
        a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
    );
  }

  public getCurrentSiteDT() {
    return this.getSiteTime(new Date());
  }

  public getSiteTime(date: Date) {
    const format = "yyyy-MM-ddTHH:mm:ss.SSS"; // ISO date time string format to be used by angular date pipe

    const siteOffset = this._siteService.siteUTCOffset;
    // const dtString = moment(date).format(siteOffset);
    const dtString = this._datePipe.transform(date, format, siteOffset);
    const convertedDate = this._timezoneService.stripTimeZoneOffset(dtString);
    return convertedDate;
  }

  public stripLocalTimeZone(dateStr: string) {
    const format = "yyyy-MM-ddTHH:mm:ss.SSS"; // ISO date time string format to be used by angular date pipe
    dateStr = this._datePipe.transform(new Date(dateStr), format);

    //console.log(dateStr);

    // const convertedDate = this._timezoneService.stripTimeZoneOffset(dateStr);

    return dateStr;
  }

  // Calls API to Verify BadgeID of User
  getUserPhysicalMgmtInfo(BadgeID:string,UserID:number): Promise<any> {
     const apiURL = environment.getAPI("getUserPhysicalMgmtInfo");
    const postBody = {SiteID: this._siteService.selectedSiteID,BadgeID:BadgeID,UserID: UserID};
    // console.log(postBody);
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
          this.UserBadgeVerficationResponse = success.body;
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }
}
