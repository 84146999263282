import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatExpansionPanel, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ApprovalGroup } from 'src/app/models/approval-group-model';
import { UpdateParkingGroupModalComponent } from 'src/app/parking-lot-group/modals/update-parking-group/update-parking-group-modal.component';
import { ApprovalGroupService } from 'src/app/services/approval-group.service';
import { EntryPointService } from 'src/app/services/entry-point.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SearchService } from 'src/app/services/search.service';
import { SiteService } from 'src/app/services/site.service';
import { UpdateApprovalGroupComponent } from '../update-approval-group/update-approval-group.component';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-view-approval-group',
  templateUrl: './view-approval-group.component.html',
  styleUrls: ['./view-approval-group.component.css']
})
export class ViewApprovalGroupComponent implements OnInit {
  protected step = 0;
  protected approvalGroup: ApprovalGroup;
  AvailableUsersList: Array<String> = [];
  selectedUserList: Array<String> = [];
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ApprovalGroup,
    protected dialogRef: MatDialogRef<ViewApprovalGroupComponent>,
    protected entryPointService: EntryPointService,
    protected notificationService: NotificationService,
    private siteService: SiteService,
    protected searchService: SearchService,
    protected approvalGroupService: ApprovalGroupService,
    protected messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.approvalGroup = { ...this.data };
    this.getUsers();

//labels by message service
if (localStorage.getItem('MyApprovalLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('MyApprovalLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "View Approval Group Modal",
      "ApprovalGroup"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('MyApprovalLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}

  getUsers(): void {
    this.approvalGroupService.getApprovalGroupUserList(this.approvalGroup.ApprovalGroupID).subscribe((res: any) => {
      const data = res.body;
      data.forEach(element => {
        this.AvailableUsersList.push(element.FirstName + ' ' + element.LastName);
      });

    })
    this.getApprovalGroupUsers();
  }

  getApprovalGroupUsers() {
    this.approvalGroup.Users.forEach(element => {
      if (element.Active === 1) {
        this.selectedUserList.push(element.FirstName +' '+ element.LastName);
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close(this.searchService.setPageName("Approval Group"));
    this.approvalGroupService.viewApprovalGroupOpen = false;
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }
}
