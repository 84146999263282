import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatExpansionPanel } from '@angular/material';
import { DialogService } from 'src/app/services/dialog.service';
import { LoginRegistrationService } from 'src/app/services/login-registration.service';
import { MessageService } from 'src/app/services/message.service';
import { NotificationService } from 'src/app/services/notification.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { SiteService } from 'src/app/services/site.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UserInvitationService } from 'src/app/services/user-invitation.service';
import { UserService } from 'src/app/services/user.service';
import { map, startWith } from "rxjs/operators";
import { Observable } from "rxjs";
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-create-access-request',
  templateUrl: './create-access-request.component.html',
  styleUrls: ['./create-access-request.component.css']
})
export class CreateAccessRequestComponent implements OnInit {

  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  protected step = 0;
  protected sites: any;
  //protected organizations: any;
  protected organizations: Array<{
    OrganizationID: number;
    Name: string;
    StartDate: string;
    EndDate: string;
    IsAdmin: number;
  }> = [];
  protected selectedSite: any;
  protected selectedSiteID: number;
  protected selectedOrganizationID: number;
  protected sitesLoaded: boolean = true;
  protected orgsLoaded: boolean = true;
  protected screenLabels : Array<string> = [];
  protected appLanguage;
  selectOrganizationControl = new FormControl();
  filteredSelectOrganization: Observable<any[]>;
  selectSiteControl = new FormControl();
  filteredSelectSites: Observable<any[]>;
  constructor(
    private siteService: SiteService,
    private organizationService: OrganizationService,
    protected dialogRef: MatDialogRef<CreateAccessRequestComponent>,
    private dialogService: DialogService,
    private loginRegistrationService: LoginRegistrationService,
    private userService: UserService,
    private notificationService: NotificationService,
    private userInvitationService: UserInvitationService,
    protected messageService: MessageService
  ) { }

  ngOnInit(): void {
       //labels by message service
if (localStorage.getItem('CreateAccessRequestLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('CreateAccessRequestLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Create User Access Request Modal",
    "UserAccessRequest"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('CreateAccessRequestLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    // this.loginRegistrationService.getSiteListAPI(this.userService.userID).subscribe((res: any) => {
    //   this.sitesLoaded = false;
    //   this.sites = res['body'];
    // });

    this.loginRegistrationService.getSiteListByRoleAPI(this.userService.userID).subscribe((res: any) => {
      this.sitesLoaded = false;
      this.sites = res['body'];
      this.filteredSelectSites = this.selectSiteControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value?.Name),
        map(Name => Name ? this._filteredSelectSites(Name) : this.sites.slice())
      );
    });

  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }
  onRequestRaised() {

    const body = {
      UserIDToSubmit: this.userService.userID,
      SiteID: this.selectedSiteID,
      UserID: this.userService.userID,
      OrganizationID: this.selectedOrganizationID,
      RequesterComments: null,
      Email: null,
      FirstName: null,
      LastName: null,
      Phone: null,
      Role : [],
      Team: []
    };

    this.userInvitationService
      .submitUserRoleRequestAPI(body)
      .then((s) => {
        this.notificationService.success(s.Message);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    this.onNoClick();
  }
  
  onSiteSelectionChange(event) {
    this.selectedSiteID = event.option.value;
    this.loginRegistrationService.getSelfRegOrganizations(this.selectedSite, this.userService.userID).subscribe((res: any) => {
      let response = res.body;
      this.orgsLoaded = false;
      let filteredOrgs = this.userService.userOrganizations.map(itemY => { return itemY.Name; });
      this.organizations = response.filter(itemX => !filteredOrgs.includes(itemX.Name));
      this.filteredSelectOrganization = this.selectOrganizationControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value?.Name),
        map(Name => Name ? this._filteredSelectOrganization(Name) : this.organizations.slice())
      );
    })
    this.selectOrganizationControl.setValue('');
    //this.selectedOrganizationID = null;
  }

  displayFn(selectedoption: number) {
    if(selectedoption) {
    return this.organizations.find(x => x.OrganizationID === selectedoption).Name;
    }
  }

  displayFn1(selectedoption: number) {
    if(selectedoption) {
    return this.sites.find(x => x.SiteID === selectedoption).Name;
    }
  }
  
  private _filteredSelectOrganization(value: string) {
    return this.organizations.filter(option => option.Name.toLowerCase().includes(value.toLowerCase()));
  }

  private _filteredSelectSites(value: string) {
    return this.sites.filter(option => option.Name.toLowerCase().includes(value.toLowerCase()));
  }

  onOrgSelectionChange(event){
    this.selectedOrganizationID = event.option.value;
  }
}
