import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatExpansionPanel, MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { ApprovalConfig } from 'src/app/models/approval-config-model';
import { ApprovalGroup } from 'src/app/models/approval-group-model';
import { ApprovalType } from 'src/app/models/approval-type-model';
import { ApprovalTypeService } from 'src/app/services/approval-type.service';
import { EntryPointService } from 'src/app/services/entry-point.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SearchService } from 'src/app/services/search.service';
import { SiteService } from 'src/app/services/site.service';
import { UserService } from 'src/app/services/user.service';
import { ApprovalRequestConfigurationService } from 'src/app/services/approval-request-configuration.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-view-approval-request-configuration-modal',
  templateUrl: './view-approval-request-configuration-modal.component.html',
  styleUrls: ['./view-approval-request-configuration-modal.component.css']
})
export class ViewApprovalRequestConfigurationModalComponent implements OnInit {

  protected step = 0;
  protected approvalType: ApprovalType;
  protected approvalGroups: ApprovalGroup[];
  protected tableFunctions: any[];
  protected emailTemplates: any[];
  protected tableFunctionreturnValues: any[];
  protected addedApprovalConfigs: any[] = [];
  protected approvalConfig: any;
  nameFormControl = new FormControl("", [Validators.required]);
  usersList: Array<any> = [];
  AvailableUsersList: Array<String> = [];
  selectedUserList: Array<String> = [];
  configData: any;
  approvalRequest: any;
  viewApprovalRequest: any;
  requestModificationAllowed = false;
  requestAutoApproval = false;
  conditionReturnValueList: any[] = [];
  selectedConditionReturnValueList: any[] = [];
  tableFunctionreturnValuesFiltered: any[] = [];
  positions: Array<number> = [1, 2, 3, 4, 5];
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  protected approvalGroupType: any;
  protected groupLoaded: boolean = true;
  protected groupsForLoggedInSite: ApprovalGroup[];
  protected groupsForSiteIDZero: ApprovalGroup[];
  approverEmailTemplates: any[] = []
  protected noEmailTemplate = "No Email Required";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<ViewApprovalRequestConfigurationModalComponent>,
    private siteService: SiteService,
    protected messageService: MessageService,
    protected approvalRequestConfigService: ApprovalRequestConfigurationService,
    protected notificationService: NotificationService,
    protected searchService: SearchService,
    protected userService: UserService,
  ) { }

  ngOnInit(): void {
    const data = { ...this.data };
    this.approvalType = this.data.approvalType;
    this.approvalRequest = data.approvalConfig;
    const dropdownValues = data.dropdownValues;
    //this.approvalGroups = dropdownValues.approvalGroups;
    this.tableFunctions = dropdownValues.tableFunctions;
    this.tableFunctionreturnValues = dropdownValues.tableFunctionReturnValues
    this.emailTemplates = dropdownValues.emailTemplates;
    const approverEmailTemplates = this.emailTemplates;
    this.approverEmailTemplates = approverEmailTemplates.filter(site => site.SiteID === this.userService.selectedSiteID)
    this.setValues(data);
    
    this.approvalRequestConfigService.getApprovalGroupTypeAPI(this.approvalConfig.ApprovalGroupID).subscribe((res: HttpResponse<any>) => {
      let response = res.body[0].Type;
      const allApprovalGroups = data.dropdownValues.approvalGroups;
      if (response === "Static" || response === null) {
        this.approvalGroupType = "Site";
        this.approvalRequestConfigService.getApprovalGroupBySiteID(this.siteService.selectedSiteID, this.approvalRequest.ApprovalTypeID).subscribe((res: HttpResponse<any>) => {
          let response = res.body;
          this.groupsForLoggedInSite = response;
          this.approvalGroups = this.groupsForLoggedInSite;
          this.groupLoaded = false;
        }, error => {
          console.log(error);
        });
        this.approvalRequestConfigService.getApprovalGroupBySiteID(0, this.approvalRequest.ApprovalTypeID).subscribe((res: HttpResponse<any>) => {
          let response = res.body;
          this.groupsForSiteIDZero = response;
        }, error => {
          console.log(error);
        });
      }
      else if (response === "Dynamic") {
        this.approvalGroupType = "System";
        this.approvalRequestConfigService.getApprovalGroupBySiteID(this.siteService.selectedSiteID, this.approvalRequest.ApprovalTypeID).subscribe((res: HttpResponse<any>) => {
          let response = res.body;
          this.groupsForLoggedInSite = response;
        }, error => {
          console.log(error);
        });
        this.approvalRequestConfigService.getApprovalGroupBySiteID(0, this.approvalRequest.ApprovalTypeID).subscribe((res: HttpResponse<any>) => {
          let response = res.body;
          this.groupsForSiteIDZero = response;
          this.approvalGroups = this.groupsForSiteIDZero;
          this.groupLoaded = false;
        }, error => {
          console.log(error);
        });
      }
    }, error => {
      console.log(error);
    });

    //labels by message service
    if (localStorage.getItem('ViewApprovalReqConfigLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('ViewApprovalReqConfigLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "View Approval Request Configuration Modal",
        "ApprovalRequestConfiguration"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('ViewApprovalReqConfigLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}

  setValues(data) {
    this.conditionReturnValueList = [];
    this.selectedConditionReturnValueList = [];
    this.requestModificationAllowed = data.selectedApprovalRequest.RequestModificationAllowed === 1 ? true : false;
    this.requestAutoApproval = data.selectedApprovalRequest.RequesterAutoApprovalEnabled === 1 ? true : false;
    this.tableFunctionreturnValuesFiltered = this.tableFunctionreturnValues.filter(val => val.TableFunctionID === data.selectedApprovalRequest.TableFunctionID);
    this.tableFunctionreturnValuesFiltered.forEach(element => {
      this.conditionReturnValueList.push(element.Description);
    });
    const values = data.selectedApprovalRequest.TableFunctionReturn ? data.selectedApprovalRequest.TableFunctionReturn.split(","): [];
    values.forEach(ele => {
      this.tableFunctionreturnValuesFiltered.forEach(val => {
        if (val.Value === Number(ele)) {
          this.selectedConditionReturnValueList.push(val.Description)
        }
      });
    });
    this.approvalConfig = new ApprovalConfig(
      data.selectedApprovalRequest.SiteID,
      data.selectedApprovalRequest.ApprovalRequestConfigDescription,
      data.selectedApprovalRequest.ApprovalTypeID,
      data.selectedApprovalRequest.ApprovalGroupID,
      data.selectedApprovalRequest.Position,
      data.selectedApprovalRequest.TableFunctionID,
      data.selectedApprovalRequest.TableFunctionReturn,
      data.selectedApprovalRequest.GrpApprovalEmailTemplateID,
      data.selectedApprovalRequest.GrpCancellationEmailTemplateID,
      data.selectedApprovalRequest.RequestModificationAllowed,
      data.selectedApprovalRequest.RequesterAutoApprovalEnabled,
      data.selectedApprovalRequest.Active
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onRemoveConfig(config) {
    const index: number = this.addedApprovalConfigs.indexOf(config);
    if (index !== -1) {
      this.addedApprovalConfigs.splice(index, 1);
    }
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  getNameRequiredErrorMessage() {
    return this.nameFormControl.hasError("required")
      ? "You must enter a value"
      : " ";
  }
  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }
}



