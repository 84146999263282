import { TemplateCategoryUser } from './template-categoryUser.model';

export class Team {
  public CreatedByName: string;
  public ModifiedByName: string;
  
  constructor(
    public TeamID: number,
    public Name: string,
    public TemplateCategoryTeamList: Array<{
       TemplateCategoryTeamID: number,
       TemplateCategoryID: number,
       TemplateCategory: string,
       TeamID: number,
       Active: boolean,
       ModifiedBy: string,
       ModifiedDate : Date,
       CreatedBy: string,
       CreatedDate: Date
    }>,
    public OrganizationID: number,
    public AutoAddAllOrgUsers : number,
    public AutoAddAllCategories:number,
    public Active: number,
    public ModifiedBy: number,
    public ModifiedDate: Date,
    public CreatedBy: number,
    public CreatedDate: Date,    
    public SelectedTeamOrgFunctionList:Array<{
       OrganizationFunctionTeamID: number,
       FunctionCategoryID: number,
       OrganizationFunctionID: number,
       OrganizationFunctionName: string,
       TeamID: number,
       Active: boolean,
       ModifiedBy: string,
       ModifiedDate : Date,
       CreatedBy: string,
       CreatedDate: Date
    }> 

  ) {
    this.Active = 1;
  }
}
