import { DatePipe } from "@angular/common";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { GridApi } from "ag-grid-community";

import { ApprovalService, IApprovalRequestStatusResponse } from "src/app/services/approval.service";
import { SiteService } from "src/app/services/site.service";
import { UserService } from "src/app/services/user.service";
import { IApproveRejectEmitter } from "../AgGrid/approve-reject-cell-renderer/approve-reject-cell-renderer.component";

@Component({
    selector: 'app-approval-status-grid',
    templateUrl: './approval-status-grid.component.html',
    styleUrls: ['./approval-status-grid.component.css']
})
export class ApprovalStatusGridComponent implements OnInit {
    @Input('requestID') public requestArray: any;
    
    private gridApi: GridApi;

    public approvalStatusLoaded: boolean = false;
    public isRequestEditRejected: boolean = false;
    public disabled: boolean = false;
    public noDataToDisplay: boolean = false;
    public reviewerRowData: any;
    public modificationAllowed: boolean = false;
    public integrationAllowed: boolean = false;
    @Output() integrateCanBeActioned: EventEmitter<any> = new EventEmitter();
    constructor(private approvalService: ApprovalService, private siteService: SiteService, private datePipe: DatePipe, private userService: UserService) { }

    async ngOnInit() {
        this.loadApprovalStatus();
    }

    private formatDate(date: string) {
        const formattedDate = this.datePipe.transform(
            date,
            this.siteService.dataTimeFormat.angularDateFormat
        );
        return formattedDate ? formattedDate : null;
    }

    onGridReady(params) {
        this.gridApi = params.api;
    }

    onFirstDataRendered() {
        this.gridApi.sizeColumnsToFit();
    }


    public refreshApprovalDetails() {
        this.loadApprovalStatus();
    }

    public async loadApprovalStatus() {
        try {
            this.approvalStatusLoaded = false;
            this.reviewerRowData = await this.approvalService.getApprovalDetails(
                this.requestArray[0].RequestID,
                this.requestArray[0].ApprovalType
                );
            this.noDataToDisplay = this.reviewerRowData.length > 0 ? false : true;

            
            const isRequestRejected = this.reviewerRowData.find(
                (rd) =>
                    rd.Status.toLowerCase() === "Rejected".toLowerCase()
            );
            this.isRequestEditRejected = isRequestRejected ? true : false;
            this.approvalStatusLoaded = true;
            this.reviewerRowData.forEach(data => {
                if (data.Status.toLowerCase() === 'Requested'.toLowerCase()) {
                    this.userService.checkUserCanApprove(data.ApprovalRequestID, data.ApprovalGroupRequestID).subscribe((res: any) => {
                        if (res.body.CanBeActionedOn === 1) {
                            data.CanBeActionedOn = 1;
                           if( data.RequestModificationAllowed === 1){
                            this.integrateCanBeActioned.emit(true)
                           }
                        } else {
                            data.CanBeActionedOn = 0;
                        }
                    });
                }
            });  
        } catch (err) {
            console.error(err);
        }
    }

    onApprove(data) {
        const eventData: IApproveRejectEmitter = {
          isApproved: true,
          data: data,
        };
        this.disabled = true;
        this.approvalService.approvedRejected.emit(eventData);
        // this.params.clicked(this.params.value);
      }
    
      onReject(data) {
        const eventData: IApproveRejectEmitter = {
          isApproved: false,
          data: data,
        };
        this.disabled = true;
        this.approvalService.approvedRejected.emit(eventData);
      }
}