import { Component, OnInit } from "@angular/core";
import { MessageService } from '../services/message.service';
import { UserService } from '../services/user.service';
import { SiteService } from '../services/site.service';

@Component({
  selector: "app-audit-log",
  templateUrl: "./audit-log.component.html",
  styleUrls: ["./audit-log.component.css"],
})
export class AuditLogComponent implements OnInit {
  protected appLanguage;
  public screenLabels: Array<string> = [];
  constructor(
    public userService: UserService,
    public siteService: SiteService,
    protected messageService: MessageService,) {
  }

  ngOnInit(): void {

//labels by message service
if (localStorage.getItem('AuditLogLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('AuditLogLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Audit Log Page",
    "AuditLogPage"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('AuditLogLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

}
