import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatSelect,
  MatSelectChange,
  MatExpansionPanel,
} from "@angular/material";
import { DatePipe } from "@angular/common";
import { OrganizationService } from "../../../services/organization.service";
import { HttpErrorResponse } from "@angular/common/http";
import { NotificationService } from "../../../services/notification.service";
import { DialogService } from "../../../services/dialog.service";
import { SiteService } from "src/app/services/site.service";
import { UtilityService } from "src/app/services/utility.service";
import { UserService } from "src/app/services/user.service";
import { SearchService } from "src/app/services/search.service";
import { FormControl, NgForm, Validators } from "@angular/forms";
import { GridApi } from "ag-grid-community";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-view-organization-modal",
  templateUrl: "./update-organization-modal.component.html",
  styleUrls: ["./update-organization-modal.component.css"],
  providers: [DatePipe],
})
export class UpdateOrganizationModalComponent implements OnInit {
  @ViewChild("parkingLimit") parkingLimit: ElementRef;
  @ViewChild("parkingLotGrpDropDown") parkingLotGrpDropDown: any;
  @ViewChild("entryPointZoneLimit") entryPointZoneLimit: ElementRef;
  @ViewChild("entryPointZoneDropDown") entryPointZoneDropDown: any;
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  @ViewChild("searchField") searchField: ElementRef;

  protected rowData = [];
  protected rowDataZone = [];
  protected rowDataParking = [];
  protected selectedAdmins = [];
  protected selectedZones = [];
  protected selectedParking = [];
  protected isRowSelected: boolean = false;
  protected isZoneRowSelected: boolean = false;
  protected isParkingRowSelected  : boolean = false;
  protected addZoneLimit: boolean = false;
  protected addParkingLimit: boolean = false;
  protected orgAdmins: string[] = [];
  gridOptions = {
    pagination: true,       // pagination properties
    paginationPageSize: 10
  };
  private getRowStyle;
  private getRowStyle2;
  private getRowStyle3;

  adminColumnDefs = [{
    headerName: "",
    field: "",
    width: 60,
    cellStyle: { color: "#589bf8" },
    hide: false,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    resizable: false,
    sortable: false,
    lockPosition: true,
  }, { field: "OKTAID", headerName: 'Email' },
  { field: "LastName", headerName: 'Last Name', sortable: true }, { field: "FirstName", headerName: 'First Name', sortable: true },];
  columnDefsZone = [{
    headerName: "",
    field: "",
    width: 60,
    cellStyle: { color: "#589bf8" },
    hide: false,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    resizable: false,
    sortable: false,
    lockPosition: true,
  }, { field: "EntryPointZoneName", headerName: 'Entry Point Zone', sortable: true },
  { valueGetter: this.zoneValue, headerName: 'Limit' }];
  gridOptionsZone = {
    pagination: true,       // pagination properties
    paginationPageSize: 10
  }; 
  columnDefsParking = [{
    headerName: "",
    field: "",
    width: 60,
    cellStyle: { color: "#589bf8" },
    hide: false,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    resizable: false,
    sortable: false,
    lockPosition: true,
  }, { field: "ParkingLotGroupName", headerName: 'Parking Lot Group', sortable: true },
  { valueGetter: this.parkingValue, headerName: 'Limit' }];
  gridOptionsParking = {
    pagination: true,       // pagination properties
    paginationPageSize: 10
  }; 
  protected adminExists: boolean = false;
  protected addAdmin: boolean = false;
  protected myControl = new FormControl();
  protected searchOption = new FormControl();
  protected gridApi: GridApi;
  protected gridApi2: GridApi;
  protected gridApi3: GridApi;
  protected adminsLoaded: boolean = false;
  protected searchLoaded: boolean = false;
  protected selectedUser: string;
  protected userList: Array<any> = [];
  protected adminsToAdd: Array<any> = [];
  protected adminsToRemove: Array<any> = [];
  protected filteredUsersList: Array<String> = [];
  protected step = 0;
  protected selectedValue: string = "";
  protected onSearch: boolean = false;
  protected hasParkingLoaded: boolean = false;
  protected hasEntryPointZoneLoaded: boolean = false;
  protected organization = { ...this.data };
  protected isSelectedParkingLotGrpRegulated: boolean = true;
  protected isSelectedEntryPointZoneRegulated: boolean = true;
  protected disableNewParkingLotAdd: boolean = true;
  protected disableNewEntryZoneAdd: boolean = true;
  private originalParkingWithLimits: Array<{
    OrganizationParkingLimitID: number;
    Limit: number;
    ParkingLotGroupID: number;
    ParkingLotGroupName: string;
    Active: boolean;
    Regulated: boolean;
  }> = [];

  protected availableParking: Array<{
    ParkingLotGroupID: number;
    ParkingLotGroupName: string;
    Regulated: boolean;
  }> = [];
  protected parkingWithLimits: Array<{
    OrganizationParkingLimitID: number;
    Limit: number;
    ParkingLotGroupID: number;
    ParkingLotGroupName: string;
    Active: boolean;
    Regulated: boolean;
  }> = [];
  protected originalEntryPointZoneWithLimits: Array<{
    OrgEntryPointZoneLimitID: number;
    Limit: number;
    EntryPointZoneID: number;
    EntryPointZoneName: string;
    Active: boolean;
    Regulated: boolean;
  }>;
  protected availableEntryPointZones: Array<{
    EntryPointZoneID: number;
    EntryPointZoneName: string;
    Regulated: boolean;
  }> = [];
  protected entryPointZoneWithLimits: Array<{
    OrgEntryPointZoneLimitID: number;
    Limit: number;
    EntryPointZoneID: number;
    EntryPointZoneName: string;
    Active: boolean;
    Regulated: boolean;
  }> = [];
  protected minDate;

  protected parkingEmpty: number = 0;
  protected onHideParking: boolean = true;
  protected zoneEmpty: number = 0;
  protected onHideZone: boolean = true;
  protected disableOnSave: boolean = false;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  public defaultColDef : object;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      OrganizationID: number;
      StartDate: string;
      EndDate: string;
      Internal: number;
      SelfRegAllowed: number;
      CreatedBy: number;
      CreatedDate: string;
      ModifiedBy: number;
      ModifiedDate: string;
      Name: string;
      Active: boolean;
    },
    protected datePipe: DatePipe,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<UpdateOrganizationModalComponent>,
    protected organizationService: OrganizationService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private siteService: SiteService,
    private utilService: UtilityService,
    private userService: UserService,
    private searchService: SearchService,
    protected messageService: MessageService,
  ) {
    this.getRowStyle = function (params) {
      if (params.node.rowPinned === 'top') {
        return { "background-color": "rgb(0, 88, 128,0.5)" };
      }
    };
    this.defaultColDef = {
      resizable: true,
  }
  }

  ngOnInit(): void {
//labels by message service
if (localStorage.getItem('UpdateOrganizationLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('UpdateOrganizationLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Update Organization Modal",
    "Organization"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('UpdateOrganizationLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    //get list of org admins
    this.organizationService.getAdminListByOrg(this.data.OrganizationID).subscribe((res: any) => {
      var adminDetails = res['body'];
      var tempArr = [];
      adminDetails.forEach((ag) => {
        tempArr.push({
          LastName: ag.LastName,
          FirstName: ag.FirstName,
          OKTAID: ag.OKTAID,
          UserID: ag.UserID
        });
      });
      this.adminsLoaded = true;
      this.rowData = tempArr;
    });

    this.organizationService
      .getOrganizationByIDAPI(this.data.OrganizationID)
      .then(() => {
        const details = this.organizationService.getOrganizationByIDResponse()[0];

        this.availableParking = details.unlinkedParkingLotGroupArray;
        this.parkingWithLimits = details.OrganizationParkingLimitArray;
        this.availableEntryPointZones = details.unlinkedEntryPointZone;
        this.entryPointZoneWithLimits = details.OrganizationEntryPointLimit;
        this.originalParkingWithLimits = [
          ...details.OrganizationParkingLimitArray,
        ];
        this.originalEntryPointZoneWithLimits = [
          ...details.OrganizationEntryPointLimit,
        ];
        const filteredLimits = this.entryPointZoneWithLimits.filter(item => item.Active);
        this.rowDataZone = filteredLimits;
        const filteredParking = this.parkingWithLimits.filter(item => item.Active);
        this.rowDataParking = filteredParking
        this.hasParkingLoaded = true;
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    this.onHideParking = false;
    this.onHideZone = false;

    //for enabling add-if we have 1row
    this.searchService.setOrganizationID(this.data.OrganizationID);
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  //when selection changes in org admin ag-grid
  onSelectionChanged($event) {
    const selectedRows: any[] = $event.api.getSelectedRows();
    this.selectedAdmins = selectedRows;
    if (selectedRows.length) {
      this.isRowSelected = true;
    } else {
      this.isRowSelected = false;
    }
  }
  onZoneSelectionChanged($event) {
    const selectedRows: any[] = $event.api.getSelectedRows();
    this.selectedZones = selectedRows;
    if (selectedRows.length) {
      this.isZoneRowSelected = true;
    } else {
      this.isZoneRowSelected = false;
    }
  }
  onParkingSelectionChanged($event) {
    const selectedRows: any[] = $event.api.getSelectedRows();
    this.selectedParking = selectedRows;
    if (selectedRows.length) {
      this.isParkingRowSelected = true;
    } else {
      this.isParkingRowSelected = false;
    }
  }
  showAddZoneLimit(){
    this.addZoneLimit= ! this.addZoneLimit;
  }
  showAddParkingLimit(){
    this.addParkingLimit= ! this.addParkingLimit;
  }
  //when selection changes in org admin select box
  onUserSelectionChange(event: MatSelectChange) {
    this.adminExists = false;
    this.selectedUser = event.value;
    const email = this.selectedUser.split(" ").splice(-1)[0];
    this.userList.forEach(user => {
      if (email === user.Email) {
        this.rowData.forEach(cUser => {
          if (user.UserID === cUser.UserID) {
            this.adminExists = true;
          }
        })
        if (!this.adminExists) {
          var userObj = { LastName: user.LastName, FirstName: user.FirstName, OKTAID: user.OKTAID, UserID: user.UserID }
          //this.gridApi.setPinnedTopRowData([userObj]);
          if (this.adminsToRemove.length > 0) {
            //check if added user was previously removed
            for (var i = 0; i < this.adminsToRemove.length; i++) {
              if (user.UserID === this.adminsToRemove[i]) {
                var index = this.adminsToRemove.indexOf(this.adminsToRemove[i]);
                this.adminsToRemove.splice(index, 1);
                this.rowData = [...this.rowData, userObj];
                return this.adminsToRemove;
              }
              else {
                this.rowData = [...this.rowData, userObj];
                this.adminsToAdd.push(user.UserID);
                return;
              }
            }

          }
          else {
            this.adminsToAdd.push(user.UserID);
            this.rowData = [...this.rowData, userObj];
          }
        }
      }
    });

    this.selectedValue = null;

  }

  //when remove selected admins button is clicked
  onRemoveSelected() {
    var selectedIDs = [];
    this.selectedAdmins.forEach(row => {
      selectedIDs.push(row.UserID)
    });

    this.adminsToRemove = this.adminsToRemove.concat(selectedIDs);
    this.gridApi.applyTransaction({ remove: this.selectedAdmins });
    var rowData = [];
    this.gridApi.forEachNode(node => rowData.push(node.data));
    this.rowData = rowData;
    if (this.adminsToAdd.length > 0) {
      //check if removed user was previously added
      for (var i = 0; i < this.adminsToAdd.length; i++) {
        for (var j = 0; j < this.adminsToRemove.length; j++) {
          if (this.adminsToAdd[i] === this.adminsToRemove[j]) {
            var indexAdd = this.adminsToAdd.indexOf(this.adminsToAdd[i]);
            this.adminsToAdd.splice(indexAdd, 1);
            var indexRemove = this.adminsToRemove.indexOf(this.adminsToRemove[j]);
            this.adminsToRemove.splice(indexRemove, 1);
          }
        }
      }
    }

  }


  //shows the add org admin panel
  showPanel() {
    var addButton = (<HTMLInputElement>document.getElementById("withMarginLeft")).value;
    this.addAdmin = !this.addAdmin;
    if (this.addAdmin) {
      addButton = '<mat-icon>remove_circle_outline</mat-icon>';
    }
  }
  startDateChanged(event) {
    if (event.value !== null) {
      this.minDate =
        new Date(this.organization.StartDate) >
          this.utilService.getCurrentSiteDT()
          ? new Date(this.organization.StartDate)
          : this.utilService.getCurrentSiteDT();
    } else {
      this.minDate = new Date(event.value);
    }

  }
  //search function for new org admin search
  searchUsers() {
    this.searchLoaded = false;
    if (this.selectedValue === null) {
      this.onSearch = false;
      this.filteredUsersList = [];
    } else {
      this.onSearch = true;
      this.searchService.setPageName("User");
      Promise.all([this.searchService
        .smartSearchMultiSiteAPI(this.selectedValue,null,null,false)])
        .then(() => {
          this.userList = this.searchService.searchRecords;
          this.userList.forEach(element => {
            if (element.Active === 1) {
              this.filteredUsersList.push(element.FirstName + ' ' + element.LastName + ' ' + '|' + ' ' + element.Email);
            }

          });
          this.searchLoaded = true;
          this.searchService.setPageName("Organizations");

        })


        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    }
  }
  //clear the user search bar
  clearSearchField() {
    this.searchField.nativeElement.value = "";
    this.onSearch = false;
    this.adminExists = false;
    this.filteredUsersList = [];
    this.searchLoaded = false;
    this.selectedValue = null;
  }
  //key event on new org admin search
  onKey(event: any) {
    this.onSearch = false;
    this.adminExists = false;
    this.filteredUsersList = [];
    this.searchLoaded = false;
  }
  //when ag-grid ready
  onGridReady(params) {
    this.gridApi = params.api;
  }
  onGridReady2(params) {
    this.gridApi2 = params.api;
  }
  onGridReady3(params) {
    this.gridApi3 = params.api;
  }
  endDateChanged(event) {
    // this.minDate = new Date(event.value);
    if (this.organization.StartDate == null) {
      this.minDate = new Date(event.value);
    } else {
      this.minDate =
        new Date(this.organization.StartDate) >
          this.utilService.getCurrentSiteDT()
          ? new Date(this.organization.StartDate)
          : this.utilService.getCurrentSiteDT();
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }


  // Remove parking limit
  deleteFieldValueForParking() {
    this.selectedParking.forEach(row => {
      var index = this.parkingWithLimits.indexOf(row);
      this.parkingWithLimits.splice(index, 1);
      this.availableParking.push(row);
    });
    this.gridApi3.applyTransaction({ remove: this.selectedParking });
    var rowData = [];
    this.gridApi3.forEachNode(node => rowData.push(node.data));
    this.rowDataParking = rowData;
  }


  // Remove entry point zone limit
  deleteFieldValueForZone() {
    this.selectedZones.forEach(row => {
      var index = this.entryPointZoneWithLimits.indexOf(row);
      this.entryPointZoneWithLimits.splice(index, 1);
      this.availableEntryPointZones.push(row);
    });
    this.gridApi2.applyTransaction({ remove: this.selectedZones });
    var rowData = [];
    this.gridApi2.forEachNode(node => rowData.push(node.data));
    this.rowDataZone = rowData;

  }



  // Add Parking Lot Limit
  onAddParkingLimit() {
    if (!this.onHideParking) {
      this.onHideParking = true;
    }
    //for enabling add-if we have 1row
    const parkingLotGrpName: string = this.parkingLotGrpDropDown.selected
      ._mostRecentViewValue;
    const parkingLoGrpID: number = +this.parkingLotGrpDropDown.value;

    const isRegulated = this.availableParking.filter(
      (ap) => ap.ParkingLotGroupID === parkingLoGrpID
    )[0].Regulated;
    const limit = isRegulated ? null : this.parkingLimit.nativeElement.value;

    // adding to the parking lot group with limit to parkingWithLimits array
    this.parkingWithLimits.push({
      OrganizationParkingLimitID: 0,
      Limit: limit,
      ParkingLotGroupID: parkingLoGrpID,
      ParkingLotGroupName: parkingLotGrpName,
      Active: true,
      Regulated: isRegulated,
    });
    this.rowDataParking = [...this.rowDataParking, {
      OrganizationParkingLimitID: 0,
      Limit: limit,
      ParkingLotGroupID: parkingLoGrpID,
      ParkingLotGroupName: parkingLotGrpName,
      Active: true,
      Regulated: isRegulated,
    }];
    // removing from available parking
    let index = -1;

    for (let i = 0; i < this.availableParking.length; i++) {
      if (this.availableParking[i].ParkingLotGroupID === parkingLoGrpID) {
        index = i;
      }
    }

    if (index >= 0) {
      this.availableParking.splice(index, 1);
      this.parkingLotGrpDropDown.selectedValue = undefined;
      this.parkingEmpty = 0
    }
    // Clearing the Limit Field
    this.parkingLimit.nativeElement.value = null;
    this.disableNewParkingLotAdd = true;
    this.isSelectedParkingLotGrpRegulated = true;

    this.disableOnSave = false;
  }

  // Add Entry Point Zone limit
  onAddZoneLimit() {
    //for enabling add-if we have 1row
    const entryPointZoneName: string = this.entryPointZoneDropDown.selected
      ._mostRecentViewValue;
    const entryPointZoneID: number = +this.entryPointZoneDropDown.value;

    const isRegulated = this.availableEntryPointZones.filter(
      (ap) => ap.EntryPointZoneID === entryPointZoneID
    )[0].Regulated;
    const limit = isRegulated
      ? null
      : this.entryPointZoneLimit.nativeElement.value;

    // adding to the entry Point Zone with limit to EntryPointZoneLimit array
    this.entryPointZoneWithLimits.push({
      OrgEntryPointZoneLimitID: 0,
      Limit: limit,
      EntryPointZoneID: entryPointZoneID,
      EntryPointZoneName: entryPointZoneName,
      Active: true,
      Regulated: isRegulated,
    });
    this.rowDataZone = [...this.rowDataZone, {
      OrgEntryPointZoneLimitID: 0,
      Limit: limit,
      EntryPointZoneID: entryPointZoneID,
      EntryPointZoneName: entryPointZoneName,
      Active: true,
      Regulated: isRegulated,
    }];
    // removing from available entry Point Zone
    let index = -1;

    for (let i = 0; i < this.availableEntryPointZones.length; i++) {
      if (
        this.availableEntryPointZones[i].EntryPointZoneID === entryPointZoneID
      ) {
        index = i;
      }
    }

    if (index >= 0) {
      this.availableEntryPointZones.splice(index, 1);
      this.entryPointZoneDropDown.selectedValue = undefined;
      this.zoneEmpty = 0
    }
    // Clearing the Limit Field
    this.entryPointZoneLimit.nativeElement.value = null;
    this.disableNewEntryZoneAdd = true;

    this.disableOnSave = false;
  }
  // Save the Organizational Limits
  onSave($event) {

    const name = this.organization.Name.trim() === this.organization.Name ? this.organization.Name : this.organization.Name.trim();
    this.organization.Name = name ;

    if (this.parkingEmpty) {
      this.onAddParkingLimit();
    }
    if (this.zoneEmpty) {
      this.onAddZoneLimit();
    }
    this.dialogService
      .openConfirmDialog(
        this.screenLabels['ConfirmOrgLimitUpdate'] ? this.screenLabels['ConfirmOrgLimitUpdate'] : "Confirm Organization Limit Update",
        this.screenLabels['ConfirmSaveMsg'] ? this.screenLabels['ConfirmSaveMsg'] : "Are you sure you want to save the changes ?"  
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.parkingWithLimits.forEach((element) => {
            const parkingLimitIndex = this.originalParkingWithLimits.findIndex(
              (f) => f.ParkingLotGroupID == element.ParkingLotGroupID
            );
            if (parkingLimitIndex >= 0) {
              element.OrganizationParkingLimitID = this.originalParkingWithLimits[
                parkingLimitIndex
              ].OrganizationParkingLimitID;
              this.originalParkingWithLimits.splice(parkingLimitIndex, 1);
            }
          });

          const differenceParking = this.originalParkingWithLimits.filter(
            (x) => !this.parkingWithLimits.includes(x)
          );

          // unlinking
          differenceParking.forEach((item) => {
            item.Active = false;
            this.parkingWithLimits.push(item);
          });

          this.entryPointZoneWithLimits.forEach((element) => {
            const zoneLimitIndex = this.originalEntryPointZoneWithLimits.findIndex(
              (f) => f.EntryPointZoneID == element.EntryPointZoneID
            );
            if (zoneLimitIndex >= 0) {
              element.OrgEntryPointZoneLimitID = this.originalEntryPointZoneWithLimits[
                zoneLimitIndex
              ].OrgEntryPointZoneLimitID;
              this.originalEntryPointZoneWithLimits.splice(zoneLimitIndex, 1);
            }
          });

          const differenceZone = this.originalEntryPointZoneWithLimits.filter(
            (x) => !this.entryPointZoneWithLimits.includes(x)
          );

          // unlinking
          differenceZone.forEach((item) => {
            item.Active = false;
            this.entryPointZoneWithLimits.push(item);
          });

          this.organizationService
            .updateOrganizationLimitAPI(

              this.organization.OrganizationID,
              this.datePipe.transform(this.organization.EndDate, "yyyy-MM-dd"),
              this.userService.userID,
              this.siteService.selectedSiteID,
              this.datePipe.transform(this.organization.StartDate, "yyyy-MM-dd"),
              this.organization.Internal ? 1 : 0,
              this.organization.SelfRegAllowed ? 1 : 0,
              1,
              this.organization.Name,
              this.parkingWithLimits,
              this.entryPointZoneWithLimits,
              this.adminsToAdd,
              this.adminsToRemove,
            )
            .then(() => {
              this.notificationService.success(
                this.organizationService.message
              );
            })
            .catch((err: HttpErrorResponse) => {
              this.notificationService.failure(err.message);
            });

          this.onNoClick();
        }
      });
  }
  
  isNameEmpty(): boolean {
    return !this.organization.Name || !this.organization.Name.trim();
  }

  nameFormControl = new FormControl("", [
    Validators.required,  // Required validation
    Validators.pattern(/^[a-zA-Z\s-][a-zA-Z\s0-9-]*$/),  // Updated pattern validation
    this.specialCharacterValidator(),
  ]);

  // Custom validator function
  specialCharacterValidator() {
    return (control: FormControl) => {
      const value = control.value;
      if (!value) {
        return null; // No validation for empty values
      }

      // Check for spaces or hyphens followed by spaces
      if (/^\s/.test(value)) {
        return { specialCharactersNotAllowed: true };
      }
  
      const isValid = /^[a-zA-Z\s0-9]*(-[a-zA-Z\s0-9]+)*$/.test(value);
  
      if (isValid && !/^-/.test(value)) {
      return null;
    };
  return { specialCharactersNotAllowed: true };
    }
  }

  // On Parking Lot Group Change
  onParkingLotGrpSelectionChange(event: MatSelect) {
    const parkingLotGrpID = +event.value;
    this.parkingEmpty = +event.value;
    this.disableNewParkingLotAdd = false;
    this.disableOnSave = false;
    const isRegulated = Number(
      this.availableParking.filter(
        (ap) => ap.ParkingLotGroupID === parkingLotGrpID
      )[0].Regulated
    );
    this.isSelectedParkingLotGrpRegulated = isRegulated == 0 ? false : true;
  }

  //On Entry Point Zone Change
  onEntryPointZoneSelectionChange(event: MatSelect) {
    const entryPointZoneID = +event.value;
    this.zoneEmpty = +event.value;
    this.disableNewEntryZoneAdd = false;
    this.disableOnSave = false;
    const isRegulated = Number(
      this.availableEntryPointZones.filter(
        (ap) => ap.EntryPointZoneID === entryPointZoneID
      )[0].Regulated
    );
    this.isSelectedEntryPointZoneRegulated = isRegulated == 0 ? false : true;
  }

  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }
  zoneValue(params) {
    return params.data.Regulated ? 'Self Governed' : params.data.Limit
  }
  parkingValue(params) {
    return params.data.Regulated ? 'Self Governed' : params.data.Limit
  }
}
