import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import { UserService } from "./user.service";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class MultiSortService {
  private sortingRules: Array<{
    UserSearchSortID: number;
    TableName: string;
    FieldName: string;
    Order: string;
    Position: number;
  }>;
  constructor(private http: HttpClient, private userService: UserService) {
    // Initializing the sorting rule array
    this.sortingRules = [];
  }

  // Multi Sort API
  getMultiSortAPI(): Promise<any> {
    const apiURL = environment.getAPI("getMultiSort");
    const body: {
      UserID: number;
    } = {
      UserID: this.userService.userID,
    };

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.sortingRules = success.body;
            this.sortingRules.sort((a, b) =>
              a.Position < b.Position ? -1 : a.Position > b.Position ? 1 : 0
            );
            console.log(this.sortingRules);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });

    return promise;
  }

  // Get Sorting by TableName
  public getSortingRulesByTableName(tableName: string) {
    return [...this.sortingRules.filter((sr) => sr.TableName === tableName)];
  }
}
