import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  MatExpansionPanel,
  MatDialogRef,
  MatCheckboxChange,
  MatSelectChange,
  MatDialog,
} from "@angular/material";
import { NgForm } from "@angular/forms";
import { RoleMapModalComponent } from "../../role-map/modals/role-map-modal/role-map-modal.component";
import { SearchService } from "../../services/search.service";
import { NotificationService } from "../../services/notification.service";
import { HttpErrorResponse } from "@angular/common/http";
import { DialogService } from "../../services/dialog.service";
import { WidgetService, widgetType } from "src/app/services/widget.service";
import { WidgetConfigModalComponent } from "./widget-config/widget-config.modal.component";
import { SpinnerService } from "src/app/services/spinner.service";
import { SiteService } from "src/app/services/site.service";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-search-filter",
  templateUrl: "./widget-selection.modal.component.html",
  styleUrls: ["./widget-selection.modal.component.css"],
})
export class WidgetSelectionModalComponent implements OnInit {
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  @ViewChild("filterForm") filterForm: NgForm;

  protected step = 0;
  protected loaded = true;

  protected isWidgetLoading = true;
  protected largeCharts: Array<any> = [];
  protected smallCharts: Array<any> = [];

  protected selectedWidgetID = null;

  // widgetGroups: Array<any> = [
  //   {
  //     category: "Pass",
  //     widget: [
  //       {
  //         value: 1,
  //         viewValue: "Drive On Visitor Checked In",
  //         type: "SingleValue",
  //       },

  //       {
  //         value: 2,
  //         viewValue: "Total Drive-On Visitors Expected Today",
  //         type: "SingleValue",
  //       },
  //     ],
  //   },
  //   {
  //     category: "Parking Lot Group",
  //     widget: [
  //       {
  //         value: 3,
  //         viewValue: "Parking Lot Group Analytics",
  //         type: "SingleValue",
  //       },
  //     ],
  //   },
  //   {
  //     category: "Entry Point",
  //     widget: [
  //       {
  //         value: 3,
  //         viewValue: "Parking Lot Group Analytics",
  //         type: "LineChart",
  //       },
  //     ],
  //   },
  // ];
  protected appLanguage;
  public screenLabels: Array<string> = [];
  constructor(
    public dialogRef: MatDialogRef<WidgetSelectionModalComponent>,
    public searchService: SearchService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private dialog: MatDialog,
    protected widgetService: WidgetService,
    private spinnerService: SpinnerService,
    protected siteService:SiteService,
    protected messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.widgetService
      .getAllWidgetsAPI()
      .then(() => (this.isWidgetLoading = false))
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );


//labels by message service
if (localStorage.getItem('WidgetSelectionlLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('WidgetSelectionlLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Select Widget Modal",
      "Widget"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('WidgetSelectionlLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  onMainHeaderClose() {
    this.freezePanel.open();
  }

  categoryChanged(event: MatSelectChange) {
    const category = event.value;
    console.log("Category Changed: " + category);

    this.selectedWidgetID = null;

    // filter charts
    this.largeCharts = this.widgetService
      .getAllWidgets()
      .filter((c) => c.Category === category)[0]
      .Widget.filter(
        (w) =>
          w.Type === widgetType.BarChartWidget ||
          w.Type === widgetType.PieChartWidget ||
          w.Type === widgetType.LineChartWidget
      );

    this.smallCharts = this.widgetService
      .getAllWidgets()
      .filter((c) => c.Category === category)[0]
      .Widget.filter((w) => w.Type === widgetType.SingleValueWidget);
  }

  selectedWidgetChange(event) {
    const value = event.target.value;

    console.log(this.smallCharts);

    this.selectedWidgetID = value;
    console.log("Create Widget: " + value);
  }

  public async addWidget() {
    const isSmallWidget = this.smallCharts.find(
      (chart) => chart.Value === +this.selectedWidgetID
    );

    // console.log(isSmallWidget);
    // console.log("Its a small chart: ", isSmallWidget ? true : false);

    if (isSmallWidget) {
      // call the create widget API to create large widget
      this.widgetService
        .CreateUpdateWidgetAPI(this.selectedWidgetID, -1, -1, true)
        .then(() => this.widgetService.updateWidgets.emit(true));
    } else {
      // call the create widget API to create large widget
      this.widgetService
        .CreateUpdateWidgetAPI(this.selectedWidgetID, -1, -1, false)
        .then(async (userWidgetID: number) => {
          console.log("User Widget Created: ", userWidgetID);

          this.spinnerService.setIsLoading(true);
          // get the initial widget data
          const widgetData = await this.widgetService.getAssignedUserWidgetByIDAPI(
            userWidgetID
          );
          console.log("Widget Data: ", widgetData);
          this.spinnerService.setIsLoading(false);

          // setting flag to mark it as a new widget
          widgetData[0].isNewWidget = true;

          // open widget configure dialog
          const dialogRef = this.dialog
            .open(WidgetConfigModalComponent, {
              disableClose: true,
              data: widgetData[0],
              backdropClass: "backdropBackground",
            })
            .afterClosed()
            .subscribe((confirm: boolean) => {
              console.log("Dialog Confirmation: ", confirm);

              // activate the newly created widget
              if (confirm) {
                this.widgetService.CreateUpdateWidgetAPI(
                  this.selectedWidgetID,
                  -1,
                  -1,
                  true,
                  userWidgetID
                );

                this.widgetService.updateWidgets.emit(true);
              }
            });
        });
    }

    this.onNoClick();
  }
}
