import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { DialogService } from "../services/dialog.service";
import { NotificationService } from "../services/notification.service";
import { SearchService } from "../services/search.service";
import { ParkingLotGroup } from "../models/parking-lot-group.model";
import { ViewParkingGroupModalComponent } from "./modals/view-parking-group/view-parking-group-modal.component";
import { CreateParkingGroupModalComponent } from "./modals/create-parking-group/create-parking-group-modal.component";
import { UpdateParkingGroupModalComponent } from "./modals/update-parking-group/update-parking-group-modal.component";
import { ParkingLotGroupService } from "../services/parking-lot-group.service";
import { HttpErrorResponse } from "@angular/common/http";
import { UserService } from "../services/user.service";
import { SiteService } from "../services/site.service";
import { GridApi } from "ag-grid-community";
import { MessageService } from "../services/message.service";

@Component({
  selector: "app-parking-lot-group",
  templateUrl: "./parking-lot-group.component.html",
  styleUrls: ["./parking-lot-group.component.css"],
})
export class ParkingLotGroupComponent implements OnInit {
  protected isRowSelected: boolean;
  protected rowData: ParkingLotGroup[];
  protected rowClassRules;
  private paginationPageSize;
  protected parkingLotGroupColumnDefs = [];
  private selectedParkingLotGroup: ParkingLotGroup;
  private gridApi: GridApi;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  public isActive : boolean = false;
  
  public defaultColDef : object;
  constructor(
    protected dialog: MatDialog,
    protected dialogService: DialogService,
    protected notificationService: NotificationService,
    protected searchService: SearchService,
    protected parkingLotGroupService: ParkingLotGroupService,
    protected userService: UserService,
    private siteService: SiteService,
    protected messageService: MessageService,
  ) {
    this.paginationPageSize = 50;
    // set row styles
    this.rowClassRules = {
      "row-inactive": (params) => {
        var condition = params.data.Active === 0;
        return condition;
      },
    };
    this.defaultColDef = {
      resizable: true,
  }
  }

  ngOnInit(): void {
    this.searchService.setPageName("Parking Lot Group");

    //labels by message service
    if (localStorage.getItem('ParkingLotGroupLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('ParkingLotGroupLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Parking Lot Group",
        "ParkingLotGroup"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('ParkingLotGroupLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        this.isRowSelected = false;
        this.rowData = this.searchService.searchRecords;
        this.gridApi.paginationGoToPage(0);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    this.searchService.searchInitiated.subscribe((eventData: boolean) => {
      if (eventData) {
        this.searchService.smartSearchPromise
          .then(() => {
            this.rowData = this.searchService.searchRecords;
            this.gridApi.paginationGoToPage(0);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    });

    // subscribe to site changed
    this.siteService.siteChanged.subscribe((event: boolean) => {
      if(event){
        this.searchService
        .smartSearchMultiSiteAPI(null)
        .then(() => {
          this.rowData = this.searchService.searchRecords;   
          this.gridApi.paginationGoToPage(0);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
      }
    });

    this.parkingLotGroupService.parkingLotGroupDataChanged.subscribe((e) => {
      this.searchService
        .smartSearchMultiSiteAPI(null)
        .then(() => {
          this.rowData = this.searchService.searchRecords;
          this.gridApi.paginationGoToPage(0);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    });

    
  }

  setColumnDef() {
    this.parkingLotGroupColumnDefs = [
      {
        headerName: "",
        field: "",
        width: 80,
        cellStyle: { color: "#589bf8" },
        hide: false,
        checkboxSelection: true,
        resizable: false,
        sortable: false,
        lockPosition: true,
      },
      {
        headerName: this.screenLabels["ParkingLotGroupID"]
        ? this.screenLabels["ParkingLotGroupID"]
        : "Parking Lot Group ID",
        field: "ParkingLotGroupID",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridName"]
          ? this.screenLabels["AgGridName"]
          : "Name",
        field: "Name",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridDescription"]
          ? this.screenLabels["AgGridDescription"]
          : "Description",
        field: "Description",
        hide: false,
        sortable: true,
      },
      {
        headerName:this.screenLabels["EmailDirections"]
        ? this.screenLabels["EmailDirections"]
        : "Email Directions", 
        field: "EmailDirections",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridRegulated"]
          ? this.screenLabels["AgGridRegulated"]
          : "Regulated",
        field: "Regulated",
        hide: false,
        sortable: true,
        cellRenderer: (data) => {
          return data.value ? "Yes" : "No";
        },
      },
      {
        headerName: this.screenLabels["AgGridTargetCapacity"]
          ? this.screenLabels["AgGridTargetCapacity"]
          : "Target Capacity",
        field: "TargetCapacity",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["Active"]
        ? this.screenLabels["Active"]
        : "Active",
        field: "Active",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["ModifiedDate"]
        ? this.screenLabels["ModifiedDate"]
        : "Modified Date", 
        field: "ModifiedDate",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["ModifiedBy"]
        ? this.screenLabels["ModifiedBy"]
        : "Modified By", 
        field: "ModifiedBy",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["CreatedDate"]
        ? this.screenLabels["CreatedDate"]
        : "Created Date", 
        field: "CreatedDate",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["CreatedBy"]
        ? this.screenLabels["CreatedBy"]
        : "Created By", 
        field: "CreatedBY",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["CreatedByName"]
        ? this.screenLabels["CreatedByName"]
        : "Created By Name",
        field: "CreatedByName",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["ModifiedByName"]
        ? this.screenLabels["ModifiedByName"]
        : "Modified By Name", 
        field: "ModifiedByName",
        hide: true,
        sortable: true,
      },
    ];
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDef()
  }

  // make ag-rid colums fill their container
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  // sets the data of selected row
  onSelectionChanged($event) {
    const selectedRows: ParkingLotGroup[] = $event.api.getSelectedRows();
    if (selectedRows.length) {
      this.selectedParkingLotGroup = selectedRows[0];
      this.isRowSelected = true;
    } else {
      this.isRowSelected = false;
    }

    if(this.selectedParkingLotGroup.Active === 0){
      this.isActive = false;
    } else {
      this.isActive = true;
    }
  }

  // open selected parking lot group
  EditParkingLotGroup() {
    this.dialog.open(UpdateParkingGroupModalComponent, {
      disableClose: true,
      data: this.selectedParkingLotGroup,
    });
  }

  // open modal for creating a new parking lot group
  onCreateNewParkingLotGroup() {
    this.dialog.open(CreateParkingGroupModalComponent, {
      disableClose: true,
      data: {},
    });
  }

  // open selected parking Lot Group
  openParkingLotGroup($event) {
    var selectedParkingLotGroup: ParkingLotGroup = $event.data;
    this.dialog.open(ViewParkingGroupModalComponent, {
      disableClose: true,
      data: selectedParkingLotGroup,
    });
  }

  // Disable Parking Lot Group
  DeleteParkingLotGroup() {
    var name = this.selectedParkingLotGroup.Name;
    var message: string =
      "Are you sure you want to Delete Parking Lot Group " + name + " ?";
    this.dialogService
      .openConfirmDialog(this.screenLabels["DeleteParkingLotGroupConfirmMsg"]? this.screenLabels["DeleteParkingLotGroupConfirmMsg"] : "Delete Parking Lot Group", message)
      .afterClosed()
      .subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.parkingLotGroupService
            .deleteParkingLotGroup(this.selectedParkingLotGroup)
            .then(() => {
              this.notificationService.success(
                this.screenLabels["DeleteParkingLotGroupSuccessMsg"]? this.screenLabels["DeleteParkingLotGroupSuccessMsg"] : "Parking Lot Group was Deleted Successfully."
              );
            });
        }
      });
  }
}
