import { Injectable, Output, EventEmitter } from "@angular/core";
import { EntryPointZone } from "../models/entry-point-zone.model";
import { EntryPointGroupMap } from "../models/entry-point-group-map.model";
import { UserService } from "./user.service";
import { NotificationService } from "./notification.service";
import { HttpHeaders, HttpResponse, HttpClient } from "@angular/common/http";
import { EntryPoint } from "../models/entry-point.model";
import { environment } from "src/environments/environment";
import { SiteService } from "./site.service";

@Injectable({
  providedIn: "root",
})
export class EntryPointZoneService {
  private entryPointZones: EntryPointZone[];
  public entryPointZoneAPIResponse: Array<{
    EntryPointZoneID: number;
    Name: string;
    Description: string;
    Active: number;
    Regulated: string;
    TargetCapacity: number;
    ModifiedBy: string;
    ModifiedDate: Date;
    CreatedBy: string;
    CreatedDate: Date;
  }>;
  public message: string;

  @Output() entryPointZoneDataChanged: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
    private http: HttpClient,
    private siteService: SiteService
  ) {
    this.entryPointZones = [];
  }

  createEntryPointZoneAPI(entryPointZone: EntryPointZone): Promise<any> {
    const apiURL = environment.getAPI("entryPointZoneCreate");

    const postBody = {
      Name: entryPointZone.Name,
      Description: entryPointZone.Description,
      Regulated: entryPointZone.Regulated,
      TargetCapacity: entryPointZone.TargetCapacity,
      Active: 1,
      UserID: this.userService.userID,
      SiteID: this.siteService.selectedSiteID,
    };
    console.log(postBody);
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
            this.message = success.body.Message;
            console.log(success.body);
            this.notificationService.success(this.message);
            this.entryPointZoneDataChanged.emit(true);
            console.log(success.body);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  getAllEntryPointZonesAPI(): Promise<any> {
    const apiURL = environment.getAPI("getEntryPointZone");

    const postBody = {
      EntryPointZoneID: "",
      SiteID: this.siteService.selectedSiteID,
    };
    console.log(postBody);
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.entryPointZoneAPIResponse = success.body;
            this.entryPointZones = new Array<EntryPointZone>();

            this.entryPointZoneAPIResponse.forEach((element) => {
              let zone = new EntryPointZone(
                element.EntryPointZoneID,
                element.Name,
                element.Description,
                element.Regulated ? true : false,
                element.TargetCapacity,
                element.Active ? 1 : 0,
                element.ModifiedBy ? +element.ModifiedBy : null,
                new Date(element.ModifiedDate),
                element.CreatedBy ? +element.CreatedBy : null,
                new Date(element.CreatedDate)
              );
              this.entryPointZones.push(zone);
            });
            console.log(success.body);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public getEntryPointZone(): EntryPointZone[] {
    return [...this.entryPointZones];
  }

  updateEntryPointZoneAPI(entryPointZone: EntryPointZone): Promise<any> {
    const apiURL = environment.getAPI("entryPointZoneUpdate");

    const postBody = {
      EntryPointZoneID: entryPointZone.EntryPointZoneID,
      Name: entryPointZone.Name,
      Description: entryPointZone.Description,
      Regulated: entryPointZone.Regulated,
      TargetCapacity: entryPointZone.TargetCapacity,
      Active: entryPointZone.Active,
      UserID: this.userService.userID,
      SiteID: this.siteService.selectedSiteID,
    };
    console.log("Post Body:");
    console.log(postBody);
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
            console.log(success.body);
            this.notificationService.success(this.message);
            this.entryPointZoneDataChanged.emit(true);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public deleteEntrypointZone(entryPointZone: EntryPointZone) {
    entryPointZone.Active = 0; //disabling entry point zone
    return this.updateEntryPointZoneAPI(entryPointZone).then(() =>
      this.entryPointZoneDataChanged.emit(true)
    );
  }
}
