import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'app-button-renderer',
  template: `<button class="button" type="button" (click)="onClick($event)">Link</button>`,
  styles:[`
  .button{
    background-color: rgba(0, 77, 180, 0.8) !important;
    color: white;
    border-radius: 10px;
    background: rgba(0, 77, 180, 0.8);
    height: 25px;
    margin-bottom: 25px;
  }`]

})
export class ButtonRendererComponent implements ICellRendererAngularComp {
 
params;

  agInit(params): void {
    this.params = params;
  }

  onClick($event) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.onClick(params);
    }
  }

  refresh(params?: any): boolean {
    return true;
  }
}
