import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ApprovalGroup } from '../models/approval-group-model';
import { DialogService } from '../services/dialog.service';
import { NotificationService } from '../services/notification.service';
import { SearchService } from '../services/search.service';
import { SiteService } from '../services/site.service';
import { UserService } from '../services/user.service';
import { CreateApprovalGroupComponent } from './modals/create-approval-group/create-approval-group.component';
import { UpdateApprovalGroupComponent } from './modals/update-approval-group/update-approval-group.component';
import { ViewApprovalGroupComponent } from './modals/view-approval-group/view-approval-group.component';
import { ApprovalGroupService } from '../services/approval-group.service';
import { GridApi } from 'ag-grid-community';
import { MessageService } from '../services/message.service';

@Component({
  selector: 'app-approval-group',
  templateUrl: './approval-group.component.html',
  styleUrls: ['./approval-group.component.css']
})
export class ApprovalGroupComponent implements OnInit {

  protected isRowSelected: boolean;
  protected rowData: ApprovalGroup[];
  protected rowClassRules;
  protected approvalGroupColumnDefs = [];
  private selectedApprovalGroup: ApprovalGroup;
  public gridApi: GridApi;
  public paginationPageSize;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  public isActive: boolean = false;
  protected activeConfigCount: number;
  protected pendingAGRCount: number;
  constructor(
    protected dialog: MatDialog,
    protected dialogService: DialogService,
    protected notificationService: NotificationService,
    protected searchService: SearchService,
    protected approvalGroupService: ApprovalGroupService,
    protected userService: UserService,
    private siteService: SiteService,
    protected messageService: MessageService
  ) {
    this.paginationPageSize = 50;
    // set row styles
    this.rowClassRules = {
      "row-inactive": (params) => {
        var condition = params.data.Active === 0;
        return condition;
      },
    };
  }

  ngOnInit(): void {
    this.searchService.setPageName("Approval Group");


    //labels by message service
    if (localStorage.getItem('ApprovalGroupLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('ApprovalGroupLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Approval Group",
        "ApprovalGroup"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('ApprovalGroupLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        this.isRowSelected = false;
        this.rowData = this.searchService.searchRecords;

        this.gridApi.paginationGoToPage(0);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    this.searchService.searchInitiated.subscribe((eventData: boolean) => {
      if (eventData) {
        this.searchService.smartSearchPromise
          .then(() => {
            this.rowData = this.searchService.searchRecords;
            this.gridApi.paginationGoToPage(0);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    });

    // subscribe to site changed
    this.siteService.siteChanged.subscribe((event: boolean) => {
      if (event) {
        this.searchService.smartSearchPromise
          .then(() => {
            this.rowData = this.searchService.searchRecords;
            this.gridApi.paginationGoToPage(0);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    });


    this.approvalGroupService.approvalGroupDataChanged.subscribe((e) => {
      this.searchService.setPageName("Approval Group");
      this.searchService
        .smartSearchMultiSiteAPI(null)
        .then(() => {
          this.rowData = this.searchService.searchRecords;
          this.isRowSelected = false;
          this.gridApi.paginationGoToPage(0);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    });

    this.approvalGroupColumnDefs = [
      {
        headerName: "",
        field: "",
        width: 80,
        cellStyle: { color: "#589bf8" },
        hide: false,
        checkboxSelection: true,
        resizable: false,
        sortable: false,
        lockPosition: true,
      },
      {
        headerName: this.screenLabels["AgGridSiteID"] ? this.screenLabels["AgGridSiteID"] : "SiteID",
        field: "SiteID",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridName"] ? this.screenLabels["AgGridName"] : "Name",
        field: "Name",
        hide: false,
        resizable: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridDescription"] ? this.screenLabels["AgGridDescription"] : "Description",
        field: "Description",
        hide: false,
        resizable: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridActive"] ? this.screenLabels["AgGridActive"] : "Active",
        field: "Active",
        hide: true,
        resizable: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridUserID"] ? this.screenLabels["AgGridUserID"] : "UserID",
        field: "UserID",
        hide: true,
        resizable: true,
        sortable: true,
      }
    ];
  }


  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }

  // make ag-rid colums fill their container
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  // sets the data of selected row
  onSelectionChanged($event) {
    const selectedRows: ApprovalGroup[] = $event.api.getSelectedRows();
    if (selectedRows.length) {
      this.selectedApprovalGroup = selectedRows[0];
      this.isRowSelected = true;
    } else {
      this.isRowSelected = false;
    }

    if (this.selectedApprovalGroup.Active === 0) {
      this.isActive = false;
    } else {
      this.isActive = true;
    }
  }

  // open selected approval group
  EditApprovalGroup() {
    this.approvalGroupService
      .getApprovalGroupById(this.selectedApprovalGroup.ApprovalGroupID)
      .then((res) => {
        const approvalGroup = <ApprovalGroup>res;
        this.selectedApprovalGroup.Users = approvalGroup.Users;
        this.selectedApprovalGroup.UserID = this.userService.userID;
        this.dialog.open(UpdateApprovalGroupComponent, {
          disableClose: true,
          data: this.selectedApprovalGroup,
        });
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }

  // open modal for creating a new approval group
  onCreateNewApprovalGroup() {
    this.dialog.open(CreateApprovalGroupComponent, {
      disableClose: true,
      data: {},
    });
  }

  // open selected parking Lot Group
  openApprovalGroup($event) {
    this.selectedApprovalGroup = $event.data;
    this.approvalGroupService
      .getApprovalGroupById(this.selectedApprovalGroup.ApprovalGroupID)
      .then((res) => {
        const approvalGroup = <ApprovalGroup>res;
        this.selectedApprovalGroup.Users = approvalGroup.Users;
        if(this.approvalGroupService.viewApprovalGroupOpen) return;
        this.dialog.open(ViewApprovalGroupComponent, {
          disableClose: true,
          data: this.selectedApprovalGroup,
        });
        this.approvalGroupService.viewApprovalGroupOpen = true;
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }

  // Disable Approval Group
  deleteApprovalGroup() {
    const approvalGroupID = this.selectedApprovalGroup.ApprovalGroupID;
    this.approvalGroupService.getApprovalGroupUseCount(approvalGroupID).subscribe((res: any) => {
      this.activeConfigCount = res.body[0].activeApprovalConfigCount;
      this.pendingAGRCount = res.body[0].pendingAGRCount;
      if (this.activeConfigCount != 0 || this.pendingAGRCount != 0) {
        let message = this.selectedApprovalGroup.Name + " can't be deleted..<br>Actively used in the following"

        if (this.pendingAGRCount > 0 && this.activeConfigCount === 0) 
          message += "<br>Pending Approval Group Count " + this.pendingAGRCount;
        else if (this.activeConfigCount > 0 && this.pendingAGRCount === 0)
          message += "<br> Active Approval Config Count " + this.activeConfigCount;
        else 
          message += "<br>Pending Approval Group Count " + this.pendingAGRCount +
            "<br> Active Approval Config Count " + this.activeConfigCount;
      
        this.dialogService.openAlertDialogDialog(
          "Warning",message
        );

      } else {
        this.selectedApprovalGroup.UserID = this.userService.userID;
        var name = this.selectedApprovalGroup.Name;
        var message: string =
          this.screenLabels["DeleteApprovalGroupConfirmMsg"] ? this.screenLabels["DeleteApprovalGroupConfirmMsg"] : "Are you sure you want to Delete Approval <<id>> ?"
        const capacityMessage = message.replace("<<id>>", name)
        this.dialogService
          .openConfirmDialog(this.screenLabels["DeleteApprovalGroupconfirmLabel"] ? this.screenLabels["DeleteApprovalGroupconfirmLabel"] : "Delete Approval Group", capacityMessage)
          .afterClosed()
          .subscribe((isConfirmed: boolean) => {
            if (isConfirmed) {
              this.approvalGroupService
                .getApprovalGroupById(this.selectedApprovalGroup.ApprovalGroupID)
                .then((res) => {
                  const approvalGroup = <ApprovalGroup>res;
                  this.selectedApprovalGroup.Users = [];
                  approvalGroup.Users.forEach(user => {
                    this.selectedApprovalGroup.Users.push({
                      "AGUserID": user.UserID,
                      "Active": user.Active
                    });
                  })
                  this.approvalGroupService
                    .deleteApprovalGroup(this.selectedApprovalGroup);
                })
                .catch((err: HttpErrorResponse) => {
                  this.notificationService.failure(err.message);
                });
            }
          });
      }
    })
  }

}
