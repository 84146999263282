import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Inject,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { RoleMapService } from "../../../services/role-map.service";
import { IFunctionData } from "../../../models/interfaces/function-data.model";
import { MatExpansionPanel, MatCheckboxChange } from "@angular/material";
import { FormGroup, FormControl, Validators, NgForm } from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";
import { NotificationService } from "../../../services/notification.service";
import { DialogService } from "../../../services/dialog.service";
import { SiteService } from "src/app/services/site.service";
import { RequiredFieldsService } from 'src/app/services/required-fields.service';
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-role-map-modal",
  templateUrl: "./role-map-update-modal.component.html",
  styleUrls: ["./role-map-update-modal.component.css"],
})
export class RoleMapUpdateModalComponent implements OnInit {
  @ViewChild("freePanel") freezePanel: MatExpansionPanel;
  @ViewChild("createForm", {static:true}) createForm: NgForm;

  // rolenameFormControl = new FormControl("", [Validators.required]);
  // descriptionFormControl = new FormControl("", [Validators.required]);
  protected appLanguage;
  public screenLabels: Array<string> = [];
  protected step = 0;
  protected loaded = false;
  protected roleSaved = false;
  protected roleName: string;
  protected description: string;
  protected roleMapping: {
    RoleID: number;
    RoleName: string;
    Notes: string;
    Active: number;
    ModifiedBy: number;
    ModifiedDate: string;
    CreatedBy: number;
    CreatedDate: string;
  };

  protected functionData: Array<{
    FunctionID: number;
    Tag: string;
    Label: string;
    Description: string;
    DisplayOrder: number;
    FunctionCategoryID: number;
    Category: string;
    CatDisplayOrder: number;
  }>;

  public categories; // holds distinct list of categories
  protected functionsChecked: Array<{
    CategoryID: number;
    FunctionID: number;
    Active: number;
  }> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      RoleID: number;
      RoleName: string;
      Notes: string;
      Active: number;
      ModifiedBy: number;
      ModifiedDate: string;
      CreatedBy: number;
      CreatedDate: string;
    },
    public dialogRef: MatDialogRef<RoleMapUpdateModalComponent>,
    protected roleMapService: RoleMapService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    protected siteService:SiteService,
    protected requiredFieldsService : RequiredFieldsService,
    protected messageService: MessageService,
  ) {
    this.functionData = [];
    this.roleMapping = { ...this.data };
  }

  ngOnInit(): void {
    this.roleMapService
      .getRoleMappingsAPI(this.roleMapping.RoleID)
      .then(() => {
        this.roleMapService.getFunctions().then(() => {
          this.categories = [
            ...new Set(
              this.roleMapService.functionApiData
                .sort((a, b): number => {
                  return a.CatDisplayOrder < b.CatDisplayOrder ? -1 : 1;
                })
                .map((e) => e.Category)
            ),
          ];

          this.functionData = [
            ...this.roleMapService.functionApiData.sort((a, b): number => {
              return a.DisplayOrder < b.DisplayOrder ? -1 : 1;
            }),
          ];

          this.functionsChecked = this.roleMapService.getSelectedFunctions(
            this.data.RoleID
          );

          this.loaded = true;
          // console.log(this.functionData);
        });
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    //labels by message service
    if (localStorage.getItem('UpdateRoleMapLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('UpdateRoleMapLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Update Role Modal",
        "Roles"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('UpdateRoleMapLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  ngAfterViewChecked() {
    // setting up the required fields
    this.requiredFieldsService.setRequiredFields(
      'Edit Role',
      this.createForm
    );
  }
  // roledataForm: FormGroup = new FormGroup({
  //   rolename: this.rolenameFormControl,
  //   // description: this.descriptionFormControl,
  // });
  // getRoleRequiredErrorMessage() {
  //   return this.rolenameFormControl.hasError("required")
  //     ? "You must enter a value"
  //     : " ";
  // }
  // getDescRequiredErrorMessage() {
  //   return this.descriptionFormControl.hasError("required")
  //     ? "You must enter a value"
  //     : " ";
  // }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  onMainHeaderClose() {
    this.freezePanel.open();
  }

  // Function invoked when Role Function is saved
  onSaveRole() {
    // console.log("Role Name: " + this.roleName);
    // console.log("Description: " + this.description);
    // console.log(this.functionsChecked);

    const name = this.roleMapping.RoleName.trim() === this.roleMapping.RoleName ? this.roleMapping.RoleName : this.roleMapping.RoleName.trim();
    this.roleMapping.RoleName = name ;
    const notes = this.roleMapping.Notes.trim() === this.roleMapping.Notes ? this.roleMapping.Notes : this.roleMapping.Notes.trim();
    this.roleMapping.Notes = notes ;

    this.dialogService
      .openConfirmDialog(
        this.screenLabels["UpdateRole"]? this.screenLabels["UpdateRole"]:"Update Role",
        this.screenLabels["SaveConfirmMsg"]? this.screenLabels["SaveConfirmMsg"]: "Are you sure you want to save the changes ?"
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
        this.roleMapService
          .updateRoleMapAPI(
            this.data.RoleID,
            this.roleMapping.RoleName,
            this.roleMapping.Notes,
            true,
            this.functionsChecked
          )
          .then(() => {
            this.notificationService.success(this.roleMapService.message);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.success(err.message);
          });
        }
        this.onNoClick();
      });
  }

  isRoleNameEmpty(): boolean {
    return !this.roleMapping.RoleName || !this.roleMapping.RoleName.trim();
  }
  isNotesEmpty(): boolean {
    return !this.roleMapping.Notes || !this.roleMapping.Notes.trim();
  }

  nameFormControl = new FormControl("", [
    Validators.required,  // Required validation
    Validators.pattern(/^[a-zA-Z\s-][a-zA-Z\s0-9-]*$/),  // Pattern validation for only alphabets and spaces , hyphen
    this.specialCharacterValidator(),
  ]);

  notesFormControl = new FormControl("", [
    Validators.required,  // Required validation
    Validators.pattern(/^(?!\s)(?!.*-\s).*$/),  // Pattern validation for only alphabets and spaces , hyphen
    //this.specialCharacterValidator(),
  ]);

  // Custom validator function
  specialCharacterValidator() {
    return (control: FormControl) => {
      const value = control.value;
      if (!value) {
        return null; // No validation for empty values
      }

      // Check for spaces or hyphens followed by spaces
      if (/^\s/.test(value)) {
        return { specialCharactersNotAllowed: true };
      }
  
      const isValid = /^[a-zA-Z\s0-9]*(-[a-zA-Z\s0-9]+)*$/.test(value);
  
      if (isValid && !/^-/.test(value)) {
      return null;
    };
  return { specialCharactersNotAllowed: true };
    }
  }

  public getFunctionData(): void {
    this.roleMapService.getFunctions();
  }

  // invokes when ever  the check box value is changed
  chkBoxValueChanged(
    categoryID: number,
    functionID: number,
    $event: MatCheckboxChange
  ) {
    const foundFilterObj = this.functionsChecked.filter(
      (fc) => fc.FunctionID == functionID && fc.CategoryID == fc.CategoryID
    );

    const tuple = {
      CategoryID: categoryID,
      FunctionID: functionID,
      Active: $event.checked ? 1 : 0,
    };

    if ($event.checked) {
      if (!foundFilterObj.length) this.functionsChecked.push(tuple);
      else foundFilterObj[0].Active = 1;
    } else {
      foundFilterObj[0].Active = 0;
      console.log(this.functionsChecked);
      // this.functionsChecked[foundIndex].Active = 0;
      // console.log(this.functionsChecked);
    }
  }

  isFunctionIDAssociated(functionID: number): boolean {
    if (
      this.functionsChecked.filter(
        (fc) => fc.FunctionID == functionID && fc.Active
      ).length
    )
      return true;
    else return false;
  }
}
