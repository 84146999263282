import { Component, OnInit, Inject, DoCheck, ViewChild } from "@angular/core";

import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatExpansionPanel,
} from "@angular/material";
import { EntryPointStation } from "../../../models/entry-point-station.model";
import { EntryPoint } from "../../../models/entry-point.model";
import { EntryPointService } from "../../../services/entry-point.service";
import { NotificationService } from "../../../services/notification.service";
import { UpdateParkingGroupModalComponent } from "../update-parking-group/update-parking-group-modal.component";
import { ParkingLot } from "../../../models/parking-lot.model";
import { ParkingLotGroup } from "../../../models/parking-lot-group.model";
import { SiteService } from "src/app/services/site.service";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-view-parking-group-modal",
  templateUrl: "./view-parking-group-modal.component.html",
  styleUrls: ["./view-parking-group-modal.component.css"],
})
export class ViewParkingGroupModalComponent implements OnInit {
  protected step = 0;
  protected parkingLotGroups: ParkingLotGroup;
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  protected appLanguage;
  public screenLabels: Array<string> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ParkingLotGroup,
    protected dialogRef: MatDialogRef<UpdateParkingGroupModalComponent>,
    protected entryPointService: EntryPointService,
    protected notificationService: NotificationService,
    private siteService: SiteService,
    protected messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.parkingLotGroups = { ...this.data };
    console.log(this.data);

    //labels by message service
    if (localStorage.getItem('ViewParkingLotGroupLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('ViewParkingLotGroupLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "View Parking Lot Group Modal",
        "ParkingLotGroup"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('ViewParkingLotGroupLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }
}
