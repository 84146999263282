import { CommonConfirmDialogComponent } from "./../common-confirm-dialog/common-confirm-dialog.component";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material";
import { CommonAlertDialogComponent } from "../common-alert-dialog/common-alert-dialog.component";
import { DeactivationDialogComponent } from "../deactivation-dialog/deactivation-dialog.component";

@Injectable({
  providedIn: "root",
})
export class DialogService {
  constructor(private dialog: MatDialog) {}
  message: string;
  title: string;
  message1: string;
  message2: string;
  dataArray: any;

  // opens confirm dialog box
  openConfirmDialog(title: string, messsag: any) {
    return this.dialog.open(CommonConfirmDialogComponent, {
      width: "400px",
      height: "300px",
      panelClass: "commonconfirm-dialog-container",
      disableClose: true,
      position: { top: "19%" },
      data: {
        title: title,
        message: messsag,
      },
      backdropClass:'backdropBackground'
    });
  }

  // display alert popup
  openAlertDialogDialog(
    title: string,
    html: string,
    w: string = "400px",
    h: string = "200px"
  ) {
    return this.dialog.open(CommonAlertDialogComponent, {
      panelClass: "commonconfirm-dialog-container",
      position: { top: "19%" },
      data: {
        title: title,
        message: html,
        width: w,
        height: h,
      },
    });
  }

  //opens deactivation dailog
  openDeactivationDialog(arrayValues: any, title: string, messsage1: string, messsage2: string) {
    return this.dialog.open(DeactivationDialogComponent, {
      width: "800px",
      height: "500px",
      panelClass: "commonconfirm-dialog-container",
      disableClose: true,
      position: { top: "19%" },
      data: {
        dataArray: arrayValues,
        title: title,
        message1: messsage1,
        message2: messsage2,
      },
      backdropClass:'backdropBackground'
    });
  }
}
