import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Environment } from 'ag-grid-community';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class ApprovalRequestConfigurationService {

  @Output() approvalRequestConfigDataChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  public httpOptions: { headers; observe } = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
    observe: "response",
  };
  constructor(
    private http: HttpClient,
    private notificationService: NotificationService
  ) {
    this.approvalRequestConfigDataChanged = new EventEmitter<boolean>();
  }

  public createApprovalRequestConfig(approvalRequestConfig: any) {
    this.approvalRequestConfigUpsert(approvalRequestConfig).then((success: any) => {
      this.notificationService.success(
        "Approval Request Configuration Created Successfully."
      );
      this.approvalRequestConfigDataChanged.emit(true);
    });
  }
  
  // Method to update a Parking Lot Group by id
  public updateApprovalRequestConfig(approvalRequestConfig: any) {
    this.approvalRequestConfigUpsert(approvalRequestConfig).then((success: any) => {
      this.notificationService.success(
        "Approval Request Configuration Updated Successfully."
      );
      this.approvalRequestConfigDataChanged.emit(true);
    });
  }

  public deleteApprovalRequestConfig(approvalRequestConfig: any) {
    this.approvalRequestConfigUpsert(approvalRequestConfig).then((success: any) => {
      this.notificationService.success(
        "Approval Request Configuration Deleted Successfully."
      );
      this.approvalRequestConfigDataChanged.emit(true);
    });
  }

  public approvalRequestConfigUpsert(approvalRequestConfig: any) {
    const apiURL = environment.getAPI("approvalRequestConfigUpsert");

    const postBody = approvalRequestConfig;
    
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, this.httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            resolve(success.body);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }
  public getApprovalGroupBySiteID(siteID: number, approvalTypeID: number){
    const apiURL= environment.getAPI("getApprovalGroupBySiteID");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      SiteID: siteID,
      ApprovalTypeID: approvalTypeID
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    return res;
  }
  public getApprovalGroupTypeAPI(approvalGroupID: number){
    const apiURL = environment.getAPI("getApprovalGroupType");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      ApprovalGroupID: approvalGroupID,
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    return res;
  }
}
