import {
  Component,
  OnInit,
  Inject
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, Validators, NgForm } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { UserService } from 'src/app/services/user.service';
import { SearchService } from 'src/app/services/search.service';
import { MatChipInputEvent } from '@angular/material';
import { TemplateCategory } from 'src/app/models/template-category,model';
import { TemplateCategoryService } from 'src/app/services/template-category-service';
import { Site, SiteService } from "src/app/services/site.service";
import { MessageService } from "src/app/services/message.service";

export interface User {
  userID: number;
  userName: string;
}

@Component({
  selector: "app-view-template-category-modal",
  templateUrl: "./view-template-category-modal.component.html",
  styleUrls: ["./view-template-category-modal.component.css"],
})
export class ViewTemplateCategoryComponent implements OnInit {
 
  protected templateCategory = new TemplateCategory(0,"",this.userService.organizationID,"",1,this.userService.userID,new Date,this.userService.userID,new Date);
  protected appLanguage;
  public screenLabels: Array<string> = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TemplateCategory,
    public dialogRef: MatDialogRef<ViewTemplateCategoryComponent>,
    protected userService: UserService,
    protected searchService: SearchService,
    protected templateCategoryService: TemplateCategoryService,
    protected siteService:SiteService,
    protected messageService: MessageService,
  ) {
    document.addEventListener(
      "keydown",
      (e) => {
        if ((e.target as any).nodeName === "MAT-EXPANSION-PANEL-HEADER") {
          e.stopImmediatePropagation();
        }
        if ((e.target as any).nodeName === "MAT-DIALOG-CONTAINER") {
          if (this.step != 2) {
            this.nextStep();
          } else {
            return;
          }
        }
      },
      true
    );
   
    
      
  }
  durationInSeconds = 3;
  step = 0;
  disabled = false;
  private fieldArray: Array<any> = [];
  private newAttribute: any = {};

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  nextSteptwo() {
    this.email.markAsTouched();
    if (!this.email.invalid) {
      this.step++;
    }
  }
  email = new FormControl("", [Validators.required, Validators.email]);
  entryPointGroupFormControl = new FormControl("", Validators.required);

  

  ngOnInit() {
    this.templateCategory = { ...this.data };   

    //labels by message service
if (localStorage.getItem('ViewTemplateCategoryLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('ViewTemplateCategoryLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "View Template Category Modal",
      "TemplateCategory"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('ViewTemplateCategoryLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

  }

  
loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}
 
  onNoClick() {
    this.dialogRef.close(false);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;  
     if (input) {
        input.value = '';
      }
    
  }

  onSaveChanges() {
  
   this.templateCategoryService.updateTemplateCategoryAPI(this.templateCategory);
   this.onNoClick();
  }

 
  
  
}
