import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ModuleWithComponentFactories,
  ElementRef,
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, Validators, NgForm } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { EntryPoint } from "src/app/models/entry-point.model";
import { EntryPointGroup } from "src/app/models/entry-point-group.model";
import { Building } from "src/app/models/building.model";
import { ViewSelectorService } from "src/app/services/view-selector.service";
import { PassService } from "src/app/services/pass.service";
import { BuildingService } from "src/app/services/building.service";
import { UserService } from "src/app/services/user.service";
import { NotificationService } from "src/app/services/notification.service";
import { DatePipe } from "@angular/common";
import { EntryPointService } from "src/app/services/entry-point.service";
import { DialogService } from "src/app/services/dialog.service";
import { EntryPointGroupService } from "src/app/services/entry-point-group.service";
import { OrganizationService } from "src/app/services/organization.service";
import { ParkingLotService } from "src/app/services/parking-lot.service";
import { SearchService } from "src/app/services/search.service";
import { RequiredFieldsService } from "src/app/services/required-fields.service";
import { HttpErrorResponse } from "@angular/common/http";
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatChipInputEvent,
  MatRadioChange,
  MatSelectChange,
  MatAutocompleteTrigger,
} from "@angular/material";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { trigger } from "@angular/animations";
import { TemplateCategory } from "src/app/models/template-category,model";
import { TemplateCategoryService } from "src/app/services/template-category-service";
import { SiteService } from "src/app/services/site.service";
import { UtilityService } from "src/app/services/utility.service";
import { MessageService } from 'src/app/services/message.service';

export interface User {
  userID: number;
  userName: string;
}
// export interface Building {
//   name: string;
// }

@Component({
  selector: "app-create-template-category-modal",
  templateUrl: "./create-template-category-modal.component.html",
  styleUrls: ["./create-template-category-modal.component.css"],
})
export class CreateTemplateCategoryComponent implements OnInit {
  @ViewChild("templateCategoryForm") templateCategoryForm: NgForm
  protected templateCategory = new TemplateCategory(
    0,
    "",
    this.userService.organizationID,
    "",
    1,
    this.userService.userID,
    this.utilService.getCurrentSiteDT(),
    this.userService.userID,
    this.utilService.getCurrentSiteDT()
  );
  protected appLanguage;
  public screenLabels: Array<string> = [];

  constructor(
    public dialogRef: MatDialogRef<CreateTemplateCategoryComponent>,
    protected userService: UserService,
    protected searchService: SearchService,
    protected templateCategoryService: TemplateCategoryService,
    protected requiredFieldsService: RequiredFieldsService,
    protected siteService:SiteService,
    private utilService: UtilityService,
    protected requiredFieldService: RequiredFieldsService,
    protected messageService: MessageService,
  ) {
    document.addEventListener(
      "keydown",
      (e) => {
        if ((e.target as any).nodeName === "MAT-EXPANSION-PANEL-HEADER") {
          e.stopImmediatePropagation();
        }
        if ((e.target as any).nodeName === "MAT-DIALOG-CONTAINER") {
          if (this.step != 2) {
            this.nextStep();
          } else {
            return;
          }
        }
      },
      true
    );
  }
  durationInSeconds = 3;
  step = 0;
  disabled = false;
  private fieldArray: Array<any> = [];
  private newAttribute: any = {};

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  nextSteptwo() {
    this.email.markAsTouched();
    if (!this.email.invalid) {
      this.step++;
    }
  }
  email = new FormControl("", [Validators.required, Validators.email]);
  entryPointGroupFormControl = new FormControl("", Validators.required);

  ngOnInit() {


//labels by message service
if (localStorage.getItem('CreateTemplateCategoryLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('CreateTemplateCategoryLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Create Template Category Modal",
      "TemplateCategory"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('CreateTemplateCategoryLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}

  ngAfterViewChecked(){
    this.requiredFieldService.setRequiredFields(
      'Add Template Category', this.templateCategoryForm
    )
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  onSaveChanges() {
    const category = this.templateCategory.Category.trim() === this.templateCategory.Category ? this.templateCategory.Category : this.templateCategory.Category.trim();
    this.templateCategory.Category = category ;
    
    this.templateCategoryService.createTemplateCategoryAPI(
      this.templateCategory
    );
    this.onNoClick();
  }

  isCategoryEmpty(): boolean {
    return !this.templateCategory.Category.trim();
  }

  categoryFormControl = new FormControl("", [
    Validators.required,  // Required validation
    Validators.pattern(/^[a-zA-Z\s-][a-zA-Z\s0-9-]*$/),  // Pattern validation for only alphabets and spaces , hyphen
    this.specialCharacterValidator(),
  ]);

  // Custom validator function
  specialCharacterValidator() {
    return (control: FormControl) => {
      const value = control.value;
      if (!value) {
        return null; // No validation for empty values
      }

      // Check for spaces or hyphens followed by spaces
      if (/^\s/.test(value)) {
        return { specialCharactersNotAllowed: true };
      }
  
      const isValid = /^[a-zA-Z\s0-9]*(-[a-zA-Z\s0-9]+)*$/.test(value);
  
      if (isValid && !/^-/.test(value)) {
      return null;
    };
  return { specialCharactersNotAllowed: true };
    }
  }

}
