import { V } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Inject, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import { MatCheckboxChange, MatDialogRef } from '@angular/material';
import { MatCalendar, MatCalendarCellCssClasses, MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import moment from 'moment';
import { ISiteConfig, ISystemSettingsGetAPIResponse } from '../models/interfaces/system-settings-get-api.model';
import { DialogService } from '../services/dialog.service';
import { HolidayService } from '../services/holiday-calendar.service';
import { NotificationService } from '../services/notification.service';
import { SearchService } from '../services/search.service';
import { SiteService } from '../services/site.service';
import { UtilityService } from '../services/utility.service';
import { MessageService } from '../services/message.service';

interface MonthInterface {
  minDate: any;
  maxDate: any;
  month: number;
}
@Component({
  selector: 'app-holiday-calendar',
  templateUrl: './holiday-calendar.component.html',
  styleUrls: ["./holiday-calendar.component.css"],
  encapsulation: ViewEncapsulation.None
})

export class HolidayCalendarComponent {

  @ViewChild("filterrForm") filterrForm: NgForm;
  
  protected selected: Date | null;
  protected event: any;
  protected yearSelection = 'currentYear' ? 'currentYear' : 'nextYear';
  protected customMinMonth: any[];
  protected customMaxMonth: any[];
  protected monthInterface: MonthInterface[] = [];
  protected nextMonthInterface: MonthInterface[] = [];
  protected isChecking: boolean;
  protected getMonthNew;
  protected newSelectedValues: any[];
  protected selecingtDate1: string = 'highlight-dates';
  protected storingIndexes: number[];
  protected dateClass1: any;
  protected checkingValue: boolean;
  protected selectedList: any[] = [];
  protected notSaved: boolean = true;
  protected saved: boolean = false;
  protected selectedDate: Date | null;
  protected selectedNextDate: Date | null;

  protected msg: string;
  protected msgTwo: string ='';

  private siteResponse: ISystemSettingsGetAPIResponse;
  private _selectedSiteID: number;
  private _selectedSiteSettings: ISiteConfig[];

  protected holidays: any[] = [];
  protected dummyHolidays: any[] =[];
  protected nonWorkingDays: any[] = [];
  protected calendarLoading: boolean = false;

  protected date = new Date()
  protected currentYear = this.date.getFullYear();
  protected nextYear = this.date.getFullYear() + 1;

  @ViewChild(MatCalendar, { static: false }) calendar: MatCalendar<Date>;
  protected appLanguage;
  public screenLabels: Array<string> = [];

  constructor(
    protected _searchService: SearchService,
    protected siteService: SiteService,
    protected holidayService: HolidayService,
    private notificationService: NotificationService,
    private utilService: UtilityService,
    private datePipe: DatePipe,
    private _dialogService: DialogService,
    private router: Router,
    private fb: FormBuilder,
    protected messageService: MessageService,
  ) {
    this._selectedSiteID = this.siteService.selectedSiteID;
    this._selectedSiteSettings = this.siteService.siteSettings.SystemSettings;
    this._searchService.hideSearch();
  }
  
  ngOnInit() {
   // this._searchService.hideSearch();
    console.log("yearselection", this.yearSelection);

//labels by message service
if (localStorage.getItem('CalendarLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('CalendarLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Calendar",
      "Calendar"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('CalendarLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}


    if(this.yearSelection == 'currentYear'){
    this.holidayService.getHolidaysYearAPI().subscribe(response => {
      console.log("RESponse", response);
      let date = this.datePipe.transform(this.utilService.getCurrentSiteDT(), "yyyy-MM-dd");
      response.HolidayDates.forEach(element => {
        //filtering out the present and future holidays
        if (Date.parse(element) >= Date.parse(date)) {
          let originalDate = new Date(element);
          let formatDate = originalDate.toISOString().split('T')[0];
          this.holidays.push(formatDate);
          this.dummyHolidays.push(formatDate);
        }
      })
      response.NonworkingDates.forEach(element => {
        let nonworking = moment(element).format("MM/DD/YYYY");
        let formatted = new Date(nonworking).getTime();
        //console.log("NonWorking Dates",formatted)
        this.nonWorkingDays.push(formatted);
      })
    }, error => {
      console.log(error);
    });
  } else if(this.yearSelection == 'nextYear'){
    this.holidayService.getHolidaysNextAPI().subscribe(response => {
      console.log("RESponse", response);
      let date = this.datePipe.transform(this.utilService.getCurrentSiteDT(), "yyyy-MM-dd");
      response.HolidayDates.forEach(element => {
        //filtering out the present and future holidays
        if (Date.parse(element) >= Date.parse(date)) {
          let formatDate = moment(element).format("YYYY-MM-DD");
          this.holidays.push(formatDate);
          this.dummyHolidays.push(formatDate);
        }
      })
      response.NonworkingDates.forEach(element => {
        let nonworking = moment(element).format("MM/DD/YYYY");
        let formatted = new Date(nonworking).getTime();
        //console.log("NonWorking Dates",formatted)
        this.nonWorkingDays.push(formatted);
      })
    }, error => {
      console.log(error);
    });
  }

    this.gettingYear();
    this.checkboxValidate();
    this.daysAPIArray;

    //highlight functionality should be loaded after service loads
    setTimeout(() => {
      this._disableFunction();
      this._selectionFunction();
    }, 4000);

    setTimeout(() => {
      this.calendarLoading = true;
    }, 3000);

    this.holidayService.getSiteSettingsAPI().then((response) => {
      response.SystemSettings

      this.daysAPIArray.forEach(item => item.value = Number((response.SystemSettings.filter(x => x.Name === item.indexValue)).map(x => x.Value)));
      this.days.forEach(item => item.value = Number((response.SystemSettings.filter(x => x.Name === item.indexValue)).map(x => x.Value)));
    })
    .catch((err: HttpErrorResponse) =>
    this.notificationService.failure(err.message)
  );


  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}

  daysAPIArray: any[] = [
    {
      label: this.screenLabels["Sunday"] ? this.screenLabels["Sunday"]: "Sunday" ,
      value: 0,
      indexValue: "1",
    },
    {
      label: this.screenLabels["Monday"] ? this.screenLabels["Monday"]: "Monday",
      value: 0,
      indexValue: "2",
    },
    {
      label: this.screenLabels["Tuesday"] ? this.screenLabels["Tuesday"]: "Tuesday",
      value: 0,
      indexValue: "3",
    },
    {
      label: this.screenLabels["Wednesday"] ? this.screenLabels["Wednesday"]: "Wednesday",
      value: 0,
      indexValue: "4",
    },
    {
      label: this.screenLabels["Thursday"] ? this.screenLabels["Thursday"]: "Thursday",
      value: 0,
      indexValue: "5",
    },
    {
      label:this.screenLabels["Friday"] ? this.screenLabels["Friday"]: "Friday",
      value: 0,
      indexValue: "6",
    },
    {
      label: this.screenLabels["Saturday"] ? this.screenLabels["Saturday"]: "Saturday",
      value: 0,
      indexValue: "7",
    },
  ]
  days: any[] = [
    {
      label: this.screenLabels["Sunday"] ? this.screenLabels["Sunday"]: "Sunday",
      value: 0,
      indexValue: "1",
    },
    {
      label: this.screenLabels["Monday"] ? this.screenLabels["Monday"]: "Monday",
      value: 0,
      indexValue: "2",
    },
    {
      label: this.screenLabels["Tuesday"] ? this.screenLabels["Tuesday"]: "Tuesday",
      value: 0,
      indexValue: "3",
    },
    {
      label: this.screenLabels["Wednesday"] ? this.screenLabels["Wednesday"]: "Wednesday",
      value: 0,
      indexValue: "4",
    },
    {
      label: this.screenLabels["Thursday"] ? this.screenLabels["Thursday"]: "Thursday",
      value: 0,
      indexValue: "5",
    },
    {
      label: this.screenLabels["Friday"] ? this.screenLabels["Friday"]: "Friday",
      value: 0,
      indexValue: "6",
    },
    {
      label: this.screenLabels["Saturday"] ? this.screenLabels["Saturday"]: "Saturday",
      value: 0,
      indexValue: "7",
    },
  ];
  duplicateDays = this.days;
  newDays: any[] = [];
  //calling on checkbox selection
  onChangeDays($event) {
    console.log(
      'newEvent: ' + $event.source.value + '  :  ' + $event.source.checked
    );
    const value = $event.source.value;
    const isChecked = $event.source.checked;

    this.newDays = this.days.map((a) => {
      if (a.label === value) {
        a.value = isChecked
        return a;
      }
      return a;
    });
    this.checkingValue = true;
    console.log(this.newDays);
    this.notSaved = true;

    this._disableFunction();

    console.log("checkbox validationn",this.checkboxValidate())
    let message: any = this.screenLabels["WorkweekModifiedWarningMsg"] ? this.screenLabels["WorkweekModifiedWarningMsg"]: "Workweek modified.  This may impact the holiday schedule of <<year1>> & <<year2>>.  Please re-evaluate.";
    let capacityMessage = message.replace("<<year1>>", this.currentYear).replace("<<year2>>",this.nextYear); 
     if(!this.checkboxValidate()) {
        this.msg= capacityMessage;
        setTimeout(() => {
          this.msg = null;
          }, 8000);
       }
      //  else if(this.checkboxValidate()) {
      //   this.msg = null;
      //  }

  }

  //for validating checkbox selection
  checkboxValidate() {
    console.log("duplicate days", this.duplicateDays);
    console.log("daysAPI", this.daysAPIArray);
    const result = JSON.stringify(this.duplicateDays) == JSON.stringify(this.daysAPIArray)
    console.log(result);
    return result;
  }

   //for validating holiday selection
   holidayValidate() {
    console.log("selectedList", this.selectedList);
    console.log("dummyholidays", this.dummyHolidays);
    const result = JSON.stringify(this.selectedList.length) == JSON.stringify(this.dummyHolidays.length)
    console.log(result);
    return result;
  }

  checkYear(): number {
    let dt = new Date();
    let year = dt.getFullYear();
    if (this.yearSelection === 'currentYear') {
      return year;
    } else if (this.yearSelection === 'nextYear') {
      return year + 1;
    }
  }

  gettingYear() {
    if (this.yearSelection === 'currentYear') {
      this.monthInterface = this.getMonthByYear(this.yearSelection);
      console.log("selectedlist", this.selectedList.length)
      if ((!this.checkboxValidate() && this.notSaved) || (this.selectedDate && this.notSaved)) {
      //if (!this.checkboxValidate() || this.selectedDate) {
        this._dialogService.openConfirmDialog(
          this.screenLabels["ConfirmYearChangeLabel"] ? this.screenLabels["ConfirmYearChangeLabel"]: "Confirm Year Change",
          this.screenLabels["ConfirmYearChangeMsg"] ? this.screenLabels["ConfirmYearChangeMsg"]: "Are you sure want to switch year before saving ?"
          
        )
          .afterClosed().subscribe((confirm: boolean) => {
            if (confirm) {
                this.resetFunction();
              this.yearSelection = 'currentYear';
              this.monthInterface = this.getMonthByYear(this.yearSelection);
            }
            else {
              this.yearSelection = 'nextYear';
              this.nextMonthInterface = this.getMonthByYear(this.yearSelection);
            }
          })
      } else {
        this.yearSelection = 'currentYear';
        this.monthInterface = this.getMonthByYear(this.yearSelection);
      }
    }
    else if (this.yearSelection === 'nextYear') {
      console.log("selectedlist", this.selectedList.length)
       if ((!this.checkboxValidate() && this.notSaved) || (this.selectedDate && this.notSaved)) {
        //if (!this.checkboxValidate() || this.selectedDate) {
        this._dialogService.openConfirmDialog(
          this.screenLabels["ConfirmYearChangeLabel"] ? this.screenLabels["ConfirmYearChangeLabel"]: "Confirm Year Change",
          this.screenLabels["ConfirmYearChangeMsg"] ? this.screenLabels["ConfirmYearChangeMsg"]: "Are you sure want to switch year before saving ?"
        )
          .afterClosed().subscribe((confirm: boolean) => {
            if (confirm) {
              this.resetFunction();
              this.yearSelection = 'nextYear';
              this.nextMonthInterface = this.getMonthByYear(this.yearSelection);
            }
            else {
              this.yearSelection = 'currentYear';
              this.monthInterface = this.getMonthByYear(this.yearSelection);
            }
          })
      } else {
        this.yearSelection = 'nextYear';
        this.nextMonthInterface = this.getMonthByYear(this.yearSelection);
      }
    }
  }

  resetFunction() {
    //uncheck all checkbox
    this.days[0].value = false;
    this.days[1].value = false;
    this.days[2].value = false;
    this.days[3].value = false;
    this.days[4].value = false;
    this.days[5].value = false;
    this.days[6].value = false;

    //assign previous api values to checkbox
   this.days[0].value = this.daysAPIArray[0].value;
   this.days[1].value = this.daysAPIArray[1].value;
   this.days[2].value = this.daysAPIArray[2].value;
   this.days[3].value = this.daysAPIArray[3].value;
   this.days[4].value = this.daysAPIArray[4].value;
   this.days[5].value = this.daysAPIArray[5].value;
   this.days[6].value = this.daysAPIArray[6].value;

   this.holidays = [];
   //assign previous api values to holidays list
   this.holidays = this.dummyHolidays;
   console.log("dummy holidays",this.dummyHolidays);

  this._selectionFunction();
  }
  //for year selection

  getMonthByYear(selectionYear: string): MonthInterface[] {
    let monthInterface1: MonthInterface[] = [];
    let year = this.checkYear();
    console.log("year", year, "+", selectionYear);
    let month = 0;
    let daysInMonth = 0;
    for (let i = 0; i < 12; i++) {
      daysInMonth = new Date(year, month, 0).getDate();

      monthInterface1.push({
        minDate: new Date(year, month, 1),
        maxDate: new Date(year, month, new Date(year, month + 1, 0).getDate()),
        month: month,
      });
      month++;
    }
    console.log(monthInterface1.length);
    return monthInterface1;
  }

  //for disabling dates
  disableWeekendsFilter: (d: Date) => any;

    private _disableFunction() {
    this.disableWeekendsFilter = (d: Date): boolean => {
      if(d >= new Date()){
        return !this.days[d.getDay()].value;
      }
      else {
        return false;
      }
      


    }
    //this._selectionFunction();
  }
  
  //for multi-selection of dates
  isSelected: (event: any) => any;

  private _selectionFunction() {
    console.log("highlight called")
    this.isSelected = (event: any) => {
      const date = event.getFullYear() + '-' +
        ('00' + (event.getMonth() + 1)).slice(-2) + '-' +
        ('00' + event.getDate()).slice(-2);
      return this.holidays.find((x) => x == date) ? 'applyColor' : null;
      
    }
  }

  onselect(event: any, calendar: any) {
    console.log('select 5 called');
    const date =
      event.getFullYear() +
      '-' +
      ('00' + (event.getMonth() + 1)).slice(-2) +
      '-' +
      ('00' + event.getDate()).slice(-2);
    const index = this.holidays.findIndex((x) => x == date);
    this.notSaved = true;
    if (index < 0) {
      this.holidays.push(date); //we get holidays here
      this.selectedList.push(date);
    }
    else this.holidays.splice(index, 1);
    calendar.updateTodaysDate();
  }

  onNoClick(): void { }

  /*  for setting setsystemsettings */
  private _upsertSiteSettingsValue(
    name: string,
    category: string,
    value: string,
    active: number,
    isSingleValued: boolean,
    siteID?: number
  ) {
    let settingRecord = this._checkExistingSiteSetting(
      name,
      category,
      isSingleValued,
      siteID,
      value
    );

    const systemSettingsID =
      settingRecord === undefined ? 0 : settingRecord.SystemSettingsID;

    const siteSettings: ISiteConfig = {
      SystemSettingsID: systemSettingsID,
      SiteID: siteID,
      Name: name,
      Category: category,
      Value: value,
      Active: active,
    };

    if (systemSettingsID !== 0) {
      const index = this._selectedSiteSettings.findIndex(
        (s) => s.SystemSettingsID === systemSettingsID
      );

      this._selectedSiteSettings[index] = siteSettings;

      console.log(this._selectedSiteSettings);
    } else {
      this._selectedSiteSettings.push(siteSettings);
      console.log(this._selectedSiteSettings);
    }
  }

  private _checkExistingSiteSetting(
    name: string,
    category: string,
    isSingleValued: boolean,
    siteID?: number,
    value?: string
  ): ISiteConfig {
    if (siteID === undefined)
      return this._selectedSiteSettings.find(
        (ss) => ss.Name === name && ss.Category === category
      );
    else if (
      (siteID !== undefined && value === undefined) ||
      (siteID !== undefined && value !== undefined && isSingleValued)
    )
      return this._selectedSiteSettings.find(
        (ss) =>
          ss.Name === name && ss.Category === category && ss.SiteID === siteID
      );
    else if (siteID !== undefined && value !== undefined && !isSingleValued)
      return this._selectedSiteSettings.find(
        (ss) =>
          ss.Name === name &&
          ss.Category === category &&
          ss.SiteID === siteID &&
          ss.Value === value
      );
  }

  private _formatSunday() {
    const settingName = "Sunday";
    const settingCategory = "Calendar";

    const value = (Number(this.days[0].value)).toString();

    console.log("value", Number(this.days[0].value));

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }
  private _formatMonday() {
    const settingName = "Monday";
    const settingCategory = "Calendar";

    const value = (Number(this.days[1].value)).toString();

    console.log("value", Number(this.days[1].value));

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }
  private _formatTuesday() {
    const settingName = "Tuesday";
    const settingCategory = "Calendar";

    const value = (Number(this.days[2].value)).toString();

    console.log("value", Number(this.days[2].value));

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }
  private _formatWednesday() {
    const settingName = "Wednesday";
    const settingCategory = "Calendar";

    const value = (Number(this.days[3].value)).toString();

    console.log("value", Number(this.days[3].value));

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }
  private _formatThursday() {
    const settingName = "Thursday";
    const settingCategory = "Calendar";

    const value = (Number(this.days[4].value)).toString();

    console.log("value", Number(this.days[4].value));

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }
  private _formatFriday() {
    const settingName = "Friday";
    const settingCategory = "Calendar";

    const value = (Number(this.days[5].value)).toString();

    console.log("value", Number(this.days[5].value));

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }
  private _formatSaturday() {
    const settingName = "Saturday";
    const settingCategory = "Calendar";

    const value = (Number(this.days[6].value)).toString();

    console.log("value", Number(this.days[6].value));

    this._upsertSiteSettingsValue(
      settingName,
      settingCategory,
      value,
      1,
      true,
      this._selectedSiteID
    );
  }
  /*  .for setting setsystemsettings */

  protected onSaveChanges() {

    console.log("checkbox validate", this.checkboxValidate())

    this._dialogService.openConfirmDialog(
      this.screenLabels["ConfirmSiteSettingsLabel"] ? this.screenLabels["ConfirmSiteSettingsLabel"]: "Save Site Settings",
      this.screenLabels["ConfirmSiteSettingsMsg"] ? this.screenLabels["ConfirmSiteSettingsMsg"]: "Are you sure if you want to save the Site Settings ?" 
    )
      .afterClosed().subscribe(async (confirm: boolean) => {
        if (confirm) {
          this.notSaved = false;
          /*if (!this.checkboxValidate()) {
            this._formatSunday();
            this._formatMonday();
            this._formatTuesday();
            this._formatWednesday();
            this._formatThursday();
            this._formatFriday();
            this._formatSaturday();
            const days = this.siteService.createUpdateSiteSettingsAPI(
              this._selectedSiteSettings
            ).then(() => {             
              this.notificationService.success("System settings updated successfully");
            });
            console.log("selected date", this.selectedDate);
          }*/
          
          console.log("days",this.days)
          
          const daysFilter: string = this.days.filter(element => (Number(element.value) === 1)).map(ele => ele.indexValue).join(",")
          console.log("daysfilter",daysFilter)
          this.holidayService.setNonWorkingDaysAPI(
            daysFilter,
            // this.checkYear(),
            this.siteService.selectedSiteID
          ).then(() => {
            console.log("checkYear", this.checkYear() + "SiteId", this.siteService.selectedSiteID)

            let newArray: string[] = []
            this.holidays.forEach(element => {
              let formatted = new Date(element).toISOString();
              newArray.push(formatted)
            })

            this.holidayService.setHolidaysAPI(
              this.checkYear(),
              this.siteService.selectedSiteID,
              //this.holidays
              newArray
            ).then(() => {
              this.selectedDate = null;
               this.notificationService.success(this.screenLabels["SystemSettingsUpdatedSuccessMsg"] ? this.screenLabels["SystemSettingsUpdatedSuccessMsg"]: "System settings updated successfully");
               //this.notificationService.success("All the dates marked as holiday");
              console.log("successful");
            }).catch((er: HttpErrorResponse) => {
              this.notificationService.failure(er.message);
            })
          })
            .catch((err: HttpErrorResponse) =>
              this.notificationService.failure(err.message)
            );
          //this.notSaved = true;

          this.onNoClick();
        }
        
      });
    }
        
  
}
