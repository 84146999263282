import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { OktaAuthService } from "@okta/okta-angular";

@Injectable()
export class LoggedInUserGuard implements CanActivate {
  constructor(
    private router: Router,
    private oktaAuthService: OktaAuthService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // user is not authenticated
    if (!(await this.oktaAuthService.isAuthenticated())) {
      return true;
    }

    // user is authenticated hence don't allow user to access the login screen instead navigate to dashboard
    this.router.navigate(["/dashboard"]);
    return false;
  }
}
