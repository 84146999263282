import { Component, OnInit, HostListener, Input, EventEmitter, ViewChild } from "@angular/core";
import { AuthService } from "./services/auth.service";
import { SpinnerService } from "./services/spinner.service";
import { OktaAuthService } from "@okta/okta-angular";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from './services/user.service';
import { CountdownComponent } from 'ngx-countdown';
import { NotificationService } from './services/notification.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "vms-project";
  time: any = 300;
  @ViewChild('cd', { static: false }) 
  private countdown: CountdownComponent;

  protected isOktaAuthenticated = false;
  protected callInUser: boolean = false;

  protected verifyResponse: any;
  protected verifyUserEnable: boolean = false;

  constructor(
    public spinnerService: SpinnerService,
    private oktaAuthService: OktaAuthService,
    private route: ActivatedRoute,
    private router: Router,
    private authService : AuthService,
    public userService: UserService,
    public notificationService : NotificationService
  ) {}
  async ngOnInit() {
    //this.authService.signedInFlag = true;
    this.oktaAuthService.setFromUri("/user-verification");

    this.authService.getVerifyUser().subscribe((res) => {
      this.verifyUserEnable = res.status;
      if (this.verifyUserEnable) {
        this.router.navigate(['/verify-user'])
      }
    });
    this.authService.userChangedToCallInUser.subscribe((event: boolean) => {
      if (event) {
        if (this.authService.validCaller) {
          this.callInUser = true;
        } else {
          this.callInUser = false;
        }
      }
    });
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.authService.validCaller && this.router.url != "/") {
      this.countdown.restart();
    }
  }

  @HostListener('document:click', ['$event'])
  onMouseMove(e) {
    if (this.authService.validCaller && this.router.url != "/") {
      if(this.callInUser)
      this.countdown.restart();
    }
  }
  
  timesUp(event) {
    if (event.action == "done") {
      // this.authService.validCaller = false;
      // this.userService.userID = this.userService.loggedInUserID;
      // this.userService.firstName = this.userService.loggedInUserFirstName;
      // this.userService.middleName = this.userService.loggedInUserMiddleName;
      // this.userService.lastName = this.userService.loggedInUserLastName;
      // this.userService.suffix = this.userService.loggedInSuffix;
      // this.userService.email = this.userService.loggedInEmail;
      // this.userService.phone = this.userService.loggedInPhone;
      // this.userService.userFunctions = this.userService.loggedInUserFunctions;
      // this.userService.callInUserID = 0;
      // this.authService.userChangedToCallInUser.emit(true);
      // this.userService.userOrganizations = this.userService.loggedInUserOrganizations;
      // this.userService.organizationID = this.userService.loggedInUserOrganizationID;      
      // this.userService.organizationChanged.emit(true);
      this.authService.validCaller = false;
      this.userService.userID = this.userService.loggedInUserID;
      this.userService.loggedInUserFirstName = this.userService.firstName;
      this.userService.loggedInUserMiddleName = this.userService.middleName;
      this.userService.loggedInUserLastName = this.userService.lastName;
      this.userService.loggedInSuffix = this.userService.suffix;
      this.userService.loggedInEmail = this.userService.email;
      this.userService.loggedInPhone = this.userService.phone;
      this.userService.userFunctions = this.userService.loggedInUserFunctions;
      this.userService.callInUserID = 0;
      this.authService.userChangedToCallInUser.emit(true);
      this.userService.userOrganizations = this.userService.loggedInUserOrganizations;
      this.userService.organizationID = this.userService.loggedInUserOrganizationID;
      this.userService.organizationChanged.emit(true);
      this.notificationService.failure("Call-In Authenticaion User Session Expired!")
    }
  }
}
