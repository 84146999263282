import { Injectable } from "@angular/core";
import { clone, cloneDeep } from "lodash";
import { SiteService } from "./site.service";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";

export interface SystemLabel {
  SystemLabelID: number;
  SiteID: number;
  ScreenName: string;
  TableName: string;
  FieldName: string;
  Lable: string;
}

@Injectable({
  providedIn: "root",
})
export class LabelService {
  private _appLabels = [];
  private appLanguage;
  private _selectedSiteID: number;
  private _selectedScreenName: string;
  private _selectedTableName: string;
  public prefLanguageID: number;

  public screenLabels: Array<string> = [];

  constructor(private siteService: SiteService, private http: HttpClient) {
    // site change listener
    this.siteService.siteChanged.subscribe(async (changed) => {
      this._selectedSiteID = this.siteService.selectedSiteID;
      await this.getLabelAPI();
      this.loadScreenLabels();
    });
  }

  get appLabels(): Array<SystemLabel> {
    return clone(this._appLabels);
  }

  public getLabel(
    siteID: number,
    screenName: string,
    tableName: string,
    fieldName: string
  ): string {
    return this.appLabels.find(
      (al) =>
        al.SiteID === siteID &&
        al.ScreenName === screenName &&
        al.TableName === tableName &&
        al.FieldName === fieldName
    ).Lable;
  }

  // the method should be invoked to initialize the screen labels
  public initScreenLabels(
    siteID: number,
    screenName: string,
    tableName: string
  ) {
    this._selectedSiteID = siteID;
    this._selectedScreenName = screenName;
    this._selectedTableName = tableName;

    this.loadScreenLabels();
  }

  public initScreenLabelsByLanguage(
    screenName: string,
    tableName: string
  ) {
    this._selectedScreenName = screenName;
    this._selectedTableName = tableName;
    this.loadScreenLabelsByLanguage();
  }

  // the method should be invoked to filter the labels wrt siteID, screenName, and tableName
  private loadScreenLabels() {
    const labels = this.appLabels
      .filter(
        (al) =>
          al.SiteID === this._selectedSiteID &&
          al.ScreenName === this._selectedScreenName &&
          al.TableName === this._selectedTableName
      )
      .map((o) => ({ FieldName: o.FieldName, Label: o.Lable }));

    // DO NOT Uncomment the following line
    // this.screenLabels = [];
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));

    console.log("Screen Labels", this.screenLabels);
  }

  private loadScreenLabelsByLanguage() {
    const labels = this.appLanguage
      .filter(
        (al) =>
          al.ScreenName === this._selectedScreenName &&
          al.TableName === this._selectedTableName
      )
      .map((o) => ({ FieldName: o.FieldName, Lable: o.Lable }));

    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Lable));

  }

  // the method should be invoked to read site labels
  public getLabelAPI(): Promise<any> {
    const apiURL = environment.getAPI("getSiteLabels");

    const body = {
      SiteID: this.siteService.selectedSiteID,
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          async (success: HttpResponse<any>) => {
            // success
            this._appLabels = success.body;
            console.log(success.body)
            this.loadScreenLabels();
            // console.log(success.body);
            resolve(cloneDeep(success.body));
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  
}

