import { DatePipe } from "@angular/common";
import { SiteService } from "src/app/services/site.service";

export class RoleAssignmentApprovalAgGridConfig {
  public roleAssignmentApprovalColumnDefs = [];

  constructor(
    private _datePipe: DatePipe, 
    private _siteService: SiteService, 
    private screenLabels,) {
    this.roleAssignmentApprovalColumnDefs = [
      {
        headerName: this.screenLabels["AgGridRequestID"] ? this.screenLabels["AgGridRequestID"]: "Request ID",
        width: 80,
        field: "UserAccessRequestID",
        cellStyle: { color: "#589bf8" },
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridRequestedBy"]? this.screenLabels["AgGridRequestedBy"]: "Requested By",
        field: "RequestedByName",
        sortable: true,

        filter: true,
      },
      {
        headerName: this.screenLabels["AgGridOrganization"]? this.screenLabels["AgGridOrganization"]: "Organization",
        field: "OrganizationName",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridRequestedOn"]? this.screenLabels["AgGridRequestedOn"]: "Requested On",
        field: "RequestedOnDate",
        sortable: true,
        filter: true,
        cellRenderer: (data) => {
          // const formattedDate = this._datePipe.transform(
          //   data.value,
          //   "MM/dd/yyyy"
          // );
          // return formattedDate ? formattedDate : null;

          const formattedDate = this._datePipe.transform(
            data.value,
            this._siteService.dataTimeFormat.angularDateFormat
          );

          return formattedDate ? formattedDate : null;
        },
      },
      {
        headerName: this.screenLabels["AgGridRequestedFor"]? this.screenLabels["AgGridRequestedFor"]: "Requested For",
        field: "RequestedForName",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridRequestedForFirstName"]? this.screenLabels["AgGridRequestedForFirstName"]: "Requested For First Name",
        field: "RequestedForUserFirstName",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridRequestedForMiddleName"]? this.screenLabels["AgGridRequestedForMiddleName"]: "Requested For Middle Name",
        field: "RequestedForUserMiddleName",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridRequestedForLastName"]? this.screenLabels["AgGridRequestedForLastName"]: "Requested For Last Name",
        field: "RequestedForUserLastName",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridRequestedForSuffix"] ? this.screenLabels["AgGridRequestedForSuffix"]: "Requested ForSuffix",
        field: "RequestedForUserSuffix",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridRequestedForStatus"]? this.screenLabels["AgGridRequestedForStatus"]: "Requested For Status",
        field: "RequestedForUserStatus",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridRequestersComments"]? this.screenLabels["AgGridRequestersComments"]: "Requesters Comments",
        field: "RequesterComments",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridReviewersComments"]? this.screenLabels["AgGridReviewersComments"]: "Reviewer's Comments",
        field: "ApproversComments",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridEmail"] ? this.screenLabels["AgGridEmail"]: "Email",
        field: "NewUserEmail",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridRoles"]? this.screenLabels["AgGridRoles"]: "Roles",
        field: "Roles",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["AgGridStatus"] ? this.screenLabels["AgGridStatus"]: "Status",
        field: "Status",
        sortable: true,
        valueGetter(params) {
          return params.data.Active === 0 ? "Cancelled" : params.data.Status
        } 
      },
    ];

    
  }
}
