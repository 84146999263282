import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { SearchService } from "../services/search.service";
import { EntryPointService } from "../services/entry-point.service";
import { EntryPoint } from "../models/entry-point.model";
import { ViewEntryPointModalComponent } from "./modals/view-entry-point/view-entry-point-modal.component";
import { CreateEntryPointComponent } from "./modals/create-entry-point/create-entry-point.component";
import { GridApi } from "ag-grid-community";
import { UpdateEntryPointComponent } from "./modals/update-entry-point/update-entry-point.component";
import { DialogService } from "../services/dialog.service";
import { NotificationService } from "../services/notification.service";
import { HttpErrorResponse } from "@angular/common/http";
import { EntryPointZoneService } from "../services/entry-point-zone.service";
import { UserService } from "../services/user.service";
import { SiteService } from "../services/site.service";
import { Printers } from "../models/printers.model";
import { EntryPointStation } from "../models/entry-point-station.model";
import { MessageService } from '../services/message.service';

@Component({
  selector: "app-entry-point",
  templateUrl: "./entry-point.component.html",
  styleUrls: ["./entry-point.component.css"],
})
export class EntryPointComponent implements OnInit {
  private gridApi: GridApi;
  protected isRowSelected = false;
  protected selectedEntryPoint: EntryPoint;
  protected entryPointRows: EntryPoint[];
  protected rowData;
  protected rowClassRules;
  private paginationPageSize;
  public isActive: boolean = false;
  protected entryPointColumnDefs : any ;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  public defaultColDef : object;

  constructor(
    protected searchService: SearchService,
    protected entryPointService: EntryPointService,
    protected dialog: MatDialog,
    protected dialogService: DialogService,
    protected notificationService: NotificationService,
    protected entryPointZoneService: EntryPointZoneService,
    public userService: UserService,
    private siteService: SiteService,
    protected messageService: MessageService,
  ) {
    this.paginationPageSize = 50;
    // set row styles
    this.rowClassRules = {
      "row-inactive": (params) => {
        var condition = params.data.Active === 0;
        return condition;
      },
    };
    this.defaultColDef = {
      resizable: true,
  }
  }

  ngOnInit(): void {
    this.searchService.setPageName("Entry Point");


    //labels by message service
if (localStorage.getItem('EntryPointLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('EntryPointLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Entry Point",
      "EntryPoint"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('EntryPointLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    // load rows into ag grid on OnInit event
    if (this.entryPointZoneService.getEntryPointZone.length == 0) {
      this.entryPointZoneService
        .getAllEntryPointZonesAPI()
        .then(() => {})
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    }

    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        this.rowData = this.searchService.searchRecords;
        this.gridApi.paginationGoToPage(0);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    this.entryPointService.entryPointDataChanged.subscribe((e: boolean) => {
      this.searchService
        .smartSearchMultiSiteAPI(null)
        .then(() => {
          this.rowData = this.searchService.searchRecords;
          this.gridApi.paginationGoToPage(0);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    });

    // this.entryPointService.parseGetEndpointAPI().then(() => {
    //   this.rowData = this.entryPointService.getEntryPoints();
    // });

    this.entryPointService.entryPointDataChanged.subscribe((e) => {
      const api = this.gridApi;
      const parseGetEndpointAPIPromise = this.searchService.smartSearchMultiSiteAPI(
        null
      );
      parseGetEndpointAPIPromise.then(() => {
        this.rowData = this.searchService.searchRecords;
      });
      this.isRowSelected = false;
      this.gridApi.paginationGoToPage(0);
    });

    // Listen to Search Btn click event and Y Ag Grid with Search results
    this.searchService.searchInitiated.subscribe((eventData: boolean) => {
      if (eventData) {
        this.searchService.smartSearchPromise
          .then(() => {
            this.rowData = this.searchService.searchRecords;
            this.gridApi.paginationGoToPage(0);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    });

    // subscribe to site changed
    this.siteService.siteChanged.subscribe((event: boolean) => {
      if(event){
        this.searchService
        .smartSearchMultiSiteAPI(null)
        .then(() => {
          this.rowData = this.searchService.searchRecords;   
          this.gridApi.paginationGoToPage(0);      
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
      }
    });
  }

  setColumnDef(){
  this.entryPointColumnDefs = [
    {
      headerName: "",
      field: "",
      width: 40,
      cellStyle: { color: "#589bf8" },
      hide: false,
      checkboxSelection: true,
      resizable: false,
      sortable: false,
      lockPosition: true,
    },
    // {
    //   headerName: "",
    //   field: "",
    //   width: 80,
    //   cellStyle: { color: "#589bf8" },
    //   resizable: false,
    //   sortable: false,
    //   lockPosition: true,
    // },
    {
      headerName: this.screenLabels["EntryPointID"]
      ? this.screenLabels["EntryPointID"]
      : "EntryPoint ID",
      field: "EntryPointID",
      hide: true,
      sortable: true,
    },
    {
      headerName: this.screenLabels["DescriptionAgGrid"]
        ? this.screenLabels["DescriptionAgGrid"]
        : "Description",
      field: "Description",
      sortable: true,
      width: 110,
      filter: true,
    },
    {
      headerName: this.screenLabels["PhoneAgGrid"]
        ? this.screenLabels["PhoneAgGrid"]
        : "Phone",
      field: "Phone",
      sortable: true,
      width: 110,
      filter: true,
    },
    {
      headerName: this.screenLabels["EntryPointZoneAgGrid"]
        ? this.screenLabels["EntryPointZoneAgGrid"]
        : "Entry Point Zone",
      field: "EntryPointZoneID",
      hide: false,
      sortable: true,
      cellRenderer: (data) => {
        return this.entryPointZoneService
          .getEntryPointZone()
          .filter((ez) => ez.EntryPointZoneID == +data.value)
          .map((e) => e.Name)[0];
      },
    },
    {
      headerName:this.screenLabels["ModifiedBy"]
      ? this.screenLabels["ModifiedBy"]
      : "Modified By",
      field: "ModifiedBy",
      hide: true,
      sortable: true,
    },
    {
      headerName:this.screenLabels["ModifiedDate"]
      ? this.screenLabels["ModifiedDate"]
      : "Modified Date",
      field: "ModifiedDate",
      hide: true,
      sortable: true,
    },
    {
      headerName:this.screenLabels["CreatedBy"]
      ? this.screenLabels["CreatedBy"]
      : "Created By",
      field: "CreatedBy",
      hide: true,
      sortable: true,
    },
    {
      headerName:this.screenLabels["CreatedByName"]
      ? this.screenLabels["CreatedByName"]
      : "Created By Name",
      field: "CreatedByName",
      hide: true,
      sortable: true,
    },
    {
      headerName:this.screenLabels["ModifiedByName"]
      ? this.screenLabels["ModifiedByName"]
      : "Modified By Name",
      field: "ModifiedByName",
      hide: true,
      sortable: true,
    },
  ];
}

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDef()
}

  onGridReady(params) {
    this.gridApi = params.api;
  }

  // make ag-rid colums fill their container
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }
  // show entry-point modal
  openEntryPoint($event) {
    if (this.userService.userFunctions["EntryPointView"]) {
      this.selectedEntryPoint = $event.data;
      this.dialog.open(ViewEntryPointModalComponent, {
        disableClose: true,
        data: this.selectedEntryPoint,
      });
    }
  }

  // called to display entry point create modal
  onCreateEntryPoint() {
    this.dialog.open(CreateEntryPointComponent, {           
      disableClose: true,
      data: EntryPoint });
  }

  // sets the data of selected row
  onSelectionChanged($event) {
    const selectedRows: EntryPoint[] = $event.api.getSelectedRows();
    if (selectedRows.length) {
      this.selectedEntryPoint = selectedRows[0];
      this.isRowSelected = true;
    } else {
      this.isRowSelected = false;
    }

    if(this.selectedEntryPoint.Active === 0){
      this.isActive = false;
    } else {
      this.isActive = true;
    }
  }

  onEditEntryPoint() {
    this.dialog.open(UpdateEntryPointComponent, {
      disableClose: true,
      data: { ...this.selectedEntryPoint },
    });
  }

  onDisableEntryPoint() {
    let name = this.selectedEntryPoint.Description;
    let message: string = this.screenLabels["DeleteEntryPointConfirmMsg"] ? this.screenLabels["DeleteEntryPointConfirmMsg"] : "Are you sure you want to Delete the Entry Point <<id>> ? ";
    const capacityMessage = message.replace("<<id>>",name)
    this.dialogService
      .openConfirmDialog(
        this.screenLabels["DeleteEntryPointBtn"]? this.screenLabels["DeleteEntryPointBtn"]: "Delete Entry Point",
        capacityMessage
      )
      .afterClosed()
      .subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          // const entryPointStations = this.entryPointService.getEntryPointStations(
          //   this.selectedEntryPoint.EntryPointID
          // );
           if (this.entryPointService.getEntryPoints().length) {
          let printers:Array<Printers>;
          let cloudPrintEnable:boolean;
          let entryPointStations:Array<EntryPointStation>;
          this.entryPointService.getEntryPointByID(this.siteService.selectedSiteID, this.selectedEntryPoint.EntryPointID).subscribe((res: any) => {
            let response = res.body;
            cloudPrintEnable = response.CloudPrintEnabled;
            printers = response.Printers;
            entryPointStations = response.EntryPointStations;
        
          // unlink entry point stations
            entryPointStations.forEach((item) => (item.Active = false));
            printers.forEach((item) => (item.Active = false));

            this.entryPointService
              .disableEntryPoint(
                this.selectedEntryPoint.EntryPointID,
                this.selectedEntryPoint.Description,
                this.selectedEntryPoint.Phone,
                this.selectedEntryPoint.EmailDirections,
                this.selectedEntryPoint.EntryPointZoneID,
                printers,
                entryPointStations,
                cloudPrintEnable
              )
              .then(() => {
                this.notificationService.success(
                  this.entryPointService.message
                );
              });
            });
          } else {
            this.entryPointService
              .parseGetEndpointAPI()
              .then(() => {

                let printers: Array<Printers>;
                let cloudPrintEnable: boolean;
                let entryPointStations: Array<EntryPointStation>;
                this.entryPointService.getEntryPointByID(this.siteService.selectedSiteID, this.selectedEntryPoint.EntryPointID).subscribe((res: any) => {
                  let response = res.body;
                  cloudPrintEnable = response.CloudPrintEnabled;
                  printers = response.Printers;
                  entryPointStations = response.EntryPointStations;
               
                entryPointStations.forEach((item) => (item.Active = false));
                printers.forEach((item) => (item.Active = false));

                this.entryPointService
                  .disableEntryPoint(
                    this.selectedEntryPoint.EntryPointID,
                    this.selectedEntryPoint.Description,
                    this.selectedEntryPoint.Phone,
                    this.selectedEntryPoint.EmailDirections,
                    this.selectedEntryPoint.EntryPointZoneID,
                    printers,
                    entryPointStations,
                    cloudPrintEnable
                  )
                  .then(() => {
                    this.notificationService.success(
                      this.entryPointService.message
                    );
                  });
                });
              })
              .catch((err: HttpErrorResponse) => {
                this.notificationService.success(err.message);
              });
          }
        }
      });
  }
}
