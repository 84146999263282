import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm, FormControl, Validators } from '@angular/forms';
import { MatExpansionPanel, MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { DomainModel } from 'src/app/models/domain-type.model';
import { DialogService } from 'src/app/services/dialog.service';
import { DomainService } from 'src/app/services/domain.service';
import { LabelService } from 'src/app/services/label.service';
import { RequiredFieldsService } from 'src/app/services/required-fields.service';
import { SiteService } from 'src/app/services/site.service';
import { UserService } from 'src/app/services/user.service';
import { EditDomainComponent } from '../edit-domain/edit-domain.component';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-view-domain',
  templateUrl: './view-domain.component.html',
  styleUrls: ['./view-domain.component.css']
})
export class ViewDomainComponent implements OnInit {
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  @ViewChild("domainForm") domainForm: NgForm;
  protected step = 0;
  protected domain: DomainModel;
  protected domainTypes: any;
  protected appLanguage;
  public screenLabels: Array<string> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DomainModel,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<ViewDomainComponent>,
    protected domainService: DomainService,
    protected dialogService: DialogService,
    private userService: UserService,
    private siteService: SiteService,
    protected labelService: LabelService,
    protected requiredFieldsService : RequiredFieldsService,
    protected messageService: MessageService,
  ) { }

  ngOnInit(): void {
     // initialize labels
     this.labelService.initScreenLabels(
      this.siteService.selectedSiteID,
      "View Domain Modal",
      "Domain"
    );

    //labels by message service
    if (localStorage.getItem('ViewDomainLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('ViewDomainLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "View Domain Modal",
        "Domain"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('ViewDomainLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    this.domain = { ...this.data };

    this.domainService.getDomainPickListAPI().then(() => {
      this.domainTypes = this.domainService.domainTypeTables;
    });
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  ngAfterViewChecked() {
    // setting up the required fields
    this.requiredFieldsService.setRequiredFields(
      'View Domain',
      this.domainForm
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  onMainHeaderClose() {
    this.freezePanel.open();
  }
}
