import { SecurityComponent } from "./../security.component";
import { Component, Inject } from "@angular/core";
import { SearchService } from "../../services/search.service";
import { SpinnerService } from "../../services/spinner.service";
import { UserService } from "src/app/services/user.service";
import { Site, SiteService } from "src/app/services/site.service";
import { MessageService } from "src/app/services/message.service";

// tslint:disable-next-line:no-empty-interface
export interface DialogData {}
@Component({
  selector: "app-security-mobile",
  templateUrl: "./security.component.mobile.html",
  styleUrls: ["./security.component.mobile.css"],
})
// tslint:disable-next-line:component-class-suffix
export class SecurityComponentMobile extends SecurityComponent {
  constructor(
    protected searchService: SearchService,
    spinnerService: SpinnerService,
    userService: UserService,
    siteService:SiteService,
    protected messageService: MessageService
  ) {
    super(searchService, spinnerService, userService,siteService,messageService);
  }
}
