import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { HttpResponse } from '@angular/common/http';
import { MessageService } from '../services/message.service';

@Component({
  selector: 'app-confirmation-page',
  templateUrl: './confirmation-page.component.html',
  styleUrls: ['./confirmation-page.component.css']
})
export class ConfirmationPageComponent implements OnInit {

  @Input() authorizerResponse: any;
  @Output() msgFromConfirmation = new EventEmitter<boolean>();
  protected appLanguage;
  public screenLabels: Array<string> = [];
  
  constructor(
    private authService: AuthService,
    protected messageService: MessageService,
  ) { }

  ngOnInit(): void {
    //labels by message service
if (localStorage.getItem('AcknowledgmentLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('AcknowledgmentLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Settings",
    "Settings"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('AcknowledgmentLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}

  onSaveAcknowledgment() {
    this.msgFromConfirmation.emit(true);
  }
}
