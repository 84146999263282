import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { DashboardWidgetsComponent } from "../dashboard-widgets.component";
import { ViewportRuler } from "@angular/cdk/overlay";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { WidgetService, widgetType } from "src/app/services/widget.service";
import { UserService } from "src/app/services/user.service";
import { WidgetSelectionModalComponent } from "../modals/widget-selection.modal.component";
import { MatDialog } from "@angular/material";
import { HttpErrorResponse } from "@angular/common/http";
import { OrganizationService } from "src/app/services/organization.service";
import { Subject } from "rxjs";
import { clone, cloneDeep } from "lodash";
import { SiteService } from "src/app/services/site.service";
import { UtilityService } from "src/app/services/utility.service";
import { AuthService } from "src/app/services/auth.service";
import { Subscription } from "rxjs/internal/Subscription";
import { MessageService } from "src/app/services/message.service";
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: "app-dashboard-widgets-desktop",
  templateUrl: "./dashboard-widgets.component.desktop.html",
  styleUrls: ["./dashboard-widgets.component.desktop.css"],
})
export class DashboardWidgetsComponentDesktop
  extends DashboardWidgetsComponent
  implements OnInit, AfterViewInit, OnDestroy {
  // properties for gridster
  protected options = this.widgetService.options;
  protected dashboardWidgets: Array<any>;
  
  private widgetSubscription: Subscription;
  
  // Observable for update
  update$: Subject<any> = new Subject();
  message: string;

  constructor(
    viewportruler: ViewportRuler,
    widgetService: WidgetService,
    userService: UserService,
    dialog: MatDialog,
    organizationService: OrganizationService,
    siteService:SiteService,
    utilService: UtilityService,
    authService: AuthService,
    messageService: MessageService,
    dialogService : DialogService
  ) {
    super(
      viewportruler,
      widgetService,
      userService,
      dialog,
      organizationService,
      siteService,
      utilService,
      authService,
      messageService,
      dialogService
    );
  }

  ngOnInit() {
     //labels by message service
     if (localStorage.getItem('DashboardWidgetLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('DashboardWidgetLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "DashboardWidget Modal",
        "Pass"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('DashboardWidgetLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    if (
      this.userService.getUserDetails().length !=
      0
    ) {
      this.getUserWidgets();
    } else {
      this.userService
        .getUserAssociationAPI(this.userService.userID,this.siteService.selectedSiteID)
        .then(() => {
          const userOrganizations = [
            ...this.userService
              .getUserDetails()[0]
              .UserOrganization.map((o) => ({
                OrganizationID: o.OrganizationID,
              })),
          ];
          // if(this.userService.organizationID <= 0){
          //   this.userService.organizationID = userOrganizations[0].OrganizationID;
          //   this.userService.IsAdmin = userOrganizations[0].IsAdmin ? (userOrganizations[0].IsAdmin == 1 ? 1 : 0) : 0
          // }

          this.getUserWidgets();
        });
    }

    // this.getUserWidgets();
    this.widgetSubscription = this.widgetService.updateWidgets.subscribe((event: boolean) => {
      if (event) this.getUserWidgets();
    });

    this.userService.organizationChanged.subscribe((event: boolean) => {
      if (event) this.getUserWidgets();
    });

     this.siteService.siteChanged.subscribe((event: boolean) => {
      if (event) {
        this.widgetService.dashboardWidgets = [];
        this.getUserWidgets();
      }
    });

    


    this.authService.userChangedToCallInUser.subscribe((event: boolean) => {
      if(event) {
        this.widgetService.dashboardWidgets = [];
        this.getUserWidgets();
      }
    });

    // this.widgetService.refreshData();
  }

  removeItem(event, item) {
    this.dialogService
      .openConfirmDialog(
        this.screenLabels['DeleteWidget'] ? this.screenLabels['DeleteWidget'] : "Delete Widget",
        this.screenLabels['DeleteWidgetMsg'] ? this.screenLabels['DeleteWidgetMsg'] : "Do you want to delete the widget ?")
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.dashboardWidgets.splice(this.dashboardWidgets.indexOf(item), 1);
          this.widgetService.DeleteWidgetAPI(item.userWidgetID)
            .then(() => {
              this.widgetService.updateWidgets.emit(true);
              this.widgetService.notificationService.success(
              this.screenLabels['UserWidgetremoved'] ? this.screenLabels['UserWidgetremoved'] : "User Widget is Removed Successfully."
              );
            })
            .catch((err: HttpErrorResponse) =>
            this.widgetService.notificationService.failure(err.message)
            );
        }
      });

    // console.log(item);
  }

  ngAfterViewInit() {}

  

  changedOptions() {
    this.options.api.optionsChanged();
  }

  // open widget selector dialog box
  openWidgetSelection() {
    const dialogRef = this.dialog.open(WidgetSelectionModalComponent, {
      disableClose: true,
      backdropClass: "backdropBackground",
    });
  }

  private getUserWidgets() {
    this.widgetService
      .getAssignedUserWidgetsAPI()
      .then((dw) => {
        // load the dashboard data
        // this.dashboardWidgets = [];
        // this.dashboardWidgets = this.widgetService.dashboardWidgets;

        // this.dashboardWidgets = cloneDeep(dw);
        // this.widgetService.dashboardWidgetDataReady.subscribe(
        //   (dashWidgets) => (this.dashboardWidgets = dashWidgets)
        // );
        this.dashboardWidgets = dw;

        // this.dashboardWidgets = cloneDeep(this.widgetService.dashboardWidgets);
        // this.dashboardWidgets = [...this.widgetService.dashboardWidgets];
        // this.dashboardWidgets = clone(this.widgetService.dashboardWidgets);

        // for (let dashboardWidget of this.widgetService.dashboardWidgets) {
        //   if (dashboardWidget.type === "Line Chart Widget") {
        //     console.log("Its a line chart");
        //     dashboardWidget.data = cloneDeep(dashboardWidget.data);
        //     this.dashboardWidgets.push(dashboardWidget);
        //   } else {
        //     this.dashboardWidgets.push(dashboardWidget);
        //   }
        // }

     
      })
      .catch((err: HttpErrorResponse) => console.log(err));
  }

  // Update function
  updateChart() {
    this.update$.next(true);
  }

  ngOnDestroy() {
    this.widgetSubscription.unsubscribe();
  }
}
