export class ApprovalGroup {
    public CreatedByName: string;
    public ModifiedByName: string;
  
    constructor(
      public ApprovalGroupID: number,
      public SiteID: number,
      public Name: string,
      public Description: string,
      public Users: any[],
      public Active: number,
      public UserID: number
    ) {
      this.Active = 1;
    }
  }
  