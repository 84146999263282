import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MatExpansionPanel, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ApprovalGroup } from 'src/app/models/approval-group-model';
import { UpdateParkingGroupModalComponent } from 'src/app/parking-lot-group/modals/update-parking-group/update-parking-group-modal.component';
import { ApprovalGroupService } from 'src/app/services/approval-group.service';
import { DialogService } from 'src/app/services/dialog.service';
import { EntryPointService } from 'src/app/services/entry-point.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SearchService } from 'src/app/services/search.service';
import { SiteService } from 'src/app/services/site.service';
import { RequiredFieldsService } from 'src/app/services/required-fields.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-update-approval-group',
  templateUrl: './update-approval-group.component.html',
  styleUrls: ['./update-approval-group.component.css']
})
export class UpdateApprovalGroupComponent implements OnInit {
  protected step = 0;
  protected approvalGroup: ApprovalGroup;
 // nameFormControl = new FormControl("", [Validators.required]);
  usersList: Array<any> = [];
  AvailableUsersList: Array<String> = [];
  selectedUserList: Array<String> = [];
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  @ViewChild("updtAprForm") updtAprForm: NgForm;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ApprovalGroup,
    protected dialogRef: MatDialogRef<UpdateApprovalGroupComponent>,
    protected entryPointService: EntryPointService,
    protected approvalGroupService: ApprovalGroupService,
    protected notificationService: NotificationService,
    protected searchService: SearchService,
    protected dialogService: DialogService,
    private siteService: SiteService,
    protected requiredFieldsService: RequiredFieldsService,
    protected messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.approvalGroup = { ...this.data };
   // this.getUsers();
   
//labels by message service
if (localStorage.getItem('UpdateApprovalGroupLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('UpdateApprovalGroupLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Update Approval Group Modal",
      "ApprovalGroup"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('UpdateApprovalGroupLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  ngAfterViewChecked() {
    // setting up the required fields
    this.requiredFieldsService.setRequiredFields(
      'Edit Approval Group',
      this.updtAprForm
    );
  }

  // getUsers(): void {
  //   this.searchService.setPageName("User");
  //   this.searchService
  //     .smartSearchMultiSiteAPI(null)
  //     .then(() => {
  //       this.usersList = this.searchService.searchRecords;
  //       this.usersList.forEach(element => {
  //         if (element.Active === 1) {
  //           console.log(element)
  //           this.AvailableUsersList.push(element.FirstName +' '+ element.LastName+ ' '+ '|'+' '+element.Email);
  //         }
  //       });
  //     })
  //     .catch((err: HttpErrorResponse) => {
  //       this.notificationService.failure(err.message);
  //     });
  //     this.getApprovalGroupUsers();
  // }

  getApprovalGroupUsers() {
    this.approvalGroup.Users.forEach(element => {
      if (element.Active === 1) {
        this.selectedUserList.push(element.FirstName +' '+ element.LastName);
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close(this.searchService.setPageName("Approval Group"));
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  // Method to Update Approval Group

  onSaveChanges() {
   // const users = [];
    // this.usersList.forEach(user =>{
    //   this.selectedUserList.forEach(suser =>{
    //     const firstName = suser.split(" ")[0];
    //     const lastName = suser.substr(suser.indexOf(" ") + 1);
    //     if(firstName === user.FirstName && lastName === user.LastName){
    //       users.push({
    //         "AGUserID": user.UserID,
    //         "Active": user.Active
    //       });
    //     }
    //   })
    // });
    // this.approvalGroup.Users.forEach(user => {
    //   let isPresent = false;
    //   users.forEach(f=> {
    //     if (f.AGUserID === user.UserID){
    //       isPresent = true;
    //     }
    //   });
    //   if (!isPresent){
    //     users.push({
    //       "AGUserID": user.UserID,
    //       "Active": 0
    //     });
    //   }
    // })
    //this.approvalGroup.Users = users;
    this.dialogService
      .openConfirmDialog(
        this.screenLabels['EditApprovalGroupConfirmLabel'] ? this.screenLabels['EditApprovalGroupConfirmLabel'] : "Confirm Approval Group Update",
        this.screenLabels['EditApprovalGroupConfirmMsg'] ? this.screenLabels['EditApprovalGroupConfirmMsg'] : "Are you sure you want to save the changes ?"
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        const name = this.approvalGroup.Name.trim() === this.approvalGroup.Name ? this.approvalGroup.Name : this.approvalGroup.Name.trim();
        this.approvalGroup.Name = name ;
        if (confirm) {
          this.approvalGroupService.updateApprovalGroup(this.approvalGroup);
        }
      });

    this.onNoClick();
  }

  isNameEmpty(): boolean {
    return !this.approvalGroup.Name || !this.approvalGroup.Name.trim();
  }

  nameFormControl = new FormControl("", [
    Validators.required,  // Required validation
    Validators.pattern(/^[a-zA-Z\s-][a-zA-Z\s0-9-]*$/),  // Updated pattern validation
    this.specialCharacterValidator(),
  ]);

  // Custom validator function
  specialCharacterValidator() {
    return (control: FormControl) => {
      const value = control.value;
      if (!value) {
        return null; // No validation for empty values
      }

      // Check for spaces or hyphens followed by spaces
      if (/^\s/.test(value)) {
        return { specialCharactersNotAllowed: true };

      }

      const isValid = /^[a-zA-Z\s0-9]*(-[a-zA-Z\s0-9]+)*$/.test(value);

      if (isValid && !/^-/.test(value)) {
        return null;
      };
      return { specialCharactersNotAllowed: true };
    }
  }

  // getNameRequiredErrorMessage() {

  //   return "You must select a user";
  // }

  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }
}
