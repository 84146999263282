import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { RoleMapService } from "../../../services/role-map.service";
import { IFunctionData } from "../../../models/interfaces/function-data.model";
import { MatExpansionPanel, MatCheckboxChange } from "@angular/material";
import { FormGroup, FormControl, Validators, NgForm } from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";
import { NotificationService } from "../../../services/notification.service";
import { SiteService } from "src/app/services/site.service";
import { RequiredFieldsService } from 'src/app/services/required-fields.service';
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-role-map-modal",
  templateUrl: "./role-map-modal.component.html",
  styleUrls: ["./role-map-modal.component.css"],
})
export class RoleMapModalComponent implements OnInit {
  @ViewChild("freePanel") freezePanel: MatExpansionPanel;
  @ViewChild("createForm", {static:true}) createForm: NgForm;

  //rolenameFormControl = new FormControl("", [Validators.required]);
  // descriptionFormControl = new FormControl("", [Validators.required]);
  protected step = 0;
  protected loaded = false;
  protected roleSaved = false;
  protected roleName: string;
  protected description: string;

  protected functionData: Array<{
    FunctionID: number;
    Tag: string;
    Label: string;
    Description: string;
    DisplayOrder: number;
    FunctionCategoryID: number;
    Category: string;
    CatDisplayOrder: number;
  }>;

  public categories; // holds distinct list of categories
  protected functionsChecked: Array<{
    CategoryID: number;
    FunctionID: number;
    Active: boolean;
  }> = [];
  protected appLanguage;
  public screenLabels: Array<string> = [];
  constructor(
    public dialogRef: MatDialogRef<RoleMapModalComponent>,
    protected roleMapService: RoleMapService,
    private notificationService: NotificationService,
    protected siteService:SiteService,
    protected requiredFieldsService : RequiredFieldsService,
    protected messageService: MessageService
  ) {
    this.functionData = [];
  }

  ngOnInit(): void {
    this.roleMapService.getFunctions().then(() => {
      this.loaded = true;
      this.categories = [
        ...new Set(
          this.roleMapService.functionApiData
            .sort((a, b): number => {
              return a.CatDisplayOrder < b.CatDisplayOrder ? -1 : 1;
            })
            .map((e) => e.Category)
        ),
      ];
      this.functionData = [
        ...this.roleMapService.functionApiData.sort((a, b): number => {
          return a.DisplayOrder < b.DisplayOrder ? -1 : 1;
        }),
      ];
      // console.log(this.functionData);
    });

    //labels by message service
    if (localStorage.getItem('RoleMapLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('RoleMapLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Create Role Modal",
        "Roles"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('RoleMapLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  ngAfterViewChecked() {
    // setting up the required fields
    this.requiredFieldsService.setRequiredFields(
      'Create Role',
      this.createForm
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  onMainHeaderClose() {
    this.freezePanel.open();
  }

  onSaveRole() {
    // console.log("Role Name: " + this.roleName);
    // console.log("Description: " + this.description);
    // console.log(this.functionsChecked);

    const name = this.roleName.trim() === this.roleName ? this.roleName : this.roleName.trim();
    this.roleName = name ;
    const notes = this.description.trim() === this.description ? this.description : this.description.trim();
    this.description = notes ;

    this.roleMapService
      .createRoleMapAPI(
        this.roleName,
        this.description,
        true,
        this.functionsChecked
      )
      .then(() => {
        this.notificationService.success(this.roleMapService.message);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.success(err.message);
      });
    this.onNoClick();
  }

  isRoleNameEmpty(): boolean {
    return !this.roleName || !this.roleName.trim();
  }

  isNotesEmpty(): boolean {
    return !this.description || !this.description.trim();
  }

  nameFormControl = new FormControl("", [
    Validators.required,  // Required validation
    Validators.pattern(/^[a-zA-Z\s-][a-zA-Z\s0-9-]*$/),  // Updated pattern validation
    this.specialCharacterValidator(),
  ]);

  notesFormControl = new FormControl("", [
    Validators.required,  // Required validation
    Validators.pattern(/^(?!\s)(?!.*-\s).*$/),  // Updated pattern validation
    //this.specialCharacterValidator(),
  ]);

  // Custom validator function
  specialCharacterValidator() {
    return (control: FormControl) => {
      const value = control.value;
      if (!value) {
        return null; // No validation for empty values
      }

      // Check for spaces or hyphens followed by spaces
      if (/^\s/.test(value)) {
        return { specialCharactersNotAllowed: true };
      }
  
      const isValid = /^[a-zA-Z\s0-9]*(-[a-zA-Z\s0-9]+)*$/.test(value);
  
      if (isValid && !/^-/.test(value)) {
      return null;
    };
  return { specialCharactersNotAllowed: true };
    }
  }

  public getFunctionData(): void {
    this.roleMapService.getFunctions();
  }

  // invokes when ever  the check box value is changed
  chkBoxValueChanged(
    categoryID: number,
    functionID: number,
    $event: MatCheckboxChange
  ) {
    const tuple = {
      CategoryID: categoryID,
      FunctionID: functionID,
      Active: true,
    };
    if ($event.checked) {
      this.functionsChecked.push(tuple);
    } else {
      const foundIndex = this.functionsChecked.indexOf(tuple);
      this.functionsChecked.splice(foundIndex, 1);

      // console.log(this.functionsChecked);
    }
  }
}
