import {
  ISystemSettingsGetAPIResponse,
  ISiteConfig,
  ISiteOrgAdminRoles,
} from "../models/interfaces/system-settings-get-api.model";

import { cloneDeep } from "lodash";

export class ReadSiteSettingsOperations {
  private _siteConfigs: ISiteConfig[] = [];
  private _orgAdminRoles: ISiteOrgAdminRoles[] = [];
  private _timeZones: Array<{ Name: string }> = [];

  constructor(private _settingsAPIResponse: ISystemSettingsGetAPIResponse) {
    this._formatSiteSettings();
  }

  private _formatSiteSettings() {
    try {
      this._settingsAPIResponse = this.filterInactiveRecords(
        this._settingsAPIResponse
      );
      this._orgAdminRoles = this._settingsAPIResponse.AvailableOrgAdminRoles;
      this._siteConfigs = this._settingsAPIResponse.SystemSettings;
      this._timeZones = this._settingsAPIResponse.TimeZones;
    } catch (err) {
      console.error(err);
    }
  }

  // method that filter out the inactive records from the AvailableOrgAdminRoles and SystemSettings prop
  private filterInactiveRecords(
    settingsAPIResponse: ISystemSettingsGetAPIResponse
  ) {
    if (
      settingsAPIResponse.AvailableOrgAdminRoles &&
      settingsAPIResponse.AvailableOrgAdminRoles.length
    ) {
      settingsAPIResponse.AvailableOrgAdminRoles = settingsAPIResponse.AvailableOrgAdminRoles.filter(
        (oar) => oar.Active === 1
      );
    }

    // filter the inactive system settings
    if (
      settingsAPIResponse.SystemSettings &&
      settingsAPIResponse.SystemSettings.length
    ) {
      settingsAPIResponse.SystemSettings = settingsAPIResponse.SystemSettings.filter(
        (ss) => ss.Active === 1
      );
    }

    return settingsAPIResponse;
  }

  // method to get the POI syllable count
  public getPOISyllableCount() {
    let tempSettingsObj = this._siteConfigs.find(
      (sc) =>
        sc.Name === "SoundexSyllable" &&
        sc.Category === "POIChecking" &&
        sc.Active === 1
    );
    return tempSettingsObj ? +tempSettingsObj.Value : null;
  }

  // method to get the POI Name count
  public getPOINameCount() {
    let tempSettingsObj = this._siteConfigs.find(
      (sc) =>
        sc.Name === "NameCount" &&
        sc.Category === "POIChecking" &&
        sc.Active === 1
    );
    return tempSettingsObj ? +tempSettingsObj.Value : null;
  }

  // method to get the POI Article count
  public getPOIArticleCount() {
    let tempSettingsObj = this._siteConfigs.find(
      (sc) =>
        sc.Name === "ArticleCount" &&
        sc.Category === "POIChecking" &&
        sc.Active === 1
    );
    return tempSettingsObj ? +tempSettingsObj.Value : null;
  }

  // method to get the POI Suffix count
  public getPOISuffixCount() {
    let tempSettingsObj = this._siteConfigs.find(
      (sc) =>
        sc.Name === "SuffixCount" &&
        sc.Category === "POIChecking" &&
        sc.Active === 1
    );
    return tempSettingsObj ? +tempSettingsObj.Value : null;
  }

  // method to get smart search max row limit
  public getSmartSearchMaxRowLimit() {
    return this._settingsAPIResponse.SmartSearchMaxLimit
      ? +this._settingsAPIResponse.SmartSearchMaxLimit
      : null;
  }

  // method to get smart search row limit
  public getSmartSearchRowLimit() {
    let tempSettingsObj = this._siteConfigs.find(
      (sc) =>
        sc.Name === "SmartSearchLimit" &&
        sc.Category === "SmartSearch" &&
        sc.Active === 1
    );

    return tempSettingsObj ? +tempSettingsObj.Value : null;
  }

  // method to get all the timezones
  public getAllTimeZones(): { Name: string }[] {
    return cloneDeep(this._timeZones);
  }

  // method to get the selected timezone
  public getSelectedTimeZone() {
    // get selected timezone
    let tempSettingsObj = this._siteConfigs.find(
      (sc) =>
        sc.Name === "SiteTimeZone" &&
        sc.Category === "SiteTimeZone" &&
        sc.Active === 1
    );
    return tempSettingsObj ? tempSettingsObj.Value : null;
  }

  // method to get the selected org admin roles
  public getAvailableOrgAdminRoles(): ISiteOrgAdminRoles[] {
    return this._orgAdminRoles ? cloneDeep(this._orgAdminRoles) : [];
  }

  public getDateFormat() {
    const tempSettingsObj = this._settingsAPIResponse.SystemSettings.find(
      (sc) =>
        sc.Name === "DateFormat" && sc.Category === "Date" && sc.Active === 1
    );

    return tempSettingsObj ? tempSettingsObj.Value : null;
  }

  public getTimeFormat() {
    const tempSettingsObj = this._settingsAPIResponse.SystemSettings.find(
      (sc) =>
        sc.Name === "TimeFormat" && sc.Category === "Time" && sc.Active === 1
    );

    return tempSettingsObj ? tempSettingsObj.Value : null;
  }

  public getPhoneFormat() {
    const tempSettingsObj = this._settingsAPIResponse.SystemSettings.find(
      (sc) =>
        sc.Name === "Phone" && sc.Category === "Info" && sc.Active === 1
    );

    return tempSettingsObj ? tempSettingsObj.Value : null;
  }

  public getPassCheckInThreshold() {
    const tempSettingsObj = this._settingsAPIResponse.SystemSettings.find(
      (sc) =>
        sc.Name === "CheckInThreshold" &&
        sc.Category === "Pass" &&
        sc.Active === 1
    );

    return tempSettingsObj ? +tempSettingsObj.Value : null;
  }

  public checkParkingEnabled() {
    const tempSettingsObj = this._settingsAPIResponse.SystemSettings.find(
      (sc) =>
        sc.Name === "ParkingEnabled" &&
        sc.Category === "Parking" &&
        sc.Active === 1
    );

    return tempSettingsObj ? +tempSettingsObj.Value : null;
  }

   public checkTemporaryBadgeEnabled(){
    const tempSettingsObj = this._settingsAPIResponse.SystemSettings.find(
      (sc) =>
        sc.Name === "TemporaryBadgeEnabled" &&
        sc.Category === "Badge" &&
        sc.Active === 1
    );

    return tempSettingsObj  ? +tempSettingsObj.Value : null;
  }

  // public checkPurgeEnabled(){
  //   const tempSettingsObj = this._settingsAPIResponse.SystemSettings.find(
  //     (sc) =>
  //       sc.Name === "EnableDelete" &&
  //       sc.Category === "Pass" &&
  //       sc.Active === 1
  //   );

  //   return tempSettingsObj  ? +tempSettingsObj.Value : null;
  // }

  // public getPurgeCount(){
  //   let tempSettingsObj = this._settingsAPIResponse.SystemSettings.find(
  //     (sc)=>
  //     sc.Name === "DeleteThreshold" &&
  //     sc.Category === "Pass" &&
  //     sc.Active === 1
  //   )
  //   return tempSettingsObj ? tempSettingsObj.Value : null;
  // }

  public checkinPhotoEnabled(){
    const tempSettingsObj = this._settingsAPIResponse.SystemSettings.find(
      (sc) =>
        sc.Name === "PassTransaction" &&
        sc.Category === "CheckInPhoto" &&
        sc.Active === 1
    );

    return tempSettingsObj  ? +tempSettingsObj.Value : null;
  }

  public cameraEquipmentEnabled(){
    const tempSettingsObj = this._settingsAPIResponse.SystemSettings.find(
      (sc) =>
        sc.Name === "Pass" &&
        sc.Category === "CameraEquipment" &&
        sc.Active === 1
    );

    return tempSettingsObj  ? +tempSettingsObj.Value : null;
  }

public callinPassEnabled() {
  const tempSettingsObj = this._settingsAPIResponse.SystemSettings.find(
    (sc) =>
      sc.Name === "CallInPassEnabled" &&
      sc.Category === "CallInPass" &&
      sc.Active === 1
  );

  return tempSettingsObj  ? +tempSettingsObj.Value : null;
}

  public getDefaultStartTime(){
    let tempSettingsObj = this._siteConfigs.find(
      (sc)=>
      sc.Name === "DefaultStartTime" &&
      sc.Category === "SiteTimeZone" &&
      sc.Active === 1
    )
    return tempSettingsObj ? tempSettingsObj.Value : null;
  }
  public getDefaultEndTime(){
    let tempSettingsObj = this._siteConfigs.find(
      (sc)=>
      sc.Name === "DefaultEndTime" &&
      sc.Category === "SiteTimeZone" &&
      sc.Active === 1
    )
    return tempSettingsObj ? tempSettingsObj.Value : null;
  }
  public getDefaultReasonOfVisit(){
    let tempSettingsObj = this._siteConfigs.find(
      (sc)=>
      sc.Name === "DefaultVisitReason" &&
      sc.Category === "Pass" &&
      sc.Active === 1
    )
    return tempSettingsObj ? tempSettingsObj.Value : null;
  }

  public getDefaultSiteType(){
    let tempSettingsObj = this._siteConfigs.find(
      (sc)=>
      sc.Name === "Type" &&
      sc.Category === "General" &&
      sc.Active === 1
    )
    return tempSettingsObj ? tempSettingsObj.Value : null;
  }

  public checkCountryEnabled(){
    const tempSettingsObj = this._settingsAPIResponse.SystemSettings.find(
      (sc) =>
        sc.Name === "CountryEnabled" &&
        sc.Category === "Country" &&
        sc.Active === 1
    );

    return tempSettingsObj  ? +tempSettingsObj.Value : null;
  }

  public checkCloudPrintEnabled(){
    const tempSettingsObj = this._settingsAPIResponse.SystemSettings.find(
      (sc) =>
        sc.Name === "CloudPrintingEnabled" &&
        sc.Category === "CloudPrinting" &&
        sc.Active === 1
    );

    return tempSettingsObj  ? +tempSettingsObj.Value : null;
  }

  public getCloudPrintServer(){
    let tempSettingsObj = this._settingsAPIResponse.SystemSettings.find(
      (sc) =>
        sc.Name === "CloudPrintWebServer" &&
        sc.Category === "CloudPrinting" &&
        sc.Active === 1
    );
    return tempSettingsObj ? tempSettingsObj.Value : null;
  }

  public getTemplate(){
    let tempSettingsObj = this._settingsAPIResponse.SystemSettings.find(
      (sc)=>
      sc.Name === "VisitorPass" &&
      sc.Category === "PrintTemplateOptions" &&
      sc.Active === 1
    )
    return tempSettingsObj ? tempSettingsObj.Value : null;
  }
  public getCarTemplate(){
    let tempSettingsObj = this._settingsAPIResponse.SystemSettings.find(
      (sc)=>
      sc.Name === "ParkingPass" &&
      sc.Category === "PrintTemplateOptions" &&
      sc.Active === 1
    )
    return tempSettingsObj ? tempSettingsObj.Value : null;
  }
  public getCloudTemplate(){
    let tempSettingsObj = this._settingsAPIResponse.SystemSettings.find(
      (sc)=>
      sc.Name === "CloudVisitorPass" &&
      sc.Category === "PrintTemplateOptions" &&
      sc.Active === 1
    )
    return tempSettingsObj ? tempSettingsObj.Value : null;
  }
  public getCarCloudTemplate(){
    let tempSettingsObj = this._settingsAPIResponse.SystemSettings.find(
      (sc)=>
      sc.Name === "CloudParkingPass" &&
      sc.Category === "PrintTemplateOptions" &&
      sc.Active === 1
    )
    return tempSettingsObj ? tempSettingsObj.Value : null;
  }
  // public getArchivalCount(){
  //   let tempSettingsObj = this._settingsAPIResponse.SystemSettings.find(
  //     (sc)=>
  //     sc.Name === "ArchiveThreshold" &&
  //     sc.Category === "Pass" &&
  //     sc.Active === 1
  //   )
  //   return tempSettingsObj ? tempSettingsObj.Value : null;
  // }
}
