import { Injectable, Output, EventEmitter } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { UserService } from "./user.service";
import {
  GridsterConfig,
  Draggable,
  Resizable,
  PushDirections,
  GridsterItem,
  GridType,
  CompactType,
  DisplayGrid,
} from "angular-gridster2";
import { DialogService } from "./dialog.service";
import { NotificationService } from "./notification.service";
import { environment } from "src/environments/environment";
import { clone, cloneDeep } from "lodash";
import { UtilityService } from "./utility.service";
import { SiteService } from "./site.service";

export enum widgetType {
  SingleValueWidget = "Single Value Widget", // small widget
  LineChartWidget = "Line Chart Widget", // large widget
  PieChartWidget = "Pie Chart Widget", // large widget
  BarChartWidget = "Bar Chart Widget", // large widget
}

interface GridOption extends GridsterConfig {
  draggable: Draggable;
  resizable: Resizable;
  pushDirections: PushDirections;
}

interface Widget extends GridsterItem {
  category: string;
  title: string;
  type: string;
  userWidgetID: number;
  userID: number;
  widgetID: number;
  active: number;
  data: any;
}

@Injectable({
  providedIn: "root",
})
export class WidgetService {
  public message: string;
  @Output() updateWidgets: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() dashboardWidgetDataReady: EventEmitter<Widget[]> = new EventEmitter<
    Widget[]
  >();

  private readonly TIME_INTERVAL = 30 * 1000; // update every 30 seconds

  // properties for gridster
  public options: GridOption;

  // array  to hold widget
  public dashboardWidgets: Array<Widget> = [];
  public originalDashboardWidget: Array<Widget> = []; // used to get the full set of available series for line chart widget
  public userWidgetConfig: [
    // used to store the widget configuration
    {
      UserWidgetFilterID: number;
      UserWidgetID: number;
      DateOption: number;
      Series: string[];
      Active: number;
    }
  ];

  private widgetOption: Array<{
    Category: string;
    Widget: Array<{
      Value: number;
      ViewValue: string;
      Type: string;
      FunctionID: number;
    }>;
  }> = [];

  private lineChartData: Array<{
    name: string;
    series: Array<{
      value: number;
      name: any;
    }>;
  }> = [];

  private filterStartDate: Date;
  private filterEndDate: Date;
  private dateOptions: Array<{
    PickListID: number;
    TableName: string;
    FieldName: string;
    DisplayValue: string;
    Active: string;
  }> = [];

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private dialogService: DialogService,
    public notificationService: NotificationService,
    private utilService: UtilityService,
    private siteService:SiteService
  ) {
    // setting grid option
    this.options = {
      gridType: GridType.Fixed,
      compactType: CompactType.None,
      margin: 10,
      outerMargin: true,
      outerMarginTop: null,
      outerMarginRight: null,
      outerMarginBottom: null,
      outerMarginLeft: null,
      useTransformPositioning: true,
      mobileBreakpoint: 140,
      minCols: 1,
      maxCols: 1,
      minRows: 4,
      maxRows: 4,
      // maxItemCols: 2,
      // minItemCols: 1,
      // maxItemRows: 2,
      // minItemRows: 1,
      // maxItemArea: 2500,
      // minItemArea: 1,
      // defaultItemCols: 2,
      // defaultItemRows: 2,
      fixedColWidth: 140,
      fixedRowHeight: 100,
      keepFixedHeightInMobile: false,
      keepFixedWidthInMobile: false,
      scrollSensitivity: 10,
      scrollSpeed: 20,
      enableEmptyCellClick: false,
      enableEmptyCellContextMenu: false,
      enableEmptyCellDrop: false,
      enableEmptyCellDrag: false,
      enableOccupiedCellDrop: false,
      emptyCellDragMaxCols: 50,
      emptyCellDragMaxRows: 50,
      ignoreMarginInRow: false,
      draggable: {
        enabled: true,
      },
      resizable: {
        enabled: false,
      },
      swap: false,
      pushItems: true,
      disablePushOnDrag: false,
      disablePushOnResize: false,
      pushDirections: { north: true, east: false, south: true, west: false },
      pushResizeItems: false,
     // displayGrid: DisplayGrid.OnDragAndResize,
      disableWindowResize: false,
      disableWarnings: false,
      disableAutoPositionOnConflict: false,
      scrollToNewItems: false,
      setGridSize: true,
      itemChangeCallback: (item, itemComponent) =>
        this.itemChange(item, itemComponent),
      itemResizeCallback: (item, itemComponent) => this.itemResize,
    };

    this.refreshData();
  }

  // get parking lot group analytics
  public parkingLotGroupAnalyticsAPI(
    organizationID: number,
    startDate: Date,
    endDate: Date
  ): Promise<any> {
    const dateRegex: RegExp = /^([0-9]{2,4})-([0-1][0-9])-([0-3][0-9])(?:( [0-2][0-9]):([0-5][0-9]):([0-5][0-9]))?(.[0-9]{1,6})?$/;

    const apiURL = environment.getAPI("getAvailableParkingAnalytics");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const body = {
      OrganizationID: organizationID,
      StartDate: startDate,
      EndDate: endDate,
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.lineChartData = success.body;

            this.lineChartData.forEach((item) => {
              item.series.forEach((row) => {
                // Is Date-Time Type
                if (dateRegex.test(row.name)) {
                  row.name = new Date(row.name);
                }
              });
            });

            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public getLineChartDate() {
    return [...this.lineChartData];
  }

  // get date options from pick list table
  public getDateOptionsAPI() {
    const apiURL = environment.getAPI("getPickList");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise<any>((resolve, reject) => {
      // resolve if dateOption values are already pre-populated from Pick List
      if (this.dateOptions.length) {
        resolve([...this.dateOptions]);
      }

      //try to retrieve the dateOptions from Pick List
      else {
        this.http
          .post(apiURL, { TableName: "UserWidgetFilter",SiteID:this.siteService.selectedSiteID }, httpOptions)
          .toPromise()
          .then(
            (success: HttpResponse<any>) => {
              // success
              this.dateOptions = success.body.map((e) => ({
                PickListID: e.PickListID,
                TableName: e.TableName,
                FieldName: e.FieldName,
                DisplayValue: e.DisplayValue,
                Active: e.Active,
              }));
              // console.log(success.body.map((e) => e.DisplayValue));

              this.dateOptions = [
                ...this.dateOptions.filter((d) => d.FieldName === "DateOption"),
              ];
              resolve([...this.dateOptions]);
            },
            (err) => {
              // error
              reject(err);
            }
          );
      }
    });
    return promise;
  }

  // logic to refresh widget data
  public refreshData() {
    setInterval(() => {
      this.updateWidgets.emit(true);
    }, this.TIME_INTERVAL);
  }

  // get all widget option api call
  public getAllWidgetsAPI(): Promise<any> {
    const apiURL = environment.getAPI("getWidgets");

    // console.log(postBody);
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, { UserID: this.userService.userID,SiteID:this.siteService.selectedSiteID }, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.widgetOption = success.body;
            // console.log(this.widgetOption);

            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // the method would be invoked to filter user widget data api
  private filterUserWidgetDataAPI(
    userWidgetFilterConfig: Array<{
      UserWidgetFilterID: number;
      UserWidgetID: number;
      DateOption: number;
      DateOptionValue: string;
      Series: Array<string>;
      Active: number;
    }>,
    rawDashboardWidget: Array<{
      category: string;
      title: string;
      type: string;
      userWidgetID: number;
      userID: number;
      widgetID: number;
      active: number;
    }>
  ): Promise<any> {
    const apiURL = environment.getAPI("filterWidgetAPI");

    // console.log(postBody);
    const body = {
      user_widget_filter_config: userWidgetFilterConfig,
      raw_dashboard_widget: rawDashboardWidget,
    };

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.widgetOption = success.body;
            // console.log(this.widgetOption);

            resolve(success.body);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public getAllWidgets() {
    return [...this.widgetOption];
  }

  // get all widgets assigned to the user
  public async getAssignedUserWidgetsAPI(): Promise<any> {
    const apiURL = environment.getAPI("getUserWidgets");    

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"        
      }),
      observe: "response",
    };

    const body = {
      UserID: this.userService.userID,
      OrganizationID: this.userService.organizationID,
      SiteID:this.siteService.selectedSiteID
    };

    // console.log(JSON.stringify(body));

    const promise = new Promise(async (resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          async (success: HttpResponse<any>) => {
            // success
            const userWidgets: Array<{
              Title: string;
              Category: string;
              Type: string;
              UserWidgetID: number;
              UserID: number;
              WidgetID: number;
              PosX: number;
              PosY: number;
              Active: number;
              ChartData: any;
            }> = success.body;

            // console.log(userWidgets);

            // this.dashboardWidgets = [];
            const tempDashboardWidgets = [];
            const addedToWidgetUser = [];

            userWidgets.forEach((widget) => {
              const widgetItem: Widget = {
                category: widget.Category,
                title: widget.Title,
                type: widget.Type,
                userWidgetID: widget.UserWidgetID,
                userID: widget.UserID,
                widgetID: widget.WidgetID,
                active: widget.Active,
                data: widget.ChartData,
                x: widget.PosX,
                y: widget.PosY,
                rows: widget.Type === widgetType.SingleValueWidget ? 1 : 2,
                cols: widget.Type === widgetType.SingleValueWidget ? 1 : 2,
              };

              tempDashboardWidgets.push(widgetItem);
            });

            const lineChartUserWidgetIDs = tempDashboardWidgets
              .filter((dw: Widget) => dw.type === widgetType.LineChartWidget)
              .map((dw: Widget) => dw.userWidgetID);

            // read all line chart widget config
            let userWidgetConfigs: Array<{
              UserWidgetFilterID: number;
              UserWidgetID: number;
              DateOption: number;
              DateOptionValue: string;
              Series: string[];
              Active: number;
            }> = [];

            // read all user widget config data
            for await (let uswID of lineChartUserWidgetIDs) {
              await this.getUserWidgetConfigAPI(uswID);
              const widgetConfig = { ...this.userWidgetConfig[0] };
              const dateOptionValue = (await this.getDateOptionsAPI()).find(
                (dOpt) => dOpt.PickListID === widgetConfig.DateOption
              ).DisplayValue;

              userWidgetConfigs.push({
                UserWidgetFilterID: widgetConfig.UserWidgetFilterID,
                UserWidgetID: widgetConfig.UserWidgetID,
                DateOption: widgetConfig.DateOption,
                DateOptionValue: dateOptionValue,
                Series: widgetConfig.Series,
                Active: widgetConfig.Active,
              });
            }

            const rawDashboardWidget = tempDashboardWidgets;
            this.originalDashboardWidget = cloneDeep(tempDashboardWidgets);

            // filter widget api data
            const filteredWidgetData = await this.filterUserWidgetDataAPI(
              userWidgetConfigs,
              rawDashboardWidget
            );

            // formatting Line charts having date
            filteredWidgetData.forEach((widget) => {
              if (widget.type === widgetType.LineChartWidget) {
                const dateTimeRegex = /^([0-9]{2,4})-([0-1][0-9])-([0-3][0-9])(?:( [0-2][0-9]):([0-5][0-9]):([0-5][0-9]))?$/;

                widget.data.forEach((data) => {
                  data.series.forEach((s) => {
                    if (dateTimeRegex.test(s.name)) {
                      s.name = new Date(s.name);
                      // console.log("Its a date " + s.name);
                    }
                  });
                });
              }
            });

            filteredWidgetData.sort((a, b) =>
              a.userWidgetID > b.userWidgetID ? 1 : -1
            );

            this.dashboardWidgets.sort((a, b) =>
              a.userWidgetID > b.userWidgetID ? 1 : -1
            );

            // code to prevent flicking
            for (let i = 0; i < this.dashboardWidgets.length; i++) {
              if (
                this.dashboardWidgets[i].userWidgetID ===
                filteredWidgetData[i].userWidgetID
              ) {
                this.dashboardWidgets[i].data = [];
                this.dashboardWidgets[i].data = filteredWidgetData[i].data;
              } else {
                if (
                  !addedToWidgetUser.includes(
                    filteredWidgetData[i].userWidgetID
                  )
                )
                  this.dashboardWidgets.push(filteredWidgetData[i]);
              }
              addedToWidgetUser.push(this.dashboardWidgets[i].userWidgetID);
            }

            if (filteredWidgetData.length > this.dashboardWidgets.length) {
              for (let i = 0; i < filteredWidgetData.length; i++) {
                if (
                  !addedToWidgetUser.includes(
                    filteredWidgetData[i].userWidgetID
                  )
                ) {
                  this.dashboardWidgets.push(filteredWidgetData[i]);
                  // this.originalDashboardWidget = [...this.dashboardWidgets];

                  addedToWidgetUser.push(filteredWidgetData[i].userWidgetID);
                }
              }
            }
            // .code to prevent flicking

            // this.dashboardWidgets = tempDashboardWidgets;

            // console.log(this.dashboardWidgets);
            // this.originalDashboardWidget = [...this.dashboardWidgets];

            // for await (let dw of this.dashboardWidgets) {
            //   // console.log("Looping widget");
            //   // console.log(dw);

            //   const widget: Widget = {
            //     active: dw.active,
            //     category: dw.category,
            //     cols: dw.cols,
            //     data: dw.data,
            //     rows: dw.rows,
            //     title: dw.title,
            //     type: dw.type,
            //     userID: dw.userID,
            //     userWidgetID: dw.userWidgetID,
            //     widgetID: dw.widgetID,
            //     x: dw.x,
            //     y: dw.y,
            //   };

            //   if (widget.type === "Line Chart Widget") {
            //     // console.log("Line chart widget");
            //     widget.data.forEach((d) => {
            //       d.name = d.name;
            //       d.series = [...d.series];
            //     });

            //     // console.log("Reading Line Chart user widget:", dw.userWidgetID);

            //     // apply widget config filter to widget
            //     await this.getUserWidgetConfigAPI(dw.userWidgetID);
            //     // console.log(
            //     //   "Line Chart Config Read for User Widget ID",
            //     //   dw.userWidgetID
            //     // );

            //     // check if the widget has configurations set
            //     if (this.userWidgetConfig[0]) {
            //       console.log(this.userWidgetConfig);
            //       // filter series labels
            //       dw.data = this.filterSeries(
            //         this.userWidgetConfig[0].Series,
            //         dw.data
            //       );

            //       dw.data.forEach((d) => {
            //         const dateOption = this.userWidgetConfig[0].DateOption;
            //         // console.log("Before Date Filter(Series): ", d.series);
            //         // console.log("Date Option:", dateOption);
            //         d.series = this.filterDate(dateOption, d.series);
            //       });

            //       // console.log(dw.data);

            //       this.dashboardWidgets.find(
            //         (dashWidget) => dashWidget.userWidgetID === dw.userWidgetID
            //       ).data = dw.data;
            //     }
            //   }
            //   this.originalDashboardWidget.push(widget);
            // }

            // this.dashboardWidgets = [...this.dashboardWidgets];

            console.log(this.dashboardWidgets);
            // console.log("getAssignedUserWidgetAPI is resolved");
            this.dashboardWidgetDataReady.emit(this.dashboardWidgets);
            resolve(this.dashboardWidgets);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // get assigned user widget data by user widget ID
  public getAssignedUserWidgetByIDAPI(userWidgetID: number) {
    const apiURL = environment.getAPI("getUserWidgets");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const body = {
      UserID: this.userService.userID,
      OrganizationID: this.userService.organizationID,
      UserWidgetID: userWidgetID,
      SiteID:this.siteService.selectedSiteID
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            resolve(success.body);
          },
          (err) => reject(err)
        );
    });
    return promise;
  }

  // invoke this method to save user widget config
  public saveUserWidgetConfigAPI(
    dateOptions: number,
    userWidgetID: number,
    seriesLabelArray: string[],
    userWidgetFilterID?: number
  ): Promise<any> {
    const apiURL = environment.getAPI("setUserWidgetConfig");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const body = {
      UserWidgetFilterID: userWidgetFilterID ? userWidgetFilterID : 0,
      DateOption: dateOptions,
      UserWidgetID: userWidgetID,
      Active: 1,
      Series: seriesLabelArray.join(","),
      UserID: this.userService.userID,
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });

    return promise;
  }

  // invoke this method to get the user widget config
  public getUserWidgetConfigAPI(userWidgetID: number) {
    const apiURL = environment.getAPI("getUserWidgetConfig");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const body = {
      UserWidgetID: userWidgetID,
      SiteID:this.siteService.selectedSiteID
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.userWidgetConfig = success.body;
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });

    return promise;
  }

  public getAssignedWidgets() {
    return this.dashboardWidgets;
  }

  // create or update widget for user API
  public CreateUpdateWidgetAPI(
    widgetID: number,
    posX: number,
    posY: number,
    active: boolean,
    userWidgetID?: any
  ): Promise<any> {
    if (userWidgetID === undefined || userWidgetID === null) userWidgetID = "";

    const apiURL = environment.getAPI("userWidgetCreateUpdate");

    // console.log(postBody);
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const body = {
      UserWidgetID: userWidgetID,
      WidgetID: widgetID,
      PosX: posX,
      PosY: posY,
      Active: active,
      UserID: this.userService.userID,
      SiteID:this.siteService.selectedSiteID
    };

    // console.log(body);

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            // console.log(success.body);
            // console.log("Widget Updated / Created Successfully");

            resolve(success.body.UserWidgetID);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // Delete widget for user API
  public DeleteWidgetAPI(userWidgetID: number): Promise<any> {
    const apiURL = environment.getAPI("removeUserWidget");

    // console.log(postBody);
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const body = {
      UserWidgetID: userWidgetID,
    };

    // console.log(body);

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
            // console.log(success.body);

            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public itemChange(item, itemComponent) {
    console.info("itemChanged", item, itemComponent);

    if (item.userWidgetID === undefined || item.userWidgetID === null)
      item.userWidgetID = "";

    this.CreateUpdateWidgetAPI(
      item.widgetID,
      item.x,
      item.y,
      item.active === 1 ? true : false,
      item.userWidgetID
    ).then(() => {
      //console.log("Position Saved Successfully")
    });
  }

  public itemResize(item, itemComponent) {
    console.info("itemResized", item, itemComponent);
  }

  // check if a large widget could be pushed
  CanAccommodateLargeWidget() {
    // generate a temporary widget
    const tempGridItem: GridsterItem = {
      x: -1,
      y: -1,
      rows: 1,
      cols: 1,
    };

    // retrieve the the first available position for the chart to be append to the grid
    const item = this.options.api.getFirstPossiblePosition(tempGridItem);

    return this.options.api.getNextPossiblePosition(item);
  }

  // check if a small widget could be pushed
  CanAccommodateSmallWidget() {
    // generate a temporary widget
    const tempGridItem: GridsterItem = {
      x: -1,
      y: -1,
      rows: 1,
      cols: 1,
    };

    // retrieve the the first available position for the chart to be append to the grid
    const item = this.options.api.getFirstPossiblePosition(tempGridItem);
    return this.options.api.getNextPossiblePosition(item);
  }

  

  public filterDate(
    dateOption: number,
    series: Array<{ name: Date; value: number }>
  ) {
    if (dateOption === 13) {
      // filter for 1st week
      this.setWeekStartAndEndDate(1);
    }

    if (dateOption === 14) {
      // filter for 2nd week
      this.setWeekStartAndEndDate(2);
    }

    if (dateOption === 15) {
      // filter for 3rd week
      this.setWeekStartAndEndDate(3);
    }

    if (dateOption === 16) {
      // filter for 4th week
      this.setWeekStartAndEndDate(4);
    }

    if (dateOption === 17) {
      // filter for 5th week
      this.setWeekStartAndEndDate(5);
    }

    if (dateOption === 18) {
      // filter for 6th week
      this.setWeekStartAndEndDate(6);
    }

    if (dateOption === 19) {
      // filter for all weeks upto 6 weeks
      this.setWeekStartAndEndDate(6);
      this.filterStartDate = this.utilService.getCurrentSiteDT();
    }

    series = [
      ...series.filter(
        (s) => s.name <= this.filterEndDate && s.name >= this.filterStartDate
      ),
    ];

    // console.log("Filter Start Date: ", this.filterStartDate);
    // console.log("Filter Start Date: ", this.filterEndDate);

    // console.log("After filter (Series):", series);

    return series;
  }

  // week index start from 1
  private setWeekStartAndEndDate(weekNumber: number) {
    if (weekNumber === 1) {
      this.filterStartDate = this.utilService.getCurrentSiteDT();
      this.filterEndDate = new Date(
        this.utilService
          .getCurrentSiteDT()
          .setDate(this.utilService.getCurrentSiteDT().getDate() + 7)
      );

      return;
    }

    this.filterStartDate = new Date(
      this.utilService
        .getCurrentSiteDT()
        .setDate(
          this.utilService.getCurrentSiteDT().getDate() +
            7 * (weekNumber - 1) +
            1
        )
    );
    this.filterEndDate = new Date(
      this.utilService
        .getCurrentSiteDT()
        .setDate(this.utilService.getCurrentSiteDT().getDate() + 7 * weekNumber)
    );
  }

  public filterSeries(
    seriesLabels: Array<string>,
    graphData: Array<{
      name: string;
      series: Array<{ name: Date; value: number }>;
    }>
  ) {
    const data = graphData.filter((d) => seriesLabels.includes(d.name));
    console.log(data);

    return [...data];
  }
}
