import { Component, OnInit } from "@angular/core";
import { MatDialogConfig, MatDialog } from "@angular/material";
import { NewpassModalComponentMobile } from "../newpass-modal/mobile/newpass-modal.component.mobile";
import { NewpassModalComponentDesktop } from "../newpass-modal/desktop/newpass-modal.component.desktop";
import { ViewSelectorService } from "../services/view-selector.service";
import { SpinnerService } from "../services/spinner.service";
import { AuthService } from "../services/auth.service";
import { CreateBadgeHolderPassModalComponent } from "../create-badge-holder-pass/create-badge-holder-pass-modal.component";
import { UserService } from "../services/user.service";
import { Site, SiteService } from "../services/site.service";
import { analyzeFileForInjectables } from "@angular/compiler";
import { PassService } from "../services/pass.service";
import { NotificationService } from "../services/notification.service";
import { MessageService } from "../services/message.service";
import { HttpResponse } from "@angular/common/http";
//import { MessageService } from "src/app/services/message.service";
@Component({
  selector: "app-master-layout",
  templateUrl: "./desktop/master-layout.desktop.component.html",
  styleUrls: ["./desktop/master-layout.desktop.component.css"],
})
export abstract class MasterLayoutComponent implements OnInit {
  protected isMobile = false;
  orgAdminPanelState: boolean = false;
  siteAdminPanelState: boolean = false;
  adminPanelState: boolean = false;
  reportPanelState: boolean = false;
  protected callInUser: boolean = false;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  protected userAuthenticated: boolean = false;

  constructor(
    protected viewSelectorService: ViewSelectorService,
    protected dialog: MatDialog,
    protected spinnerService: SpinnerService,
    protected authService: AuthService,
    protected userService: UserService,
    protected siteService:SiteService,
    protected passService: PassService,
    protected notificationService: NotificationService,
    protected messageService: MessageService,
  ) {}
  ngOnInit(): void {
    this.isMobile = this.viewSelectorService.getIsMobile();

    //labels by message service
    if (localStorage.getItem('MasterLayoutLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('MasterLayoutLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Master Layout Page",
        "MasterLayout"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('MasterLayoutLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    this.authService.userChangedToCallInUser.subscribe((event: boolean) => {
      if (event) {
        if (this.authService.validCaller) {
          this.callInUser = true;
        } else {
          this.callInUser = false;
        }
      }
    });
    //subscribing subject to get if callinuser is authenticated or not
    this.userService.getCallInUserAuthenticated().subscribe((data) => {
      this.userAuthenticated = data.status;
    });
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }
  // opens new Badge modal
  createBadgeHolderPass() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.backdropClass = "backdropBackground";
    if (this.isMobile === true) {
      this.dialog.open(CreateBadgeHolderPassModalComponent, 
        dialogConfig);
    } else {
      this.dialog.open(CreateBadgeHolderPassModalComponent, dialogConfig);
    }
  }
  //on terminate call in user
  onTerminate() {
    this.authService.validCaller = false;
    this.userService.userID = this.userService.loggedInUserID ;
    this.userService.userOrganizations = this.userService.loggedInUserOrganizations;
    this.userService.organizationID = this.userService.loggedInUserOrganizationID;    
    this.passService.checkIsIgnoreLocksAPI(this.userService.userID, this.userService.organizationID, false)
    .subscribe((res: HttpResponse<any>) => {
      this.passService.ignoreLocks = res.body.IsIgnoreLocks;
    }, error => {
      this.authService.logout();
      console.log(error);
    });
    this.userService.loggedInUserFirstName = this.userService.firstName  ;
    this.userService.loggedInUserMiddleName = this.userService.middleName ;
    this.userService.loggedInUserLastName = this.userService.lastName ;
    this.userService.loggedInSuffix = this.userService.suffix ;
    this.userService.loggedInEmail = this.userService.email ;
    this.userService.loggedInPhone = this.userService.phone ;
    this.userService.userFunctions = this.userService.loggedInUserFunctions;
    this.userService.callInUserID = 0;
    this.userService.organizationChanged.emit(true);
    this.authService.userChangedToCallInUser.emit(true);
    this.userService.setCallInUserAuthenticated(false);
  }
  toggleSiteAdminPanel() {
    this.siteAdminPanelState = false;
  }
  openedSiteAdminPanel() {
    this.siteAdminPanelState = true;
  }
  toggleOrgAdminPanel() {
    this.orgAdminPanelState = false;
  }
  openedOrgAdminPanel() {
    this.orgAdminPanelState = true;
  }
 
  openedAdminPanel() {
    
    if(this.adminPanelState){
      this.adminPanelState = false;
      this.orgAdminPanelState = false;
      this.siteAdminPanelState = false;
    }else{
      this.adminPanelState = true;
    }
  }

  openedReportPanel(){
    if(this.reportPanelState){
      this.reportPanelState = false;
    }else{
      this.reportPanelState = true;
    }
  }

  toggleReportPanel() {
    this.reportPanelState = false;
  }
}
