import { Injectable, EventEmitter, Output } from "@angular/core";
import { EntryPoint } from "../models/entry-point.model";
import { PersonOfInterest, RestrictionType } from "../models/person-of-interest.model";
import { HttpHeaders, HttpResponse, HttpClient } from "@angular/common/http";
import { UserService } from "./user.service";
import { NotificationService } from "./notification.service";
import { environment } from "src/environments/environment";
import { Site, SiteService } from "./site.service";

@Injectable({
  providedIn: "root",
})
export class PersonOfInterestService {
  PickListID: number;
  public personofInterestData: PersonOfInterest[] = [];
  public personOfInterests: PersonOfInterest[] = [];

  private restrictionType : RestrictionType[];

  private poiData : PersonOfInterest[];

  @Output() parkingLotDataChanged: EventEmitter<boolean>;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private notificationService: NotificationService,
    private siteService:SiteService
  ) {}

  public getPoiMatchList(guestName: string): Promise<any> {
    const apiURL = environment.getAPI("getPOIMatchList");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const body: {
      SearchString: string;
      SiteID:number;
    } = {
      SearchString: guestName,
      SiteID:this.siteService.selectedSiteID
    };
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            this.personofInterestData = success.body;
            this.personOfInterests = [];
            this.personofInterestData.forEach((element) => {
              let poi = new PersonOfInterest(
                element.PersonOfInterestID,
                element.FirstName,
                element.MiddleName,
                element.LastName,
                element.Suffix,
                element.Company,
                element.Phone,
                element.Email,
                element.Notes,
                element.NotificationEmail,
                element.RestrictedOn,
                element.RestrictionEndDate,
                element.RestrictedReason,
                element.Restricted,
                // element.restrictionTypa
              );
              this.personOfInterests.push(poi);
            });
            resolve();
          },
          (err) => {
            reject(err);
          }
        );
    });
    return promise;
  }

   // get reason for visit from pick list table
   public getDisplayValueAPI() {
    const apiURL = environment.getAPI("getPickList");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise<
      {
        PickListID: number;
        TableName: string;
        FieldName: string;
        DisplayValue: number;
        Active: boolean;
      }[]
    >((resolve, reject) => {
      this.http
        .post(
          apiURL,
          {
            TableName: "PersonOfInterest",
            SiteID: this.siteService.selectedSiteID,
          },
          httpOptions
        )
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.restrictionType = success.body.map((e) => ({
              PickListID: e.PickListID,
              TableName: e.TableName,
              FieldName: e.FieldName,
              DisplayValue: e.DisplayValue,
              Active: e.Active,
            }));
            // console.log(success.body.map((e) => e.DisplayValue));
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }
  public getRestrictionTypeValue(): RestrictionType[] {
    return [...this.restrictionType];
  }

  
}
