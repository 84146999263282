import { Component, OnInit, Inject, DoCheck, ViewChild } from "@angular/core";

import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatExpansionPanel,
} from "@angular/material";
import { EntryPointStation } from "../../../models/entry-point-station.model";
import { EntryPoint } from "../../../models/entry-point.model";
import { EntryPointService } from "../../../services/entry-point.service";
import { NotificationService } from "../../../services/notification.service";
import { UpdateParkingModalComponent } from "../update-parking/update-parking-modal.component";
import { ParkingLot } from "../../../models/parking-lot.model";
import { ParkingLotGroup } from "../../../models/parking-lot-group.model";
import { ParkingLotGroupService } from "../../../services/parking-lot-group.service";
import { HttpErrorResponse } from "@angular/common/http";
import { SiteService } from "src/app/services/site.service";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-view-parking-modal",
  templateUrl: "./view-parking-modal.component.html",
  styleUrls: ["./view-parking-modal.component.css"],
})
export class ViewParkingModalComponent implements OnInit {
  protected step = 0;
  protected parkingLot: ParkingLot;
  protected parkingLotGroups: Array<ParkingLotGroup>;
  protected entryPoints: Array<EntryPoint>;
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ParkingLot,
    protected dialogRef: MatDialogRef<ViewParkingModalComponent>,
    protected entryPointService: EntryPointService,
    protected notificationService: NotificationService,
    private parkingLotGroupService: ParkingLotGroupService,
    private siteService: SiteService,
    protected messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.parkingLot = { ...this.data };

    //labels by message service
if (localStorage.getItem('ViewParkingLoTLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('ViewParkingLoTLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "View Parking Lot Modal",
      "ParkingLot"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('ViewParkingLoTLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    if (this.entryPointService.getEntryPoints().length != 0) {
      this.entryPoints = [...this.entryPointService.getEntryPoints()];
    } else {
      this.entryPointService
        .parseGetEndpointAPI()
        .then(() => {
          this.entryPoints = [...this.entryPointService.getEntryPoints()];
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    }

    if (this.parkingLotGroupService.getParkingLotGroups().length != 0) {
      this.parkingLotGroups = [
        ...this.parkingLotGroupService.getParkingLotGroups(),
      ];
    } else {
      this.parkingLotGroupService
        .getParkingLotGroupAPI()
        .then(() => {
          this.parkingLotGroups = [
            ...this.parkingLotGroupService.getParkingLotGroups(),
          ];
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    }

    console.log(this.parkingLot);
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}

  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }
}
