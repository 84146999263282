import { Component, OnInit, Inject, ViewChild, ElementRef, Renderer2, } from '@angular/core';
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { UtilityService } from "../services/utility.service";
import { PassService } from "../services/pass.service";
import { DialogService } from "../services/dialog.service";
import { NotificationService } from "../services/notification.service"
import { SiteService } from '../services/site.service';
import { UserService } from '../services/user.service';
import { ISystemSettingsGetAPIResponse } from '../models/interfaces/system-settings-get-api.model';
import { ReadSiteSettingsOperations } from '../site-settings/read-site-settings-operations';
import { MessageService } from '../services/message.service';
export interface DialogData { }

@Component({
  selector: 'app-webcam',
  templateUrl: './webcam.component.html',
  styleUrls: ['./webcam.component.css']
})
export class WebcamComponent implements OnInit {
  @ViewChild("hiddenBtn") private hiddenBtn: ElementRef;
  @ViewChild("printHTMLContainer") private printHtmlContainer: ElementRef;

  //webcam
  dateNow: Date = this.utilService.getCurrentSiteDT();
  public showWebcam = false;
  public existingImageUrl = this.passService.existingPhotoUrl;
  public existingImage = this.passService.existingImage;
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  public videoOptions: MediaTrackConstraints = {
    width: { ideal: 300 },
    height: { ideal: 400 }
  };
  public printPassDisabledWeb:boolean = false;
  public disablePrint:boolean = false;
  public templateID: number;
  private _settingsAPIResponse: ISystemSettingsGetAPIResponse;
  protected appLanguage;
  public screenLabels: Array<string> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WebcamComponent>,
    private utilService: UtilityService,
    private passService: PassService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private renderer: Renderer2,
    public siteService: SiteService,
    public userService: UserService,
    protected messageService: MessageService,
  ) { }

  async ngOnInit(): Promise<void> {

//labels by message service
if (localStorage.getItem('WebcamLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('WebcamLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Webcam",
    "Webcam"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('WebcamLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    WebcamUtil.getAvailableVideoInputs();
    this._settingsAPIResponse = await this.siteService.getSiteSettingsAPI();
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  public handleImage(webcamImage): void {
    this.webcamImage = webcamImage;
    const arr = this.webcamImage.imageAsDataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const file: File = new File([u8arr], this.data.passId, { type: 'jpeg' })
    this.passService.image = file;

  }
  public triggerSnapshot(): void {
    this.passService.existingImage = false;
    this.existingImage = false;
    this.trigger.next();
    this.passService
      .passTransactionCreateAPI(this.data.passId, "UploadPhoto")
      .then(() => {
        //this.notificationService.success(this.passService.message);
      })
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );
  }
  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
  onNoClick() {
    this.dialogRef.close();
  }
  public printPassTransaction(passID: number): void {
    this.showWebcam = !this.showWebcam;
    this.onNoClick();
    this.passService
      .isPassCheckedInAPI(passID)
      .then(() => {
        this.passService
          .passTransactionCreateAPI(passID, "Print")
          .then(() => {
            //this.notificationService.success(this.passService.message);
            this.passService.redeemPassAPI(passID).then(() => {
            })
              .catch((err: HttpErrorResponse) =>
                this.notificationService.failure(err.message)
              );
          })
          .catch((err: HttpErrorResponse) =>
            this.notificationService.failure(err.message)
          );
      })
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );
  }

  async showPrintingDialog(passID: number, tempName: string) {

    const entryPointID = typeof this.userService.userCheckedInEntryPoint == 'number' ? this.userService.userCheckedInEntryPoint : this.userService.userCheckedInEntryPoint.EntryPointID;
    
    this.passService.checkCloudPrintEnabledAPI(this.siteService.selectedSiteID, entryPointID).subscribe(async (res: any) => {
      this.disablePrint = true;
      if (res.body.IsCloudPrint === true) {
        let passType:Array<String>;
        if (tempName === "Regular Pass") {
          this.templateID = Number(this._settingsAPIResponse.SystemSettings ?
            (this._settingsAPIResponse.SystemSettings.filter(ele => ele.Name === "CloudVisitorPass")).map(ele => ele.Value) : null);
            passType = ['visitor'];
          } else {
          this.templateID = Number(this._settingsAPIResponse.SystemSettings ?
            (this._settingsAPIResponse.SystemSettings.filter(ele => ele.Name === "CloudParkingPass")).map(ele => ele.Value) : null);
            passType = ['visitor','parking'];
        }
        this.passService.cloudPrintProcessAPI(
          this.siteService.selectedSiteID,
          entryPointID,
          this.userService.userID,
          passID,
          this.templateID,
          passType,
          Number(sessionStorage.getItem("EntryPointStationID")),
        )
          .then((success: HttpResponse<any>) => {
            this.disablePrint = false;
              if(this.passService.cloudPrinted === 0){
                this.passService.passTransactionCreateAPI(passID, "PhotopassCloudPrint", "Failed")
                .then(() => {}).catch((err: HttpErrorResponse) => this.notificationService.failure(err.message));
                this.regularPrint(passID, tempName)
              } else{
                this.passService.passTransactionCreateAPI(passID, "PhotopassCloudPrint", "Success")
                .then(() => {}).catch((err: HttpErrorResponse) =>this.notificationService.failure(err.message));
              }
            this.notificationService.success(this.passService.message);
          })
          .catch((err: HttpErrorResponse) => {
            this.disablePrint = false;
            this.passService.passTransactionCreateAPI(passID, "PhotopassCloudPrint", "Error")
              .then(() => {}).catch((err: HttpErrorResponse) => this.notificationService.failure(err.message));
            this.notificationService.failure(err.error.message);
            this.regularPrint(passID, tempName);
          });
      }
      else if (res.body.IsCloudPrint === false) {
       this.regularPrint(passID, tempName)
      }
    })

  }

  async regularPrint(passID: number, tempName: string){
    if (tempName === "Regular Pass") {
      this.templateID = Number(this._settingsAPIResponse.SystemSettings ?
        this._settingsAPIResponse.SystemSettings.filter(ele => ele.Name === "VisitorPass").map(ele => ele.Value) : null);
    } else {
      this.templateID = Number(this._settingsAPIResponse.SystemSettings ?
        this._settingsAPIResponse.SystemSettings.filter(ele => ele.Name === "ParkingPass").map(ele => ele.Value) : null);
    }
    this.disablePrint = false;
    try {
      const html = await this.passService.getPassPrintTemplateAPI(
        passID,
        this.templateID
      );
      this.renderer.setProperty(
        this.printHtmlContainer.nativeElement,
        "innerHTML",
        html
      );

      this.hiddenBtn.nativeElement.click();
      this.printPassTransaction(passID)
    } catch (err) {
      console.error(err);
      this.dialogService.openAlertDialogDialog(
        this.screenLabels["PrintError"] ? this.screenLabels["PrintError"]:"Print Error",
        this.screenLabels["CouldNotShowPrintDialogMsg"] ? this.screenLabels["CouldNotShowPrintDialogMsg"]:"Could not show print dialog"
      );
    }
  }
}


