import { Component, OnInit } from "@angular/core";
import { SearchService } from "../services/search.service";
import { NotificationService } from "../services/notification.service";
import { SpinnerService } from "../services/spinner.service";
import { MatDialog } from "@angular/material";
import { HttpErrorResponse } from "@angular/common/http";
import { ViewPassAuditModalComponent } from "./modals/view-pass-audit-modal/view-pass-audit-modal.component";
import { iif } from "rxjs";
import { SiteService } from "../services/site.service";
import { GridApi } from "ag-grid-community";
import { MessageService } from '../services/message.service';

export interface IAuditLog {
  AuditLogID: number;
  TableName: string;
  FieldName: string;
  RecordID: number;
  ModifiedBy: number;
  ModifiedDate: string;
  OperationType: string;
  PreviousValue: string;
  UpdatedValue: string;
  ModifiedByName: string;
  PassActive: number;
}

@Component({
  selector: "app-pass-audit",
  templateUrl: "./pass-audit.component.html",
  styleUrls: ["./pass-audit.component.css"],
})
export class PassAuditComponent implements OnInit {
  protected rowData: Array<IAuditLog>;
  protected auditLogColumnDefs: any;
  protected rowClassRules: any;
  private paginationPageSize;
  private gridApi: GridApi;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  protected columnDefs = [];
  public defaultColDef : object;

  constructor(
    private searchService: SearchService,
    private notificationService: NotificationService,
    private spinnerService: SpinnerService,
    private dialog: MatDialog,
    protected siteService:SiteService,
    protected messageService: MessageService

  ) {
    // initialize data
    // this.rowData = [];
    this.paginationPageSize = 50;
    // sets column definitions for audit log agGrid
    

    // set row styles
    this.rowClassRules = {
      "row-inactive": (params) => {
        // console.log(params);
        var condition = params.data.Active === 0;
        return condition;
      },
    };
    this.defaultColDef = {
      resizable: true,
  } 
  }

  ngOnInit(): void {
    this.searchService.setPageName("Pass Audit");
   
    //labels by message service
if (localStorage.getItem('PassAuditLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('PassAuditLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Pass Audit",
      "AuditLog"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('PassAuditLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    // turn on spinner
    setTimeout(() => {
      this.spinnerService.setIsLoading(true);
    }, 0);
    // invoke smart search to retrieve audit logs
    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        // turn of spinner
        setTimeout(() => {
          this.spinnerService.setIsLoading(false);
        }, 0);
        // populate row data
        this.rowData = this.searchService.searchRecords;
        this.gridApi.paginationGoToPage(0);
        // this.rowData = this.formatAuditLogs(this.searchService.searchRecords);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
        this.spinnerService.setIsLoading(false);
        setTimeout(() => {
          this.spinnerService.setIsLoading(false);
        }, 0);
      });

    // Listen to Search Btn click event and refresh Ag Grid with Search results
    this.searchService.searchInitiated.subscribe((eventData: boolean) => {
      if (eventData) {
        this.searchService.smartSearchPromise
          .then(() => {
            this.rowData = this.searchService.searchRecords;
            this.gridApi.paginationGoToPage(0);
            // this.rowData = this.formatAuditLogs(
            //   this.searchService.searchRecords
            // );
            setTimeout(() => {
              this.spinnerService.setIsLoading(false);
            }, 0);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
            setTimeout(() => {
              this.spinnerService.setIsLoading(false);
            }, 0);
          });
      }
    });

    // subscribe to site changed
    this.siteService.siteChanged.subscribe((event: boolean) => {
      if(event){
        this.searchService
        .smartSearchMultiSiteAPI(null)
        .then(() => {
          this.rowData = this.searchService.searchRecords;   
          this.gridApi.paginationGoToPage(0);
            setTimeout(() => {
              this.spinnerService.setIsLoading(false);
            }, 0);    
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
          setTimeout(() => {
              this.spinnerService.setIsLoading(false);
            }, 0);
        });
      }
    });
  }

  
  setColumnDef() {
    this.auditLogColumnDefs = [
      {
        headerName: this.screenLabels["AuditLogID"]
        ? this.screenLabels["AuditLogID"]
        : "Audit Log ID",
        field: "AuditLogID",
        hide: true,
        sortable: true,
        // sort: "desc",
      },
      {
        headerName: this.screenLabels["PassIDAgGrid"]
          ? this.screenLabels["PassIDAgGrid"]
          : "Pass ID",
        field: "RecordID",
        cellStyle: { color: "#589bf8" },
        hide: false,
        sortable: true,
        filter: "agNumberColumnFilter",
      },
      {
        headerName: this.screenLabels["TableNameAgGrid"]
          ? this.screenLabels["TableNameAgGrid"]
          : "Table Name",
        field: "TableName",
        sortable: true,
        hide: true,
        filter: "agTextColumnFilter",
      },
  
      {
        headerName: this.screenLabels["ModifiedByAgGrid"]
          ? this.screenLabels["ModifiedByAgGrid"]
          : "Modified By",
        field: "ModifiedByName",
        hide: false,
        sortable: true,
        filter: "agTextColumnFilter",
      },
      {
        headerName: this.screenLabels["OperationTypeAgGrid"]
          ? this.screenLabels["OperationTypeAgGrid"]
          : "Operation Type",
        field: "OperationType",
        hide: false,
        sortable: true,
        filter: "agTextColumnFilter",
      },
      {
        headerName: this.screenLabels["ModifiedBy"]
        ? this.screenLabels["ModifiedBy"]
        : "Modified By",
        field: "ModifiedBy",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["ModifiedDate"]
        ? this.screenLabels["ModifiedDate"]
        : "Modified Date",
        field: "ModifiedDate",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["FieldNameAgGrid"]
          ? this.screenLabels["FieldNameAgGrid"]
          : "Field Name",
        field: "FieldName",
        sortable: true,
        filter: "agTextColumnFilter",
      },
      {
        headerName: this.screenLabels["UpdatedValueAgGrid"]
          ? this.screenLabels["UpdatedValueAgGrid"]
          : "Updated Value",
        field: "UpdatedValue",
        hide: false,
        sortable: true,
        filter: "agTextColumnFilter",
      },
      {
        headerName:this.screenLabels["PreviousValueAgGrid"]
          ? this.screenLabels["PreviousValueAgGrid"]
          : "Previous Value",
        field: "PreviousValue",
        hide: false,
        sortable: true,
        filter: "agTextColumnFilter",
      },
      {
        headerName: this.screenLabels["PassActive"]
        ? this.screenLabels["PassActive"]
        : "Active", 
        field: "PassActive",
        hide: true,
        sortable: true,
        filter: "agTextColumnFilter",
      },
    ];

  }
  

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDef()
}

  onGridReady(params) {
    this.gridApi = params.api;    
  }

  // invoked when a row is clicked
  protected openAuditLog(event) {
    const auditLogEntry = event.data;
    this.dialog.open(ViewPassAuditModalComponent, {
      disableClose: true,
      data: auditLogEntry,
    });
  }

  // invoked when data is first rendered
  protected onFirstDataRendered(event) {}

  // invoked when row selection is changed
  protected onSelectionChanged(event) {}
}
