import { TemplateCategoryUser } from './template-categoryUser.model';

export class TemplateCategory {
  public CreatedByName: string;
  public ModifiedByName: string;
  
  constructor(
    public TemplateCategoryID: number,
    public Category: string,
    public OrganizationID: number,
    public UserIDs : string,
    public Active: number,
    public ModifiedBy: number,
    public ModifiedDate: Date,
    public CreatedBy: number,
    public CreatedDate: Date
  ) {
    this.Active = 1;
  }
}
