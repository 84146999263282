import { Component, OnInit } from "@angular/core";
import { PersonOfInterest, RestrictionType } from "../models/person-of-interest.model";
import { SearchService } from "../services/search.service";
import { HttpErrorResponse } from "@angular/common/http";
import { NotificationService } from "../services/notification.service";
import { DatePipe } from "@angular/common";
import { SiteService } from "../services/site.service";
import { PersonOfInterestAgGridConfig } from "./person-of-interest-ag-grid-config.class";
import { GridApi } from "ag-grid-community";
import { PersonOfInterestService } from '../services/person-of-interest.service';
import { MessageService } from "../services/message.service";

@Component({
  selector: "app-person-of-interest",
  templateUrl: "./person-of-interest.component.html",
  styleUrls: ["./person-of-interest.component.css"],
})
export class PersonOfInterestComponent implements OnInit {
  private gridApi: GridApi;

  protected isRowSelected: boolean;
  protected rowData: PersonOfInterest[];
  protected selectedRow: PersonOfInterest;
  protected rowClassRules;
  protected restrictionTypa: Array<{
    PickListID: number;
    TableName: string;
    FieldName: string;
    DisplayValue: string;
    Active: boolean;
  }> = [];
  protected isRestrictionValueLoading = true;
  protected appLanguage;
  protected screenLabels : Array<string> = [];
  protected personOfInterestColumnDefs = [];
  PersonOfInterestAgGridConfig : any;
  public defaultColDef : object;
  constructor(
    protected searchService: SearchService,
    protected notificationService: NotificationService,
    protected datePipe: DatePipe,
    private siteService: SiteService,
    protected personOfInterestService: PersonOfInterestService,
    protected messageService: MessageService,
  ) {
    // set row styles
    this.rowClassRules = {
      "row-inactive": (params) => {
        var condition = params.data.Active === 0;
        return condition;
      },
    };
    this.defaultColDef = {
      resizable: true,
  }
  }

  ngOnInit(): void {
    this.searchService.setPageName("Person Of Interest");

    this.personOfInterestService.getDisplayValueAPI().then(() => {
      // console.log(this.passService.reasonOfVisit);
      this.isRestrictionValueLoading = false;
      this.restrictionTypa = this.personOfInterestService.getRestrictionTypeValue();

      console.log(this.restrictionTypa);
    });
    //labels by message service
if (localStorage.getItem('PersonOfInterestLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('PersonOfInterestLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Person Of Interest",
      "PersonOfInterest"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('PersonOfInterestLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    this.personOfInterestColumnDefs = new PersonOfInterestAgGridConfig(
      this.personOfInterestService,
      this.messageService
    ).personOfInterestColumnDefs;

    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {

        this.rowData = this.searchService.searchRecords;
        // .map((element) => {
        //   if (element.RestrictionType)
        //     element.RestrictionType = this.personOfInterestService.getRestrictionTypeValue().find((e: RestrictionType) => e.PickListID == element.RestrictionType);
        //   return element;
        // });
        
        console.log("rowdata>>", this.rowData);
        this.rowData.forEach((element) => {

          element.RestrictedOn = this.formatDate(element.RestrictedOn);
          element.RestrictionEndDate = this.formatDate(element.RestrictionEndDate);
         
        });
        this.gridApi.paginationGoToPage(0);
      })

//place


      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    this.searchService.searchInitiated.subscribe((eventData: boolean) => {
      if (eventData) {
        this.searchService.smartSearchPromise
          .then(() => {
            this.rowData = this.searchService.searchRecords
            // .map((element) => {
            //   if (element.RestrictionType)
            //     element.RestrictionType = this.personOfInterestService.getRestrictionTypeValue().find((e: RestrictionType) => e.PickListID == element.RestrictionType).DisplayValue;
            //   return element;
            // });
            this.gridApi.paginationGoToPage(0);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    });

    // subscribe to site changed
    this.siteService.siteChanged.subscribe((event: boolean) => {
      if (event) {
        this.searchService
          .smartSearchMultiSiteAPI(null)
          .then(() => {
            debugger
            this.rowData = this.searchService.searchRecords
            // .map((element) => {
            //   if (element.RestrictionType)
            //     element.RestrictionType = this.personOfInterestService.getRestrictionTypeValue().find((e: RestrictionType) => e.PickListID == element.RestrictionType).DisplayValue;
            //   return element;
            // });
            this.gridApi.paginationGoToPage(0);


          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    });
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    // console.log(JSON.stringify(this.screenLabels))
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.personOfInterestColumnDefs = new PersonOfInterestAgGridConfig(
      this.personOfInterestService,
       this.screenLabels,
    ).personOfInterestColumnDefs
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  // make ag-rid columns fill their container
  protected onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }
  // sets the data of selected row
  onSelectionChanged($event) {
    const selectedRows: PersonOfInterest[] = $event.api.getSelectedRows();
    if (selectedRows.length) {
      this.selectedRow = selectedRows[0];
      this.isRowSelected = true;
    } else {
      this.isRowSelected = false;
    }
  }
  formatDate(date: any): string {
    console.log(date);
    if(date !== '0000-00-00 00:00:00' ) {
    const formattedDate = this.datePipe.transform(date, "yyyy-MM-dd");
    return formattedDate;
    } else {
      return null;
    }
  }

}
