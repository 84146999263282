import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { ViewSelectorService } from "./../services/view-selector.service";
import { AuthService } from "../services/auth.service";
import { SearchService } from "../services/search.service";
import { NotificationService } from "../services/notification.service";
import { SpinnerService } from "../services/spinner.service";
import { ViewAuthenticateCallerModalComponent } from "./modals/view-authenticate-caller/view-authenticate-caller-modal.component";
import { UserService } from "../services/user.service";
import { SiteService } from "../services/site.service";
import { CallerAuthenticationAgGridConfig } from "./caller-authentication-ag-grid-config";
import { GridApi } from "ag-grid-community";
import { MessageService } from '../services/message.service';
import { PassService } from "../services/pass.service";

@Component({
  selector: "app-caller-authentication",
  templateUrl: "./desktop/caller-authentication.component.desktop.html",
  styleUrls: ["./desktop/caller-authentication.component.desktop.css"],
})
export class CallerAuthenticationComponent implements OnInit {
  rowData: Array<any> = [];
  params: [];
  private gridApi: GridApi;
  private isMobile = false;
  protected firstName: string;
  protected lastName: string;
  protected authenticationCode: number;
  protected userMessage: string;
  protected isValidUser: boolean = false;
  protected rowClassRules;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  public defaultColDef : object;
  protected ignoreLocksAPICalled: boolean = false;

  constructor(
    private dialog: MatDialog,
    private http: HttpClient,
    service: ViewSelectorService,
    protected authService: AuthService,
    protected searchService: SearchService,
    protected notificationService: NotificationService,
    protected spinnerService: SpinnerService,
    protected userService: UserService,
    private siteService: SiteService,
    protected messageService: MessageService,
    protected passService: PassService
  ) {
    if (service.getIsMobile()) {
      this.isMobile = true;
    }
    this.rowClassRules = {
      "row-inactive": (params) => {
        var condition = params.data.Active === 0;
        return condition;
      },
    };
    this.defaultColDef = {
      resizable: true,
  }
  }

  protected isRowSelected = false;

  protected userColumnDefs = [];

  ngOnInit(): void {
    setTimeout(() => {
      this.spinnerService.setIsLoading(true);
    }, 0);
    this.searchService.setPageName("Call In User");


    //labels by message service
    if (localStorage.getItem('CallInPassLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('CallInPassLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Call In User",
        "User"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('CallInPassLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

  

    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        const rowData = this.searchService.searchRecords;
        this.rowData = rowData.filter(
          (f) => f.UserID != this.userService.userID
        );
        if(!this.authService.validCaller)this.gridApi.paginationGoToPage(0);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    this.searchService.searchInitiated.subscribe((event: boolean) => {
      if (event) {
        this.searchService.smartSearchPromise
          .then(() => {
            const rowData = this.searchService.searchRecords;
            this.rowData = rowData.filter(
              (f) => f.UserID != this.userService.userID
            );
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    });


     // subscribe to site changed
    this.siteService.siteChanged.subscribe((event: boolean) => {
      if(event){
        this.searchService
        .smartSearchMultiSiteAPI(null)
        .then(() => {
           const rowData = this.searchService.searchRecords;
            this.rowData = rowData.filter(
              (f) => f.UserID != this.userService.userID
            );
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
      }
    });
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    //read ag-Grid labels
    this.userColumnDefs = new CallerAuthenticationAgGridConfig(
      this.screenLabels
    ).userColumnDefs;
  }

  openUser($event) {
    if (this.userService.userFunctions["CallInPassActivate"]) {
      this.dialog.open(ViewAuthenticateCallerModalComponent, {
        disableClose: true,
        data: $event.data,
        backdropClass: "backdropBackground",
      });
    }
  }
  // make ag-rid colums fill their container
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }

  TerminateCaller() {
    this.authService.validCaller = false;
    this.userService.userID = this.userService.loggedInUserID ;
    this.userService.userOrganizations = this.userService.loggedInUserOrganizations;
    this.userService.organizationID = this.userService.loggedInUserOrganizationID;    
    this.passService.checkIsIgnoreLocksAPI(this.userService.userID, this.userService.organizationID, false)
    .subscribe((res: HttpResponse<any>) => {
      this.passService.ignoreLocks = res.body.IsIgnoreLocks;
    }, error => {
      this.authService.logout();
      console.log(error);
    });
    this.userService.loggedInUserFirstName = this.userService.firstName  ;
    this.userService.loggedInUserMiddleName = this.userService.middleName ;
    this.userService.loggedInUserLastName = this.userService.lastName ;
    this.userService.loggedInSuffix = this.userService.suffix ;
    this.userService.loggedInEmail = this.userService.email ;
    this.userService.loggedInPhone = this.userService.phone ;
    this.userService.userFunctions = this.userService.loggedInUserFunctions;
    this.userService.callInUserID = 0;
    this.userService.organizationChanged.emit(true);
    this.authService.userChangedToCallInUser.emit(true);
  }
}
