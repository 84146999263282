import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { LoginRegistrationComponent } from "../login-registration.component";
import { AuthService } from "src/app/services/auth.service";
import { ViewSelectorService } from "src/app/services/view-selector.service";
import { LoginRegistrationService } from "src/app/services/login-registration.service";
import { NotificationService } from "src/app/services/notification.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-login-registration-desktop",
  templateUrl: "./login-registration.component.desktop.html",
  styleUrls: ["./login-registration.component.desktop.css"],
})
export class LoginRegistrationComponentDesktop extends LoginRegistrationComponent {
  constructor(
    router: Router, 
    authService: AuthService,
    viewSelectorService: ViewSelectorService, 
    loginRegistrationService: LoginRegistrationService, 
    notificationService:NotificationService,
    userService: UserService
    ) {
    super(
      router, 
      authService,
      viewSelectorService, 
      loginRegistrationService, 
      notificationService,
       userService
       );
  }
}