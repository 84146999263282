import { EventEmitter, Injectable, Output } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ISearchUserInviteGetAPI } from "../models/interfaces/search-user-invite-get-api";
import { SiteService } from "./site.service";
import { Role } from "../models/role.model";
import { IInviteUserApproval } from "../models/interfaces/invite-user-approval";
import { UserService } from "./user.service";

export interface ISubmitUserRoleRequestAPI {
  
  UserIDToSubmit: number;
  SiteID: number;
  Email: string;
  FirstName: string;
  LastName: string;
  Phone: string;
  UserID: number;
  OrganizationID: number;
  RequesterComments: string;
  Role: {
    RoleID: number;
    Active: number;
  }[];

  Team: {
    TeamID: number;
    Active: number;
  }[];
}

@Injectable({
  providedIn: "root",
})
export class UserInvitationService {
  @Output() accessRequestUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();
  public approvalOpen = false;

  httpOptions: { headers; observe } = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
    observe: "response",
  };
  constructor(
    private _http: HttpClient,
    private _siteService: SiteService,
    private _userService: UserService
  ) {}

  // Calls API to get the list of application users
  public searchUserToInviteAPI(emailID: string) {
    const apiURL = environment.getAPI("searchUserToInvite");
    const postBody = { EmailID: emailID };
    const promise = new Promise<ISearchUserInviteGetAPI>((resolve, reject) => {
      this._http
        .post(apiURL, postBody, this.httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            resolve(success.body);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public submitUserRoleRequestAPI(body: ISubmitUserRoleRequestAPI) {
    const apiURL = environment.getAPI("submitUserRoleRequest");
    const postBody = body;
    const promise = new Promise<any>((resolve, reject) => {
      this._http
        .post(apiURL, postBody, this.httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            resolve(success.body);
            this.accessRequestUpdated.emit(true);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public approveRejectUserInvitationAPI(body: IInviteUserApproval) {
    const apiURL = environment.getAPI("updateUserRequestStatus");
    const postBody = body;
    const promise = new Promise<any>((resolve, reject) => {
      this._http
        .post(apiURL, postBody, this.httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            this.accessRequestUpdated.emit(true);
            resolve(success.body);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public async getOrgAdminAvailableRoles() {
    try {
      const siteSettings = await this._siteService.getSiteSettingsAPI();
      const orgRoles = siteSettings.AvailableOrgAdminRoles.filter(
        (ar) => ar.Active === 1
      );

      const roles: Role[] = [];

      orgRoles.forEach((or) => {
        const role: Role = {
          RoleID: +or.RoleID,
          SiteID: or.SiteID,
          RoleName: or.RoleName,
          Notes: undefined,
          Active: or.Active,
        };

        roles.push(role);
      });

      return roles;
    } catch (err) {
      console.error(err);
    }
  }

  public async cancelUserInvitationRequestAPI(approvalRequestID: number, userAccessApprovalRequestID: number) {
    const apiURL = environment.getAPI("cancelUserRoleRequest");

    const postBody = {
      UpdaterID: this._userService.userID,
      ApprovalRequestID: approvalRequestID,
      UserAccessRequestID: userAccessApprovalRequestID,
      SiteID: this._siteService.selectedSiteID
    };
    const promise = new Promise<any>((resolve, reject) => {
      this._http
        .post(apiURL, postBody, this.httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            resolve(success.body);
            this.accessRequestUpdated.emit(true);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  
  public getUserAccessDetailsAPI(requestID:number) {
    const apiURL = environment.getAPI("getUserAccessDetails");
    const body = {
      UserAccessRequestID:requestID,
      SiteID: this._siteService.selectedSiteID
    }
    const res = this._http.post<any>(apiURL, body, this.httpOptions);
    return res;
  }

  public setIntegrations(integratedData, userID) {
    const apiURL = environment.getAPI("setIntegrations");
    const body = {
      UserID: userID,
      UserIntegrations: integratedData,
      SiteID: this._siteService.selectedSiteID
    }
    const res = this._http.post<any>(apiURL, body, this.httpOptions);
    return res
  }
}
