import moment from "moment";
import { UserService } from "src/app/services/user.service";
import { HttpErrorResponse } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { SiteService } from "src/app/services/site.service";

export class AccessRequestsAgGridConfig {
  private _accessRequestColumnDefs = [];
  private _applicationUsers = [];
  get accessRequestColumnDefs() {
    return this._accessRequestColumnDefs;
  }
  constructor(
    private screenLabels,
    private _userService: UserService,
    private _datePipe: DatePipe,
    private _siteService: SiteService
    
  ) {
    this._accessRequestColumnDefs = [
      {
        headerName: "",
        field: "",
        width: 80,
        cellStyle: { color: "#589bf8" },
        hide: false,
        checkboxSelection: true,
        resizable: false,
        sortable: false,
        lockPosition: true,
      },
      {
        headerName: this.screenLabels["ApprovalRequestID"] ? this.screenLabels["ApprovalRequestID"] : "Approval Request ID",
        field: "ApprovalRequestID",
        sortable: true,
        // width: 110,
        filter: true,
        cellStyle: { color: "#589bf8" },
        hide: true,
      },
      {
        headerName: this.screenLabels["UserAccessRequestID"] ? this.screenLabels["UserAccessRequestID"] : "Request ID",
        field: "UserAccessApprovalRequestID",
        sortable: true,
        // width: 110,
        filter: true,
        cellStyle: { color: "#589bf8" },
        hide: false,
      },
      {
        headerName: this.screenLabels["FirstName"] ? this.screenLabels["FirstName"] : "First Name",
        field: "FirstName",
        sortable: true,
        // width: 110,
        filter: true,
        hide: false,
      },
      {
        headerName: this.screenLabels["LastName"] ? this.screenLabels["LastName"] : "Last Name",
        field: "LastName",
        sortable: true,
        // width: 110,
        filter: true,
        hide: false,
      },
      {
        headerName: this.screenLabels["OKTAID"] ? this.screenLabels["OKTAID"] : "Email",
        field: "OKTAID",
        sortable: true,
        // width: 110,
        filter: true,
        hide: false,
      },
      {
        headerName:this.screenLabels["Phone"] ? this.screenLabels["Phone"] : "Phone", 
        field: "Phone",
        sortable: true,
        // width: 110,
        filter: true,
        hide: false,
      },
      {
        headerName:this.screenLabels["ModifiedDate"] ? this.screenLabels["ModifiedDate"] : "Modified Date", 
        field: "ModifiedDate",
        sortable: true,
        // width: 110,
        filter: true,
        hide: false,
        cellRenderer: (data) => {
            const formattedDate = this._datePipe.transform(
              data.value,
              this._siteService.dataTimeFormat.angularDateFormat
            );
            return formattedDate ? formattedDate : null;
          },
      },
      {
        headerName:this.screenLabels["Status"] ? this.screenLabels["Status"] : "Status", 
        field: "Status",
        sortable: true,
        filter: true,
        valueGetter (params) {
          return (params.data.Active === 0 ) ? "Cancelled" : params.data.Status;
        }
      }
    ];
  }

}
