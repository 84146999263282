import { Injectable, Output, EventEmitter } from "@angular/core";
import { UserService } from "./user.service";
import { NotificationService } from "./notification.service";
import { HttpHeaders, HttpResponse, HttpClient } from "@angular/common/http";
import { TemplateCategory } from "../models/template-category,model";
import { PassTemplate } from "../models/pass-template.model";
import { environment } from "src/environments/environment";
import { ExecFileSyncOptionsWithStringEncoding } from "child_process";
import { SiteService } from "./site.service";

@Injectable({
  providedIn: "root",
})
export class PassTemplateService {
  public passTemplates: PassTemplate;
  public passTemplateTables: Array<{
    PickListID: number;
    TableName: string;
    FieldName: string;
    DisplayValue: string;
    Active: boolean;
  }> = [];

  @Output() passTemplateDataChanged: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private notificationService: NotificationService,
    private siteService:SiteService
  ) {
    this.passTemplateDataChanged = new EventEmitter<boolean>();
  }

  // Method to return Template Category array
  /*public getTemplates(): PassTemplate[] {
    return [...this.passTemplates];
  }*/

  public getTemplateAPI(templateID): Promise<any> {
    const apiURL = environment.getAPI("getPassTemplateV2");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const postBody = {
      PassTemplateID: templateID,
      SiteID:this.siteService.selectedSiteID
    };
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.passTemplates = success.body[0];
            this.passTemplates.EstArrivalTime = this.passTemplates.EstArrivalTime
                ? this.passTemplates.EstArrivalTime.toString().replace("Z", "")
                : this.passTemplates.EstArrivalTime;

                this.passTemplates.EstDepartureTime = this.passTemplates.EstDepartureTime
                ? this.passTemplates.EstDepartureTime.toString().replace("Z", "")
                : this.passTemplates.EstDepartureTime;
   

            resolve();
          },
          (err) => {
            reject(err);
          }
        );
    });
    return promise;
  }

  public createPassTemplateAPI(passTemplate: PassTemplate) {
    const apiURL = environment.getAPI("passTemplateCreate");

    const postBody = passTemplate;
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.notificationService.success(
              "Pass Template Created successfully."
            );
            this.passTemplateDataChanged.emit(true);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public updatePassTemplateAPI(passTemplate: PassTemplate) {
    const apiURL = environment.getAPI("passTemplateUpdate");

    const postBody = passTemplate;
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.notificationService.success(
              "Pass Template Updated successfully."
            );
            this.passTemplateDataChanged.emit(true);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public deletePassTemplateAPI(passTemplate: PassTemplate) {
    const apiURL = environment.getAPI("passTemplateUpdate");
    const postBody = passTemplate;
    postBody.UserID = this.userService.userID
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.passTemplateDataChanged.emit(true);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // get Pass template  from pick list table
  public getPassTemplatePickListTablesAPI() {
    const apiURL = environment.getAPI("getPickList");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise<
      {
        PickListID: number;
        TableName: string;
        FieldName: string;
        DisplayValue: number;
        Active: boolean;
      }[]
    >((resolve, reject) => {
      this.http
        .post(apiURL, { TableName: "PassTemplate" ,SiteID:this.siteService.selectedSiteID}, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.passTemplateTables = success.body.map((e) => ({
              PickListID: e.PickListID,
              TableName: e.TableName,
              FieldName: e.FieldName,
              DisplayValue: e.DisplayValue,
              Active: e.Active,
            }));
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }
}