import { Component, OnInit, Inject, DoCheck } from "@angular/core";

import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { EntryPointStation } from "../../../models/entry-point-station.model";
import { EntryPoint } from "../../../models/entry-point.model";
import { EntryPointService } from "../../../services/entry-point.service";
import { NotificationService } from "../../../services/notification.service";
import { HttpErrorResponse } from "@angular/common/http";
import { EntryPointZone } from "src/app/models/entry-point-zone.model";
import { EntryPointZoneService } from "src/app/services/entry-point-zone.service";
import { SearchService } from "src/app/services/search.service";
import { IAuditLog } from "../../pass-audit.component";
import { CountUpDirective } from "@swimlane/ngx-charts";

import * as $ from "jquery";
import { SiteService } from "src/app/services/site.service";
import { UtilityService } from "src/app/services/utility.service";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-view-pass-audit-modal",
  templateUrl: "./view-pass-audit-modal.component.html",
  styleUrls: ["./view-pass-audit-modal.component.css"],
})
export class ViewPassAuditModalComponent implements OnInit {
  protected step = 0;
  protected passID: number;
  protected tempDate: string;

  private auditLogs: Array<IAuditLog>;
  protected createTypeLogs: Array<IAuditLog>;
  protected updateTypeLogs: Array<IAuditLog>;
  protected deleteTypeLogs: Array<IAuditLog>;
  protected updateTransactions: Array<{
    TransactionID: string;
    Details: Array<IAuditLog>;
    ModifiedDate: string;
    ModifiedByName: string;
  }>;
  protected appLanguage;
  public screenLabels: Array<string> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ViewPassAuditModalComponent>,
    private searchService: SearchService,
    protected siteService: SiteService,
    protected utilService: UtilityService,
    protected messageService: MessageService,
  ) {}

  ngOnInit(): void {
    console.log(this.data)
    this.passID = this.data.RecordID;

    //labels by message service
    if (localStorage.getItem('ViewPassAuditModalLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('ViewPassAuditModalLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "View Pass Audit Modal",
        "AuditLog"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('ViewPassAuditModalLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    this.auditLogs = [...this.searchService.searchRecords].filter(
      (record) => record.RecordID === this.passID && record.TableName === "Pass"
    );
    console.log(this.auditLogs);

    this.createTypeLogs = this.auditLogs.filter(
      (log) => log.OperationType.toLowerCase() === "Create".toLowerCase()
    );
    this.updateTypeLogs = this.auditLogs.filter(
      (log) => log.OperationType.toLowerCase() === "Update".toLowerCase()
    );
    this.deleteTypeLogs = this.auditLogs.filter(
      (log) => log.OperationType.toLowerCase() === "Delete".toLowerCase()
    );

    // console.log("Create Type Logs: ", this.createTypeLogs);
    // console.log("Update Type Logs: ", this.updateTypeLogs);
    // console.log("Delete Type Logs: ", this.deleteTypeLogs);

    // sorting update type logs based on Modified Date description
    this.updateTypeLogs.reverse();

    this.updateTransactions = this.generateUpdateTransaction();
    // console.log(this.updateTransactions);

    // this.collapseAuditLogTable();

     
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  private generateUpdateTransaction() {
    let count = 0;
    const transactions: Array<{
      TransactionID: string;
      Details: Array<IAuditLog>;
      ModifiedDate: string;
      ModifiedByName: string;
    }> = [];

    let sameTransaction: Array<IAuditLog> = [];
    for (let i = 0; i < this.updateTypeLogs.length - 1; i++) {
      if (
        this.updateTypeLogs[i].ModifiedDate ===
          this.updateTypeLogs[i + 1].ModifiedDate &&
        this.updateTypeLogs[i].ModifiedBy ===
          this.updateTypeLogs[i + 1].ModifiedBy
      ) {
        sameTransaction.push(this.updateTypeLogs[i]);
      } else {
        ++count;
        transactions.push({
          TransactionID: count.toString(),
          Details: sameTransaction,
          ModifiedDate: sameTransaction[0].ModifiedDate,
          ModifiedByName: sameTransaction[0].ModifiedByName,
        });

        sameTransaction = [];
        sameTransaction.push(this.updateTypeLogs[i + 1]);
      }
    }

    if (sameTransaction.length > 0) {
      transactions.push({
        TransactionID: count.toString(),
        Details: sameTransaction,
        ModifiedDate: sameTransaction[0].ModifiedDate,
        ModifiedByName: sameTransaction[0].ModifiedByName,
      });
    }

    return transactions;
  }

  protected collapseAuditLogTable($event, id) {
    // console.log($event, id);

    $(".log-row-" + id).toggle();
    // $(".audit-caret-" + id).toggleClass("flip-caret");

    $(".audit-caret-" + id).text(function (_, value) {
      return value == "-" ? "+" : "-";
    });
  }
}
