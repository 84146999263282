export class EntryPointStation {
  public Active: boolean;
  constructor(
    public EntryPointStationID: number,
    public EntryPointID: number,
    public HostName: string,
    public IPAddress: string,
    public DefaultPrinterName:string,
    public ModifiedBy: number,
    public ModifiedDate: Date,
    public CreatedBy: number,
    public CreatedDate: Date
  ) {
    this.Active = true;
  }
}
