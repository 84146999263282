export class PersonOfInterest {

  constructor(
  public PersonOfInterestID: number,
  public FirstName: string,  
  public MiddleName: string,
  public LastName: string,
  public Suffix: string,  
  public Company: string,
  public Phone: string,
  public Email: string,
  public Notes: string,
  public NotificationEmail: string,
  public RestrictedOn: string,
  public RestrictionEndDate: string,
  public RestrictedReason: string,
  public Restricted: string,
  public RestrictionType ?: number | string,
  public DisplayValue ?: string

  ) {
    
    
  }



}
export class RestrictionType{

    PickListID: number;
    TableName: string;
    FieldName: string;
    DisplayValue: string;
    Active: boolean;

}