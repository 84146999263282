import { Injectable, Output, EventEmitter } from "@angular/core";
import { UserService } from "./user.service";
import { NotificationService } from "./notification.service";
import { HttpHeaders, HttpResponse, HttpClient } from "@angular/common/http";
import { TemplateCategory } from "../models/template-category,model";
import { Team } from "../models/team.model";
import { TeamTemplateCategory } from "../teams/team/edit-team-modal/edit-team-modal.component";
import { environment } from "src/environments/environment";
import { UserTeam } from "../models/userTeam.model";

@Injectable({
  providedIn: "root",
})
export class TeamService {
  private teams: Team[];
  private teamApiData: Team[];
  public message: string;
  public userTeams: UserTeam[];
  private userTeamApiData: UserTeam[];

  @Output() teamDataChanged: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  public functionApiData: Array<{
    OrganizationFunctionID: number;
    Tag: string;
    Label: string;
    Description: string;
    DisplayOrder: number;
    FunctionCategoryID: number;
    Category: string;
    CatDisplayOrder: number;
  }>;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private notificationService: NotificationService
  ) {
    this.teamDataChanged = new EventEmitter<boolean>();
    this.teams = [];
  }

  // Method to return Team array
  public getTeam(): Team[] {
    return [...this.teams];
  }

  public getTeamAPI(TeamID: number): Promise<any> {
    const apiURL = environment.getAPI("getTeam");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const postBody = {
      TeamID: TeamID,
    };
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.teamApiData = success.body;
            this.teams = this.teamApiData;
            resolve();
          },
          (err) => {
            reject(err);
          }
        );
    });
    return promise;
  }

  public getUserTeamAPI(UserID: number): Promise<any> {
    const apiURL = environment.getAPI("getUserTeam");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const postBody = {
      UserID: UserID,
    };
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.userTeamApiData = success.body;
            this.userTeams = this.userTeamApiData;
            resolve();
          },
          (err) => {
            reject(err);
          }
        );
    });
    return promise;
  }

  public createTeamAPI(
    team: Team,
    functionChecked: Array<{
      CategoryID: number;
      OrganizationFunctionID: number;
      Active: boolean;
    }>
  ) {
    const apiURL = environment.getAPI("teamCreateUpdate");

    const postBody = {
      TeamID: 0,
      OrganizationID: this.userService.organizationID,
      Name: team.Name,
      AutoAddAllOrgUsers: 0,
      AutoAddAllCategories: team.AutoAddAllCategories,
      Active: 1,
      UserID: this.userService.userID,
      OrganizationFunctionTeamArray: functionChecked.map((f) => ({
        OrganizationFunctionTeamID: 0,
        OrganizationFunctionID: f.OrganizationFunctionID,
        Active: f.Active ? 1 : 0,
      })),
      TemplateCategoryTeamArray: [],
    };

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
            this.teamDataChanged.emit(true);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public updateTeamAPI(
    team: Team,
    functionChecked: Array<{
      CategoryID: number;
      OrganizationFunctionID: number;
      Active: boolean;
      OrganizationFunctionTeamID: number;
    }>,
    chipSelectedCategory: TeamTemplateCategory[]
  ) {
    const apiURL = environment.getAPI("teamCreateUpdate");

    const postBody = {
      "TeamID": team.TeamID,
      "OrganizationID": this.userService.organizationID,
      "Name": team.Name,
      "AutoAddAllOrgUsers": team.AutoAddAllOrgUsers,
      "AutoAddAllCategories": team.AutoAddAllCategories,
      "Active": 1,
      "UserID": this.userService.userID,
      "OrganizationFunctionTeamArray": functionChecked.map((f) => ({
        OrganizationFunctionTeamID: f.OrganizationFunctionTeamID,
        OrganizationFunctionID: f.OrganizationFunctionID,
        Active: f.Active ? 1 : 0
      })),
      TemplateCategoryTeamArray: chipSelectedCategory.map((f) => ({
        TemplateCategoryTeamID: f.TemplateCategoryTeamID,
        TemplateCategoryID: f.TemplateCategoryID,
        Active: f.Active,
      })),
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
            this.teamDataChanged.emit(true);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public deleteTeamAPI(team: Team) {
    const apiURL = environment.getAPI("teamCreateUpdate");
    const postBody = {
      "TeamID": team.TeamID,
      "OrganizationID": this.userService.organizationID,
      "Name": team.Name,
      "AutoAddAllOrgUsers": team.AutoAddAllOrgUsers,
      "AutoAddAllCategories": team.AutoAddAllCategories,
      "Active": 0,
      "UserID": this.userService.userID,
      "OrganizationFunctionTeamArray": [],
      "TemplateCategoryTeamArray": []
    }
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.teamDataChanged.emit(true);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // Invokes an API call to get the list of Functions
  getOrgFunctions(): Promise<any> {
    const apiURL = environment.getAPI("getOrganizationFunctionDesign");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise<{
      FunctionID: number;
      Tag: string;
      Label: string;
      Description: string;
      DisplayOrder: number;
      FunctionCategoryID: number;
      Category: string;
      CatDisplayOrder: number;
    }>((resolve, reject) => {
      this.http
        .post(apiURL, "", httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.functionApiData = success.body.filter(
              (fd) => fd.FunctionID !== null
            );
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // Calls update user Team API

  updateUserTeamAssociationsAPI(
    userID,
    userTeam: Array<{
      Name: string;
      TeamID: number;
      UserTeamID: number;
      Active: number;
    }>
  ): Promise<any> {
    const apiURL = environment.getAPI("userTeamCreateUpdate");

    const postBody = {
      UserTeamArray: userTeam.map((ut) => ({
        UserTeamID: ut.UserTeamID,
        UserID: userID,
        TeamID: ut.TeamID,
        Active: ut.Active,
        UpdaterUserID: this.userService.userID,
      })),
    };
    // postBody.UserID = this.userID;
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }
}
