import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SpinnerService {
  public isLoading = false;

  setIsLoading(data) {
    this.isLoading = data;
  }
  getIsLoading() {
    return this.isLoading;
  }
}
