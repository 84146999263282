import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatExpansionPanel } from "@angular/material";
import { OrganizationService } from "src/app/services/organization.service";
import { HttpErrorResponse } from "@angular/common/http";
import { NotificationService } from "src/app/services/notification.service";

@Component({
  selector: "app-raise-capacity-request-modal",
  templateUrl: "./raise-capacity-request-modal.component.html",
  styleUrls: ["./raise-capacity-request-modal.component.css"],
})
export class RaiseCapacityModalComponent implements OnInit {
  @ViewChild("freezePanel") freezePanel : MatExpansionPanel;
  protected step = 0;
  protected organization: {
    OrganizationID: number;
    Name: string;
    Active: number;
    Internal: number;
    StartDate: string;
    EndDate: string;
    ModifiedBy: number;
    CreatedBy: number;
    CreatedDate: string;
  } = null;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      OrganizationID: number;
      OrganizationName: string;
      StartDate: Date;
      EndDate: Date;
    },
    protected dialogRef: MatDialogRef<RaiseCapacityModalComponent>,

    private notificationService: NotificationService
  ) {
    // this.organization = this.organizationService
    //   .get()
    //   .filter((org) => org.OrganizationID === this.data.OrganizationID)[0];
  }

  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  onSaveChanges() {
    this.onNoClick();
  }

  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }
}
