export class ParkingLot {
  public CreatedByName: string;
  public ModifiedByName: string;

  constructor(
    public ParkingLotID: number,
    public Name: string,
    public Description: string,
    public DriveOnRequired: boolean,
    public EmailDirections: string,
    public DefaultEntryPoint: number,
    public ParkingLotGroupID: number,
    public DefaultEntryPointName: string,
    public ParkingLotGroupName: string,
    public Active: number,
    public ModifiedBy: number,
    public ModifiedDate: Date,
    public CreatedBy: number,
    public CreatedDate: Date
  ) {
    this.Active = 1;
  }
}
