import { Component, OnInit, Inject, Output, ViewChild } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatExpansionPanel,
} from "@angular/material";
import { OrganizationService } from "src/app/services/organization.service";
import { HttpErrorResponse } from "@angular/common/http";
import { NotificationService } from "src/app/services/notification.service";
import { GridApi } from "ag-grid-community";
import * as _moment from "moment";
import { default as _rollupMoment } from "moment";
import moment from "moment";
import { LimitRequestService } from "src/app/services/limit-request.service";
import { UserService } from "src/app/services/user.service";
import { SpinnerService } from "src/app/services/spinner.service";
import { NgForm } from "@angular/forms";
import { SiteService } from "src/app/services/site.service";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-reject-approve-short-modal",
  templateUrl: "./reject-approve-short-modal.component.html",
  styleUrls: ["./reject-approve-short-modal.component.css"],
})
export class RejectApproveShortModalComponent implements OnInit {
  @ViewChild("requestForm") requestForm: NgForm;
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  private gridApi: GridApi;

  protected step = 0;
  protected creationDate;
  protected startDate;
  protected endDate;
  protected organizationName: string;
  protected approversComment: string;
  protected createdBy: string;
  protected requestersComment: string;
  protected requesterName: string;
  protected parkingLotName: string;
  protected entryPointGroupName: string;

  protected approversColumnDefs = null;
  protected rowData: Array<any> = null;
  protected rowClassRules;
  protected appLanguage;
  public screenLabels: Array<string> = [];

  protected approvalData: {
    ApprovalRequestID: number;
    RequestedBy: string;
    RequestedOn: string;
    Status: string;
    Organization: string;
    StartDate: string;
    EndDate: string;
    ParkingLot: string;
    EntryPointGroup: string;
    IncreaseByLimit: number;
    ApproverComments: string;
    RequesterComments: string;
    AffectedEntityDetails: Array<{
      AffectedEntity: string;
      TargetCapacity: number;
      ApprovedSummary: number;
      RequestedSummary: number;
      series: Array<{
        date: string;
        ApprovedCapacity: number;
        RequestedCapacity: number;
      }>;
    }>;
  };

  protected organization: {
    OrganizationID: number;
    Name: string;
    Active: number;
    Internal: number;
    StartDate: string;
    EndDate: string;
    ModifiedBy: number;
    CreatedBy: number;
    CreatedDate: string;
  } = null;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    protected dialogRef: MatDialogRef<RejectApproveShortModalComponent>,
    private notificationService: NotificationService,
    private limitService: LimitRequestService,
    private userService: UserService,
    protected spinnerService: SpinnerService,
    protected siteService:SiteService,
    protected messageService: MessageService
  ) {}

  ngOnInit(): void {
     //labels by message service
     if (localStorage.getItem('RejectApproveShortModalLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('RejectApproveShortModalLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Reject/Approve limit Modal",
        "OrgCombinedLimitRequest"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('RejectApproveShortModalLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }
    this.creationDate = moment(this.data.CreatedDate).format("MM/DD/YYYY");

    this.limitService
      .getApprovalDetailsByIDAPI(this.data.OrgCombinedLimitRequestID)
      .then(() => {
        this.approvalData = this.limitService.getApprovalDetailsByID()[0];
        this.organizationName = this.approvalData.Organization;
        this.parkingLotName = this.approvalData.ParkingLot;
        this.entryPointGroupName = this.approvalData.EntryPointGroup;
        this.requestersComment = this.approvalData.RequesterComments;
        this.approversComment = this.approvalData.ApproverComments;
        this.createdBy = this.approvalData.RequestedBy;
      });
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  onSaveChanges() {
    this.onNoClick();
  }

  protected onGridReady(params) {
    this.gridApi = params.api;
  }

  protected onFirstDataRendered() {
    this.gridApi.sizeColumnsToFit();
  }

  // invoked when a limit request is approved
  protected async onApproveLimit() {
    const approversComment = this.requestForm.value.approversComment;
    this.onNoClick();

    await this.limitService.approveLimitRequestAPI(
      this.data.OrgCombinedLimitRequestID,
      approversComment
    );
    this.notificationService.success(this.limitService.message);
  }

  // invoked when a limit request is rejected
  protected async onRejectLimit() {
    const approversComment = this.requestForm.value.approversComment;
    this.onNoClick();

    await this.limitService.rejectLimitRequestAPI(
      this.data.OrgCombinedLimitRequestID,
      approversComment
    );
    this.notificationService.success(this.limitService.message);
  }

  //code to freeze Panel

  onMainHeaderClose() {
    this.freezePanel.open();
  }
}
