import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApprovalGroup } from '../models/approval-group-model';
import { NotificationService } from './notification.service';
import { SiteService } from './site.service';
import { UserService } from './user.service';
import { UtilityService } from './utility.service';
import { ApprovalGroupMember } from '../models/approval-group-member-model';

@Injectable({
  providedIn: 'root'
})
export class ApprovalGroupService {
  private approvalGroup: ApprovalGroup[];
  private approvalGroupApiData: Array<ApprovalGroup>;
  public viewApprovalGroupOpen = false;
  public httpOptions: { headers; observe } = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
    observe: "response",
  };
  @Output() approvalGroupDataChanged: EventEmitter<
    boolean
  > = new EventEmitter<boolean>();
  @Output() approvalMemberDataChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private notificationService: NotificationService,
    private siteService: SiteService,
    private utilService: UtilityService
  ) {
    this.approvalGroupDataChanged = new EventEmitter<boolean>();
    this.approvalMemberDataChanged = new EventEmitter<boolean>();
    this.approvalGroup = [];
    this.getApprovalGroupAPI();
  }

  // Method to return Parking lot Group array
  public getApprovalGroups(): ApprovalGroup[] {
    return [...this.approvalGroup];
  }

  public getApprovalGroupAPI(): Promise<any> {
    const apiURL = environment.getAPI("getApprovalGroup");
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, { SiteID: this.siteService.selectedSiteID }, this.httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.approvalGroupApiData = success.body;
            this.approvalGroup = new Array<ApprovalGroup>();

            this.approvalGroupApiData.forEach((element) => {
              let plg = new ApprovalGroup(
                element.ApprovalGroupID,
                element.SiteID,
                element.Name,
                element.Description,
                element.Users,
                element.Active ? 1 : 0,
                element.UserID
              );
              this.approvalGroup.push(plg);
            });
            resolve(success);
          },
          (err) => {
            reject(err);
          }
        );
    });
    return promise;
  }

  // Method to add a Parking Lot Group
  public createApprovalGroup(approvalGroup: ApprovalGroup) {
    let temp: ApprovalGroup = { ...approvalGroup };
    temp.ApprovalGroupID = 0;
    this.approvalGroup.push(temp);
    this.updateApprovalGroupAPI(approvalGroup).then((success: any) => {
      this.notificationService.success(
        success.Message
      );
      this.approvalGroupDataChanged.emit(true);
    });
  }

  // Method to update a Parking Lot Group by id
  public updateApprovalGroup(approvalGroup: ApprovalGroup) {
    this.updateApprovalGroupAPI(approvalGroup).then((success: any) => {
      this.notificationService.success(
        "Approval Group Updated Successfully."
      );
      this.approvalGroupDataChanged.emit(true);
    });
  }

  public updateApprovalGroupAPI(approvalGroup: ApprovalGroup) {
    const apiURL = environment.getAPI("approvalGroupUpsert");

    const postBody = {
      ApprovalGroupID: approvalGroup.ApprovalGroupID,
      SiteID: approvalGroup.SiteID,
      Name: approvalGroup.Name,
      Description: approvalGroup.Description,
     // Users: approvalGroup.Users,
      Active: approvalGroup.Active,
      UserID: approvalGroup.UserID
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, this.httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            resolve(success.body);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  //get Approval group by id

  public getApprovalGroupById(approvalGroupId: number) {
    const apiURL = environment.getAPI("getApprovalGroupById");
    const postBody = {
      ApprovalGroupID: approvalGroupId,
    };
    
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, this.httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            resolve(success.body);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // Method to delete Parking Lot by id

  public deleteApprovalGroup(approvalGroup: ApprovalGroup) {
    approvalGroup.Active = 0; //disabling parking lot Group
    return this.updateApprovalGroupAPI(approvalGroup).then((success: any) => {
      this.notificationService.success(
        "Approval Group Deleted Successfully."
      );
      this.approvalGroupDataChanged.emit(true);
    });
  }

  public addApprovalGroupMember(approvalGroupMember: ApprovalGroupMember) {
    this.approvalGroupMemberAPI(approvalGroupMember).then((success: any) => {
      this.notificationService.success(
        "Approval Group Member Updated Successfully."
      );
      this.approvalMemberDataChanged.emit(true);
    });
  }

  public removeApprovalGroupMember(approvalGroupMember: ApprovalGroupMember) {
    return this.approvalGroupMemberAPI(approvalGroupMember).then((success: any) => {
      this.notificationService.success(
        "Approval Group Member Deleted Successfully."
      );
      this.approvalGroupDataChanged.emit(true);
    });
  }

  public approvalGroupMemberAPI(approvalGroupMember){
    const apiURL = environment.getAPI("approvalGroupMember");

    const postBody = {
      ApprovalGroupID: approvalGroupMember.ApprovalGroupID,
     // SiteID: approvalGroup.SiteID,
     // Name: approvalGroup.Name,
     // Description: approvalGroup.Description,
      Users: approvalGroupMember.Users,
     // Active: approvalGroup.Active,
     // UserID: approvalGroup.UserID
    };
    
    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, this.httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            resolve(success.body);

          },
          (err) => {
            reject(err);
          }
        );
    });
    return promise;
  }

  public getApprovalGroupUseCount(approvalGroupID) {
    const apiURL = environment.getAPI("getApprovalGroupUseCount");
    const body = { ApprovalGroupID: approvalGroupID }
    const res = this.http.post<any>(apiURL, body, this.httpOptions);
    return res;
  }

  public getApprovalGroupUserList(approvalGroupID) {
    const apiURL = environment.getAPI("getApprovalGroupUserList");
    const body = {
      ApprovalGroupID : approvalGroupID
    }
    const res = this.http.post<any>(apiURL, body, this.httpOptions);
    return res;
  }
} 
