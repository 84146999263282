import { Component, OnInit } from "@angular/core";
import { UserService } from "../services/user.service";
import { SiteService } from "../services/site.service";
import { MessageService } from '../services/message.service';

@Component({
  selector: "app-master-data",
  templateUrl: "./desktop/master-data.component.desktop.html",
  styleUrls: ["./desktop/master-data.component.desktop.css"],
})
export class MasterDataComponent implements OnInit {
  protected appLanguage;
  public screenLabels: Array<string> = [];
  constructor(
    public userService: UserService,
    private siteService: SiteService,
    protected messageService: MessageService,
  ) {}

  ngOnInit(): void {
//labels by message service
if (localStorage.getItem('MasterDataLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('MasterDataLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Master Data Page",
      "MasterData"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('MasterDataLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
}

}
