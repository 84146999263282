import { Injectable, Output, EventEmitter } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from "@angular/common/http";
import { UserService } from "./user.service";
import { environment } from "src/environments/environment";
import { IApproveRejectEmitter } from "../common/AgGrid/approve-reject-cell-renderer/approve-reject-cell-renderer.component";
import { SiteService } from "./site.service";
import { NotificationService } from "./notification.service";

export interface IApprovalRequestStatusResponse {
  RecordID: number;
  ApprovalGroupRequestID: number;
  ApprovalRequestID: number;
  ApprovalGroupID: number;
  UserID: number;
  ApprovalLevel: number;
  Status: string;
  ApprovalGroupName: string;
  CanBeActionedOn: number;
  ApprovalComments: string;
  ApprovedDate: string;
  ApprovedBy: string;
  ModifiedDate: string;
  ReturnValuesCount: number;
  FunctionVerificationDetails: string;
  FunctionVerificationDetailsExists: number;
  ApprovalRequestConfigID: number;
  ApprovalRequestConfigDescription?: string;
  FunctionName: string;
  TablefunctionReturnValuesDescription: string;
}

@Injectable({
  providedIn: "root",
})
export class ApprovalService {
  @Output() approvedRejected: EventEmitter<IApproveRejectEmitter>;
  public passCreateApprovalOpen = false;
  public orgLimitRequestOpen = false;
  public checkinApprovalOpen = false;

  constructor(
    private _http: HttpClient, 
    private _userService: UserService, 
    private _siteService: SiteService, 
    private _notificationService: NotificationService
    ) {
    this.approvedRejected = new EventEmitter<IApproveRejectEmitter>();
  }

  public async getApprovalStatus(approvalRequestID: number, TableName?: string, ScreenName?: string) {
    const apiURL = environment.getAPI("getUserRoleRequest");

    const body = {
      UserID: this._userService.userID,
      RequestID: approvalRequestID,
      SiteID: this._siteService.selectedSiteID,
      TableName: TableName,
      ScreenName: ScreenName
    };

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise<IApprovalRequestStatusResponse[]>(
      (resolve, reject) => {
        this._http
          .post(apiURL, body, httpOptions)
          .toPromise()
          .then(
            (success: HttpResponse<any>) => {
              resolve(success.body);
            },
            (err) => {
              // error
              reject(err);
            }
          );
      }
    );
    return promise;
  }

  public async getVerificationDetailsAPI(approvalRequestID: number, approvalRequestConfigID: number, recordID: number,) {
    const apiURL = environment.getAPI("getFunctionVerificationDetails");

    const body = {
      ApprovalRequestID: approvalRequestID,
      ApprovalRequestConfigID: approvalRequestConfigID,
      RecordID: recordID,
      UserID: this._userService.userID,
      SiteID: this._siteService.selectedSiteID
    };

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise<{ AgGridConfig: any[], Data: any[] }>(
      (resolve, reject) => {
        this._http
          .post(apiURL, body, httpOptions)
          .toPromise()
          .then(
            (success: HttpResponse<any>) => {
              // success
              const data = success.body;
              const agGridConfig = [];

              // generate the agGrid config details
              if (data.length) {
                Object.keys(data[0]).forEach((key) => {
                  agGridConfig.push({
                    headerName: key,
                    field: key,
                  });
                });
              }
              resolve({ AgGridConfig: agGridConfig, Data: data });
            },
            (err: HttpErrorResponse) => {
              // error
              console.error(err);
              this._notificationService.failure(err.message);
              reject(err);
            }
          );
      }
    );
    return promise;
  }

  public async getApprovalDetails(requestID:number, approvalType:number) {
    const apiURL = environment.getAPI("getApprovalDetails");

    const body = {
      RequestID: requestID,
      ApprovalType: approvalType,
      SiteID: this._siteService.selectedSiteID,
    };

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise(
      (resolve, reject) => {
        this._http
          .post(apiURL, body, httpOptions)
          .toPromise()
          .then(
            (success: HttpResponse<any>) => {
              resolve(success.body);
            },
            (err) => {
              // error
              reject(err);
            }
          );
      }
    );
    return promise;
  } 

  public checkApprovalRequestExistsAPI(passID: number){
    const apiURL = environment.getAPI("checkApprovalRequestExists");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      PassID: passID,
      SiteID: this._siteService.selectedSiteID
    }
    const res = this._http.post<any>(apiURL, body, httpOptions);
    return res;
  }
}
