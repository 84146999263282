import { Injectable, Output, EventEmitter } from "@angular/core";
import { EntryPointGroup } from "../models/entry-point-group.model";
import { EntryPointGroupMap } from "../models/entry-point-group-map.model";
import { UserService } from "./user.service";
import { NotificationService } from "./notification.service";
import { HttpHeaders, HttpResponse, HttpClient } from "@angular/common/http";
import { EntryPoint } from "../models/entry-point.model";
import { EntryPointService } from "./entry-point.service";
import { ParkingLotGroup } from "../models/parking-lot-group.model";
import { environment } from "src/environments/environment";
import { SiteService } from "./site.service";
import { UtilityService } from "./utility.service";

@Injectable({
  providedIn: "root",
})
export class ParkingLotGroupService {
  private parkingLotGroups: ParkingLotGroup[];
  private parkingLotGroupsApiData: Array<ParkingLotGroup>;

  @Output() parkingLotGroupDataChanged: EventEmitter<
    boolean
  > = new EventEmitter<boolean>();

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private notificationService: NotificationService,
    private siteService: SiteService,
    private utilService: UtilityService
  ) {
    this.parkingLotGroupDataChanged = new EventEmitter<boolean>();
    this.parkingLotGroups = [];
    this.getParkingLotGroupAPI();
  }

  // Method to return Parking lot Group array
  public getParkingLotGroups(): ParkingLotGroup[] {
    return [...this.parkingLotGroups];
  }

  public getParkingLotGroupAPI(): Promise<any> {
    const apiURL = environment.getAPI("getParkingLotGroup");

    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, { SiteID: this.siteService.selectedSiteID }, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.parkingLotGroupsApiData = success.body;
            this.parkingLotGroups = new Array<ParkingLotGroup>();

            this.parkingLotGroupsApiData.forEach((element) => {
              let plg = new ParkingLotGroup(
                element.ParkingLotGroupID,
                element.Name,
                element.Description,
                element.EmailDirections,
                element.Regulated ? true : false,
                element.TargetCapacity,
                element.Active ? 1 : 0,
                element.ModifiedBy ? +element.ModifiedBy : null,
                new Date(element.ModifiedDate),
                element.CreatedBy ? +element.CreatedBy : null,
                new Date(element.CreatedDate)
              );
              this.parkingLotGroups.push(plg);
            });
            resolve();
          },
          (err) => {
            reject(err);
          }
        );
    });
    return promise;
  }

  // Method to add a Parking Lot Group
  public createParkingLotGroup(parkingLotGroups: ParkingLotGroup): number {
    let temp: ParkingLotGroup = { ...parkingLotGroups };
    let ParkingLotGroupID: number = this.parkingLotGroups.length + 1;
    temp.ParkingLotGroupID = ParkingLotGroupID;
    temp.CreatedDate = this.utilService.getCurrentSiteDT();
    this.parkingLotGroups.push(temp);

    this.createParkingLotGroupAPI(parkingLotGroups).then(() => {
      
    });

    return ParkingLotGroupID;
  }

  public createParkingLotGroupAPI(parkingLotGroups: ParkingLotGroup) {
    const apiURL = environment.getAPI("parkingLotGroupCreate");

    const postBody = {
      Name: parkingLotGroups.Name,
      Description: parkingLotGroups.Description,
      EmailDirections: "Directions",
      Regulated: parkingLotGroups.Regulated,
      TargetCapacity: parkingLotGroups.TargetCapacity,
      Active: 1,
      UserID: this.userService.userID,
      SiteID: this.siteService.selectedSiteID,
    };
    console.log(postBody);
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            console.log(success.body);
            this.notificationService.success(
              success.body.Message
            );
            this.parkingLotGroupDataChanged.emit(true);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // Method to update a Parking Lot Group by id
  public updateParkingLotGroup(parkingLotGroup: ParkingLotGroup) {
    return this.updateParkingLotGroupAPI(parkingLotGroup).then(() => {
      
    });
  }

  public updateParkingLotGroupAPI(parkingLotGroup: ParkingLotGroup) {
    const apiURL = environment.getAPI("parkingLotGroupUpdate");

    const postBody = {
      ParkingLotGroupID: parkingLotGroup.ParkingLotGroupID,
      Name: parkingLotGroup.Name,
      Description: parkingLotGroup.Description,
      EmailDirections: "Directions",
      Regulated: parkingLotGroup.Regulated,
      TargetCapacity: parkingLotGroup.TargetCapacity,
      Active: parkingLotGroup.Active,
      UserID: this.userService.userID,
      SiteID: this.siteService.selectedSiteID,
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, postBody, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            console.log(success.body);
            this.notificationService.success(
              success.body.Message
            );
            this.parkingLotGroupDataChanged.emit(true);
            resolve();
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // Method to delete Parking Lot by id

  public deleteParkingLotGroup(parkingLotGroup: ParkingLotGroup) {
    parkingLotGroup.Active = 0; //disabling parking lot Group
    return this.updateParkingLotGroupAPI(parkingLotGroup).then(() =>
      this.parkingLotGroupDataChanged.emit(true)
    );
  }
}
