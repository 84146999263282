import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";
import {
  MatExpansionPanel,
  MatDialogRef,
  MatCheckboxChange,
  MatSelectChange,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { NgForm, FormControl } from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";
import { WidgetService, widgetType } from "src/app/services/widget.service";
import { SearchService } from "src/app/services/search.service";
import { NotificationService } from "src/app/services/notification.service";
import { DialogService } from "src/app/services/dialog.service";
import { MatChipsModule } from "@angular/material/chips";
import { startWith, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { cloneDeep } from "lodash";
import { SiteService } from "src/app/services/site.service";
import { UtilityService } from "src/app/services/utility.service";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-widget-config-modal",
  templateUrl: "./widget-config.modal.component.html",
  styleUrls: ["./widget-config.modal.component.css"],
})
export class WidgetConfigModalComponent implements OnInit {
  @ViewChild("freezePanel") freezePanel: MatExpansionPanel;
  @ViewChild("filterForm") filterForm: NgForm;

  protected step = 0;
  protected loaded = true;

  protected minSeriesLimit = 1;
  protected maxSeriesLimit = 6;

  protected selectedWidgetID = null;
  // protected selectedSeries: Array<string> = [];
  protected availableSeries: Array<string> = [];

  protected autoCompleteOptionItems: Array<{
    name: string;
    show: boolean;
  }>;

  filteredOptions: Observable<
    Array<{
      name: string;
      show: boolean;
    }>
  >;

  protected selectedDateRangeOption: number = undefined;

  protected seriesControl = new FormControl([]);
  protected filterControl = new FormControl();

  protected filterStartDate: Date;
  protected filterEndDate: Date;

  protected userWidgetConfig: {
    UserWidgetFilterID: number;
    UserWidgetID: number;
    DateOption: number;
    Series: string[];
    Active: number;
  };

  protected dateOptions: Array<{
    PickListID: number;
    TableName: string;
    FieldName: string;
    DisplayValue: string;
    Active: string;
  }> = [];
  protected appLanguage;
  public screenLabels: Array<string> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WidgetConfigModalComponent>,
    public searchService: SearchService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    protected widgetService: WidgetService,
    protected siteService:SiteService,
    private utilService: UtilityService,
    protected messageService: MessageService,
  ) {
    console.log(data);

    this.selectedWidgetID = this.data.widgetID;
    // console.log(this.selectedWidgetID);
    // this.selectedSeries = this.data.data.map((d) => d.name);
  }

  async ngOnInit() {
    this.dateOptions = await this.loadDateOptions();

    // load settings from settings from user widget config if set
    this.setWidgetConfig(this.data.userWidgetID);

    // loads the available set of series labels
    this.availableSeries = this.getAvailableSeries();

    //labels by message service
if (localStorage.getItem('WidgetConfigLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('WidgetConfigLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Configure Widget Modal",
    "Widget"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('WidgetConfigLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    console.log(this.availableSeries);

    // initializing auto complete
    this.autoCompleteOptionItems = [
      ...this.availableSeries.sort().map((item) => {
        return {
          name: item,
          show: true,
        };
      }),
    ];

    // event listener for auto complete
    this.filteredOptions = this.filterControl.valueChanges.pipe(
      startWith(""),
      map((value: string) => {
        this.autoCompleteOptionItems.forEach((option) => {
          option.show = option.name
            .toLocaleLowerCase()
            .includes(value.toLowerCase());
        });
        return this.autoCompleteOptionItems;
      })
    );

    console.log(this.autoCompleteOptionItems);
    console.log(this.availableSeries);
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  // clear previous auto complete on select dropdown close
  onSelectDropdownClose() {
    this.filterControl.setValue("");
  }

  onNoClick(confirm?: boolean): void {
    this.dialogRef.close(!!confirm);
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  onMainHeaderClose() {
    this.freezePanel.open();
  }

  onSeriesRemoved(s: string) {
    const series = this.seriesControl.value as string[];
    this.removeFirst(series, s);
    this.seriesControl.setValue(series);
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  protected async onWidgetUpdateSave(updateForm: NgForm) {
    this.dialogService
      .openConfirmDialog(
        this.screenLabels['UpdateWifgetConfigurationLabel'] ? this.screenLabels['UpdateWifgetConfigurationLabel'] : "Update Widget Configuration",
        this.screenLabels['UpdateWifgetConfigurationMsg'] ? this.screenLabels['UpdateWifgetConfigurationMsg'] : "Are you sure you want to save the configuration?"
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          // this.filterSeries();
          // this.filterDate();

          console.log(this.data);
          console.log(this.selectedDateRangeOption);
          console.log(updateForm);
          console.log(this.data.UserWidgetID);

          const dateOption: number = this.selectedDateRangeOption;
          let userWidgetID: number = this.data.userWidgetID;
          const seriesLabelArray: Array<string> = this.seriesControl.value;
          let userWidgetFilterID = 0; // pass 0 for new user widget

          if (this.data.hasOwnProperty("isNewWidget")) {
            userWidgetID = this.data.UserWidgetID;
          }

          if (this.userWidgetConfig)
            userWidgetFilterID = this.userWidgetConfig.UserWidgetFilterID;

          // save the record
          this.widgetService
            .saveUserWidgetConfigAPI(
              dateOption,
              userWidgetID,
              seriesLabelArray,
              userWidgetFilterID
            )
            .then(() => {
              this.notificationService.success(this.widgetService.message);
              this.widgetService.updateWidgets.emit(true);
            })
            .catch((err: HttpErrorResponse) => {
              this.notificationService.failure(err.message);
            });

          // this.data = { ...this.data };
          this.onNoClick(true);
        }
      });
  }

  protected checkSeriesLimit(matSelect) {
    console.log("Value Changed");
    if (this.seriesControl.value.length >= this.maxSeriesLimit) {
      console.log("Disable Mat Select");
      matSelect.close();
    }
  }

  // week index start from 1
  private setWeekStartAndEndDate(weekNumber: number) {
    if (weekNumber === 1) {
      this.filterStartDate = this.utilService.getCurrentSiteDT();
      this.filterEndDate = new Date(
        this.utilService
          .getCurrentSiteDT()
          .setDate(this.utilService.getCurrentSiteDT().getDate() + 7)
      );

      return;
    }

    this.filterStartDate = new Date(
      this.utilService
        .getCurrentSiteDT()
        .setDate(
          this.utilService.getCurrentSiteDT().getDate() +
            7 * (weekNumber - 1) +
            1
        )
    );
    this.filterEndDate = new Date(
      this.utilService
        .getCurrentSiteDT()
        .setDate(this.utilService.getCurrentSiteDT().getDate() + 7 * weekNumber)
    );
  }

  // the method is invoked to retrieve the available series for the widget
  private getAvailableSeries() {
    // execute the branch for new un-configured widget
    if (this.data.hasOwnProperty("isNewWidget")) {
      const chartSeries: Array<string> = [
        ...this.data.ChartData.map((cd) => cd.name),
      ];

      return chartSeries;
    }
    // execute the widget for the configured widget
    else {
      console.log(this.widgetService.originalDashboardWidget);

      const originalWidget = this.widgetService.originalDashboardWidget.find(
        (o) => o.userWidgetID === this.data.userWidgetID
      );
      const allSeries = [...originalWidget.data.map((d) => d.name)];

      console.log(allSeries);

      return allSeries;
    }
  }

  private getOriginalWidget(userWidgetID: number) {
    const widget = {
      ...this.widgetService.originalDashboardWidget.find(
        (w) => w.userWidgetID === userWidgetID
      ),
    };

    if (widget.type === "Line Chart Widget") {
      widget.data.forEach((d) => {
        d.name = d.name;
        d.series = [...d.series];
      });
    }

    return widget;
  }

  private async setWidgetConfig(userWidgetID: number) {
    try {
      await this.widgetService.getUserWidgetConfigAPI(this.data.userWidgetID);
      this.userWidgetConfig = { ...this.widgetService.userWidgetConfig[0] };

      console.log(this.userWidgetConfig);

      if (
        this.userWidgetConfig.hasOwnProperty("DateOption") &&
        this.userWidgetConfig.hasOwnProperty("Series")
      ) {
        this.selectedDateRangeOption = this.userWidgetConfig.DateOption;
        this.seriesControl.setValue(this.userWidgetConfig.Series);
      }
      console.log(this.userWidgetConfig);
    } catch (ex) {
      this.notificationService.failure(this.screenLabels['CouldNotReadWidgetMsg'] ? this.screenLabels['CouldNotReadWidgetMsg'] : "Could not read Widget configuration");
      console.error(ex);
    }
  }

  private async loadDateOptions() {
    const dateOptions = cloneDeep(await this.widgetService.getDateOptionsAPI());
    console.log(dateOptions);

    return dateOptions;
  }
}
