import { Injectable } from "@angular/core";
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material";
@Injectable({
  providedIn: "root",
})
export class NotificationService {
  msg: string;
  //message: string = 'Snack Bar opened.';
  setAutoHide: boolean = true;
  autoHide: number = 3000;
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "bottom";

  constructor(public snackBar: MatSnackBar) {}

  success(message: string) {
    //this.config['panelClass'] = ['notification'];
    //this.snackBar.open('',msg,this.config);
    let config = new MatSnackBarConfig();
    this.setAutoHide = true;
    config.panelClass = ["red-snackbar"];
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    this.snackBar.open(message, "", config);
  }
  failure(message: string): void {
    let config = new MatSnackBarConfig();
    this.setAutoHide = false;
    config.panelClass = ["red-snackbar"];
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    this.snackBar.open(message, "X", config);
  }
  configFailure(err:any): void {
    let config = new MatSnackBarConfig();
    var errMsg = "";
    if (err.status == 502) {
      errMsg = "502 - error";
    } else if (err.status == 503) {
      errMsg = "503 - error";
    } 
    this.setAutoHide = false;
    config.panelClass = ["red-snackbar"];
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    this.snackBar.open(errMsg, "X", config);
  }
}
